import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import { MainHeader, SecondHeader, Paragraph } from './styles/AuditTypography';
import { ProductContainer, ConnectLine, Seal } from './styles/AuditProducts';
import SealTitle from './SealTitle';

import box from '../../images/products/box.png';
import weather from '../../images/products/weather.png';
import index from '../../images/products/index.png';
import smart from '../../images/products/LogoSmart.png';
import fire from '../../images/products/fire.png'
import seal from '../../images/audit/selo.png';

function Products() {
  const [isSealActive, setSealActive] = useState({
    sealOne: false,
    sealTwo: false,
  });

  useEffect(() => {
    const scrollToTop = () => {
      return window.scrollTo(0, 0);
    };

    return () => {
      scrollToTop();
    };
  }, []);

  return (
    <Grid container>
      <Grid item>
        <MainHeader component="h2" align="left">Produtos</MainHeader>
        <SecondHeader component="h3" align="left">Conheça nossa linha do tempo</SecondHeader>
      </Grid>

      <Grid item>
        <Grid container sx={{ mb: 5, mt: 15 }} justifyContent="space-between">
          <Grid item xs={1.2}>
            <img src={weather} alt="weather logo" width="100%" height="25px" />
          </Grid>
          <Grid item xs={0.2}>
            <ConnectLine color="#644C9A" />
          </Grid>
          <ProductContainer item xs={10.6}>
            <Paragraph>
              A funcionalidade do Weather contempla os dados históricos (1980-até o presente) e de previsão (30 dias, fontes: INMET e NOAA) de todas as variáveis climáticas mais importantes para a agrometeorologia, sendo elas chuva, temperatura, radiação solar, umidade relativa do ar, velocidade do vento e evapotranspiração. Além dessas variáveis, geramos o que chamamos de variáveis secundárias, sendo essas as que contabilizam graus-dia, dias com chuva, dias com onda de calor e dias com ocorrência de geada. Nesse produto, você pode consultar as variáveis climáticas de forma isoladas e independentes ao longo da série histórica toda e em diversas escalas e formatos.
            </Paragraph>
          </ProductContainer>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container sx={{ mb: 5 }} justifyContent="space-between">
          <Grid item xs={0.9}>
            <img src={box} alt="box logo" height="25px" />
          </Grid>
          <Grid item xs={0.5}>
            <ConnectLine color="#0072BB" />
          </Grid>
          <ProductContainer item xs={10.6}>
            <Paragraph>
              O Box agrupa todas as variáveis resultantes do cálculo do balanço hídrico, o que nos trás informações históricas e de previsão da evapotranspiração potencial e real, armazenamento de água no solo (absoluto e relativo), excedente e déficit hídrico variando de acordo com a CAD (Capacidade de Água Disponível), assim auxiliando o usuário a inferir sobre a condição de disponibilidade hídrica para o crescimento das culturas. Para o cálculo do Balanço Hídrico diário, usamos a metodologia desenvolvida por Thornthwaite e Mather (1955)
            </Paragraph>
          </ProductContainer>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container sx={{ mb: 5 }} justifyContent="space-between">
          <Grid item sx={{ position: 'relative' }}>
            <Link to="/audit/pauloCesar">
              <Seal>
                <img
                  src={seal}
                  alt="Paulo César Sentelhas Seal"
                  onMouseOver={() => setSealActive({ ...isSealActive, sealOne: true })}
                  onMouseOut={() => setSealActive({ ...isSealActive, sealOne: false })}
                />
                {
                  isSealActive.sealOne && (
                    <SealTitle>
                      Criado por Prof. Paulo Sentelhas
                    </SealTitle>
                  )
                }
              </Seal>
            </Link>
          </Grid>
          <Grid item xs={1}>
            <img src={index} alt="index logo" height="25px" />
          </Grid>
          <Grid item xs={0.4}>
            <ConnectLine color="#50AC32" />
          </Grid>
          <ProductContainer item xs={10.6}>
            <Paragraph>
              O Index é a funcionalidade de favorabilidade climática para as principais operações agrícolas. Nessa ferramenta relacionamos a disponibilidade de água na camada superficial do solo com as principais práticas agrícolas para gerar índices de favorabilidade. As operações agrícolas contempladas são: plantio, tráfego/colheita, aplicação de herbicida, preparo do solo, adubação, pulverização e condição de crescimento das culturas.
            </Paragraph>
          </ProductContainer>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container sx={{ mb: 5 }} justifyContent="space-between">
          <Grid item xs={0.9}>
            <img src={fire} alt="weather logo" height="25px" />
          </Grid>
          <Grid item xs={0.5}>
            <ConnectLine color="#D83F32" />
          </Grid>
          <ProductContainer item xs={10.6}>
            <Paragraph>
              Ferramenta de monitoramento e previsão de risco de incêndio. Utilizando a Fórmula de Monte Alegre (FMA), o Fire correlaciona as principais variáveis climáticas que podem favorecer a ocorrência de incêndio de causa natural. Essa ferramenta permite o usuário a entender qual é a probabilidade de risco com base no histórico climatológico e se os dados de previsão do tempo indicam algum risco a curto prazo.
            </Paragraph>
          </ProductContainer>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container sx={{ mb: 5 }} justifyContent="space-between">
          <Grid item sx={{ position: 'relative', zIndex: 3 }}>
            <Link to="/audit/pauloCesar">
              <Seal>
                <img
                  src={seal}
                  alt="Paulo César Sentelhas Seal"
                  onMouseOver={() => setSealActive({ ...isSealActive, sealTwo: true })}
                  onMouseOut={() => setSealActive({ ...isSealActive, sealTwo: false })}
                />
                {
                  isSealActive.sealTwo && (
                    <SealTitle>
                      Criado por Prof. Paulo Sentelhas
                    </SealTitle>
                  )
                }
              </Seal>
            </Link>
          </Grid>
          <Grid item xs={1} sx={{ zIndex: 2 }}>
            <img src={smart} alt="smart logo" height="25px" />
          </Grid>
          <Grid item xs={0.4}>
            <ConnectLine color="#5D5D5C" />
          </Grid>
          <ProductContainer item xs={10.6}>
            <Paragraph>
              O Smart, diferente das demais funcionalidades do Agrymax interage as condições climáticas apresentadas no weather e no box com as principais culturas agrícolas e florestais produzidas no Brasil. Com auxílio do Modelo de Zoneamento Agroecológico da FAO (1979) e suas adaptações, fruto de anos de pesquisa do nosso fundador Prof. Dr. Paulo Sentelhas, geramos análises específicas de estimativa e previsão de safra para as culturas da cana-de-açúcar, soja, milho, algodão, trigo e eucalipto.
            </Paragraph>
          </ProductContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Products;
