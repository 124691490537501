import React, { Component } from 'react'

class AdminHomePage extends Component{
  render(){
    return(
      <div className='row admin-information'>
        <div className='admin-home-page'>
          <h4>Olá administrador!</h4>
          <span>Utilize o menu ao lado para navegar pela área de administradores.</span>
        </div>
      </div>
    )
  }
}

export default AdminHomePage
