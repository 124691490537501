import { Button, Grid, IconButton } from '@material-ui/core';
import { Box, styled } from '@mui/material';

export const PointsModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  backgroundColor: 'white',
  borderRadius: '5px',
});

export const PointsModalButtonIcon = styled(Button)({
  marginTop: '30px',
  maxHeight: '50px'
});

export const PointsModalButtonSave = styled(Button)({
  size: '10px',
  backgroundColor: 'rgb(106, 164, 38)',
  color: 'white',
  padding: '5px 50px',
  margin: '10px 5px'
});

export const PointsModalGrid = styled(Grid)({
  display: 'flex',
  justifyContent: "center"
});

export const PointsModalInput= styled('input')({
  padding: '0px 10px',
  color: 'black',
});

export const PlanModalIconButton = styled(IconButton)({
  margin: '0px 0px 0px 280px',
  position: 'fixed',
});

export const latLongBox = styled(Box)({
  overflowY: 'scroll',
  overflowX: 'hidden',
  maxHeight: '500px'
})
