import * as React from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import * as S from './styles/AutomaticAuditFooter'

function AutomaticAuditFooter() {
  const history = useHistory();
  const iconsName = ['device_thermostat', 'water_drop', 'assessment', 'sanitizer'];

  return (
    <S.AutoAuditBox container>
      <Grid item xs={1}>
        {
          iconsName.map((icon) => (
            <Grid item container>
              <S.AutoAuditIcon >{icon}</S.AutoAuditIcon>
            </Grid>
          ))
        }
      </Grid>
      <Grid
        item
        xs={11}
        md={6}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid item xs={3}>
          <S.AutoAuditP>
            Além da auditoria automática que
            <br/>
            é feita todos os dias, periodicamente
            <br/>
            realizamos a
          </S.AutoAuditP>
        </Grid>
        <Grid item xs={3}>
          <S.AutoAuditCustomP onClick={() => history.push("/audit/manualAudit")}>
            Auditoria manual
          </S.AutoAuditCustomP>
          <S.AutoAuditBoxP>, confira o cronograma:</S.AutoAuditBoxP>
        </Grid>
        <Grid item xs={3}>
          <S.AutoAuditButton onClick={() => history.push("/audit/manualAudit")}>
            Ir para auditoria manual
          </S.AutoAuditButton>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item container xs={12} alignItems="center">
          <S.AutoAuditIconArrow >arrow_right_alt</S.AutoAuditIconArrow>
          <S.AutoAuditLP>Quer saber sobre nossos produtos?</S.AutoAuditLP>
        </Grid>
        <Grid item container justifyContent="flex-end" xs={12}>
          <S.AutoAuditLink to={"/audit/products"}>Clique aqui</S.AutoAuditLink>
        </Grid>
      </Grid>
    </S.AutoAuditBox>
  );
}

export default AutomaticAuditFooter;
