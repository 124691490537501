import React, { useState } from "react"
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from "@material-ui/core"
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from "material-ui-pickers";

const UpdateModal = ({ toggleUpdate, sendUpdate, row }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    function handleUpdate(){
        const data = {
            rowId: row.line_id,
            startDate: fromDate,
            endDate: toDate
        }

        sendUpdate(data)
    }

    if(row.farm_id){
        return (
            <Dialog open={row.farm_id ? true : false} onClose={toggleUpdate}>
                <DialogTitle>
                    Executar a integração para <b>{row.farm_name}</b> no período:
                </DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                        <DatePicker
                            keyboard
                            placeholder={'DD/MM/AAAA'}
                            label="Data inicial"
                            name="fromDate"
                            format={"DD/MM/YYYY"}
                            margin="dense"
                            value={fromDate}
                            onChange={e => setFromDate(e)}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            minDate={moment('1981-01-01')}
                            maxDate={moment()}
                            InputProps={{disableUnderline: true, style: {width: "100%"}, error: true}}
                            FormHelperTextProps={{error: true, className: "input-error-text" }}
                            helperText={""}
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                        />
                    </MuiPickersUtilsProvider>
    
                    <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                        <DatePicker
                            keyboard
                            placeholder={'DD/MM/AAAA'}
                            label="Data final"
                            name="toDate"
                            format={"DD/MM/YYYY"}
                            margin="dense"                                            
                            value={toDate}
                            onChange={e => setToDate(e)}
                            disableOpenOnEnter
                            animateYearScrolling={false}
                            minDate={moment('1981-01-01')}
                            maxDate={moment()}
                            InputProps={{disableUnderline: true, style: {width: "100%"}, error: true}}
                            FormHelperTextProps={{error: true, className: "input-error-text" }}
                            helperText={""}
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button className="green save-button" onClick={handleUpdate}>Atualizar</Button>
                </DialogActions>
            </Dialog>
        )
    }
    else {
        return null
    }
}

export default UpdateModal;