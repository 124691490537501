import React, {Component} from 'react'

import { getUserPremiumSmarts, putUserPremiumSmarts } from '../../../api/admin'

import { Grid, Button, GridList, GridListTile } from '@material-ui/core';
import { showToaster } from '../../common/Toaster';

const wheatImage = '/images/logos/smart-wheat.png'
const caneImage= '/images/logos/smart-cane.png'
const soyImage= '/images/logos/smart-soy.png'
const cornImage = '/images/logos/smart-corn.png'
const cottonImage = '/images/logos/smart-cotton.png'
const eucalyptusImage = '/images/logos/smart-eucalyptus.png'

class ProductManagementSamartPremium extends Component {
  constructor(props){
    super(props)
    this.state = {
      premiumSmartsAvailable: [],
      premiumSmartsAuthorized: [],
      id:0,
      smarts:{
        'cana':{alt: 'sugar-logo', src:caneImage},
        'soja' :{alt: 'soy-logo', src:soyImage},
        'milho' :{alt: 'corn-logo', src:cornImage},
        'algodao' :{alt: 'product-item', src:cottonImage},
        'trigo' :{alt: 'wheat-logo', src:wheatImage},
        'eucalipto': {alt: 'eucalyptus-logo', src:eucalyptusImage}
      }
    }
  }
  
  //Lifecycle methods
  UNSAFE_componentWillReceiveProps(nextProps){
    const getSmarts = async (id) => {
      const data = await getUserPremiumSmarts(id);
      if (data) {
        this.setState({
          premiumSmartsAvailable: data.data.data.available,
          premiumSmartsAuthorized: data.data.data.authorized,
          id: id
        })
      }
    };
    getSmarts(nextProps.item.id);
  }

  onProductChange = (product, type) => {
    const smarts = Object.keys(this.state.smarts)
    if (type === 'available') {
      let list = this.state.premiumSmartsAvailable
      let indice = list.indexOf(product);
      list.splice(indice, 1)
      list = this.state.premiumSmartsAuthorized
      list.push(product)
      let authorizedList = []
      smarts.forEach((prod) => {
        if (list.includes(prod)){
          authorizedList.push(prod)
        }
      })
      this.setState({
        premiumSmartsAuthorized: authorizedList
      })
    } else {
      let list = this.state.premiumSmartsAuthorized
      let indice = list.indexOf(product);
      list.splice(indice, 1)
      list = this.state.premiumSmartsAvailable
      list.push(product)
      let availableList = []
      smarts.forEach((prod) => {
        if (list.includes(prod)){
          availableList.push(prod)
        }
      })
      this.setState({
        premiumSmartsAvailable: availableList
      })
    }
  }

  onSaveNewPermissions = () => {
    if(this.state.premiumSmartsAuthorized.length === 0) {
      const toasterParams = {
        title: "Dados inválidos",
        message: 'pelo menos um smart deve ser escolhido',
        status: true,
        autoClose: true,
      };
      showToaster(toasterParams);
    } else{
      putUserPremiumSmarts(this.state.id, this.state.premiumSmartsAuthorized)
    }
  }

  drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  }

  renderSmarts(product, index, type) {
    const { smarts } = this.state
    return(
      <GridListTile key={product}>
        <Button>
          <img 
            onClick={()=> this.onProductChange(product, type)}
            id={index}
            className='product-item'
            alt= {smarts[product].alt}
            src={smarts[product].src}/>
        </Button>
      </GridListTile>
    )
  }

  render(){
    return (
      <Grid container className='access-control' justify='center'>
        <Grid item xs={12}>
          <Grid container className='title-row' justify='space-between' alignItems='center'>
            <Grid item>
              <h5 className='title'>
                  Controle de Acesso
              </h5>
            </Grid>
            <Grid item>
              {this.state.activeProduct !== 0 ?
                <Button className='green-button' variant='contained' onClick={this.onSaveNewPermissions}>
                  Salvar
                </Button>
              :
                null
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item className='management-container'>
          <Grid container spacing={24} >
            <Grid item md={3}>
              <Grid container className='product-container'>
                <Grid item xs={12} className='product-title'>
                  PRODUTOS
                </Grid>
                <Grid item xs={12}>
                  <GridList className='product-list' cellHeight={75} cols={1}>
                    {this.state.premiumSmartsAvailable.map((product, index) => this.renderSmarts(product, index, 'available'))}
                  </GridList>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Grid container className='product-container'>
                <Grid item xs={12} className='product-title'>
                PONTOS CADASTRADOS
                </Grid>
                <Grid item xs={12}>
                  <GridList className='product-list' cellHeight={75} cols={1}>
                    {this.state.premiumSmartsAuthorized.map((product, index) => this.renderSmarts(product, index, 'authorized'))}
                  </GridList>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ProductManagementSamartPremium
