import React from 'react';
import { CSVDownload } from "react-csv";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function DownloadScreen({ downloadLink, resetState, isClicked, datasetTypeValue }) {
  return (
    <Grid
      container
      style={{ height: "100%" }}
      alignContent="center"
      justify="center"
      wrap="wrap"
    >
      <Grid item xs={12}>
        <Typography variant="h5" textAlign="center">
          O download pode demorar um pouquinho, que tal tomar um
          cafézinho?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img
          src="/images/others/cafe1.gif"
          alt="Gif de uma xicara de café"
          style={{ display: 'block', margin: 'auto' }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{
            backgroundColor: !downloadLink
              ? "rgb(194, 194, 194)"
              : "#6aa426",
            color: "white",
            padding: "9px 93px",
            margin: "80px 0 0 0",
          }}
          onClick={resetState}
          disabled={!downloadLink}
        >
          Baixar
        </Button>
        {
          typeof downloadLink !== "string" && isClicked && datasetTypeValue === "raw"
            ? <CSVDownload data={downloadLink} />
            : null
        }
      </Grid>
    </Grid>
  );
}

export default DownloadScreen;
