export function numberToMonth(month){
  if(month === '01' || month === 'jan'){
    return 'Janeiro'
  }else if(month === '02' || month === 'fev'){
    return 'Fevereiro'
  }else if(month === '03' || month === 'mar'){
    return 'Março'
  }else if(month === '04' || month === 'abr'){
    return 'Abril'
  }else if(month === '05' || month === 'mai'){
    return 'Maio'
  }else if(month === '06' || month === 'jun'){
    return 'Junho'
  }else if(month === '07' || month === 'jul'){
    return 'Julho'
  }else if(month === '08' || month === 'ago'){
    return 'Agosto'
  }else if(month === '09' || month === 'set'){
    return 'Setembro'
  }else if(month === '10' || month === 'out'){
    return 'Outubro'
  }else if(month === '11' || month === 'nov'){
    return 'Novembro'
  }else if(month === '12' || month === 'dez'){
    return 'Dezembro'
  }else{
    return month
  }
}

export function monthToNumber(month){

  month = month.substring(0, 3).toLowerCase();

  if(month === 'jan'){
    return '01'
  }else if(month === 'fev'){
    return '02'
  }else if(month === 'mar'){
    return '03'
  }else if(month === 'abr'){
    return '04'
  }else if(month === 'mai'){
    return '05'
  }else if(month === 'jun'){
    return '06'
  }else if(month === 'jul'){
    return '07'
  }else if(month === 'ago'){
    return '08'
  }else if(month === 'set'){
    return '09'
  }else if(month === 'out'){
    return '10'
  }else if(month === 'nov'){
    return '11'
  }else if(month === 'dez'){
    return '12'
  }else{
    return month
  }
}

export function leapYear(year){
  return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
}

export function setDates(month, decendial, year){
  let dates = []
  let months30 = ['04', '06', '09', '11']
  let months31 = ['01', '03', '05', '07', '08', '10', '12']
  if(decendial === '1'){
    dates.push(year + '-' + month + '-01')
    dates.push(year + '-' + month + '-10')
  }else if(decendial === '2'){
    dates.push(year + '-' + month + '-11')
    dates.push(year + '-' + month + '-20')
  }else if(decendial === '3'){
    dates.push(year + '-' + month + '-21')
    if(month === '02'){
      if(leapYear(parseInt(year,10)) === true){
        dates.push(year + '-' + month + '-29')
      }else{
        dates.push(year + '-' + month + '-28')
      }
    }else if(months30.includes(month)){
      dates.push(year + '-' + month + '-30')
    }else if(months31.includes(month)){
      dates.push(year + '-' + month + '-31')
    }
  }else if(decendial === '4'){
    let monthString = month.toString()
    dates.push(year+ '-' + monthString + '-01')
    if(monthString === '02'){
      if(leapYear(parseInt(year,10)) === true){
        dates.push(year + '-' + monthString + '-29')
      }else{
        dates.push(year + '-' + monthString + '-28')
      }
    }else if(months30.includes(monthString)){
      dates.push(year + '-' + monthString + '-30')
    }else if(months31.includes(monthString)){
      dates.push(year + '-' + monthString + '-31')
    }
  }else{
    dates.push(year + '-01-01')
    dates.push(year + '-12-31')
  }
  return dates
}

export function setDecendialNumber(day){
  if(day <= 10){
    return '1'
  }else if(day > 10 && day <= 20){
    return '2'
  }else{
    return '3'
  }
}

export function translateMonth(month) {
  if(month === 'January'){
    return 'Janeiro'
  }else if(month === 'February'){
    return 'Fevereiro'
  }else if(month === 'March'){
    return 'Março'
  }else if(month === 'April'){
    return 'Abril'
  }else if(month === 'May'){
    return 'Maio'
  }else if(month === 'June'){
    return 'Junho'
  }else if(month === 'July'){
    return 'Julho'
  }else if(month === 'August'){
    return 'Agosto'
  }else if(month === 'September'){
    return 'Setembro'
  }else if(month === 'October'){
    return 'Outubro'
  }else if(month === 'November'){
    return 'Novembro'
  }else if(month === 'December'){
    return 'Dezembro'
  }else{
    return month
  }
}

export function translateMonthToInitials(month) {
  if(['January', "01", 'Janeiro'].includes(month)){
    return 'Jan'
  }else if(['February', "02", 'Fevereiro'].includes(month)){
    return 'Fev'
  }else if(['March', "03", 'Março'].includes(month)){
    return 'Mar'
  }else if(['April',"04",'Abril'].includes(month)){
    return 'Abr'
  }else if(['May', "05", 'Maio'].includes(month)){
    return 'Mai'
  }else if(['June', "06", 'Junho'].includes(month)){
    return 'Jun'
  }else if(['July', "07", 'Julho'].includes(month)){
    return 'Jul'
  }else if(['August', "08", 'Agosto'].includes(month)){
    return 'Ago'
  }else if(['September', "09", 'Setembro'].includes(month)){
    return 'Set'
  }else if(['October', "10", 'Outubro'].includes(month)){
    return 'Out'
  }else if(['November', "11", 'Novembro'].includes(month)){
    return 'Nov'
  }else if(['December', "12", 'Dezembro'].includes(month)){
    return 'Dez'
  }
}

export function isDateInRange(date, start, end){
  return start <= date && date <= end
}

export function agriculturalYear(index){
  switch(index){
    case 0:
      return 6
    case 1:
      return 7
    case 2:
      return 8
    case 3:
      return 9
    case 4:
      return 10
    case 5:
      return 11
    case 6:
      return 0
    case 7:
      return 1
    case 8:
      return 2
    case 9:
      return 3
    case 10:
      return 4
    case 11:
      return 5
    default:
      return -1
  }
}

export function agriculturalYearSubtraction(visualizationType, data, graphicYear = new Date().getFullYear()){
  if(data.bhbox) {
    const length = data.bhbox.length

    return visualizationType === 'agricultural' && (
      (
        data.bhbox[length-1].year === parseInt(graphicYear, 10) &&
        data.bhbox[length-1].data[6][0] === -1
      )
      ||
      (
        data.bhbox[length-1].year === new Date().getFullYear()-1 &&
        graphicYear === new Date().getFullYear() &&
        data.bhbox[length-1].data[6][0] !== -1
      )
    )
  }
}

export function setDecendialDates(dayInfo, initial) {
  const months = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ];
  const longMonths = ['Janeiro', 'Março', 'Maio', 'Julho', 'Agosto', 'Outubro', 'Dezembro'];
  const shortMonths = ['Abril', 'Junho', 'Setembro', 'Novembro'];
  
  const [name, decendial] = dayInfo.label.split('_');
  let month = months.findIndex(m => m === name) + 1;

  if (month <= 9) {
    month = `0${month}`;
  } else {
    month = month.toString();
  }

  let lastDay;
  if (longMonths.includes(name)) {
    lastDay = '31';
  } else if (shortMonths.includes(name)) {
    lastDay = '30';
  } else {
    lastDay = leapYear(Number(dayInfo.year)) ? '29' : '28';
  }

  switch (decendial) {
    case '1':
      return initial ? ['1', month] : ['10', month];
    case '2':
      return initial ? ['11', month] : ['20', month];
    default:
      return initial ? ['21', month] : [lastDay, month];
  }
}

export function setLabeltoDate(dayInfo, initial = true){
  const isDecendial = dayInfo.label.includes('_');

  const [day, month] = isDecendial ? setDecendialDates(dayInfo, initial) : dayInfo.label.split('/');

  return  `${dayInfo.year}-${month}-${day}`;
}

export function getYearsInterval(years, minYear = 0){

  const range = (start, end, length = end - start + 1) =>
    Array.from({ length }, (_, i) => start + i)

  years = years.replace(/\s+/g, '');

  let yearRange = []
  let yearList = []

  yearList = years.split(',')
  yearList.forEach((year) => {
    let interval = year.split('-')
    if(interval.length > 1){
      range(parseInt(interval[0], 10), parseInt(interval[1], 10)).forEach(sub => {
        if(sub > minYear){
          yearRange.push(sub.toString())
        }
      })
    }
    else{
      if(parseInt(year, 10) > minYear){
        yearRange.push(year)
      }
    }
  })
  return [...new Set(yearRange.sort())]
}

export function setYearsList(start, end){
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export function renderActualYear(visuelizationType, year, monthNumber, monthOrDecendioName) {
  const decendiosArray = [
    'jan_1', 'jan_2', 'jan_3', 'fev_1', 'fev_2', 'fev_3', 'mar_1', 'mar_2', 'mar_3',
    'abr_1', 'abr_2', 'abr_3', 'mai_1', 'mai_2', 'mai_3', 'jun_1', 'jun_2', 'jun_3',
  ];
  const monthsArray = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun'];

  if (visuelizationType === 'agricultural' &&
      (decendiosArray.includes(monthOrDecendioName) || monthsArray.includes(monthOrDecendioName) || monthNumber < 7)
    ) {
      return Number(year) + 1;
  } else {
      return year;
  }
}