import React, { Component} from 'react'
import { SOY, CORN, COTTON, WHEAT, EUCALYPTUS } from "../common/defaultValues"

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

class GeneralSoyForm extends Component {

  render() {
    let cycleOptions = []

    switch(this.props.type){
      case "cotton-general-productivity":
        cycleOptions = COTTON.CYCLE_OPTIONS
        break
      case "corn-general-productivity":
        cycleOptions = CORN.CYCLE_OPTIONS
        break
      case "soy-general-productivity":
        cycleOptions = SOY.CYCLE_OPTIONS
        break
      case "wheat-general-productivity":
        cycleOptions = WHEAT.CYCLE_OPTIONS
        break
      default:
        break
    }
    
    return(
      <FormControl component="fieldset">
        {this.props.type === "cotton-general-productivity" || "corn-general-productivity" || "soy-general-productivity" || "wheat-general-productivity" ?
          (<div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <FormControl>
              <InputLabel htmlFor="crop-input">Safra</InputLabel>
              <Select inputProps={{name: 'crop', id: 'crop-input'}} className={"prod-months-dropdown"} value={this.props.currentState.crop} onChange={this.props.handleChange} name="crop">
                <MenuItem value={'Atual'}>Atual</MenuItem>
                <MenuItem value={'Próxima'}>Próxima</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <TextField
              name="eficiency"
              type="text"
              value={this.props.currentState.eficiency}
              label="Eficiência Agrícola (%)"
              onChange={ this.props.handleChange }
              InputProps={{disableUnderline: true, style: {width: "70%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              className="text-fields"
              style={{width: '70%'}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.props.errors.eficiency ? this.props.errors.eficiency : ''}
            />
            <FormControl>
              <InputLabel htmlFor="cycle-input">Ciclo</InputLabel>
              <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.currentState.cycle} onChange={this.props.handleChange} name="cycle">
                {cycleOptions.map(option => 
                  <MenuItem value={option}>{option}</MenuItem> 
                )}
              </Select>
            </FormControl>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <TextField
              margin={"dense"}
              name="cc_user"
              type="text"
              label={"Índice de colheita"}
              value={this.props.currentState.cc_user}
              onChange={this.props.handleChange}
              InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              className="text-fields"
              style={{width: '45%'}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.props.errors.cc_user ? this.props.errors.cc_user : ''}
            />
            <TextField
              margin={"dense"}
              name="u_percent_user"
              type="text"
              label={this.props.reportType === 'productivity-spec' ? "Umidade do colmo (%)" : "Umidade do grão (%)"}
              value={this.props.currentState.u_percent_user}
              onChange={this.props.handleChange}
              InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              className="text-fields"
              style={{width: '45%'}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.props.errors.u_percent_user ? this.props.errors.u_percent_user : ''}
            />
          </div>
          {this.props.type !== "wheat-general-productivity" &&
            <div style={{display: 'flex', justifyContent: 'space-between', fontSize: "1rem"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.props.currentState.frost}
                    onChange={this.props.handleChange}
                    name="frost"
                    style={{color: "green"}}
                  />
                }
                label="Penalização de Geada"
              />
            </div>
          }
        </div>)
        : this.props.type === "eucalyptus-general-productivity"
        ? <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <FormControl>
            <InputLabel htmlFor="crop-input">Safra</InputLabel>
            <Select inputProps={{name: 'crop', id: 'crop-input'}} className={"prod-months-dropdown"} value={this.props.currentState.crop} onChange={this.props.handleChange} name="crop">
              <MenuItem value={'Atual'}>Atual</MenuItem>
              <MenuItem value={'Próxima'}>Próxima</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <TextField
            name="eficiency"
            type="text"
            value={this.props.currentState.eficiency}
            label="Eficiência Agrícola (%)"
            onChange={ this.props.handleChange }
            InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
            className="text-fields"
            style={{width: '70%'}}
            FormHelperTextProps={{error: true, className: "input-error-text" }}
            helperText={this.props.errors.eficiency ? this.props.errors.eficiency : ''}
          />
          <FormControl>
            <InputLabel htmlFor="cycle-input">Ciclo</InputLabel>
            <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.currentState.cycle} onChange={this.props.handleChange} name="cycle">
              {EUCALYPTUS.CYCLE_OPTIONS.map(option => 
                <MenuItem value={option}>{option}</MenuItem> 
              )}
            </Select>
          </FormControl>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <FormControl>
            <InputLabel htmlFor="range-input">Visão</InputLabel>
            <Select inputProps={{name: 'range', id: 'range-input'}} className={"prod-months-dropdown"} value={this.props.currentState.range} onChange={this.props.handleChange} name="range">
              <MenuItem value={'monthly'}>Mensal</MenuItem>
              <MenuItem value={'annualy'}>Anual</MenuItem>
            </Select>
          </FormControl>
           <FormControl>
            <InputLabel htmlFor="clone-input">Safra</InputLabel>
            <Select inputProps={{name: 'clone', id: 'clone-input'}} className={"prod-months-dropdown"} value={this.props.currentState.clone} onChange={this.props.handleChange} name="clone">
              <MenuItem value={'Plastico'}>Plastico</MenuItem>
              <MenuItem value={'Subtropical'}>Subtropical</MenuItem>
              <MenuItem value={'Tropical'}>Tropical</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      : null}
      </FormControl>
    )
  }
}

export default GeneralSoyForm;
