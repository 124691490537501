import IntegrationApi from '../api/integration'
import { toggleLoader } from "./global"

export const GET_API_KEYS = 'GET_API_KEYS'
export const SET_KEYS_SUCCESS = 'SET_KEYS_SUCCESS'
export const EDIT_KEYS_SUCCESS = 'EDIT_KEYS_SUCCESS'
export const DELETE_KEYS_SUCCESS = 'DELETE_KEYS_SUCCESS'
export const GET_POINTS_SUCCESS = 'GET_POINTS_SUCCESS'
export const GET_STATIONS_SUCCESS = 'GET_STATIONS_SUCCESS'
export const SET_STATION_SUCCESS = 'SET_STATION_SUCCESS'
export const EDIT_STATION_SUCCESS = 'EDIT_STATION_SUCCESS'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const PAGE_UPDATE = 'PAGE_UPDATE'
export const RESET_MESSAGE = 'RESET_MESSAGE'
export const GENERAL_ERROR = 'GENERAL_ERROR'
export const UPDATE_RESET = 'UPDATE_RESET'

export function getApiKeys(integration){
    return function(dispatch){
        return IntegrationApi.getApiKeys(integration)
            .then(keys => {
                dispatch(getApiKeysSuccess(keys))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function setApiKeys(keys){
    return function(dispatch){
        return IntegrationApi.setApiKeys(keys)
            .then(response => {
                dispatch(setApiKeysSuccess(response))
                dispatch(getApiKeys(keys.integration))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function editApiKeys(keys){
    return function(dispatch){
        return IntegrationApi.editApiKeys(keys)
            .then(response => {
                dispatch(editApiKeysSuccess(response))
                dispatch(getApiKeys(keys.integration))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function deleteApiKeys(integration){
    return function(dispatch){
        return IntegrationApi.deleteApiKeys(integration)
            .then(response => {
                dispatch(deleteApiKeysSuccess(response))
                dispatch(getApiKeys(integration))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function getApiKeysSuccess(keys){
    return({type: 'GET_API_KEYS', keys})
}

export function setApiKeysSuccess(response){
    return({type: 'SET_KEYS_SUCCESS', response})
}

export function editApiKeysSuccess(response){
    return({type: 'EDIT_KEYS_SUCCESS', response})
}

export function deleteApiKeysSuccess(response){
    return({type: 'DELETE_KEYS_SUCCESS', response})
}

export function getAvailablePoints(integration){
    return function(dispatch){
        return IntegrationApi.getAvailablePoints(integration)
            .then(response => {
                dispatch(getAvailablePointsSuccess(response))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function getUserStations(data){
    return function(dispatch){
        return IntegrationApi.getUserStations(data)
            .then(stations => {
                dispatch(getUserStationsSuccess(stations))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function setUserStation(data){
    return function(dispatch){
        return IntegrationApi.setUserStation(data)
            .then(response => {
                dispatch(setUserStationSuccess(response))
                dispatch(pageUpdateHandle())           
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function editUserStation(data){
    return function(dispatch){
        return IntegrationApi.editUserStation(data)
            .then(response => {
                dispatch(editUserStationSuccess(response))
                dispatch(pageUpdateHandle())
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function deleteUserStation(farm){
    return function(dispatch){
        return IntegrationApi.deleteUserStation(farm)
            .then(response => {
                dispatch(deleteUserStationSuccess(response))
                dispatch(pageUpdateHandle())
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            })
    }
}

export function updateIntegration(data){
    return function(dispatch){
        toggleLoader()
        return IntegrationApi.updateIntegration(data)
            .then(response => {
                dispatch(updateIntegrationSuccess(response))
            }).catch(error => {
                dispatch(generalError())
                throw(error)
            }).finally(() => {
                toggleLoader()
            }) 
    }
}

export function pageUpdateHandle(){
    return({type: 'PAGE_UPDATE'})
}

export function resetUpdate(){
    return({type: 'UPDATE_RESET'})
}

export function resetMessage(){
    return({type: 'RESET_MESSAGE'})
}

export function getAvailablePointsSuccess(points){
    return({type: 'GET_POINTS_SUCCESS', points})
}

export function getUserStationsSuccess(stations){
    return({type: 'GET_STATIONS_SUCCESS', stations})
}

export function setUserStationSuccess(response){
    return({type: 'SET_STATION_SUCCESS', response})
}

export function editUserStationSuccess(response){
    return({type: 'EDIT_STATION_SUCCESS', response})
}

export function deleteUserStationSuccess(response){
    return({type: 'DELETE_USER_SUCCESS', response})
}

export function updateIntegrationSuccess(response){
    return({type: 'UPDATE_INTEGRATION_SUCCESS', response})
}

export function generalError(){
    return({type: 'GENERAL_ERROR'})
}

