import AuthApi from '../api/auth'
import jwt from 'jsonwebtoken'
import {getFarms} from './farms'
import {toggleLoader} from './global'
import {getNeutralGrid,getXavierGrid} from './map'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const SET_LOGIN_ERRORS_TO_NULL = 'SET_LOGIN_ERRORS_TO_NULL'
export const SET_REMEMBER_ME = 'SET_REMEMBER_ME'

export function login(data, remember) {
  return function(dispatch){
    return AuthApi.login(data)
      .then(responseSession => {
        if(responseSession.status >= 200 && responseSession.status <= 300){
          if(remember){
            localStorage.setItem('Token', responseSession.authentication_token)
          }else{
            sessionStorage.setItem('Token', responseSession.authentication_token)
          }
          dispatch(getXavierGrid())
          dispatch(loginSuccess(jwt.decode(responseSession.authentication_token)))
          if(jwt.decode(responseSession.authentication_token)['type'] === 'bank'){
            dispatch(getNeutralGrid())
          }else{
            dispatch(getFarms(responseSession.authentication_token))
          }
        }else {
          dispatch(createSessionFailed(responseSession))
          dispatch(toggleLoader())
        }
      }).catch(error => {throw(error)})
  }
}

export function logout(){
  let token = localStorage.Token ? localStorage.Token : sessionStorage.Token
  return dispatch => {
    return AuthApi.logout(token).then(responseSession => {
      if(responseSession.status >= 200 && responseSession.status <= 300){
        if(localStorage.Token){
          localStorage.removeItem('Token')
        }else{
          sessionStorage.removeItem('Token')
        }
        dispatch(logoutSuccess())
      }else {
        dispatch(createSessionFailed(responseSession))
      }
    }).catch(error => {throw(error)})

  }
}

export function validateToken(){
  let token = localStorage.Token ? localStorage.Token : sessionStorage.Token
  return dispatch => {
    return AuthApi.validateToken(token).then(response =>{
      if(response.valid === true){
        dispatch(loginSuccess(jwt.decode(token)))
      }else{
        localStorage.removeItem('Token')
        sessionStorage.removeItem('Token')
        dispatch(logoutSuccess())
      }
    }).catch(error => {throw(error)})
  }
}


export function logoutSuccess(){
  return({
    type: 'LOGOUT_SUCCESS'
  })
}

export function setRememberMe(check){
  return({type: 'SET_REMEMBER_ME', check})
}

export function loginSuccess(data){
  return({type: 'LOGIN_SUCCESS', data})
}

export function createSessionFailed(data) {
  return({type: 'LOGIN_FAILED', data})
}

export function setLoginErrorsToNull(){
  return({type: 'SET_LOGIN_ERRORS_TO_NULL'})
}
