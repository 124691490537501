import MapApi from "../api/map";
import { toggleLoader } from "../actions/global";

export const MAP_CLICK_EVENT = "MAP_CLICK_EVENT";
export const MAP_CLICK_EDIT_EVENT = "MAP_CLICK_EDIT_EVENT";
export const XAVIER_BHBOX_EVENT = "XAVIER_BHBOX_EVENT";
export const CHANGE_MAP_DAY = "CHANGE_MAP_DAY";
export const GENERATE_MAP_DATA = "GENERATE_MAP_DATA";
export const LOAD_MAP_DATA = "LOAD_MAP_DATA";
export const SET_SHOW_LAT_LNG = "SET_SHOW_LAT_LNG";
export const RESET_GRID = "RESET_GRID";
export const SET_GRID_POINT_NAME = "SET_GRID_POINT_NAME";
export const SET_GRID_POINT_COORDS = "SET_GRID_POINT_COORDS";
export const SET_MAP_YEAR = "SET_MAP_YEAR";
export const XAVIER_GRID_SUCCESS = "XAVIER_GRID_SUCCESS";
export const SET_BHBOX_DATE_CLICK = "SET_BHBOX_DATE_CLICK";
export const GET_KMZ_SUCCESS = "GET_KMZ_SUCCESS";
export const KMZ_DELETE_SUCCESS = "KMZ_DELETE_SUCCESS";

export function generateMapData(reportTypes, attrs, abort) {
  return function (dispatch) {
    dispatch(toggleLoader());
    return MapApi.generateMapData(reportTypes, attrs, abort)
      .then((data) => {
        dispatch(mapDataSuccess(data));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        dispatch(toggleLoader());
      });
  };
}

export function getNeutralGrid() {
  return function (dispatch) {
    return MapApi.getNeutralGrid()
      .then((data) => {
        dispatch(mapDataSuccess(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getXavierGrid() {
  return function (dispatch) {
    return MapApi.getXavierGrid()
      .then((data) => {
        dispatch(xavierGridSuccess(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getKmzFile() {
  return function (dispatch) {
    return MapApi.getKmzFile()
      .then((data) => {
        dispatch(getKmzFileSuccess(data));
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function deleteKmzFile() {
  return function (dispatch) {
    return MapApi.deleteKmzFile()
      .then(() => {
        dispatch(deleteKmzSuccess());
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function allowXavierBhbox() {
  return { type: "XAVIER_BHBOX_EVENT" };
}

export function xavierGridSuccess(data) {
  return { type: "XAVIER_GRID_SUCCESS", data };
}

export function setMapYear(year) {
  return { type: "SET_MAP_YEAR", year };
}

export function setShowLatLng(lat, lng) {
  return { type: "SET_SHOW_LAT_LNG", lat, lng };
}

export function mapDataSuccess(data) {
  return { type: "LOAD_MAP_DATA", data };
}

export function allowMapClickEvent() {
  return { type: "MAP_CLICK_EVENT" };
}

export function allowMapClickEditEvent() {
  return { type: "MAP_CLICK_EDIT_EVENT" };
}

export function changeMapDay(day) {
  return { type: "CHANGE_MAP_DAY", day };
}

export function resetGrid() {
  return { type: "RESET_GRID" };
}

export function setGridPointName(name) {
  return { type: "SET_GRID_POINT_NAME", name };
}

export function setGridPointCoords(lat, lng) {
  return { type: "SET_GRID_POINT_COORDS", lat, lng };
}

export function setBhboxDateClick(dec, month, year) {
  return { type: "SET_BHBOX_DATE_CLICK", dec, month, year };
}

export function getKmzFileSuccess(payload) {
  return { type: "GET_KMZ_SUCCESS", payload };
}

export function deleteKmzSuccess() {
  return { type: "KMZ_DELETE_SUCCESS" };
}
