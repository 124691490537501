import { AGRYTOOLS } from "../shared/utils/consts";
import { authHeaderUpload } from "./configUpload";
import { authHeaderDownload } from "./configDownload";

class ReportsApi {
  static multipartPayload(attrs, type) {
    const formData = new FormData();

    for (let key in attrs) {
      if (attrs[key] instanceof Array) {
        formData.append(key, []); // Ensures sending if array is empty
        for (let arr in attrs[key]) {
          formData.append(key, attrs[key][arr]);
        }
      } else {
        formData.append(key, attrs[key]);
      }
    }
    formData.append("variable", type);
    return formData;
  }

  static spreadsheetGenerator(reportTypes, farmAttrs) {
    return fetch(process.env.REACT_APP_API + "api/agryindex", {
      method: "POST",
      headers: authHeaderDownload(),
      body: this.multipartPayload(farmAttrs, reportTypes[0]),
    })
      .then((response) => {
          response.blob().then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'new_file.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
        const data = response;
        return data;
      })
      .catch((error) => {
        console.log("API error:", error);
        return error;
      });
  }

  static generateReport(reportTypes, farmAttrs) {
    let bhboxType = ["bhbox", "extbh", "etretp", "excdef", "arm", "exc", "def"];
    let agryFireType = ["fmaac"];
    if (bhboxType.includes(reportTypes[0])) {
      return fetch(process.env.REACT_APP_API + "api/bhbox", {
        method: "POST",
        headers: authHeaderUpload(),
        body: this.multipartPayload(farmAttrs, reportTypes[0]),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.log("API error:", error);
          return error;
        });
    } else if (
      AGRYTOOLS.includes(reportTypes[0]) ||
      reportTypes[0] === "atrmax"
    ) {
      return fetch(process.env.REACT_APP_API + "api/agryindex", {
        method: "POST",
        headers: authHeaderUpload(),
        body: this.multipartPayload(farmAttrs, reportTypes[0]),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.log("API error:", error);
          return error;
        });
    } else if (agryFireType.includes(reportTypes[0])) {
      return fetch(process.env.REACT_APP_API + "api/agryfire", {
        method: "POST",
        headers: authHeaderUpload(),
        body: this.multipartPayload(farmAttrs, reportTypes[0]),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.log("API error:", error);
          return error;
        });
    } else if (reportTypes === "favo") {
      const token = localStorage.getItem("Token")
        ? localStorage.getItem("Token")
        : sessionStorage.getItem("Token");

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token} `);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      return fetch(
        `${process.env.REACT_APP_API}api/agryindex-compare?cad=${
          farmAttrs.cad
        }&lat=${farmAttrs.coords.split(",")[0]}&lon=${
          farmAttrs.coords.split(",")[1]
        }&point_id=${farmAttrs.farmId}&year=${farmAttrs.year}`,
        requestOptions
      )
        .then((response) => response.json())
        .catch((error) => {
          throw new Error(error);
        });
    } else {
      return fetch(process.env.REACT_APP_API + "api/agriweather", {
        method: "POST",
        headers: authHeaderUpload(),
        body: this.multipartPayload(farmAttrs, reportTypes[0]),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.log("API error:", error);
          return error;
        });
    }
  }
}

export default ReportsApi;
