import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { ResponsiveContainer, CartesianGrid, BarChart, Bar, XAxis, YAxis, Label, Legend, Tooltip } from "recharts"
import CustomizedAxisTick from "../reports/CustomizedAxisTick"

import { changeMapDay, generateMapData } from "../../actions/map"
import { generatingMapData, toggleLeftLoader } from "../../actions/global";

import { monthToNumber, leapYear, setDates } from  "../../shared/utils/DatesUtils"

const WIDTH_FACTOR = 0.653345582;
const FULLSCREEN_WIDTH_FACTOR = 0.960799385;
const DATE = new Date();
const CURRENT_MONTH = DATE.getMonth() + 1

const CustomTooltip = ({active, showTooltips, payload, label, weatherType, weatherRange, variable}) => {

  if(active && showTooltips) {
    if(payload !== null && payload.length > 0 && payload !== undefined) {
      if(weatherRange === "annualy") {
        return (
          <div className="custom-tooltip">
            <p className="label">{`${label}`}</p>
            <p style={{color: payload[0].stroke}} className="desc">{`${payload[0].value.toFixed(2)} mm`}</p>
          </div>
        )
      }else if(weatherType === "cmp"){
        return(
          <div className="custom-tooltip2">
            <p className="label">{`${label}`}</p>

            {payload[0] !== undefined ? payload.map((item,index) => {
              return(
                <p>
                  <p style={{color: item.stroke}} className="desc">{`${item.name}: ${item.value} mm`}</p>
                </p>
              )
            }
          )
          :
            <p>
              <p className="desc">"Something went wrong"</p>
            </p>
          }
          </div>
        )
      }else {
        let year = payload[0].payload['year']
        if(year === undefined){
          year = payload[0].name.split('_')[1]
        }
        return(
          <div className="custom-tooltip">
            <p className="label">{`${year? label + '/' + year : label}`}</p>
            <p style={{color: payload[0].stroke}} className="desc">{`${payload[0].value.toFixed(2)} mm`}</p>
          </div>
        )
      }
    }
  }
  return null;
}

const BhboxBarchart = ({
  showTooltips, graphicType, graphicRange, yearBegin, yearEnd, data, fullScreen,
  reportTypes, compareYears, tb, soil_texture, visualization_type, lastUsedCad, filterParams
}) => {
  const [filteredData, setFilteredData] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    let lastYear = new Date().getFullYear()
    let temporaryControl = true
    let filteredData = []
    let endOfSlice = graphicType === "period" && graphicRange === "daily"
      ? data.bhbox.length - 1 : data.bhbox.length

    data.bhbox.slice(0, endOfSlice).forEach((tempData) => {
      let values = {}
      let currentYear = tempData['year']

      if(graphicType === "period") {
        if(graphicRange === "annualy") {
          tempData["data"].forEach((graphicData) => {
            values["name"] = graphicData["name"]
            values[parseInt(graphicData["name"], 10) === lastYear || parseInt(graphicData["name"], 10) > lastYear ? reportTypes + "_" : reportTypes] = graphicData[reportTypes]
            values["year"] = currentYear
            filteredData.push(values)
            values = {}
          })
        }
        else {
          if(graphicRange === "daily") {
            if (tempData["year"].toString() === yearBegin && yearBegin === lastYear.toString()) {
              const last = tempData['data'].length - data.bhbox[data.bhbox.length - 1]['data'].length 
              tempData["data"].slice(0, last).forEach((graphicData) => {
                values["name"] = graphicData["name"]
                values[reportTypes] = graphicData[reportTypes]
                values["year"] = currentYear
                filteredData.push(values)
                values = {}
              })

              data.bhbox[data.bhbox.length - 1]['data'].forEach((graphicData) => {
                values["name"] = graphicData["name"]
                values[`${reportTypes}_${lastYear}`] = graphicData[reportTypes]
                values["year"] = currentYear
                filteredData.push(values)
                values = {}
              })
          } else if ((tempData["year"].toString() === yearBegin &&
            yearBegin === (lastYear - 1).toString()) &&
            visualization_type === 'agricultural') {
              const last = tempData['data'].length - data.bhbox[data.bhbox.length - 1]['data'].length 
              tempData["data"].slice(0, last).forEach((graphicData) => {
                values["name"] = graphicData["name"]
                values[reportTypes] = graphicData[reportTypes]
                values["year"] = currentYear
                filteredData.push(values)
                values = {}
              })

              data.bhbox[data.bhbox.length - 1]['data'].forEach((graphicData) => {
                values["name"] = graphicData["name"]
                values[`${reportTypes}_${CURRENT_MONTH < 7 ? lastYear - 1 : lastYear}`] = graphicData[reportTypes]
                values["year"] = currentYear
                filteredData.push(values)
                values = {}
              })
          } else if(tempData["year"].toString() === yearBegin) {
              tempData["data"].forEach((graphicData) => {
                values["name"] = graphicData["name"]
                values[reportTypes] = graphicData[reportTypes]
                values["year"] = currentYear
                filteredData.push(values)
                values = {}
              })
            } 
          } else {
            if(tempData["year"].toString() === yearBegin) {
              tempData["data"].forEach((graphicData) => {
                values["name"] = graphicData["name"]
                values[reportTypes] = graphicData[reportTypes]
                values["year"] = currentYear
                filteredData.push(values)
                values = {}
              })
            }
          }
        }
      }
      else if(tempData["year"] && ((compareYears.includes(tempData["year"].toString())) && graphicType === "cmp")) {
        let values = {}
        let currentYear = tempData["year"]

        tempData["data"].forEach((graphicData, index) => {
          if(temporaryControl) {
            values["name"] = graphicData["name"]
            values[currentYear] = graphicData[reportTypes]
            filteredData.push(values)
            values = {}

            if(leapYear(parseInt(currentYear, 10)) === false && graphicData["name"] === "28/02") {
              if(compareYears.length === 2) {
                if(leapYear(parseInt(compareYears[0], 10)) === true || leapYear(parseInt(compareYears[1], 10)) === true) {
                  values["name"] = "29/02"
                  filteredData.push(values)
                  values = {}
                }
              }
            }
          }
          else {
            if(graphicData["name"] === filteredData[index]["name"]) {
              filteredData[index][currentYear] = graphicData[reportTypes]
            }
            else {
              if(index+1 < filteredData.length) {
                filteredData[index+1][currentYear] = graphicData[reportTypes]
              }
            }
          }
        })
        temporaryControl = false
      }
    })

    setFilteredData(filteredData);
  }, [data, compareYears, graphicRange, graphicType, reportTypes, yearBegin, visualization_type])

  function onClick(e) {
    let newDay = {}
    let initial_date = ''
    let final_date = ''
    let variable = ''
    let dayAndMonth = undefined
    let precAux = false

    variable = reportTypes
    let year = Object.keys(e)[0]

    if(graphicRange === 'daily'){
      if(year === 'chartX'){
        if(precAux === true){
          precAux = false
        }else{
          if(graphicType === "period"){
            year = e.activePayload[0].payload.year
          }else {
            year = e.activePayload[0].name
          }

          dayAndMonth = e['activeLabel'].split('/')
          newDay['label'] = e.activeLabel
          newDay['year'] = year.toString()
          initial_date = year.toString() + '-' + dayAndMonth[1] + '-' + dayAndMonth[0]
          final_date = year.toString() + '-' + dayAndMonth[1] + '-' + dayAndMonth[0]
        }
      }else{
        if(graphicType === 'cmp'){
          year = e['value'] === e[Object.keys(e)[0]] ? year : Object.keys(e)[1]
        }
        dayAndMonth = e['name'].split('/')
        newDay['label'] = e.name
        newDay['year'] = e.year.toString()
        initial_date = e.year.toString() + '-' + dayAndMonth[1] + '-' + dayAndMonth[0]
        final_date = e.year.toString() + '-' + dayAndMonth[1] + '-' + dayAndMonth[0]
        precAux = true
      }
    }else if(graphicRange === 'decendial' || graphicRange === 'monthly' || graphicRange === 'annualy'){
      let dates = ''
      if(year === 'chartX'){
        if(precAux === true){
          precAux = false
        }else{
          if(graphicType === "period"){
            year = e.activePayload[0].payload.year
          }else {
            year = e.activePayload[0].name
          }
          
          dayAndMonth = e['activeLabel'].split('_')
          newDay['label'] = e.activeLabel
          newDay['year'] = year.toString()
          if(graphicRange === 'decendial'){
            dates = setDates(monthToNumber(dayAndMonth[0]), dayAndMonth[1], year)
          }else if(graphicRange === 'monthly'){
            dates = setDates(monthToNumber(dayAndMonth[0]), '4', year)
          }else if(graphicRange === 'annualy'){
            dates = setDates('', '5', year)
          }

          initial_date = dates[0]
          final_date = dates[1]
        }
      }else{
        if(graphicType === 'cmp'){
          year = e['value'] === e[Object.keys(e)[0]] ? year : Object.keys(e)[1]
        }
        if(year === 'name'){
          year = e['year']
        }
        dayAndMonth = e['name'].split('_')
        newDay['label'] = e.name
        newDay['year'] = year.toString()
        if(graphicRange === 'decendial'){
          dates = setDates(monthToNumber(dayAndMonth[0]), dayAndMonth[1], year)
        }else if(graphicRange === 'monthly'){
          dates = setDates(monthToNumber(dayAndMonth[0]), '4', year)
        }else if(graphicRange === 'annualy'){
          dates = setDates('', '5', year)
        }

        initial_date = dates[0]
        final_date = dates[1]
        precAux = true
      }
    }

    if(initial_date !== '' && final_date !== ''){
      let attrs = {
        initial_date: initial_date,
        final_date: final_date,
        type: graphicType,
        range: graphicRange,
        cad: lastUsedCad,
        tb: tb,
        soil: soil_texture,
        visualization_type
      }

      newDay['initial_date'] = initial_date
      newDay['final_date'] = final_date
      newDay['variable'] = variable.toLowerCase()
      dispatch(changeMapDay(newDay))
      dispatch(generatingMapData())
      dispatch(toggleLeftLoader())
      dispatch(generateMapData(variable.toLowerCase(), attrs))
    }
  }

  function setUnitLabel(){
    return "mm"
  }

  function setMaxBarSize(count){
      if(count < 225){
        return (window.innerWidth*0.48)/count
      }else if(count >= 225 && count < 315){
        return 0.1
      }else if(count >= 315){
        return 0.001
      }
  }

  function setGridValues(data) {
    let count = 0
    data.forEach(item => {
      count += 1
    })
    let intervalCurrentYear = 0
    let tickCountCurrentYear = 0
    for(let i = 1; i < count; i++){
      if(count%i === 0){
        if(count/i >= 10 && count/i <= 36){
          if(tickCountCurrentYear < count/i){
            intervalCurrentYear = i
            tickCountCurrentYear = count/i
          }
        }
      }
    }
    return [intervalCurrentYear, tickCountCurrentYear]
  }

  function setYDomain() {
    let domain = []

    if(reportTypes === "exc") {
      switch(graphicRange) {
        case "daily":
          domain = [0, 150, 11]
          break
        case "decendial":
          domain = [0, 180, 11]
          break
        case "monthly":
          domain = [0, 500, 11]
          break
        default:
          domain = [0, 1000, 11]
      }
    }else {
      switch(graphicRange) {
        case "daily":
          domain = [0, 12, 11]
          break
        case "decendial":
          domain = [0, 120, 11]
          break
        case "monthly":
          domain = [0, 300, 11]
          break
        default:
          domain = [0, 1000, 11]
      }
    }

    return domain;
  }
  
  function setBarGapSize(count){
      if(count < 225){
        return -(Math.floor((window.innerWidth*0.48)/count))
      }else if(count >= 225 && count < 315){
        return 0.1
      }else if(count >= 315){
        return 0.001
      }
  }

  function setColors(index){
    if(index === 0){
      return '#FF0000'
    }else if(index === 1){
      return '#0000FF'
    }else if(index === 2){
      return '#4daf4a'
    }else if(index === 3){
      return '#984ea3'
    }else if(index === 4){
      return '#ff7f00'
    }else if(index === 5){
      return '#ffff33'
    }else if(index === 6){
      return '#a65628'
    }else if(index === 7){
      return '#f781bf'
    }else if(index === 8){
      return '#999999'
    }else if(index === 9){

    }else if(index === 10){
      return '#666666'
    }
  }
  
  function setBars(begin, compareYears, data){
      let barsArray = []
      let bar = undefined
      let count = 0
      data.forEach(item => {
        count += 1
      })
      let maxSize = graphicRange === 'daily' ? setMaxBarSize(count) : 60    
      
      if(graphicType === 'period'){
        if(graphicRange === 'annualy'){
          let currentYear = new Date().getFullYear()
          let currentYearAgricultural = `${currentYear.toString().slice(2)}-${(currentYear + 1).toString().slice(2)}`
          data.forEach((year,index) => {
            if(parseInt(year.name, 10) === currentYear
              || year.name === currentYearAgricultural){
              bar = <Bar
                      key={year.name}
                      dataKey={reportTypes + "_"}
                      isAnimationActive={false}
                      onClick={onClick}
                      stroke={setColors(0)}
                      fill={setColors(0)}
                      unit={'mm'}
                    />
            }else {
              bar = <Bar
                key={year.name}
                dataKey={reportTypes}
                isAnimationActive={false}
                onClick={onClick}
                stroke={setColors(1)}
                fill={setColors(1)}
                unit={'mm'}
              />
            }
            barsArray.push(bar)
            bar = undefined
          })
        }else if(graphicRange === 'daily'){
          bar = <Bar key={reportTypes} dataKey={reportTypes} isAnimationActive={false} maxBarSize={maxSize} onClick={onClick} stroke={setColors(1)} fill={setColors(1)} unit={'mm'}/>
          barsArray.push(bar)
          bar = <Bar key={reportTypes + '_' + begin} dataKey={reportTypes + '_' + begin.toString()} isAnimationActive={false} maxBarSize={maxSize} onClick={onClick} stroke={setColors(0)} fill={setColors(0)} unit={'mm'}/>
          barsArray.push(bar)
        }else{
          bar = <Bar key={reportTypes} dataKey={reportTypes} isAnimationActive={false} maxBarSize={maxSize} onClick={onClick} stroke={setColors(1)} fill={setColors(1)} unit={'mm'}/>
          barsArray.push(bar)
        }
      }else if(graphicType === 'cmp'){
        compareYears.forEach((year,index) => {
          bar = <Bar key={year} dataKey={year.toString()} isAnimationActive={false} maxBarSize={maxSize} onClick={onClick} stroke={setColors(index)} fill={setColors(index)} unit={'mm'}/>
          barsArray.push(bar)
          bar = undefined
        })
      }
      return barsArray
  }

  function setGraphic(data){
    let bars = setBars(yearBegin, compareYears, data)
    let count = 0
    data.forEach(item => {
      count += 1
    })
    let barGap = graphicType === 'period'
      ? graphicRange === 'annualy'
      ? data.length < 30
        ? -36
        : -12
      : graphicRange === 'daily'
        ? setBarGapSize(count)
        : 10
      : 6
    let interval = graphicRange === 'daily' ? 'preserveStartEnd' : 0
    let domain = setYDomain()
    let tickCount = 36
    let currentYearGrid = setGridValues(data)
    if(currentYearGrid[0] !== 0 && graphicRange === 'daily'){
      interval = currentYearGrid[0]
      tickCount = currentYearGrid[1]
    }

    let legend = []

    if(graphicType === 'cmp'){
      legend = null
    }

    return(
      <ResponsiveContainer width="100%" height="95%">
        <BarChart data={data} barGap={barGap}>
          <XAxis
            interval={interval}
            dataKey="name"
            tick={<CustomizedAxisTick  graphicRange={graphicRange}/>}
            height={45}
            tickCount={tickCount}
          />
          <YAxis
            interval={0}
            tickCount={domain[2]}
            tick={{fontSize: '0.85rem'}}
            domain={[domain[0], domain[1]]}
          >
            <Label
              angle={-90}
              value={setUnitLabel(reportTypes)}
              position='insideLeft'
              offset={10}
              style={{textAnchor: 'middle'}}
            />
          </YAxis>
          <CartesianGrid strokeDasharray="1 1"/>
          <Tooltip
            content={ <CustomTooltip
              variable={reportTypes}
              weatherType={graphicType}
              weatherRange={graphicRange}
            /> }
            showTooltips={showTooltips}
            yearBegin={yearBegin}
          />
          { bars }
          <Legend
            margin={{top: 20, left: 0, right: 0, bottom: 0}}
            align='center'
            verticalAlign='top'
            payload= {legend}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  function setYearOrName(year, name) {
    if(year) {
      if (visualization_type !== 'civil') {
        return (`${year.toString().slice(2)}-${(year + 1).toString().slice(2)}`);
      }
      return year.toString();
    } else {
      return name.toString();
    }
  }

  const { startDate, endDate, startYear, endYear, startYearAgricultural, endYearAgricultural } = filterParams;

  const actualStartYear = visualization_type === 'civil' ? startYear : startYearAgricultural;
  const actualEndYear = visualization_type === 'civil' ? endYear : endYearAgricultural;

  const startingIndex = graphicRange !== 'annualy'
      ? filteredData.findIndex(({ name }) => name === startDate)
      : filteredData.findIndex(({ year, name }) => setYearOrName(year, name) === actualStartYear);
    const endingIndex = graphicRange !== 'annualy'
      ? filteredData.findIndex(({ name }) => name === endDate)
      : filteredData.findIndex(({ year, name }) => setYearOrName(year, name) === actualEndYear);

    const newFilteredData = filteredData.filter((_d, index) => index >= startingIndex && index <= endingIndex);

    let widthSize = fullScreen
      ? Math.round(window.innerWidth * FULLSCREEN_WIDTH_FACTOR)
      : Math.round(window.innerWidth * WIDTH_FACTOR);

  return (
      <div style={{height: '100%'}}>
          {setGraphic(newFilteredData, widthSize, reportTypes)}
      </div>
  )
}

export default BhboxBarchart;