import { authHeader } from "./config"

class AuthApi {
  static login(data) {
    return fetch(process.env.REACT_APP_API + 'api/auth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: data.identifier,
        password: data.password
      })
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }
  static logout(token) {
    return fetch(process.env.REACT_APP_API + 'api/logout', {
      method: 'GET',
      headers: authHeader(),
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }

  static validateToken(token) {
    return fetch(process.env.REACT_APP_API + 'api/validateToken', {
      method: 'GET',
      headers: authHeader(),
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }
}

export default AuthApi
