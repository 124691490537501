// https://mui.com/pt/components/menus/#positioned-menu
import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as S from './styles/AuditDropdown'

export default function AuditDropdown(prop) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const items = prop.items

  function menuItem (item, key) {
    return (
      <S.AuditDropdownLink
        key={key}
        to={item[1]}
      >
        <MenuItem onClick={handleClose}>
          {item[0]}
        </MenuItem>
      </S.AuditDropdownLink>
    )
  }

  return (
    <>
      <S.AuditDropdownButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {items[0]}
      </S.AuditDropdownButton>
      {
        items.length > 1 && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            { items[1].map( (item, key) => menuItem(item, key)) }
          </Menu>
        )
      }
    </>
  );
}


