import React, { Component } from 'react'
import validateInput from '../../shared/validators/login'

import TypesOption from './TypesOption'

class UserPlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userDescription: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onHover = this.onHover.bind(this)
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state)

    if(!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()
    if(this.isValid()) {
      this.setState({ errors: {}, isLoading: true })
      this.props.login(this.state)
    }
  }

  onHover(e){
    this.setState({
      userDescription: e.target.id
    })
  }

  render() {
    return (
      <div className="signin-modal-user-type">
        <div className="row" style={{ marginBottom: "0px" }}>
          <div className="types col s12">
            <TypesOption
              value={"basic"}
              icon={"person"}
              label={"Básico"}
              onHover={this.onHover}
              onClick={this.props.changeUserPlan}
            ></TypesOption>
            <TypesOption
              value={"premium"}
              icon={"people"}
              label={"Premium"}
              onHover={this.onHover}
              onClick={this.props.changeUserPlan}
            ></TypesOption>
            <TypesOption
              value={"smart premium"}
              icon={"people"}
              label={"Smart premium"}
              onHover={this.onHover}
              onClick={this.props.changeUserPlan}
            ></TypesOption>
          </div>
        </div>
        <div className="row button login-area-form-container-inside-buttons-row">
          <button
            className="btn-flat btn-drop"
            onClick={this.props.toggleSignIn}
          >
            VOLTAR
          </button>
        </div>
      </div>
    );
  }
}

export default UserPlan
