import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Grid, Button, withStyles, Modal } from "@material-ui/core"
import TrashIcon from "@material-ui/icons/Delete"
import { cloneDeep } from "lodash"
import AddIcon from "@material-ui/icons/Add"

import { adminGetPoints, adminUpdatePoints, resetServerMessage } from "../../../actions/admin"
import { uploadProcessLatLot } from '../../../api/uploadProcessLatLot';
import InputField from '../../common/InputField'
import ChangePlanModal from "./ChangePlanModal"
import EditPointsDropdown from './EditPointsDropdown'

import * as S from '../styles/PlanManagementModal'

const styles = {
    disabledButton: {
        backgroundColor: "gray"
    },
    select: {
        marginLeft: "8px",
        marginBottom: "20px",
        borderBottom: "2px solid #4CAF50",
    },
    input: {
        marginLeft: "8px",
    }
}

const PlanManagement = (props) => {
  const [tempPoints, setTempPoints] = useState([])
  const [pointsToCoords, setPointsToCoords] = useState([])
  const [lat, setLat] = useState("")
  const [lng, setLon] = useState("")
  const [update, setUpdate] = useState(false)
  const [didMount, setDidMount] = useState(false)
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch()
  const points = useSelector(state => state.admin.availablePoints)
  const serverMsg = useSelector(state => state.admin.serverMessage)    
  
  useEffect(() => {
    if(serverMsg !== ""){
      dispatch(resetServerMessage())
      setUpdate(true)
    }
  }, [dispatch, serverMsg])

  useEffect(() => {
    if(!didMount || update){
      dispatch(adminGetPoints(props.item.id))
      setUpdate(false)
      setDidMount(true)
    }
  }, [dispatch, didMount, update, props.item.id])
  
  useEffect(() => {
    const newPoints = [] 
    points.map(item => newPoints.push(item.coords))
    setTempPoints(newPoints)
    setPointsToCoords(newPoints)      
  }, [points])

  function addRow(){
    const point = lat + "," + lng
    if(tempPoints.find((item) => item === point) || lat === "" || lng === ""){
      return
    }
    setTempPoints([...tempPoints, point])
    setLat("")
    setLon("")
  }

  function deleteRow(index){
    let temp = cloneDeep(tempPoints)
    temp.splice(index, 1)
    setTempPoints(temp)
  }

  function savePointsChange() {
    let pointsToAdd = tempPoints.filter(coord => !pointsToCoords.includes(coord))
    let pointsToDelete = points.filter(point => !tempPoints.includes(point.coords))
    pointsToDelete.forEach((item, index) => pointsToDelete[index] = item.id)
    let data = {
      user_id: props.item.id.toString(),
      points_add: pointsToAdd,
      points_delete: pointsToDelete
    }

    dispatch(adminUpdatePoints(data))
  }

  async function onSubmitFile(fileCsv) {
    let responseUpdateFile = await uploadProcessLatLot(fileCsv);
    let points = []
    responseUpdateFile.data.forEach((latLon) => {
      const point = latLon.latitude + "," + latLon.longitude
      if(tempPoints.find((item) => item === point) || latLon.latitude === "" || latLon.longitude === ""){
        return null
      }
      points.push(point)
      console.log(points);
    })
    setTempPoints([...tempPoints, ...points])
};

  return (
    <Grid container className='plan-control' justify='space-between' alignItems='center'>
      <Grid item>
        <h5 className='title-row'>
          Planos de Usuário
        </h5>
      </Grid>
      <Grid item>
        <ChangePlanModal
         changeUserPlan={props.changeUserPlan}/>
      </Grid>
      <Grid container>
        <Grid item container spacing={16} xs={12} justify='flex-start'>
          <Grid item xs={3} className="api-key plan-header">
            <div className='plan-label'>Tipo de plano:</div>
            <InputField
              name='user-plan'
              type='text'
              value={ props.userPlanString }
              error={ undefined }
              active={true}
              activeFarm={""}
            />
          </Grid>
          {props.userPlan === "basic" ?
            <Grid item>
              <Button
                className='green-button api'
                variant='contained'
                onClick={() => setOpen(true)}>
                Cadastrar Marcadores
              </Button>
            </Grid>
          : null }
        </Grid>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        > 
        <S.PointsModalBox>
          <S.PlanModalIconButton>
            <i className='material-icons' onClick={()=> setOpen(false)}>close</i>
          </S.PlanModalIconButton>
          <EditPointsDropdown  
          onSubmitFile={onSubmitFile}
          />
          <S.latLongBox>
            <S.PointsModalGrid item container spacing={40}>
              <Grid item md={3} className="api-key">
                <S.PointsModalInput
                  name='lat'
                  label='Latitude'
                  type='text'
                  value={ lat }
                  error={ undefined }
                  onChange={(e) => setLat(e.target.value)}
                  active={true}
                  activeFarm={false}
                />
              </Grid>
              <Grid item md={3}>
                <S.PointsModalInput
                  name='lon'
                  label='Longitude'
                  type='text'
                  value={ lng }
                  error={ undefined }
                  onChange={(e) => setLon(e.target.value)}
                  active={true}
                  activeFarm={false}
                />
              </Grid>
              <S.PointsModalButtonIcon
                disabled={props.userPlan === "premium"} 
                className="green-button api" 
                onClick={addRow}
              >
                <AddIcon/>
              </S.PointsModalButtonIcon>
            </S.PointsModalGrid>
            {(props.userPlan === "basic" && tempPoints.length > 0) ? tempPoints.map((coords, index) => {
              return (
                <S.PointsModalGrid key={index} item container spacing={40}>
                  <Grid item sm={3} className="api-key">
                    <S.PointsModalInput
                    name='lat'
                    type='text'
                    value={ coords.split(",")[0] }
                    error={ undefined }
                    active={true}
                    activeFarm={''}
                  />
                  </Grid>
                  <Grid item sm={3}>
                    <S.PointsModalInput
                      name='lon'
                      type='text'
                      value={ coords.split(",")[1] }
                      error={ undefined }
                      active={true}
                      activeFarm={''}
                    />
                  </Grid>
                    <S.PointsModalButtonIcon  
                      className='green-button api' 
                      onClick={() => deleteRow(index)}
                    >
                      <TrashIcon/>
                    </S.PointsModalButtonIcon>
                </S.PointsModalGrid>
                )
              })
              : null}
          </S.latLongBox>
            <S.PointsModalGrid>
              <S.PointsModalButtonSave
                className='green-button'
                variant='contained'
                onClick={savePointsChange}>
                Salvar
              </S.PointsModalButtonSave>
            </S.PointsModalGrid>
          </S.PointsModalBox>
        </Modal>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(PlanManagement)