import React from 'react';

import { TableRow, Button } from '@mui/material';

import * as S from './styles/AutomaticAuditTable';

function AuditTableBody({ data, checks }) {
  const getRowTitle = (name) => {
    switch (name) {
      case 'weather': return 'Weather';
      case 'box': return 'Box';
      case 'index': return 'Index';
      case 'sugarcane': return 'Cana';
      case 'soy': return 'Soja';
      case 'corn': return 'Milho';
      case 'cotton': return 'Algodão';
      case 'wheat': return 'Trigo';
      case 'eucalyptus': return 'Eucalipto';
      case 'daily': return 'Dados diários';
      case 'prediction': return 'Previsão';
      case 'robots': return 'Coleta de dados';
      default: return 'Fire';
    }
  }

  const buildColumns = (rows, checkMarks) => {
    const columns = [];

    rows.forEach((name, i) => {
      const row = [getRowTitle(name)];

      checkMarks.forEach((e) => {
        let cont = 0;
        e[Object.keys(e)].forEach((d) => {
          if((d.product === data[i] || d.service === data[i]) && d.status) {
            cont+=1;
          }
        });
        if (cont) {
          row.push(["check_circle", '#6AA426']);
        } else {
          row.push(["cancel", '#FF0000' ]);
        }
      });

      columns.push(row);
    });

    return columns;
  }

  const columns = buildColumns(data, checks);

  return (
    columns.map((column, key) => {
      return (
        <TableRow key={key}>
          {
            column.map((value, k) => k === 0
              ? (
                <S.AutomaticAuditTableStub key={ k }>
                  {value}
                </S.AutomaticAuditTableStub>
              )
              : (
                <S.AutomaticAuditTableCell key={ k }>
                  <Button sx={{color: value[1], padding:'10px 5px'}}>
                    <i className='material-icons'>{ value[0] }</i>
                  </Button>
                </S.AutomaticAuditTableCell>
              )
            )
          }
        </TableRow>
      )
    })
  );
}

export default AuditTableBody;
