import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AssignmentReturned from "@material-ui/icons/AssignmentReturned";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import SwitchCameraIcon from "@material-ui/icons/SwitchCamera";
import { CloudUploadOutlined } from "@material-ui/icons";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class AdminSidebar extends Component{
  render(){
    return(
      <div className="row admin-sidebar">
        <div className="admin-sidebar-title">
          <img style={{width:'156px'}} src='/images/agrymax_branco.png' alt='agrymax-logo' onClick={() => window.location.reload()} className="brand-logo center landing-logo"/>
        </div>
        <div className="admin-sidebar-item">
          <Link to='/admin/users'>
            <AccountCircleIcon />
            <span>Usuários</span>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to='/admin/enos'>
            <CalendarTodayIcon />
            <span>Anos/Enos</span>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to='/admin/data-download'>
            <AssignmentReturned />
            <span>Download de Dados</span>
          </Link>
        </div>
        <div className='admin-sidebar-item'>
          <Link to='/admin/notifications'>
            <img className='custom-icon' src="/images/others/notifications_config.png" alt="An icon of a bell over an gear" />
            <span>Notificações de Atualização</span>
          </Link>
        </div>
        <div className='admin-sidebar-item'>
          <Link to='/admin/points-transfer'>
            <SwitchCameraIcon />
            <span>Transferir Pontos de Usuário</span>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to="/admin/user-export">
            <FolderSharedIcon />
            <span>Exportar Dados de Usuário</span>
          </Link>
        </div>
        <div className="admin-sidebar-item">
          <Link to="/admin/upload-file">
            <CloudUploadOutlined />
            <span>Upload Dados de Estações Validadas</span>
          </Link>
        </div>
      </div>
    )
  }
}

export default AdminSidebar
