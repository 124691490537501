import * as React from 'react';
import { Grid, Button, Modal } from "@material-ui/core"
import * as S from '../styles/ChangePlanModal'


export default function ChangePlanModal(props) {
  const [openPlan, setOpenPlan] = React.useState(false);

  function changePlan(plan) {
    props.changeUserPlan(plan)
    setOpenPlan(false)
  }

  return (
    <Grid item>
      <Button 
        onClick={() => setOpenPlan(true)}
        className="green-button api"
        variant="contained"
      >
        Mudar plano
      </Button>
      <Modal
        open={openPlan}
        // onClose={() => setOpenPlan(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <S.PlanModalBox>
          <Grid item container justify='center'>
          <S.PlanModalIconButton>
            <i className='material-icons' onClick={()=> setOpenPlan(false)}>close</i>
          </S.PlanModalIconButton>
            <S.PlanModalTypographyTitle  variant="h5" component="h2">
              Para qual plano gostaria de mudar?
            </S.PlanModalTypographyTitle >
          </Grid>
          <Grid item container justify='center'>
            <S.PlanModalTooltip title='Da acesso aos produtos weather, 
              box, index, fire e aos smarts liberados por pontos, 
              e não e possivel ver todo o Basil'
            >
              <S.PlanModalButton
                onClick={() => changePlan("basic")}
                className="green-button api"
                variant="contained"
              >
                Básico
              </S.PlanModalButton>
            </S.PlanModalTooltip >
            <S.PlanModalTooltip  title='Da acesso a todos os produtos do Básico
                e as produtividades dos pontos 
                escolhidos manualmente, é possivel ver todo o Brasil'>
              <S.PlanModalButton
                onClick={() => changePlan("premium")}
                className="green-button api"
                variant="contained"
              >
                Premium
              </S.PlanModalButton>
            </S.PlanModalTooltip >
            <S.PlanModalTooltip  title='Da acesso a todos os produtos do plano Premium
             mas libera automaticamente a produtividades para todos os da conta.'>
              <S.PlanModalButton
              onClick={() => changePlan("smart premium")}
              className="green-button api"
              variant="contained"
              >
                Smart Premium
              </S.PlanModalButton>
            </S.PlanModalTooltip >
          </Grid>
        </S.PlanModalBox>
      </Modal>
    </Grid>
  );
}

