import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import KmzTableRow from './KmzTableRow';
import CustomTablePagination from '../common/CustomTablePagination';
import DeleteModal from '../common/DeleteModal';

import { withStyles } from '@material-ui/core/styles';

import MapApi from '../../api/map';

import { getKmzFile } from '../../actions/map';
import { kmzDeleteSucess, kmzUpdateSucess } from '../../actions/profile';

const styles = (theme) => ({
  filesTable: {
    maxWidth: '70%',
    margin: 'auto',
  },
  tableCellHead: {
    backgroundColor: '#D1FCA0',
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '600',
    padding: '5px 23px 5px 23px',
  },
  tableCellBody: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#373737',
    padding: '5px 23px 5px 23px',
  },
  oddCell: {
    backgroundColor: '#EEEEEE',
  },
  evenCell: {
    backgroundColor: '#FFFFFF',
  },
  nameCell: {
    width: '80%',
  },
  iconsCell: {
    width: '20%',
  },
  icons: {
    backgroundColor: '#DDF8BF',
    color: '#373737',
    boxShadow: 'none',
    marginRight: '15px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  deleteModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '0.2px solid #707070',
    borderRadius: '16px',
    padding: '16px',
  },
  modalTitle: {
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    color: '#6AA426',
  },
  modalText: {
    fontSize: '1rem',
    fontFamily: 'Roboto',
    color: '#000000',
  },
  modalButtons: {
    margin: '20px 0',
    textAlign: 'center',
  },
  cancelButton: {
    backgroundColor: '#D2D2D2',
    borderRadius: '8px',
    color: '#373737',
    fontSize: '1.2rem',
    marginRight: '20px',
  },
  deleteButton: {
    backgroundColor: '#6AA426',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '1.2rem',
  },
});

function KmzFilesTable({ classes, setToaster }) {
  const [page, setPage] = useState(0);
  const [editId, setEditId] = useState(-1);
  const [deleteId, setDeleteId] = useState(-1);
  const [update, setUpdate] = useState('');
  const data = useSelector((state) => state.map.kmz);

  const dispatch = useDispatch();

  const handleChange = ({ target: { value } }) => {
    setUpdate(value);
  }

  const handleUpdate = (file) => {
    updateFile(file);
    setUpdate('');
  };

  const handleDelete = () => {
    return deleteFile(deleteId);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const updateFile = async (file) => {
    try {
      const data = await MapApi.updateKmzFile(editId, update);
      dispatch(kmzUpdateSucess(data));
      dispatch(getKmzFile());
      setToaster(true);
      setEditId(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteFile = async (id) => {
    try {
      const data = await MapApi.deleteKmzFile(id);
      dispatch(kmzDeleteSucess(data));
      dispatch(getKmzFile());
      setToaster(true);
      setDeleteId(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const findDeleteFileName = () => {
    return data.find(({id}) => id === deleteId)?.name;
  }

  const rowsPerPage = 5;

  return (
    <div style={ { marginTop: '20px' } }>
      <Table className={ classes.filesTable }>
        <TableHead>
          <TableRow table="head">
            <TableCell className={ [classes.tableCellHead, classes.nameCell ]}>
              Nome do Arquivo
            </TableCell>
            <TableCell className={ classes.tableCellHead }>
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <KmzTableRow
                  key={ row.id }
                  classes={ classes }
                  index={ index }
                  row={ row }
                  editId={ editId }
                  update={ update }
                  handleChange={ handleChange }
                  handleUpdate={ handleUpdate }
                  setDeleteId={ setDeleteId }
                  setEditId={ setEditId }
                />
              )
            )
          }
        </TableBody>
        <CustomTablePagination
          page={ page }
          rowsPerPage={ rowsPerPage }
          rows={ data }
          handleChangePage={ handleChangePage }
        />
      </Table>
      <DeleteModal
        deleteId={ deleteId }
        classes={ classes }
        handleDelete={ handleDelete }
        setDeleteId={ setDeleteId }
        title="Tem certeza que deseja excluir o arquivo KMZ?"
        text={ `Se exlcuir o arquivo ${findDeleteFileName()}, você não terá mais acesso às informações nele contidas` }
        confirmMsg="EXCLUIR ARQUIVO KMZ"
      />
    </div>
  );
}

export default withStyles(styles)(KmzFilesTable);
