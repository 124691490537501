import React from "react";
import { ResponsiveContainer, PieChart, Cell, Pie, Legend } from "recharts";

const customLabel = ({ cx, cy, midAngle, outerRadius, innerRadius, value }) => {
    const RADIAN = Math.PI / 180
    const startX = cx + (innerRadius * 1.2) * Math.cos(-midAngle * RADIAN)
    const startY = cy + (innerRadius * 1.2) * Math.sin(-midAngle * RADIAN)
    const midX = cx + (innerRadius * 1.4) * Math.cos(-midAngle * RADIAN)
    const midY = cy + (innerRadius * 1.4) * Math.sin(-midAngle * RADIAN)
    const endX = midX + (Math.cos(-midAngle * RADIAN) >= 0 ? 1 : -1) * 22
    const endY = midY
    const textAnchor = Math.cos(-midAngle * RADIAN) >= 0 ? "start" : "end"
    
    return (
        <g>
            <path d={`M${startX},${startY}L${midX},${midY}L${endX},${endY}`} stroke={"#333"} fill="none"/>
            <circle cx={endX} cy={endY} r={2} fill={"#333"} stroke="#333"/>
            <text x={endX + (Math.cos(-midAngle * RADIAN) >= 0 ? 1 : -1) * 6 } y={endY} textAnchor={textAnchor} fill="#333">{value}%</text>
        </g>
    )
} 

const DashboardPieChart = ({ data, label }) => {

    const colors =["#327C0E", "#57B828", "#9BFF5D"]

    return (
        <ResponsiveContainer width="100%" >
            <PieChart>
                <Pie 
                    data={data}
                    innerRadius={"60%"}
                    outerRadius={"70%"}
                    labelLine={false}
                    label={customLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={index} fill={colors[index]} />
                    ))}
                </Pie>
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default DashboardPieChart