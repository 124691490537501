import { authHeader } from './config'

class NotificationAPI {
    static getUserNotifications(id = "") {
        const path = id === ""
            ? `api/notification`
            : `api/notification?id=${id}`
            
        return fetch(process.env.REACT_APP_API + path, {
            method: "GET",
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static getAdminNotifications(id = "", data) {
        const path = data
            ? `api/admin/notifications?limit=${data.limit}&page=${data.page}`
            : id === "" && data === undefined
            ? `api/admin/notifications`
            : `api/admin/notifications?id=${id}`

        return fetch(process.env.REACT_APP_API + path, {
            method: "GET",
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static createNotification(data) {
        return fetch(process.env.REACT_APP_API + "api/admin/notifications", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                title: data.title,
                body: data.body,
                url: data.url
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static editNotification(data) {
        return fetch(process.env.REACT_APP_API + "api/admin/notifications", {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify({
                title: data.title,
                body: data.body,
                url: data.url,
                id: data.id
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static deleteNotification(id) {
        return fetch(process.env.REACT_APP_API + "api/admin/notifications", {
            method: "DELETE",
            headers: authHeader(),
            body: JSON.stringify({
                id: id
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }
}

export default NotificationAPI;