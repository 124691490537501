import React, { Component } from 'react'
import classnames from 'classnames'
import { rates } from '../../../lib/bhbox'

class IndicatorColor extends Component {
  getLevel(data, type, soil) {
    if(data === undefined || data === -1){
      return 8
    }else if(type === 'bhbox' || type === 'cresc' || type === 'extbh'){
      if (data > 80) { return 0 }
      else if (data <= 80 && data > 60) { return 1 }
      else if (data <= 60 && data > 40) { return 2 }
      else if (data <= 40 && data > 30) { return 3 }
      else if (data <= 30 && data > 20) { return 4 }
      else if (data <= 20 && data > 10) { return 5 }
      else if (data <= 10 && data >= 1) { return 6 }
      else if (data < 1) { return 7 }
      else if (data < 0) { return 8 }
    }else if(type === 'plan'){
      if(soil === 'veryClayey' || soil === 'clayey'){
        if(data <= 50 || data > 90) {return 9}
        else if(data > 50 && data <= 70) {return 10}
        else if(data > 70) {return 11}
      }else if(soil === 'sandyAverageTexture' || soil === 'sandy'){
        if(data <= 60 || data > 97) {return 9}
        else if(data > 60 && data <= 80) {return 10}
        else if(data > 80) {return 11}
      }else{
        if(data <= 55 || data > 95) {return 9}
        else if(data > 55 && data <= 75) {return 10}
        else if(data > 75) {return 11}
      }
    }else if(type === 'fert'){
      if(soil === 'veryClayey' || soil === 'clayey'){
        if(data <= 50 || data > 90) {return 9}
        else if(data > 50 && data <= 70) {return 10}
        else if(data > 70) {return 11}
      }else if(soil === 'sandyAverageTexture' || soil === 'sandy'){
        if(data <= 60 || data > 97) {return 9}
        else if(data > 60 && data <= 80) {return 10}
        else if(data > 80) {return 11}
      }else{
        if(data <= 55 || data > 95) {return 9}
        else if(data > 55 && data <= 75) {return 10}
        else if(data > 75) {return 11}
      }
    }else if(type === 'traf'){
      if(soil === 'veryClayey'){
        if(data <= 80) {return 11}
        else if(data > 80 && data <= 90) {return 10}
        else if(data > 90) {return 9}
      }else if(soil === 'clayey'){
        if(data <= 85) {return 11}
        else if(data > 85 && data <= 90) {return 10}
        else if(data > 90) {return 9}
      }else if(soil === 'sandyAverageTexture' || soil === 'sandy'){
        if(data <= 95) {return 11}
        else if(data > 95 && data <= 97) {return 10}
        else if(data > 97) {return 9}
      }else{
        if(data <= 90) {return 11}
        else if(data > 90 && data <= 95) {return 10}
        else if(data > 95) {return 9}
      }
    }else if(type === 'herb'){
      if(soil === 'veryClayey'){
        if(data <= 30) {return 12}
        else if(data > 30 && data <= 50) {return 13}
        else if(data > 50 && data <= 70) {return 15}
        else if(data > 70) {return 16}
      }else if(soil === 'clayey'){
        if(data <= 35) {return 12}
        else if(data > 35 && data <= 55) {return 13}
        else if(data > 55 && data <= 75) {return 15}
        else if(data > 75) {return 16}
      }else if(soil === 'mediumTexture'){
        if(data <= 40) {return 12}
        else if(data > 40 && data <= 60) {return 13}
        else if(data > 60 && data <= 80) {return 15}
        else if(data > 80) {return 16}
      }else if(soil === 'sandyAverageTexture'){
        if(data <= 45) {return 12}
        else if(data > 45 && data <= 65) {return 13}
        else if(data > 65 && data <= 85) {return 15}
        else if(data > 85) {return 16}
      }else{
        if(data <= 50) {return 12}
        else if(data > 50 && data <= 70) {return 13}
        else if(data > 70 && data <= 90) {return 15}
        else if(data > 90) {return 16}
      }
    }else if(type === 'ps'){
      if(soil === 'veryClayey'){
        if(data <= 25) {return 12}
        else if(data > 25 && data <= 30) {return 13}
        else if(data > 30 && data <= 75) {return 14}
        else if(data > 75 && data <= 85) {return 15}
        else if(data > 85) {return 16}
      }else if(soil === 'clayey'){
        if(data <= 30) {return 12}
        else if(data > 30 && data <= 35) {return 13}
        else if(data > 35 && data <= 80) {return 14}
        else if(data > 80 && data <= 90) {return 15}
        else if(data > 90) {return 16}
      }else if(soil === 'mediumTexture'){
        if(data <= 35) {return 12}
        else if(data > 35 && data <= 40) {return 13}
        else if(data > 40 && data <= 85) {return 14}
        else if(data > 85 && data <= 93) {return 15}
        else if(data > 93) {return 16}
      }else if(soil === 'sandyAverageTexture'){
        if(data <= 40) {return 12}
        else if(data > 40 && data <= 45) {return 13}
        else if(data > 45 && data <= 90) {return 14}
        else if(data > 90 && data <= 95) {return 15}
        else if(data > 95) {return 16}
      }else{
        if(data <= 45) {return 12}
        else if(data > 45 && data <= 50) {return 13}
        else if(data > 50 && data <= 95) {return 14}
        else if(data > 95 && data <= 97) {return 15}
        else if(data > 97) {return 16}
      }
    }else if(type === 'pulv'){
      if(data === 0) {return 9}
      else {return 11}
    }
  }

  render() {
    let level = this.getLevel(this.props.data, this.props.agrytoolsType, this.props.soil)

    return(
      <div className={classnames('bhbox--indicator-color',
                                {'inside-active-indicator': this.props.id === this.props.active},
                                {'bhbox--indicator-border': this.props.last },
                                {'first--indicator-prediction': this.props.prediction === 0 && this.props.agrytoolsType === 'bhbox'},
                                {'second--indicator-prediction': [1,2].includes(this.props.prediction) && this.props.agrytoolsType === 'bhbox'},
                                {'third--indicator-prediction': this.props.prediction === 3 && this.props.agrytoolsType === 'bhbox'},
                                {'first--indicator-prediction-detail': this.props.predictionDetail === 0 && this.props.agrytoolsType === 'bhbox'},
                                {'second--indicator-prediction-detail': [1,2].includes(this.props.predictionDetail) && this.props.agrytoolsType === 'bhbox'},
                                {'third--indicator-prediction-detail': this.props.predictionDetail === 3 && this.props.agrytoolsType === 'bhbox'},
                              )}
            id={this.props.id}
            data-level={ level }
            title={this.props.agrytoolsType === 'herb' || this.props.agrytoolsType === 'ps' ? rates[level][this.props.agrytoolsType] : rates[level]['title'] }
            onClick={level === 8 ? null : this.props.onClick}
            key={this.props.id}
      >
      </div>
    )
  }
}

export default IndicatorColor
