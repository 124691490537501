import React, { Component, Fragment } from 'react'
import validateInput from '../../shared/validators/passwordRecover'
import { connect } from 'react-redux'

//components
import InputField from '../common/InputField'

class RecoverPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identifier: '',
      errors: {}
    }
  }

  //Lifecycle methods
  componentWillUnmount(){
    this.props.setErrorsToNull()
  }

  //Helper methods
  isValid() {
    const { errors, isValid } = validateInput(this.state)

    if(!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  //#region Render methods
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    if(this.isValid()) {
      this.props.initRecoverPassword(this.state.identifier)
      this.props.toggleLoader()
    }
  }
  //#endregion

  render(){
    const {errors, identifier} = this.state
    return(
      <Fragment>
        <div className='login-area-form-container-inside-form-row'>
          {this.props.errors ?
            <div className="errors">
              {this.props.errors}
            </div> : null
          }
          <form onSubmit={ this.onSumbit }>
            <InputField
              name='identifier'
              label='Email'
              type='email'
              onChange={ this.onChange }
              value={ identifier }
              error={ errors.identifier }
              active={ identifier !== ''}
              loginInputActive={identifier !== ''}
              activeFarm={'a'}
            />
          </form>
        </div>
        <div className='login-area-form-container-inside-buttons-row'>
          <button onClick={this.onSubmit} className='btn wave-light agrymet-green space-between'>RECUPERAR SENHA</button>
          <button className='btn-flat btn-drop' onClick={this.props.toggleRecoverPassword}>CANCELAR</button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state){
  return{
    errors: state.users.errors,
  }
}

function mapDispatchToProps(dispatch){
  return{
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordForm)
