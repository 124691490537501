import React, { Component, Fragment } from "react";

import {
  CANE,
  SOY,
  CORN,
  COTTON,
} from "../reports/productivity/common/defaultValues";

import { DropdownFile } from "./DropdownFile";
import DownloadScreen from "./DownloadScreen";

import { downloadGeneralProductivityData } from "../../actions/admin";

import { PointsListNewComponent } from "./PointsListNewComponent";
import VariablesSelect from "./VariablesSelect";
import InputField from "../common/InputField";
import SearchDropdown from "../common/SearchDropdown";
import IndexForm from "./download-forms/IndexForm";
import ProductivityForm from "./download-forms/ProductivityForm";
import {
  uploadCSVWithAllInfos,
  uploadProcessLatLot,
} from "../../api/uploadProcessLatLot";
import { ToastContainer } from "react-toastify";
import { showToaster } from "../common/Toaster";
import Checkbox from "@material-ui/core/Checkbox";
import { Button, FormControlLabel, Grid } from "@material-ui/core";
import Select from 'react-select';

const customStyles = {
  option: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    width: "100%",
    height: "40px",
    display: "flex",
    borderBottom: state.isFocused
      ? "solid 2px #26a69a"
      : "solid 1px rgba(50, 0, 0, 0.4)",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  indicatorSeparator: () => ({}),
};

let DOWNLOAD_VARIABLES = [
  "tmax",
  "tmin",
  "p",
  "qg",
  "u2",
  "ur",
  "etp",
  "q0",
  "arm_25",
  "arm_50",
  "arm_75",
  "arm_100",
  "arm_125",
  "arm_150",
  "arm_200",
  "arm_250",
  "arm_300",
  "etr_25",
  "etr_50",
  "etr_75",
  "etr_100",
  "etr_125",
  "etr_150",
  "etr_200",
  "etr_250",
  "etr_300",
  "exc_25",
  "exc_50",
  "exc_75",
  "exc_100",
  "exc_125",
  "exc_150",
  "exc_200",
  "exc_250",
  "exc_300",
  "def_25",
  "def_50",
  "def_75",
  "def_100",
  "def_125",
  "def_150",
  "def_200",
  "def_250",
  "def_300",
];

let options = [];

export class DataDownloadRefactored extends Component {
  constructor(props) {
    super(props);

    this.state = {
      responseCsvWithAllLatAndLog: {},
      fileCsv: undefined,
      points: [],
      idsFarmsOfCheckbox: [],
      variables: [],
      initialDate: "",
      finalDate: "",
      lat: "",
      lon: "",
      dragPoint: undefined,
      datasetType: { value: "raw", label: "Dados brutos" },
      product: { value: "" },
      userData: { value: false, label: "Não" },
      selectedUser: { value: "" },
      selectedPoint: { value: -1 },
      required: {},
      toggleCheckboxValue: [],
      allOptionsCheckboxs: false,
      isFileReadyToDownloadPage: false,
      linkToDownload: "",
      isButtonToDownloadClicked: false,
      toggleGeneralProductivity: false,
      productivityCrop: { value: 'Atual', label: 'Atual' },
      visualizationType: { value: 'civil', label: 'Civil' },
    };
  }

  //#region Lifecycle methods

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.downloadDataReturn !== undefined) {
      this.props.resetDownload();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    //eslint-disable-next-line
    if (
      Object.keys(this.state.required).length !== 0 &&
      Object.keys(nextState.required).length === 0
    ) {
      this.setState({
        required: {},
      });
      return true;
    } else {
      return true;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userData !== this.state.userData && this.state.userData) {
      this.props.getUsersList();
    }
    if (prevState.selectedUser !== this.state.selectedUser) {
      this.setState(
        {
          points: [],
          selectedPoint: {},
          product: {},
        },
        () => {
          const data = {
            user_id: this.state.selectedUser.value,
            product: "cana",
          };
          this.props.getProductList(data);
        }
      );
    }
    if (prevState.datasetType.value !== this.state.datasetType.value) {
      this.setState({
        points: [],
        lat: "",
        lon: "",
      });
    }
    if (
      prevState.selectedPoint.value !== this.state.selectedPoint.value &&
      this.state.selectedPoint.value !== {}
    ) {
      this.setState({ points: [] }, () => {
        this.addSelectedPoint();
      });
    }
    if (
      this.state.points[0] &&
      prevState.points[0] !== this.state.points[0] &&
      this.state.datasetType.value !== "raw"
    ) {
      this.setState({
        lat: this.state.points[0].lat,
        lon: this.state.points[0].lng,
      });
    }
    if (prevState.datasetType !== this.state.datasetType) {
      this.setState({
        product: { value: "" },
      });
    }
  }

  showAlert = (msg) => {
    const toasterParams = {
      title: "Dados inválidos",
      message: msg,
      status: true,
      autoClose: true,
    };

    showToaster(toasterParams);
  };

  setFileCsvForUpload = (file) => {
    this.setState({
      fileCsv: file,
    });
  };

  onSubmitFile = async () => {
    let responseUpdateFile = undefined;
    if (this.state.datasetType.value === "productivity") {
      this.setState({ isFileReadyToDownloadPage: true });
      responseUpdateFile = await uploadCSVWithAllInfos(
        this.state.fileCsv,
        this.state.product.value
      );
      if (responseUpdateFile.message) {
        this.setState({ isFileReadyToDownloadPage: false });
      } else if (!responseUpdateFile.message) {
        this.setState({
          linkToDownload: responseUpdateFile,
          fileCsv: undefined,
        });
      }
    } else {
      responseUpdateFile = await uploadProcessLatLot(this.state.fileCsv);
      if (!responseUpdateFile.message) {
        const convertedLatLogObject = responseUpdateFile.data.map((latLog) => {
          return {
            lat: latLog.latitude,
            lng: latLog.longitude,
          };
        });
        this.setState({
          points: [...convertedLatLogObject, ...this.state.points],
          fileCsv: undefined,
          lat: "",
          lon: "",
          LATAdmin: "",
          LNGAdmin: "",
        });
      }
    }
    if (responseUpdateFile?.message) {
      return this.showAlert(responseUpdateFile.message);
    }
  };

  downloadForm() {
    switch (this.state.datasetType.value) {
      case "productivity":
        return (
          <Fragment>
            {this.state.product.value === "cane" && (
              <Fragment>
                {CANE.CYCLE_OPTIONS.forEach((option) =>
                  options.push({ state: "cycle", value: option, label: option })
                )}
                <div>
                  <ProductivityForm
                    setPageToDownload={this.setPageToDownload}
                    setLinkToDownload={this.setLinkToDownload}
                    isSendingCSV={this.state.fileCsv}
                    idsFarmsOfCheckbox={this.state.idsFarmsOfCheckbox}
                    cad={this.state.points[0] ? this.state.points[0].cad : 50}
                    points={this.state.points}
                    type={this.state.product.value}
                    cutNumber={CANE.CUT_NUMBER}
                    kdec={CANE.KDEC}
                    cycle={CANE.CYCLE}
                    ccUser={CANE.CC}
                    moisturePercent={CANE.U_PERCENT}
                    cycleOptions={options}
                    parentStatePoints={this.parentState}
                    pointsRequired={this.state.points}
                  />
                </div>
              </Fragment>
            )}
            {this.state.product.value === "cotton" && (
              <Fragment>
                {COTTON.CYCLE_OPTIONS.forEach((option) =>
                  options.push({ state: "cycle", value: option, label: option })
                )}
                <div>
                  <ProductivityForm
                    setPageToDownload={this.setPageToDownload}
                    setLinkToDownload={this.setLinkToDownload}
                    isSendingCSV={this.state.fileCsv}
                    idsFarmsOfCheckbox={this.state.idsFarmsOfCheckbox}
                    cad={this.state.points[0] ? this.state.points[0].cad : 50}
                    points={this.state.points}
                    type={this.state.product.value}
                    cycle={COTTON.CYCLE}
                    ccUser={COTTON.CC}
                    moisturePercent={COTTON.U_PERCENT}
                    cycleOptions={options}
                    parentStatePoints={this.parentState}
                    pointsRequired={this.state.points}
                  />
                </div>
              </Fragment>
            )}
            {this.state.product.value === "corn" && (
              <Fragment>
                {CORN.CYCLE_OPTIONS.forEach((option) =>
                  options.push({ state: "cycle", value: option, label: option })
                )}
                <div>
                  <ProductivityForm
                    setPageToDownload={this.setPageToDownload}
                    setLinkToDownload={this.setLinkToDownload}
                    isSendingCSV={this.state.fileCsv}
                    idsFarmsOfCheckbox={this.state.idsFarmsOfCheckbox}
                    cad={this.state.points[0] ? this.state.points[0].cad : 50}
                    points={this.state.points}
                    type={this.state.product.value}
                    cycle={CORN.CYCLE}
                    ccUser={CORN.CC}
                    moisturePercent={CORN.U_PERCENT}
                    cycleOptions={options}
                    parentStatePoints={this.parentState}
                    pointsRequired={this.state.points}
                  />
                </div>
              </Fragment>
            )}
            {this.state.product.value === "soy" && (
              <Fragment>
                {SOY.CYCLE_OPTIONS.forEach((option) =>
                  options.push({ state: "cycle", value: option, label: option })
                )}
                <div>
                  <ProductivityForm
                    setPageToDownload={this.setPageToDownload}
                    setLinkToDownload={this.setLinkToDownload}
                    isSendingCSV={this.state.fileCsv}
                    idsFarmsOfCheckbox={this.state.idsFarmsOfCheckbox}
                    cad={this.state.points[0] ? this.state.points[0].cad : 50}
                    points={this.state.points}
                    type={this.state.product.value}
                    cycle={SOY.CYCLE}
                    ccUser={SOY.CC}
                    moisturePercent={SOY.U_PERCENT}
                    cycleOptions={options}
                    parentStatePoints={this.parentState}
                    pointsRequired={this.state.points}
                  />
                </div>
              </Fragment>
            )}
          </Fragment>
        );
      case "index":
        return (
          this.state.product.value !== "" && (
            <div>
              <IndexForm
                setPageToDownload={this.setPageToDownload}
                setLinkToDownload={this.setLinkToDownload}
                isSendingCSV={this.state.fileCsv}
                idsFarmsOfCheckbox={this.state.idsFarmsOfCheckbox}
                points={this.state.points}
                cad={this.state.points[0] ? this.state.points[0].cad : 50}
                type={this.state.product.value}
                parentStatePoints={this.parentState}
                pointsRequired={this.state.points}
              />
            </div>
          )
        );
      default:
        return;
    }
  }

  selectPeriod = () => {
    return (
      <Fragment>
        <Grid container item justify="space-around" lg={9} xl={6}>
          <Grid item>
            {this.state.required.initialDate ? (
              <div style={{ color: "red" }}>*Escolha uma data inicial</div>
            ) : null}
            <InputField
              name="initialDate"
              label="Data Inicial"
              type="text"
              onChange={this.onChangeDate}
              value={this.state.initialDate}
              error={false}
              active={this.state.initialDate !== ""}
              activeFarm={"a"}
            />
          </Grid>
          <Grid item>
            {this.state.required.finalDate ? (
              <div style={{ color: "red" }}>*Escolha uma data Final</div>
            ) : null}
            <InputField
              name="finalDate"
              label="Data Final"
              type="text"
              onChange={this.onChangeDate}
              value={this.state.finalDate}
              error={false}
              active={this.state.finalDate !== ""}
              activeFarm={"a"}
            />
          </Grid>
          <Grid item>
            {this.state.required.variables ? (
              <div style={{ color: "red" }}>
                *Selecione pelo menos uma variável
              </div>
            ) : null}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <VariablesSelect
              options={DOWNLOAD_VARIABLES}
              onChange={this.selectVariable}
              variables={this.state.variables}
            />
          </Grid>
          <Grid item container justify="flex-end">
            <Grid item>
              <button
                className="btn wave-light green"
                onClick={this.onSubmit}
                style={{ margin: "0 50px 20px 0", fontSize: "12px" }}
              >
                DOWNLOAD
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  addPoint = (point) => {
    let points = this.state.points.slice();
    let dontAddPoint = false;

    this.state.points.map((item) => {
      return item.lat === point.lat && item.lng === point.lng
        ? (dontAddPoint = true)
        : null;
    });

    if (dontAddPoint) {
      return;
    }

    points.push(point);
    this.setState({
      points: points,
    });
  };

  addSelectedPoint = () => {
    const newPoint = this.props.points.filter(
      (point) => point.id === this.state.selectedPoint.value
    )[0];

    if (newPoint) {
      const coords = newPoint.coords.split(",");
      const point = {
        lat: coords[0],
        lng: coords[1],
        cad: newPoint.cad,
        id: newPoint.id,
      };
      this.addPoint(point, true);
    }
  };

  addInputPoint = (event) => {
    event.preventDefault();
    let pointLat = this.state.lat;
    let pointLon = this.state.lon;
    if (this.state.lat !== "" && this.state.lon !== "") {
      let needTreatmentLat = this.state.lat.split(",");
      let needTreatmentLon = this.state.lon.split(",");

      if (needTreatmentLat.length > 1) {
        pointLat = needTreatmentLat[0] + "." + needTreatmentLat[1];
      }
      if (needTreatmentLon.length > 1) {
        pointLon = needTreatmentLon[0] + "." + needTreatmentLon[1];
      }

      let point = {
        lat: pointLat,
        lng: pointLon,
      };

      this.addPoint(point);

      if (!this.state.userData.value) {
        return;
      }
      this.setState({
        lat: "",
        lon: "",
      });
    }
  };

  deleteSelectedItem = (tablePoint) => {
    let newPointsArray = this.state.points.filter((point) => {
      const currentStatePoint = `${point.lat} - ${point.lng}`;
      const currentTablePoint = `${tablePoint.lat} - ${tablePoint.lng}`;
      return currentStatePoint !== currentTablePoint;
    });
    this.setState({
      points: newPointsArray,
    });
  };

  setDragPoint = (point) => {
    this.setState({
      dragPoint: point,
    });
  };

  resolveDragPoint = (point) => {
    let index = this.state.points.findIndex(
      (point) =>
        point.lat === this.state.dragPoint.lat &&
        point.lng === this.state.dragPoint.lng
    );
    let newArray = this.state.points.slice();
    newArray[index] = point;
    this.setState({
      points: newArray,
      dragPoint: undefined,
    });
  };

  selectVariable = (event) => {
    let variablesArray = undefined;
    if (event.target["checked"]) {
      variablesArray = this.state.variables.slice();
      variablesArray.push(event.target.name);
      this.setState({
        variables: variablesArray,
      });
    } else {
      variablesArray = this.state.variables.filter(
        (variable) => variable !== event.target.name
      );
      this.setState({
        variables: variablesArray,
      });
    }
  };

  onSelectChange = (event) => {
    if (event.value === "raw") {
      this.setState({
        userData: {
          label: "Não",
          state: "userData",
          value: false,
        },
      });
    }
    this.setState({ [event.state]: event });
  };

  onChangeDate = (event) => {
    if (event.target.name === "LATAdmin") {
      this.setState({
        lat: event.target.value,
      });
    } else if (event.target.name === "LNGAdmin") {
      this.setState({
        lon: event.target.value,
      });
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  parentState = (childState) => {
    this.setState({
      required: {
        parentPoints: childState,
      },
    });
  };

  getDownload = () => {
    const { productivityCrop, visualizationType, idsFarmsOfCheckbox } = this.state;

    const data = {
      cropYear: productivityCrop.value === 'Atual'
        ? new Date().getFullYear() : new Date().getFullYear() + 1,
      visualizationType: visualizationType.value,
      points: idsFarmsOfCheckbox
    }

    this.setPageToDownload()
    downloadGeneralProductivityData(data)
      .then((response) => this.setLinkToDownload(response));
  }

  onSubmit = () => {
    let points = [];
    let pointsFlag = false,
      variablesFlag = false,
      initialDateFlag = false,
      finalDateFlag = false;
    // required fields to submit for download
    if (
      this.state.points.length !== 0 &&
      this.state.variables.length !== 0 &&
      this.state.initialDate !== "" &&
      this.state.finalDate !== ""
    ) {
      this.state.points.forEach((point) => {
        points.push(point.lat + "," + point.lng);
      });
      this.setState(
        {
          initialDate: this.state.initialDate.replace(/\//g, "-"),
          finalDate: this.state.finalDate.replace(/\//g, "-"),
          isFileReadyToDownloadPage: true,
        },
        async () => {
          this.setState({
            linkToDownload: await this.props.downloadData(
              points,
              this.state.variables,
              this.state.initialDate,
              this.state.finalDate
            ),
          });
        }
      );
    } else {
      if (this.state.points.length === 0) {
        pointsFlag = !pointsFlag;
      }
      if (this.state.variables.length === 0) {
        variablesFlag = !variablesFlag;
      }
      if (this.state.initialDate === "") {
        initialDateFlag = !initialDateFlag;
      }
      if (this.state.finalDate === "") {
        finalDateFlag = !finalDateFlag;
      }
      this.setState({
        required: {
          points: pointsFlag,
          variables: variablesFlag,
          initialDate: initialDateFlag,
          finalDate: finalDateFlag,
        },
      });
    }
  };

  setPageToDownload = () => {
    this.setState({
      isFileReadyToDownloadPage: true,
    });
  };
  setLinkToDownload = (link) => {
    this.setState({
      linkToDownload: link,
    });
  };

  downloadAndResetState = () => {
    this.setState({ isButtonToDownloadClicked: true }, () => {
      if (this.state.datasetType.value !== "raw") {
        window.open(this.state.linkToDownload.url, "_blank");
      }
      this.setState({
        isButtonToDownloadClicked: false,
        responseCsvWithAllLatAndLog: {},
        fileCsv: undefined,
        points: [],
        idsFarmsOfCheckbox: [],
        variables: [],
        initialDate: "",
        finalDate: "",
        lat: "",
        lon: "",
        toggleCheckboxValue: [],
        allOptionsCheckboxs: false,
        isFileReadyToDownloadPage: false,
        linkToDownload: "",
        toggleGeneralProductivity: false,
      });
    });
  };

  render() {
    return (
      <>
        <h5 style={{ marginLeft: "1%" }}>Download de Dados</h5>
        {!this.state.isFileReadyToDownloadPage ? (
          <>
            {" "}
            <ToastContainer
              style={{ marginTop: "36px", textAlign: "left", width: "376px" }}
            />
            <Grid container justify="space-around">
              <Grid item container lg={6} xl={6}>
                <Grid item lg={12} xl={12}>
                  <h6>Selecione o tipo de dados para download:</h6>
                  <SearchDropdown
                    name="datasetType"
                    className="dataset-select"
                    styles={customStyles}
                    value={this.state.datasetType}
                    onChange={this.onSelectChange}
                    isSearchable={false}
                    options={[
                      {
                        state: "datasetType",
                        value: "raw",
                        label: "Dados brutos",
                      },
                      {
                        state: "datasetType",
                        value: "productivity",
                        label: "Dados de produtividade",
                      },
                      {
                        state: "datasetType",
                        value: "index",
                        label: "Dados do agryindex",
                      },
                    ]}
                  />
                </Grid>
                {this.state.datasetType.value !== "raw" && (
                  <>
                    {!this.state.fileCsv ? (
                      <Grid item lg={12} xl={12}>
                        <h6>Usar dados de usuário?</h6>
                        <SearchDropdown
                          name="userData"
                          styles={customStyles}
                          value={this.state.userData}
                          onChange={this.onSelectChange}
                          isSearchable={false}
                          options={[
                            { state: "userData", value: false, label: "Não" },
                            { state: "userData", value: true, label: "Sim" },
                          ]}
                        ></SearchDropdown>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {this.state.userData.value ? (
                      <Grid item lg={12} xl={12}>
                        <h6>Usuário:</h6>
                        <SearchDropdown
                          name="selectedUser"
                          styles={customStyles}
                          value={this.state.selectedUser}
                          onChange={this.onSelectChange}
                          options={this.props.users.map((user) => ({
                            state: "selectedUser",
                            value: user.id,
                            label: user.email,
                          }))}
                        ></SearchDropdown>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item lg={12} xl={12}>
                      <h6>Produto:</h6>
                      <SearchDropdown
                        name="product"
                        className="prodtype-select"
                        styles={customStyles}
                        displayEmpty={true}
                        value={this.state.product}
                        onChange={this.onSelectChange}
                        isSearchable={false}
                        options={
                          this.state.datasetType.value === "productivity"
                            ? [
                                {
                                  state: "product",
                                  value: "cane",
                                  label: "Cana de açucar",
                                },
                                {
                                  state: "product",
                                  value: "soy",
                                  label: "Soja",
                                },
                                {
                                  state: "product",
                                  value: "corn",
                                  label: "Milho",
                                },
                                {
                                  state: "product",
                                  value: "cotton",
                                  label: "Algodão",
                                },
                              ]
                            : [
                                {
                                  state: "product",
                                  value: "plan",
                                  label: "Plantio",
                                },
                                {
                                  state: "product",
                                  value: "traf",
                                  label: "Tráfego",
                                },
                              ]
                        }
                      />
                    </Grid>
                  </>
                )}

                {!this.state.userData.value && (
                  <Grid item lg={12} xl={12}>
                    <DropdownFile
                      fileCsv={this.state.fileCsv}
                      setFileCsvForUpload={this.setFileCsvForUpload}
                      onSubmitFile={this.onSubmitFile}
                    />
                  </Grid>
                )}

                {!this.state.userData.value &&
                !this.state.fileCsv &&
                this.state.datasetType.value !== "raw" && (
                  <Grid
                    item
                    style={{ marginTop: "15px" }}
                    container
                    justify="space-around"
                  >
                    <Grid item>
                      <InputField
                        name="LATAdmin"
                        label="Latitude"
                        onChange={this.onChangeDate}
                        value={this.state.lat}
                        error={false}
                        active={this.state.lat !== ""}
                        activeFarm={"a"}
                      />
                    </Grid>
                    <Grid item>
                      <InputField
                        name="LNGAdmin"
                        label="Longitude"
                        value={this.state.lon}
                        error={false}
                        onChange={this.onChangeDate}
                        active={this.state.lon !== ""}
                        activeFarm={"a"}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={!this.state.LATAdmin || !this.state.LNGAdmin}
                        onClick={this.addInputPoint}
                        style={{
                          marginLeft: "8px",
                          fontSize: "30px",
                          color: "#6aa426",
                        }}
                      >
                        +
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {this.state.datasetType.value === "raw" && (
                  <Grid
                    item
                    style={{ marginTop: "15px" }}
                    container
                    justify="space-around"
                  >
                    <Grid item>
                      <InputField
                        name="LATAdmin"
                        label="Latitude"
                        onChange={this.onChangeDate}
                        value={this.state.lat}
                        error={false}
                        active={this.state.lat !== ""}
                        activeFarm={"a"}
                      />
                    </Grid>
                    <Grid item>
                      <InputField
                        name="LNGAdmin"
                        label="Longitude"
                        value={this.state.lon}
                        error={false}
                        onChange={this.onChangeDate}
                        active={this.state.lon !== ""}
                        activeFarm={"a"}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={!this.state.LATAdmin || !this.state.LNGAdmin}
                        onClick={this.addInputPoint}
                        style={{
                          marginLeft: "8px",
                          fontSize: "30px",
                          color: "#6aa426",
                        }}
                      >
                        +
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <h6>Pontos selecionados (Clique em um para deletar)</h6>
                <PointsListNewComponent
                  points={this.state.points}
                  deleteSelectedItem={this.deleteSelectedItem}
                />
              </Grid>
            </Grid>
            
            {this.state.datasetType.value === "raw" && (
              <>
                <h6 style={{ marginLeft: "4%" }}>
                  Selecione o periodo (No formato AAAA-MM-DD)
                </h6>
                {this.selectPeriod()}
              </>
            )}

            {this.state.userData.value && this.props.points.length !== 0 && (
              <div style={{ margin: "0 0 0 4%" }}>
                <h6>Pontos (selecione ao menos um ponto):</h6>
                <Grid container>
                  <Grid item lg={12} xl={12}>
                    <FormControlLabel
                      onChange={() => {
                        let points = [],
                          pointsId = [];
                        this.props.points.map((point, index) => {
                          points.push(index);
                          pointsId.push(point.id);
                          return index;
                        });
                        this.setState(
                          {
                            allOptionsCheckboxs:
                              !this.state.allOptionsCheckboxs,
                          },
                          () => {
                            if (this.state.allOptionsCheckboxs) {
                              this.setState({
                                toggleCheckboxValue: points,
                                idsFarmsOfCheckbox: pointsId,
                              });
                            } else {
                              this.setState({
                                toggleCheckboxValue: [],
                                idsFarmsOfCheckbox: [],
                              });
                            }
                            return;
                          }
                        );
                        return;
                      }}
                      label="Todos"
                      control={
                        <Checkbox
                          style={{
                            color: "#3EBCF6",
                          }}
                        />
                      }
                    />
                  </Grid>
                  {this.props.points.map((point, index) => {
                    return (
                      <Grid lg={3} xl={3} item key={index}>
                        <FormControlLabel
                          onChange={(event) => {
                            if (
                              event !== undefined &&
                              this.state.toggleCheckboxValue.includes(index)
                            ) {
                              this.setState({
                                toggleCheckboxValue:
                                  this.state.toggleCheckboxValue.filter(
                                    (checkboxIndex) => checkboxIndex !== index
                                  ),
                                idsFarmsOfCheckbox:
                                  this.state.idsFarmsOfCheckbox.filter(
                                    (id) => id !== point.id
                                  ),
                              });
                            } else {
                              this.setState({
                                toggleCheckboxValue: [
                                  ...this.state.toggleCheckboxValue,
                                  index,
                                ],
                                idsFarmsOfCheckbox: [
                                  ...this.state.idsFarmsOfCheckbox,
                                  point.id,
                                ],
                              });
                            }
                          }}
                          label={point.name ? point.name : point.initials}
                          control={
                            <Checkbox
                              style={{
                                color: "#3EBCF6",
                              }}
                              checked={this.state.toggleCheckboxValue.includes(
                                index
                              )}
                              value={point.coords}
                            />
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}
            
            {
              this.state.product.value === 'cane' && (
                <div style={{ margin: "0 0 0 4%", width: '40%' }}>
                  {
                    this.state.userData.value && (
                      <FormControlLabel
                        fontSize="20px"
                        onChange={ () => {
                          const { toggleGeneralProductivity } = this.state;
                          this.setState({ toggleGeneralProductivity: !toggleGeneralProductivity });
                        } }
                        label="Produtividade Geral"
                        control={
                          <Checkbox
                            style={{
                              color: "#3EBCF6",
                            }}
                            checked={ this.state.toggleGeneralProductivity }
                          />
                        }
                      />
                    )
                  }
                  {
                    this.state.toggleGeneralProductivity && (
                      <Grid container>
                        <Grid item md>
                          <h6>Selecionar Safra:</h6>
                          <Select
                            name="productivityCrop"
                            styles={ customStyles }
                            value={this.state.productivityCrop}
                            onChange={this.onSelectChange}
                            options={
                              [
                                { state: 'productivityCrop', value: 'Atual', label: 'Atual' },
                                { state: 'productivityCrop', value: 'Próxima', label: 'Próxima' },
                              ]
                            }
                          />
                        </Grid>
                        <Grid item md>
                          <h6>Selecionar ano:</h6>
                          <Select
                            name="visualizationType"
                            styles={ customStyles }
                            value={this.state.visualizationType}
                            onChange={this.onSelectChange}
                            options={
                              [
                                { 
                                  state: 'visualizationType',
                                  value: 'civil',
                                  label: 'Civil',
                                },
                                {
                                  state: 'visualizationType',
                                  value: 'agricultural',
                                  label: 'Agrícola',
                                },
                              ]
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <button
                            className="btn wave-light green"
                            onClick={ this.getDownload }
                            style={ { margin: '10px 0' } }
                          >
                            DOWNLOAD
                          </button>
                        </Grid>
                      </Grid>
                    )
                  }
                </div>
              )
            }

            { !this.state.toggleGeneralProductivity && this.downloadForm() }
          </>
        ) : (
          <DownloadScreen
            downloadLink={this.state.linkToDownload}
            resetState={this.downloadAndResetState}
            isClicked={this.state.isButtonToDownloadClicked}
            datasetTypeValue={this.state.datasetType.value}
          />
        )}
      </>
    );
  }
}
