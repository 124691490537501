import {
  GENERATE_REPORT,
  LOAD_REPORT,
  TOGGLE_FULL_SCREEN,
  TOGGLE_REPORT_CONTAINER,
  TOGGLE_YEARS_DROPDOWN,
  TOGGLE_FARMS_DROPDOWN,
  RESET_REPORT,
  SET_REPORT_TYPES,
  SET_WEATHER_TYPES,
  SET_LAST_USED_CAD,
  SET_LAST_VISUALIZED_CAD,
  SET_TBASAL,
  SET_LAST_USED_SOIL,
  TOGGLE_SOIL_DROPDOWN,
  SAVE_REPORT
} from '../actions/reports'

const initialState = {
  reportTypes: [],
  farmAttrs: {},
  report: undefined,
  storedReport: undefined,
  fullScreen: false,
  reportActive: false,
  yearsDropdown: false,
  farmsDropdown: false,
  soilDropdown: false,
  graphicType: undefined,
  graphicRange: undefined,
  lastUsedCad: '0',
  lastVisualizedCad: '0',
  lastUsedSoil: '',
  tBasal: 10
}

export default (state = initialState, action) => {
  switch(action.type) {
    case GENERATE_REPORT:
      return Object.assign({}, state,
        { reportTypes: action.reportTypes, farmAttrs: action.farmAttrs }
      )
    case SET_REPORT_TYPES:
      return Object.assign({}, state, {reportTypes: action.reportTypes})
    case LOAD_REPORT:
      return Object.assign({}, state, { report: action.report})
    case SAVE_REPORT:
      return Object.assign({}, state, { storedReport: action.report})
    case TOGGLE_FULL_SCREEN:
      return Object.assign({}, state, { fullScreen: !state.fullScreen })
    case TOGGLE_REPORT_CONTAINER:
      return Object.assign({}, state, { reportActive: !state.reportActive })
    case TOGGLE_YEARS_DROPDOWN:
      return Object.assign({}, state, { yearsDropdown: !state.yearsDropdown })
    case TOGGLE_FARMS_DROPDOWN:
      return Object.assign({}, state, { farmsDropdown: !state.farmsDropdown })
    case RESET_REPORT:
      return Object.assign({}, state, {report: undefined})
    case SET_WEATHER_TYPES:
      return Object.assign({}, state, {graphicType: action.graphicType, graphicRange: action.graphicRange})
    case SET_LAST_USED_CAD:
      return Object.assign({}, state, {lastUsedCad: action.cad})
    case SET_LAST_VISUALIZED_CAD:
      return Object.assign({}, state, {lastVisualizedCad: action.cad})
    case SET_TBASAL:
      return Object.assign({}, state, {tBasal: action.tb})
    case SET_LAST_USED_SOIL:
      return Object.assign({}, state, {lastUsedSoil: action.soil})
    case TOGGLE_SOIL_DROPDOWN:
      return Object.assign({}, state, {soilDropdown: !state.soilDropdown})
    default:
      return state
  }
}
