import React from 'react';

const AgryFireFooter = () => {
    return(
        <footer className='fire-indicators-labels'>
        <div className='indicators-labels--item'>
            <div className='label-text-unit'>
            <p>Risco de Incêndio</p>
            </div>
        </div>
        <div className='indicators-labels--item level-0'>
            <div className='label-color'></div>
            <div className='label-text'>
            <p>Nulo</p>
            </div>
        </div>
        <div className='indicators-labels--item level-1'>
            <div className='label-color'></div>
            <div className='label-text'>
            <p>Baixo</p>
            </div>
        </div>
        <div className='indicators-labels--item level-2'>
            <div className='label-color'></div>
            <div className='label-text'>
            <p>Médio</p>
            </div>
        </div>
        <div className='indicators-labels--item level-3'>
            <div className='label-color'></div>
            <div className='label-text'>
            <p>Alto</p>
            </div>
        </div>
        <div className='indicators-labels--item level-4'>
            <div className='label-color'></div>
            <div className='label-text'>
            <p>Muito Alto</p>
            </div>
        </div>
        <div className='indicators-labels--item level-5'>
            <div className='label-color'></div>
            <div className='label-text'>
            <p>Previsão</p>
            <p>de 30 dias</p>
            </div>
        </div>
        </footer>
    )
}

export default AgryFireFooter
