export var rates = {
  0: {
    title: "81 a 100% - Muito Favorável"
  },
  1: {
    title: "61 a 80% - Favorável"
  },
  2: {
    title: "41 a 60% - Razoável"
  },
  3: {
    title: "31 a 40% - Desfavorável"
  },
  4: {
    title: "21 a 30% - Crítico"
  },
  5: {
    title: "11 a 20% - Muito Crítico"
  },
  6: {
    title: "1 a 10% - Severo"
  },
  7: {
    title: "< 1% - Muito Severo"
  },
  8: {
    title: "Indefinido"
  },
  9: {
    title: "Desfavorável"
  },
  10: {
    title: "Razoável"
  },
  11: {
    title: "Favorável"
  },
  12: {
    herb: "Época Seca",
    ps: "Alto Risco de Desestruturação"
  },
  13: {
    herb: "Época Semi-Seca",
    ps: "Médio de Desestruturação"
  },
  14: {
    ps: "Favorável"
  },
  15: {
    herb: "Época Semi-Umida",
    ps: "Médio Risco de Compactação"
  },
  16: {
    herb: "Época Umida",
    ps: "Alto Risco de Compactação"
  }
}
