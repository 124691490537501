import { createTheme } from "@mui/material/styles";

const auditTheme = createTheme({
  typography: {
    auditHeader: {
      fontFamily: 'Open Sans',
      fontSize: '2rem',
      color: '#373737',
      textShadow: '0px 3px 6px #00000029',
    },
    auditParagraph: {
      fontFamily: 'Roboto',
      fontSize: '1.3rem',
      color: '#373737',
    },
    auditCaption: {
      color: '#707070',
      fontSize: '1rem',
    },
  },
});

export default auditTheme;
