import { Button, Checkbox, MenuItem, Select } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  setCropInfo,
  setMonthlyData,
  setMonthsForThirdStepTable,
  setMonthsWithDefaultValues,
} from "../../../../actions/GeneralCaneProductivityAction";
import ProductivityApi from "../../../../api/productivity";
import { showToaster } from "../../../../components/common/Toaster";

import { civilMonths, agriculturalMonths } from '../../../../shared/utils/months';

export function Step3GeneralCaneModal({ handleBack, handleNext, pointId }) {
  const [errorToPostAllTableData, setErrorToPostAllTableData] = useState("");

  const dispatch = useDispatch();
  const {
    monthlyData,
    monthsForThirdStepTable,
    period,
    cropYear,
    visualizationType,
    cropInfo,
    monthsWithDefaultValues,
  } = useSelector((state) => state.generalCaneProductivityReducer);

  useEffect(() => {
    const monthlyDataApiForThirdStep = async () => {
      const bodyObjectToRequest = {
        point_id: pointId,
        initial_month: period.initialMonth,
        final_month: period.finalMonth,
        crop_year: cropYear,
        visualization_type: visualizationType,
      };
      const { data } = await ProductivityApi.getCustomizedData(
        bodyObjectToRequest
      );

      dispatch(
        setCropInfo({
          kdec: data.kdec ? data.kdec : 0.22,
          cycle: data.cycle ? data.cycle : 12,
        })
      );
      dispatch(setMonthlyData(data.monthly_data ? data.monthly_data : {}));
    };
    monthlyDataApiForThirdStep();
  }, [
    cropYear,
    visualizationType,
    period.finalMonth,
    period.initialMonth,
    pointId,
    dispatch,
    monthsForThirdStepTable,
  ]);

  useEffect(() => {
    const months = visualizationType === 'civil' ? civilMonths : agriculturalMonths;

    const initialIndex = months.findIndex((month) => month.id === period.initialMonth);
    const finalIndex = months.findIndex((month) => month.id === period.finalMonth);
    const monthsForThirdStepTable = months.filter(
      (_month, index) => index >= initialIndex && index <= finalIndex
    );

    dispatch(setMonthsForThirdStepTable(monthsForThirdStepTable));
  }, [period.finalMonth, period.initialMonth, visualizationType, dispatch]);

  const handleUseDefaultValues = (event) => {
    const { name, value } = event.target;

    const defaultMonthlyData = {
      cc_user: 0.77,
      u_percent_user: 75,
      agry_eficiency: 0.7,
      first_cut_prop: 0.2,
      second_cut_prop: 0.2,
      third_cut_prop: 0.2,
      fourth_cut_prop: 0.2,
      fifth_or_plus_prop: 0.2,
      frost_flag: true,
    };

    if (name === "useDefaultValues" && !cropInfo.useDefaultValues) {
      const updatedMonths = monthsForThirdStepTable.map(({id}) => {
        dispatch(
          setMonthlyData({
            [id]: { ...monthlyData[id], ...defaultMonthlyData }
          }),
        );
        return id;
      });

      dispatch(setMonthsWithDefaultValues(updatedMonths));
    }

    if (name === "useDefaultValues" && cropInfo.useDefaultValues) {
      const monthlyDataToDeleteTheDefaultMonthWithDefaultValues = monthlyData;

      monthsForThirdStepTable.forEach(({ id }) => {
        if (monthsWithDefaultValues.includes(id)) {
          delete monthlyDataToDeleteTheDefaultMonthWithDefaultValues[id];
        }
      });

      dispatch(
        setMonthlyData(monthlyDataToDeleteTheDefaultMonthWithDefaultValues)
      );

      dispatch(setMonthsWithDefaultValues([]));
    }

    if (name !== undefined) {
      dispatch(
        setCropInfo({
          [name]:
            name === "useDefaultValues" ? !cropInfo.useDefaultValues : value,
        })
      );
    }
  };

  const handleInputTableChange = (event, monthIndex, divide) => {
    const { name, value } = event.target;
    dispatch(
      setMonthlyData({
        [monthIndex]: {
          ...monthlyData[monthIndex],
          [name]: divide && name !== "u_percent_user" ? value / 100 : value,
        },
      })
    );
  };

  const renderTableRow = (row, divider) => {
    return (
      <tr>
        {Object.entries(monthsForThirdStepTable).map((month) => {
          return (
            <td
              key={`${row}_${Number(month[1].id)}`}
              style={{ position: "relative", height: "76px" }}
            >
              <input
                type="text"
                name={row}
                style={{
                  marginBottom: "0px",
                  height: "1.8rem",
                }}
                value={
                  divider &&
                  row !== "u_percent_user" &&
                  monthlyData[month[1].id]
                    ? Object.keys(monthlyData[month[1].id]).includes(row) &&
                      Object.entries(monthlyData[month[1].id]).length !== 0
                      ? Math.round(monthlyData[month[1].id][row] * 100)
                      : ""
                    : monthlyData[month[1].id]
                    ? monthlyData[month[1].id][row]
                    : ""
                }
                onChange={(event) =>
                  handleInputTableChange(event, Number(month[1].id), divider)
                }
              />
              {divider && (
                <span
                  style={{
                    position: "absolute",
                    top: "28px",
                    right: "5px",
                  }}
                >
                  %
                </span>
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  const descriptionOfEachRowInTheThirdStepTable = [
    "Eficiência Agrícola",
    "Índice de colheita",
    "Umidade do colmo",
    "Cana planta",
    "Cana soca 1",
    "Cana soca 2",
    "Cana soca 3",
    "Cana soca 4",
  ];

  const cycles = [10, 11, 12, 13, 14, 15, 16, 17, 18];

  const showAlert = (msg) => {
    const toasterParams = {
      title: "Dados inválidos",
      message: msg,
      status: true,
      autoClose: true,
    };

    showToaster(toasterParams);
  };

  return (
    <>
      <Fragment>
        <div style={{ margin: "0 0 0 14%" }}>
          <label>
            <Checkbox
              name="useDefaultValues"
              checked={
                cropInfo.useDefaultValues ? cropInfo.useDefaultValues : false
              }
              value={
                cropInfo.useDefaultValues ? cropInfo.useDefaultValues : false
              }
              onClick={handleUseDefaultValues}
            />
            Preencher com valores padrões
          </label>
          <div style={{ display: "flex", margin: "16px 0 0 16px" }}>
            <div>
              <span style={{ fontSize: "16px" }}>Kdec</span>
              <input
                name="kdec"
                style={{
                  width: "20%",
                  marginBottom: "0",
                  marginTop: "0",
                  marginRight: "0",
                  marginLeft: "16px",
                  height: "1.8rem",
                  fontSize: "16px",
                  color: "#707070",
                }}
                value={cropInfo.kdec}
                onChange={handleUseDefaultValues}
              />
            </div>
            <div style={{ width: "30%" }}>
              <label>
                <span style={{ fontSize: "16px", color: "black" }}>Ciclo</span>
                <Select
                  id="cycle"
                  name="cycle"
                  value={cropInfo.cycle}
                  onChange={handleUseDefaultValues}
                  style={{
                    width: "20%",
                    margin: "0 0 0 16px",
                    color: "#707070",
                  }}
                >
                  {cycles.map((currentCycle) => (
                    <MenuItem
                      style={{ color: "#707070" }}
                      value={currentCycle}
                      key={currentCycle}
                    >
                      {currentCycle}
                    </MenuItem>
                  ))}
                </Select>
              </label>
            </div>
          </div>
        </div>
      </Fragment>
      <div style={{ display: "flex", margin: "36px 0 41px 0" }}>
        <div style={{ margin: "4.8% 0 0 0", width: "18%" }}>
          {descriptionOfEachRowInTheThirdStepTable.map((description, index) =>
            index === 0 ? (
              <p key={index}>{description}</p>
            ) : (
              <p key={index} style={{ margin: "54px 0 0 0" }}>
                {description}
              </p>
            )
          )}
        </div>
        <table>
          <thead>
            <tr>
              {monthsForThirdStepTable.map((month) => (
                <th
                  style={{
                    backgroundColor: "rgb(106, 164, 38)",
                    color: "white",
                    borderRadius: "0",
                  }}
                  key={month.name}
                >
                  {`${month.name.substring(3, 0)}.`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderTableRow("agry_eficiency", false)}
            {renderTableRow("cc_user", false)}
            {renderTableRow("u_percent_user", true)}
            {renderTableRow("first_cut_prop", true)}
            {renderTableRow("second_cut_prop", true)}
            {renderTableRow("third_cut_prop", true)}
            {renderTableRow("fourth_cut_prop", true)}
            {renderTableRow("fifth_or_plus_prop", true)}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "0 0.8% 18px 0",
        }}
      >
        {errorToPostAllTableData ? (
          <span style={{ color: "red" }}>ERROR: {errorToPostAllTableData}</span>
        ) : (
          <span></span>
        )}
        <Button
          style={{
            color: "#707070",
            width: "170px",
            height: "33px",
          }}
          disableRipple
          onClick={() => {
            dispatch(
              setCropInfo({
                useDefaultValues: false,
              })
            );
            handleBack();
          }}
        >
          Voltar
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(106, 164, 38)",
            color: "white",
            width: "170px",
            height: "33px",
          }}
          disableRipple
          variant="contained"
          onClick={async () => {
            const { kdec, cycle } = cropInfo;
            for (const object in monthlyData) {
              delete monthlyData[object].frost_flag;
              for (const objectWithMonthData in monthlyData[object]) {
                monthlyData[object][objectWithMonthData] = parseFloat(
                  monthlyData[object][objectWithMonthData]
                );
              }
            }
            const dataForPostThirdStep = {
              points_id: pointId,
              kdec,
              cycle,
              crop_year: cropYear,
              visualization_type: visualizationType,
              monthly_data: monthlyData,
            };
            const response = await ProductivityApi.postCustomizedData(
              dataForPostThirdStep
            );

            if (
              response.messages &&
              !response.messages.includes("Atualizações realizadas com sucesso")
            ) {
              showAlert(response.messages);
              setErrorToPostAllTableData(response.messages);
              setTimeout(() => {
                setErrorToPostAllTableData("");
              }, 8000);
            } else if (response.message) {
              showAlert("Error: Invalid data!");
              setErrorToPostAllTableData("Error: Invalid data!");
              setTimeout(() => {
                setErrorToPostAllTableData("");
              }, 8000);
            } else {
              handleNext();
            }
          }}
        >
          Próximo
        </Button>
        <ToastContainer
          style={{ marginTop: "36px", textAlign: "left", width: "376px" }}
        />
      </div>
    </>
  );
}
