import store from '../../store'

import { toggleReportContainer, resetReport, setReportTypes } from '../../actions/reports'
import { resetGrid } from '../../actions/map'
import { showToaster } from '../../components/common/Toaster'

export function activePointChange(prevProps, props, product) {
    if(prevProps.activeFarm !== props.activeFarm){
        let farms = props.farms.farms ? props.farms.farms : props.farms
        let newFarm = farms.find(farm => farm.id === props.activeFarm)
        let text
        switch(product){
          case 'soja':
            text = 'Soja'
            break
          case 'soy-productivity':
            text = 'Soja'
            break
          case 'soy-general-productivity':
            text = 'Soja'
            break
          case 'cana':  
            text = 'Cana'
            break
          case 'productivity-spec':
            text = 'Cana'
            break
          case 'milho':
            text = 'Milho'
            break
          case 'corn-general-productivity':
            text = 'Milho'
            break
          case 'corn-spec-productivity':
            text = 'Milho'
            break
          case 'algodao':
            text = 'Algodão'
            break
          case 'cotton-general-productivity':
            text = 'Algodão'
            break
          case 'cotton-spec-productivity':
            text = 'Algodão'
            break
          case 'trigo':
            text = 'Trigo'
            break
          case 'wheat-general-productivity':
            text = 'Trigo'
            break
          case 'wheat-spec-productivity':
            text = 'Trigo'
            break
          case 'eucalyptus-general-productivity':
            text = 'Eucalipto'
            break
          case 'eucalyptus-spec-productivity':
            text = 'Eucalipto'
            break
          default:
            text = ''
        }
        if(newFarm.smart && newFarm.smart.products.indexOf(text.toLowerCase()) > -1){
          return true
        }
        else{
          store.dispatch(setReportTypes(''))
          store.dispatch(resetReport())
          store.dispatch(toggleReportContainer())
          store.dispatch(resetGrid())
          
          const toasterParams = {
            title: "Erro!",
            message: `Você não tem acesso ao Smartcrops ${text} para esse ponto`,
            status: false,
            autoClose: true
          }
          showToaster(toasterParams)
          return false
        }
    }
}

