import UsersApi from '../api/users'
import jwt from 'jsonwebtoken'
import {getFarms} from './farms'
import {loginSuccess} from './login'
import {toggleLoader} from './global'

export const CREATE_USER_RESULT = 'CREATE_USER_RESULT'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const SET_ERRORS_TO_NULL = 'SET_ERRORS_TO_NULL'
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS'
export const CONFIRM_USER_FAILED = 'CONFIRM_USER_FAILED'
export const SET_CULTURES = 'SET_CULTURES'

export function createUser(data, userType, userPlan, cultures, smart) {
  return function (dispatch) {
    dispatch(toggleLoader());
    return UsersApi.createUser(data, userType, userPlan, cultures, smart)
      .then((result) => {
        dispatch(toggleLoader());
        if (result.status >= 200 && result.status <= 300) {
          dispatch(createUserResult(result));
        } else {
          dispatch(createUserFailed(result));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function initRecoverPassword(email) {
  return function(dispatch){
    return UsersApi.InitRecoverUserPassword(email)
      .then(result => {
        if(result.status >= 200 && result.status <= 300){
          dispatch(createUserResult(result))
        }else {
          dispatch(createUserFailed(result))
          dispatch(toggleLoader())
        }
      }).catch(error => {throw(error)})
  }
}

export function recoverPassword(password,passwordConfirm,token) {
  return function(dispatch){
    return UsersApi.recoverUserPassword(password,passwordConfirm,token)
      .then(result => {
        if(result.status >= 200 && result.status <= 300){
          dispatch(createUserResult(result))
        }else {
          dispatch(createUserFailed(result))
          dispatch(toggleLoader())
        }
      }).catch(error => {throw(error)})
  }
}

export function getCultures(){
  return function(dispatch){
    return UsersApi.getCultures()
      .then(cultures => {
        dispatch(setCultures(cultures))
      }).catch(error => {throw(error)})
  }
}


export function confirmUser(token) {
  return function(dispatch){
    return UsersApi.confirmUser(token)
      .then(result => {
        if(result.status >= 200 && result.status <= 300){
          sessionStorage.setItem('Token', result.token)
          dispatch(loginSuccess(jwt.decode(result.token)))
          dispatch(getFarms(result.token))
          dispatch(confirmUserSuccess(result))
        }else {
          dispatch(confirmUserFailed(result))
        }
      }).catch(error => {throw(error)})
  }
}

export function sendContactInfo(contact) {

  return function(dispatch){
    return UsersApi.sendUserContactInfo(contact)
    .then(result => { console.log(result) })
    .catch(error => {throw(error)});
  }

}

export function confirmUserSuccess(result) {
  return({type: 'CONFIRM_USER_SUCCESS', result})
}

export function setCultures(cultures){
  return({type: 'SET_CULTURES', cultures})
}

export function confirmUserFailed(result) {
  return({type: 'CONFIRM_USER_FAILED', result})
}

export function createUserResult(result){
  return({type: 'CREATE_USER_RESULT', result})
}

export function createUserFailed(result){
  return({type: 'CREATE_USER_FAIL', result})
}

export function setErrorsToNull(){
  return({type: 'SET_ERRORS_TO_NULL'})
}
