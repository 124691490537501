export var rates = {
  0: {
    title: "Nulo"
  },
  1: {
    title: "Baixo"
  },
  2: {
    title: "Médio"
  },
  3: {
    title: "Alto"
  },
  4: {
    title: "Muito Alto"
  },
  5: {
    title: "Indefinido"
  },
}
