import React, { useState, useEffect } from 'react'

import { createNotification, editNotification, resetNotifications } from "../../../actions/notification"

import { Grid, TextField, Button } from "@material-ui/core"

import YoutubePlayer from "../../common/YoutubePlayer"
import { useDispatch } from 'react-redux';

const NotificationForm = ({toggleForm, detailedNotification}) => {
    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [url, setUrl] = useState("");
    const [id, setId] = useState("")

    const dispatch = useDispatch();

    useEffect(() => {
        if(detailedNotification.length > 0) {
            setTitle(detailedNotification[0].title)
            setBody(detailedNotification[0].body)
            setUrl(detailedNotification[0].url)
            setId(detailedNotification[0].id)
        }

        return () => {
            setTitle("")
            setBody("")
            setUrl("")
            setId("")
        }
    }, [detailedNotification])

    function submitNotification(e) {
        const data = {
            title: title,
            body: body,
            url: url,
            id: id
        }
        if(detailedNotification.length > 0) {
            dispatch(editNotification(data))
        }
        else {
            dispatch(createNotification(data))
        }
        dispatch(resetNotifications())
        toggleForm(true)
    }

    return (
        <Grid container className="notification-form-container">
            <Grid item xs={12}>
                <h6>Inserir Título</h6>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    fullWidth
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        className: "notification-form-text-field"
                    }}
                    inputProps={{
                        className: "notification-form-input"
                    }}
                />
            </Grid>
            <Grid item xs={12} className="margin-16">
                <h6>Inserir Texto:</h6>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    fullWidth
                    multiline
                    rows={10}
                    rowsMax={10}
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        className: "notification-form-text-field"
                    }}
                    inputProps={{
                        className: "notification-form-input",
                        maxLength: 800
                    }}
                />
            </Grid>
            <Grid item xs={12} className="margin-16" >
                <h6>Link para o vídeo tutorial (opcional):</h6>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField 
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    fullWidth
                    variant="filled"
                    InputProps={{
                        disableUnderline: true,
                        className: "notification-form-text-field"
                    }}
                    inputProps={{
                        className: "notification-form-input"
                    }}
                />
            </Grid>
            <Grid item container xs={12} sm={6} justify="flex-end" alignContent="center">
                <Grid item container sm={3} justify="flex-end">
                    <Button onClick={toggleForm} style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                        <span>CANCELAR</span>
                    </Button>
                </Grid>
                <Grid item container sm={3} justify="flex-end">
                    <button className='btn wave-light green' onClick={submitNotification} style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                        <span>SALVAR</span>
                    </button>
                </Grid>
            </Grid>
            <Grid item xs={12} className="margin-16">
                <YoutubePlayer source={url} className="embed-content" />
            </Grid>
        </Grid>
    )
}

export default NotificationForm;