import React, { useState } from "react";

import ProfileAPI from "../../api/profile";

import { showToaster } from "../../components/common/Toaster";
import { ToastContainer } from "react-toastify";

import { Button, Dialog, Grid } from "@material-ui/core";
import "../../stylesheets/css/components/profile.css";

export function ChangePassword({
  isChangePasswordModalOpen,
  handleChangePasswordModalClose,
}) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const showAlert = (title, message) => {
    const toasterParams = {
      title,
      message,
      status: true,
      autoClose: false,
    };

    showToaster(toasterParams);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const handleSaveNewPassword = async (
    newPasswordParameter = newPassword,
    confirmNewPasswordParameter = confirmNewPassword
  ) => {
    if (newPasswordParameter !== confirmNewPasswordParameter) {
      showAlert("Dados inválidos" ,"Por favor, digite a mesma senha nos dois campos!");
      return;
    }
    const passwordAndConfirmPassword = {
      password: newPasswordParameter,
      confirm_password: confirmNewPasswordParameter,
    };
    const responseChangePassword = await ProfileAPI.changePassword(
      passwordAndConfirmPassword
    );
    if (responseChangePassword.message?.password) {
      showAlert("Dados inválidos", responseChangePassword.message.password);
      return;
    }
    setNewPassword("");
    setConfirmNewPassword("");
    showAlert("Feito!", "Senha alterada com sucesso!");
    handleChangePasswordModalClose();
  };

  return (
    <Dialog
      open={isChangePasswordModalOpen}
      onClose={handleChangePasswordModalClose}
      fullWidth
    >
      <Grid container alignItems="center" style={{ height: "350px" }}>
        <Grid item container sm={12} justify="center">
          <Grid item sm={8} style={{ textAlign: "center" }}>
            <p>Nova senha</p>
            <input
              type="password"
              style={{
                padding: "0 0 0 14px",
                border: "1px solid #6AA426",
                borderRadius: "4px",
              }}
              placeholder="Nova senha"
              onChange={handleNewPasswordChange}
              value={newPassword}
            />
            <p>Repetir nova senha</p>
            <input
              style={{
                padding: "0 0 0 14px",
                border: "1px solid #6AA426",
                borderRadius: "4px",
              }}
              type="password"
              placeholder="Repetir nova senha"
              onChange={handleConfirmNewPasswordChange}
              value={confirmNewPassword}
            />
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 50px 0 0",
          }}
        >
          <Button
            style={{
              backgroundColor: "#6AA426",
              color: "#FFF",
              width: "100px",
            }}
            onClick={() =>
              handleSaveNewPassword(newPassword, confirmNewPassword)
            }
          >
            Salvar
          </Button>
        </div>
      </Grid>
      <ToastContainer
        style={{ marginTop: "36px", textAlign: "left", width: "376px" }}
      />
    </Dialog>
  );
}
