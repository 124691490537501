import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

class LatLngShow extends Component {
  render() {
    return(
      <div className={classnames("lat-lng-show", {'lat-lng-report-open': this.props.reportActive})}>
        {this.props.showLat}  {this.props.showLng}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    showLat: state.map.showLat,
    showLng: state.map.showLng,
    reportActive: state.Reports.reportActive
  }
}

export default connect(mapStateToProps, null)(LatLngShow)
