import { connect } from 'react-redux'
import { loadProfile, loadPointData, deletePointRow, clearDeleteMessage, editPointRow, toggleLoader, resetPoints } from '../actions/profile'

import Profile from '../components/profile/Profile'

function mapStateToProps(state) {
    return {
      generalInfo: state.profile.generalInfo,
      uploadedFarms: state.profile.uploadedPoints,
      pagination: state.profile.pagination,
      points: state.profile.points,
      message: state.profile.message,
      loading: state.profile.loading,
      prevSelectedPoint: state.global.listedPoint
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      loadProfile: (isSidebar) => {
        dispatch(loadProfile(isSidebar))
      },
      loadPointData: (data) => {
        dispatch(loadPointData(data))
      },
      deletePointRow: (data) => {
        dispatch(deletePointRow(data))
      },
      clearDeleteMessage: () => {
        dispatch(clearDeleteMessage())
      },
      editPointRow: (data) => {
        dispatch(editPointRow(data))
      },
      toggleLoader: () => {
        dispatch(toggleLoader())
      },
      resetPoints: () => {
        dispatch(resetPoints())
      }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Profile)