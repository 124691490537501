import { Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';

export const AutoAuditBox = styled(Grid)({
  color: "#0F475A",
  backgroundColor: "#D1FCA0",
  margin: "50px 0 0 0",
  padding: '2% 5% 2% 5%',
  display: 'flex',
  justifyContent: 'center'
});

export const AutoAuditIcon = styled(Icon)({
  fontSize:'40px',
  margin: '10px 3px'
});

export const AutoAuditIconArrow = styled(Icon)({
  display: 'inline',
  fontSize:'70px',
  color: '#0F475A',
});

export const AutoAuditLink = styled(Link)({
  fontSize: '1.15rem', 
  color: '#0F475A',
  marginTop: '-20px',
  fontWeight: '500',
  '&:hover': {
    textDecoration: 'underline',
  }
});

export const AutoAuditP = styled("div")({
  fontSize: '1.15rem',
  margin: '10px 25px',
  color: '#373737',
});

export const AutoAuditBoxP = styled("div")({
  display: 'inline',
  fontSize: 16,
  color: '#373737',
});

export const AutoAuditCustomP = styled("div")({
  margin: '1px 1px 1px 25px',
  display: 'inline',
  fontSize: 22,
  fontWeight: '500',
  color: '#0A6482',
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  }
});

export const AutoAuditLP = styled("div")({
  display: 'inline',
  fontSize: '1.2rem', 
});

export const AutoAuditGridCenter = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '50px'
});

export const AutoAuditGridEnd = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const AutoAuditButton = styled(Button)({
  background: '#0F475A 0% 0% no-repeat padding-box',
  opacity: 1,
  borderRadius: '10px',
  color: '#FFFFFF',
  padding: '10px 50px',
  font: 'Roboto',
  margin: '10px 25px',
  fontWeight: 'bold',
  '&:hover': {
    background: '#0F475A',
    boxShadow: '0px 3px 6px #00000029',
  }
});



