import React from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem } from '@material-ui/core';

function ScheduleTableSelect({ value, name, handleChange, items }) {
  return (
    <Select
      value={ value }
      name={ name }
      onChange={ handleChange }
    >
      { items.map(({ value, label }) => <MenuItem value={ value }>{ label }</MenuItem>) }
    </Select>
  );
}

ScheduleTableSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  item: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string, label: PropTypes.string,
  })),
}.isRequired;

export default ScheduleTableSelect;
