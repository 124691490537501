export const SET_IS_USING_MODAL = "SET_IS_USING_MODAL";
export const SET_IS_MODAL_OPEN = "SET_IS_MODAL_OPEN";
export const SET_CROP_YEAR = "SET_CROP_YEAR";
export const SET_VISUALIZATION_TYPE = "SET_VISUALIZATION_TYPE";
export const SET_INITIAL_PERIOD = "SET_INITIAL_PERIOD";
export const SET_FINAL_PERIOD = "SET_FINAL_PERIOD";
export const SET_MONTHLY_DATA = "SET_MONTHLY_DATA";
export const SET_CROP_INFO = "SET_CROP_INFO";
export const SET_MONTHS_FOR_THIRD_STEP_TABLE =
  "SET_MONTHS_FOR_THIRD_STEP_TABLE";
export const SET_MONTHS_WITH_DEFAULT_VALUES = "SET_MONTHS_WITH_DEFAULT_VALUES";
export const SET_IS_THIRD_STEP_BUTTON_DISABLED =
  "SET_IS_THIRD_STEP_BUTTON_DISABLED";
export const SET_TCH = "SET_TCH";
export const SET_USE_PRECIOUS_CROP = "SET_USE_PRECIOUS_CROP";
export const RESET_MODAL_STATE = "RESET_MODAL_STATE";


export function setIsUsingCustomizedModal(isUsingCustomizedModal) {
  return { type: SET_IS_USING_MODAL, isUsingCustomizedModal };
}

export function setIsModalOpen(isModalOpen) {
  return { type: SET_IS_MODAL_OPEN, isModalOpen };
}

export function setCropYear(cropYear) {
  return { type: SET_CROP_YEAR, cropYear };
}

export function setVisualizationType(visualizationType) {
  return { type: SET_VISUALIZATION_TYPE, visualizationType };
}

export function setInitialPeriod(initialMonth) {
  return { type: SET_INITIAL_PERIOD, initialMonth };
}

export function setFinalPeriod(finalMonth) {
  return { type: SET_FINAL_PERIOD, finalMonth };
}

export function setMonthlyData(monthlyData) {
  return { type: SET_MONTHLY_DATA, monthlyData };
}

export function setCropInfo(cropInfo) {
  return { type: SET_CROP_INFO, cropInfo };
}
export function setMonthsForThirdStepTable(monthsForThirdStepTable) {
  return { type: SET_MONTHS_FOR_THIRD_STEP_TABLE, monthsForThirdStepTable };
}

export function setMonthsWithDefaultValues(monthsWithDefaultValues) {
  return { type: SET_MONTHS_WITH_DEFAULT_VALUES, monthsWithDefaultValues };
}

export function setIsThirdStepButtonDisabled(isThirdStepButtonDisabled) {
  return { type: SET_IS_THIRD_STEP_BUTTON_DISABLED, isThirdStepButtonDisabled };
}

export function setTch(tch) {
  return { type: SET_TCH, tch };
}

export function setUsePreviousCrop(usePreviousCrop) {
  return { type: SET_USE_PRECIOUS_CROP, usePreviousCrop };
}

export function resetModalState() {
  return { type: RESET_MODAL_STATE };
}
