import { Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Box, styled, tooltipClasses } from '@mui/material';

export const PlanModalBox = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '5px',
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height: 350,
  overflow: 'y'
});

export const PlanModalTypography = styled(Typography)({
  margin: '30px',
  width: 220,
  textAlign: 'center'
});

export const PlanModalTypographyTitle = styled(Typography)({
  fontSize: '1.64rem',
  margin: '50px 30px 20px 30px',
  textAlign: 'center',
  width: 280,
});

export const PlanModalButton = styled(Button)({
  width: '160px',
  size: '10px',
  backgroundColor: 'rgb(106, 164, 38)',
  color: 'white',
  padding: '5px 0px',
  margin: '12px 5px'
});

export const PlanModalTooltip = styled(Tooltip)({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 30,
  },
});

export const PlanModalIconButton = styled(IconButton)({
  margin: '0px 0px 0px 250px',
  position: 'fixed',
});
