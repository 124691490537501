import { connect } from 'react-redux'
import LoginPage from '../components/login/LoginPage'

import {setErrorsToNull, initRecoverPassword, sendContactInfo} from '../actions/users'
import {toggleLoader} from '../actions/global'

function mapStateToProps(state) {
  return {
    isLogged: state.auth.isLoggedIn,
    loader: state.global.loader,
    createResult: state.users.createResult
  }
}

function mapDispatchToProps(dispatch){
  return {
    setErrorsToNull: () => {
      dispatch(setErrorsToNull())
    },
    initRecoverPassword: (email) => {
      dispatch(initRecoverPassword(email))
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    sendContactInfo: (contact) => {
      dispatch(sendContactInfo(contact))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
