import React, { Fragment } from 'react'
import { Route,Redirect, Switch } from 'react-router'
import jwt from 'jsonwebtoken'

import Root from './containers/Root'
import Login from './containers/LoginContainer'
import ConfirmUser from './containers/ConfirmUser'
import RecoverPassword from './containers/RecoverPassword'
import UploadData from './containers/UploadData'
import ProfileData from './containers/ProfileData'
import IntegrationData from './containers/IntegrationData'

import AdminHomePage from './components/admin/AdminHomePage'
import AdminSidebar from './components/admin/AdminSidebar'
import AdminUserList from './components/admin/AdminUserList'
import AdminEditUser from './components/admin/AdminEditUser'
import AdminEnosList from './components/admin/AdminEnosList'
import AdminAddUser from './components/admin/AdminAddUser'
import AdminAddEnos from './components/admin/AdminAddEnos'
import AdminDataDownload from './components/admin/AdminDataDownload'
import AdminNotifications from './components/admin/notifications/AdminNotifications'
import AdminPointsTransfer from './components/admin/transfer/AdminPointsTransfer'
import UserDataExport from './components/admin/UserDataExport'
import UploadDataFromValidateStations from './components/admin/UploadDataFromValidatedStations'

import AuditHome from './components/audit/AuditHome'

import EmailAuthorization from './components/EmailAuthorization';

let token = localStorage.Token ? localStorage.Token : sessionStorage.Token

const PrivateAdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    (localStorage.Token || sessionStorage.Token) && jwt.decode(token)['admin'] === 'True' ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

const PrivateUserRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    (localStorage.Token || sessionStorage.Token) ? (
      <Component {...props}/>
    ) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }}/>
    )
  )}/>
)

export default (
    <Switch>
      <Route path='/confirm/:token' component={ConfirmUser}/>
      <Route exact path='/reset/:token' component={RecoverPassword}/>
      <Route path='/login' exact render={() =>
        localStorage.Token || sessionStorage.Token ?
        <Redirect to='/'/>
        :
        <Route path='/login' component={Login}/>
      }/>
      <Route path='/api/email_authorization' component={ EmailAuthorization } />

      <PrivateUserRoute path='/' component={Root} exact/>
      <PrivateUserRoute path='/upload' component={UploadData} exact/>
      <PrivateUserRoute path='/audit/:page' component={AuditHome}/>
      <PrivateUserRoute path='/user-data' component={ProfileData} exact/>
      <PrivateUserRoute path='/integration' component={IntegrationData} exact/>

      <Fragment>
        {window.location.pathname.includes('admin') ? <AdminSidebar /> : null}

        <PrivateAdminRoute path='/admin' component={AdminHomePage} exact/>
        <PrivateAdminRoute path='/admin/users' component={AdminUserList} exact/>
        <PrivateAdminRoute path='/admin/users/add' component={AdminAddUser}/>
        <PrivateAdminRoute path='/admin/users/edit/:id' component={AdminEditUser}/>
        <PrivateAdminRoute path='/admin/enos' component={AdminEnosList} exact/>
        <PrivateAdminRoute path='/admin/enos/add' component={AdminAddEnos} />
        <PrivateAdminRoute path='/admin/enos/edit/:year' component={AdminAddEnos} />
        <PrivateAdminRoute path='/admin/data-download' component={AdminDataDownload} />
        <PrivateAdminRoute path='/admin/notifications' component={AdminNotifications} />
        <PrivateAdminRoute path='/admin/points-transfer' component={AdminPointsTransfer} />
        <PrivateAdminRoute path='/admin/user-export' component={UserDataExport} />
        <PrivateAdminRoute path='/admin/upload-file' component={UploadDataFromValidateStations} />
      </Fragment>
    </Switch>
)
