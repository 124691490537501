import React, { Component } from 'react';

const HUGE_X_AXIS_ITEM_GRAPHIC_RANGES = ["annualy", "decendial"];

export default class CustomizedAxisTick extends Component {
  render () {
    const {x, y, payload, graphicRange} = this.props;

    let fontSize = "1em";

    if (HUGE_X_AXIS_ITEM_GRAPHIC_RANGES.includes(graphicRange)) {
      if (window.innerWidth <= 1000)
        fontSize = "0.7em";
      else if (window.innerWidth <= 1080)
        fontSize = "0.75em";
      else if (window.innerWidth <= 1130)
        fontSize = "0.8em";
      else if (window.innerWidth <= 1200)
        fontSize = "0.85em";
      else if (window.innerWidth <= 1250)
        fontSize = "0.9em";
      else if (window.innerWidth <= 1350)
        fontSize = "0.95em";
    }

   	return (
    	<g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={fontSize}>{payload.value}</text>
      </g>
    )
  }
}