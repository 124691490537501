import { 
  GET_ALL_USERS_SUCCESS, 
  SET_USERS_STATUS_SUCCESS, 
  EDIT_USER_SUCCESS, 
  START_DOWNLOAD,
  RESET_DOWNLOAD, 
  GET_ENOS_SUCCESS, 
  ADD_ENOS_STATUS,
  RESET_STATUS_ANOS,
  GET_POINT_LIST, 
  MODIFY_PRODUCT_ACCESS,
  MODIFY_REGISTRATION_LIMIT,
  RESET_SERVER_MESSAGE,
  CREATE_API_KEY_SUCCESS,
  DELETE_API_KEY_SUCCESS,
  SET_API_DATES_SUCESS,
  ADMIN_GET_POINTS_SUCCESS,
  UPDATE_USER_POINTS
} from '../actions/admin'

const initialState = {
  usersList: [],
  downloadData: undefined,
  enosList: undefined,
  enosStatus: undefined,
  availablePoints: [],
  authorizedPoints: [],
  registrationLimit: undefined,
  serverStatus: '',
  serverMessage: ''
}

export default (state=initialState, action) => {
  let newArray, user
  switch(action.type) {
    case GET_ALL_USERS_SUCCESS:
      return Object.assign({}, state, {usersList: action.users})
    case EDIT_USER_SUCCESS:
    case SET_USERS_STATUS_SUCCESS:
      newArray = state.usersList.filter(user => user.id !== action.user['id'])
      newArray.push(action.user)
      newArray.sort(function(a,b) {return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);} )
      return Object.assign({}, state, {usersList: newArray})
    case START_DOWNLOAD:
      return Object.assign({}, state, {downloadData: action.data})
    case RESET_DOWNLOAD:
      return Object.assign({}, state, {downloadData: undefined})
    case GET_ENOS_SUCCESS:
      return Object.assign({}, state, {enosList: action.enos})
    case ADD_ENOS_STATUS:
      return Object.assign({}, state, {enosStatus: action.status})
    case RESET_STATUS_ANOS:
      return Object.assign({}, state, {enosStatus: undefined})
    case GET_POINT_LIST:
      return Object.assign({}, state, {availablePoints: action.data.points, authorizedPoints: action.data.authorized, registrationLimit: action.data.limits})
    case MODIFY_PRODUCT_ACCESS:
      return Object.assign({}, state, {serverStatus: action.data.status, serverMessage: action.data.message})
    case MODIFY_REGISTRATION_LIMIT:
      return Object.assign({}, state, {serverStatus: action.data.status, serverMessage: action.data.message, registrationLimit: action.data.limits })
    case RESET_SERVER_MESSAGE:
      return Object.assign({}, state, {serverStatus: '', serverMessage: ''})
    case CREATE_API_KEY_SUCCESS:
      user = state.usersList.find(user => user.id === action.user)
      user.api_key = action.response.key
      newArray = state.usersList.filter(user => user.id !== action.user)
      newArray.push(user)
      newArray.sort(function(a,b) {return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);} )
      return Object.assign({}, state, {usersList: newArray, serverStatus: action.response.status, serverMessage: action.response.message})
    case DELETE_API_KEY_SUCCESS:
      user = state.usersList.find(user => user.id === action.user)
      user.api_key = null
      newArray = state.usersList.filter(user => user.id !== action.user)
      newArray.push(user)
      newArray.sort(function(a,b) {return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);} )
      return Object.assign({}, state, {usersList: newArray, serverStatus: action.response.status, serverMessage: action.response.message})
    case SET_API_DATES_SUCESS:
      return Object.assign({}, state, {serverStatus: action.response.status, serverMessage: action.response.message})
    case ADMIN_GET_POINTS_SUCCESS:
      return Object.assign({}, state, {availablePoints: action.response.data})
    case UPDATE_USER_POINTS:
      return Object.assign({}, state, {serverMessage: action.data.msg})
    default:
      return state
  }
}
