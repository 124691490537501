const applications = {
  applications: {
    agrymax: {
      name: "Agrymax",
      styles: {
        backgroundColor: "#6AA426"
      }
    },
    smart_cana: {
      name: "Smart Cana",
      styles: {
        backgroundColor: "#7cb342"
      }
    },
    smart_cafe: {
      name: "Smart Café",
      styles: {
        backgroundColor: "#4caf50"
      }
    },
    smart_laranja: {
      name: "Smart Laranja",
      styles: {
        backgroundColor: "#cf9c48"
      }
    }
  }
}

export default applications
