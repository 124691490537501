import isEmpty from 'lodash/isEmpty'

export default function validateInput(values, type) {
  let errors = {}

  if(values.eficiency !== '' && (parseFloat(values.eficiency) < 0 || parseFloat(values.eficiency) > 100)){
    errors.eficiency = "A eficiência deve estar entre 0 e 100"
  }

  if(values.cad === '') {
    errors.cad = "Campo obrigatório"
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}