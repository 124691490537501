import ScheduleApi from "../api/schedule"
import { toggleLoader } from "./global"

export const GET_SCHEDULES = 'GET_SCHEDULES'
export const EDIT_SCHEDULE = 'EDIT_SCHEDULE'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE'
export const RESET_STATUS = 'RESET_STATUS'
export const CHANGE_STATUS = 'CHANGE_STATUS'

export function getSchedules(){
    return function(dispatch){
        dispatch(toggleLoader())
        return ScheduleApi.getSchedules()
            .then(({ data }) => {
                dispatch(toggleLoader())
                dispatch(getSchedulesSuccess(data))
            })
            .catch(error => {throw(error)})
    }
}

export function createSchedule(data){
    return function(dispatch){
        return ScheduleApi.createSchedule(data)
            .then(response => {
                dispatch(createScheduleSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function editSchedule(data){
    return function(dispatch){
        dispatch(toggleLoader())
        return ScheduleApi.editSchedule(data)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(editScheduleSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function deleteSchedule(row){
    return function(dispatch){
        dispatch(toggleLoader())
        return ScheduleApi.deleteSchedule(row)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(deleteScheduleSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function getSchedulesSuccess(data){
    return({type: "GET_SCHEDULES", data})
}

export function createScheduleSuccess(data){
    return({type: "CREATE_SCHEDULE", data})
}

export function editScheduleSuccess(data){
    return({type: "EDIT_SCHEDULE", data})
}

export function deleteScheduleSuccess(data){
    return({type: "DELETE_SCHEDULE", data})
}

export function resetScheduleStatus(){
    return({type: "RESET_STATUS"})
}

export function changeScheduleStatus(data) {
    return({ type: CHANGE_STATUS, data })
}
