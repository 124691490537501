import React, { useState } from "react";

import ProfileAPI from "../../api/profile";

import { showToaster } from "../../components/common/Toaster";
import { ToastContainer } from "react-toastify";

import { Button, Dialog, Grid } from "@material-ui/core";
import "../../stylesheets/css/components/profile.css";

export function DeleteAccount({
  isDeleteAccountModalOpen,
  handleDeleteAccountModalClose,
}) {
  const [confirmPasswordToDelete, setConfirmPasswordToDelete] = useState("");

  const showAlert = (title, message) => {
    const toasterParams = {
      title,
      message,
      status: true,
      autoClose: true,
    };

    showToaster(toasterParams);
  };

  const handleConfirmPasswordToDeleteAccount = (event) => {
    setConfirmPasswordToDelete(event.target.value);
  };

  const handleCancelDeleteAccount = () => {
    handleDeleteAccountModalClose();
  };

  const handleDeleteAccount = async (
    confirmNewPasswordToDeleteParameter = confirmPasswordToDelete
  ) => {
    const objectBodyToDeleteAccount = {
      password: confirmNewPasswordToDeleteParameter,
    };

    const responseChangePassword = await ProfileAPI.deleteAccount(
      objectBodyToDeleteAccount
    );

    if (!responseChangePassword.message.includes("excluído")) {
      showAlert("Dados inválidos", responseChangePassword.message);
      return;
    }
    showAlert("Feito!", "Conta deletada com sucesso!");
    setConfirmPasswordToDelete('')
    localStorage.clear()
    sessionStorage.clear()
    window.alert("Conta deletada com sucesso!");
    window.location.href = "/login"
  };

  return (
    <Dialog
      open={isDeleteAccountModalOpen}
      onClose={handleDeleteAccountModalClose}
      fullWidth
    >
      <Grid container alignItems="center" style={{ height: "350px" }}>
        <Grid item container sm={12} justify="center">
          <Grid item sm={8} style={{ textAlign: "center" }}>
            <p>Tem certeza que deseja excluir esta conta?</p>

            <p>Após a exclusão não será possível recuperar os dados.</p>

            <p>Senha</p>
            <input
              style={{
                padding: "0 0 0 14px",
                border: "1px solid #6AA426",
                borderRadius: "4px",
              }}
              type="password"
              placeholder="Digite sua senha para deletar a conta."
              onChange={handleConfirmPasswordToDeleteAccount}
              value={confirmPasswordToDelete}
            />
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            margin: "0 50px 0 0",
          }}
        >
          <Button
            style={{
              backgroundColor: "#FFF",
              color: "#000",
              width: "100px",
            }}
            onClick={handleCancelDeleteAccount}
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: "#6AA426",
              color: "#FFF",
              width: "100px",
            }}
            onClick={() => handleDeleteAccount(confirmPasswordToDelete)}
          >
            Excluir
          </Button>
        </div>
      </Grid>
      <ToastContainer
        style={{ marginTop: "36px", textAlign: "left", width: "376px" }}
      />
    </Dialog>
  );
}
