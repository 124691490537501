import isEmpty from 'lodash/isEmpty'

export default function validateInput(data) {
  let errors = {}
  let lat = data.coords.split(',')[0]
  let lon = data.coords.split(',')[1]

  if(lat.length === 0) {
    errors.lat = 'Entre com uma latitude!'
  }

  if(lon.length === 0) {
    errors.lon = 'Entre com uma longitude!'
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
