import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router'
import Loader from '../common/Loader'
import classnames from 'classnames'
import { ToastContainer } from 'react-toastify'

//components
import LoginForm from './LoginForm'
import ContactInfo from './ContactInfo'
import RecoverPasswordForm from './RecoverPasswordForm'
import { showToaster } from '../common/Toaster'

class LoginPage extends Component {
  constructor(props){
    super(props)

    this.state = {
      contactFlag: false,
      recoverPassword: false
    }
  }

  //#region Lifecycle methods
  componentDidMount(){
    if(this.props.createResult !== undefined){
      this.props.toggleLoader()
      this.showAlert('SUCESSO!', this.props.createResult['message'])
    }
  }

  componentDidUpdate(prevProps,prevState){
    if(this.props.createResult !== undefined && prevProps.createResult === undefined){
      this.showAlert('SUCESSO!', this.props.createResult['message'])
      if(this.state.contactFlag){
        this.toggleContactFlag()
      }else if(this.state.recoverPassword){
        this.toggleRecoverPassword()
        this.props.toggleLoader()
      }else{
        this.props.toggleLoader()
      }
    }
  }
  //#endregion

  //Helper methods
  showAlert(title, msg) {

    const toasterParams = {
      title: title,
      message: msg,
      status: true,
      autoClose: true,
    }

    showToaster(toasterParams)
  }

  //#region Render methods
  toggleContactFlag = () => {
    this.setState({
      contactFlag: !this.state.contactFlag
    })
  }

  toggleRecoverPassword = () => {
    this.setState({
      recoverPassword: !this.state.recoverPassword
    })
  }
  //#endregion

  render() {
    if(this.props.isLogged){
      return (
        <Redirect to='/'/>
      )
    }else{
      return(
        <Fragment>
          <ToastContainer className='toast-alert-container'/>
          <Loader active={this.props.loader} />
          <div className='login-area'>
            <div className={classnames('login-area-form-container')}>
              <div className={classnames('login-area-form-container-inside', {'signin-area-form-container-inside': this.state.contactFlag})}>
                <div className={classnames('login-area-form-container-inside-title-row', {'signin-area-form-container-inside-title-row': this.state.contactFlag})}>
                  <div className='login-area-form-container-inside-title'>
                    <img alt='agrymax-logo' className={classnames({'logo-image-sign-in': this.state.contactFlag},{'logo-image': !this.state.contactFlag})} src='/images/logo_agrymax.png'/>
                  </div>
                </div>
                {this.state.contactFlag?
                  <ContactInfo toggleContactFlag={this.toggleContactFlag} sendContactInfo={this.props.sendContactInfo}/>
                  : this.state.recoverPassword ?
                  <RecoverPasswordForm
                    toggleLoader={this.props.toggleLoader}
                    toggleRecoverPassword={this.toggleRecoverPassword}
                    initRecoverPassword={this.props.initRecoverPassword}
                    setErrorsToNull={this.props.setErrorsToNull}
                  />
                  :
                  <div className='login-form'>
                    <LoginForm toggleContactFlag={this.toggleContactFlag} toggleRecoverPassword={this.toggleRecoverPassword}/>
                  </div>
                }
              </div>
              {this.state.contactFlag ?
                null :
                <div className="footer">
                  <div className="footer-social-networks">
                    <a href="https://www.facebook.com/agrymet/"  rel="noopener noreferrer">
                      <img alt='facebook-logo' src='/images/facebook.png'></img>
                    </a>
                    <a href="https://www.instagram.com/agrymet/"  rel="noopener noreferrer">
                      <img alt='instagram-logo' src='/images/instagram.png'></img>
                    </a>
                    <a href="https://www.linkedin.com/company/agrymet/" rel="noopener noreferrer">
                      <img alt='linkedin-logo' src='/images/linkedin-logo.png'></img>
                    </a>
                  </div>
                  <div className="footer-logo">
                    <img alt='agrymet-logo' className="agrymet-logo" src="/images/logo_agrymet_branco.png"></img>
                  </div>
                  <div className="footer-text">
                    <label>
                      Todos os direitos reservados à Agrymet
                    </label>
                  </div>
                </div>
              }
            </div>
            <div className={classnames('login-area-image-container', {'signin-area-image-container': this.state.contactFlag})}>
              <video style={{'height': '100%', zIndex: 0, position: 'absolute', right: '0px'}} id="background-video" loop autoPlay muted>
                  <source src="/istock-830459030.mp4" type="video/mp4"/>
              </video>
            </div>
          </div>
        </Fragment>
      )
    }
  }
}

export default LoginPage
