import isEmpty from 'lodash/isEmpty'

export default function validateInput(values, type) {
  let errors = {}

  if(type === 'productivity-spec'){
    if(values.cut_number !== '' && (parseInt(values.cut_number,10) > 10 || parseInt(values.cut_number,10) < 1)){
      errors.cut_number = "Número do corte entre 1 e 10"
    }

    if(values.cut_number !== ''){
      let regexTest = /^\d+$/.test(values.cut_number)
      if(!regexTest){
        errors.cut_number = "Número do corte deve ser um inteiro"
      }
    }

    if(values.kdec !== '' && (parseFloat(values.kdec) < 0 || parseFloat(values.kdec) > 1)){
      errors.kdec = "O kdec deve estar entre 0 e 1"
    }
  }

  if(values.eficiency !== '' && (parseFloat(values.eficiency) < 0 || parseFloat(values.eficiency) > 100)){
    errors.eficiency = "O eficiência deve estar entre 0 e 100"
  }

  if(values.cc_user !== '' && (parseFloat(values.cc_user) < 0 || parseFloat(values.cc_user) > 1)){
    errors.cc_user = "O índice deve estar entre 0 e 1"
  }

  if(values.u_percent_user !== '' && (parseFloat(values.u_percent_user) < 0 || parseFloat(values.u_percent_user) >= 100)){
    errors.u_percent_user = "A umidade deve ser 0 <= umidade < 100"
  }

  if(!values.date){
    errors.date = "A data é obrigatória"
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
