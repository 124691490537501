import React, { Component } from "react";
import { Gmaps, Marker, InfoWindow } from "react-gmaps";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import MapButtons from "./MapButtons";
import MapInformation from "./MapInformation";
import LatLngShow from "./LatLngShow";
import MapColorsInfo from "./MapColorsInfo";
import CompareFarmsBar from "../components/reports/bhbox/CompareFarmsBar";
import { showToaster } from "../components/common/Toaster";
import classnames from "classnames";

import "../stylesheets/css/components/map.css";

// Actions
import {
  addFarm,
  setActiveFarm,
  editFarm,
  saveAuxEditedCoords,
} from "../actions/farms";
import {
  allowMapClickEvent,
  allowXavierBhbox,
  allowMapClickEditEvent,
  setShowLatLng,
  resetGrid,
  setGridPointName,
  setGridPointCoords,
  getKmzFile,
} from "../actions/map";
import {
  toggleSidebar,
  setCadError,
  toggleLoader,
  toggleLeftLoader,
  toggleSidebarLoader,
  generatingMapData,
  fixUserFlow,
  toggleAtrHelp,
} from "../actions/global";
import {
  toggleReportContainer,
  resetReport,
  setReportTypes,
  setWeatherTypes,
  generateReport,
  setLastUsedCad,
} from "../actions/reports";
import { setProductivityUndefined } from "../actions/productivity";

import { AGRYTOOLS } from "../shared/utils/consts";

const apiKey = "AIzaSyDaBb44itnGQVy2NUY23ucm382tGmmmxTQ";
const gmParams = { v: "3.exp", key: apiKey, libraries: "visualization" };
const terrainStyle = [
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        visibility: "on",
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        visibility: "on",
      },
      {
        weight: 2.5,
      },
    ],
  },
];

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseCoords: {
        lat: "-14.235",
        lng: "-51.9253",
      },
      coords: {
        lat: "-14.235",
        lng: "-51.9253",
      },
      mapTypeId: "terrain",
      kmzLayers: null,
      showKmzLayer: false,
      zoom: 5,
      options: {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
      showInfoWindow: true,
      mapRef: undefined,
      mouseMove: undefined,
      loading: false,
      xavierGrid: false,
      selectedPointsBhbox: [],
      selectedCadBhbox: [],
      userPointGrid: true,
    };
  }

  setStyleFunction = (feature) => {
    let zoom = "0";
    if (this.state.zoom >= 0 && this.state.zoom <= 6) {
      zoom = "9";
    } else if (this.state.zoom >= 7 && this.state.zoom <= 8) {
      zoom = "18";
    } else {
      zoom = "32";
    }
    if (
      this.props.reportTypes === "cane-flowering" ||
      this.props.user.agrymax_plan === "basic"
    ) {
      let icon = feature.getProperty("style");
      return {
        icon:
          "/images/" +
          icon["icon"].toLowerCase() +
          "_" +
          zoom +
          "_inactive.png",
        visible: false,
        zIndex: 0,
      };
    }
    if (feature.getProperty("style") !== undefined) {
      let icon = feature.getProperty("style");
      if (this.state.selectedPointsBhbox.length > 1) {
        if (
          this.state.selectedPointsBhbox.includes(
            feature.getProperty("latitude") +
              "," +
              feature.getProperty("longitude")
          )
        ) {
          return {
            icon:
              "/images/" +
              icon["icon"].toLowerCase() +
              "_" +
              zoom +
              "_active.png",
            visible: true,
            zIndex: 0,
          };
        }
        return {
          icon:
            "/images/" +
            icon["icon"].toLowerCase() +
            "_" +
            zoom +
            "_inactive.png",
          visible: true,
          zIndex: 0,
        };
      } else if (
        this.props.selectedGridPoint.lat === feature.getProperty("latitude") &&
        this.props.selectedGridPoint.lng === feature.getProperty("longitude")
      ) {
        return {
          icon:
            "/images/" +
            icon["icon"].toLowerCase() +
            "_" +
            zoom +
            "_active.png",
          visible: true,
          zIndex: 0,
        };
      }
      return {
        icon:
          "/images/" +
          icon["icon"].toLowerCase() +
          "_" +
          zoom +
          "_inactive.png",
        visible: true,
        zIndex: 0,
      };
    }
  };

  setXavierGridPointInfo = (lat, lng) => {
    let point = this.props.xavierGridPoints["xavier_points"].filter((point) => {
      if (
        point["lat"].toString() === lat.toString() &&
        point["lng"].toString() === lng.toString()
      ) {
        return true;
      } else {
        return false;
      }
    });
    this.props.setGridPointName(point[0]["name"] + ", " + point[0]["uf"]);
  };

  handleGridClick = (event) => {
    if (
      this.props.reportTypes === "cane-flowering" ||
      this.props.user.agrymax_plan === "basic"
    ) {
      return;
    }

    let coords =
      event.feature.getProperty("latitude") +
      "," +
      event.feature.getProperty("longitude");
    this.props.setActiveFarm(-1);

    this.state.mapRef.data.setStyle(this.setStyleFunction);

    if (this.props.allowXavierBhboxVariable) {
      let string =
        event.feature.getProperty("latitude") +
        "," +
        event.feature.getProperty("longitude");
      let selectedPointsCopy = this.state.selectedPointsBhbox.slice();
      let selectedCadCopy = this.state.selectedCadBhbox.slice();
      let index = selectedPointsCopy.indexOf(string);
      if (index !== -1) {
        selectedPointsCopy.splice(index, 1);
        selectedCadCopy.splice(index, 1);
      } else {
        selectedPointsCopy.push(string);
        selectedCadCopy.push("0");
      }
      this.setState({
        selectedPointsBhbox: selectedPointsCopy,
        selectedCadBhbox: selectedCadCopy,
      });
    } else {
      let farmAttrs = {
        coords: coords,
        farmId: -1,
        cad:
          this.props.reportTypes[0] === "plan"
            ? 25
            : AGRYTOOLS.includes(this.props.reportTypes[0])
            ? 50
            : this.props.lastUsedCad !== "0"
            ? this.props.lastUsedCad
            : 0,
        type:
          this.props.weatherType !== undefined
            ? this.props.weatherType
            : "period",
        range:
          this.props.reportTypes[0] === "excdef"
            ? "decendial"
            : this.props.weatherRange !== undefined
            ? this.props.weatherRange
            : "daily",
        initial_date: AGRYTOOLS.includes(this.props.reportTypes[0])
          ? (new Date().getFullYear() - 10).toString()
          : "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: this.props.tBasal,
        visualization_type: this.props.visualizationType,
      };

      this.setState({
        selectedPointsBhbox: [
          event.feature.getProperty("latitude") +
            "," +
            event.feature.getProperty("longitude"),
        ],
        selectedCadBhbox: [this.props.lastUsedCad.toString()],
      });
      this.props.setGridPointCoords(
        event.feature.getProperty("latitude"),
        event.feature.getProperty("longitude")
      );
      this.setXavierGridPointInfo(
        event.feature.getProperty("latitude"),
        event.feature.getProperty("longitude")
      );
      this.props.toggleReportUndefined();
      if (
        this.props.user["type"] !== "bank" ||
        (this.props.user["type"] === "bank" && this.props.reportActive)
      ) {
        this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
        this.props.generateReport(this.props.reportTypes, farmAttrs);
        if (!this.props.reportActive && this.props.reportTypes.length !== 0) {
          this.props.toggleReportContainer();
        }
      } else {
        if (!this.props.sidebarActive) {
          this.props.toggleSidebar();
        }
      }
    }
  };

  onMapCreated = (map) => {
    window.google.maps.event.addListener(
      map,
      "mousemove",
      this.displayCoordinates
    );

    map.data.addListener("click", this.handleGridClick);

    map.setOptions({
      disableDefaultUI: true,
    });

    this.setState({
      mapRef: map,
    });
  };

  displayCoordinates = (pnt) => {
    let yOrientation = "";
    let xOrientation = "";
    var lat = pnt.latLng.lat();
    let degrees = lat.toString().split(".")[0];
    lat = (parseFloat(lat) - parseFloat(degrees)) * 60;
    let minutes = lat.toString().split(".")[0];
    lat = (parseFloat(lat) - parseFloat(minutes)) * 60;
    let seconds = lat.toString().split(".")[0];
    if (parseFloat(degrees, 10) < 0) {
      yOrientation = "S";
      degrees = (parseInt(degrees, 10) * -1).toString();
      minutes = (parseInt(minutes, 10) * -1).toString();
      seconds = (parseInt(seconds, 10) * -1).toString();
    } else if (parseFloat(degrees, 10) > 0) {
      yOrientation = "N";
    } else {
      if (parseFloat(minutes, 10) < 0) {
        degrees = (parseInt(degrees, 10) * -1).toString();
        minutes = (parseInt(minutes, 10) * -1).toString();
        seconds = (parseInt(seconds, 10) * -1).toString();
        yOrientation = "S";
      } else {
        yOrientation = "N";
      }
    }
    lat = degrees + "°" + minutes + "'" + seconds + '"' + yOrientation;

    var lng = pnt.latLng.lng();
    degrees = lng.toString().split(".")[0];
    lng = (parseFloat(lng) - parseFloat(degrees)) * 60;
    minutes = lng.toString().split(".")[0];
    lng = (parseFloat(lng) - parseFloat(minutes)) * 60;
    seconds = lng.toString().split(".")[0];
    if (parseFloat(degrees, 10) < 0) {
      xOrientation = "W";
      degrees = (parseInt(degrees, 10) * -1).toString();
      minutes = (parseInt(minutes, 10) * -1).toString();
      seconds = (parseInt(seconds, 10) * -1).toString();
    } else if (parseFloat(degrees, 10) >= 0) {
      xOrientation = "E";
    }

    lng = degrees + "°" + minutes + "'" + seconds + '"' + xOrientation;
    this.props.setShowLatLng(lat, lng);
  };

  handleZoomIn = () => {
    this.setState({
      zoom: this.state.zoom + 1,
    });
  };

  handleZoomOut = () => {
    this.setState({
      zoom: this.state.zoom - 1,
    });
  };

  handleXavierGridButton = () => {
    if (this.state.xavierGrid) {
      this.state.mapRef.data.setStyle((feature) => {
        return {
          visible: false,
        };
      });
    } else {
      this.state.mapRef.data.setStyle(this.setStyleFunction);
    }

    this.setState({
      xavierGrid: !this.state.xavierGrid,
    });
  };

  handleUserPointsGridButton = () => {
    this.setState({ userPointGrid: !this.state.userPointGrid });
  };

  onMapClick = (e) => {
    if (this.props.allowMapClickEvent) {
      let coords =
        e.latLng.lat().toFixed(3).toString() +
        "," +
        e.latLng.lng().toFixed(3).toString();
      toast.dismiss();
      let attrs = {
        user: localStorage.Token ? localStorage.Token : sessionStorage.Token,
        coords: coords,
      };
      this.props.addNewFarm(attrs);
      this.props.toggleLoader();
    } else if (this.props.allowMapClickEditEvent) {
      let coords =
        e.latLng.lat().toFixed(3).toString() +
        "," +
        e.latLng.lng().toFixed(3).toString();
      toast.dismiss();
      var pos = {
        lat: e.latLng.lat().toFixed(3).toString(),
        lng: e.latLng.lng().toFixed(3).toString(),
      };
      this.props.saveAuxEditedCoords(coords, coords);
      this.props.toggleSidebar();

      this.setState({
        coords: pos,
      });
    }
  };

  onPinClick = (e) => {
    this.props.onPinClicked(e, showToaster);
  };

  allowDrag = (farm) => {
    if (this.props.allowMapClickEditEvent && farm.id === farm.active) {
      return true;
    }

    return false;
  };

  handleDrag = (e) => {
    toast.dismiss();
    let coords =
      e.latLng.lat().toFixed(3).toString() +
      "," +
      e.latLng.lng().toFixed(3).toString();
    let farm = this.props.farms["farms"].filter(
      (farm) => farm.id === this.props.activeFarm
    )[0];

    let farmAttrs = {
      name: farm.name || "",
      cad: farm.cad || "",
      coords: coords,
      id: this.props.activeFarm,
    };
    this.props.editFarm(this.props.activeFarm, farmAttrs);

    this.setState({
      showInfoWindow: true,
    });
  };

  handleDragStart = () => {
    this.setState({
      showInfoWindow: false,
    });
  };

  handleKmzLayers = () => {
    if (this.state.showKmzLayer) {
      this.state.kmzLayers.forEach((layer) => {
        layer.setMap(null);
      });
    } else {
      this.state.kmzLayers.forEach((layer) => {
        layer.setMap(this.state.mapRef);
      })
    }

    this.setState({
      showKmzLayer: !this.state.showKmzLayer,
    });
  };

  handleMapType = () => {
    if (this.state.mapTypeId === "satellite") {
      this.setState({
        mapTypeId: "terrain",
      });
    } else {
      this.setState({
        mapTypeId: "satellite",
      });
    }
  };

  setLabelText = (index) => {
    if (index < 26) {
      return String.fromCharCode(65 + index);
    } else {
      let firstLetter = index / 26 - 1;
      let secondLetter = index % 26;
      return (
        String.fromCharCode(65 + firstLetter) +
        String.fromCharCode(65 + secondLetter)
      );
    }
  };

  handleZoom = () => {
    let bounds = this.state.mapRef.getBounds().getCenter();

    var pos = {
      lat: bounds.lat(),
      lng: bounds.lng(),
    };

    this.setState({
      zoom: this.state.mapRef.getZoom(),
      coords: pos,
    });
  };

  setFontSize(initials) {
    if (this.state.zoom <= 12) {
      if (initials.length === 1) {
        return "11px";
      } else if (initials.length === 2) {
        return "9px";
      } else if (initials.length === 3) {
        return "7px";
      } else {
        return "6px";
      }
    } else {
      if (initials.length === 1) {
        return "14px";
      } else if (initials.length === 2) {
        return "12px";
      } else if (initials.length === 3) {
        return "9px";
      } else {
        return "7px";
      }
    }
  }

  handleCadDropdownChange = (event) => {
    let index = parseInt(event.target.classList[0], 10);
    let selectedCadCopy = this.state.selectedCadBhbox.slice();
    selectedCadCopy[index] = event.target.value;
    this.setState({
      selectedCadBhbox: selectedCadCopy,
    });
  };

  onOkClick = () => {
    let farmAttrs = {
      coords: "",
      farmId: -1,
      cad:
        this.props.reportTypes[0] === "plan"
          ? 25
          : AGRYTOOLS.includes(this.props.reportTypes[0])
          ? 50
          : this.props.lastUsedCad !== "0"
          ? this.props.lastUsedCad
          : 0,
      type: "period",
      range: this.props.reportTypes[0] === "excdef" ? "decendial" : "daily",
      initial_date:
        AGRYTOOLS.includes(this.props.reportTypes[0]) ||
        this.props.reportTypes[0] === "fmaac"
          ? (new Date().getFullYear() - 10).toString()
          : "1980",
      final_date: (new Date().getFullYear() + 1).toString(),
      xavier_points: this.state.selectedPointsBhbox,
      xavier_cads: this.state.selectedCadBhbox,
      visualization_type: this.props.visualizationType,
    };

    this.props.toggleReportUndefined();
    this.props.allowXavierBhboxCall();
    this.props.toggleReportContainer();
    this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
    this.props.generateReport(this.props.reportTypes, farmAttrs);
  };

  onCancelClick = () => {
    this.props.allowXavierBhboxCall();
    this.props.toggleReportContainer();
    this.setState({
      selectedCadBhbox: ["0"],
      selectedPointsBhbox: [
        this.props.selectedGridPoint.lat +
          "," +
          this.props.selectedGridPoint.lng,
      ],
    });
    this.state.mapRef.data.setStyle(this.setStyleFunction);
  };

  reportActiveMapOffset(zoom) {
    let base = 0;
    let factor = 0;

    if (zoom === 2) {
      return 90.0;
    } else if (zoom === 3) {
      return 50.0;
    } else if (zoom >= 4 && zoom <= 7) {
      base = 20.0;
      factor = zoom - 5;
      return factor < 0 ? base - factor * 5.0 : base - (factor + 1) * 5.0;
    } else if (zoom >= 8) {
      base = 2.0;
      factor = zoom - 8;
      return factor !== 0 ? base / (factor * 2.0) : base;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // eslint-disable-next-line
    if (this.props.grid === undefined && nextProps.grid === null) {
      return true;
    }
    if (
      this.props.grid !== nextProps.grid &&
      nextProps.grid !== undefined &&
      Object.keys(nextProps.grid).length === 0
    ) {
      const toasterParams = {
        title: "Dados não cadastrados",
        message:
          "O banco de dados não tem registros de algumas informações que você está procurando. Se este é um comportamento inesperado, contate o administrador do sistema!",
        status: false,
        autoClose: false,
        buttons: [
          {
            name: "OK",
            style: "delete",
            function: toast.dismiss,
          },
        ],
      };
      showToaster(toasterParams);
      this.props.toggleLeftLoader();
      return true;
    } else {
      return true;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    var pos = undefined;
    if (
      // eslint-disable-next-line
      (this.props.grid !== nextProps.grid && nextProps.grid == undefined) ||
      // eslint-disable-next-line
      (this.props.grid !== nextProps.grid &&
        nextProps.grid !== undefined &&
        Object.keys(nextProps.grid).length !== 0) ||
      // eslint-disable-next-line
      (this.props.grid === nextProps.grid && nextProps.grid == undefined) ||
      // eslint-disable-next-line
      (this.props.grid === nextProps.grid &&
        nextProps.grid !== undefined &&
        Object.keys(nextProps.grid).length !== 0)
    ) {
      if (nextProps.isPinClicked) {
        if (this.state.zoom < 8) {
          this.setState({ zoom: 8 });
        }
        this.props.resetPinFlag();
      }

      if (nextProps.selectedGridPoint !== this.props.selectedGridPoint) {
        this.setState({
          selectedPointsBhbox: [
            nextProps.selectedGridPoint.lat +
              "," +
              nextProps.selectedGridPoint.lng,
          ],
          selectedCadBhbox: [this.props.lastUsedCad.toString()],
        });
      }
      //Set the map center when there's a bhbox open
      if (
        nextProps.activeFarm === -2 &&
        nextProps.sidebarActive === false &&
        nextProps.allowMapClickEvent === false
      ) {
        this.setState({
          coords: this.state.baseCoords,
          zoom: 5,
        });
      } else if (nextProps.reportActive && nextProps.grid !== undefined) {
        var activeFarm = nextProps.activeFarm;
        var posNum = undefined;
        if (activeFarm === -1) {
          posNum = {
            lat: this.props.selectedGridPoint.lat,
            lng:
              this.props.selectedGridPoint.lng +
              this.reportActiveMapOffset(this.state.zoom),
          };
          this.setState({
            coords: posNum,
          });
        } else {
          let markers = this.map.getChildren();
          markers.forEach((marker) => {
            if (activeFarm === marker.props.id) {
              posNum = {
                lat: parseFloat(marker.props.lat),
                lng:
                  parseFloat(marker.props.lng) +
                  this.reportActiveMapOffset(this.state.zoom),
              };
              this.setState({
                coords: posNum,
              });
            }
          });
        }
      } else if (
        this.props.activeFarm !== -1 &&
        this.props.activeFarm !== nextProps.activeFarm &&
        nextProps.activeFarm !== -1
      ) {
        let farm = nextProps.farms["farms"].filter((farm) => {
          if (farm.id === nextProps.activeFarm) {
            return farm;
          }
          return nextProps.farms["farms"][0];
        })[0];
        pos = {
          lat: farm.coords.split(",")[0],
          lng: farm.coords.split(",")[1],
        };
        this.setState({
          coords: pos,
        });
      }

      if (
        nextProps.activeDay["variable"] === "etretp" ||
        nextProps.activeDay["variable"] === "atrmax" ||
        nextProps.weatherType === "accumulate" ||
        nextProps.reportTypes === "productivity" ||
        nextProps.reportTypes === "productivity-spec"
      ) {
        this.props.resetGrid();
        this.state.mapRef.data.forEach((feature) => {
          this.state.mapRef.data.remove(feature);
        });
        this.setState({
          xavierGrid: false,
        });
      }

      if (
        nextProps.leftLoader === true &&
        this.state.loading === false &&
        this.state.mapRef !== undefined
      ) {
        this.state.mapRef.data.forEach((feature) => {
          this.state.mapRef.data.remove(feature);
        });
        this.setState({
          loading: true,
          xavierGrid: false,
        });
        if (nextProps.generatingMap === true) {
          this.props.generatingMapData();
        }
        this.props.resetGrid();
      } else if (
        nextProps.user["type"] !== "bank" &&
        nextProps.grid !== undefined &&
        this.state.loading === true
      ) {
        this.props.toggleLeftLoader();
        if (nextProps.grid !== null) {
          this.setState({
            loading: false,
            xavierGrid: true
          })
          this.state.mapRef.data.addGeoJson(nextProps.grid)
          this.state.mapRef.data.setStyle(this.setStyleFunction)
        }else{
          this.state.mapRef.data.forEach((feature) => {
            this.state.mapRef.data.remove(feature);
          });
          this.setState({
            loading: true,
            xavierGrid: false,
          });
          if (nextProps.generatingMap === true) {
            this.props.generatingMapData();
          }
          this.props.resetGrid();
        }
      } else if (
        nextProps.user["type"] === "bank" &&
        this.state.loading === true &&
        nextProps.grid !== undefined
      ) {
        if (this.state.xavierGrid) {
          this.state.mapRef.data.setStyle(this.setStyleFunction);
        } else {
          this.setState({
            loading: false,
            xavierGrid: true,
          });
          this.state.mapRef.data.addGeoJson(nextProps.grid);
          this.state.mapRef.data.setStyle(this.setStyleFunction);
          if (this.props.leftLoader) {
            this.props.toggleLeftLoader();
          }
        }
      } else if (
        nextProps.grid === undefined &&
        this.state.loading === false &&
        this.state.mapRef !== undefined
      ) {
        this.state.mapRef.data.forEach((feature) => {
          this.state.mapRef.data.remove(feature);
        });
        this.setState({
          loading: false,
          xavierGrid: false,
        });
      }

      if (this.props.activeFarm === -1 && nextProps.activeFarm !== -1) {
        this.props.setGridPointCoords("", "");
        this.state.mapRef.data.setStyle(this.setStyleFunction);
      }

      if (
        (this.props.editCoords !== "" && nextProps.editCoords === "") ||
        (this.props.editMode === "" && nextProps.editMode !== "")
      ) {
        let farm = nextProps.farms["farms"].filter(
          (farm) => farm.id === nextProps.activeFarm
        )[0];
        pos = {
          lat: farm.coords.split(",")[0],
          lng: farm.coords.split(",")[1],
        };
        this.setState({
          coords: pos,
        });
      }

      if (
        !this.props.allowMapClickEditEvent &&
        nextProps.allowMapClickEditEvent
      ) {
        this.state.mapRef.data.setStyle((feature) => {
          return {
            visible: false,
          };
        });
      } else if (
        this.props.allowMapClickEditEvent &&
        !nextProps.allowMapClickEditEvent
      ) {
        this.state.mapRef.data.setStyle(this.setStyleFunction);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetGrid();
    this.props.setProductivityUndefined();
    this.props.toggleReportUndefined();
    this.props.setReportTypes([]);
  }

  calculateInfoWindowOffset(zoom) {
    if (zoom <= 4) {
      return 0.5;
    } else if (zoom === 5) {
      return 1.4;
    } else if (zoom >= 6 && zoom <= 12) {
      let base = 0.8;
      let divisionFactor = Math.pow(2, zoom - 6);
      return base / divisionFactor;
    } else if (zoom >= 13) {
      let base = 0.008;
      let divisionFactor = Math.pow(2, zoom - 13);
      return base / divisionFactor;
    }
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  setPinColor = (lat, lon) => {
    let color = "#FFD250";
    let closestDistance = Infinity;

    if (this.state.mapRef) {
      this.state.mapRef.data.forEach((feature) => {
        const latGrid = feature.getProperty("latitude");
        const lonGrid = feature.getProperty("longitude");
  
        if (
          parseFloat(lonGrid) >= parseFloat(lon) - 0.5 &&
          parseFloat(lonGrid) <= parseFloat(lon) + 0.5 &&
          parseFloat(latGrid) <= parseFloat(lat) + 0.5 &&
          parseFloat(latGrid) >= parseFloat(lat) - 0.5
        ) {
          const distance = this.getDistanceFromLatLonInKm(
            parseFloat(latGrid),
            parseFloat(lonGrid),
            parseFloat(lat),
            parseFloat(lon),
          );
  
          if (distance < closestDistance) {
            closestDistance = distance;
            color = feature.getProperty("style");
            color = "#" + color["icon"];
          }
        }
      });
    }

    return color;
  };

  setIcon(id, lat, lon) {
    let icon;
    let color = this.setPinColor(lat, lon);
    if (this.state.zoom >= 0 && this.state.zoom <= 4) {
      icon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: color === "#TRANSP" ? "#FFFFFF" : color,
        fillOpacity: color === "#TRANSP" ? 0.4 : 1,
        strokeWeight: this.props.activeFarm === id ? 2 : 1,
        scale: 5,
      };
    } else if (this.state.zoom >= 5 && this.state.zoom <= 12) {
      icon = {
        path: "M67.871,19.436c0,7.314-8.623,12.626-8.623,23.814c0-11.188-8.623-16.5-8.623-23.814c0-4.762,3.861-8.623,8.623-8.623S67.871,14.674,67.871,19.436z",
        fillColor: color === "#TRANSP" ? "#FFFFFF" : color,
        fillOpacity: color === "#TRANSP" ? 0.4 : 1,
        anchor: new window.google.maps.Point(59, 46),
        strokeWeight: this.props.activeFarm === id ? 2 : 1,
        labelOrigin: new window.google.maps.Point(59, 20),
      };
    } else {
      icon = {
        path: "M22.997,11.498c0,9.752-11.499,16.834-11.499,31.752C11.498,28.332,0,21.25,0,11.498C0,5.148,5.148,0,11.498,0C17.849,0,22.997,5.148,22.997,11.498z",
        fillColor: color === "#TRANSP" ? "#FFFFFF" : color,
        fillOpacity: color === "#TRANSP" ? 0.4 : 1,
        anchor: new window.google.maps.Point(12, 46),
        strokeWeight: this.props.activeFarm === id ? 2 : 1,
        labelOrigin: new window.google.maps.Point(11, 10),
      };
    }

    return icon;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState.zoom !== this.state.zoom && this.state.xavierGrid) ||
      prevProps.grid !== this.props.grid
    ) {
      this.state.mapRef.data.setStyle(this.setStyleFunction);
    }
    if (this.props.kmz !== undefined && prevProps.kmz !== this.props.kmz) {
      window.setInterval(this.props.getKmzFile, 3300000);
      const kmzFiles = this.props.kmz.map(({ url }) => (
        new window.google.maps.KmlLayer(url, {
          suppressInfoWindows: true,
          map: null,
        })
      ));
      this.setState({ kmzLayers: kmzFiles });
    }
  }

  componentDidMount() {
    this.props.getKmzFile();
  }

  render() {
    let infoWindow = null;

    if (this.props.farms !== undefined) {
      if (this.props.allowMapClickEditEvent) {
        if (this.state.showInfoWindow) {
          let farm = this.props.farms["farms"].filter(
            (farm) => farm.id === this.props.activeFarm
          )[0];
          infoWindow = (
            <InfoWindow
              lat={
                parseFloat(farm.coords.split(",")[0]) +
                this.calculateInfoWindowOffset(this.state.zoom)
              }
              lng={parseFloat(farm.coords.split(",")[1])}
              content={"Clique no mapa"}
            />
          );
        }
      }
    }

    return (
      <div>
        <div className="screen">
          <Gmaps
            ref={(map) => (this.map = map)}
            width={"100%"}
            height={"calc(100vh - 48px)"}
            lat={this.state.coords.lat}
            lng={this.state.coords.lng}
            zoom={this.state.zoom}
            mapTypeId={this.state.mapTypeId}
            styles={this.state.mapTypeId === "terrain" ? terrainStyle : null}
            loadingMessage={"Carregando mapa da fazenda"}
            params={gmParams}
            onMapCreated={this.onMapCreated}
            onClick={this.onMapClick}
            onTilesLoaded={this.handleZoom}
          >
            {this.props.farms !== undefined && this.state.userPointGrid
              ? this.props.farms["farms"].map((farm, index) => (
                  <Marker
                    key={farm.id}
                    lat={farm.coords.split(",")[0]}
                    lng={farm.coords.split(",")[1]}
                    draggable={this.allowDrag(farm)}
                    zIndex={1000 - index > 0 ? 1000 - index : 1}
                    title={
                      farm.name === "" ? "Ponto " + farm.initials : farm.name
                    }
                    id={farm.id}
                    onDragStart={this.handleDragStart}
                    onDragEnd={this.handleDrag}
                    onClick={this.onPinClick}
                    icon={this.setIcon(
                      farm.id,
                      farm.coords.split(",")[0],
                      farm.coords.split(",")[1]
                    )}
                    label={{
                      text: this.state.zoom >= 5 ? farm.initials : " ",
                      fontSize: this.setFontSize(farm.initials),
                      fontWeight: "500",
                      color: "#3E2723",
                      colorOpacity: 0.13,
                    }}
                  />
                ))
              : null}
            {this.props.farms !== undefined ? infoWindow : null}
          </Gmaps>
          <MapButtons
            reportActive={this.props.reportActive}
            kmzFiles={this.props.kmz}
            handleKmzLayer={this.handleKmzLayers}
            handleMapType={this.handleMapType}
            handleZoomIn={this.handleZoomIn}
            handleZoomOut={this.handleZoomOut}
            xavierGrid={this.state.xavierGrid}
            showXavierButton={
              this.props.reportTypes === "cane-flowering" ||
              this.props.user.agrymax_plan === "basic"
                ? false
                : true
            }
            handleGrid={this.handleXavierGridButton}
            handleUserPointsGridButton={this.handleUserPointsGridButton}
            activeGrid={this.props.grid !== undefined ? true : false}
            userPointGrid={this.state.userPointGrid}
            showKmzLayer={this.state.showKmzLayer}
          />
          <div className="row">
            <LatLngShow />
            {this.props.weatherRange === "annualy" ? (
              <div
                className={classnames(
                  "map-annual-info",
                  { "map-annual-info-report-open": this.props.reportActive },
                  { "map-annual-info-report-closed": !this.props.reportActive }
                )}
              >
                Dados do usuário não são levados em consideração
              </div>
            ) : null}
          </div>
          {this.props.user["type"] === "bank" ? null : (
            <MapInformation xavierGrid={this.props.grid} />
          )}
          {this.props.allowXavierBhboxVariable ? (
            <CompareFarmsBar
              selectedPointsBhbox={this.state.selectedPointsBhbox}
              selectedCadBhbox={this.state.selectedCadBhbox}
              xavierGridPoints={this.props.xavierGridPoints}
              onChange={this.handleCadDropdownChange}
              onCancelClick={this.onCancelClick}
              onOkClick={this.onOkClick}
              reportTypes={this.props.reportTypes}
            />
          ) : null}
          {this.props.user["type"] === "bank" ? null : (
            <MapColorsInfo xavierGrid={this.props.grid} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms.points,
    activeFarm: state.farms.activeFarm,
    allowMapClickEvent: state.map.allowMapClickEvent,
    allowMapClickEditEvent: state.map.allowMapClickEditEvent,
    sidebarActive: state.global.sidebarActive,
    reportActive: state.Reports.reportActive,
    report: state.Reports.report,
    reportTypes: state.Reports.reportTypes,
    activeDay: state.map.weatherMapDay,
    cadError: state.global.cadError,
    grid: state.map.grid,
    selectedGridPoint: state.map.selectedGridPointCoords,
    user: state.auth.user,
    lastUsedCad: state.Reports.lastUsedCad,
    weatherType: state.Reports.graphicType,
    weatherRange: state.Reports.graphicRange,
    loader: state.global.loader,
    leftLoader: state.global.leftLoader,
    generatingMap: state.global.generatingMapData,
    userFix: state.global.userFix,
    xavierGridPoints: state.map.xavierGridPoints,
    allowXavierBhboxVariable: state.map.allowXavierBhbox,
    editCoords: state.farms.editCoords,
    editMode: state.farms.editMode,
    tBasal: state.Reports.tBasal,
    atrHelp: state.global.atrHelp,
    visualizationType: state.global.visualizationType,
    kmz: state.map.kmz,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewFarm: (coords) => {
      dispatch(addFarm(coords));
    },
    setMapClickEvent: () => {
      dispatch(allowMapClickEvent());
    },
    setMapClickEditEvent: () => {
      dispatch(allowMapClickEditEvent());
    },
    setActiveFarm: (id) => {
      dispatch(setActiveFarm(id));
    },
    editFarm: (id, attrs) => {
      dispatch(editFarm(id, attrs));
    },
    toggleSidebar: () => {
      dispatch(toggleSidebar());
    },
    toggleReportContainer: () => {
      dispatch(toggleReportContainer());
    },
    toggleReportUndefined: () => {
      dispatch(resetReport());
    },
    setReportTypes: (reportTypes) => {
      dispatch(setReportTypes(reportTypes));
    },
    setWeatherTypes: (graphicType, graphicRange) => {
      dispatch(setWeatherTypes(graphicType, graphicRange));
    },
    generateReport: (reportType, farmAttrs) => {
      dispatch(generateReport(reportType, farmAttrs));
    },
    setShowLatLng: (lat, lng) => {
      dispatch(setShowLatLng(lat, lng));
    },
    setCadError: () => {
      dispatch(setCadError());
    },
    resetGrid: () => {
      dispatch(resetGrid());
    },
    setGridPointName: (name) => {
      dispatch(setGridPointName(name));
    },
    setGridPointCoords: (lat, lng) => {
      dispatch(setGridPointCoords(lat, lng));
    },
    toggleLoader: () => {
      dispatch(toggleLoader());
    },
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader());
    },
    toggleSidebarLoader: () => {
      dispatch(toggleSidebarLoader());
    },
    generatingMapData: () => {
      dispatch(generatingMapData());
    },
    fixUserFlow: () => {
      dispatch(fixUserFlow());
    },
    allowXavierBhboxCall: () => {
      dispatch(allowXavierBhbox());
    },
    setLastUsedCad: (cad) => {
      dispatch(setLastUsedCad(cad));
    },
    saveAuxEditedCoords: (coords, auxCoords) => {
      dispatch(saveAuxEditedCoords(coords, auxCoords));
    },
    toggleAtrHelp: () => {
      dispatch(toggleAtrHelp());
    },
    setProductivityUndefined: () => {
      dispatch(setProductivityUndefined());
    },
    getKmzFile: () => {
      dispatch(getKmzFile());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
