const initialState = {
    charts: [],
    data: undefined,
    culture: "",
    pagination: {
        total_pages: 1,
        current_page: 0,
        next_page: 1,
        previous_page: null,
        total_itens: 0
    },
    options: [],
    points: [],
    years: [],
    message: ""
}

export default (state = initialState, action) => {
    switch(action.type) {
        case "SET_DASHBOARD_CULTURE":
            return Object.assign({}, state, {culture: action.culture})
        case "GET_TABLE_SUCCESS":
            return Object.assign({}, state, {
                data: action.response.data, 
                pagination: action.response.pagination,
                options: action.response.options,
                points: action.response.points,
                years: [...state.years].sort()
            });
        case "EDIT_DASHBOARD_LINE":
            return Object.assign({}, state, {message: action.response.message})
        case "ADD_PAYLOAD_SUCCESS":
            return Object.assign({}, state, {message: action.response.message, status: action.response.status})
        case "ADD_DASHBOARD_LINE":
            return Object.assign({}, state, {message: action.response.message, status: action.response.status})
        case "DELETE_DASHBOARD_LINE":
            return Object.assign({}, state, {message: action.response.message});
        case "GET_CHARTS_SUCCESS":
            return Object.assign({}, state, {charts: action.response.data})
        case "RESET_DASHBOARD_MESSAGE":
            return Object.assign({}, state, {message: ""})
        default:
            return state
    }
}