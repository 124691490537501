import { authHeader} from './config'

class EnosApi {
  static getEnosData(type) {
    return fetch(process.env.REACT_APP_API + 'api/enos', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        enos_type: type.type,
        year_type: type.yearType,
        year_range: type.yearRange
      })
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }
}

export default EnosApi
