import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import history from "./common/history";
import { setListedPoint, toggleKmz, toggleSchedule } from "../actions/global";

import WeatherDownloadModal from "./profile/WeatherDownloadModal";
import KmzFiles from "./profile/KmzFiles";

import "../stylesheets/css/components/profile.css";
import { Grid, Button } from "@material-ui/core";
import { ChangePassword } from "./profile/ChangePassword";
import { DeleteAccount } from "./profile/DeleteAccount";

class ProfileBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPoint: -1,
      weatherDownloadModalOpen: false,
      kmzUploadModal: false,
      isChangePasswordModalOpen: false,
      isDeleteAccountModalOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      selectedPoint:
        this.props.listedPoint !== -1 ? this.props.listedPoint : -1,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedPoint !== this.state.selectedPoint) {
      this.props.setListedPoint(this.state.selectedPoint);
    }
  }

  onPointChange = (event) => {
    this.setState({
      selectedPoint: event.target.value,
    });
  };

  onClick = () => {
    history.push(`/user-data`);
  };

  onToggleSchedule = () => {
    this.props.toggleSchedule();
  };

  onToggleKmz = () => {
    this.props.toggleKmz();
  }

  openDownloadModal = () => {
    this.setState({
      weatherDownloadModalOpen: !this.state.weatherDownloadModalOpen,
    });
  };

  openKmzDownload = () => {
    this.setState({
      kmzUploadModal: !this.state.kmzUploadModal,
    });
  };

  handleChangePasswordModalOpen = () => {
    this.setState({
      isChangePasswordModalOpen: true,
    });
  };

  handleChangePasswordModalClose = () => {
    this.setState({
      isChangePasswordModalOpen: false,
    });
  };

  handleDeleteAccountModalOpen = () => {
    this.setState({
      isDeleteAccountModalOpen: true,
    });
  };

  handleDeleteAccountModalClose = () => {
    this.setState({
      isDeleteAccountModalOpen: false,
    });
  };

  render() {
    const { generalInfo, uploadedFarms, userPlan } = this.props;
    const products = generalInfo.access ? generalInfo.access : [];
    let plan = userPlan === "basic" ? "BÁSICO" : "PREMIUM";

    return (
      <Fragment>
        <WeatherDownloadModal
          openModal={this.state.weatherDownloadModalOpen}
          closeModal={this.openDownloadModal}
          productList={this.state.downloadingProducts}
        />
        <KmzFiles
          openModal={this.state.kmzUploadModal}
          closeModal={this.openKmzDownload}
        />
        <ChangePassword
          isChangePasswordModalOpen={this.state.isChangePasswordModalOpen}
          handleChangePasswordModalClose={this.handleChangePasswordModalClose}
        />
        <DeleteAccount
          isDeleteAccountModalOpen={this.state.isDeleteAccountModalOpen}
          handleDeleteAccountModalClose={this.handleDeleteAccountModalClose}
        />
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="flex-start"
          className="profile-bar"
        >
          <Grid item container direction="column" className="user-header">
            <Grid item>
              <Link to="/integration" className="icon-ref">
                <i className="material-icons green">dns</i>
              </Link>
            </Grid>
            <Grid item>
              <div className="bold">{generalInfo.email}</div>
            </Grid>
            <Grid item>
              <div>{generalInfo.address}</div>
            </Grid>
            <Grid item>
              <div>{generalInfo.total_points} pontos cadastrados</div>
            </Grid>
            <Grid
              className="containerButtonsChangePasswordAndDeleteAccount"
              item
            >
              <Button
                className="buttonsChangePasswordAndDeleteAccount"
                onClick={this.handleChangePasswordModalOpen}
              >
                Alterar senha
              </Button>
              <Button
                className="buttonsChangePasswordAndDeleteAccount"
                onClick={this.handleDeleteAccountModalOpen}
              >
                Excluir conta
              </Button>
            </Grid>
          </Grid>
          <Grid item className="line" />
          <Grid
            item
            container
            alignItems="center"
            justify="space-between"
            direction="column"
            className="user-products"
          >
            <Grid item>
              <div className="bold">PLANO</div>
            </Grid>
            <Grid item>
              <div>{plan}</div>
            </Grid>
            <Grid item>
              <div className="bold">SMARTCROPS</div>
            </Grid>
            <Grid item container justify="center" className="smart-items">
              <Grid item>
                <img
                  src={
                    products.includes("cana")
                      ? "/images/logos/cane-active.png"
                      : "/images/logos/cane-inactive.png"
                  }
                  className="product-image"
                  alt={`Cana${
                    products.includes("cana") ? null : " não"
                  } contratado`}
                  title={`Cana${
                    products.includes("cana") ? "" : " não"
                  } contratado`}
                />
              </Grid>
              <Grid item>
                <img
                  src={
                    products.includes("soja")
                      ? "/images/logos/soy-active.png"
                      : "/images/logos/soy-inactive.png"
                  }
                  className="product-image"
                  alt={`Soja${
                    products.includes("soja") ? "" : " não"
                  } contratado`}
                  title={`Soja${
                    products.includes("soja") ? "" : " não"
                  } contratado`}
                />
              </Grid>
              <Grid item>
                <img
                  src={
                    products.includes("milho")
                      ? "/images/logos/corn-active.png"
                      : "/images/logos/corn-inactive.png"
                  }
                  className="product-image"
                  alt={`Milho${
                    products.includes("milho") ? "" : " não"
                  } contratado`}
                  title={`Milho${
                    products.includes("milho") ? "" : " não"
                  } contratado`}
                />
              </Grid>
              <Grid item>
                <img
                  src={
                    products.includes("algodao")
                      ? "/images/logos/cotton-active.png"
                      : "/images/logos/cotton-inactive.png"
                  }
                  className="product-image"
                  alt={`Algodão${
                    products.includes("algodao") ? "" : " não"
                  } contratado`}
                  title={`Algodão${
                    products.includes("algodao") ? "" : " não"
                  } contratado`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="line" />
          <Grid
            item
            container
            alignItems="center"
            justify="center"
            direction="column"
            className="user-points"
          >
            <Grid item className="point-container">
              <Button className="point-list" onClick={this.onToggleSchedule}>
                {this.props.scheduleActive
                  ? "ESCONDER GRÁFICOS AGENDADOS"
                  : "VISUALIZAR GRÁFICOS AGENDADOS"}
              </Button>
            </Grid>
            <Grid item className="bold-text-container">
              <div>PONTOS COM DADOS DO USUÁRIO</div>
            </Grid>
            <Grid item className="profile-select-container">
              <select
                className="profile-select"
                value={this.state.selectedPoint}
                onChange={this.onPointChange}
              >
                <option key={-1} value={-1}>
                  ------
                </option>
                {uploadedFarms.map((point) => (
                  <option key={point.initials} value={point.farm_id}>
                    {point.name === ""
                      ? "Ponto " + point.initials
                      : point.initials + " - " + point.name}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item className="bold-text-container">
              <Grid container>
                <Grid item xs={8}>
                  <div className="bold">DATA ÚLTIMO UPLOAD:</div>
                </Grid>
                <Grid item xs={4}>
                  {generalInfo.last_upload ? (
                    generalInfo.last_upload
                  ) : (
                    <div>--/--/----</div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="point-container">
              <Button className="point-list" onClick={this.onClick}>
                VISUALIZAR E EDITAR DADOS
              </Button>
            </Grid>
          </Grid>
          <Grid item className="line" />
          <Grid
            item
            container
            alignItems="center"
            justify="center"
            direction="column"
            className="user-download"
          >
            <Grid item className="download-container">
              <Button className="button" onClick={this.openDownloadModal}>
                DOWNLOAD DE DADOS WEATHER
              </Button>
            </Grid>
            <Grid item className="download-container">
              <Button className="button" onClick={this.onToggleKmz}>
                ARQUIVOS KMZ
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    generalInfo: state.profile.generalInfo,
    uploadedFarms: state.profile.uploadedPoints,
    listedPoint: state.global.listedPoint,
    scheduleActive: state.global.scheduleActive,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setListedPoint: (point) => {
      dispatch(setListedPoint(point));
    },
    toggleSchedule: () => {
      dispatch(toggleSchedule());
    },
    toggleKmz: () => {
      dispatch(toggleKmz());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileBar);
