import React, { Component } from "react";
import IndicatorColor from "./IndicatorColor";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  changeMapDay,
  generateMapData,
  setMapYear,
} from "../../../actions/map";
import { generatingMapData, toggleLeftLoader } from "../../../actions/global";

class DetailPopover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeField: undefined,
    };
  }

  //Lifecycle methods

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      activeField: undefined,
    });
  }

  //#region Helper methods

  setDay(dec, index) {
    if (dec === 1) {
      return 1 + parseInt(index, 10);
    } else if (dec === 2) {
      return 11 + +parseInt(index, 10);
    } else {
      return 21 + +parseInt(index, 10);
    }
  }

  setDate(day, month, year) {
    if (month < 10) {
      month = "0" + month.toString();
    }
    if (day < 10) {
      day = "0" + day.toString();
    }
    return year.toString() + "-" + month + "-" + day.toString();
  }

  predictionIndicatorDetail(dec, month, year, day) {
    let currentDate = new Date();
    if (
      currentDate.getFullYear() <= year ||
      (this.props.visualizationType === "agricultural" &&
        currentDate.getFullYear() === year + 1)
    ) {
      if (currentDate.getMonth() + 1 === month) {
        return true;
      } else {
        if (
          currentDate.getDate() <= dec * 10 + day + 1 &&
          currentDate.getMonth() === month
        ) {
          return true;
        }
        if (currentDate.getMonth() === 0 && month === 0) {
          return true;
        }
      }
    }
    return false;
  }

  //#region Render methods

  getStyle = () => {
    var elInfos = this.props.element.getBoundingClientRect();
    let x = elInfos.right - elInfos.width,
      y = elInfos.top,
      width = elInfos.width;

    let right = window.innerWidth - x - 150 - width / 2;
    let diff = 0;
    if (right < 0) {
      diff = 0 - right;
      right = 0;
    } else if (window.innerWidth - right < 300) {
      diff = window.innerWidth - 300 - right;
      right = window.innerWidth - 300;
    }

    return {
      popover: {
        top: y - 16,
        right: right,
      },
      square: {
        width: width + 2,
        left: 151 - width / 2 - 2 + diff,
      },
      arrow: {
        left: 151 - 16 + diff,
      },
    };
  };

  onClick = (el) => {
    let day = this.setDay(this.props.bhboxDate.dec, el.target.id);
    let date = this.setDate(
      day,
      this.props.bhboxDate.month,
      this.props.bhboxDate.year
    );
    let month = "";
    if (this.props.bhboxDate.month < 10) {
      month = "0" + this.props.bhboxDate.month.toString();
    } else {
      month = this.props.bhboxDate.month;
    }
    if (day < 10) {
      day = "0" + day.toString();
    }
    let newDay = {
      label: day.toString() + "/" + month.toString(),
      variable: this.props.agrytoolsType,
      year: this.props.bhboxDate.year.toString(),
    };
    let attrs = {
      initial_date: date,
      final_date: date,
      type: "period",
      range: "daily",
      cad: this.props.lastUsedCad,
      visualization_type: this.props.visualizationType,
    };
    this.props.setMapDay(newDay);
    this.props.setMapYear(newDay["year"]);
    this.props.generatingMapData();
    this.props.generateMapData(this.props.agrytoolsType, attrs);
    this.props.toggleLeftLoader();
    this.setState({
      activeField: el.target.id,
    });
  };

  render() {
    const style = this.getStyle();
    const size = this.props.data.length;
    var predictionPosition = -1;
    return (
      <div className="detail-popover" style={style.popover}>
        <div className="active-indicator" style={style.square}></div>
        <div className="indicator-arrow" style={style.arrow}></div>
        <span
          className="detail-popover--close"
          onClick={this.props.disablePopover}
        >
          <i className="material-icons">clear</i>
        </span>
        <div className="detail-popover--item">
          <p className="detail-popover--item-day">dia</p>
        </div>
        {this.props.data.map((data, index) => {
          if (
            this.predictionIndicatorDetail(
              this.props.dec,
              this.props.month,
              this.props.year,
              index
            )
          ) {
            if (predictionPosition === -1) {
              predictionPosition = 0;
            } else if (index + 1 === size) {
              predictionPosition = 3;
            } else {
              predictionPosition = 1;
            }
          }
          return (
            <div className="detail-popover--item">
              <p className="detail-popover--item-day">
                {this.props.dec * 10 + index + 1}
              </p>
              <div
                className={classnames({
                  "prediction-indicator": this.predictionIndicatorDetail(
                    this.props.dec,
                    this.props.month,
                    this.props.year,
                    index
                  ),
                })}
              >
                <IndicatorColor
                  soil={this.props.soil}
                  agrytoolsType={this.props.agrytoolsType}
                  id={index.toString()}
                  active={this.state.activeField}
                  predictionDetail={predictionPosition}
                  data={data}
                  onClick={this.onClick}
                  last={index + 1 === size}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bhboxDate: state.map.bhboxDate,
    lastUsedCad: state.Reports.lastUsedCad,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMapDay: (day) => {
      dispatch(changeMapDay(day));
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes, attrs));
    },
    generatingMapData: () => {
      dispatch(generatingMapData());
    },
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader());
    },
    setMapYear: (year) => {
      dispatch(setMapYear(year));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPopover);
