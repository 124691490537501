import React, { useEffect, useLayoutEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import ProductivityApi from "../../../../api/productivity";
import { useDispatch, useSelector } from "react-redux";
import {
  resetModalState,
  setIsUsingCustomizedModal,
  setTch,
  setUsePreviousCrop,
} from "../../../../actions/GeneralCaneProductivityAction";
import { generateProductivity } from "../../../../actions/productivity";

export function Step4GeneralCaneModal({
  handleBack,
  pointId,
  onClickCalculateGeneralCaneModal,
  onClose,
  crop,
  selectedFarm
}) {
  const [isCalculatorButtonDisabled, setIsCalculatorButtonDisabled] =
    useState(true);

  const dispatch = useDispatch();

  const {
    tch,
    usePreviousCrop,
    monthsForThirdStepTable,
    period,
    cropYear,
    visualizationType,
    cropInfo,
  } = useSelector((state) => state.generalCaneProductivityReducer);

  const { cad } = useSelector((state) => state.productivity);

  useEffect(() => {
    setIsCalculatorButtonDisabled(
      usePreviousCrop
        ? !usePreviousCrop
        : Object.entries(monthsForThirdStepTable).some(
            (month) => tch[month[1].id] === undefined
          )
    );
  }, [tch, monthsForThirdStepTable, usePreviousCrop]);

  useEffect(() => {
    dispatch(setUsePreviousCrop(false));
  }, [dispatch]);

  useLayoutEffect(() => {
    const getObservedProductivityAPI = async () => {
      const objectToGetObservedProductivity = {
        point_id: pointId,
        initial_month: period.initialMonth,
        final_month: period.finalMonth,
        crop_year: cropYear - 1,
        visualization_type: visualizationType,
        prod_type: "sugarcane",
      };
      const { data } = await ProductivityApi.getObservedProductivityData(
        objectToGetObservedProductivity
      );
      if (data?.monthly_data) dispatch(setTch(data.monthly_data));
    };

    getObservedProductivityAPI();
  }, [cropYear, visualizationType, period.finalMonth, period.initialMonth, pointId, dispatch]);

  const handleChange = (event, index) => {
    const {
      target: { name, value },
    } = event;
    if (name !== undefined && name === "checkBoxTch") {
      dispatch(setUsePreviousCrop(!usePreviousCrop));
    } else {
      if (value !== undefined) {
        dispatch(
          setTch({
            [index]: value,
          })
        );
      }
    }
  };

  const defineMonthStringBasedOnIdMonthNumber = (monthId) => {
    switch (monthId) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        return 0;
    }
  };

  const handleClickOnCalculate = async () => {
    dispatch(resetModalState());

    const tchConvertStringNumbersToFloat = {};
    if (tch.length !== 0) {
      for (let index = 0; index < Object.entries(tch).length; index += 1) {
        tchConvertStringNumbersToFloat[Object.entries(tch)[index][0]] = Number(
          Object.entries(tch)[index][1]
        );
      }
    }

    const objectToPostObservedProductivity = {
      point_id: pointId,
      crop_year: cropYear - 1,
      visualization_type: visualizationType,
      prod_type: "sugarcane",
      monthly_data: tchConvertStringNumbersToFloat,
    };

    await ProductivityApi.postObservedProductivityData(
      objectToPostObservedProductivity
    );
    const averageTch = (
      Object.values(tch)
        .reduce((acc, curr) => Number(acc) + Number(curr), 0) / Object.values(tch).length
    ).toFixed(1);

    const objectToRequestRecalculateBasedOnSelectOnSecoundStep = {
      initialMonth: defineMonthStringBasedOnIdMonthNumber(period.initialMonth),
      finalMonth: defineMonthStringBasedOnIdMonthNumber(period.finalMonth),
      kdec: cropInfo.kdec,
      cycle: cropInfo.cycle,
      crop: cropYear === new Date().getFullYear() ? "Atual" : "Próxima",
      visualization_type: visualizationType,
      usePreviousCrop,
      tch: Object.entries(tch).map((value) => [Number(value[0]), value[1]]),
      averageTch,
    };

    dispatch(setIsUsingCustomizedModal(true));

    const [lat, lon] = selectedFarm.coords.split(',');
    const crop_year = crop === "Atual"
      ? new Date().getFullYear() : new Date().getFullYear() + 1;

    const data = {
      cad,
      lat,
      lon,
      crop_year,
      visualization_type: visualizationType,
      frost_flag: true,
      point_id: selectedFarm.id,
      initial_month: 0,
      final_month: 11,
    };

    await dispatch(generateProductivity(data))

    onClickCalculateGeneralCaneModal(
      objectToRequestRecalculateBasedOnSelectOnSecoundStep
    );

    onClose();
  };

  return (
    <div style={{ margin: "0 0 69px 170px" }}>
      <label>
        <Checkbox
          name="checkBoxTch"
          checked={usePreviousCrop}
          value={usePreviousCrop}
          onClick={handleChange}
        />
        Usar as safras anteriores
      </label>
      {usePreviousCrop ? (
        <div></div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "81.4%",
          }}
        >
          <span style={{ margin: "48px 0 0 15px", fontSize: "16px" }}>TCH</span>
          <table style={{ width: "80%" }}>
            <thead>
              <tr>
                {monthsForThirdStepTable.map((month) => (
                  <th
                    style={{
                      backgroundColor: "rgb(106, 164, 38)",
                      color: "white",
                      borderRadius: "0",
                      padding: "6px 8px",
                    }}
                    key={month.id}
                  >{`${month.name.substring(3, 0)}.`}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tch && (
                <tr style={{ backgroundColor: "#F2F2F2", padding: "0px" }}>
                  {Object.entries(monthsForThirdStepTable).map((month) => {
                    return (
                      <th style={{ padding: "0px" }} key={`tch_${month[1].id}`}>
                        <input
                          type="text"
                          name="tch"
                          value={tch[month[1].id]}
                          onChange={(event) => handleChange(event, month[1].id)}
                          style={{ borderBottom: "none", margin: "0px" }}
                        />
                      </th>
                    );
                  })}
                </tr>
              )}
            </tbody>
          </table>
          <div>
            <p
              style={{
                backgroundColor: "rgb(106, 164, 38)",
                color: "white",
                margin: "0",
                padding: "6px 8px",
              }}
            >
              Média
            </p>
            <p
              style={{
                textAlign: "center",
                margin: "0",
                padding: "17px 0 17px 0",
                backgroundColor: "#F2F2F2",
              }}
            >
              {Object.entries(tch).length === 0
                ? 0
                : tch &&
                  (
                    Object.values(tch).reduce((acc, curr) => {
                      return Number(acc) + Number(curr);
                    }, 0) / Object.values(tch).length
                  ).toFixed(1)}
            </p>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "67px 18.5% 18px 0",
        }}
      >
        <Button
          style={{
            color: "#707070",
            width: "170px",
            height: "33px",
          }}
          disableRipple
          onClick={() => {
            handleBack();
            dispatch(setUsePreviousCrop(true));
          }}
        >
          Voltar
        </Button>
        <Button
          disabled={isCalculatorButtonDisabled}
          style={{
            backgroundColor: "rgb(106, 164, 38)",
            color: "white",
            width: "170px",
            height: "33px",
          }}
          disableRipple
          variant="contained"
          onClick={handleClickOnCalculate}
        >
          Calcular
        </Button>
      </div>
    </div>
  );
}
