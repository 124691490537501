import { createMuiTheme } from "@material-ui/core";

const calendarTheme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        "&$checked": {
          color: "rgb(106, 164, 38) !important",
        },
      },
    },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: "rgb(106, 164, 38)",
        },
        "&$active": {
          color: "rgb(106, 164, 38)",
        },
      },
      active: {},
      completed: {},
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "rgb(106, 164, 38)",
      },
      margin: "0 0 50px 0",
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: "rgb(106, 164, 38)",
      },
      current: {
        color: "rgb(255, 255, 255)",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "green",
      },
    },
  },
});

export default calendarTheme;
