export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const TOGGLE_MORE_FARMS = 'TOGGLE_MORE_FARMS'
export const SET_CAD_ERROR = 'SET_CAD_ERROR'
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const TOGGLE_LEFT_LOADER = 'TOGGLE_LEFT_LOADER'
export const TOGGLE_SIDEBAR_LOADER = 'TOGGLE_SIDEBAR_LOADER'
export const GENERATING_MAP = 'GENERATING_MAP'
export const FIX_USER_FLOW = 'FIX_USER_FLOW'
export const TOGGLE_ATR_HELP = 'TOGGLE_ATR_HELP'
export const TOGGLE_CORRECT_TYPES_FLAG = 'TOGGLE_CORRECT_TYPES_FLAG'
export const TOGGLE_TOOLTIPS = 'TOGGLE_TOOLTIPS'
export const TOGGLE_PROFILE_BAR = 'TOGGLE_PROFILE_BAR'
export const TOGGLE_SCHEDULE = 'TOGGLE_SCHEDULE'
export const TOGGLE_KMZ = 'TOGGLE_KMZ'
export const SET_LISTED_POINT = 'SET_LISTED_POINT'
export const CHANGE_VISUALIZATION_YEAR = 'CHANGE_VISUALIZATION_YEAR'
export const CHANGE_FILTER_PARAMS = 'CHANGE_FILTER_PARAMS'
export const SET_USER_PLAN = 'SET_USER_PLAN'

export function toggleSidebar(){
  return{type: TOGGLE_SIDEBAR}
}

export function toggleProfileBar(){
  return{type: TOGGLE_PROFILE_BAR}
}

export function toggleSchedule(){
  return{type: TOGGLE_SCHEDULE}
}

export function toggleKmz() {
  return { type: TOGGLE_KMZ };
}

export function setListedPoint(point){
  return{type: SET_LISTED_POINT, point}
}

export function generatingMapData(){
  return{type: GENERATING_MAP}
}

export function toggleMoreFarms(){
  return{type: TOGGLE_MORE_FARMS}
}

export function setCadError(){
  return{type: SET_CAD_ERROR}
}

export function toggleLoader(){
  return{type: TOGGLE_LOADER}
}

export function toggleLeftLoader(){
  return{type: TOGGLE_LEFT_LOADER}
}

export function toggleSidebarLoader(){
  return{type: TOGGLE_SIDEBAR_LOADER}
}

export function toggleAtrHelp(){
  return{type: TOGGLE_ATR_HELP}
}

export function changeVisualizationYear(visualization){
  return{type: CHANGE_VISUALIZATION_YEAR, visualization}
}

export function changeFilterParams(filterParams) {
  return{type: CHANGE_FILTER_PARAMS, filterParams}
}

//THIS IS A HACK CHANGE LATER
export function fixUserFlow(){
  return{type: FIX_USER_FLOW}
}

export function toggleCorrectTypesFlag(){
  return {type: TOGGLE_CORRECT_TYPES_FLAG}
}

export function toggleTooltips(){
  return {type: TOGGLE_TOOLTIPS}
}

export function setUserPlan(plan){
  return {type: SET_USER_PLAN, plan}
}
