import React, { Component } from 'react'

import {Grid, Paper, Select, MenuItem, FormControl, IconButton, Hidden} from '@material-ui/core'


class UploadForm extends Component {
  render() {
      return(
        <Paper className='upload-form'>
          <Grid className='upload-form-grid' container justify='space-around' alignItems='center'>
            <Grid item md={5}>
              <FormControl className='point-select-form'>
                <Select
                  disableUnderline={true}
                  className='point-select'
                  value={this.props.selectedPoint} 
                  onChange={this.props.onChange} 
                  inputProps={{
                    name: 'point',
                    id: 'point'
                  }}>
                  <MenuItem value="0" disabled>Selecione um ponto</MenuItem>
                  {this.props.farms['farms'].map(farm => {
                    return(
                      <MenuItem value={farm.id}>{farm.initials} - {farm.name}({farm.coords})</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Hidden xsDown>
              <Grid item xs={0} md={1} className='vertical-line'/>
            </Hidden>            
            <Grid item md={5}>
              <Grid container direction='column' className='upload-user-data'>
                <Grid item md={6}>
                  <span className='input-file-name'>
                    {!!this.props.fileName ? this.props.fileName.name : 'Selecione o arquivo'}
                  </span>
                </Grid>
                <Grid item md={6} className='upload-context'>
                  <Grid container>
                    <Grid item>
                        <input
                          className='input-user-data'
                          type="file"
                          id='data'
                          name='data'
                          accept='.xlsx'
                          data-show-preview="false"
                          placeholder={'Insira os dados metereológicos do ponto'}
                          onChange={this.props.onChange} 
                        />
                        <label htmlFor="data">
                          <IconButton color="primary" component="span">
                            <i className='upload-icon material-icons'>cloud_upload</i>
                          </IconButton>
                        </label>
                    </Grid>
                    {!!this.props.fileName ?
                      <Grid item>
                        <IconButton color="primary" onClick={this.props.onSubmit}>
                          <i className='upload-icon material-icons'>cloud_done</i>
                        </IconButton>
                      </Grid>
                    :
                      null
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>         
        </Paper>
      )
  }

}

export default UploadForm



