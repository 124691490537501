import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from "react-toastify";

import { Grid, Typography, Button } from "@mui/material";

import SearchDropdown from "../../common/SearchDropdown";
import InputField from "../../common/InputField";
import Loader from '../../common/Loader';
import { showToaster } from '../../common/Toaster';
import AvailablePoints from './AvailablePoints';

import { getUsersList } from '../../../actions/admin';

import AdminApi from '../../../api/admin';

function AdminPointsTransfer() {
  const [info, setInfo] = useState({
    source: null,
    target: null,
    date: '',
    points: [],
  });
  const [isLoading, setLoader] = useState(false);

  const users = useSelector((state) => state.admin.usersList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  // useEffect(() => {
  //   if (info.source) {
  //     dispatch(adminGetPoints(info.source.value));
  //   }
  // }, [dispatch, info.source]);

  const handleChange = (e) => {
    setInfo({ ...info, [e.name]: e });
  };

  const handleDate = ({ target: { name, value } }) => {
    setInfo({ ...info, [name]: value });
  };

  const getSourceOptions = () => {
    return users.length
      ? users.map((user) => ({ value: user.id, label: user.email, name: 'source', }))
      : [];
  };

  const getTargetOptions = () => {
    return users.length
      ? users.map((user) => ({ value: user.id, label: user.email, name: 'target', }))
      : [];
  };

  const showAlert = (response) => {
    const successResponses = ['Todos os pontos transferidos', 'Pontos especificos transferidos'];
    const wasTransfered = successResponses.includes(response);
    const params = {
      title: wasTransfered ? 'Sucesso!' : 'Erro!',
      message: wasTransfered ? 'Pontos Transferidos' : response,
      autoClose: true,
    };
    showToaster(params);
  };

  const handleClick = async () => {
    const { source, target, date, points: infoPoints } = info;

    const transfer = { source_id: source.value, target_user: target.value, date };

    if (infoPoints.length) {
      transfer.point_ids = infoPoints;
    }

    setLoader(true);
    const response = await AdminApi.transferPoints(transfer);
    setInfo({ ...info, date: '', points: [] });
    setLoader(false);
    showAlert(response);
  };

  const isDisabled = () => {
    const { source, target, date } = info;
    return !source || !target || !date || source.value === target.value;
  };

  return (
    <div className='row admin-information' style={{ margin: '20px', width: '70%' }}>
      <Loader active={isLoading} />
      <ToastContainer
        style={{ marginTop: "36px", textAlign: "left", width: "376px" }}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component="h5" variant="h5">
            Transferência de Pontos de Usuário
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h6" variant="subtitle1">
            Selecione o usuário de origem:
          </Typography>
          <SearchDropdown
            name="source"
            className="dataset-select"
            value={info.source_id}
            onChange={handleChange}
            isSearchable
            options={getSourceOptions()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h6" variant="subtitle1">
            Selecione o usuário destino:
          </Typography>
          <SearchDropdown
            name="target"
            className="dataset-select"
            value={info.target_user}
            onChange={handleChange}
            isSearchable
            options={getTargetOptions()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h6" variant="subtitle1">
            Selecione o periodo no formato AAAA-MM-DD:
          </Typography>
          <InputField
            name="date"
            label="Data Inicial"
            type="text"
            onChange={handleDate}
            value={info.date}
            error={false}
            disabled={false}
            active
            activeFarm={"a"}
          />
        </Grid>
        <Grid item xs={12}>
          { info.source && <AvailablePoints info={info} setInfo={setInfo} /> }
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: isDisabled() ? "rgb(194, 194, 194)" : "#6aa426",
              color: "white",
              padding: "9px 93px",
            }}
            disabled={isDisabled()}
            onClick={handleClick}
          >
            Transferir
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default AdminPointsTransfer;
