import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export function PointsListNewComponent({ points, deleteSelectedItem }) {
  const onRowClick = (rowInfo) => {
    deleteSelectedItem(rowInfo);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 300 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{ backgroundColor: "#6AA426", color: "white" }}>
                Latitude
              </TableCell>
              <TableCell style={{ backgroundColor: "#6AA426", color: "white" }}>
                Longitude
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {points.map((row, index) => (
              <TableRow
                onClick={() => onRowClick(row)}
                key={index}
                style={{ cursor: "pointer" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{row.lat}</TableCell>
                <TableCell align="left">{row.lng}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
