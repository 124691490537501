import React, { Component } from 'react'

import InputField from '../common/InputField'
import { Grid, Button, Checkbox } from '@material-ui/core';
import ProductManagement from './user-edit-page/ProductManagement'
import {AgrymaxApiManagement} from './user-edit-page/AgrymaxApiManagement'
import PlanManagement from './user-edit-page/PlanManagement'
import ProductManagementSamartPremium from './user-edit-page/ProductManagementSamartPremium';


class UserForm extends Component{
  constructor(props){
    super(props)

    this.state = {
      selectedFarm: [],
      activeProduct: 0,
      caneSelected: false,
      soySelected: false
    }
  }

  render(){

    return(
      <Grid container className='users-form-container'>
        <Grid item xs={12}>
          <Grid container justify='space-between'>
            <Grid item justify='center' alignItems='center'>
              <Button
                className='button'
                variant='contained'
                onClick={this.props.returnToList}>
                  <i className='material-icons'>arrow_back_ios</i>
              </Button>
            </Grid>
            <Grid item>
              {!this.props.editMode ?
                <Button
                  className='button'
                  variant='contained'
                  onClick={this.props.toggleEditEvent}>
                    <i className='material-icons'>mode_edit</i>
                </Button>
              :
                <Grid container spacing={16}>
                  <Grid item>
                    <Button
                      className='button'
                      variant='contained'
                      onClick={this.props.toggleEditEvent}>
                        <i className='material-icons'>close</i>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className='green-button'
                      variant='contained'
                      onClick={this.props.editSubmit}>
                        <i className='material-icons'>check</i>
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container className='users-form-container-title'>
            <Grid item>
              <Checkbox
                checked={this.props.item.confirmed_at === 'Sim'}
                className='checkbox'
                onClick={this.props.setUserStatus}
              />
            </Grid>
            {this.props.item.confirmed_at === 'Sim' ?
              <Grid item>
                ID: {this.props.item.id} - Usuário Ativo (Ultimo login: {this.props.item.last_login})
              </Grid>
            :
              <Grid item>
                ID: {this.props.item.id} - Usuário Inativo (Ultimo login: {this.props.item.last_login})
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12} className="row info-form">
          <form>
            <div className="row">
              <div className="col s6">
                <InputField
                  name='email'
                  label='Email'
                  type='email'
                  onChange={ this.props.onChange }
                  value={ this.props.item.email }
                  error={ undefined}
                  active={ this.props.item.email !== ''}
                  activeFarm={this.props.editMode ? 'a' : ''}
                />
              </div>
              <div className="col s6">
                <InputField
                  name='cpf_cnpj'
                  label='CPF/CNPJ'
                  type='text'
                  value={ this.props.item.cpf_cnpj }
                  error={ undefined }
                  onChange={ this.props.onChange }
                  active={this.props.item.cpf_cnpj !== ''}
                  activeFarm={this.props.editMode ? 'a' : ''}
                  length={19}
                />
              </div>
            </div>
            {this.props.editMode ?
              <div className="row user-address">
                <div className="col s6">
                  <InputField
                    name='password'
                    label='Nova Senha'
                    type='password'
                    onChange={ this.props.onChange }
                    value={ this.props.password }
                    error={ undefined}
                    active={ this.props.password !== ''}
                    activeFarm={this.props.editMode ? 'a' : ''}
                  />
                </div>
              </div>
            :
              null
            }
          </form>
        </Grid>
        <Grid item xs={12}>
          <AgrymaxApiManagement
            user={this.props.item.id}
            api_key={this.props.item.api_key}
            onChange={ this.props.onChange }
            fromYear={this.props.item.initial_api_year}
            toYear={this.props.item.final_api_year}
            changeYearPeriod={this.props.changeYearPeriod}
            onClickApiButton={this.props.onClickApiButton}
            editMode={this.props.editMode}
          />
        </Grid>
        <Grid item xs={12}>
          {this.props.item.confirmed_at === 'Sim' ?
            <PlanManagement
              changeUserPlan={this.props.changeUserPlan}
              userPlan={this.props.userPlan.agrymax_plan}
              userPlanString={this.props.userPlan.agrymax_plan_string}
              item={this.props.item}
            />
          : null}
        </Grid>
        <Grid item xs={12}>
          {this.props.item.confirmed_at === 'Sim' ?
            this.props.item.agrymax_plan === 'smart premium'?
              <ProductManagementSamartPremium item={this.props.item}/>
              : <ProductManagement item={this.props.item}/>
          : null}
        </Grid>
      </Grid>
    )
  }
}

export default UserForm
