import React from "react";
import { CSVLink } from "react-csv";
export default class DownloadXLS extends React.Component {
    render() {
      if (this.props.data) {
        let style = {};
        const { marginRight } = this.props;

        if (marginRight) {
          style.marginRight = marginRight;
        }

        return (
          <CSVLink
            headers={this.props.headers}
            data={this.props.data}
            filename={this.props.filename || "Relatorio_de_Dados.csv"}
            className="btn btn-primary download-csv-data-button"
            target="_blank"
            style={style}
          >
            <img src={"/images/excel-icon.jpg"} alt="Exportar Dados"/>
          </CSVLink>
        );
      } else {
        return null;
      }
    }
}