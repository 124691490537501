import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ButtonUpload = styled(Button)({
  marginTop: '20px',
  width: '120px',
  height: '30px',
  textTransform: 'none',
  backgroundColor: '#6AA425',
  color: '#FFFFFF',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: '#6AA425',
    transform: 'translate(11px, 1%)',
  },
});
  