import React from 'react';
import HighCharts from '../weather/Boxplot';

class GeneralProductivity extends React.Component {
  render() {
    return (
      <HighCharts
        keys={this.props.keys}
        title={this.props.title}
        pastCropName1={this.props.pastCropName1 ? this.props.pastCropName1 : []}
        pastCropName2={this.props.pastCropName2 ? this.props.pastCropName2 : []}
        pastCropName3={this.props.pastCropName3 ? this.props.pastCropName3 : []}
        pastTchName={this.props.pastTchName}
        currentCrop={this.props.currentCrop}
        currentCropBoxplotValues={this.props.currentCropBoxplotValues}
        pastCropValues1={this.props.pastCropValues1 ? this.props.pastCropValues1 : []}
        pastCropValues2={this.props.pastCropValues2 ? this.props.pastCropValues2 : []}
        pastCropValues3={this.props.pastCropValues3 ? this.props.pastCropValues3 : []}
        pastTchValues={this.props.pastTchValues}
        currentCropPointsValues={this.props.currentCropPointsValues}
        unit={this.props.unit ? this.props.unit : 'ton/ha' }
        pastCropBoxplotValue={this.props.pastCropBoxplotValue ? this.props.pastCropBoxplotValue : []}
        showTooltips={this.props.showTooltips}
        productivityType={this.props.productivityType}
        rangeFlag={this.props.rangeFlag}
      />
    ); 
  } 
}

export default GeneralProductivity;
