import React from 'react'
import Select from 'react-select'

const customStyles = {
    option: (provided) => ({
      ...provided
    }),
    control: (provided, state) => ({
      width: '100%',
      height: '40px',
      display: 'flex',
      borderBottom: state.isFocused ? 'solid 2px #26a69a' : 'solid 1px rgba(0, 0, 0, 0.5)',

    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    indicatorSeparator: () => ({
    })
}

const SearchDropdown = ({ name, className, value, onChange, isSearchable, options }) => {
    return (
        <Select
            name={name}
            className={className}
            styles={customStyles}
            value={value}
            onChange={onChange}
            isSearchable={isSearchable}
            options={options}>
        </Select>
    )
}

export default SearchDropdown
