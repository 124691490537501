import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addEnos, editEnos, resetEnosStatus } from '../../actions/admin'
import { toggleLoader } from '../../actions/global'
import EnosForm from './EnosForm'
import Loader from '../common/Loader'

class AdminAddEnos extends Component{

  constructor(props){
    super(props)
    this.state={
      newEnos: {
        year: '',
        civilYear: '',
        agriYear: '',
        civilSem: '',
        agriSem: ''
      },
      isEditing: false
    }
  }

  componentDidMount(){
    if(this.props.history.location.state){
      this.setState({
        newEnos: this.props.history.location.state.item,
        isEditing: true
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.loader === true && nextProps.enosStatus !== 'Erro' ){
      this.props.toggleLoader()
      this.props.resetEnosStatus()
      this.returnToList()
    }
  }

  onEnosFromChange = (event) => {
    let newObject = Object.assign({}, this.state.newEnos)
    newObject[event.target.name] = event.target.value
    this.setState({
      newEnos: newObject
    })
  }

  returnToList = () => {
    this.props.history.push(`/admin/enos`)
  }

  addSubmit = (e) => {
    e.preventDefault()
    if(this.state.isEditing){
      this.props.editEnos(this.state.newEnos)
    }
    else{
      this.props.addEnos(this.state.newEnos)
    }
    this.props.toggleLoader()
  }

  render(){
    return(
      <div className='row admin-information'>
        <Loader active={this.props.loader} />
        <EnosForm
          item={this.state.newEnos}
          isEditing={this.state.isEditing}
          returnToList={this.returnToList}
          onChange={this.onEnosFromChange}
          addSubmit={this.addSubmit}
        />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    usersList: state.admin.usersList,
    enosStatus: state.admin.enosStatus,
    loader: state.global.loader
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addEnos: (newEnos) => {
      dispatch(addEnos(newEnos))
    },
    editEnos: (newEnos) => {
      dispatch(editEnos(newEnos))
    },
    resetEnosStatus: () => {
      dispatch(resetEnosStatus())
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAddEnos)
