import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'

// Components
import FarmItem from './FarmItem'

//actions
import {toggleSidebar, toggleMoreFarms} from '../actions/global'
import { setActiveFarm } from '../actions/farms'
import { toggleReportContainer } from '../actions/reports'

class FarmsList extends Component {

  onMoreFarmsClick = () => {
    this.props.toggleMoreFarmsActive()
    if(!this.props.sidebarActive){
      this.props.toggleSidebar()
    }

    if(this.props.reportActive){
      this.props.toggleReportContainer()
    }
  }

  getChecked(farm){
    return farm.id === this.props.activeFarm
  }

  render() {
    if(this.props.farms !== undefined){
      if(this.props.farms['farms'].length >= 8){
        return(
          <ul>
            { this.props.farms['farms'].slice(0,7).map(farm =>
              <FarmItem key={farm.id} farmName={farm.name} value={ farm.id } initials={farm.initials} selectFarm={ this.props.selectFarm } checked={this.getChecked(farm)}/>
            ) }
            <li>
              <button
                className={classnames('button button--more-farms', {'button--more-farms-active': this.props.moreFarmsActive})}
                onClick={this.onMoreFarmsClick}
                disabled={this.props.moreFarmsActive ? 'disabled' : ''}
                title='Mostrar Todos os Pontos'
                >
                <i className='material-icons'>line_weight</i>
              </button>
            </li>
          </ul>
        )
      }else{
        return(
          <ul>
            { this.props.farms['farms'].map(farm =>
              <FarmItem key={farm.id} farmName={farm.name} value={ farm.id } initials={farm.initials} selectFarm={ this.props.selectFarm } checked={this.getChecked(farm)}/>
            ) }
          </ul>
        )
      }
    }else return(null)
  }
}



function mapStateToProps(state) {
  return {
    farms: state.farms.points,
    activeFarm: state.farms.activeFarm,
    sidebarActive: state.global.sidebarActive,
    reportActive: state.Reports.reportActive,
    moreFarmsActive: state.global.moreFarmsActive
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMoreFarmsActive: () =>{
      dispatch(toggleMoreFarms())
    },
    toggleSidebar: () =>{
      dispatch(toggleSidebar())
    },
    setActiveFarm: (id) =>{
      dispatch(setActiveFarm(id))
    },
    toggleReportContainer: () => {
      dispatch(toggleReportContainer())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmsList)
