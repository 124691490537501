const initialState = {
    keys: {},
    stations: [],
    pagination: {},
    points: [],
    status: '',
    message: '',
    integrationStatus: '',
    update: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case "GET_API_KEYS":
            return Object.assign({}, state, { keys: action.keys.data })
        case "SET_KEYS_SUCCESS":
            return Object.assign({}, state, { status: action.response.status, message: action.response.message })
        case "EDIT_KEYS_SUCCESS":
            return Object.assign({}, state, { status: action.response.status, message: action.response.message })
        case "DELETE_KEYS_SUCCESS":
            return Object.assign({}, state, { status: action.response.status, message: action.response.message })
        case "GET_STATIONS_SUCCESS":
            return Object.assign({}, state, { stations: action.stations.data, status: action.stations.status, message: action.stations.message, pagination: action.stations.pagination })
        case "SET_STATION_SUCCESS":
            return Object.assign({}, state, { status: action.response.status, message: action.response.message })
        case "EDIT_STATION_SUCCESS":
            return Object.assign({}, state, { status: action.response.status, message: action.response.message })  
        case "DELETE_STATION_SUCCESS":
            return Object.assign({}, state, { status: action.response.status, message: action.response.message })
        case "UPDATE_INTEGRATION_SUCCESS":
            return Object.assign({}, state, { integrationStatus: action.response.status, message: action.response.message })
        case "PAGE_UPDATE":
            return Object.assign({}, state, { update: true })
        case "UPDATE_RESET":
            return Object.assign({}, state, { update: false})
        case "GET_POINTS_SUCCESS":
            return Object.assign({}, state, { points: action.points.data })
        case "RESET_MESSAGE":
            return Object.assign({}, state, { message: '', integrationStatus: ''})
        case "GENERAL_ERROR":
            return Object.assign({}, state, { status: 0, message: "Por favor tente novamente."})
        default:
            return state
    }
}