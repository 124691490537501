import React, { Component } from 'react'

class ApiErrorBoundarie extends Component {
  constructor(props){
    super(props)

    this.state = {
      hasError: false
    }
  }

  componentDidCatch(error, info){
    this.setState({
      hasError: true
    })
  }

  render(){
    if(this.state.hasError){
      return(
        <div className="api-error-boundarie">
          <icon className='material-icons api-error-icon'>highlight_off</icon>
          <h3>Oops! Algo deu errado!</h3>
          <h6>Um erro ocorreu ao tentar carregar o que você pediu. Por favor, recarregue a página.</h6>
        </div>
      )
    }else{
      return this.props.children
    }
  }
}

export default ApiErrorBoundarie
