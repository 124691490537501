import React from 'react'
import {AreaChart, Area, XAxis, YAxis,ResponsiveContainer,CartesianGrid, Tooltip, Legend, Label} from 'recharts'
import CustomizedAxisTick from '../reports/CustomizedAxisTick';

export default function EvapotranspirationChart(props) {

    const name = 'Evapotranspiração (mm)'
    let interval = 'daily'
    let tickCount = 36
    let domain = [0, 10, 11]

    return(
        <ResponsiveContainer width="100%" height="100%" className='complementary-graphs'>
            <AreaChart data={props.data} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                <XAxis dataKey="name" tick={<CustomizedAxisTick  graphicRange={'daily'}/>} interval={interval} height={80} tickCount={tickCount}/>
                <YAxis tick={{fontSize: '1em'}} interval={0} tickCount={domain[2]} domain={[domain[0], domain[1]]}>
                <Label angle={-90} value={name} position='insideLeft' offset={10} style={{textAnchor: 'middle'}} />
                </YAxis>
                <CartesianGrid strokeDasharray="1 1"/>
                {props.showTooltips ? <Tooltip/> : null}
                <Label value='Previsão'/>
                <Area isAnimationActive={false} type='linear' dataKey={'ETP'} stroke={'#4daf4a'} fillOpacity={1} fill={'#4daf4a'} unit='mm' legendType='square'/>
                <Area isAnimationActive={false} type='linear' dataKey={'ETR'} stroke={'#a2ef9d'} fillOpacity={0.8} fill={'#a2ef9d'} unit='mm' legendType='square'/>
                <Legend margin={{top: 20, left: 0, right: 0, bottom: 0}}
                        align='center'
                        verticalAlign='top'
                />
            </AreaChart>
        </ResponsiveContainer>
    )


}
