import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUsersList, setUserStatus, editUser, createApiKey, deleteApiKey, setApiDates, resetServerMessage} from '../../actions/admin'
import UserForm from './UserForm'

import Loader from "../common/Loader"
import { ToastContainer, toast } from 'react-toastify'
import { showToaster } from "../common/Toaster";


class AdminEditUser extends Component{

  constructor(props){
    super(props)
    this.state={
      selectedId: parseInt(window.location.pathname.split('/')[4], 10),
      selectedItem: undefined,
      password: '',
      selectedItemEditAux: undefined,
      editFlag: false,
    }
  }

  componentDidMount(){
    if(this.props.usersList === undefined){
      this.props.getUsersList()
    }else{
      let selected = this.props.usersList.find(user => user.id === this.state.selectedId)

      this.setState({selectedItem: selected})
    }
  }

  InputFieldValue(plan) {
    switch (plan) {
      case "smart premium": return "Smart Premium"
      case "premium": return "Premium"
      default: return "Básico"
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.usersList !== undefined && this.state.selectedItem !== undefined){
      let object = nextProps.usersList.filter(user => user.id === this.state.selectedItem['id'])
      object[0].agrymax_plan_string = this.InputFieldValue(object[0].agrymax_plan)
      let keys = Object.keys(this.state.selectedItem)
      let flag = false
      keys.forEach((key) =>{
        if(object[0][key] !== this.state.selectedItem[key]){
          flag = true
        }
      })

      if(flag){
        this.props.getUsersList()
        this.setState({
          selectedItem: object[0]
        })
      }
    }else if(nextProps.usersList !== undefined && this.state.selectedItem === undefined){
      let selected = nextProps.usersList.find(user => user.id === this.state.selectedId)
      this.setState({selectedItem: selected})
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.serverStatus === '200' && this.props.serverStatus !== prevProps.serverStatus){
      const toasterParams = {
        title: 'SUCESSO!',
        message: this.props.serverMessage,
        status: true,
        autoClose: true,
      }    
      showToaster(toasterParams)
      this.props.resetServerMessage()
    }
  }

  toggleEditEvent = (e) => {
    e.preventDefault()
    let newObject = undefined
    if(this.state.selectedItemEditAux === undefined){
      newObject = Object.assign({}, this.state.selectedItem)
      this.setState({
        editFlag: !this.state.editFlag,
        selectedItemEditAux: newObject
      })
    }else{
      newObject = Object.assign({}, this.state.selectedItemEditAux)
      this.setState({
        editFlag: !this.state.editFlag,
        selectedItemEditAux: undefined,
        selectedItem: newObject,
        password: ''
      })
    }
  }

  onClickApiButton = () => {
    if(this.state.selectedItem.api_key){
      const toasterParams = {
        title: 'DELETAR A CHAVE?',
        message: 'O usuário perderá todas as conexões externas existentes.',
        status: false,
        autoClose: false,
        buttons: [
          {
            name: 'EXCLUIR',
            style: 'delete',
            func: this.confirmApiDeletion,
          },
          {
            name: 'CANCELAR',
            func: toast.dismiss
          }
        ]
      }
  
      showToaster(toasterParams)
      return
    }
    else{
      let user = this.state.selectedItem.id
      this.props.createApiKey(user)
    }    
  }

  confirmApiDeletion = () => {
    let user = this.state.selectedItem.id

    this.props.deleteApiKey(user)
  }

  changeYearPeriod = () => {
    let message
    const data = {
        user: this.state.selectedItem.id,
        fromYear: this.state.selectedItem.initial_api_year,
        toYear: this.state.selectedItem.final_api_year
    }

    if(!data.fromYear){
      message = 'O ano inicial está vazio.'
    } else if(!data.toYear){
      message = 'O ano final está vazio.'
    } else if(data.fromYear < '1980'){
      message = 'O ano inicial deve partir de 1980'
    } else if(data.toYear < data.fromYear){
      message = 'O ano inicial deve ser menor que o final.'
    }

    if(message){
      const toasterParams = {
        title: 'ERRO!',
        message: message,
        status: false,
        autoClose: true,
      }
  
      showToaster(toasterParams)
      return
    }

    this.props.setApiDates(data)
}

  onFormChange = (event) => {
    if(event.target.name === 'password'){
      this.setState({
        password: event.target.value
      })
    }else{
      let newObject = Object.assign({}, this.state.selectedItem)
      newObject[event.target.name] = event.target.value
      this.setState({
        selectedItem: newObject
      })
    }
  }

  onPlanChange = (plan) => {
    let newObject = Object.assign({}, this.state.selectedItem)
    newObject.agrymax_plan_string = this.InputFieldValue(plan)
    newObject.agrymax_plan = plan
    this.setState({
      selectedItem: newObject
    }, () => {
      this.props.editUser(this.state.selectedItem, this.state.password)
    })
  }

  toggleUserActive = (e) => {
    e.preventDefault()
    this.props.setUserStatus(this.state.selectedItem.id)
  }

  editSubmit = (e) => {
    e.preventDefault()
    this.props.editUser(this.state.selectedItem, this.state.password)

    this.setState({
      editFlag: false
    })
  }

  returnToList = () => {
    this.props.history.push(`/admin/users`)
  }

  render(){
    return(
      <div className='row admin-information'>
        <ToastContainer className='toast-alert-container'/>
        <Loader active={this.props.loader} />
        {this.state.selectedItem &&
          <UserForm
            item={this.state.selectedItem}
            toggleEditEvent={this.toggleEditEvent}
            editMode={this.state.editFlag}
            onChange={this.onFormChange}
            password={this.state.password}
            returnToList={this.returnToList}
            setUserStatus={this.toggleUserActive}
            editSubmit={this.editSubmit}
            onClickApiButton={this.onClickApiButton}
            changeYearPeriod={this.changeYearPeriod}
            changeUserPlan={this.onPlanChange}
            userPlan={this.state.selectedItem}
          />
        }
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    usersList: state.admin.usersList,
    serverStatus: state.admin.serverStatus,
    serverMessage: state.admin.serverMessage,
    loader: state.global.loader
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUsersList: () => {
      dispatch(getUsersList())
    },
    setUserStatus: (user) => {
      dispatch(setUserStatus(user))
    },
    editUser: (user, password) => {
      dispatch(editUser(user, password))
    },
    createApiKey: (data) => {
      dispatch(createApiKey(data))
    },
    deleteApiKey: (data) => {
      dispatch(deleteApiKey(data))
    },
    setApiDates: (data) => {
      dispatch(setApiDates(data))
    },
    resetServerMessage: () => {
      dispatch(resetServerMessage())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditUser)
