import React from 'react';
import { Button } from '@mui/material';
import { TextField } from '@material-ui/core'
import MonthSelect from "../components/MonthSelect";

import moment from "moment";
import { showToaster } from "../components/common/Toaster";

const DATE = new Date();
const CURRENT_MONTH = DATE.getMonth() + 1;
const CURRENT_YEAR = DATE.getFullYear();

export default function FilterMap({
  visualizationType,
  graphicRange,
  graphicType,
  filterParams,
  changeFilterParams,
}) {
  const [yearFilter, setYearFilter] = React.useState({
    yearStart: '1980',
    yearStartAgricultural: '80-81',
    yearEnd: `${CURRENT_YEAR}`,
    yearEndAgricultural: CURRENT_MONTH >= 7
      ? `${CURRENT_YEAR.toString().slice(2)}-${(CURRENT_YEAR + 1).toString().slice(2)}`
      : `${(CURRENT_YEAR - 1).toString().slice(2)}-${CURRENT_YEAR.toString().slice(2)}`
  })

  const onDateChange = (e) => {
    const { name, value} = e.target;

    if(graphicRange === 'daily') {
      const format = /\d|[/]/; //regexp for accepting only digits or /
      const lastInput = value[value.length - 1];
  
      if (
        e.target.value.length >= 6 ||
        (!format.test(lastInput) && lastInput !== undefined)
      ) {
        return;
      }
      changeFilterParams({
        ...filterParams,
        [name]: value,
      });
    } else {
      if (graphicRange === 'annualy') {
        setYearFilter({
          ...yearFilter,
          [name]: value,
        });
        return;
      }
      changeFilterParams({
        ...filterParams,
        [name]: value,
      });
    }
  };

  const handlePeriodFilter = () => {
    const { fromPeriod, toPeriod } = filterParams;
    const [startDay, startMonth] = fromPeriod.split('/');
    const [endDay, endMonth] = toPeriod.split('/');
    const months = {
      '01': 'jan', '02': 'fev', '03': 'mar', '04': 'abr', '05': 'mai', '06': 'jun',
      '07': 'jul', '08': 'ago', '09': 'set', '10': 'out', '11': 'nov', '12': 'dez',
    };

    let start;
    let end;

    if (graphicRange === 'monthly') {
      start = months[startMonth];
      end = months[endMonth];
    } else if (graphicRange === 'decendial') {
      if (startDay < 11) {
        start = `${months[startMonth]}_1`;
      } else if (startDay < 21) {
        start = `${months[startMonth]}_2`;
      } else {
        start = `${months[startMonth]}_3`;
      }

      if (endDay < 11) {
        end = `${months[endMonth]}_1`;
      } else if (endDay < 21) {
        end = `${months[endMonth]}_2`;
      } else {
        end = `${months[endMonth]}_3`;
      }
    } else if (graphicRange === 'daily') {
      start = fromPeriod;
      end = toPeriod;
    }

    let fromDate;
    let toDate;

    if (visualizationType === 'civil') {
      fromDate = moment(filterParams.fromPeriod + "/2020", "DD-MM");
      toDate = moment(filterParams.toPeriod + "/2020", "DD-MM");
    } else {
      fromDate = startMonth >= 7
        ? moment(filterParams.fromPeriod + "/2020", "DD-MM-YYYY")
        : moment(filterParams.fromPeriod + "/2021", "DD-MM-YYYY");
      toDate = endMonth >= 7
        ? moment(filterParams.toPeriod + "/2020", "DD-MM-YYYY")
        : moment(filterParams.toPeriod + "/2021", "DD-MM-YYYY");
    }

    if (fromDate.isValid() && toDate.isValid() && toDate > fromDate) {
      changeFilterParams({
        ...filterParams,
        startDate: start,
        endDate: end
      })
    } else if (toDate < fromDate) {
      const toasterParams = {
        title: "Período Inválido!",
        message: "A data final deve ser maior que a data inicial.",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    } else {
      const toasterParams = {
        title: "Data Inválida!",
        message: "Por favor insira datas válidas nos campos",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    }
  };

  const handleYearFilter = () => {
    if (visualizationType !== 'civil') {
      changeFilterParams({
        ...filterParams,
        startYearAgricultural: yearFilter.yearStartAgricultural,
        endYearAgricultural: yearFilter.yearEndAgricultural,
      });
    } else {
      changeFilterParams({
        ...filterParams,
        startYear: yearFilter.yearStart,
        endYear: yearFilter.yearEnd,
      });
    }
  }


  return (
    graphicRange === 'daily' ? (
      <div style={{ display: 'flex' }}>
        <h6>{'Período: '}</h6>
        <TextField
          style={{ margin: "0 5px" }}
          id="fromPeriod"
          name="fromPeriod"
          type="search"
          onChange={onDateChange}
          value={filterParams.fromPeriod}
        />
        <h6>a </h6>
        <TextField
          style={{ margin: "0 5px" }}
          id="toPeriod"
          name="toPeriod"
          type="search"
          onChange={onDateChange}
          value={filterParams.toPeriod}
        />
        <Button
          sx={{
            margin: '5px',
            height: '25px',
            color: 'white',
            background: '#6bb324',
            textTransform: 'none',
            cursor: 'pointer',
            '&:hover': {
              background: '#6bb324',
            },
          }}
          onClick={() => {
            if (graphicRange !== 'annualy') {
              handlePeriodFilter();
              return;
            }
            handleYearFilter();
          }}
        >
          Filtrar
        </Button>
      </div>
    ) : (
      <>
        <h6>{'Período: '}</h6>
        <MonthSelect
          type={graphicRange !== 'annualy'
            ? 'fromPeriod'
            : visualizationType === 'civil'
            ? 'yearStart'
            : 'yearStartAgricultural'
          }
          graphicRange={graphicRange}
          onDateChange={onDateChange}
          value={graphicRange !== 'annualy' 
          ? filterParams.fromPeriod
          : visualizationType === 'civil'
          ? yearFilter.yearStart
          : yearFilter.yearStartAgricultural
        }
        visualizationType={visualizationType}
        startDate={1980}
        />
        <h6>a</h6>
        <MonthSelect
          type={graphicRange !== 'annualy'
            ? 'toPeriod'
            : visualizationType === 'civil'
            ? 'yearEnd'
            : 'yearEndAgricultural'
          }
          graphicRange={graphicRange}
          visualizationType={visualizationType}
          graphicType={graphicType}
          currentMonth={CURRENT_MONTH}
          startDate={1980}
          onDateChange={onDateChange}
          value={graphicRange !== 'annualy' 
            ? filterParams.toPeriod
            : visualizationType === 'civil'
            ? yearFilter.yearEnd
            : yearFilter.yearEndAgricultural
          }
        />
        <Button
          sx={{
            margin: '5px',
            height: '25px',
            color: 'white',
            background: '#6bb324',
            textTransform: 'none',
            cursor: 'pointer',
            '&:hover': {
            background: '#6bb324',
            },
          }}
          onClick={() => {
            if (graphicRange !== 'annualy') {
              handlePeriodFilter();
              return;
            }
            handleYearFilter();
          }}
        >
          Filtrar
        </Button>
      </>
    )
  );
}
