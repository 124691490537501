import isEmpty from 'lodash/isEmpty'

export default function validateInput(values, type) {
  let errors = {}

  if(values.eficiency !== '' && (parseFloat(values.eficiency) < 0 || parseFloat(values.eficiency) > 100)){
    errors.eficiency = "A eficiência deve estar entre 0 e 100"
  }

  if(values.cc_user !== '' && (parseFloat(values.cc_user) < 0 || parseFloat(values.cc_user) > 1)){
    errors.cc_user = "O índice deve estar entre 0 e 1"
  }

  if(values.u_percent_user !== '' && (parseFloat(values.u_percent_user) < 0 || parseFloat(values.u_percent_user) >= 100)){
    errors.u_percent_user = "A umidade deve ser 0 <= umidade < 100"
  }

  if(values.kdec !== '' && (parseFloat(values.kdec) < 0 || parseFloat(values.kdec) > 1)) {
    errors.kdec = "O Kdec precisa estar entre 0 e 1."
  }

  if(values.canePlantedProportion === '') {
    errors.canePlantedProportion = "Campo obrigatório"
  }

  if(values.cane2Proportion === '') {
    errors.cane2Proportion = "Campo obrigatório"
  }

  if(values.cane3Proportion === '') {
    errors.cane3Proportion = "Campo obrigatório"
  }

  if(values.cane4Proportion === '') {
    errors.cane4Proportion = "Campo obrigatório"
  }

  if(values.cane5Proportion === '') {
    errors.cane5Proportion = "Campo obrigatório"
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}