import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames'

import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar'

import GeneralProductivity from '../components/reports/productivity/GeneralProductivity';
import WaterStorageChart from '../components/charts/WaterStorageChart'
import EvapotranspirationChart from '../components/charts/EvapotranspirationChart'
import ProductivityAverageCropGraph from '../components/reports/productivity/ProductivityAverageCropGraph';
import DropdownField from '../components/common/DropdownField'
import CalendarDropdown from '../components/reports/productivity/CalendarDropdown'
import OptionsModal from '../components/reports/productivity/common/OptionsModal'
import DownloadXLS from '../shared/utils/DownloadXLS';
import { PRODUCTIVITY, EUCALYPTUS } from '../components/reports/productivity/common/defaultValues'

import { generateEucalyptusProductivity, setProductivityUndefined, saveCustomizedProductivity } from '../actions/productivity';
import { translateMonth } from '../shared/utils/DatesUtils';
import { toggleReportContainer } from '../actions/reports';
import { toggleTooltips } from '../actions/global';
import { activePointChange } from '../shared/utils/ProductivityAccess'
import validateInput from '../shared/validators/generalEucalyptusProdValidation'
import GeneralEucalyptusForm from '../components/reports/productivity/configs/GeneralEucalyptusForm';


class EucalyptusProductivityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: {
        eficiency: PRODUCTIVITY.EFFICIENCY,
        range: EUCALYPTUS.RANGE,
        cycle: EUCALYPTUS.CYCLE,
        clone: EUCALYPTUS.CLONE,
        bark: false,
        tree_bark: '',
        unity: EUCALYPTUS.UNITY,
        plantingYear: (EUCALYPTUS.DATE).slice(0,4),
        plantingDate: `${(EUCALYPTUS.DATE).slice(5,7)}/${(EUCALYPTUS.DATE).slice(0,4)}`,
        date: EUCALYPTUS.DATE,
        crop: "Atual"
      },
      selectedMonth: undefined,
      selectedYear: undefined,
      selectedCrop: 'Atual',
      initialYear: undefined,
      initialMonth: undefined,
      finalMonth: {value: 'December'},
      finalYear: undefined,
      calendarOpen: false,
      yearlyCropData: [],
      monthlyCropData: [],
      yearOptions: [],
      monthOptions: [],
      bottomChartYears: [],
      bottomChartMonths: [],
      constantYearOptions: [],
      constantMonthOptions: [],
      selectedFarm: undefined,
      data: undefined,
      range_flag: undefined,
      eficiency: undefined,
      eficiencyError: undefined,
      averageCropData: [],
      currentSeasonAverageData: undefined,
      lastSeasonAverageData: undefined,
      openEucalyptusDropdown: false,
      openReport: true,
      month: 1,
      year: 0,
      openOptions: false,
      errors: {}
    };
  };

  //#region Lifecycle methods
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { state } = this;
    let firstBoxplot = 0
    let options = []

    if (nextProps.productivity.range_flag === 'monthly'){
      if (nextProps.productivity && nextProps.productivity.data) {
        state.range_flag = nextProps.productivity.range_flag;
        const data = this.generateAverageCropData(nextProps.productivity.data);
        state.data = nextProps.productivity.data;
        state.averageCropData = data.averageCropData;
        state.currentSeasonAverageData = data.currentSeasonAverageData;
  
        Object.keys(nextProps.productivity.data).forEach(key => {
          if(nextProps.productivity.data[key].rp_value.length > 1 && firstBoxplot === 0){
            firstBoxplot = {value: key, box: true}
          }
          options.push({value: key, box: nextProps.productivity.data[key].rp_value.length > 1})
        })
  
        state.selectedMonth = firstBoxplot === 0 ? {value: 'December', box: false} : firstBoxplot
        if(state.selectedMonth){
          let monthlyCropData = this.generateMonthlyCropData(nextProps.productivity.data)
          state.monthlyCropData =  monthlyCropData
        }
  
        state.initialMonth = {value: 'January'}
        state.monthOptions =  options
        state.constantMonthOptions = options
        state.bottomChartMonths = options
      }
  
      if (nextProps.farms && nextProps.activeFarm) {
        const selectedFarm = nextProps.farms.find(farm => farm.id === nextProps.activeFarm);
        if(this.state.selectedFarm && this.state.selectedFarm.id !== nextProps.activeFarm){
          state.selectedCrop = 'Atual'
        }
        state.selectedFarm = selectedFarm;
      }
      this.setState(state);
    } else if (nextProps.productivity.range_flag === 'annually') {
      
      if (nextProps.productivity && nextProps.productivity.data) {
        state.range_flag = nextProps.productivity.range_flag;
        state.finalYear = {value:`${parseInt(this.state.currentFilter.date.slice(0,4)) + 13}` }
        const data = this.generateAverageCropData(nextProps.productivity.data);
        state.data = nextProps.productivity.data;
        state.averageCropData = data.averageCropData;
        state.currentSeasonAverageData = data.currentSeasonAverageData;
  
        Object.keys(nextProps.productivity.data).forEach(key => {
          if(nextProps.productivity.data[key].rp_value.length > 1 && firstBoxplot === 0){
            firstBoxplot = {value: key, box: true}
          }
  
          options.push({value: key, box: nextProps.productivity.data[key].rp_value.length > 1})
        })
        
        const { date, cycle } = this.state.currentFilter
        state.selectedYear = firstBoxplot === 0 
          ? { value: `${Number(date.slice(0,4)) + Number(cycle)}`, box: false}
          : firstBoxplot
        if(state.selectedMonth){
          let yearlyCropData = this.generateYearlyCropData(nextProps.productivity.data)
          state.yearlyCropData =  yearlyCropData
        }
  
        state.initialYear = { value:`${Number(date.slice(0,4)) + Number(cycle)}` }
        state.yearOptions =  options
        state.constantYearOptions = options
        state.bottomChartYears = options
      }
  
      if (nextProps.farms && nextProps.activeFarm) {
        const selectedFarm = nextProps.farms.find(farm => farm.id === nextProps.activeFarm);
        if(this.state.selectedFarm && this.state.selectedFarm.id !== nextProps.activeFarm){
          state.selectedCrop = 'Atual'
        }
        state.selectedFarm = selectedFarm;
      }
      this.setState(state);
    } else {
      return null
    }
  }

  componentDidUpdate(prevProps, prevState){
    activePointChange(prevProps, this.props, 'Eucalipto')
    if(this.state.data !== undefined && this.props.productivity.data === undefined){
      this.setState({data: undefined})
    }else{
      let { state } = this;
      if (state.range_flag === 'monthly'){
        if(prevState.selectedMonth !== this.state.selectedMonth || this.state.monthlyCropData.length < 1){
          state.monthlyCropData = this.generateMonthlyCropData(this.props.productivity.data)
          this.setState(state)
        }
  
        if(prevState.finalMonth.value !== this.state.finalMonth.value || prevState.initialMonth.value !== this.state.initialMonth.value){
          const data = this.generateAverageCropData(this.props.productivity.data);
          state.averageCropData = data.averageCropData;
          state.currentSeasonAverageData = data.currentSeasonAverageData;
          this.setState(state)
        }
      } else if (state.range_flag === 'annually') {
        if(prevState.selectedYear !== this.state.selectedYear || this.state.yearlyCropData.length < 1){
          state.yearlyCropData = this.generateYearlyCropData(this.props.productivity.data)
          this.setState(state)
        }
  
        if(prevState.finalYear.value !== this.state.finalYear.value || prevState.initialYear.value !== this.state.initialYear.value){
          const data = this.generateAverageCropData(this.props.productivity.data);
          state.averageCropData = data.averageCropData;
          state.currentSeasonAverageData = data.currentSeasonAverageData;
          this.setState(state)
        }
      } else {
        return null
      }
    }
  }
  //#endregion

  //#region Render methods

  handleOptionsModal = () => {
    this.setState({openOptions: !this.state.openOptions})
  }

  onChangeSelectedMonth = (value) => {
    this.setState({
      selectedMonth: {
        value: value.target.value, box: this.props.productivity.data[value.target.value].rp_value.length > 1
      }
    })
  }

  onChangeSelectedYear = (value) => {
    this.setState({
      selectedYear: {
        value: value.target.value, box: this.props.productivity.data[value.target.value].rp_value.length > 1
      }
    })
  }

  onSelectedCrop = (value) => {
    this.setState({selectedCrop: value.target.value})
  }

  getIndex = (collection, value) => {
    let index = collection.findIndex(month => {
      return month.value === value
    })
    return index
  }

  onChangeInitialMonth = (value) => {
    let beginIndex = this.getIndex(this.state.monthOptions, value.target.value)
    let endIndex = this.getIndex(this.state.monthOptions, this.state.finalMonth.value)
    let selectedMonth = this.state.selectedMonth
    let bottomChartMonths = this.state.monthOptions.slice(beginIndex,endIndex+1)
    let selectedMonthIndex = this.getIndex(bottomChartMonths, this.state.selectedMonth.value)

    if(selectedMonthIndex === -1){
      selectedMonth = bottomChartMonths[0]
    }
    this.setState({
      initialMonth: { value: value.target.value },
      bottomChartMonths: bottomChartMonths,
      selectedMonth: selectedMonth
    })
  }

  onChangeFinalMonth = (value) => {
    let beginIndex = this.getIndex(this.state.monthOptions, this.state.initialMonth.value)
    let endIndex = this.getIndex(this.state.monthOptions, value.target.value)
    let selectedMonth = this.state.selectedMonth
    let bottomChartMonths = this.state.monthOptions.slice(beginIndex,endIndex+1)
    let selectedMonthIndex = this.getIndex(bottomChartMonths, this.state.selectedMonth.value)

    if(selectedMonthIndex === -1){
      selectedMonth = bottomChartMonths[bottomChartMonths.length - 1]
    }

    this.setState({finalMonth: {value: value.target.value}, bottomChartMonths: bottomChartMonths, selectedMonth: selectedMonth})
  }

  onChangeInitialYear = (value) => {
    let beginIndex = this.getIndex(this.state.yearOptions, value.target.value)
    let endIndex = this.getIndex(this.state.yearOptions, this.state.finalYear.value)
    let selectedYear = this.state.selectedYear
    let bottomChartYears = this.state.yearOptions.slice(beginIndex,endIndex+1)
    let selectedYearIndex = this.getIndex(bottomChartYears, this.state.selectedYear.value)

    if(selectedYearIndex === -1){
      selectedYear = bottomChartYears[0]
    }
    this.setState({
      initialYear: {value: value.target.value},
      bottomChartYears: bottomChartYears,
      selectedYear: selectedYear
    })
  }

  onChangeFinalYear = (value) => {
    let beginIndex = this.getIndex(this.state.YearOptions, this.state.initialYear.value)
    let endIndex = this.getIndex(this.state.yearOptions, value.target.value)
    let selectedYear = this.state.selectedYear
    let bottomChartYears = this.state.YearOptions.slice(beginIndex,endIndex+1)
    let selectedYearIndex = this.getIndex(bottomChartYears, this.state.selectedYear.value)

    if(selectedYearIndex === -1){
      selectedYear = bottomChartYears[bottomChartYears.length - 1]
    }
    this.setState({
      finalYear: {value: value.target.value},
      bottomChartYears: bottomChartYears,
      selectedYear: selectedYear
    })
  }

  onChange = (e) => {
    let { state } = this;
    let value = e.target.value;

    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }

    state[e.target.name] = value;

    this.setState(state);
  }

  recalculate = (e) => {
    let { errors, isValid } = validateInput(this.state.currentFilter)
    if(!isValid){
      this.setState({errors: errors})
      return
    }

    const coords = this.state.selectedFarm.coords.split(',');
    const { eficiency, range, clone, cycle, tree_bark } = this.state.currentFilter
    const agriculturalEficiency = eficiency / 100;
    
    const point_id = this.state.selectedFarm.id;
    const { month } = this.state
    
    const date = `${this.state.currentFilter.plantingYear}-${month <= 9 ? `0${month}` : month}-01`
    const crop_year = `${parseInt(date.slice(0,4)) + parseInt(cycle)}`;

    const data = {
      cad: this.props.productivity.cad,
      lat: coords[0],
      lon: coords[1],
      agriculturalEficiency,
      range,
      clone,
      crop_year,
      point_id,
      cycle,
      date,
      tree_bark,
    };

    this.setState({
      openOptions: false,
      errors: {}
    }, () => {
      this.props.actions.setProductivityUndefined()
      this.props.actions.generateEucalyptusProductivity(data, 'general')
    })

    this.setState({
      currentFilter : {
        ...this.state.currentFilter,
        plantingDate: `${(date).slice(5,7)}/${(date).slice(0,4)}`,
        date: date
      }
    })
    
  }

  handleKeyPress = (e) => {
    if(e.key === 'Enter'){
      this.recalculate();
    }
  }

  generateAverageCropData = (data) => {
    const averageCropData = [];  //aqui!!
    const { cycle, unity} = this.state.currentFilter
    let cycleDivider = 1
    if (unity === "m³/ha/ano (IMA)") {
      cycleDivider = cycle
    }

    let veryUnfavorable = 0;
    let unfavorable = 0;
    let medium = 0;
    let favorable = 0;
    let veryFavorable = 0;
    let currentSeason = 0;

    let currentSeasonName = "";
    let predictionCounter = 0;
    let mainCounter = 0;

    let validKeys = Object.keys(data)

    let observedData = 0

    for (let index in validKeys) {
      let key  = validKeys[index]
      mainCounter++;
      
      if (data[key].rp_value && data[key].rp_value.length === 5) {
        veryUnfavorable += data[key].rp_value[0];
        unfavorable += data[key].rp_value[1];
        medium += data[key].rp_value[2];
        favorable += data[key].rp_value[3];
        veryFavorable += data[key].rp_value[4];
        currentSeason += data[key].rp_value[2];
        predictionCounter++;
      } else {
        observedData += data[key].rp_value[0];
        currentSeason += data[key].rp_value[0];
      }
      
      currentSeasonName = data[key].crop
    }

    if (predictionCounter > 0) {
      //we have to parse once in order to format with two decimals
      //And parse a second time as the graphic library only accepts numbers
      veryUnfavorable = (((veryUnfavorable + observedData) / mainCounter)/cycleDivider).toFixed(2);
      unfavorable = (((unfavorable + observedData) / mainCounter)/cycleDivider).toFixed(2);
      medium = (((medium + observedData) / mainCounter)/cycleDivider).toFixed(2);
      favorable = (((favorable + observedData) / mainCounter)/cycleDivider).toFixed(2);
      veryFavorable = (((veryFavorable + observedData) / mainCounter)/cycleDivider).toFixed(2);
      currentSeason = ((currentSeason / mainCounter)/cycleDivider).toFixed(2);

      averageCropData.push(
        {name: 'Muito Desfavorável', veryUnfavorable},
        {name: 'Desfavorável', unfavorable},
        {name: 'Mediano', medium},
        {name: 'Favorável', favorable},
        {name: 'Muito Favorável', veryFavorable},
      );
    }else{
      currentSeason = ((currentSeason / mainCounter)).toFixed(2);

      averageCropData.push(
        {name: currentSeasonName, currentCrop: currentSeason,},
      );
    }

    return {
      averageCropData,
      currentSeasonAverageData: currentSeason,
    };
  }

  generateYearlyCropData = (data) => {
    const { cycle, unity} = this.state.currentFilter
    let cycleDivider = 1
    if (unity === "m³/ha/ano (IMA)") {
      cycleDivider = cycle
    }

    if(this.state.selectedYear["box"]){
      let veryUnfavorable = parseFloat(data[this.state.selectedYear["value"]].rp_value[0] / cycleDivider).toFixed(2);
      let unfavorable = parseFloat(data[this.state.selectedYear["value"]].rp_value[1] / cycleDivider).toFixed(2);
      let medium = parseFloat(data[this.state.selectedYear["value"]].rp_value[2] / cycleDivider).toFixed(2);
      let favorable = parseFloat(data[this.state.selectedYear["value"]].rp_value[3] / cycleDivider).toFixed(2);
      let veryFavorable = parseFloat(data[this.state.selectedYear["value"]].rp_value[4] / cycleDivider).toFixed(2);

      return [
        {name: 'Muito Desfavorável', veryUnfavorable},
        {name: 'Desfavorável', unfavorable},
        {name: 'Mediano', medium},
        {name: 'Favorável', favorable},
        {name: 'Muito Favorável', veryFavorable},
      ]
    }else{
      let currentCrop = parseFloat(data[this.state.selectedYear["value"]].rp_value[0]).toFixed(2);

      return [
        {name: `${data[this.state.selectedYear.value].crop}`, currentCrop},
      ]
    }
  }


  generateMonthlyCropData = (data) => {
    const { cycle, unity} = this.state.currentFilter
    let cycleDivider = 1
    if (unity === "m³/ha/ano (IMA)") {
      cycleDivider = cycle
    }
    if(this.state.selectedMonth["box"]){
      let veryUnfavorable = parseFloat(data[this.state.selectedMonth["value"]].rp_value[0] / cycleDivider).toFixed(2);
      let unfavorable = parseFloat(data[this.state.selectedMonth["value"]].rp_value[1] / cycleDivider).toFixed(2);
      let medium = parseFloat(data[this.state.selectedMonth["value"]].rp_value[2] / cycleDivider).toFixed(2);
      let favorable = parseFloat(data[this.state.selectedMonth["value"]].rp_value[3] / cycleDivider).toFixed(2);
      let veryFavorable = parseFloat(data[this.state.selectedMonth["value"]].rp_value[4] / cycleDivider).toFixed(2);

      return [
        {name: 'Muito Desfavorável', veryUnfavorable},
        {name: 'Desfavorável', unfavorable},
        {name: 'Mediano', medium},
        {name: 'Favorável', favorable},
        {name: 'Muito Favorável', veryFavorable},
      ]
    }else{
      let currentCrop = parseFloat(data[this.state.selectedMonth["value"]].rp_value[0]).toFixed(2);
      return [
        {name: `${data[this.state.selectedMonth.value].crop}`, currentCrop},
      ]
    }
  }

  scaleUpBarChart(){
    let maxValues = []
    let dataArray
    if (this.state.range_flag === "monthly"){
      if (this.state.monthlyCropData.length > 1){
        dataArray = this.state.monthlyCropData
        for(let i = 0; i < dataArray.length; i++){
          if ( dataArray[i]["name"] === "Muito Favorável"){
            maxValues.push(dataArray[i]["veryFavorable"])
          }
        }
         // here we get  of the rounded maxValue added 5% (to give some margin in the bar plot)
      maxValues = (parseInt(Math.max(...maxValues)) * 1.25)
      maxValues = Math.round(maxValues)
      return maxValues
      }else {
        maxValues = (parseInt(this.state.monthlyCropData[0]["currentCrop"]) * 1.25)
        maxValues = Math.round(maxValues)
        
        return maxValues
      }
    } else if(this.state.range_flag === "annually"){
      if (this.state.yearlyCropData.length > 1){
        dataArray = this.state.yearlyCropData
        for(let i = 0; i < dataArray.length; i++){
          if ( dataArray[i]["name"] === "Muito Favorável"){
            maxValues.push(parseInt(dataArray[i]["veryFavorable"]))
          }
          // here we get  of the rounded maxValue added 5% (to give some margin in the bar plot)
          maxValues = (parseInt(Math.max(...maxValues)) * 1.25)
          maxValues = Math.round(maxValues)
        return maxValues
        }
      } else {
        maxValues = (parseInt(this.state.yearlyCropData[0]["currentCrop"]) * 1.25)
        maxValues = Math.round(maxValues)
        return maxValues
      }
    }
  }

  scaleUpAverageBarChart(){
    let maxValues = []
    let dataArray

    if (Object.keys(this.state.averageCropData).length !== 0){
      if (this.state.averageCropData.length > 1){
        dataArray = this.state.averageCropData
        for(let i = 0; i < dataArray.length; i++){
          if ( dataArray[i]["name"] === "Muito Favorável"){
            maxValues.push(dataArray[i]["veryFavorable"])
          }
        }
        // here we get  of the rounded maxValue added 5% (to give some margin in the bar plot)
        maxValues = (parseInt(Math.max(...maxValues)) * 1.25)
        maxValues = Math.round(maxValues)
        return maxValues
      }else {
        maxValues = (parseInt(this.state.averageCropData[0]["currentCrop"]) * 1.25)
        maxValues = Math.round(maxValues)
        return maxValues
      }
    }
  }

  toggleEucalyptusDropdown = () => {
    this.setState({openEucalyptusDropdown: !this.state.openEucalyptusDropdown})
  }

  toggleCalendarDropdown = () => {
    this.setState({calendarOpen: !this.state.calendarOpen})
  }

  onChangeCaneValues = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  toggleActive = () => {
    this.props.actions.toggleReportContainer()
  }

  handleCloseOptionsModal = () => {
    this.setState({openOptions: false})
    if(!this.state.data){
      window.location.reload()
    }
  }

  handleChange = ({ target: { name, value } }) => {
    let { currentFilter } = this.state;

    if(name === "bark") {
      currentFilter[name] = !this.state.currentFilter.bark;
    } else {
      currentFilter[name] = value;
    }
    this.setState({currentFilter: currentFilter});
  }

  onChangeMonth = (event) => {
    this.setState({
      month: event.target.value
    })
  }

  onChangeYear = (event) => {
    this.setState({
      year: event.target.value
    })
  }
  //#endregion

  render() {
    let YAxisLimit = this.scaleUpBarChart()
    let YAxisAverageLimit = this.scaleUpAverageBarChart()
    const { cycle, unity} = this.state.currentFilter
    let cycleDivider = 1
    if (unity === "m³/ha/ano (IMA)") {
      cycleDivider = cycle
    }

    if (this.state.data && this.props.farms && this.props.activeFarm) {
      const { productivity } = this.props;
      const csvHeaders = [
        {label: 'Rotação', key: 'crop'},
        {label: 'Mês', key: 'month'},
        {label: 'Produtividade', key: 'productivity'},
        {label: 'Limite superior', key: 'upper_limit'},
        {label: 'Terceiro quartil', key: 'third_quartile'},
        {label: 'Mediana', key: 'median'},
        {label: 'Primeiro quartil', key: 'first_quartile'},
        {label: 'Limite inferior', key: 'lower_limit'}
      ]
      const csvData = []
      const farmName = this.state.selectedFarm.name !== "" ? this.state.selectedFarm.name : `Ponto ${this.state.selectedFarm.initials}`;
      const eficiency = productivity.agriculturalEficiency < 1 ? Math.round(productivity.agriculturalEficiency * 100) : productivity.agriculturalEficiency;
      let title = `${farmName} | ${this.state.range_flag === 'annually' ? `Plantio = ${this.state.currentFilter.plantingDate}` : `Ano de plantio = ${(this.state.currentFilter.plantingDate).slice(3)}`} | CAD = ${productivity.cad} mm | E.F. = ${eficiency ? eficiency : '70'}% | Ciclo = ${this.state.currentFilter.cycle ? `${this.state.currentFilter.cycle} anos` : `${EUCALYPTUS.CYCLE} anos`}`;


      let keys = Object.keys(this.state.data);
      const translatedKeys = [];
      let currentCrop = '';
      const currentCropPointsValues = []
      const currentCropBoxplotValues = []

      if (this.state.range_flag === 'monthly'){
        if(this.state.initialMonth){
          let initialMonthIndex = keys.indexOf(this.state.initialMonth.value)
          keys = keys.slice(initialMonthIndex)
        }
        if(this.state.finalMonth && this.state.finalMonth.value !== 'December'){
          let finalMonthIndex = keys.indexOf(this.state.finalMonth.value)
          keys = keys.slice(0, finalMonthIndex+1)
        }
        
        currentCrop = this.state.data['January']['crop']
        let value = []
        let currentCropAuxiliar = []
  
        keys.forEach((key,index) => {
          translatedKeys.push(translateMonth(key))
          value = this.state.data[key]["rp_value"].slice()
  
          csvData.push({
            crop: this.state.data[key]["crop"],
            month: translateMonth(key),
            productivity: value.length > 1 ? '' : parseFloat(value[0]).toFixed(2),
            upper_limit: value.length > 1 ? parseFloat(value[0]).toFixed(2) : '',
            third_quartile: value[1] ? parseFloat(value[1]).toFixed(2) : '',
            median: value[2] ? parseFloat(value[2]).toFixed(2) : '',
            first_quartile: value[3] ? parseFloat(value[3]).toFixed(2) : '',
            lower_limit: value[4] ? parseFloat(value[4]).toFixed(2) : '',
          })
  
          currentCropAuxiliar = []
          value.forEach(cropValue => {
            currentCropAuxiliar.push(parseFloat(parseFloat(cropValue / cycleDivider).toFixed(2)))
          })
          currentCropAuxiliar.unshift(index)
          if( this.state.data[key]["rp_value"].length === 1){
            currentCropPointsValues.push(currentCropAuxiliar)
          }else{
            currentCropBoxplotValues.push(currentCropAuxiliar.slice())
          }
          currentCropAuxiliar = []
        })
      } else if(this.state.range_flag === 'annually') {
        const { plantingYear, cycle } = this.state.currentFilter;

        let keys = this.state.bottomChartYears.map((year) => year.value);

        const years = Object.keys(this.state.data);
        const currentYear = `${Number(plantingYear) + Number(cycle)}`;

        currentCrop = years.includes(currentYear)
          ? this.state.data[currentYear]['crop']
          : this.state.data[years[0]]['crop'];
        
        let value = []
        let currentCropAuxiliar = []
  
        keys.forEach((key,index) => {
          translatedKeys.push(key)
          value = this.state.data[key]["rp_value"].slice()
  
          csvData.push({
            crop: this.state.data[key]["crop"],
            year: key,
            productivity: value.length > 1 ? '' : parseFloat(value[0]).toFixed(2),
            upper_limit: value.length > 1 ? parseFloat(value[0]).toFixed(2) : '',
            third_quartile: value[1] ? parseFloat(value[1]).toFixed(2) : '',
            median: value[2] ? parseFloat(value[2]).toFixed(2) : '',
            first_quartile: value[3] ? parseFloat(value[3]).toFixed(2) : '',
            lower_limit: value[4] ? parseFloat(value[4]).toFixed(2) : '',
          })
  
          currentCropAuxiliar = []
          value.forEach(cropValue => {
            currentCropAuxiliar.push(parseFloat(parseFloat(cropValue).toFixed(2)))
          })
          currentCropAuxiliar.unshift(index)
          if( this.state.data[key]["rp_value"].length === 1){
            currentCropPointsValues.push(currentCropAuxiliar)
          }else{
            currentCropBoxplotValues.push(currentCropAuxiliar.slice())
          }
          currentCropAuxiliar = []
        })
      } else {
      return null
      }
      
      if (this.state.range_flag === 'monthly'){
        return (
          <Fragment>
            <section className={classnames("report--container visible productivity", {active: this.props.reportActive})} style={{zIndex: "8"}}>
              <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.props.reportActive})} onClick={ this.toggleActive }><i className='material-icons'>play_arrow</i></span>
              <header className='row bhbox-header productivity-header'>
                <div className="prod-month-container">
                  <CalendarDropdown
                    active={this.state.calendarOpen}
                    onClick={this.toggleCalendarDropdown}
                    initialMonth={this.state.initialMonth}
                    finalMonth={this.state.finalMonth}
                    constantMonthOptions={this.state.constantMonthOptions}
                    onChangeInitialMonth={this.onChangeInitialMonth}
                    onChangeFinalMonth={this.onChangeFinalMonth}
                    range={this.state.range_flag}
                  />
                </div>
                <div className="prod-options-container">
                  <div className="header-graphic" style={{marginRight: '4px'}}>
                    <span title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'} onClick={this.props.actions.toggleTooltips}>
                      <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                    </span>
                  </div>
                  <div className="header-graphic" style={{marginRight: '4px'}}>
                    <span title="Configurações" onClick={this.handleOptionsModal}>
                      <i className='material-icons'>settings</i>
                    </span>
                  </div>
  
                  <DownloadXLS
                    marginRight={undefined}
                    headers={csvHeaders}
                    data={csvData}
                  />
                </div>
              </header>
              <div className="general-prod-disclaimer">
                <span>*</span>
                {`Última atualização: ${this.props.productivity.created_at}, Próxima atualização: ${this.props.productivity.next_update}`}
              </div>
              <PerfectScrollbar className={classnames('productivity-scroll ps ps--theme default')}>
                <div className="general-graph">
                  <GeneralProductivity
                    keys={translatedKeys}
                    title={title}
                    currentCrop={currentCrop}
                    currentCropBoxplotValues={currentCropBoxplotValues}
                    currentCropPointsValues={currentCropPointsValues}
                    showTooltips={this.props.showTooltips}
                    unit={this.state.currentFilter.unity}
                    productivityType={"eucalyptus-general-productivity"}
                  />
                </div>
                <div className="botton-graphs">
                  <Grid container>
                    <Grid item xs={6}>
                      <div className="subtitle">
                        Mês da colheita
                        <DropdownField
                          value={this.state.selectedMonth["value"]}
                          options={this.state.bottomChartMonths}
                          onChange={this.onChangeSelectedMonth}
                          type={'months'}
                        />
                      </div>
                      {
                        this.state.monthlyCropData.length > 0 && (
                          <ProductivityAverageCropGraph
                            data={this.state.monthlyCropData}
                            ReportType={"eucalyptus-general-productivity"}
                            YAxisLimit={YAxisLimit}
                            unit={this.state.currentFilter.unity}
                            showTooltips={this.props.showTooltips}
                          />
                        )
                      }
                    </Grid>
                    <Grid item xs={6}>
                      {this.state.averageCropData.length > 0 &&
                        <Fragment>
                          <div className="subtitle">
                            Média da Rotação
                          </div>
                          <ProductivityAverageCropGraph
                          data={this.state.averageCropData}
                          ciclo={this.state.currentFilter.cycle}
                          YAxisLimit={YAxisAverageLimit}
                          ReportType={"eucalyptus-general-productivity"}
                          unit={this.state.currentFilter.unity}
                          showTooltips={this.props.showTooltips}
                        />
                        </Fragment>
                      }
                    </Grid>
                  </Grid>
                    {productivity.armData !== null && (
                      <WaterStorageChart
                        data={productivity.armData}
                        cad={productivity.cad}
                        farm={farmName}
                        crop={productivity.data !== undefined ? productivity.data.January.crop : ''}
                        showTooltips={this.props.showTooltips}
                      />
                    )
                    }
                    {productivity.etrData !== null && (
                      <EvapotranspirationChart
                        data={productivity.etrData}
                        cad={productivity.cad}
                        showTooltips={this.props.showTooltips}
                      />
                    )
                    }
                </div>
  
              </PerfectScrollbar>
            </section>
            {this.state.openOptions &&
              <OptionsModal
                open={this.state.openOptions}
                noClose={this.state.data ? false : true}
                recalculate={this.recalculate}
                handleClose={this.handleCloseOptionsModal}
                title={"Configurações"}
                form={
                  <GeneralEucalyptusForm
                    type={"eucalyptus-general-productivity"}
                    initialOptions={keys}
                    onChangeMonth={this.onChangeMonth}
                    monthSelection={this.state.currentFilter.range === 'monthly' ? false : true}
                    handleChange={this.handleChange}
                    currentState={this.state.currentFilter}
                    initialDate={this.state.initialMonth}
                    month={this.state.month}
                    errors={this.state.errors}
                  />
                }
              />
            }
          </Fragment>
        )
      } else if (this.state.range_flag === 'annually') {
        return (
          <Fragment>
            <section className={classnames("report--container visible productivity", {active: this.props.reportActive})} style={{zIndex: "8"}}>
              <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.props.reportActive})} onClick={ this.toggleActive }><i className='material-icons'>play_arrow</i></span>
              <header className='row bhbox-header productivity-header'>
                <div className="prod-month-container">
                </div>
                <div className="prod-options-container">
                  <div className="header-graphic" style={{marginRight: '4px'}}>
                    <span title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'} onClick={this.props.actions.toggleTooltips}>
                      <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                    </span>
                  </div>
                  <div className="header-graphic" style={{marginRight: '4px'}}>
                    <span title="Configurações" onClick={this.handleOptionsModal}>
                      <i className='material-icons'>settings</i>
                    </span>
                  </div>
  
                  <DownloadXLS
                    marginRight={undefined}
                    headers={csvHeaders}
                    data={csvData}
                  />
                </div>
              </header>
              <div className="general-prod-disclaimer">
                <span>*</span>
                {`Última atualização: ${this.props.productivity.created_at}, Próxima atualização: ${this.props.productivity.next_update}`}
              </div>
              <PerfectScrollbar className={classnames('productivity-scroll ps ps--theme default')}>
                <div className="general-graph">
                  <GeneralProductivity
                    keys={translatedKeys}
                    title={title}
                    currentCrop={currentCrop}
                    currentCropBoxplotValues={currentCropBoxplotValues}
                    currentCropPointsValues={currentCropPointsValues}
                    showTooltips={this.props.showTooltips}
                    productivityType={"eucalyptus-general-productivity"}
                    unit={this.state.currentFilter.unity}
                    rangeFlag={this.state.range_flag}
                  />
                </div>
                <div className="botton-graphs">
                  <Grid container>
                    <Grid item xs={6}>
                      <div className="subtitle">
                       Ano da colheita
                        <DropdownField
                          value={this.state.selectedYear["value"]}
                          options={this.state.bottomChartYears}
                          onChange={this.onChangeSelectedYear}
                          type={'years'}
                        />
                      </div>
                      {
                        this.state.yearlyCropData.length > 0 && (
                          <ProductivityAverageCropGraph
                            data={this.state.yearlyCropData}
                            YAxisLimit={YAxisLimit}
                            ReportType={"eucalyptus-general-productivity"}
                            unit={this.state.currentFilter.unity}
                            showTooltips={this.props.showTooltips}
                          />
                        )
                      }
                    </Grid>
                    <Grid item xs={6}>
                      {this.state.averageCropData.length > 0 &&
                        <Fragment>
                          <div className="subtitle">
                            Média das Rotações
                          </div>
                          <ProductivityAverageCropGraph
                            data={this.state.averageCropData}
                            ciclo={this.state.currentFilter.cycle}
                            YAxisLimit={YAxisAverageLimit}
                            ReportType={"eucalyptus-general-productivity"}
                            unit={this.state.currentFilter.unity}
                            showTooltips={this.props.showTooltips}
                          />
                        </Fragment>
                      }
                    </Grid>
                  </Grid>
                    {productivity.armData !== null && (
                      <WaterStorageChart
                        data={productivity.armData}
                        cad={productivity.cad}
                        farm={farmName}
                        crop={productivity.data !== undefined ? productivity[`data`][`${this.state.currentFilter.date + 7}`][`crop`] : ''}
                        showTooltips={this.props.showTooltips}
                      />
                    )
                    }
                    {productivity.etrData !== null && (
                      <EvapotranspirationChart
                        data={productivity.etrData}
                        cad={productivity.cad}
                        showTooltips={this.props.showTooltips}
                      />
                    )
                    }
                </div>
  
              </PerfectScrollbar>
            </section>
            {this.state.openOptions &&
              <OptionsModal
                open={this.state.openOptions}
                noClose={this.state.data ? false : true}
                recalculate={this.recalculate}
                handleClose={this.handleCloseOptionsModal}
                title={"Configurações"}
                form={
                  <GeneralEucalyptusForm
                    type={"eucalyptus-general-productivity"}
                    initialOptions={keys}
                    onChangeMonth={this.onChangeMonth}
                    monthSelection={this.state.currentFilter.range === 'monthly' ? false : true}
                    currentState={this.state.currentFilter}
                    handleChange={this.handleChange}
                    initialDate={this.state.initialMonth}
                    month={this.state.month}
                    errors={this.state.errors}
                  />
                }
              />
            }
          </Fragment>
        )
      } else {
        return null
      }  
      
    } else {
      return (
        <section className="report--container active visible productivity" style={{zIndex: "8"}}>
          <div className='loading-screen-prod'>
            Calculando...
            <br></br>
            O processo pode levar alguns minutos
          </div>
        </section>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms.points.farms,
    activeFarm: state.farms.activeFarm,
    productivity: state.productivity,
    reportActive: state.Reports.reportActive,
    showTooltips: state.global.showTooltips
  }
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {
      generateEucalyptusProductivity,
      saveCustomizedProductivity,
      setProductivityUndefined,
      toggleReportContainer,
      toggleTooltips
    }), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EucalyptusProductivityContainer);
