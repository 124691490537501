import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import classnames from "classnames";
// Components
import InputField from "./common/InputField";
import DropdownField from "./common/DropdownField";
import { showToaster } from "./common/Toaster";
import ReportOption from "./ReportOption";
import MoreFarmsList from "./MoreFarmsList";

// Styles
import "../stylesheets/css/components/sidebar.css";
import { connect } from "react-redux";
import { allowMapClickEvent } from "../actions/map";

class FarmForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subProducts: "",
      showLabel: false,
      addingFromGrid: false,
    };
  }

  handleEditEvent = () => {
    this.props.toggleSidebar();
    this.props.setMapClickEditEvent();

    const toasterParams = {
      title: "Local no Mapa",
      message: "Clique no mapa para alterar a localização.",
      status: true,
      autoClose: false,
      buttons: [
        {
          name: "CANCELAR",
          function: toast.dismiss,
        },
      ],
    };
    showToaster(toasterParams);
  };

  handleEditModeEvent = () => {
    this.props.saveOldValues();
    this.props.setEditMode(this.props.activeFarm);
  };

  handleAlert = () => {
    if (this.props.allowMapClickEditEvent === true) {
      this.props.setMapClickEditEvent();
    }
  };

  handleProductsDropdownChange = (event) => {
    this.setState({
      subProducts: event.target.value,
    });
  };

  renderSubProducts = (
    smartCaneAccess,
    smartSoyAccess,
    smartCornAccess,
    smartCottonAccess,
    smartWheatAccess,
    smartEucalyptus
  ) => {
    if (this.state.subProducts === "bhbox") {
      return (
        <div className="sub-products">
          <ReportOption
            label="ARM/CAD"
            icon="/images/products/bhbox.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "bhbox"
                ? true
                : false
            }
            value="bhbox"
            reports={this.props.reportTypes.indexOf("bhbox")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="EXTRATO BH"
            icon="/images/products/extrato.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "extbh"
                ? true
                : false
            }
            value="extbh"
            reports={this.props.reportTypes.indexOf("extbh")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="ETR/ETP"
            icon="/images/products/ETR ETP.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "etretp"
                ? true
                : false
            }
            value="etretp"
            reports={this.props.reportTypes.indexOf("etretp")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="EXC/DEF"
            icon="/images/products/exc def.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "excdef"
                ? true
                : false
            }
            value="excdef"
            reports={this.props.reportTypes.indexOf("excdef")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="EXC"
            icon="/images/products/surplus.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "exc"
                ? true
                : false
            }
            value="exc"
            reports={this.props.reportTypes.indexOf("exc")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitBhbox}
          />
          <ReportOption
            label="DEF"
            icon="/images/products/deficit.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "def"
                ? true
                : false
            }
            value="def"
            reports={this.props.reportTypes.indexOf("def")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitBhbox}
          />
          <ReportOption
            label="ARM"
            icon="/images/products/armazenamento.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "arm"
                ? true
                : false
            }
            value="arm"
            reports={this.props.reportTypes.indexOf("arm")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
        </div>
      );
    } else if (this.state.subProducts === "weather") {
      return (
        <div className="sub-products">
          <ReportOption
            label="TEMPERATURA"
            icon="/images/products/temperature.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "temperatura"
                ? true
                : false
            }
            value="temperatura"
            reports={this.props.reportTypes.indexOf("temperatura")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="RADIAÇÃO SOLAR"
            icon="/images/products/radiacao solar.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "rs"
                ? true
                : false
            }
            value="rs"
            reports={this.props.reportTypes.indexOf("rs")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="GRAUS-DIA"
            icon="/images/products/grausdia.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "gd"
                ? true
                : false
            }
            value="gd"
            reports={this.props.reportTypes.indexOf("gd")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="VENTO A 2 METROS"
            icon="/images/products/vento.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "u2"
                ? true
                : false
            }
            value="u2"
            reports={this.props.reportTypes.indexOf("u2")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="CHUVA"
            icon="/images/products/chuva.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "prec"
                ? true
                : false
            }
            value="prec"
            reports={this.props.reportTypes.indexOf("prec")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="DIAS COM CHUVA"
            icon="/images/products/chuva.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "rainy_days"
                ? true
                : false
            }
            value="rainy_days"
            reports={this.props.reportTypes.indexOf("rainy_days")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="UMIDADE RELATIVA"
            icon="/images/products/umidade.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "ur"
                ? true
                : false
            }
            value="ur"
            reports={this.props.reportTypes.indexOf("ur")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="ETP"
            icon="/images/products/ETR ETP.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "etp"
                ? true
                : false
            }
            value="etp"
            reports={this.props.reportTypes.indexOf("etp")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="ONDA DE CALOR"
            icon="/images/products/heat_wave.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "heat_wave"
                ? true
                : false
            }
            value="heat_wave"
            reports={this.props.reportTypes.indexOf("heat_wave")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="GEADA"
            icon="/images/products/frost_risk.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "frost_risk"
                ? true
                : false
            }
            value="frost_risk"
            reports={this.props.reportTypes.indexOf("frost_risk")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <div style={{ float: 'right', marginTop: '80px' }}>
            <p style={{ fontSize: "14px", marginRight: '10px', fontStyle: 'italic' }}>
              previsão por
              <a href="https://www.noaa.gov/" rel="noopener noreferrer" target="_blank"> NOAA </a>
              e
              <a href="https://portal.inmet.gov.br/" rel="noopener noreferrer" target="_blank"> INMET </a>
            </p>
          </div>
        </div>
      );
    } else if (this.state.subProducts === "tools") {
      return (
        <div className="sub-products">
          <ReportOption
            label="PLANTIO"
            icon="/images/products/plantio.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "plan"
                ? true
                : false
            }
            value="plan"
            reports={this.props.reportTypes.indexOf("plan")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="HERBICIDA"
            icon="/images/products/herbicida.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "herb"
                ? true
                : false
            }
            value="herb"
            reports={this.props.reportTypes.indexOf("herb")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="TRÁFEGO"
            icon="/images/products/trafego.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "traf"
                ? true
                : false
            }
            value="traf"
            reports={this.props.reportTypes.indexOf("traf")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="PREPARO SOLO"
            icon="/images/products/preparo do solo.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "ps"
                ? true
                : false
            }
            value="ps"
            reports={this.props.reportTypes.indexOf("ps")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="ADUBAÇÃO"
            icon="/images/products/adubo.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "fret"
                ? true
                : false
            }
            value="fert"
            reports={this.props.reportTypes.indexOf("fert")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="CRESCIMENTO"
            icon="/images/products/Crescimento.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "cresc"
                ? true
                : false
            }
            value="cresc"
            reports={this.props.reportTypes.indexOf("cresc")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="PULVERIZAÇÃO"
            icon="/images/products/pulverization.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "pulv"
                ? true
                : false
            }
            value="pulv"
            reports={this.props.reportTypes.indexOf("pulv")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="FAVORABILIDADE CLIMÁTICA"
            icon="/favorabilidadeClimatica.png"
            value="favo"
            onSubmit={this.props.onSubmit}
          />
        </div>
      );
    } else if (this.state.subProducts === "fire") {
      //menu do fire
      return (
        <div className="sub-products">
          <ReportOption
            label="RISCO DE INCÊNDIO"
            icon="/images/products/fire_icone.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "fmaac"
                ? true
                : false
            }
            value="fmaac"
            reports={this.props.reportTypes.indexOf("fmaac")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
        </div>
      );
    } else if (this.state.subProducts === "atrmax" && smartCaneAccess) {
      return (
        <div className="sub-products">
          <ReportOption
            label="ATRmax"
            icon="/images/products/atrmax.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "atrmax"
                ? true
                : false
            }
            value="atrmax"
            reports={this.props.reportTypes.indexOf("atrmax")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmit}
          />
          <ReportOption
            label="Produtividade Geral"
            icon="/images/products/cane.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "productivity"
                ? true
                : false
            }
            value="productivity"
            reports={this.props.reportTypes.indexOf("productivity")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Produtividade Específica"
            icon="/images/products/cane.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "productivity-spec"
                ? true
                : false
            }
            value="productivity-spec"
            reports={this.props.reportTypes.indexOf("productivity-spec")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Risco de Florescimento"
            icon="/images/products/cane-flower.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "cane-flowering"
                ? true
                : false
            }
            value="cane-flowering"
            reports={this.props.reportTypes.indexOf("cane-flowering")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitFloweringRisk}
          />
          <ReportOption
            label="Dashboard"
            icon="/images/products/dashboard.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "dashboard"
                ? true
                : false
            }
            value="cane-dashboard"
            reports={this.props.reportTypes.indexOf("dashboard")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitDashboard}
            hidden={true}
          />
        </div>
      );
    } else if (this.state.subProducts === "smart" && smartSoyAccess) {
      return (
        <div className="sub-products">
          <ReportOption
            label="Produtividade Específica"
            icon="/images/products/soy.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "soy-productivity"
                ? true
                : false
            }
            value="soy-productivity"
            reports={this.props.reportTypes.indexOf("soy-productivity")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Produtividade geral de soja"
            icon="/images/products/soy.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "soy-general-productivity"
                ? true
                : false
            }
            value="soy-general-productivity"
            reports={this.props.reportTypes.indexOf("soy-general-productivity")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Dashboard"
            icon="/images/products/dashboard.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "dashboard"
                ? true
                : false
            }
            value="soy-dashboard"
            reports={this.props.reportTypes.indexOf("dashboard")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitDashboard}
            hidden={true}
          />
        </div>
      );
    } else if (this.state.subProducts === "corn" && smartCornAccess) {
      return (
        <div className="sub-products">
          <ReportOption
            label="Produtividade Específica"
            icon="/images/products/corn.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "corn-spec-productivity"
                ? true
                : false
            }
            value="corn-spec-productivity"
            reports={this.props.reportTypes.indexOf("corn-spec-productivity")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Produtividade geral de milho"
            icon="/images/products/corn.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "corn-general-productivity"
                ? true
                : false
            }
            value="corn-general-productivity"
            reports={this.props.reportTypes.indexOf(
              "corn-general-productivity"
            )}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Dashboard"
            icon="/images/products/dashboard.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "dashboard"
                ? true
                : false
            }
            value="corn-dashboard"
            reports={this.props.reportTypes.indexOf("dashboard")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitDashboard}
            hidden={true}
          />
        </div>
      );
    } else if (this.state.subProducts === "cotton" && smartCottonAccess) {
      return (
        <div className="sub-products">
          <ReportOption
            label="Produtividade Específica"
            icon="/images/products/cotton.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "cotton-spec-productivity"
                ? true
                : false
            }
            value="cotton-spec-productivity"
            reports={this.props.reportTypes.indexOf("cotton-spec-productivity")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Produtividade geral de algodão"
            icon="/images/products/cotton.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "cotton-general-productivity"
                ? true
                : false
            }
            value="cotton-general-productivity"
            reports={this.props.reportTypes.indexOf(
              "cotton-general-productivity"
            )}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Dashboard"
            icon="/images/products/dashboard.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "dashboard"
                ? true
                : false
            }
            value="cotton-dashboard"
            reports={this.props.reportTypes.indexOf("dashboard")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitDashboard}
            hidden={true}
          />
        </div>
      );
    } else if (this.state.subProducts === "wheat" && smartWheatAccess) {
      return (
        <div className="sub-products">
          <ReportOption
            label="Produtividade Específica"
            icon="/images/products/wheat.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "wheat-spec-productivity"
                ? true
                : false
            }
            value="wheat-spec-productivity"
            reports={this.props.reportTypes.indexOf("wheat-spec-productivity")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Produtividade geral de trigo"
            icon="/images/products/wheat.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "wheat-general-productivity"
                ? true
                : false
            }
            value="wheat-general-productivity"
            reports={this.props.reportTypes.indexOf(
              "wheat-general-productivity"
            )}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Dashboard"
            icon="/images/products/dashboard.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "dashboard"
                ? true
                : false
            }
            value="wheat-dashboard"
            reports={this.props.reportTypes.indexOf("dashboard")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitDashboard}
            hidden={true}
          />
        </div>
      );
    } else if (this.state.subProducts === "eucalyptus" && smartEucalyptus) {
      return (
        <div className="sub-products">
          <ReportOption
            label="Produtividade Específica"
            icon="/images/products/wheat.png"
            disabled={false}
            value="eucalyptus-spec-productivity"
            reports={this.props.reportTypes.indexOf(
              "eucalyptus-spec-productivity"
            )}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Produtividade Geral"
            icon="/images/products/wheat.png"
            disabled={
              this.props.reportTypes.length > 0 &&
              this.props.reportTypes[0] !== "eucalyptus-general-productivity"
                ? true
                : false
            }
            value="eucalyptus-general-productivity"
            reports={this.props.reportTypes.indexOf(
              "eucalyptus-general-productivity"
            )}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitProductivity}
          />
          <ReportOption
            label="Dashboard"
            icon="/images/products/dashboard.png"
            disabled={true}
            value="eucalyptus-dashboard"
            reports={this.props.reportTypes.indexOf("dashboard")}
            onChange={this.props.handleReportTypeChange}
            onSubmit={this.props.onSubmitDashboard}
            hidden={true}
          />
        </div>
      );
    }
  };

  onFocus = () => {
    if (!this.state.showLabel) {
      this.setState({
        showLabel: true,
      });
    }
  };

  onBlur = () => {
    this.props.onBlur();
    this.setState({
      showLabel: false,
      addingFromGrid: false,
    });
  };

  onCancel = () => {
    if (this.state.addingFromGrid) {
      this.props.handleEditCancel();
      this.props.setEditMode("");
      this.props.setActiveFarm(-1);
      this.props.toggleLoader();
      this.props.deletePoint(this.props.activeFarm);
      this.props.toggleReportContainer();
    } else if (this.props.addingFlag) {
      this.props.setEditMode("");
      this.props.toggleLoader();
      this.props.deletePoint(this.props.activeFarm);
      this.props.setActiveFarm(this.props.farms["farms"][0]["id"]);
    } else {
      this.props.handleEditCancel();
      this.props.setEditMode("");
    }
    this.props.toggleAddingFlag(false);
    this.setState({
      addingFromGrid: false,
    });
  };

  handleDeletePoint = () => {
    this.props.setMapClickEvent();
    const toasterParams = {
      title: "EXCLUIR PONTO?",
      message: "Tem certeza que deseja excluir esse ponto?",
      status: false,
      autoClose: false,
      buttons: [
        {
          name: "EXCLUIR",
          style: "delete",
          func: this.deletePoint,
        },
        {
          name: "CANCELAR",
          func: toast.dismiss,
        },
      ],
    };
    showToaster(toasterParams);
  };

  deletePoint = () => {
    const deletedFarm = this.props.activeFarm;

    this.props.setActiveFarm(-2);
    this.props.setProductivityUndefined();
    this.props.toggleReportUndefined();
    this.props.setReportTypes([]);
    this.props.deletePoint(deletedFarm);
    this.props.toggleLoader();
    this.props.toggleSidebar();
    this.props.setEditMode("");
  };

  addPointFromGrid = () => {
    let attrs = {
      user: localStorage.Token ? localStorage.Token : sessionStorage.Token,
      coords: this.props.coords,
    };
    this.props.toggleReportContainer();
    this.props.addNewFarm(attrs);
    this.props.toggleSidebarLoader();
    this.setState({
      addingFromGrid: true,
    });
  };

  render() {
    if (this.props.moreFarmsActive) {
      return <MoreFarmsList />;
    } else {
      const { errors, name, coords, initials } = this.props;

      let smartPermissions =
        this.props.activeFarm && this.props.farms["farms"]
          ? this.props.farms["farms"].find(
              (farm) => farm.id === this.props.activeFarm
            )
          : undefined;
      let smartCaneAccess = false;
      let smartSoyAccess = false;
      let smartCornAccess = false;
      let smartCottonAccess = false;
      let smartWheatAccess = false;
      let smartEucalyptus = false;
      if (smartPermissions) {
        if (smartPermissions["smart"]) {
          smartCaneAccess = smartPermissions["smart"].products.includes("cana")
            ? true
            : false;
          smartSoyAccess = smartPermissions["smart"].products.includes("soja")
            ? true
            : false;
          smartCornAccess = smartPermissions["smart"].products.includes("milho")
            ? true
            : false;
          smartCottonAccess = smartPermissions["smart"].products.includes(
            "algodao"
          )
            ? true
            : false;
          smartWheatAccess = smartPermissions["smart"].products.includes(
            "trigo"
          )
            ? true
            : false;
          smartEucalyptus = smartPermissions["smart"].products.includes(
            "eucalipto"
          )
            ? true
            : false;
        }
      }
      const isBasicPlan = this.props.userPlan === "basic";

      return (
        <div className="row">
          <form
            className="farmForm"
            onSubmit={this.props.onSubmit}
            ref={(form) => {
              if (form) this.form = form;
            }}
          >
            <div
              className={classnames("farmForm--body col s12", {
                "edit-mode-open": this.props.editMode !== "",
              })}
            >
              {this.props.user === "bank" ? null : this.props.activeFarm !==
                -1 ? (
                this.props.editMode === "" ? (
                  <Link
                    to="#"
                    className="button button--square button--farm-info-edit"
                    onClick={this.handleEditModeEvent}
                  >
                    <i className="material-icons">mode_edit</i>
                    <label>Editar</label>
                  </Link>
                ) : isBasicPlan ? null : (
                  <Link
                    to="#"
                    className="button button--square button--farm-info-delete"
                    onClick={this.handleDeletePoint}
                  >
                    <i className="material-icons">delete</i>
                    <span>Excluir ponto</span>
                  </Link>
                )
              ) : (
                <Link
                  to="#"
                  className="button button--square button--farm-info-add"
                  onClick={this.addPointFromGrid}
                >
                  <i className="material-icons">add</i>
                  <label>Adicionar</label>
                </Link>
              )}
              {this.props.activeFarm > 0 ? (
                <div className="id-field ">
                  {`ID: ${this.props.activeFarm}`}
                </div>
              ) : null}

              <InputField
                name="name"
                label="NOME DA UNIDADE PRODUTORA"
                onChange={this.props.onChange}
                value={name}
                error={errors.name}
                active={name !== ""}
                activeFarm={
                  this.props.user === "bank" ? "" : this.props.editMode
                }
              />
              <InputField
                name="initials"
                label="SIGLA"
                onChange={this.props.onChange}
                value={initials}
                error={errors.name}
                length={4}
                active={initials !== ""}
                activeFarm={
                  this.props.user === "bank" ? "" : this.props.editMode
                }
                onFocus={this.onFocus}
                labelActive={this.state.showLabel}
              />

              <div className="coords-div">
                <InputField
                  name="LAT"
                  label="Latitude"
                  onChange={this.props.onChange}
                  value={coords}
                  error={errors.lat}
                  active={coords !== ""}
                  disabled={
                    smartCaneAccess ||
                    smartSoyAccess ||
                    smartCornAccess ||
                    smartCottonAccess ||
                    isBasicPlan
                      ? "disabled"
                      : undefined
                  }
                  activeFarm={
                    this.props.user === "bank" ||
                    smartCaneAccess ||
                    smartSoyAccess ||
                    smartCornAccess ||
                    smartCottonAccess ||
                    smartWheatAccess ||
                    smartEucalyptus ||
                    isBasicPlan
                      ? ""
                      : this.props.editMode
                  }
                />
                <InputField
                  name="LNG"
                  label="Longitude"
                  value={coords}
                  error={errors.lon}
                  onChange={this.props.onChange}
                  active={coords !== ""}
                  disabled={
                    smartCaneAccess ||
                    smartSoyAccess ||
                    smartCornAccess ||
                    smartCottonAccess ||
                    isBasicPlan
                      ? "disabled"
                      : undefined
                  }
                  activeFarm={
                    this.props.user === "bank" ||
                    smartCaneAccess ||
                    smartSoyAccess ||
                    smartCornAccess ||
                    smartCottonAccess ||
                    smartWheatAccess ||
                    smartEucalyptus ||
                    isBasicPlan
                      ? ""
                      : this.props.editMode
                  }
                />
                {this.props.editMode !== "" ? (
                  this.props.user === "bank" ||
                  smartCaneAccess ||
                  smartSoyAccess ||
                  smartCornAccess ||
                  smartCottonAccess ||
                  smartWheatAccess ||
                  smartEucalyptus ||
                  isBasicPlan ? null : (
                    <Fragment>
                      <div className="col s1"></div>
                      <div className="col s1">
                        <Link
                          to="#"
                          className="button button--square button--square-edit"
                          onClick={this.handleEditEvent}
                        >
                          <i className="material-icons">edit_location</i>
                        </Link>
                      </div>
                    </Fragment>
                  )
                ) : null}
              </div>
              <div className="col s12" id="cad-div">
                <label>Capacidade de água disponível (CAD):</label>
                <span>{this.props.cad || 0}mm</span>
              </div>
              <div className="soil-div col s12">
                <div className="col s6">
                  <label>Textura do Solo</label>
                </div>
                <div className="input-field select-field col s6">
                  <DropdownField
                    value={
                      this.props.soilTexture ? this.props.soilTexture : "null"
                    }
                    onChange={this.props.handleDropdownChange}
                    type={"soil-texture"}
                    disabled={
                      this.props.user === "bank" || this.props.activeFarm === -1
                        ? false
                        : this.props.editMode === ""
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <div className="soil-div col s12">
                <div className="col s6">
                  <label>Profundidade do Solo</label>
                </div>
                <div className="input-field select-field col s6">
                  <DropdownField
                    value={this.props.soilDepth ? this.props.soilDepth : "null"}
                    onChange={this.props.handleDropdownChange}
                    type={"soil-depth"}
                    disabled={
                      this.props.user === "bank" || this.props.activeFarm === -1
                        ? false
                        : this.props.editMode === ""
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
            {this.props.editMode === "" ? (
              <Fragment>
                <div className="form-divider col s12">
                  <label>SELEÇÃO DE PRODUTOS</label>
                </div>

                <div className="reports col s12">
                  <Fragment>
                    <div className="input-field sub-products-select-field col s12">
                      <DropdownField
                        value={this.state.subProducts}
                        onChange={this.handleProductsDropdownChange}
                        type={"products"}
                        user={this.props.prodUser}
                        smartPermissions={
                          this.props.activeFarm && this.props.farms["farms"]
                            ? this.props.farms["farms"].find(
                                (farm) => farm.id === this.props.activeFarm
                              )
                            : undefined
                        }
                      />
                    </div>
                    {this.renderSubProducts(
                      smartCaneAccess,
                      smartSoyAccess,
                      smartCornAccess,
                      smartCottonAccess,
                      smartWheatAccess,
                      smartEucalyptus
                    )}
                  </Fragment>
                </div>
              </Fragment>
            ) : (
              <div className="row button">
                <div className="col s12">
                  {this.props.updatePending ? null : (
                    <button
                      className="btn-flat button--farm-info-cancel"
                      onClick={this.onCancel}
                    >
                      CANCELAR
                    </button>
                  )}
                  <button
                    type="submit"
                    className="btn wave-light green right"
                    disabled={!this.props.soilTexture || !this.props.soilDepth}
                    onClick={this.onBlur}
                  >
                    SALVAR
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMapClickEvent: () => {
      dispatch(allowMapClickEvent());
    },
  };
}

export default connect(null, mapDispatchToProps)(FarmForm);
