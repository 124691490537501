import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ClickOutside from "react-click-outside"
import moment from "moment"
import { isEqual } from "lodash"

// Actions
import { setApplication } from '../actions/application'
import { setActiveFarm } from '../actions/farms'
import { logout,setLoginErrorsToNull } from '../actions/login'
import { setErrorsToNull } from '../actions/users'
import { toggleLoader } from '../actions/global'
import { getUserNotifications, getUserDetailedNotification, resetSpecificNotifications } from '../actions/notification'

// Components
import { showToaster } from '../components/common/Toaster'
import SidebarHeader from './SidebarHeader'
import AddFarm from './AddFarm'
import NotificationDetail from "../components/notification/NotificationDetail"

// Libs
import applicationConfig from '../lib/applications'
import { Paper, Grid, List, ListItem } from '@material-ui/core'

class Navbar extends Component {
  constructor(props){
    super(props)

    this.state= {
      mdText: '',
      showModal: false,
      loginModal: false,
      signInModal: false,
      showNotifications: false,
      showNotificationsModal: false
    }
  }

  componentDidMount(){
    this.props.getUserNotifications()
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.createResult !== undefined && prevProps.createResult === undefined){

      const toasterParams = {
        title: 'SUCESSO!',
        message: this.props.createResult['message'],
        status: true,
        autoClose: true
      }  
      showToaster(toasterParams)

      this.onSignInCloseClick()
    }

    if(!isEqual(prevProps.detailedNotifications, this.props.detailedNotifications)) {
      this.toggleNotificationModal()
    }

    if(isEqual(prevProps.specificNotifications, this.props.specificNotifications)) {
      if (this.props.specificNotifications.length !== 0 && this.state.showNotificationsModal === false){
      this.toggleNotificationModal()
      }
    }

    if(!isEqual(prevProps.specificNotifications, this.props.specificNotifications)) {
      if (this.props.specificNotifications.length !== 0 && this.state.showNotificationsModal === false){
      this.toggleNotificationModal()
      }
    }
  }

  openLoginModal = () => {
    this.setState({
      loginModal: true
    })
  }

  toggleNotificationList = () => {
    this.setState({
      showNotifications: !this.state.showNotifications
    })
  }
  
  toggleNotificationModal = () => {
    this.setState({
      showNotificationsModal: !this.state.showNotificationsModal
    })
  }

  onLoginCloseClick = () => {
    this.props.setLoginErrorsToNull()
    this.setState({
      loginModal: false
    })
  }

  openSignInModal = () =>{
    this.setState({
      signInModal: true
    })
  }

  onSignInCloseClick = (e) => {
    this.props.setErrorsToNull()
    this.setState({
      signInModal: false
    })
  }

  handleCloseModal = () => {
    this.toggleNotificationModal()
    this.props.resetSpecificNotifications()
  }     

  handleShowInformation = () => {
    return 
  }

  handleLogout = () => {
    this.props.toggleLoader()
    this.props.logout()
  }

  farmSearch() {
    if(this.props.farms.length === 0 && this.props.logged) {
      return(
        <ul>
          <li>
            <AddFarm active={ true }/>
          </li>
        </ul>
      )
    }
  }

  handleSelectFarm = (e) => {
    
    const toasterParams = {
      title: 'ERRO!',
      message: "Insira um valor para a CAD.",
      status: false,
      autoClose: true
    }  
    this.props.onPinClicked(e, () => showToaster(toasterParams), true);
  }

  toggleDetailedNotification = (e) => {
    this.props.getUserDetailedNotification(e.currentTarget.id)
    if (this.props.specificNotifications.length !== 0){
      this.toggleNotificationModal()
    }
  }

  render() {
        return(
          <div>
            <nav style={ applicationConfig.applications[this.props.application]['styles'] }>
              <div className='nav-wrapper col s8'>
                {this.props.logged && this.props.user['type'] !== 'bank'?
                  <SidebarHeader selectFarm={this.handleSelectFarm} clearForm={ this.clearForm }/>
                  : null
                }
                { this.farmSearch() }

                <img style={{width:'156px'}} src='/images/agrymax_branco.png' alt='agrymax-logo' onClick={() => window.location.reload()} className="brand-logo center landing-logo"/>
                <div>
                  <ul id='nav-mobile' className='right hide-on-med-and-down'>
                    <li title='Dados do Usuário'>
                      <Link to='' onClick={this.props.onProfileClicked}>
                        <i className='material-icons'>{this.props.profileActive ? 'person_outline' : 'person'}</i>
                      </Link>
                    </li>
                    <li title="Notificações do Sistema">
                      <Link onClick={this.toggleNotificationList}>
                        <i className="material-icons">notifications</i>
                      </Link>
                    </li>
                    <li title='Painel de auditoria'>
                      <Link to='/audit/automaticAudit'>
                        <i className='material-icons'>content_paste</i>
                      </Link>
                    </li>
                    <li title='Upload de Dados'>
                      <Link to='/upload'>
                        <i className='material-icons'>file_upload</i>
                      </Link>
                    </li>
                    <li title='Sair'>
                      <Link to='/' onClick={this.handleLogout}>
                        <i className='material-icons'>exit_to_app</i>
                      </Link>
                    </li>
                  </ul>
                </div>
                {this.state.showNotifications &&
                  <ClickOutside onClickOutside={this.toggleNotificationList} >
                    <Paper className="notification-list" >
                      <List >
                        {this.props.notificationList.map(item => {
                          return (
                            <ListItem>
                              <Link id={item.id} onClick={this.toggleDetailedNotification}>
                                <Grid container xs={12} className="notification-item">
                                  <Grid item xs={12}>
                                    <span><b>{item.title}</b></span>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <span>{moment(item.edited_at).format('DD/MM/YYYY')}</span>
                                  </Grid>
                                </Grid>
                              </Link>
                            </ListItem>
                          )
                        })}
                      </List>
                    </Paper>
                  </ClickOutside>
                }
                <NotificationDetail openModal={this.state.showNotificationsModal} closeModal={this.handleCloseModal} notifications={(this.props.detailedNotifications.length !== 0) ? this.props.detailedNotifications : this.props.specificNotifications}/>
              </div>
            </nav>
          </div>
        )
  }
}

function mapStateToProps(state) {
  return {
    application: state.Application.application,
    farms: state.farms,
    profileActive: state.global.profileActive,
    isLogged: state.auth.isLoggedIn,
    createResult: state.users.createResult,
    user: state.auth.user,
    notificationList: state.notification.historyList,
    detailedNotifications: state.notification.detailedList,
    specificNotifications: state.notification.specific
  }

}

function mapDispatchToProps(dispatch) {
  return {
    changeApplication: (application) => {
      dispatch(setApplication(application))
    },
    setActiveFarm: (id) =>{
      dispatch(setActiveFarm(id))
    },
    logout: () => {
      dispatch(logout())
    },
    setErrorsToNull: () => {
      dispatch(setErrorsToNull())
    },
    setLoginErrorsToNull: () => {
      dispatch(setLoginErrorsToNull())
    },
    resetSpecificNotifications: () => {
      dispatch(resetSpecificNotifications())
    },
    getUserNotifications: () => {
      dispatch(getUserNotifications())
    },
    getUserDetailedNotification: (id) => {
      dispatch(getUserDetailedNotification(id))
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
