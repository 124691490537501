import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { downloadWeatherByPoints } from "../../actions/profile"

import { Dialog, Grid, List, Checkbox, FormControlLabel, DialogActions, Button } from "@material-ui/core"
import "../../stylesheets/css/components/profile.css"

import ReportOption from "../ReportOption"

const WeatherDownloadModal = ({ openModal, closeModal }) => {
    const [productList, setProductList] = useState([]);
    const [pointList, setPointList] = useState([]);

    const dispatch = useDispatch()
    const farmList = useSelector(state => state.farms.points)

    function changeProductList(event) {
        const product = event.target.value

        if(productList.includes(product)) {
            setProductList(productList.filter(item => item !== product))
        }
        else {
            setProductList([...productList, product])
        }
    }

    function changePointList(event) {
        const point = event.target.value

        if(pointList.includes(point)) {
            setPointList(pointList.filter(item => item !== point))
        }
        else {
            setPointList([...pointList, point])
        }
    }

    function downloadWeatherData() {
        const data = {
          points: pointList,
          variables: productList
        }
        dispatch(downloadWeatherByPoints(data))
      }

    return (
        <Dialog open={openModal} onClose={closeModal} maxWidth="md" fullWidth className="weather-download-modal">
            <Grid container >
                <Grid item container md={6} justify="center" alignContent="flex-start">
                    <Grid item container xs={12} justify={"center"}>
                        <h6>Produtos</h6>
                    </Grid>
                    <List style={{maxHeight: "420px", overflowY: "auto", overflowX: "hidden"}}>
                        <Grid item container spacing={16} className="product-container">
                            <Grid item md={4}>
                                <ReportOption
                                    label='TEMPERATURA'
                                    icon={
                                        productList.includes("temperatura")
                                        ? '/images/products/green-colored/temperature.png'
                                        : '/images/products/temperature.png'
                                    }
                                    disabled={false}
                                    value='temperatura'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='RADIAÇÃO SOLAR'
                                    icon={
                                        productList.includes("rs")
                                        ? '/images/products/green-colored/radiacao-solar.png'
                                        : '/images/products/radiacao solar.png'
                                    }
                                    disabled={false}
                                    value='rs'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='GRAUS-DIA'
                                    icon={
                                        productList.includes("gd")
                                        ? '/images/products/green-colored/grausdia.png'
                                        : '/images/products/grausdia.png'
                                    }
                                    disabled={false}
                                    value='gd'
                                    reports={1}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='VENTO A 2 METROS'
                                    icon={
                                        productList.includes("u2")
                                        ? '/images/products/green-colored/vento.png'
                                        : '/images/products/vento.png'
                                    }
                                    disabled={false}
                                    value='u2'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='CHUVA'
                                    icon={
                                        productList.includes("prec")
                                        ? '/images/products/green-colored/chuva.png'
                                        : '/images/products/chuva.png'
                                    }
                                    disabled={false}
                                    value='prec'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='DIAS COM CHUVA'
                                    icon={
                                        productList.includes("rainy_days")
                                        ? '/images/products/green-colored/chuva.png'
                                        : '/images/products/chuva.png'
                                    }
                                    disabled={false}
                                    value='rainy_days'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='UMIDADE RELATIVA'
                                    icon={
                                        productList.includes("ur")
                                        ? '/images/products/green-colored/umidade.png'
                                        : '/images/products/umidade.png'
                                    }
                                    disabled={false}
                                    value='ur'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='ETP'
                                    icon={
                                        productList.includes("etp")
                                        ? '/images/products/green-colored/ETR-ETP.png'
                                        : '/images/products/ETR ETP.png'
                                    }
                                    disabled={false}
                                    value='etp'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='ONDA DE CALOR'
                                    icon={
                                        productList.includes("heat_wave")
                                        ? '/images/products/green-colored/heat_wave.png'
                                        : '/images/products/heat_wave.png'
                                    }
                                    disabled={false}
                                    value='heat_wave'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ReportOption
                                    label='GEADA'
                                    icon={
                                        productList.includes("frost_risk")
                                        ? '/images/products/green-colored/frost_risk.png'
                                        : '/images/products/frost_risk.png'
                                    }
                                    disabled={false}
                                    value='frost_risk'
                                    reports={0}
                                    onChange={changeProductList}
                                />
                            </Grid>
                        </Grid>
                    </List>
                </Grid>
                <Grid item container md={6} justify="center">
                    <Grid item container xs={12} justify={"center"}>
                        <h6>Pontos</h6>
                    </Grid>
                    <List style={{maxHeight: "420px", overflowY: "auto", overflowX: "hidden"}}>
                        <Grid item container justify="center" className="point-list">
                            {farmList.farms.map(row => (
                                <Grid item xs={8}>
                                    <FormControlLabel
                                        control= {
                                            <Checkbox
                                                value={row.id}
                                                onChange={changePointList}
                                                checked={pointList.includes(row.id.toString())}
                                            />
                                        }
                                        label={row.name !== "" 
                                            ? row.initials + " - " + row.name
                                            : "Ponto " + row.initials
                                        }
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Grid>
            </Grid>
            <DialogActions>
                <Button onClick={downloadWeatherData} >Download</Button>
            </DialogActions>
        </Dialog>
    )
}

export default WeatherDownloadModal