import { connect } from 'react-redux'
import SidebarContent from '../components/SidebarContent'

// Actions
import { generateReport, toggleReportContainer, resetReport,setReportTypes, setWeatherTypes, setLastUsedCad, setLastUsedSoil, setLastVisualizedCad, generateReportToStore} from '../actions/reports'
import { addFarm,editFarm,saveAuxEditedCoords, setEditMode, deletePoint, setActiveFarm, addingFlag} from '../actions/farms'
import { allowMapClickEditEvent, generateMapData, changeMapDay, setGridPointName, resetGrid } from '../actions/map'
import {
  toggleSidebar, toggleProfileBar, toggleMoreFarms, setCadError, toggleLoader,toggleSidebarLoader, generatingMapData, toggleCorrectTypesFlag, changeVisualizationYear, changeFilterParams
} from '../actions/global'
import { generateProductivity, generateSpecificProductivity, generateSpecificEucalyptusProductivity , setProductivityUndefined, setChangedCadFlag, generateSoyProductivity, generateCornProductivity, generateCottonProductivity, generateWheatProductivity, generateEucalyptusProductivity } from '../actions/productivity';
import { getCad } from '../actions/cad'
import { getFloweringRisk } from '../actions/flowering'
import { setDashboardCulture } from '../actions/dashboard'

function mapStateToProps(state, ownProps) {
  return {
    farms: state.farms.points,
    activeFarm: state.farms.activeFarm,
    activeCad: state.cad.cad,
    active: state.global.sidebarActive,
    profileActive: state.global.profileActive,
    reportActive: state.Reports.reportActive,
    moreFarmsActive: state.global.moreFarmsActive,
    cadError: state.global.cadError,
    allowMapClickEditEvent: state.map.allowMapClickEditEvent,
    user: state.auth.user,
    selectedGridPointCoords: state.map.selectedGridPointCoords,
    selectedGridPointName: state.map.selectedGridPointName,
    sidebarLoading: state.global.sidebarLoader,
    reportTypes: state.Reports.reportTypes,
    weatherType: state.Reports.graphicType,
    weatherRange: state.Reports.graphicRange,
    activeDate: state.map.weatherMapDay,
    mapYear: state.map.mapYear,
    editCoords: state.farms.editCoords,
    report: state.Reports.report,
    editMode: state.farms.editMode,
    tBasal: state.Reports.tBasal,
    xavierPoints: state.map.xavierGridPoints !== undefined ? state.map.xavierGridPoints['xavier_points'] : state.map.xavierGridPoints,
    lastUsedSoil: state.Reports.lastUsedSoil,
    addingFlag: state.farms.addingFlag,
    lastUsedCad: state.Reports.lastUsedCad,
    lastVisualizedCad: state.Reports.lastVisualizedCad,
    productivity: state.productivity.data,
    visualizationType: state.global.visualizationType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    generateReport: (reportType, farmAttrs) => {
      dispatch(generateReport(reportType, farmAttrs))
    },
    generateReportToStore: (type, attr) => {
      dispatch(generateReportToStore(type, attr))
    },
    editFarm: (id, attrs) => {
      dispatch(editFarm(id, attrs))
    },
    setMapClickEditEvent: () => {
      dispatch(allowMapClickEditEvent())
    },
    toggleSidebar: () =>{
      dispatch(toggleSidebar())
    },
    toggleMoreFarmsActive: () =>{
      dispatch(toggleMoreFarms())
    },
    toggleReportContainer: ()=>{
      dispatch(toggleReportContainer())
    },
    toggleReportUndefined: ()=>{
      dispatch(resetReport())
    },
    setReportTypes: (reportTypes) => {
      dispatch(setReportTypes(reportTypes))
    },
    setWeatherTypes: (graphicType, graphicRange) => {
      dispatch(setWeatherTypes(graphicType,graphicRange))
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes,attrs))
    },
    setCadError : () => {
      dispatch(setCadError())
    },
    setLastUsedCad: (cad) => {
      dispatch(setLastUsedCad(cad))
    },
    setMapDay: (day) => {
      dispatch(changeMapDay(day))
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    generatingMapData: () => {
      dispatch(generatingMapData())
    },
    saveAuxEditedCoords: (coords, auxCoords) => {
      dispatch(saveAuxEditedCoords(coords,auxCoords))
    },
    setEditMode: (mode) => {
      dispatch(setEditMode(mode))
    },
    addNewFarm: (coords) => {
      dispatch(addFarm(coords))
    },
    deletePoint: (id) => {
      dispatch(deletePoint(id))
    },
    toggleSidebarLoader: () => {
      dispatch(toggleSidebarLoader())
    },
    toggleProfileBar: () => {
      dispatch(toggleProfileBar())
    },
    setActiveFarm: (id) => {
      dispatch(setActiveFarm(id))
    },
    setLastUsedSoil: (soil) => {
      dispatch(setLastUsedSoil(soil))
    },
    toggleAddingFlag: (flag) =>{
      dispatch(addingFlag(flag))
    },
    setGridPointName: (name) => {
      dispatch(setGridPointName(name))
    },
    generateProductivity: data => {
      dispatch(generateProductivity(data))
    },
    generateSpecificProductivity: data => {
      dispatch(generateSpecificProductivity(data))
    },
    generateSpecificEucalyptusProductivity: (data, type) => {
      dispatch(generateSpecificEucalyptusProductivity(data, type))
    },
    toggleCorrectTypesFlag: () => {
      dispatch(toggleCorrectTypesFlag())
    },
    setProductivityUndefined: () => {
      dispatch(setProductivityUndefined())
    },
    setLastVisualizedCad: (cad) => {
      dispatch(setLastVisualizedCad(cad))
    },
    setChangedCadFlag: (value) => {
      dispatch(setChangedCadFlag(value))
    },
    generateSoyProductivity: (data, type) => {
      dispatch(generateSoyProductivity(data, type))
    },
    generateCornProductivity: (data, type) => {
      dispatch(generateCornProductivity(data, type))
    },
    generateCottonProductivity: (data, type) => {
      dispatch(generateCottonProductivity(data, type))
    },
    generateWheatProductivity: (data, type) => {
      dispatch(generateWheatProductivity(data, type))
    },
    generateEucalyptusProductivity: (data, type) => {
      dispatch(generateEucalyptusProductivity(data, type))
    },
    getCad: (soil) => {
      dispatch(getCad(soil))
    },
    changeVisualizationYear: visualization => {
      dispatch(changeVisualizationYear(visualization))
    },
    changeFilterParams: filterParams => {
      dispatch(changeFilterParams(filterParams))
    },
    resetGrid: () => {
      dispatch(resetGrid())
    },
    getFloweringRisk: (data) => {
      dispatch(getFloweringRisk(data))
    },
    setDashboardCulture: (culture) => {
      dispatch(setDashboardCulture(culture))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent)
