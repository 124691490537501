import React, { useState, Fragment } from "react";
import { connect } from "react-redux";

import { FormControl, TextField, Grid } from "@material-ui/core";
import Select from "../../common/SearchDropdown";
import { getYearsInterval } from "../../../shared/utils/DatesUtils";
import { PRODUCTIVITY } from "../../reports/productivity/common/defaultValues";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import {
  downloadProductivityData,
  resetDownload,
} from "../../../actions/admin";

const ProductivityForm = (props) => {
  const [cutNumber, setCutNumber] = useState(
    props.cutNumber ? props.cutNumber : undefined
  );
  const [kdec, setKdec] = useState(props.kdec ? props.kdec : undefined);
  const [efficiency, setEfficiency] = useState(PRODUCTIVITY.EFFICIENCY);
  const [cad, setCad] = useState({ state: "cad", value: 50, label: "50" });
  const [cycle, setCycle] = useState(
    props.cycle
      ? { state: "cycle", value: props.cycle, label: props.cycle.toString() }
      : undefined
  );
  const [plantingDate, setPlantingDate] = useState(null);
  const [ccUser, setCcUser] = useState(props.ccUser);
  const [moisturePercentUser, setMoisture] = useState(props.moisturePercent);
  const [yearRange, setYearRange] = useState("");
  const [frost, setFrost] = useState(false);
  const [requiredPlantingDate, setRequiredPlantingDate] = useState(false);
  const [requiredYearRange, setRequiredYearRange] = useState(false);
  //eslint-disable-next-line
  const [requiredPoints, setRequiredPoints] = useState(false);

  async function  onSubmit() {
    let plantingDateFlag = false,
      yearRangeFlag = false,
      pointsFlag = false;
    if (plantingDate !== null && yearRange !== "") {
      const yearsArray = getYearsInterval(yearRange, 1980).map((year) => {
        return `&years=${year}`;
      });

      const data = {
        idsFarmsOfCheckbox: props.idsFarmsOfCheckbox,
        type: props.type,
        points: props.points,
        cad: cad.value,
        date: plantingDate.replace(/\//g, "-"),
        cc_user: ccUser,
        u_percent_user: moisturePercentUser,
        agry_eficiency: efficiency > 1 ? efficiency / 100 : efficiency,
        kdec: kdec,
        cycle: cycle.value,
        cut_number: cutNumber,
        cane_type: cutNumber !== 1 ? "rest" : "plant",
        point_id: props.id,
        years: yearsArray.join(""),
        frost: frost,
      };
      props.setPageToDownload();
      props.setLinkToDownload(await downloadProductivityData(data));
    } else {
      if (plantingDate == null) {
        plantingDateFlag = !plantingDateFlag;
      }
      if (yearRange === "") {
        yearRangeFlag = !yearRangeFlag;
      }
      if (props.pointsRequired.length === 0) {
        pointsFlag = !pointsFlag;
      }
      setRequiredPlantingDate(plantingDateFlag);
      setRequiredYearRange(yearRangeFlag);
      setRequiredPoints(pointsFlag);
      props.parentStatePoints(pointsFlag);
    }
  }

  function handleChange(value) {
    if (value.target.name === "frost") {
      setFrost(!frost);
    }
  }

  return (
    <FormControl style={{ padding: "50px" }}>
      {!props.isSendingCSV ? (
        <>
          <Grid container justify="space-between" alignItems="flex-end">
            <Grid item className="product-item" justify="flex-start" sm={4}>
              {props.cutNumber ? (
                <Fragment>
                  <p className="label">Número do Corte</p>
                  <TextField
                    margin={"dense"}
                    name="cutNumber"
                    type="text"
                    value={cutNumber}
                    onChange={(e) => setCutNumber(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: { width: "100%" },
                      error: true,
                      inputProps: { style: { height: "2rem", margin: 0 } },
                    }}
                    className="text-fields"
                    style={{ width: "80%" }}
                    FormHelperTextProps={{
                      error: true,
                      className: "input-error-text",
                    }}
                  />
                </Fragment>
              ) : null}
            </Grid>
            <Grid item className="product-item" sm={4}>
              {props.kdec ? (
                <Fragment>
                  <p className="label">Kdec</p>
                  <TextField
                    margin={"dense"}
                    name="kdec"
                    type="text"
                    value={kdec}
                    onChange={(e) => setKdec(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      style: { width: "100%" },
                      error: true,
                      inputProps: { style: { height: "2rem", margin: 0 } },
                    }}
                    className="text-fields"
                    style={{ width: "90%" }}
                    FormHelperTextProps={{
                      error: true,
                      className: "input-error-text",
                    }}
                  />
                </Fragment>
              ) : null}
            </Grid>
            <Grid item lg={4} xl={4}>
              <p className="label margin-left-10">Ciclo</p>
              <Select
                name="cycle"
                inputProps={{ name: "cycle", id: "cycle-input" }}
                className="prod-months-dropdown"
                value={cycle}
                onChange={(e) => setCycle(e)}
                options={props.cycleOptions}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item className="product-item" justify="flex-start" sm={4}>
              <p className="label">Índice de colheita</p>
              <TextField
                margin={"dense"}
                name="ccUser"
                type="text"
                value={ccUser}
                onChange={(e) => setCcUser(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: { width: "100%" },
                  error: true,
                  inputProps: { style: { height: "2rem", margin: 0 } },
                }}
                className="text-fields"
                style={{ width: "80%" }}
                FormHelperTextProps={{
                  error: true,
                  className: "input-error-text",
                }}
              />
            </Grid>
            <Grid item lg={4} xl={4}>
              <p className="label">{`Umidade do ${
                props.type === "cane" ? "Colmo" : "Grão"
              } (%)`}</p>
              <TextField
                margin={"dense"}
                name="moisturePercentUser"
                type="text"
                value={moisturePercentUser}
                onChange={(e) => setMoisture(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: { width: "100%" },
                  error: true,
                  inputProps: { style: { height: "2rem", margin: 0 } },
                }}
                className="text-fields"
                style={{ width: "90%" }}
                FormHelperTextProps={{
                  error: true,
                  className: "input-error-text",
                }}
              />
            </Grid>
            <Grid
              container
              item
              className="product-item"
              justify="flex-start"
              sm={4}
            >
              <Grid item lg={12} xl={12}>
                <p className="label margin-left-10">Cad</p>
                <Select
                  name="cad"
                  inputProps={{ name: "cad", id: "cad-input" }}
                  className="prod-months-dropdown"
                  value={cad}
                  onChange={(e) => setCad(e)}
                  options={[
                    { state: "cad", value: 25, label: 25 },
                    { state: "cad", value: 50, label: 50 },
                    { state: "cad", value: 75, label: 75 },
                    { state: "cad", value: 100, label: 100 },
                    { state: "cad", value: 150, label: 150 },
                    { state: "cad", value: 200, label: 200 },
                    { state: "cad", value: 250, label: 250 },
                    { state: "cad", value: 300, label: 300 },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item lg={4} xl={4}>
              <p className="label">Eficiência</p>
              <TextField
                margin={"dense"}
                name="efficiency"
                type="text"
                value={efficiency}
                onChange={(e) => setEfficiency(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: { width: "100%" },
                  error: true,
                  inputProps: { style: { height: "2rem", margin: 0 } },
                }}
                className="text-fields"
                style={{ width: "80%" }}
                FormHelperTextProps={{
                  error: true,
                  className: "input-error-text",
                }}
              />
            </Grid>
            <Grid
              item
              className="product-item"
              justify="flex-start"
              lg={4}
              xl={4}
            >
              <p className="label">Data de plantio (Formato DD-MM)</p>
              {requiredPlantingDate === true ? (
                <div style={{ color: "red", marginTop: "5px" }}>
                  *Escolha uma data de plantio
                </div>
              ) : null}
              <TextField
                fullWidth
                margin={"dense"}
                name="plantingDate"
                type="text"
                value={plantingDate}
                onChange={(e) => setPlantingDate(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  style: { width: "91%" },
                  error: true,
                  inputProps: { style: { height: "2rem", margin: 0 } },
                }}
                className="text-fields"
              />
            </Grid>
            <Grid
              container
              item
              className="product-item"
              justify="flex-end"
              sm={4}
            >
              <Grid item sm={12}>
                <p className="label margin-left-10">
                  Anos para gerar (Formato YYYY)
                </p>
                {requiredYearRange === true ? (
                  <div style={{ color: "red", margin: "5px 0 0 10%" }}>
                    *Escolha uma ano
                  </div>
                ) : null}
                <TextField
                  fullWidth
                  margin={"dense"}
                  name="yearRange"
                  type="text"
                  value={yearRange}
                  onChange={(e) => setYearRange(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    style: { width: "112%" },
                    error: true,
                    inputProps: { style: { height: "2rem", margin: 0 } },
                  }}
                  className="text-fields margin-left-10"
                  style={{ width: "90%" }}
                />
              </Grid>
              <Grid item sm={12} className="text-align-end">
                <p className="helper">
                  {" "}
                  Para definir anos individualmente: 2018, 2019, 2020
                </p>
                <p className="helper">
                  {" "}
                  Para definir intervalos entre anos: 2018-2020
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} xl={12}>
              {props.type !== "wheat" ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={frost}
                      onChange={handleChange}
                      name="frost"
                      style={{ color: "green" }}
                    />
                  }
                  label="Penalização de Geada"
                />
              ) : null}
            </Grid>
          </Grid>
          <Grid item lg={12} xl={12}>
            <button className="btn wave-light green" onClick={onSubmit}>
              DOWNLOAD
            </button>
          </Grid>
        </>
      ) : (
        ""
      )}
    </FormControl>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    downloadDataReturn: state.admin.downloadData,
  };
}

function mapDispatchToProps(dispatch) {
  return {

    resetDownload: () => {
      dispatch(resetDownload());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductivityForm);
