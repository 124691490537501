import React from 'react';
import PropTypes from 'prop-types';

import { TableRow, TableCell, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import ScheduleTableSelect from './ScheduleTableSelect';

function ScheduleTableRow(props) {
  const {
    classes, index, row, editId, update, handleChange, handleUpdate, setDeleteId, setEditId,
  } = props;
  const rowColor = index % 2 === 0 ? 'evenCell' : 'oddCell';
  const cellClasses = [classes.tableCellBody, classes[rowColor]];
  const isEditing = editId === row.id;

  const periodItems = [
    { value: 1, label: 'Diário' },
    { value: 7, label: 'Semanal' },
    { value: 31, label: 'Mensal' },
  ]
  const dayTimeItems = [
    { value: 'manhã', label: 'Manhã' },
    { value: 'tarde', label: 'Tarde' },
    { value: 'noite', label: 'Noite' },
  ];

  const getPeriod = (period) => {
    switch (period) {
      case 1:
        return 'Diário';
      case 7:
        return 'Semanal';
      default:
        return 'Mensal';
    }
  };

  const getDayTime = (dayTime) => {
    switch (dayTime) {
      case 'morning':
        return 'Manhã';
      case 'afternoon':
        return 'Tarde';
      default:
        return 'Noite';
    }
  }

  return (
    <TableRow>
      <TableCell className={ cellClasses }>
        { row.name }
      </TableCell>
      <TableCell className={ cellClasses }>
        { `${row.graphic_product_type} / ${row.graphic_type} / ${row.graphic_scale}` }
      </TableCell>
      <TableCell className={ cellClasses }>
        { 
          !isEditing ? getPeriod(row.period)
            : (
              <ScheduleTableSelect
                value={ update.period }
                name="period"
                handleChange={ handleChange }
                items={ periodItems }
              />
            )
        }
      </TableCell>
      <TableCell className={ cellClasses }>
        { 
          !isEditing ? getDayTime(row.day_time)
            : (
              <ScheduleTableSelect
                value={ update.dayTime }
                name="dayTime"
                handleChange={ handleChange }
                items={ dayTimeItems }
              />
            )
        }
      </TableCell>
      <TableCell className={ cellClasses }>
        { isEditing ? <input
          name="email"
          value={ update.email }
          onChange={ handleChange }
          margin="normal"
        /> : row.email }
      </TableCell>
      <TableCell className={ cellClasses }>
        <div style={ { display: 'flex' } }>
          <Fab
            size="small"
            className={ classes.icons }
            onClick={ isEditing ? () => handleUpdate(row) : () => setEditId(row.id) }
          >
            { isEditing ? <CheckIcon /> : <EditIcon /> }
          </Fab>
          <Fab size="small" className={ classes.icons }>
            <DeleteIcon onClick={ () => setDeleteId(row.id) } />
          </Fab>
        </div>
      </TableCell>
    </TableRow>
  );
}

ScheduleTableRow.propTypes = {
  classes: PropTypes.object,
  index: PropTypes.number,
  row: PropTypes.object,
  editId: PropTypes.number,
  update: PropTypes.object,
  handleChange: PropTypes.func,
  handleUpdate: PropTypes.func,
  setEditId: PropTypes.func,
  setDeleteId: PropTypes.func,
}.isRequired;

export default ScheduleTableRow;
