import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import validateInput from '../shared/validators/specificProdValidator';
import DownloadXLS from '../shared/utils/DownloadXLS';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import SpecificProductivityBoxplot from '../components/reports/productivity/SpecificProductivityBoxplot';
import ObservedSpecificProductivityChart from '../components/reports/productivity/ObservedSpecificProductivityChart';
import SpecificProductivityOptions from '../components/reports/productivity/SpecificProductivityOptions';
import EfficiencyBarChart from '../components/reports/productivity/EfficiencyBarChart'
import DropdownField from '../components/common/DropdownField';
import { PRODUCTIVITY, EUCALYPTUS } from "../components/reports/productivity/common/defaultValues"

import IconButton from '@material-ui/core/IconButton';

import { generateSpecificEucalyptusProductivity, setProductivityUndefined, setChangedCadFlag } from '../actions/productivity';
import { toggleReportContainer, setReportTypes } from '../actions/reports';
import { toggleTooltips } from '../actions/global';
import { activePointChange } from '../shared/utils/ProductivityAccess'

const INITIAL_SPECIFIC_STATE = {
  
  eficiency: PRODUCTIVITY.EFFICIENCY,
  range: EUCALYPTUS.RANGE,
  cycle: EUCALYPTUS.CYCLE,
  clone: EUCALYPTUS.CLONE,
  unity: EUCALYPTUS.UNITY,
  bark: EUCALYPTUS.BARK,
  tree_bark: '',
  plantingYear: (EUCALYPTUS.DATE).slice(0,4),
  date: null,
  obs_value: '',
}



class SpecificEucalyptusProductivityContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openOptions: true,
      specific: this.setInitialState(),
      selectedProd: 'PP',
      monthlyCropData: [],
      compareData: [],
      boxplotData: [],
      currentCrop: '',
      lastCropValues: [],
      lastCrop: '',
      selectedFarm: undefined,
      data: undefined,
      currentSeasonAverageData: undefined,
      lastSeasonAverageData: undefined,
      openReport: true,
      errors: {}
    };
  };

  //State initializer method
  setInitialState(){
    return INITIAL_SPECIFIC_STATE
  }

  //#region Lifecycle methods

  componentDidMount(){
    const state = Object.assign({}, this.state);
    if (this.props.farms && this.props.activeFarm) {
      const selectedFarm = this.props.farms.find(farm => farm.id === this.props.activeFarm);
      state.selectedFarm = selectedFarm;
    }
    this.setState(state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const state = Object.assign({}, this.state);

    if (nextProps.productivity && nextProps.productivity.data) {
      state.data = nextProps.productivity.data;
      let key = Object.keys(state.data)[0]
      if(state.data[key]['pp_value'].length > 1){
        //boxplot
        let boxReturn = this.generateBoxplotData(nextProps.productivity.data)
        state.boxplotData = boxReturn[0]
        state.currentCrop = boxReturn[1]
      }else{
        state.monthlyCropData = this.generateMonthlyCropData(nextProps.productivity.data)
        state.compareData = this.generateCompareData(nextProps.productivity.data)
      }
    }

    if (nextProps.farms && nextProps.activeFarm) {
      const selectedFarm = nextProps.farms.find(farm => farm.id === nextProps.activeFarm);
      if(this.state.selectedFarm && this.state.selectedFarm.id !== nextProps.activeFarm){
        state.specific = this.setInitialState()
        state.openOptions = true
        this.props.actions.setProductivityUndefined();
        if(!this.props.reportActive){
          this.props.actions.toggleReportContainer();
        }
      }
      state.selectedFarm = selectedFarm;
    }
    this.setState(state);
  }

  componentDidUpdate(prevProps, prevState) {
    activePointChange(prevProps, this.props, this.props.reportType)
    let newState = {}
    if(this.state.data !== undefined && this.props.productivity.data === undefined){
      newState.data = undefined
      newState.compareData = []
      newState.monthlyCropData = []
      newState.boxplotData = []
      newState.openReport = true
      if(this.props.reportType !== prevProps.reportType){
        newState.openOptions = true
        newState.specific = this.setInitialState()
      } else if (this.props.changedCadFlag){
        newState.openOptions = true;
        this.props.actions.setChangedCadFlag(false)
      }
      this.setState(newState);
    }

    if(prevProps.reportActive && !this.props.reportActive){
      this.setState({openReport: false})
    }
  }
  //#endregion

  //#region Render methods
  recalculate = (e) => {
    let { errors, isValid } = validateInput(this.state.specific, this.props.reportType)
    if(!isValid){
      this.setState({errors: errors})
      return
    }

    const coords = this.state.selectedFarm.coords.split(',');
    const { tree_bark, bark, clone, range, cycle } = this.state.specific
    const agriculturalEficiency = this.state.specific.eficiency !== '' ? parseFloat(this.state.specific.eficiency) / 100 : '';
    const date = moment(this.state.specific.date).format('YYYY-MM-DD')
    const point_id = this.props.activeFarm
    const crop_year = `${parseInt(date.slice(0,4)) + parseInt(cycle)}`
    const unity = this.state.specific.unit
    

    let data = {}
    
      data = {
        cad: this.state.selectedFarm.cad,
        lat: coords[0],
        lon: coords[1],
        agriculturalEficiency,
        range,
        cycle,
        date,
        clone,
        bark,
        tree_bark,
        unity,
        crop_year,
        point_id
      };

      this.setState({openOptions: false}, () => {
        this.props.actions.setProductivityUndefined();
        this.props.actions.generateSpecificEucalyptusProductivity(data, "specific");
      })

  }

  generateMonthlyCropData = (data) => {
    let key = Object.keys(data)[0]
    const { productivity } = this.props;
    let unit = this.state.specific.unity
    let cycle = 1
    if(unit === 'm³/ha/ano (IMA)') {cycle = productivity.cycle}

    let pp = parseFloat((data[key]['pp_value'][0]/cycle).toFixed(2));
    let ap = parseFloat((data[key]['ap_value'][0]/cycle).toFixed(2));
    let rpEstimate = parseFloat((data[key]['rp_value'][0]/cycle).toFixed(2));
    let rpObserved = this.state.specific.obs_value !== '' ? parseFloat(this.state.specific.obs_value) : undefined;

    if(rpObserved){
      return [
        {name: 'PP', pp},
        {name: 'PA', ap},
        {name: 'PR_Est', rpEstimate},
        {name: 'PR_Obs', rpObserved},
      ]
    }else{
      return [
        {name: 'PP', pp},
        {name: 'PA', ap},
        {name: 'PR_Est', rpEstimate},
      ]
    }
  }

  generateCompareData = (data) => {
    let key = Object.keys(data)[0]
    const { productivity } = this.props;
    let unit = this.state.specific.unity
    let cycle = 1
    if(unit === 'm³/ha/ano (IMA)') {cycle = productivity.cycle}

    let ygdh = parseFloat(((parseFloat(data[key]['pp_value'][0]) - parseFloat(data[key]['ap_value'][0]))/cycle).toFixed(2));
    let ygmanEstimate = parseFloat(((parseFloat(data[key]['ap_value'][0]) - parseFloat(data[key]['rp_value'][0]))/cycle).toFixed(2));
    let ygmanObserved = this.state.specific.obs_value !== '' ? parseFloat(((data[key]['ap_value'][0]/cycle) - this.state.specific.obs_value).toFixed(2)) : undefined;

    if(ygmanObserved){
      return [
        {name: 'YGdh', ygdh},
        {name: 'YGman_Est', ygmanEstimate},
        {name: 'YGman_Obs', ygmanObserved},
      ]
    }else{
      return [
        {name: 'YGdh', ygdh},
        {name: 'YGman_Est', ygmanEstimate},
      ]
    }
  }

  generateBoxplotData = (data) => {
    let key = Object.keys(data)[0]
    let valuesCurrent = []
    let insideKeys = Object.keys(data[key])

    insideKeys.forEach(insideKey => {
      let auxValues = []
      if(['pp_value', 'ap_value', 'rp_value'].includes(insideKey)){
        data[key][insideKey].forEach(value => {
          auxValues.push(parseFloat(parseFloat(value).toFixed(2)))
        })
        valuesCurrent.push(auxValues)
      }
    })


    return [valuesCurrent, data[key]['crop']]
  }

  toggleActive = () => {
    this.setState({openReport: !this.state.openReport})
  }

  handleOptionsModal = () => {
    this.setState({openOptions: !this.state.openOptions})
  }

  onChangeProd = (event) => { this.setState({selectedProd: event.target.value}) }

  handleChange = (event) => {
    if(event.target.name === "frost" || event.target.name === "bark") {
      this.setState({specific: {...this.state.specific, [event.target.name]: !this.state.specific.frost }})
    }
    else {
      this.setState({specific: {...this.state.specific, [event.target.name]: event.target.value}})
    }
  }

  handleDateChange = (event) => {
    this.setState({specific: {...this.state.specific, date: event, obs_value: ""}})
  }

  handleCloseOptionsModal = () => {
    this.setState({openOptions: false})
    if(!this.state.data){
      window.location.reload()
    }
  }

  setTitles = () => {
    const { productivity } = this.props;
    let titleLine1 = "";
    if(this.state.selectedFarm && this.state.data){
      const farmName = this.state.selectedFarm.name !== "" ? this.state.selectedFarm.name : `Ponto ${this.state.selectedFarm.initials}`;
      const eficiency = productivity.agriculturalEficiency < 1 ? Math.round(productivity.agriculturalEficiency * 100) : productivity.agriculturalEficiency;
        titleLine1 = `${farmName} | Ano de plantio: ${(moment(this.state.specific.date).format('YYYY-MM-DD').slice(0,4))} | CAD = ${productivity.cad} mm | E.F. = ${eficiency}% | Ciclo = ${productivity.cycle} Anos`
     
    }

    return [titleLine1]
  }

  handlePDFClick = (e) => {
    e.preventDefault()
    const input = document.getElementById('capture-pdf');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0, 200, 120);
      pdf.save("download.pdf");
    })
  }
  //#endregion

  render() {
    const { productivity } = this.props;
    let unit = this.state.specific.unity
    let cycle = 1
    if(unit === 'm³/ha/ano (IMA)') {cycle = productivity.cycle}
    let title = this.setTitles()
    let cenaries = []
    if(this.state.boxplotData.length > 0){
      let position = this.state.selectedProd === 'PP' ? 0 : this.state.selectedProd === 'PA' ? 1 : 2
      cenaries = [
        {name: 'Muito Desfavorável', md:  parseFloat(this.state.boxplotData[position][0] / cycle).toFixed(2)},
        {name: 'Desfavorável', d:  parseFloat(this.state.boxplotData[position][1] / cycle).toFixed(2)},
        {name: 'Mediano', me:  parseFloat(this.state.boxplotData[position][2] / cycle).toFixed(2)},
        {name: 'Favorável', fa:  parseFloat(this.state.boxplotData[position][3] / cycle).toFixed(2)},
        {name: 'Muito Favorável', mf:  parseFloat(this.state.boxplotData[position][4] / cycle).toFixed(2)},
      ]
    }

    let csvHeaders = undefined
    let csvData = []
    let efficiency = []
    if(this.state.monthlyCropData.length > 0){
      csvData = [
        ['PP', this.state.monthlyCropData[0].pp],
        ['PA', this.state.monthlyCropData[1].ap],
        ['PR_Est', this.state.monthlyCropData[2].rpEstimate],
        ['PR_Obs', this.state.monthlyCropData[3] ? this.state.monthlyCropData[3].rpObserved: ''],

        ['YGdh', this.state.compareData[0].ygdh],
        ['YGman_Est', this.state.compareData[1].ygmanEstimate],
        ['YGman_Obs', this.state.compareData[2] ? this.state.compareData[2].ygmanObserved : '']
      ]
      efficiency = [
        {name: "Eficiência Climática", ce: this.state.monthlyCropData[1].ap / this.state.monthlyCropData[0].pp * 100},
        {name: "Eficiência Florestal", ae: this.props.productivity.agriculturalEficiency * 100}
      ]
      if(this.state.monthlyCropData[3]) {
        efficiency.push({name: "Eficiência Florestal Real", rae: (this.state.monthlyCropData[3].rpObserved / this.state.monthlyCropData[1].ap) * 100})
      }
    }else if(this.state.boxplotData.length > 0){
      csvData = [
        ['PP_Cen1', this.state.boxplotData[0][0]],
        ['PP_Cen2', this.state.boxplotData[0][1]],
        ['PP_Cen3', this.state.boxplotData[0][2]],
        ['PP_Cen4', this.state.boxplotData[0][3]],
        ['PP_Cen5', this.state.boxplotData[0][4]],
        ['', ''],
        ['PA_Cen1', this.state.boxplotData[1][0]],
        ['PA_Cen2', this.state.boxplotData[1][1]],
        ['PA_Cen3', this.state.boxplotData[1][2]],
        ['PA_Cen4', this.state.boxplotData[1][3]],
        ['PA_Cen5', this.state.boxplotData[1][4]],
        ['',''],
        ['PR_Cen1', this.state.boxplotData[2][0]],
        ['PR_Cen2', this.state.boxplotData[2][1]],
        ['PR_Cen3', this.state.boxplotData[2][2]],
        ['PR_Cen4', this.state.boxplotData[2][3]],
        ['PR_Cen5', this.state.boxplotData[2][4]],
      ]
    }

    return (
      <Fragment>
        <section className={classnames("report--container visible productivity", {active: this.state.openReport})} style={{zIndex: "8"}}>
          <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.state.openReport})} onClick={ this.toggleActive }>
            <i className='material-icons'>play_arrow</i>
          </span>
          <header className='row bhbox-header productivity-header' style={{padding: '6px 8px'}}>
            <div className="prod-options-container" style={{width: '100%'}}>
              {this.state.data &&
                <Fragment>
                  {this.props.reportType === 'eucalyptus-spec-productivity' &&
                      <Fragment>
                        <DownloadXLS
                          marginRight={'32px'}
                          headers={csvHeaders}
                          data={csvData}
                        />
                        <IconButton disableRipple={true} aria-label="picture_as_pdf" onClick={this.handlePDFClick}>
                          <i className="material-icons">picture_as_pdf</i>
                        </IconButton>
                      </Fragment>
                  }
                  <IconButton disableRipple={true} aria-label="tooltips" onClick={this.props.actions.toggleTooltips} title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'}>
                    <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                  </IconButton>
                  <IconButton disableRipple={true} aria-label="settings" onClick={this.handleOptionsModal}>
                    <i className="material-icons">settings</i>
                  </IconButton>
                </Fragment>
              }
            </div>
          </header>
          <PerfectScrollbar className={classnames('productivity-scroll ps ps--theme default')}>
            {this.state.data && this.props.farms && this.props.activeFarm ?
              <div id='capture-pdf' className="general-graph" style={{textAlign: 'center', height: '90%'}}>
                {this.props.reportType === 'eucalyptus-spec-productivity' &&
                  <Fragment>
                    <h5>{title[0]}</h5>
                  </Fragment>
                }
                <Grid container>
                  <Grid item xs={6}>
                    {this.state.monthlyCropData.length > 0 ?
                      <ObservedSpecificProductivityChart
                        className='left-chart'
                        type={'brute'}
                        data={this.state.monthlyCropData}
                        unit={unit}
                        unitText={`Produtividade ${unit}`}
                        showTooltips={this.props.showTooltips}
                      />
                      :
                        null
                      }
                    {this.state.boxplotData.length > 0 ?
                      <SpecificProductivityBoxplot
                        className='left-chart'
                        title={''}
                        keys={['PP', 'PA', 'PRe']}
                        unitText={`Produtividade ${unit}`}
                        unit={unit}
                        cycle={cycle}
                        currentCrop={this.state.currentCrop}
                        currentCropBoxplotValues={this.state.boxplotData}
                        showTooltips={this.props.showTooltips}
                        reportType={'eucalyptus-spec-productivity'}
                      />
                      :
                      null
                    }
                  </Grid>
                  <Grid item className='right-chart' xs={6}>
                    {this.state.compareData.length > 0 ?
                      <ObservedSpecificProductivityChart
                        type={'compare'}
                        data={this.state.compareData}
                        unit={unit}
                        unitText={`Produtividade ${unit}`}
                        showTooltips={this.props.showTooltips}
                      />
                    :
                      null
                    }
                    {this.state.boxplotData.length > 0 ?
                      <React.Fragment>
                        <div className="subtitle">
                          <DropdownField
                            value={this.state.selectedProd}
                            options={['PP', 'PA', 'PRe']}
                            onChange={this.onChangeProd}
                            type={'prod'}
                          />
                        </div>
                        <ObservedSpecificProductivityChart
                          type={'compareBox'}
                          data={cenaries} unit={unit}
                          unitText={`Produtividade ${unit}`}
                          showTooltips={this.props.showTooltips}
                        />
                      </React.Fragment>
                      :
                      null
                    }
                  </Grid>
                  {this.state.monthlyCropData.length > 0 &&
                    <Grid item container justify="center">
                      <Grid item xs={12} lg={6}>
                        <EfficiencyBarChart
                          data={efficiency}
                          showTooltips={this.props.showTooltips}
                          reportType={'eucalyptus-spec-productivity'}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </div>
              :
              <div className='loading-screen-prod'>
                Calculando...
              </div>
            }

          </PerfectScrollbar>
        </section>
        <SpecificProductivityOptions
          handleDateChange={this.handleDateChange}
          reportType={this.props.reportType}
          noClose={this.state.data ? false : true}
          recalculate={this.recalculate}
          open={this.state.openOptions}
          productivity={this.state.specific}
          handleChange={this.handleChange}
          handleClose={this.handleCloseOptionsModal}
          errors={this.state.errors}
        />
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms.points.farms,
    activeFarm: state.farms.activeFarm,
    productivity: state.productivity,
    reportActive: state.Reports.reportActive,
    reportType: state.Reports.reportTypes,
    changedCadFlag: state.productivity.changedCadFlag,
    showTooltips: state.global.showTooltips
  }
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {
      generateSpecificEucalyptusProductivity,
      setProductivityUndefined,
      toggleReportContainer,
      setReportTypes,
      setChangedCadFlag,
      toggleTooltips,
    }), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecificEucalyptusProductivityContainer);
