import { authHeader } from './config'

class CadApi {
    static getCad(soil){
        return fetch(process.env.REACT_APP_API + `api/cad?soil_texture=${soil.texture}&soil_depth=${soil.depth}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }
}

export default CadApi