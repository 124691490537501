import React, { Component } from "react";
import classnames from "classnames";

import FarmForm from "./FarmForm";
import ProfileBar from "./ProfileBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import validateInput from "../shared/validators/farmForm";
import { showToaster } from "../components/common/Toaster";

//utils
import {
  numberToMonth,
  monthToNumber,
  setDates,
  setDecendialNumber,
} from "../shared/utils/DatesUtils";

import "../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";
import { toggleLeftLoader } from "../actions/global";

class SidebarLoader extends Component {
  render() {
    if (this.props.active === true) {
      return (
        <div className="overlay">
          <div className="loader"></div>
        </div>
      );
    } else {
      return null;
    }
  }
}

class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      coords: "",
      cad: "0",
      errors: {},
      initials: "",
      nameAux: "",
      coordsAux: "",
      soilAux: "",
      cadAux: "0",
      initialsAux: "",
      file: "",
      isLoading: false,
      reportTypes: [],
      updatePending: false,
    };
  }

  //#region Lifecycle methods
  componentDidMount() {
    if (![-2, -1].includes(this.props.activeFarm)) {
      this.selectFarm(this.props.activeFarm);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let oldActiveFarm = this.props.activeFarm;
    let newActiveFarm = nextProps.activeFarm;

    const newFarmData = nextProps.farms.farms.filter(
      (farm) => farm.id === newActiveFarm
    )[0];
    const oldFarmData = this.props.farms.farms.filter(
      (farm) => farm.id === newActiveFarm
    )[0];

    if (this.props.user["type"] !== "bank") {
      if (newActiveFarm === "") {
        this.clearForm();
      } else if (newActiveFarm !== oldActiveFarm && newActiveFarm !== -1) {
        this.selectFarm(newActiveFarm);
      } else if (
        nextProps.activeFarm === -1 &&
        nextProps.selectedGridPointName !== ""
      ) {
        if (nextProps.selectedGridPointCoords["lat"] === "") {
          let point = this.props.xavierPoints.filter((point) => {
            if (
              point["name"] === nextProps.selectedGridPointName.split("-")[0]
            ) {
              return true;
            } else {
              return false;
            }
          });
          this.setState({
            name: nextProps.selectedGridPointName.split("-")[0],
            initials: nextProps.selectedGridPointName.split("-")[1],
            coords: point[0]["lat"] + "," + point[0]["lng"],
          });
        } else {
          this.setState({
            name: nextProps.selectedGridPointName.split("-")[0],
            initials: nextProps.selectedGridPointName.split("-")[1],
            coords:
              nextProps.selectedGridPointCoords["lat"] +
              "," +
              nextProps.selectedGridPointCoords["lng"],
          });
        }
      } else if (
        (this.props.editMode === "" && nextProps.editMode !== "") ||
        (this.props.editMode !== "" && nextProps.editMode === "")
      ) {
        this.selectFarm(newActiveFarm, nextProps.farms["farms"]);
      } else if (JSON.stringify(oldFarmData) !== JSON.stringify(newFarmData)) {
        //in case the point was edited
        this.props.setEditMode("");
        this.selectFarm(newActiveFarm, nextProps.farms["farms"]);
      }
    } else {
      this.setState({
        name: nextProps.selectedGridPointName.split("-")[0],
        initials: nextProps.selectedGridPointName.split("-")[1],
        coords:
          nextProps.selectedGridPointCoords["lat"] +
          "," +
          nextProps.selectedGridPointCoords["lng"],
      });
    }

    if (nextProps.editCoords !== "") {
      this.setState({
        coords: nextProps.editCoords,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cad === "" && prevProps.cadError && this.state.cad !== "") {
      this.props.setCadError();
    }
    if (prevProps.activeCad !== this.props.activeCad) {
      this.setState({ cad: this.props.activeCad });
    }
  }
  //#endregion

  //#region Helper methods
  setCorrectTypeAndRange(
    reportType,
    currentReportType,
    currentType,
    currentRange
  ) {
    let weather = ["temperatura", "prec", "rs", "gd", "u2", "ur", "etp"];
    let bhbox = ["bhbox", "excdef", "extbh", "arm", "etretp", "exc", "def"];
    let index = ["plan", "herb", "ps", "fert", "traf", "cresc", "favo"];
    let accumulate = ["prec", "rs", "gd", "etp"];
    let typeAndRange = [];
    if (currentType === undefined || currentRange === undefined) {
      typeAndRange = ["period", "daily"];
    } else {
      if (
        (weather.includes(reportType) && bhbox.includes(currentReportType)) ||
        (weather.includes(currentReportType) && bhbox.includes(reportType))
      ) {
        typeAndRange = ["period", "daily"];
        this.props.toggleCorrectTypesFlag();
      } else {
        if (weather.includes(reportType)) {
          if (
            accumulate.includes(currentReportType) &&
            !accumulate.includes(reportType)
          ) {
            if (currentType === "accumulate") {
              typeAndRange = ["period", "daily"];
            } else {
              typeAndRange = [currentType, currentRange];
            }
          } else {
            typeAndRange = [currentType, currentRange];
          }
        } else {
          typeAndRange = ["period", "daily"];
        }
      }
    }

    if (reportType === "excdef") {
      typeAndRange = ["cmp", "decendial"];
    } else if (["exc", "def"].includes(reportType) && currentRange) {
      typeAndRange = [currentType, currentRange];
    } else if (reportType === "arm") {
      typeAndRange = ["cmp", "daily"];
    } else if (index.includes(reportType)) {
      typeAndRange = ["period", "daily"];
    } else if (reportType === "atrmax") {
      typeAndRange = ["cmp", "daily"];
    }
    return typeAndRange;
  }

  maskLatLon(value, oldValue) {
    let erasing = value.length < oldValue.length;
    let subStrings = undefined;
    if (!erasing) {
      if (value.charAt(0) === "-") {
        if (value.length === 4 && !value.includes(".")) {
          return (
            value.charAt(0) +
            value.charAt(1) +
            value.charAt(2) +
            "." +
            value.charAt(3)
          );
        } else if (value.length > 4) {
          subStrings = value.split(".");
          if (subStrings[0].length > 3 || subStrings[1].length > 3) {
            return oldValue;
          }
        }
      } else {
        if (value.length === 3 && !value.includes(".")) {
          return value.charAt(0) + value.charAt(1) + "." + value.charAt(2);
        } else if (value.length > 3) {
          subStrings = value.split(".");
          if (subStrings[0].length > 2 || subStrings[1].length > 3) {
            return oldValue;
          }
        }
      }
    } else {
      return value;
    }

    return value;
  }
  //#endregion

  //#region Render methods

  toggleActive = () => {
    this.props.toggleSidebar();
    if (this.props.moreFarmsActive) {
      this.props.toggleMoreFarmsActive();
    }
  };

  onSubmit = async (e, editingCad = undefined) => {
    this.props.changeVisualizationYear('civil');

    if (this.props.weatherRange) {
      let startDate;
      let endDate;
      
      switch (this.props.weatherRange) {
        case 'daily':
          startDate = '01/01';
          endDate = '31/12';
          break;
        case 'decendial':
          startDate = 'jan_1';
          endDate = 'dez_3';
          break;
        case 'monthly':
          startDate = 'jan';
          endDate = 'dez';
          break;
        default:
          startDate = '01/01';
          endDate = '31/12';
          break;
      }

      this.props.changeFilterParams({
        fromPeriod: '01/01',
        toPeriod: '31/12',
        fromIndex: 1,
        toIndex: 366,
        startDate,
        endDate,
      });
    }

    let type = [];
    let reportType = [];

    if (e.target !== undefined) {
      e.preventDefault();
      type.push(e.target.value);
    } else {
      type.push(e);
    }

    let agrytools = ["herb", "plan", "ps", "fert", "traf", "cresc", "favo"];

    if (e.target.value === "favo") {
      this.props.toggleReportUndefined();

      this.props.setLastUsedSoil(
        agrytools.includes(type[0])
          ? "sandyAverageTexture"
          : this.state.soil_texture
      );
      this.props.setReportTypes(e.target.value);
      this.toggleActive();
      if (this.props.reportActive !== true) {
        this.props.toggleReportContainer();
      }
      let cad = this.props.cad;
      let farmAttrs = {
        coords: this.state.coords,
        farmId: this.props.activeFarm,
        cad,
        year: new Date().getFullYear(),
      };
      this.props.generatingMapData();
      this.props.resetGrid();
      this.props.generateReport("favo", farmAttrs);

      return;
    }

    let typeAndRange = this.setCorrectTypeAndRange(
      type[0],
      this.props.reportTypes[0],
      this.props.weatherType,
      this.props.weatherRange
    );

    let cad = this.props.cad;

    if (editingCad) {
      cad = editingCad;
    }

    let farmAttrs = {
      name: this.state.name,
      coords: this.state.coords,
      farmId: this.props.activeFarm,
      cad,
      initials: this.state.initials,
      type: typeAndRange[0],
      range: typeAndRange[1],
      data_files: this.state.file,
      initial_date: "1980",
      final_date: (new Date().getFullYear() + 1).toString(),
      tb: this.props.tBasal,
      cultivate_type: "early",
      visualization_type: 'civil',
      min_prec: "0",
    };

    if (
      (type[0] === "bhbox" ||
        type[0] === "extbh" ||
        type[0] === "etretp" ||
        type[0] === "excdef" ||
        type[0] === "arm" ||
        type[0] === "fmaac") &&
      this.state.cad === "0"
    ) {
      const toasterParams = {
        title: "Erro!",
        message: "Insira um valor para a CAD.",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);

      if (!this.props.cadError) {
        this.props.setCadError();
      }
    } else {
      let dayAndMonth = undefined;
      let year = undefined;
      let initial_date = "";
      let final_date = "";
      let variable =
        type[0] === "temperatura"
          ? "tmean"
          : type[0] === "excdef"
          ? "def"
          : type[0].toString();
      let dates = undefined;
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let currentDay = today.getDate().toString();
      let currentMonth = (today.getMonth() + 1).toString();
      if (currentDay < 10) {
        currentDay = "0" + currentDay;
      }

      if (currentMonth < 10) {
        currentMonth = "0" + currentMonth;
      }

      let day = {
        label: currentDay + "/" + currentMonth,
        year: new Date().getFullYear().toString(),
        variable: variable,
      };
      if (this.props.activeDate["label"] === "") {
        dayAndMonth = [currentDay, currentMonth];
        year = new Date().getFullYear().toString();

        if (type[0] === "excdef") {
          let dec = setDecendialNumber(parseInt(currentDay, 10));
          let excdefDates = setDates(currentMonth, dec, year);
          initial_date = excdefDates[0];
          final_date = excdefDates[1];

          day["label"] = numberToMonth(currentMonth.toString()) + "_" + dec;
        } else {
          initial_date =
            year.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
          final_date =
            year.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
        }
      } else {
        if (typeAndRange[1] === "daily") {
          dayAndMonth = this.props.activeDate["label"].split("/");
          if (dayAndMonth.length < 2) {
            if (
              this.props.mapYear.toString() !==
              new Date().getFullYear().toString()
            ) {
              dayAndMonth[0] = agrytools.includes(type[0])
                ? dayAndMonth[0]
                : "31";
              dayAndMonth[1] = agrytools.includes(type[0])
                ? dayAndMonth[1]
                : "12";
              day["label"] = dayAndMonth[0] + "/" + dayAndMonth[1];
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            } else {
              //GET CURRENT DAY AND MONTH
              dayAndMonth[0] = currentDay;
              dayAndMonth[1] = currentMonth;
              day["label"] = dayAndMonth[0] + "/" + dayAndMonth[1];
              day["year"] = new Date().getFullYear().toString();
              day["variable"] = variable;
            }
          } else {
            day["label"] = dayAndMonth[0] + "/" + dayAndMonth[1];
            day["year"] = this.props.mapYear.toString();
            day["variable"] = variable;
          }
          initial_date =
            this.props.mapYear.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
          final_date =
            this.props.mapYear.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
        } else if (typeAndRange[1] === "decendial") {
          dayAndMonth = this.props.activeDate["label"].split("_");
          if (dayAndMonth.length < 2) {
            if (
              this.props.mapYear.toString() !==
              new Date().getFullYear().toString()
            ) {
              dayAndMonth[0] = "dez";
              dayAndMonth[1] = "3";
              day["label"] = dayAndMonth[0] + "_" + dayAndMonth[1];
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            } else {
              let today = new Date();
              let currentDay = today.getDate();

              let currentDecendial = setDecendialNumber(currentDay);

              dayAndMonth[0] = numberToMonth(currentMonth);
              dayAndMonth[1] = currentDecendial;
              day["label"] = dayAndMonth[0] + "_" + dayAndMonth[1];
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            }
          } else {
            day["label"] = dayAndMonth[0] + "_" + dayAndMonth[1];
            day["year"] = this.props.mapYear.toString();
            day["variable"] = variable;
          }
          dates = setDates(
            monthToNumber(dayAndMonth[0]),
            dayAndMonth[1],
            this.props.mapYear.toString()
          );
          initial_date = dates[0];
          final_date = dates[1];
        } else if (typeAndRange[1] === "monthly") {
          dayAndMonth = this.props.activeDate["label"];
          if (dayAndMonth.includes("/") || dayAndMonth.includes("_")) {
            if (
              this.props.mapYear.toString() !==
              new Date().getFullYear().toString()
            ) {
              dayAndMonth = "12";
              day["label"] = dayAndMonth;
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            } else {
              //GET CURRENT MONTH
              dayAndMonth = currentMonth;
              day["label"] = dayAndMonth;
              day["year"] = new Date().getFullYear().toString();
              day["variable"] = variable;
            }
          } else {
            day["label"] = dayAndMonth;
            day["year"] = this.props.mapYear.toString();
            day["variable"] = variable;
          }
          dates = setDates(
            monthToNumber(dayAndMonth),
            "4",
            this.props.mapYear.toString()
          );
          initial_date = dates[0];
          final_date = dates[1];
        } else if (typeAndRange[1] === "annualy") {
          dates = setDates(dayAndMonth, "5", this.props.mapYear.toString());
          initial_date = dates[0];
          final_date = dates[1];
        }
      }
      let mapAttrs = {
        initial_date: initial_date,
        final_date: final_date,
        type: typeAndRange[0],
        range: typeAndRange[1],
        cad: this.props.cad,
        tb: this.props.tBasal,
        soil_texture: this.state.soil_texture,
        soil_depth: this.state.soil_depth,
        visualization_type: 'civil',
      };

      if (variable === "extbh") {
        variable = "excdef";
        day["variable"] = "excdef";
      }

      if (
        ["etretp", "atrmax", "heat_wave", "frost_risk", "rainy_days"].includes(
          type[0]
        )
      ) {
        this.props.setMapDay(day);
        this.props.resetGrid();
      } else {
        this.props.setMapDay(day);
        this.props.generatingMapData();
        this.props.toggleLeftLoader();
        this.props.generateMapData(variable, mapAttrs);
      }

      if (type[0] === "extbh") {
        reportType.push("bhbox");
      } else {
        reportType.push(type[0]);
      }

      this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
      this.props.setReportTypes(type);
      this.props.toggleReportUndefined();
      this.props.generateReport(reportType, farmAttrs);
      this.props.setLastUsedCad(farmAttrs.cad);
      this.toggleActive();
      if (this.props.reportActive !== true) {
        this.props.toggleReportContainer();
      }
    }
  };

  onSubmitBhbox = (e, editingCad = undefined) => {
    this.props.toggleLeftLoader();
    let type = [];
    let reportType = [];
    if (e.target !== undefined) {
      e.preventDefault();
      type.push(e.target.value);
    } else {
      type.push(e);
    }
    let typeAndRange = this.setCorrectTypeAndRange(
      type[0],
      this.props.reportTypes[0],
      this.props.weatherType,
      this.props.weatherRange
    );
    let cad = this.state.cad;

    if (this.props.lastVisualizedCad !== "0") {
      cad = this.props.lastVisualizedCad;
    } else if (this.props.lastUsedCad !== "0") {
      cad = this.props.lastUsedCad;
    }

    if (editingCad) {
      cad = editingCad;
    }

    let farmAttrs = {
      name: this.state.name,
      coords: this.state.coords,
      farmId: this.props.activeFarm,
      cad,
      initials: this.state.initials,
      type: typeAndRange[0],
      range: typeAndRange[1],
      initial_date: "1980",
      final_date: (new Date().getFullYear() + 1).toString(),
      tb: undefined,
      visualization_type: 'civil',
    };

    if (this.state.cad === "0") {
      const toasterParams = {
        title: "Erro!",
        message: "Insira um valor para a CAD.",
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);

      if (!this.props.cadError) {
        this.props.setCadError();
      }
    } else {
      let dayAndMonth = undefined;
      let year = undefined;
      let initial_date = "";
      let final_date = "";
      let variable =
        type[0] === "temperatura"
          ? "tmean"
          : type[0] === "excdef"
          ? "def"
          : type[0].toString();
      let dates = undefined;
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let currentDay = today.getDate().toString();
      let currentMonth = (today.getMonth() + 1).toString();
      if (currentDay < 10) {
        currentDay = "0" + currentDay;
      }

      if (currentMonth < 10) {
        currentMonth = "0" + currentMonth;
      }

      let day = {
        label: currentDay + "/" + currentMonth,
        year: new Date().getFullYear().toString(),
        variable: variable,
      };
      if (this.props.activeDate["label"] === "") {
        dayAndMonth = [currentDay, currentMonth];
        year = new Date().getFullYear().toString();

        if (type[0] === "excdef") {
          let dec = setDecendialNumber(parseInt(currentDay, 10));
          let excdefDates = setDates(currentMonth, dec, year);
          initial_date = excdefDates[0];
          final_date = excdefDates[1];

          day["label"] = numberToMonth(currentMonth.toString()) + "_" + dec;
        } else {
          initial_date =
            year.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
          final_date =
            year.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
        }
      } else {
        if (typeAndRange[1] === "daily") {
          dayAndMonth = this.props.activeDate["label"].split("/");
          if (dayAndMonth.length < 2) {
            //GET CURRENT DAY AND MONTH
            dayAndMonth[0] = currentDay;
            dayAndMonth[1] = currentMonth;
            day["label"] = dayAndMonth[0] + "/" + dayAndMonth[1];
            day["year"] = new Date().getFullYear().toString();
            day["variable"] = variable;
          } else {
            day["label"] = dayAndMonth[0] + "/" + dayAndMonth[1];
            day["year"] = this.props.mapYear.toString();
            day["variable"] = variable;
          }
          initial_date =
            this.props.mapYear.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
          final_date =
            this.props.mapYear.toString() +
            "-" +
            dayAndMonth[1].toString() +
            "-" +
            dayAndMonth[0].toString();
        } else if (typeAndRange[1] === "decendial") {
          dayAndMonth = this.props.activeDate["label"].split("_");
          if (dayAndMonth.length < 2) {
            if (
              this.props.mapYear.toString() !==
              new Date().getFullYear().toString()
            ) {
              dayAndMonth[0] = "dez";
              dayAndMonth[1] = "3";
              day["label"] = dayAndMonth[0] + "_" + dayAndMonth[1];
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            } else {
              let today = new Date();
              let currentDay = today.getDate();

              let currentDecendial = setDecendialNumber(currentDay);

              dayAndMonth[0] = numberToMonth(currentMonth);
              dayAndMonth[1] = currentDecendial;
              day["label"] = dayAndMonth[0] + "_" + dayAndMonth[1];
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            }
          } else {
            day["label"] = dayAndMonth[0] + "_" + dayAndMonth[1];
            day["year"] = this.props.mapYear.toString();
            day["variable"] = variable;
          }
          dates = setDates(
            monthToNumber(dayAndMonth[0]),
            dayAndMonth[1],
            this.props.mapYear.toString()
          );
          initial_date = dates[0];
          final_date = dates[1];
        } else if (typeAndRange[1] === "monthly") {
          dayAndMonth = this.props.activeDate["label"];
          if (dayAndMonth.includes("/") || dayAndMonth.includes("_")) {
            if (
              this.props.mapYear.toString() !==
              new Date().getFullYear().toString()
            ) {
              dayAndMonth = "12";
              day["label"] = dayAndMonth;
              day["year"] = this.props.mapYear.toString();
              day["variable"] = variable;
            } else {
              //GET CURRENT MONTH
              dayAndMonth = currentMonth;
              day["label"] = dayAndMonth;
              day["year"] = new Date().getFullYear().toString();
              day["variable"] = variable;
            }
          } else {
            day["label"] = dayAndMonth;
            day["year"] = this.props.mapYear.toString();
            day["variable"] = variable;
          }
          dates = setDates(
            monthToNumber(dayAndMonth),
            "4",
            this.props.mapYear.toString()
          );
          initial_date = dates[0];
          final_date = dates[1];
        } else if (typeAndRange[1] === "annualy") {
          dates = setDates(dayAndMonth, "5", this.props.mapYear.toString());
          initial_date = dates[0];
          final_date = dates[1];
        }
      }

      let mapAttrs = {
        initial_date: initial_date,
        final_date: final_date,
        type: typeAndRange[0],
        range: typeAndRange[1],
        cad:
          type[0] === "plan"
            ? 25
            : type[0] === "cresc"
            ? this.state.cad
            : this.state.cad,
        tb: this.props.tBasal,
        soil_texture: this.state.soil_texture,
        soil_depth: this.state.soil_depth,
        visualization_type: 'civil',
      };

      reportType.push(type[0]);

      if (['exc', 'def'].includes(reportType[0])) {
        farmAttrs.type = 'period';
        mapAttrs.type = 'period';
      }

      this.props.setMapDay(day);
      this.props.generatingMapData();
      this.props.generateMapData(variable, mapAttrs);

      this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
      this.props.setReportTypes(type);
      this.props.toggleReportUndefined();
      this.props.generateReport(reportType, farmAttrs);
      this.props.setLastUsedCad(farmAttrs.cad);
      this.props.setLastUsedSoil(this.state.soil_texture);
      this.toggleActive();
      if (this.props.reportActive !== true) {
        this.props.toggleReportContainer();
      }
    }
  };

  onChange = (e) => {
    if (e.target.name === "LAT") {
      let newValue = this.maskLatLon(
        e.target.value,
        this.state.coords.split(",")[0]
      );
      newValue = newValue + "," + this.state.coords.split(",")[1];
      this.setState({
        coords: newValue,
      });
    } else if (e.target.name === "LNG") {
      let newValue = this.maskLatLon(
        e.target.value,
        this.state.coords.split(",")[1]
      );
      newValue = this.state.coords.split(",")[0] + "," + newValue;
      this.setState({
        coords: newValue,
      });
    } else if (e.target.name === "name" || e.target.name === "initials") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "data") {
      this.setState({
        file: e.target.files[0],
      });
    }
  };

  handleDropdownChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const soil = {
        texture: this.state.soil_texture,
        depth: this.state.soil_depth,
      };

      this.props.getCad(soil);
    });
  };

  saveOldValues = (keepActiveReport = false) => {
    this.setState({
      nameAux: this.state.name,
      initialsAux: this.state.initials,
      coordsAux: this.state.coords,
      cadAux: this.state.cad,
      soloTextureAux: this.state.soil_texture,
      soloDepthAux: this.state.soil_depth,
    });
    if (this.props.reportActive === true && keepActiveReport === false) {
      this.props.toggleReportContainer();
    }
  };

  handleEditCancel = () => {
    this.setState({
      name: this.state.nameAux,
      initials: this.state.initialsAux,
      coords: this.state.coordsAux,
      cad: this.state.cadAux,
      soil_texture: this.state.soil_texture,
      soil_depth: this.state.soil_depth,
    });
    this.props.saveAuxEditedCoords(this.state.coordsAux, "");
  };

  onBlurInput = (e) => {
    let farmAttrs = {
      user: localStorage.Token ? localStorage.Token : sessionStorage.Tokenn,
      name: this.state.name,
      coords: this.state.coords,
      farmId: this.props.activeFarm,
      cad: this.props.activeCad,
      soil_texture: this.state.soil_texture,
      soil_depth: this.state.soil_depth,
      initials: this.state.initials,
      visualization_type: 'civil',
    };

    if (this.isValid() === true) {
      this.props.toggleSidebar();
      this.props.toggleLoader();

      this.props.editFarm(this.props.activeFarm, farmAttrs);
      this.props.saveAuxEditedCoords(null, "");
      this.props.setEditMode("");
      this.props.toggleAddingFlag(false);
      if (this.props.productivity !== undefined) {
        this.props.setLastUsedCad(this.state.cad);
        this.props.setLastVisualizedCad(this.state.cad);
        this.props.setProductivityUndefined();
        if (
          this.props.farms.farms.find((farm) => farm.id === this.props.editMode)
        ) {
          this.onSubmitProductivity(
            { target: { value: this.props.reportTypes } },
            this.state.cad
          );
        } else {
          this.props.setReportTypes("");
          this.props.setActiveFarm(-1);
          this.props.toggleReportUndefined();
          this.toggleActive();
        }
      } else if (this.props.report !== undefined) {
        this.props.setLastUsedCad(this.state.cad);
        this.props.setLastVisualizedCad(this.state.cad);
        this.props.toggleReportUndefined();
        this.onSubmit(this.props.reportTypes[0], this.state.cad);
      }
    }
  };

  isValid = () => {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors: errors });
    }

    return isValid;
  };

  selectFarm = (e, next = false) => {
    this.clearForm();
    var farmId = parseInt(e, 10);
    var farm = undefined;
    var nullableAttrs = ["smart"];
    var shouldUpdate = false;

    if (next === false) {
      farm = this.props.farms["farms"].filter((farm) => farm.id === farmId)[0];
    } else {
      farm = next.filter((farm) => farm.id === farmId)[0];
    }
    const keys = Object.keys(farm);
    for (const [i, key] of keys.entries()) {
      if (farm[key] === null && !nullableAttrs.includes(key)) {
        shouldUpdate = true;
      }
      this.setState({ [key]: farm[key] }, () => {
        if (i === keys.length - 1) {
          const keepActiveReport = true;
          const soil = {
            texture: this.state.soil_texture,
            depth: this.state.soil_depth,
          };

          this.props.getCad(soil);
          this.saveOldValues(keepActiveReport);
        }
      });
    }

    if (shouldUpdate) {
      this.props.setEditMode(this.props.activeFarm);
      this.props.setReportTypes("");
      this.props.toggleReportUndefined();
      this.props.toggleReportContainer();
      this.props.resetGrid();
      this.setState({ updatePending: true });
      if (!next) {
        const toasterParams = {
          title: "Erro!",
          message: "Por favor atualize as informações do solo para esse ponto.",
          status: false,
          autoClose: true,
        };
        showToaster(toasterParams);

        if (!this.props.active) {
          this.props.toggleSidebar();
        }
      }
    } else {
      this.setState({ updatePending: false });
    }
  };

  clearForm = () => {
    this.props.setGridPointName("");
    this.setState({
      name: "",
      lat: "",
      lng: "",
      cad: "0",
      soil_texture: "",
      soil_depth: "",
      errors: {},
      initials: "",
      isLoading: false,
      reportTypes: [],
    });
  };

  onSubmitDashboard = (e) => {
    const report = e.target.value.split("-");
    this.props.setReportTypes(report[1]);
    this.props.setDashboardCulture(report[0]);
    this.props.toggleReportUndefined();
    this.toggleActive();

    if (!this.props.reportActive) {
      this.props.toggleReportContainer();
    }
  };

  onSubmitProductivity = (e, cad = undefined) => {
    const activeFarm = this.props.farms.farms.find(
      (farm) => farm.id === this.props.activeFarm
    );
    const coords = activeFarm.coords.split(",");
    const info = {
      lat: coords[0],
      lon: coords[1],
      cad: cad ? cad : activeFarm.cad,
      point_id: activeFarm.id,
      agry_eficiency: 70,
    };

    this.props.setProductivityUndefined();

    if (
      [
        "productivity-spec",
        "eucalyptus-spec-productivity",
        "soy-productivity",
        "corn-spec-productivity",
        "cotton-spec-productivity",
        "wheat-spec-productivity",
      ].includes(e.target.value)
    ) {
      if (cad) {
        this.props.setChangedCadFlag(true);
      }
    } else if (e.target.value === "soy-general-productivity") {
      this.props.generateSoyProductivity(info, "general");
    } else if (e.target.value === "corn-general-productivity") {
      this.props.generateCornProductivity(info, "general");
    } else if (e.target.value === "cotton-general-productivity") {
      this.props.generateCottonProductivity(info, "general");
    } else if (e.target.value === "wheat-general-productivity") {
      this.props.generateWheatProductivity(info, "general");
    } else if (e.target.value === "eucalyptus-spec-productivity") {
      this.props.generateSpecificEucalyptusProductivity(info, "specific");
    } else if (e.target.value === "eucalyptus-general-productivity") {
      this.props.generateEucalyptusProductivity(info, "general");
    } else {
      this.props.generateProductivity(info);
    }

    this.props.setReportTypes(e.target.value);
    this.props.toggleReportUndefined();
    this.toggleActive();

    if (!this.props.reportActive) {
      this.props.toggleReportContainer();
    }
  };

  onSubmitFloweringRisk = (e) => {
    const activeFarm = this.props.farms.farms.find(
      (farm) => farm.id === this.props.activeFarm
    );
    const data = {
      farmId: activeFarm.id,
      predictionDays: 15,
      year: new Date().getFullYear(),
    };

    let today = new Date();
    today.setDate(today.getDate() - 1);
    let currentDay =
      new Date() > new Date(new Date().getFullYear(), 2, 22)
        ? "22"
        : today.getDate().toString();
    let currentMonth =
      new Date() > new Date(new Date().getFullYear(), 2, 22)
        ? "3"
        : (today.getMonth() + 1).toString();
    if (currentDay < 10) {
      currentDay = "0" + currentDay;
    }

    if (currentMonth < 10) {
      currentMonth = "0" + currentMonth;
    }

    const day = {
      variable: "cane-flowering",
      label: currentDay + "/" + currentMonth,
      year: new Date().getFullYear().toString(),
    };
    this.props.setMapDay(day);
    this.props.generatingMapData();
    this.props.generateMapData(e.target.value, data);

    this.props.setReportTypes(e.target.value);
    this.props.toggleReportUndefined();
    this.toggleActive();

    if (!this.props.reportActive) {
      this.props.toggleReportContainer();
    }

    this.props.getFloweringRisk(data);
  };

  onProfileClicked = () => {
    this.props.toggleProfileBar();
    if (
      this.props.profileActive &&
      (this.props.farms === undefined || this.props.activeFarm === -2)
    ) {
      this.props.toggleSidebar();
    }
  };
  //#endregion

  render() {
    return (
      <aside className={classnames("sidebar", { active: this.props.active })}>
        <SidebarLoader active={this.props.sidebarLoading} />
        <span
          className="container--toggler container--toggler-right valign-wrapper"
          onClick={this.toggleActive}
        >
          <i className="material-icons">play_arrow</i>
        </span>
        <PerfectScrollbar className="sidebar--content">
          {this.props.profileActive ? (
            <ProfileBar userPlan={this.props.user.agrymax_plan} />
          ) : (
            <FarmForm
              toggleActive={this.toggleActive}
              name={this.state.name}
              coords={this.state.coords}
              initials={this.state.initials}
              activeFarm={this.props.activeFarm}
              cad={this.state.cad}
              soilDepth={this.state.soil_depth}
              soilTexture={this.state.soil_texture}
              errors={this.state.errors}
              reportTypes={this.state.reportTypes}
              onSubmit={this.onSubmit}
              onSubmitBhbox={this.onSubmitBhbox}
              onChange={this.onChange}
              onBlur={this.onBlurInput}
              handleDropdownChange={this.handleDropdownChange}
              handleReportTypeChange={this.handleReportTypeChange}
              setMapClickEditEvent={this.props.setMapClickEditEvent}
              moreFarmsActive={this.props.moreFarmsActive}
              toggleMoreFarms={this.props.toggleMoreFarms}
              toggleSidebar={this.toggleActive}
              cadError={this.props.cadError}
              allowMapClickEditEvent={this.props.allowMapClickEditEvent}
              user={this.props.user["type"]}
              userPlan={this.props.user["agrymax_plan"]}
              prodUser={this.props.user}
              handleEditCancel={this.handleEditCancel}
              saveOldValues={this.saveOldValues}
              editMode={this.props.editMode}
              setEditMode={this.props.setEditMode}
              addNewFarm={this.props.addNewFarm}
              toggleSidebarLoader={this.props.toggleSidebarLoader}
              toggleReportContainer={this.props.toggleReportContainer}
              deletePoint={this.props.deletePoint}
              toggleReportUndefined={this.props.toggleReportUndefined}
              setProductivityUndefined={this.props.setProductivityUndefined}
              setReportTypes={this.props.setReportTypes}
              setActiveFarm={this.props.setActiveFarm}
              addingFlag={this.props.addingFlag}
              farms={this.props.farms}
              toggleLoader={this.props.toggleLoader}
              toggleAddingFlag={this.props.toggleAddingFlag}
              onSubmitProductivity={this.onSubmitProductivity}
              onSubmitDashboard={this.onSubmitDashboard}
              updatePending={this.state.updatePending}
              onSubmitFloweringRisk={this.onSubmitFloweringRisk}
            />
          )}
        </PerfectScrollbar>
      </aside>
    );
  }
}
function mapStateToProps(state) {
  return {
    cad: state.cad.cad,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
