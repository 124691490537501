const initialState = {
    data : {},
    cad: '75'
}

export default (state = initialState, action) => {
    switch(action.type) {
        case "GET_CAD_SUCCESS":
            return Object.assign({}, state, {cad: action.response.cad})
        default:
            return state
    }
}