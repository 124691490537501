import React from 'react';

const ExtbhFooter = () => {
  return(
    <footer className='indicators-labels'>
      <div className='indicators-labels--item level-0'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>81 a 100%</p>
        </div>
      </div>
      <div className='indicators-labels--item level-1'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>61 a 80%</p>
        </div>
      </div>
      <div className='indicators-labels--item level-2'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>41 a 60%</p>
        </div>
      </div>
      <div className='indicators-labels--item level-3'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>31 a 40%</p>
        </div>
      </div>
      <div className='indicators-labels--item level-4'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>21 a 30%</p>
          </div>
      </div>
      <div className='indicators-labels--item level-5'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>11 a 20%</p>
          </div>
      </div>
      <div className='indicators-labels--item level-6'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>1 a 10%</p>
        </div>
      </div>
      <div className='indicators-labels--item level-7'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>{'< 1%'}</p>
        </div>
      </div>
      <div className='indicators-labels--item level-8'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>Previsão</p>
          <p>de 30 dias</p>
        </div>
      </div>
    </footer>
  )
};

export default ExtbhFooter;
