import {GET_ENOS_SUCCESS} from '../actions/enos'

const initialState = {
  enos: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ENOS_SUCCESS:
      return Object.assign({}, state, {enos: action.enos})
    default:
      return state
  }
}
