import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';

function MapButtons(props) {
  return (
    <Grid
      container
      alignItems='flex-end'
      direction={props.reportActive ? 'row' : 'row-reverse'}
      className={classnames('map-buttons', {reportOpen: props.reportActive})}
    >
      <Grid item xs={4} className={props.reportActive ? '' : 'invert-list'}>
        <ul>
          {
            props.kmzFiles && props.kmzFiles !== "" && (
              <li>
                <Link
                  to="#"
                  title='Mudar Tipo de Mapa'
                  className='button button--square button--square-default button--map'
                  onClick={props.handleKmzLayer}
                >
                  <img
                    src={
                      props.showKmzLayer
                        ? "/images/others/kmz-icon-active.jpg"
                        : "/images/others/kmz-icon.png" 
                    }
                    alt="kmz"
                    className='kmz'
                  />
                </Link>
              </li>
            )
          }
          <li>
            <Link
              to="#"
              title='Mudar Tipo de Mapa'
              className='button button--square button--square-default button--map'
              onClick={props.handleMapType}
            >
              <i className='material-icons'>terrain</i>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              title='Aumentar Zoom'
              className='button button--square button--square-default button--map'
              onClick={props.handleZoomIn}
            >
              <i className='material-icons'>add</i>
            </Link>
          </li>
          <li>
            <Link
              to="#"
              title='Diminuir Zoom'
              className='button button--square button--square-default button--map button--zoom'
              onClick={props.handleZoomOut}
            >
              <i className='material-icons'>remove</i>
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item xs={4} className={props.reportActive ? '' : 'invert-list'}>
        {props.activeGrid && (
          <ul>             
              {props.showXavierButton && (
                <li>
                  <Link
                    to="#"
                    title={props.xavierGrid ? 'Esconder Pontos do Sistema' : 'Mostrar Pontos do Sistema'}className='button button--square button--square-default button--map zoom-side' onClick={props.handleGrid}
                  >
                    <i className='material-icons'>
                      {props.xavierGrid ? 'grid_off' : 'grid_on'}
                    </i>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="#"
                  title={props.userPointGrid ? 'Esconder Pontos do Usuário' : 'Mostrar Pontos do Usuário'}
                  className='button button--square button--square-default button--map zoom-side'
                  onClick={props.handleUserPointsGridButton}
                >
                  <i className='material-icons'>
                    {props.userPointGrid ? 'explore_off' : 'explore'}
                  </i>
                </Link>
              </li>
          </ul>
        )}
      </Grid>
      <Grid item xs={4} className={props.reportActive? '' : 'invert-list'} />
    </Grid>
  );
}

export default MapButtons;
