import { authHeader } from "./config"

class DashboardApi {
    static getDashboardTable(data) {
        return fetch(process.env.REACT_APP_API + `api/smartcrops_dashboard?culture=${data.culture}&page=${data.page}&limit=${data.limit}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static addDashboardCsv(data) {
        const formData = new FormData()
        formData.append("culture", data.culture)
        formData.append("file", data.file)

        return fetch(process.env.REACT_APP_API + "api/smartcrops_dashboard/upload", {
            method: 'POST',
            headers: authHeader(),
            body: formData
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static addDashboardLine(data) {
        return fetch(process.env.REACT_APP_API + "api/smartcrops_dashboard", {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                point_id: data.point_id,
                culture: data.culture,
                planting_date: data.planting_date,
                cycle: data.cycle,
                agry_eficiency: 0.7,
                desired_agry_eficiency: data.desired_agry_eficiency > 1 ? (data.desired_agry_eficiency / 100) : data.desired_agry_eficiency,
                user_rp_value: data.user_rp_value,
                specific_args: data.extraArgs
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static editDashboardLine(data) {
        let update = data.updateInfo;
        update.desired_agry_eficiency = data.updateInfo.desired_agry_eficiency > 1 ? (data.updateInfo.desired_agry_eficiency / 100) : data.updateInfo.desired_agry_eficiency
        update.agry_eficiency = 0.7

        return fetch(process.env.REACT_APP_API + "api/smartcrops_dashboard", {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify({
                culture: data.extraAuth,
                update_payload: update
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return 
        })
    }

    static deleteDashboardLine(data) {
        return fetch(process.env.REACT_APP_API + "api/smartcrops_dashboard", {
            method: "DELETE",
            headers: authHeader(),
            body: JSON.stringify({
                id: data.id
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return
        })
    }

    static getDashboardChart(data) {
        let points = []
        data.points.map(point => points.push(`&points=${point}`))
        
        let years = []
        data.harvests.map(year => years.push(`&harvests=${year}`))
        
        return fetch(process.env.REACT_APP_API + `api/smartcrops_dashboard/charts?culture=${data.culture}${points.join("")}${years.join("")}`, {
            method: "GET",
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }
}

export default DashboardApi