import { Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const AuditDropdownButton = styled(Button)({
  size: "small",
  color: '#373737',
  borderRadius: '36px',
  fontWeight: 'bold',
  margin: '5px 25px',
  padding: '15px',
  height: '1rem',
  '&:hover': {
    backgroundColor: "#C1D9A5",
  }
});

export const AuditDropdownLink = styled(Link)({
  color: "#373737"
});
