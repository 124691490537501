import React from 'react';
import classnames from 'classnames';

const BhboxFooter = () => {
  return(
    <footer className={classnames('indicators-labels', {"index-indicators-labels": false}, {"bhbox-indicators-labels": true})}>
      <div className='indicators-labels--item'>
        <div className='label-text-unit'>
          <p>ARM/CAD</p>
          <p>(%)</p>
        </div>
      </div>
      <div className='indicators-labels--item level-0'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>81 a 100%</p>
          <p>Muito Favorável</p>
        </div>
      </div>
      <div className='indicators-labels--item level-1'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>61 a 80%</p>
          <p>Favorável</p>
        </div>
      </div>
      <div className='indicators-labels--item level-2'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>41 a 60%</p>
          <p>Razoável</p>
        </div>
      </div>
      <div className='indicators-labels--item level-3'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>31 a 40%</p>
          <p>Desfavorável</p>
        </div>
      </div>
      <div className='indicators-labels--item level-4'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>21 a 30%</p>
          <p>Crítico</p>
        </div>
      </div>
      <div className='indicators-labels--item level-5'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>11 a 20%</p>
          <p>Muito Crítico</p>
        </div>
      </div>
      <div className='indicators-labels--item level-6'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>1 a 10%</p>
          <p>Severo</p>
        </div>
      </div>
      <div className='indicators-labels--item level-7'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>{'< 1%'}</p>
          <p>Muito Severo</p>
        </div>
      </div>
      <div className='indicators-labels--item level-8'>
        <div className='label-color'></div>
        <div className='label-text'>
          <p>Previsão</p>
          <p>de 30 dias</p>
        </div>
      </div>
    </footer>
  )
};

export default BhboxFooter;
