export function scheduleGlossary(string){
    switch(string.toString()){
        //scheduled-period
        case "1":
            return "Diário"
        case "7":
            return "Semanal"
        case "31":
            return "Mensal"
        //day-time
        case "morning":
            return "Manhã"
        case "afternoon":
            return "Tarde"
        case "night":
            return "Noite"
        //variable
        case "temperatura":
            return "Temperatura"
        case "rs":
            return "Radiação Solar"
        case "gd":
            return "Graus-dia"
        case "u2":
            return "Vento a 2m"
        case "prec":
            return "Chuva"
        case "ur":
            return "Umidade Relativa"
        case "etp":
            return "ETP"
        case "extbh":
            return "Extrato BH"
        case "etretp":
            return "ETR/ETP"
        case "excdef":
            return "EXC/DEF"
        case "arm":
            return "Armazenamento"
        //type
        case "period":
            return "Período"
        case "cmp":
            return "Comparação"
        case "anomaly":
            return "Anomalia"
        case "climatology":
            return "Climatologia"
        //range
        case "daily":
            return "Diário"
        case "decendial":
            return "Decendial"
        case "monthly":
            return "Mensal"
        case "annualy":
            return "Anual"
        default:
            return string
    }        
}

export function productivityGlossary(string) {
    switch(string.toString()) {
        case "cane":
            return "Cana"
        case "soy":
            return "Soja"
        case "corn":
            return "Milho"
        case "cotton":
            return "Algodão"
        case "wheat":
            return "Trigo"
        default:
            return string
    }
}

export function smartAccessGlossary(string) {
    switch(string.toString()) {
        case "cane":
            return "cana"
        case "soy":
            return "soja"
        case "corn":
            return "milho"
        case "cotton":
            return "algodao"
        case "wheat":
            return "trigo"
        default:
            return string
    }
}