import React, { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import * as S from './styles/AutomaticAuditTable';
import { MainHeader, SecondHeader } from './styles/AuditTypography';

import Loader from '../common/Loader';
import AuditTableBody from './AuditTableBody';

import { getAutomaticAudit } from '../../api/audits';

import getTableHeaders from './utils/AuditTableHelper';

function AutomaticAuditTable(props) {
  const [checks, setChecks] = useState();
  const {enFields, endPoint} = props.fields;
  
  useEffect(() => {
    const getchecks = async () => {
      const {data} = await getAutomaticAudit(endPoint);
      setChecks(data.data);
    };
    getchecks()
  }, [endPoint]);

  if (!checks) {
    return <Loader active={ true } />
  }

  return (
    <>
      <header>
        <MainHeader component="h2" align="left">
          Auditoria automática dos produtos
        </MainHeader>
        <SecondHeader component="h3" align="left">
          Confira o histórico da auditoria
        </SecondHeader>
      </header>
      <S.AutomaticAuditTableContainer>
        <Table 
          sx={{ minWidth: 650 }} 
          size="small" 
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              { 
                checks.map((date, index) => (
                  <S.AutomaticAuditTableColumn key={index}>
                    { getTableHeaders(date) }
                  </S.AutomaticAuditTableColumn>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <AuditTableBody data={ enFields } checks={ checks } />
          </TableBody>
        </Table>
      </S.AutomaticAuditTableContainer>
    </>
  );
}

export default AutomaticAuditTable;
