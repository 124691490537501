import { authHeader } from "./config"

class UsersApi {
  static createUser(data, userRole, userPlan, cultures, smart) {
    return fetch(process.env.REACT_APP_API + "api/users/new", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        user: data.identifier,
        password: data.password,
        cpf_cnpj: data.cpf_cnpj,
        user_role: userRole,
        cultures: cultures,
        agrymax_plan: userPlan,
        smart,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static confirmUser(token) {
    return fetch(process.env.REACT_APP_API + "api/users/confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static InitRecoverUserPassword(email) {
    return fetch(process.env.REACT_APP_API + "api/users/init_recover", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static recoverUserPassword(password, passwordConfirm, token) {
    return fetch(process.env.REACT_APP_API + "api/users/recover_password", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
        password_confirm: passwordConfirm,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getCultures() {
    return fetch(process.env.REACT_APP_API + "api/cultures", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static sendUserContactInfo(contact) {
    return fetch(process.env.REACT_APP_API + "api/users/send_contact", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...contact,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
}

export default UsersApi
