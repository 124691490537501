import React from 'react';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label} from 'recharts';
import CustomizedAxisTick from '../CustomizedAxisTick';

const CustomTooltip = ({ active, payload, label, unit, showTooltips }) => {
  let percent = 0

  if (active && showTooltips) {
    if(payload[0].payload.last){
      percent = ((payload[0].value/payload[0].payload.last) - 1) * 100
    }
    return (
      <div style={{padding: '8px', backgroundColor: 'white', border: '1px solid black', textAlign: 'center'}}>
        <p className="label">{`${label}`}</p>
        <p className="label">{`${payload[0].value} ${unit}`}</p>
        {payload[0].payload.last && percent > 0 &&
          <p className="label" style={{color:'green'}}>{`${percent.toFixed(1)}%`}</p>
        }
        {payload[0].payload.last && percent < 0 &&
          <p className="label" style={{color:'red'}}>{`${percent.toFixed(1)}%`}</p>
        }
      </div>
    );
  }

  return null;
};

export default class ObservedSpecificProductivityChart extends React.Component {
  render () {
    let bars = []
    if(this.props.type === 'brute'){
      bars = [
        <Bar dataKey="pp" fill="#FF1419" />,
        <Bar dataKey="ap" fill="#FFBF37" />,
        <Bar dataKey="rpEstimate" fill="#FFFF47" />,
        <Bar dataKey="rpObserved" fill="#8DCE5E" />,
      ]
    }else if(this.props.type === 'compareBox'){
      bars = [
        <Bar dataKey="md" fill="#FF1419" />,
        <Bar dataKey="d" fill="#FFBF37" />,
        <Bar dataKey="me" fill="#FFFF47" />,
        <Bar dataKey="fa" fill="#8DCE5E" />,
        <Bar dataKey="mf" fill="#2174B1" />,
        <Bar dataKey="lastCrop" fill="#7E7E7E" />
      ]
    }else{
      bars = [
        <Bar dataKey="ygdh" fill="#2174B1" />,
        <Bar dataKey="ygmanEstimate" fill="#7E7E7E" />,
        <Bar dataKey="ygmanObserved" fill="#878787" />
      ]
    }
    return (
      <ResponsiveContainer height={280}>
        <BarChart data={this.props.data} margin={{top: 20, right: 30, left: 20, bottom: 5}} barGap={this.props.type === 'brute' ? -66 : this.props.type === 'compareBox' ? -32 : -96}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis height={85} dataKey="name" tick={<CustomizedAxisTick graphicRange={"annualy"}/>} interval={0}/>
          <YAxis>
            <Label angle={-90} value={this.props.unitText} position="left" style={{textAnchor: 'middle'}}/>
          </YAxis>
          <Tooltip content={<CustomTooltip unit={this.props.unit} showTooltips={this.props.showTooltips}/>}/>
          {bars.map(bar => {
            return bar
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
