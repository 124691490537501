import React, { Component, Fragment } from 'react'
import validateInput from '../../shared/validators/login'
import { connect } from 'react-redux'
import { login, setRememberMe } from '../../actions/login'
import { toggleLoader } from '../../actions/global'

//components
import InputField from '../common/InputField'
import Checkbox from 'rc-checkbox'
import Button from '@material-ui/core/Button'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identifier: '',
      password: '',
      errors: {},
      loginErrors: {}
    }
  }

  //#region Lifecycle methods
  componentDidMount(){
    document.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount(){
    document.removeEventListener('keydown', this.handleKeyPress)
  }
  //#endregion

  //Helper methods
  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if(!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  //#region Render methods
  handleKeyPress = (event) => {
    // [Enter] should not submit the form when choosing an address.
    if (event.keyCode === 13) {
      if(this.state.identifier !== '' && this.state.password !== ''){
        event.preventDefault()
        if(this.isValid()) {
          this.props.toggleLoader()
          this.props.login(this.state, this.props.rememberMe)
        }
      }
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onRememberMeChange = (e) => { 
    this.props.setRememberMe(e.target.checked)
  }

  onSubmit = (e) => {
    e.preventDefault()
    if(this.isValid()) {
      this.props.toggleLoader()
      this.props.login(this.state, this.props.rememberMe)
    }
  }
  //#endregion

  render(){
    const {errors, identifier, password} = this.state
    return(
      <Fragment>
        <div className='login-area-form-container-inside-form-row'>
          {Object.keys(this.props.errors).length !== 0 ?
            <div className="row errors">
              {this.props.errors.summary}
            </div> : null
          }
          <div style={{marginBottom: '8px', paddingLeft: '8px'}}>
            <span className='user-signed'>Já sou cliente</span>
          </div>
          <form onSubmit={ this.onSumbit }>
            <InputField
              name='identifier'
              label='Email'
              type='email'
              onChange={ this.onChange }
              value={ identifier }
              error={ errors.identifier }
              active={ identifier !== ''}
              loginInputActive={identifier !== ''}
              activeFarm={'a'}
            />
            <InputField
              name='password'
              label='Senha'
              type='password'
              value={ password }
              error={ errors.password }
              onChange={ this.onChange }
              active={password !== ''}
              loginInputActive={password !== ''}
              activeFarm={'a'}
            />
          </form>
          <div className="row options-row">
            <div className="col s7">
              <label>
                <Checkbox
                  id={'all'}
                  name={'all'}
                  className='checkbox-item'
                  onChange={this.onRememberMeChange}
                  checked={this.props.rememberMe}
                />

                {'Manter Sessão'}
              </label>
            </div>
            <div className="col s5 forgot-password">
              <label onClick={this.props.toggleRecoverPassword}>Esqueci minha senha</label>
            </div>
          </div>
        </div>
        <div>
          <div className='login-area-form-container-inside-buttons-row'>
            <button ref={'submitButton'} onClick={this.onSubmit} className='btn wave-light' style={{backgroundColor: '#8cb63c', fontWeight: 'bold', width: '100%', fontSize: '12px'}}>ACESSAR CONTA</button>
          </div>
          <div style={{textAlign: 'center'}}>
            <Button style={{color: '#8cb63c', fontWeight: 'bold'}} onClick={this.props.toggleContactFlag} color="primary">
              ENTRAR EM CONTATO
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state){
  return{
    errors: state.auth.errors,
    rememberMe: state.auth.rememberMe
  }
}

function mapDispatchToProps(dispatch){
  return{
    login: (data, rememberMe) => {
      dispatch(login(data, rememberMe))
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    setRememberMe: (check) =>{
      dispatch(setRememberMe(check))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
