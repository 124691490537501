import React, { useEffect, useState } from "react"
import moment from "moment"
import { cloneDeep } from "lodash"

import { Grid } from "@material-ui/core"
import Table from "../../common/Table"

const NotificationHistory = ({data, rowEdit, rowDelete, totalItens, rowsPerPage , currentPage, handleChangePage, handleChangeRowsPerPage}) => {
    const [formattedData, setFormattedData] = useState([])

    useEffect(() => {
        if(data.length > 0) {
            const tempData = cloneDeep(data)
            tempData.map(item => {
                item.created_at = moment(item.created_at).format('DD-MM-YYYY')
                item.edited_at = moment(item.edited_at).format('DD-MM-YYYY')
                return item
            })
            setFormattedData(tempData)
        }
        else {
            setFormattedData([])
        }
    }, [data])

    return (
        <Grid item container>
            <Grid item xs={12}>
                <h6>Histórico de notificações</h6>
            </Grid>
            <Grid item xs={12}>
                <Table
                    tableStyle="notification-table"
                    data={formattedData}
                    externalEdition
                    startEditing={rowEdit}
                    handleRemove={rowDelete}
                    header={[
                        {
                            name: "Título",
                            prop: "title"
                        },
                        {
                            name: "Data de Criação",
                            prop: "created_at"
                        },
                        {
                            name: "Data de Edição",
                            prop: "edited_at"
                        }
                    ]}
                    totalItens={totalItens}
                    rowsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    )
}

export default NotificationHistory;
