import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux'
import {useDropzone} from 'react-dropzone';

import { uploadKmzFile } from "../../actions/profile";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#6CB832'
};

const acceptStyle = {
  borderColor: '#6CB832',
};

function StyledDropzone({ setToaster }) {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isFocused, isDragAccept } = useDropzone({
    onDrop: (acceptedFiles) => {
      dispatch(uploadKmzFile(acceptedFiles[0]))
        .then(() => {
          setToaster(true);
        });
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
  }), [isFocused, isDragAccept]);

  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Arraste o arquivo aqui</p>
      </div>
    </div>
  );
}

export default StyledDropzone;
