import React from 'react';

import { TableRow, TableCell, Fab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

function KmzTableRow(props) {
  const {
    classes, index, row, editId, update, handleChange, setDeleteId, handleUpdate, setEditId
  } = props;
  const rowColor = index % 2 === 0 ? 'evenCell' : 'oddCell';
  const cellClasses = [classes.tableCellBody, classes[rowColor]];
  const isEditing = editId === row.id;

  return (
    <TableRow>
      <TableCell className={ `${cellClasses.join(' ')} ${classes.nameCell}` }>
        { 
          isEditing
            ? (
              <input
                name="email"
                value={ update }
                onChange={ handleChange }
                margin="normal"
              />
            ) : row.name
        }
      </TableCell>
      <TableCell className={ cellClasses }>
        <div style={ { display: 'flex' } }>
          <Fab
            size="small"
            className={ classes.icons }
            onClick={ isEditing ? () => handleUpdate(row) : () => setEditId(row.id) }
          >
            { isEditing ? <CheckIcon /> : <EditIcon /> }
          </Fab>
          <Fab size="small" className={ classes.icons }>
            <DeleteIcon onClick={ () => setDeleteId(row.id) } />
          </Fab>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default KmzTableRow;
