import React, { Component, Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import validateInput from '../../shared/validators/contactInfo';
import { showToaster } from '../common/Toaster'

class ContactInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: {
        name: '',
        email: '',
        position: '',
      },
      errors: {}
    }
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.state.contact)
    if(!isValid) {
      this.setState({ errors })
    }
    return isValid
  }

  handleChange = (event) => {
    this.setState({contact:{...this.state.contact, [event.target.name]: event.target.value}})
  }

  onSubmit = (e) => {
    e.preventDefault()
    if(this.isValid()) {
      this.props.sendContactInfo(this.state.contact)

      const toasterParams = {
        title: 'CONTATO ENVIADO',
        message: 'Um e-mail foi enviado aos administradores com o seu contato.',
        status: true,
        autoClose: true,
      }
      showToaster(toasterParams)

      this.props.toggleContactFlag()
    }
  }

  render(){
    return(
      <Fragment>
        <div className='contact-form-container-inside-subtitle'>
          <label>Preencha os campos com as suas informações e um administrador do sistema irá entrar em contato</label>
        </div>
        <FormControl fullWidth margin={"dense"} >
          <Fragment>
            <TextField
              margin={"dense"}
              name="name"
              type="text"
              label={"Nome"}
              value={this.state.contact.name}
              onChange={this.handleChange}
              InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.state.errors.name ? this.state.errors.name : ''}
            />
            <TextField
              margin={"dense"}
              name="email"
              type="email"
              label={"E-mail"}
              value={this.state.contact.email}
              onChange={this.handleChange}
              InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.state.errors.email ? this.state.errors.email : ''}
            />
            <TextField
              margin={"dense"}
              name="position"
              type="text"
              label={"Área de atuação"}
              value={this.state.contact.position}
              onChange={this.handleChange}
              InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.state.errors.position ? this.state.errors.position : ''}
            />
          </Fragment>
        </FormControl>
        <div className='row login-area-form-container-inside-buttons-row' style={{marginTop: '16px'}}>
          <button ref={'submitButton'} onClick={this.onSubmit} className='btn wave-light space-between' style={{backgroundColor: '#8cb63c', fontWeight: 'bold'}}>Enviar</button>
          <button className='btn-flat btn-drop' onClick={this.props.toggleContactFlag}>Voltar</button>
        </div>
      </Fragment>
    )
  }

}

export default ContactInfo;
