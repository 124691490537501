import EnosApi from '../api/enos'

export const GET_ENOS = 'GET_ENOS'
export const GET_ENOS_SUCCESS = 'GET_ENOS_SUCCESS'

export function getEnos(type) {
  return function(dispatch){
    return EnosApi.getEnosData(type)
      .then(enos => {dispatch(getEnosSuccess(enos))})
      .catch(error => {throw(error)})
  }
}

export function getEnosSuccess(enos) {
  return {type: 'GET_ENOS_SUCCESS', enos}
}
