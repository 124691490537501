import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export default function validateInput(data) {
  let errors = {}

  if(Validator.isEmpty(data.email)) {
    errors.email = 'Preencha com seu email'
  }else if(!Validator.isEmail(data.email)) {
    errors.email = 'Coloque um email válido'
  }

  if(Validator.isEmpty(data.name)) {
    errors.name = 'Preencha com seu nome'
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
