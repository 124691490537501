import FloweringRiskApi from '../api/flowering'

export const GET_RISK_SUCCESS = 'GET_RISK_SUCCESS'
export const RESET_FLOWERING_REPORT = 'RESET_FLOWERING_REPORT'

export function getFloweringRisk(data){
    return function(dispatch){
        return FloweringRiskApi.getFloweringRisk(data)
            .then(response =>{
                dispatch(getFloweringRiskSuccess(response))
            }).catch(error => {throw(error)})
    }
}

export function resetFloweringReport(){
    return { type: 'RESET_FLOWERING_REPORT' }
}   

export function getFloweringRiskSuccess(data){
    return {type: 'GET_RISK_SUCCESS', data}
}