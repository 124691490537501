import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ErrorBar,
  LineChart,
  Line,
  BarChart,
  Bar,
  ReferenceLine,
} from "recharts";

import { changeMapDay, generateMapData, setMapYear } from "../../../actions/map";
import { toggleLoader, generatingMapData, toggleLeftLoader } from "../../../actions/global";

import { numberToMonth, monthToNumber, leapYear, setDates, renderActualYear } from "../../../shared/utils/DatesUtils";

import CustomizedAxisTick from "../CustomizedAxisTick";

const WIDTH_FACTOR = 0.653345582;
const FULLSCREEN_WIDTH_FACTOR = 0.960799385;
const DATE = new Date();
const CURRENT_MONTH = DATE.getMonth() + 1

//Auxilar presentation component that will be used to set custom tooltips for the weather graphics
class CustomTooltip extends Component {
  //Helper methods
  setUnitLabel(variable) {
    const variableSliceYear = variable.slice(0, -4)
    if (
      [
        "temperatura",
        "tmax",
        "tmin",
        "tmed",
        "p_tmax",
        "p_tmin",
        "p_tmed",
        "c_tmax",
        "c_tmean",
        "c_min",
        "c_gd",
      ].includes(variable)
    ) {
        return "°C";
    } else if (variable === "rs" || variable === "p_rs") {
        return "MJ/m²/d";
    } else if (variable === "gd" || variable === "p_gd") {
        return "°C dia";
    } else if (
        variable === "p" ||
        variable === "p_" ||
        variable === "prec"||
        variable === "excdef"|| 
        variable === "arm"||
        variable === "etretp"||
        ["ETP", "ETR", "etp", "p_etp", "c_etp"].includes(variable)||
        variableSliceYear === 'p_'
     ) {
        return "mm";
    } else if (["u2", "p_u2", "c_u2"].includes(variable)) {
        return "m/s";
    } else if (["ur", "p_ur", "c_ur"].includes(variable)) {
        return "%";
    } else if (
        variable === "heat_wave" ||
        variable === "p_heat_wave_" ||
        variable === "p_heat_wave_" + new Date().getFullYear().toString()
    ) {
      return this.props.temperatureControl === "35"
        ? "dia(s) com Tmax >= 35º"
        : "dia(s) com Tmax >= 32º";
    } else if (
        variable === "frost_risk" ||
        variable === "p_frost_risk_" ||
        variable === "p_frost_risk_" + new Date().getFullYear().toString()
    ) {
      return "dia(s) com Tmin <= 2º";
    } else if (
        variable === "rainy_days" ||
        variable === "p_rainy_days_" ||
        variableSliceYear ==='p_rainy_days_'||
        variable === "p_rainy_day_" + new Date().getFullYear().toString()
    ) {
        return "dia(s) com chuva";
    } else {
      return "MJ/m²/d";
    }
  }

  render() {
    const { active, showTooltips } = this.props;
    let products = [
      "ur",
      "p_ur",
      "rs",
      "p_rs",
      "u2",
      "p_u2",
      "ETP",
      "ETR",
      "etp",
      "p_etp",
      "gd",
      "p_gd",
      "p",
      "p_",
      "heat_wave",
      "p_heat_wave_",
      "frost_risk",
      "p_frost_risk_",
      "rainy_days",
      "p_rainy_days",
    ];

    if (active && showTooltips) {
      const { payload, label, weatherType, weatherRange, variable } = this.props;
      if (payload !== null && payload.length !== 0) {
        if (
          payload[0].name === "tmax" ||
          payload[0].name === "p_tmax" ||
          weatherType === "climatology"
        ) {
          if (weatherRange === "annualy") {
            return (
              <div className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <p style={{ color: payload[0].stroke }} className="desc">
                  {`${payload[0].value} ${this.setUnitLabel(payload[0].name)}`}
                </p>
                <p style={{ color: payload[2].stroke }} className="desc2">
                  {`${payload[2].value} ${this.setUnitLabel(payload[0].name)}`}
                </p>
                <p style={{ color: payload[1].stroke }} className="desc2">
                  {`${payload[1].value} ${this.setUnitLabel(payload[0].name)}`}
                </p>
              </div>
            );
          } else if (weatherType === "climatology") {
            if (variable === "temperatura") {
              let temp = [];
              for (let i = 0; i < payload.length; i++) {
                switch (payload[i].name) {
                  case "tmax":
                  case "c_tmax":
                    temp[i] = "max - ";
                    break;
                  case "tmean":
                  case "c_tmean":
                    temp[i] = "med - ";
                    break;
                  case "tmin":
                  case "c_tmin":
                    temp[i] = "min - ";
                    break;
                  default:
                    temp[i] = "";
                    break;
                }
              }
              return (
                <div className="custom-tooltip">
                  <p className="label">{`${label}`} - climatologia</p>

                  {payload[3] && (
                    <p
                      style={{ color: payload[3].stroke }}
                      className="desc"
                    >{`${payload[3].name === "tmax" && "max - "} ${
                      payload[3].value
                    } ${this.setUnitLabel(payload[0].name)}`}</p>
                  )}
                  {payload[5] && (
                    <p
                      style={{ color: payload[5].stroke }}
                      className="desc2"
                    >{`${payload[5].name === "tmean" && "med - "} ${
                      payload[5].value
                    } ${this.setUnitLabel(payload[0].name)}`}</p>
                  )}
                  {payload[4] && (
                    <p
                      style={{ color: payload[4].stroke }}
                      className="desc2"
                    >{`${payload[4].name === "tmin" && "min - "} ${
                      payload[4].value
                    } ${this.setUnitLabel(payload[0].name)}`}</p>
                  )}

                  {payload[0] &&
                    ["tmax", "tmean", "tmin"].includes(payload[0].name) && (
                      <p
                        style={{ color: payload[0].stroke }}
                        className="desc"
                      >{`${temp[0]} ${payload[0].value} ${this.setUnitLabel(
                        payload[0].name
                      )}`}</p>
                    )}
                  {payload[2] && payload[2].name === "tmean" && (
                    <p
                      style={{ color: payload[2].stroke }}
                      className="desc2"
                    >{`med - ${payload[2].value} ${this.setUnitLabel(
                      payload[0].name
                    )}`}</p>
                  )}
                  {payload[1] && payload[1].name !== "c_tmin" && (
                    <p
                      style={{ color: payload[1].stroke }}
                      className="desc"
                    >{`${temp[1]} ${payload[1].value} ${this.setUnitLabel(
                      payload[0].name
                    )}`}</p>
                  )}

                  {((payload[3] && payload[4] && payload[5]) ||
                    (payload[1] &&
                      payload[1].name !== "c_tmin" &&
                      !payload[2])) && (
                    <p className="label">
                      {label}/
                      {payload[0].payload["year"]
                        ? payload[0].payload["year"]
                        : this.props.yearBegin}
                    </p>
                  )}

                  {payload[0] &&
                    ["c_tmax", "c_tmean", "c_tmin"].includes(
                      payload[0].name
                    ) && (
                      <p
                        style={{ color: payload[0].stroke }}
                        className="desc"
                      >{`${temp[0]} ${payload[0].value} ${this.setUnitLabel(
                        payload[0].name
                      )}`}</p>
                    )}
                  {payload[2] && payload[2].name === "c_tmean" && (
                    <p
                      style={{ color: payload[2].stroke }}
                      className="desc2"
                    >{`med - ${payload[2].value} ${this.setUnitLabel(
                      payload[0].name
                    )}`}</p>
                  )}
                  {payload[1] && payload[1].name === "c_tmin" && (
                    <p
                      style={{ color: payload[1].stroke }}
                      className="desc2"
                    >{`min - ${payload[1].value} ${this.setUnitLabel(
                      payload[0].name
                    )}`}</p>
                  )}
                </div>
              );
            } else if (variable === "prec") {
              return (
                <div className="custom-tooltip">
                  <p className="label">{`${label}`} - climatologia</p>

                  {payload[0] && (
                    <p
                      style={{ color: payload[0].stroke }}
                      className="desc"
                    >{`${payload[0].value} ${this.setUnitLabel(
                      payload[0].name
                    )}`}</p>
                  )}
                  {payload[1] && (
                    <Fragment>
                      <p className="label">
                        {label}/
                        {payload[1].payload["year"]
                          ? payload[1].payload["year"]
                          : this.props.yearBegin}
                      </p>
                      <p
                        style={{ color: payload[1].stroke }}
                        className="desc"
                      >{`${payload[1].value} ${this.setUnitLabel(
                        payload[0].name
                      )}`}</p>
                    </Fragment>
                  )}
                </div>
              );
            } else {
              return (
                <div className="custom-tooltip">
                  <p className="label">{`${label}`} - climatologia</p>

                  {payload[1] && (
                    <Fragment>
                      <p
                        style={{ color: payload[1].stroke }}
                        className="desc"
                      >{`${payload[1].value} ${this.setUnitLabel(
                        payload[0].name
                      )}`}</p>
                      <p className="label">
                        {label}/
                        {payload[0].payload["year"]
                          ? payload[0].payload["year"]
                          : this.props.visualizationType !== 'civil' &&
                            (
                              +payload[0].payload['name'].split('/')[1] < 7 ||
                              ['jan', 'fev', 'mar', 'abr', 'mai', 'jun'].includes(payload[0].payload['name'].split('_')[0])
                            )

                          ? +this.props.yearBegin + 1
                          : this.props.yearBegin
                        }
                      </p>
                    </Fragment>
                  )}

                  {payload[0] && (
                    <p
                      style={{ color: payload[0].stroke }}
                      className="desc"
                    >{`${payload[0].value} ${this.setUnitLabel(
                      payload[0].name
                    )}`}</p>
                  )}
                </div>
              );
            }
          } else {
            return (
              <div className="custom-tooltip">
                <p className="label">{`${
                  label +
                  (payload[0].payload["year"]
                    ? "/" + payload[0].payload["year"]
                    : "")
                }`}</p>
                {payload[0] && (
                  <p style={{ color: payload[0].stroke }} className="desc">
                    {`${payload[0].value} ${this.setUnitLabel(payload[0].name)}`}
                  </p>
                )}
                {payload[2] && (
                  <p style={{ color: payload[2].stroke }} className="desc2">
                    {`${payload[2].value} ${this.setUnitLabel(payload[0].name)}`}
                  </p>
                )}
                {payload[1] && (
                  <p style={{ color: payload[1].stroke }} className="desc2">
                    {`${payload[1].value} ${this.setUnitLabel(payload[0].name)}`}
                  </p>
                )}
              </div>
            );
          }
        } else if (
          products.includes(payload[0].name) ||
          (["prec", "heat_wave", "frost_risk", "rainy_days"].includes(
            variable
          ) &&
            weatherType === "period" &&
            weatherRange === "daily")
        ) {
          
          if (weatherRange === "annualy") {
            return (
              <div className="custom-tooltip">
                <p className="label">{`${label}`}</p>
                <p style={{ color: payload[0].stroke }} className="desc">
                  {`${payload[0].value} ${this.setUnitLabel(payload[0].name)}`}
                </p>
              </div>
            );
          } else {
            const monthNumber = Number(payload[0].payload.name.split('/')[1]);
            const monthOrDecendioName = payload[0].payload.name;
            let year = renderActualYear(this.props.visualizationType, this.props.yearBegin, monthNumber, monthOrDecendioName)
            if (year === undefined) {
              year =
                payload[0].name.split("_")[
                  payload[0].name.split("_").length - 1
                ];
                
                year = renderActualYear(this.props.visualizationType, year, monthNumber, monthOrDecendioName);
            }
            return (
              <div className="custom-tooltip">
                <p className="label">
                  {`${year ? label + "/" + year : label}`}
                </p>
                <p style={{ color: payload[0].stroke }} className="desc">
                  {`${payload[0].value} ${this.setUnitLabel(payload[0].name)}`}
                </p>
              </div>
            );
          }
        } else if (weatherType === "cmp" || weatherType === "accumulate") {
          return (
            <div className="custom-tooltip2">
              <p className="label">{`${label}`}</p>

              {payload[0] !== undefined ? (
                payload.map((item, index) => {
                  return (
                    <p>
                      <p style={{ color: item.stroke, fontSize: 13.2 }} className="desc">{`${
                        this.props.visualizationType === 'civil'
                          ? item.name
                          : `${item.name}${item.name !== "Média" ? ` - ${Number(item.name) + 1}` : ''}`
                      }: ${item.value} ${this.setUnitLabel(variable)}`}</p>
                    </p>
                  );
                })
              ) : (
                <p>
                  <p className="desc">"Something went wrong"</p>
                </p>
              )}
              {payload[0].payload.error && (
                <p
                  style={{
                    marginTop: -10,
                    fontSize: 13.2,
                    color: "#7F7F7F",
                  }}
                >
                  {`Desvio-Padrão: ${payload[0].payload.error[1]} mm`}
                </p>
              )}
            </div>
          );
        } else if (weatherType === "anomaly") {
          return (
            <div className="custom-tooltip2">
              <p className="label">{`${payload[0].payload.name}`}</p>
              <p style={{ color: payload[0].color }} className="desc">
                {`${payload[0].name !== 'plus' ? 'Abaixo' : 'Acima' }: ${payload[0].value} ${this.setUnitLabel(variable)}`}
              </p>
              {payload[1] && (
                <p style={{ color: payload[1].color }} className="desc">
                  {`Abaixo: ${payload[1].value} ${this.setUnitLabel(variable)}`}
                </p>
              )}
              {payload[0].payload["historic"] && (
                <p className="desc">
                  {`Média: ${Number(payload[0].payload["historic"]).toFixed(1)} ${this.setUnitLabel(variable)}`}
                </p>
              )}
            </div>
          );
        }
      }
    }
    return null;
  }
}

class WeatherGraphic extends Component {
  constructor(props) {
    super(props);

    this.color = undefined;
    this.precAux = false;
  }
  
  getAgriculturalYear(selectedYear, variable = 'temperatura') {
    const firstYear = this.props.data[0][variable]
      .find(({ year }) => year.toString() === selectedYear).data;
    const secondYear = this.props.data[0][variable]
      .find(({ year }) => (year - 1).toString() === selectedYear)?.data;

    const julyStart = ['01/07', 'jul_1', 'jul'];
    const firstIndex = firstYear.findIndex(({ name }) => julyStart.includes(name));
    const lastIndex = secondYear?.findIndex(({ name }) => julyStart.includes(name));

    const firstHalf = firstIndex >= 0 ? firstYear.slice(firstIndex) : [];
    const secondHalf = secondYear ? secondYear.slice(0, lastIndex) : [];

    return [...firstHalf, ...secondHalf];
  }

  populatingYearsData = (firstYear, latYear) => {
    if(firstYear || firstYear === 0) {
      return firstYear
    } else {
      return latYear
    }
  }

  formatValue = (value) => {
    const { visualizationType } = this.props;

    if(visualizationType === 'civil') {
      return value;
    } else {
      return `${value}/${+value + 1}`;
    }
  }
  
  //#region Helper methods
  setColors(index) {
    if (index === 0) {
      return "#FF0000";
    } else if (index === 1) {
      return "#0000FF";
    } else if (index === 2) {
      return "#4daf4a";
    } else if (index === 3) {
      return "#984ea3";
    } else if (index === 4) {
      return "#ff7f00";
    } else if (index === 5) {
      return "#ffff33";
    } else if (index === 6) {
      return "#a65628";
    } else if (index === 7) {
      return "#f781bf";
    } else if (index === 8) {
      return "#999999";
    } else if (index === 9) {
    } else if (index === 10) {
      return "#666666";
    }
  }


  setLegendPayload(years, reportType) {
    let payload = [];
    return payload;
  }

  //if we decide to let the API return the data already in the way we need we have to pass an array of selected years
  setLines(begin, end, compareYears, reportType) {
    let linesArray = [];
    let line = undefined;
    let line2 = undefined;
    let line3 = undefined;
    let lineC = undefined;
    let line2C = undefined;
    let line3C = undefined;
    let selectedYears = [];

    if (begin !== end) {
      selectedYears.push(parseInt(begin, 10));
      selectedYears.push(parseInt(end, 10));
    } else {
      selectedYears.push(parseInt(begin, 10));
    }

    if (reportType === "temperatura") {
      if (this.props.graphicType === "period") {
        line = (
          <Line
            key={"tmax"}
            type="monotone"
            onClick={this.handleLineClick}
            connectNulls={true}
            dataKey={"tmax"}
            strokeWidth={3}
            stroke={this.setColors(0)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        line2 = (
          <Line
            key={"tmin"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            dataKey={"tmin"}
            strokeWidth={3}
            stroke={this.setColors(1)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        line3 = (
          <Line
            key={"tmean"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            dataKey={"tmean"}
            strokeWidth={3}
            stroke={this.setColors(2)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        linesArray.push(line2);
        linesArray.push(line3);
        if (
          begin === new Date().getFullYear().toString() ||
          begin === (new Date().getFullYear() + 1).toString() ||
          this.props.visualizationType === 'agricultural'
        ) {
          line = (
            <Line
              key={"p_tmax"}
              type="monotone"
              connectNulls={true}
              onClick={this.handleLineClick}
              strokeDasharray={"8 5"}
              dataKey={"p_tmax"}
              strokeWidth={2}
              stroke={this.setColors(0)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          line2 = (
            <Line
              key={"p_tmin"}
              type="monotone"
              connectNulls={true}
              onClick={this.handleLineClick}
              strokeDasharray={"8 5"}
              dataKey={"p_tmin"}
              strokeWidth={2}
              stroke={this.setColors(1)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          line3 = (
            <Line
              key={"p_tmean"}
              type="monotone"
              connectNulls={true}
              onClick={this.handleLineClick}
              strokeDasharray={"8 5"}
              dataKey={"p_tmean"}
              strokeWidth={2}
              stroke={this.setColors(2)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          linesArray.push(line2);
          linesArray.push(line3);
        }
        return linesArray;
      } else if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              onClick={this.handleLineClick}
              dataKey={year}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "climatology") {
        switch (this.props.temperatureFilter) {
          case "tmin":
            line2 = (
              <Line
                key={"tmin"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                dataKey={"tmin"}
                strokeWidth={3}
                stroke={this.setColors(1)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            line2C = (
              <Line
                key={"c_tmin"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                strokeDasharray={"8 5"}
                dataKey={"c_tmin"}
                strokeWidth={3}
                stroke={this.setColors(10)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            if (this.props.climatologyCompare) {
              linesArray.push(line2C);
            }
            linesArray.push(line2);
            break;
          case "tmean":
            line3 = (
              <Line
                key={"tmean"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                dataKey={"tmean"}
                strokeWidth={3}
                stroke={this.setColors(2)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            line3C = (
              <Line
                key={"c_tmean"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                strokeDasharray={"8 5"}
                dataKey={"c_tmean"}
                strokeWidth={3}
                stroke={this.setColors(10)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            if (this.props.climatologyCompare) {
              linesArray.push(line3C);
            }
            linesArray.push(line3);
            break;
          case "tmax":
            line = (
              <Line
                key={"tmax"}
                type="monotone"
                onClick={this.handleLineClick}
                connectNulls={true}
                dataKey={"tmax"}
                strokeWidth={3}
                stroke={this.setColors(0)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            lineC = (
              <Line
                key={"c_tmax"}
                type="monotone"
                onClick={this.handleLineClick}
                connectNulls={true}
                strokeDasharray={"8 5"}
                dataKey={"c_tmax"}
                strokeWidth={3}
                stroke={this.setColors(10)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            if (this.props.climatologyCompare) {
              linesArray.push(lineC);
            }
            linesArray.push(line);
            break;
          default:
            line = (
              <Line
                key={"tmax"}
                type="monotone"
                onClick={this.handleLineClick}
                connectNulls={true}
                dataKey={"tmax"}
                strokeWidth={3}
                stroke={this.setColors(0)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            line2 = (
              <Line
                key={"tmin"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                dataKey={"tmin"}
                strokeWidth={3}
                stroke={this.setColors(1)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            line3 = (
              <Line
                key={"tmean"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                dataKey={"tmean"}
                strokeWidth={3}
                stroke={this.setColors(2)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            lineC = (
              <Line
                key={"c_tmax"}
                type="monotone"
                onClick={this.handleLineClick}
                connectNulls={true}
                strokeDasharray={"8 5"}
                dataKey={"c_tmax"}
                strokeWidth={3}
                stroke={this.setColors(10)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            line2C = (
              <Line
                key={"c_tmin"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                strokeDasharray={"8 5"}
                dataKey={"c_tmin"}
                strokeWidth={3}
                stroke={this.setColors(10)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            line3C = (
              <Line
                key={"c_tmean"}
                type="monotone"
                connectNulls={true}
                onClick={this.handleLineClick}
                strokeDasharray={"8 5"}
                dataKey={"c_tmean"}
                strokeWidth={3}
                stroke={this.setColors(10)}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
              />
            );
            if (this.props.climatologyCompare) {
              linesArray.push(lineC);
              linesArray.push(line2C);
              linesArray.push(line3C);
            }
            linesArray.push(line);
            linesArray.push(line2);
            linesArray.push(line3);
            break;
        }

        return linesArray;
      }
    } else if (reportType === "rs") {
      if (this.props.graphicType === "period") {
        line = (
          <Line
            key={"rs"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            dataKey={"rs"}
            strokeWidth={3}
            stroke={this.setColors(0)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        if (
          begin === new Date().getFullYear().toString() ||
          begin === (new Date().getFullYear() + 1).toString() ||
          (
            this.props.visualizationType === 'agricultural' && CURRENT_MONTH < 7 &&
            Number(begin) + 1 === new Date().getFullYear()
          )
        ) {
          line = (
            <Line
              key={"p_rs"}
              type="monotone"
              connectNulls={true}
              onClick={this.handleLineClick}
              dataKey={"p_rs"}
              strokeDasharray={"8 5"}
              strokeWidth={3}
              stroke={this.setColors(0)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
        }
        return linesArray;
      } else if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "accumulate") {
        let constElem = (
          <Area
            dataKey={"Média"}
            fillOpacity={0.5}
            isAnimationActive={false}
            stroke={"#7F7F7F"}
            fill={"#7F7F7F"}
          />
        );
        linesArray.push(constElem);
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });

        return linesArray;
      } else if (this.props.graphicType === "climatology") {
        line = (
          <Line
            key={"rs"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            dataKey={"rs"}
            strokeWidth={3}
            stroke={this.setColors(0)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        lineC = (
          <Line
            key={"c_rs"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            strokeDasharray={"8 5"}
            dataKey={"c_rs"}
            strokeWidth={3}
            stroke={this.setColors(10)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        if (this.props.climatologyCompare) {
          linesArray.push(lineC);
        }
        linesArray.push(line);
        return linesArray;
      }
    } else if (reportType === "gd") {
      if (this.props.graphicType === "period") {
        line = (
          <Line
            key={"gd"}
            type="monotone"
            connectNulls={true}
            dataKey={"gd"}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(0)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        if (
          begin === new Date().getFullYear().toString() ||
          begin === (new Date().getFullYear() + 1).toString() ||
          (CURRENT_MONTH < 7 && begin === (new Date().getFullYear() - 1).toString())
        ) {
          line = (
            <Line
              key={"p_gd"}
              type="monotone"
              connectNulls={true}
              dataKey={"p_gd"}
              strokeDasharray={"8 5"}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(0)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
        }
        return linesArray;
      } else if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "accumulate") {
        let constElem = (
          <Area
            dataKey={"Média"}
            fillOpacity={0.5}
            isAnimationActive={false}
            stroke={"#7F7F7F"}
            fill={"#7F7F7F"}
          />
        );
        linesArray.push(constElem);
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "climatology") {
        line = (
          <Line
            key={"gd"}
            type="monotone"
            connectNulls={true}
            dataKey={"gd"}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(0)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        lineC = (
          <Line
            key={"c_gd"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            strokeDasharray={"8 5"}
            dataKey={"c_gd"}
            strokeWidth={3}
            stroke={this.setColors(10)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        if (this.props.climatologyCompare) {
          linesArray.push(lineC);
        }
        linesArray.push(line);
        return linesArray;
      }
    } else if (reportType === "u2") {
      if (this.props.graphicType === "period") {
        line = (
          <Line
            key={"u2"}
            type="monotone"
            connectNulls={true}
            dataKey={"u2"}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(2)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        if (
          begin === new Date().getFullYear().toString() ||
          begin === (new Date().getFullYear() + 1).toString() ||
          (CURRENT_MONTH < 7 && begin === (new Date().getFullYear() - 1).toString())
        ) {
          line = (
            <Line
              key={"p_u2"}
              type="monotone"
              connectNulls={true}
              dataKey={"p_u2"}
              onClick={this.handleLineClick}
              strokeDasharray={"8 5"}
              strokeWidth={3}
              stroke={this.setColors(2)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
        }
        return linesArray;
      } else if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "climatology") {
        line = (
          <Line
            key={"u2"}
            type="monotone"
            connectNulls={true}
            dataKey={"u2"}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(2)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        lineC = (
          <Line
            key={"c_u2"}
            type="monotone"
            connectNulls={true}
            dataKey={"c_u2"}
            onClick={this.handleLineClick}
            strokeDasharray={"8 5"}
            strokeWidth={3}
            stroke={this.setColors(10)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        if (this.props.climatologyCompare) {
          linesArray.push(lineC);
        }
        linesArray.push(line);
        return linesArray;
      }
    } else if (reportType === "etp") {
      if (this.props.graphicType === "period") {
        line = (
          <Line
            key={"etp"}
            type="monotone"
            connectNulls={true}
            dataKey={"etp"}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(1)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        if (
          begin === new Date().getFullYear().toString() ||
          begin === (new Date().getFullYear() + 1).toString()
        ) {
          line = (
            <Line
              key={"p_etp"}
              type="monotone"
              connectNulls={true}
              dataKey={"p_etp"}
              strokeDasharray={"8 5"}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(1)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
        }
        return linesArray;
      } else if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "accumulate") {
        let constElem = (
          <Area
            dataKey={"Média"}
            fillOpacity={0.5}
            isAnimationActive={false}
            stroke={"#7F7F7F"}
            fill={"#7F7F7F"}
          />
        );
        linesArray.push(constElem);
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "climatology") {
        line = (
          <Line
            key={"etp"}
            type="monotone"
            connectNulls={true}
            dataKey={"etp"}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(1)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        lineC = (
          <Line
            key={"c_etp"}
            type="monotone"
            connectNulls={true}
            dataKey={"c_etp"}
            onClick={this.handleLineClick}
            strokeDasharray={"8 5"}
            strokeWidth={3}
            stroke={this.setColors(10)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        if (this.props.climatologyCompare) {
          linesArray.push(lineC);
        }
        linesArray.push(line);
        return linesArray;
      }
    } else if (reportType === "ur") {
      if (this.props.graphicType === "period") {
        line = (
          <Line
            key={"ur"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            dataKey={"ur"}
            strokeWidth={3}
            stroke={this.setColors(1)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        if (
          begin === new Date().getFullYear().toString() ||
          begin === (new Date().getFullYear() + 1).toString()
        ) {
          line = (
            <Line
              key={"p_ur"}
              type="monotone"
              connectNulls={true}
              dataKey={"p_ur"}
              onClick={this.handleLineClick}
              strokeDasharray={"8 5"}
              strokeWidth={3}
              stroke={this.setColors(1)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
        }
        return linesArray;
      } else if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
        return linesArray;
      } else if (this.props.graphicType === "climatology") {
        line = (
          <Line
            key={"ur"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            dataKey={"ur"}
            strokeWidth={3}
            stroke={this.setColors(1)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        lineC = (
          <Line
            key={"c_ur"}
            type="monotone"
            connectNulls={true}
            onClick={this.handleLineClick}
            strokeDasharray={"8 5"}
            dataKey={"c_ur"}
            strokeWidth={3}
            stroke={this.setColors(10)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        if (this.props.climatologyCompare) {
          linesArray.push(lineC);
        }
        linesArray.push(line);
        return linesArray;
      }
    } else if (reportType === "excdef") {
      let constElem = (
        <Bar
          dataKey={"Média"}
          fillOpacity={0.5}
          isAnimationActive={false}
          stroke={"#7F7F7F"}
          fill={"#7F7F7F"}
        >
          <ErrorBar
            dataKey={"error"}
            width={3}
            strokeWidth={1}
            stroke={"#000"}
          />
        </Bar>
      );
      linesArray.push(constElem);

      compareYears.forEach((year, index) => {
        line = (
          <Line
            key={"show_" + year}
            type="monotone"
            strokeWidth={4}
            onClick={this.handleLineClick}
            connectNulls={true}
            dataKey={year}
            stroke={this.setColors(index)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        line = undefined;
      });
      return linesArray;
    } else if (reportType === "etretp") {
      if (this.props.graphicType === "cmp") {
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });
      } else {
        line = (
          <Line
            key={"show_" + this.props.temperatureControl}
            onClick={this.handleLineClick}
            type="monotone"
            connectNulls={true}
            dataKey={this.props.temperatureControl.toUpperCase()}
            strokeWidth={3}
            stroke={this.setColors(0)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
      }
      return linesArray;
    } else if (reportType === "arm") {
      compareYears.forEach((year, index) => {
        line = (
          <Line
            key={"show_" + year}
            type="monotone"
            connectNulls={true}
            dataKey={year}
            onClick={this.handleLineClick}
            strokeWidth={3}
            stroke={this.setColors(index)}
            dot={false}
            activeDot={false}
            isAnimationActive={false}
          />
        );
        linesArray.push(line);
        line = undefined;
      });

      return linesArray;
    } else if (reportType === "prec") {
      if (this.props.graphicType === "accumulate") {
        let constElem = (
          <Area
            dataKey={"Média"}
            fillOpacity={0.5}
            isAnimationActive={false}
            stroke={"#7F7F7F"}
            fill={"#7F7F7F"}
          />
        );
        linesArray.push(constElem);
        compareYears.forEach((year, index) => {
          line = (
            <Line
              key={"show_" + year}
              type="monotone"
              connectNulls={true}
              dataKey={year}
              onClick={this.handleLineClick}
              strokeWidth={3}
              stroke={this.setColors(index)}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          );
          linesArray.push(line);
          line = undefined;
        });

        return linesArray;
      }
    }
    return null;
  }

  setAreas(begin, end, compareYears) {
    let areasArray = [];
    let area = undefined;
    let selectedYears = [];

    if (begin !== end) {
      selectedYears.push(parseInt(begin, 10));
      selectedYears.push(parseInt(end, 10));
    } else {
      selectedYears.push(parseInt(begin, 10));
    }

    area = (
      <Area
        isAnimationActive={false}
        type="linear"
        dataKey={"ETP"}
        stroke={this.setColors(2)}
        fillOpacity={1}
        fill={this.setColors(2)}
        unit="mm"
        legendType="square"
      />
    );
    areasArray.push(area);
    area = undefined;
    area = (
      <Area
        isAnimationActive={false}
        type="linear"
        dataKey={"ETR"}
        stroke={"#a2ef9d"}
        fillOpacity={0.8}
        fill={"#a2ef9d"}
        unit="mm"
        legendType="square"
      />
    );
    areasArray.push(area);

    return areasArray;
  }

  setMaxBarSize(count) {
    if (count < 225) {
      return (window.innerWidth * 0.48) / count;
    } else if (count >= 225 && count < 315) {
      return 0.1;
    } else if (count >= 315) {
      return 0.001;
    }
  }

  setBarGapSize(count) {
    if (count < 225) {
      return -Math.floor((window.innerWidth * 0.48) / count);
    } else if (count >= 225 && count < 315) {
      return 0.1;
    } else if (count >= 315) {
      return 0.001;
    }
  }

  setBars(begin, end, compareYears, data) {
    let barsArray = [];
    let bar = undefined;
    let count = 0;
    data.forEach((item) => {
      count += 1;
    });
    let maxSize =
      this.props.graphicRange === "daily" ? this.setMaxBarSize(count) : 60;

    if (this.props.graphicType === "period") {
      let key;
      let predictionKey;
      switch (this.props.reportTypes[0]) {
        case "heat_wave":
          key = "heat_wave";
          predictionKey = "p_heat_wave_";
          break;
        case "frost_risk":
          key = "frost_risk";
          predictionKey = "p_frost_risk_";
          break;
        case "rainy_days":
          key = "rainy_days";
          predictionKey = "p_rainy_days_";
          break;
        default:
          key = "p";
          predictionKey = "p_";
          break;
      }

      if (this.props.graphicRange === "annualy") {
        let currentYear = new Date().getFullYear();
        let currentYearAgricultural = `${currentYear.toString().slice(2)}-${(currentYear + 1).toString().slice(2)}`
        data.forEach(({ year, name }) => {
          if (this.setYearOrName(year, name) === currentYear.toString()
            || this.setYearOrName(year, name) === currentYearAgricultural) {
            bar = (
              <Bar
                key={this.setYearOrName(year, name)}
                dataKey={predictionKey}
                isAnimationActive={false}
                onClick={this.onClick}
                stroke={this.setColors(0)}
                fill={this.setColors(0)}
                unit={"mm"}
              />
            );
          } else {
            bar = (
              <Bar
                key={this.setYearOrName(year, name)}
                dataKey={key}
                isAnimationActive={false}
                onClick={this.onClick}
                stroke={this.setColors(1)}
                fill={this.setColors(1)}
                unit={"mm"}
              />
            );
          }
          barsArray.push(bar);
          bar = undefined;
        });
      } else if (this.props.graphicRange === "daily") {
        bar = (
          <Bar
            key={key}
            dataKey={key}
            isAnimationActive={false}
            maxBarSize={maxSize}
            onClick={this.onClick}
            stroke={this.setColors(1)}
            fill={this.setColors(1)}
            unit={"mm"}
          />
        );
        barsArray.push(bar);

        const beginAgricultural = this.props.visualizationType === 'agricultural' && CURRENT_MONTH < 7
          ? Number(begin) + 1
          : begin
        
        bar = (
          <Bar
            key={predictionKey + beginAgricultural}
            dataKey={predictionKey + beginAgricultural.toString()}
            isAnimationActive={false}
            maxBarSize={maxSize}
            onClick={this.onClick}
            stroke={"#FF0000"}
            fill={"#FF0000"}
            unit={"mm"}
          />
        );
        barsArray.push(bar);
      } else {
        bar = (
          <Bar
            key={key}
            dataKey={key}
            isAnimationActive={false}
            maxBarSize={maxSize}
            onClick={this.onClick}
            stroke={this.setColors(1)}
            fill={this.setColors(1)}
            unit={"mm"}
          />
        );
        barsArray.push(bar);
      }
    } else if (this.props.graphicType === "cmp") {
      compareYears.forEach((year, index) => {
        bar = (
          <Bar
            key={year}
            dataKey={year.toString()}
            isAnimationActive={false}
            maxBarSize={maxSize}
            onClick={this.onClick}
            stroke={this.setColors(index)}
            fill={this.setColors(index)}
            unit={"mm"}
          />
        );
        barsArray.push(bar);
        bar = undefined;
      });
    } else if (this.props.graphicType === "climatology") {
      bar = (
        <Bar
          key={"p"}
          dataKey={"p"}
          isAnimationActive={false}
          maxBarSize={maxSize}
          onClick={this.onClick}
          stroke={this.setColors(1)}
          fill={this.setColors(1)}
          unit={"mm"}
        />
      );
      barsArray.push(bar);
      bar = (
        <Bar
          key={"c_p"}
          dataKey={"c_p"}
          isAnimationActive={false}
          maxBarSize={maxSize}
          onClick={this.onClick}
          stroke={this.setColors(0)}
          fill={this.setColors(0)}
          unit={"mm"}
        />
      );
      if (this.props.climatologyCompare) {
        barsArray.push(bar);
      }
    }
    return barsArray;
  }

  setScrollWidth() {
    let years = 1;
    for (var i = this.props.yearBegin; i < this.props.yearEnd; i++) {
      years += 1;
    }
    if (this.props.graphicType === "cmp") {
      return 10000;
    } else {
      return 20000 * Math.ceil(years / 4);
    }
  }

  setGridValues(data) {
    let count = 0;
    data.forEach((item) => {
      count += 1;
    });
    let intervalCurrentYear = 0;
    let tickCountCurrentYear = 0;
    for (let i = 1; i < count; i++) {
      if (count % i === 0) {
        if (count / i >= 10 && count / i <= 36) {
          if (tickCountCurrentYear < count / i) {
            intervalCurrentYear = i;
            tickCountCurrentYear = count / i;
          }
        }
      }
    }
    return [intervalCurrentYear, tickCountCurrentYear];
  }

  findIndexByName(data, name) {
    return data.findIndex(e => e.name === name);
  }

  filterCumulativeData(data, years, startDate, endDate) {
    const [yearOne, yearTwo] = years.map(Number);
    const startIdx = this.findIndexByName(data, startDate) + 1;
    const endIdx = this.findIndexByName(data, endDate) + 1;
  
    const delta = data[startIdx - 1][yearOne];
    const delta2 = data[startIdx - 1][yearTwo];
    const deltaMean = data[startIdx - 1]['Média'];
  
    const transformedData = data.slice(startIdx, endIdx).map(dateData => ({
      ...dateData,
      [yearOne]: Number((dateData[yearOne] - delta).toFixed(1)),
      [yearTwo]: Number((dateData[yearTwo] - delta2).toFixed(1)),
      'Média': Number((dateData['Média'] - deltaMean).toFixed(1)),
    }));
  
    const newData = startIdx === 1 ? data : transformedData;
  
    return newData;
  }

  setYearOrName(year, name) {
    if(year) {
      if (this.props.visualizationType !== 'civil') {
        return (`${year.toString().slice(2)}-${(year + 1).toString().slice(2)}`);
      }
      return year.toString();
    } else {
      return name.toString();
    }
  }

  setUnitLabel() {
    if (this.props.reportTypes.includes("temperatura")) {
      return "(°C)";
    } else if (this.props.reportTypes.includes("rs")) {
      return "(MJ/m²/d)";
    } else if (this.props.reportTypes.includes("gd")) {
      return "(°C dia)";
    } else if (this.props.reportTypes.includes("prec")) {
      return "(mm)";
    } else if (this.props.reportTypes.includes("u2")) {
      return "(m/s)";
    } else if (this.props.reportTypes.includes("ur")) {
      return "(%)";
    } else if (this.props.reportTypes.includes("etp")) {
      if (this.props.graphicType === "anomaly") {
        let unit = "mm/";

        if (this.props.graphicRange === "annualy") unit += "ano";
        else if (this.props.graphicRange === "monthly") unit += "mês";
        else unit += "decêndio";

        return `(${unit})`;
      } else {
        return "(mm/dia)";
      }
    } else if (
      this.props.reportTypes.includes("excdef") ||
      this.props.reportTypes.includes("arm")
    ) {
      return "(mm)";
    } else if (this.props.reportTypes.includes("etretp")) {
      return "(mm)";
    }
  }
  //#endregion

  //#region Render methods
  handleLineClick = (data) => {
    this.color = data.stroke.toString();
  };

  onClick = (e) => {
    if (
      !e ||
      (e.activePayload &&
        e.activePayload[0].payload[this.props.reportTypes[0]] === undefined &&
        ["decendial", "monthly"].includes(this.props.graphicRange))
    ) {
      return;
    }

    let newDay = {};
    let initial_date = "";
    let final_date = "";
    let label = "";
    let variable = "";
    let dayAndMonth = undefined;
    if (this.color !== undefined) {
      e.activePayload.forEach((line) => {
        if (line["stroke"] === this.color) {
          variable = line["name"].toString();
        }
      });
      if (variable.split("_").length > 1) {
        variable = variable.split("_")[1];
      }
      if (this.props.graphicRange === "daily") {
        if (this.props.graphicType === "period") {
          newDay["label"] = e.activeLabel;
          newDay["year"] = e.activePayload[0].payload["year"];
        } else if (this.props.graphicType === "cmp") {
          newDay["year"] = variable;
          if (
            this.props.reportTypes.includes("temperatura") ||
            this.props.reportTypes.includes("etretp")
          ) {
            variable = this.props.temperatureControl;
          } else {
            variable = this.props.reportTypes[0];
          }
          newDay["label"] = e.activeLabel;
        }
        dayAndMonth = newDay["label"].split("/");
        initial_date =
          newDay["year"].toString() +
          "-" +
          dayAndMonth[1] +
          "-" +
          dayAndMonth[0];
        final_date =
          newDay["year"].toString() +
          "-" +
          dayAndMonth[1] +
          "-" +
          dayAndMonth[0];
      } else if (this.props.graphicRange === "decendial") {
        dayAndMonth = e.activeLabel.split("_");
        newDay["label"] = e.activeLabel;
        let year = e.activePayload[0].payload["year"];
        newDay["year"] = year;
        if (this.props.graphicType === "cmp") {
          year = variable;
          if (
            this.props.reportTypes.includes("temperatura") ||
            this.props.reportTypes.includes("etretp") ||
            this.props.reportTypes.includes("excdef")
          ) {
            variable = this.props.temperatureControl;
          } else {
            variable = this.props.reportTypes[0];
          }
          newDay["label"] = e.activeLabel;
          newDay["year"] = year;
        }

        let days = setDates(
          monthToNumber(dayAndMonth[0]),
          dayAndMonth[1],
          year
        );
        initial_date = days[0];
        final_date = days[1];
      } else if (this.props.graphicRange === "monthly") {
        if (this.props.graphicType === "period") {
          newDay["label"] = e.activeLabel;
          newDay["year"] = e.activePayload[0].payload["year"];
        } else if (this.props.graphicType === "cmp") {
          newDay["label"] = e.activeLabel;
          newDay["year"] = variable;
          if (
            this.props.reportTypes.includes("temperatura") ||
            this.props.reportTypes.includes("etretp")
          ) {
            variable = this.props.temperatureControl;
          } else {
            variable = this.props.reportTypes[0];
          }
        }

        let days = setDates(monthToNumber(e.activeLabel), "4", this.props.yearBegin);
        initial_date = days[0];
        final_date = days[1];
        label = numberToMonth(e.activeLabel);
      } else if (this.props.graphicRange === "annualy") {
        newDay["label"] = "";
        newDay["year"] = e.activeLabel;
        if (
          this.props.reportTypes.includes("temperatura") ||
          this.props.reportTypes.includes("etretp")
        ) {
          variable = this.props.temperatureControl;
        } else {
          variable = this.props.reportTypes[0];
        }
        let days = setDates("", "5", newDay["year"]);
        initial_date = days[0];
        final_date = days[1];
      }
    } else if (
      this.props.reportTypes.includes("prec") &&
      this.props.graphicType !== "anomaly"
    ) {
      variable = "prec";
      let year = Object.keys(e)[0];
      if (this.props.graphicRange === "daily") {
        if (year === "chartX") {
          if (this.precAux === true) {
            this.precAux = false;
          } else {
            year = e["activePayload"][0]["payload"]["year"];
            if (year === undefined) {
              Object.keys(e["activePayload"][0]["payload"]).forEach((key) => {
                if (key.search("p_") !== -1) {
                  year = key.split("_")[1];
                }
              });
            }
            dayAndMonth = e["activeLabel"].split("/");
            newDay["label"] = e.activeLabel;
            newDay["year"] = year.toString();
            initial_date =
              year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0];
            final_date =
              year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0];
          }
        } else {
          if (this.props.graphicType === "cmp") {
            year =
              e["value"] === e[Object.keys(e)[0]] ? year : Object.keys(e)[1];
          }
          if (year === "name") {
            year = Object.keys(e)[1].split("_")[1];
          }
          dayAndMonth = e["name"].split("/");
          newDay["label"] = e.name;
          newDay["year"] = year.toString();
          initial_date =
            year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0];
          final_date =
            year.toString() + "-" + dayAndMonth[1] + "-" + dayAndMonth[0];
          this.precAux = true;
        }
      } else if (
        this.props.graphicRange === "decendial" ||
        this.props.graphicRange === "monthly" ||
        this.props.graphicRange === "annualy"
      ) {
        let dates = "";
        if (year === "chartX") {
          if (this.precAux === true) {
            this.precAux = false;
          } else {
            year = e["activePayload"][0]["payload"]["year"];
            dayAndMonth = e["activeLabel"].split("_");
            newDay["label"] = e.activeLabel;
            newDay["year"] = year.toString();
            if (this.props.graphicRange === "decendial") {
              dates = setDates(
                monthToNumber(dayAndMonth[0]),
                dayAndMonth[1],
                year
              );
            } else if (this.props.graphicRange === "monthly") {
              dates = setDates(monthToNumber(dayAndMonth[0]), "4", year);
            } else if (this.props.graphicRange === "annualy") {
              dates = setDates("", "5", year);
            }

            initial_date = dates[0];
            final_date = dates[1];
          }
        } else {
          if (this.props.graphicType === "cmp") {
            year =
              e["value"] === e[Object.keys(e)[0]] ? year : Object.keys(e)[1];
          }
          if (year === "name") {
            year = this.props.yearBegin;
          }
          dayAndMonth = e["name"].split("_");
          newDay["label"] = e.name;
          newDay["year"] = year.toString();
          if (this.props.graphicRange === "decendial") {
            dates = setDates(
              monthToNumber(dayAndMonth[0]),
              dayAndMonth[1],
              year
            );
          } else if (this.props.graphicRange === "monthly") {
            dates = setDates(monthToNumber(dayAndMonth[0]), "4", year);
          } else if (this.props.graphicRange === "annualy") {
            dates = setDates("", "5", year);
          }

          initial_date = dates[0];
          final_date = dates[1];
          this.precAux = true;
        }
      }
    } else if (this.props.graphicType === "anomaly") {
      let dates = "";
      variable = this.props.reportTypes[0];
      if (variable === "temperatura") {
        variable = this.props.temperatureControl;
      }
      let year = Object.keys(e)[0];
      if (year === "chartX") {
        if (this.precAux === true) {
          this.precAux = false;
        } else {
          year = this.props.yearBegin;
          dayAndMonth = e["activeLabel"].split("_");
          newDay["label"] = e.activeLabel;
          newDay["year"] = year.toString();
          if (this.props.graphicRange === "decendial") {
            dates = setDates(
              monthToNumber(dayAndMonth[0]),
              dayAndMonth[1],
              year
            );
          } else if (this.props.graphicRange === "monthly") {
            dates = setDates(monthToNumber(dayAndMonth[0]), "4", year);
          } else if (this.props.graphicRange === "annualy") {
            dates = setDates("", "5", year);
          }

          initial_date = dates[0];
          final_date = dates[1];
        }
      } else {
        year = this.props.yearBegin;

        if (this.props.graphicRange !== "annualy") {
          dayAndMonth = e["name"].split("_");
          newDay["label"] = e.name;
        } else {
          this.props.setMapYear(e.name.toString());
        }
        newDay["year"] =
          this.props.graphicRange === "annualy" ? e.name : year.toString();
        if (this.props.graphicRange === "decendial") {
          dates = setDates(monthToNumber(dayAndMonth[0]), dayAndMonth[1], year);
        } else if (this.props.graphicRange === "monthly") {
          dates = setDates(monthToNumber(dayAndMonth[0]), "4", year);
        } else if (this.props.graphicRange === "annualy") {
          dates = setDates("", "5", e.name);
        }

        initial_date = dates[0];
        final_date = dates[1];
        this.precAux = true;
      }
    }
    if (initial_date !== "" && final_date !== "") {
      let attrs = {
        initial_date: initial_date,
        final_date: final_date,
        type: this.props.graphicType,
        range: this.props.graphicRange,
        cad: this.props.lastUsedCad,
        tb: this.props.tBasal,
      };

      newDay["initial_date"] = initial_date;
      newDay["final_date"] = final_date;

      if (label) newDay["label"] = label;

      newDay["variable"] = variable.toLowerCase();
      this.props.setMapDay(newDay);
      this.props.generatingMapData();
      this.props.generateMapData(variable.toLowerCase(), attrs);
      this.props.toggleLeftLoader();
      this.color = undefined;
    }
  };

  //Return min value, max value, number of ticks in graph
  setYDomain = (variable, range, type, subVariable) => {
    let domain = [];
    if (variable === "temperatura") {
      if (type === "anomaly") {
        domain = [-10, 10, 11];
      } else {
        domain = [0, 40, 10];
      }
    } else if (variable === "rs") {
      if (type === "anomaly") {
        if (range === "decendial") {
          domain = [-10, 10, 11];
        } else {
          domain = [-5, 5, 11];
        }
      } else {
        domain = [0, 35, 9];
      }
    } else if (variable === "gd") {
      if (type === "anomaly") {
        if (range === "decendial") {
          domain = [-50, 50, 21];
        } else if (range === "monthly") {
          domain = [-200, 200, 9];
        } else {
          domain = [-600, 600, 21];
        }
      } else {
        if (range === "daily") {
          domain = [0, 30, 7];
        } else if (range === "decendial") {
          domain = [0, 300, 11];
        } else if (range === "monthly") {
          domain = [0, 800, 11];
        } else if (range === "annualy") {
          domain = [0, 7000, 11];
        }
      }
    } else if (variable === "u2") {
      if (type === "anomaly") {
        domain = [-1, 1, 5];
      } else {
        domain = [0, 5, 12];
      }
    } else if (variable === "ur") {
      if (type === "anomaly") {
        domain = [-30, 30, 7];
      } else {
        domain = [0, 100, 11];
      }
    } else if (variable === "etp") {
      if (type === "anomaly") {
        if (range === "decendial") {
          domain = [-20, 20, 9];
        } else if (range === "monthly") {
          domain = [-50, 50, 11];
        } else {
          domain = [-100, 100, 11];
        }
      } else {
        if (range === "daily") {
          domain = [0, 10, 11];
        } else if (range === "decendial") {
          domain = [0, 100, 11];
        } else if (range === "monthly") {
          domain = [0, 300, 11];
        } else if (range === "annualy") {
          domain = [0, 1600, 9];
        }
      }
    } else if (variable === "prec") {
      if (type === "climatology") {
        if (range === "decendial") {
          domain = [0, 150, 9];
        } else if (range === "monthly") {
          domain = [0, 500, 9];
        } else {
          domain = [0, 30, 9];
        }
      } else if (type === "anomaly") {
        if (range === "decendial") {
          domain = [-200, 200, 9];
        } else if (range === "monthly") {
          domain = [-400, 400, 9];
        } else {
          domain = [-1000, 1000, 21];
        }
      } else {
        if (range === "daily") {
          domain = [0, 80, 9];
        } else if (range === "decendial") {
          domain = [0, 250, 13];
        } else if (range === "monthly") {
          domain = [0, 700, 8];
        } else if (range === "annualy") {
          domain = [0, 4000, 12];
        }
      }
    } else if (["heat_wave", "frost_risk"].includes(variable)) {
      if (range === "daily") {
        domain = [0, 1, 2];
      } else if (range === "decendial") {
        domain = [0, 11, 5];
      } else if (range === "monthly") {
        domain = [0, 31, 6];
      } else {
        domain = [0, 45, 10];
      }
    } else if (["rainy_days"].includes(variable)) {
      if (range === "daily") {
        domain = [0, 1, 2];
      } else if (range === "decendial") {
        domain = [0, 11, 5];
      } else if (range === "monthly") {
        domain = [0, 31, 6];
      } else {
        domain = [0, 200, 10];
      }
    } else if (variable === "excdef") {
      if (subVariable === "def") {
        domain = [0, 100, 12];
      } else {
        domain = [0, 200, 12];
      }
    } else if (variable === "arm") {
      const lastUsedCad = this.props.lastUsedCad.toString();
      if (lastUsedCad === "25") {
        domain = [0, 30, 8];
      } else if (lastUsedCad === "50") {
        domain = [0, 60, 14];
      } else if (lastUsedCad === "75") {
        domain = [0, 80, 20];
      } else if (lastUsedCad === "100") {
        domain = [0, 110, 12];
      } else if (lastUsedCad === "125") {
        domain = [0, 130, 14];
      } else if (lastUsedCad === "150") {
        domain = [0, 160, 9];
      } else if (lastUsedCad === "200") {
        domain = [0, 220, 12];
      } else if (lastUsedCad === "250") {
        domain = [0, 275, 13];
      } else {
        domain = [0, 330, 14];
      }
    } else if (variable === "etretp") {
      if (type === "anomaly") {
        if (range === "decendial") {
          domain = [-20, 20, 9];
        } else if (range === "monthly") {
          domain = [-50, 50, 11];
        } else {
          domain = [-100, 100, 11];
        }
      }
    }
    return domain;
  };

  setY = (data, variable, type, compareYears, singleYear) => {
    let observedMax = 0;
    let observedMax2 = 0;
    let observedMin = 0;
    let observedMin2 = 0;
    let predictionMax = 0;
    let predictionMin = 0;
    let numberOfTicks = 0;
    let years = [];

    for (let i = 0; i <= new Date().getFullYear() - 1980; i++) {
      years.push(new Date().getFullYear() - i);
    }

    if (this.props.graphicType === "period") {
      if (variable === "temperatura") {
        observedMax = data.reduce(
          (max, p) => (p.tmax > max ? p.tmax : max),
          data[0].tmax
        );
        observedMin = data.reduce(
          (min, p) => (p.tmin < min ? p.tmin : min),
          data[0].tmin
        );
        predictionMax = data.reduce(
          (max, p) => (p.p_tmax > max ? p.p_tmax : max),
          data[0].p_tmax
        );
        predictionMin = data.reduce(
          (min, p) => (p.p_tmin < min ? p.p_tmin : min),
          data[0].p_tmin
        );
      } else if (variable === "rs") {
        observedMax = data.reduce(
          (max, p) => (p.rs > max ? p.rs : max),
          data[0].rs
        );
        observedMin = data.reduce(
          (min, p) => (p.rs < min ? p.rs : min),
          data[0].rs
        );
        predictionMax = data.reduce(
          (max, p) =>
            p.p_rs !== undefined ? (p.p_rs > max ? p.p_rs : max) : 0,
          data[0].p_rs
        );
        predictionMin = data.reduce(
          (min, p) =>
            p.p_rs !== undefined ? (p.p_rs < min ? p.p_rs : min) : 999,
          data[0].p_rs
        );
      } else if (variable === "gd") {
        observedMax = data.reduce(
          (max, p) => (p.gd > max ? p.gd : max),
          data[0].gd
        );
        observedMin = data.reduce(
          (min, p) => (p.gd < min ? p.gd : min),
          data[0].gd
        );
        predictionMax = data.reduce(
          (max, p) =>
            p.p_gd !== undefined ? (p.p_gd > max ? p.p_gd : max) : 0,
          data[0].p_gd
        );
        predictionMin = data.reduce(
          (min, p) =>
            p.p_gd !== undefined ? (p.p_gd < min ? p.p_gd : min) : 9999,
          data[0].p_gd
        );
      } else if (variable === "u2") {
        observedMax = data.reduce(
          (max, p) => (p.u2 > max ? p.u2 : max),
          data[0].u2
        );
        observedMin = data.reduce(
          (min, p) => (p.u2 < min ? p.u2 : min),
          data[0].u2
        );
        predictionMax = data.reduce(
          (max, p) =>
            p.p_u2 !== undefined ? (p.p_u2 > max ? p.p_u2 : max) : 0,
          data[0].p_u2
        );
        predictionMin = data.reduce(
          (min, p) =>
            p.p_u2 !== undefined ? (p.p_u2 < min ? p.p_u2 : min) : 999,
          data[0].p_u2
        );
      } else if (variable === "prec") {
        if (this.props.graphicRange !== "annualy") {
          observedMax = data.reduce(
            (max, p) => (p["p"] > max ? p["p"] : max),
            data[0]["p"]
          );
          observedMin = data.reduce(
            (min, p) => (p["p"] < min ? p["p"] : min),
            data[0]["p"]
          );
          predictionMax = data.reduce(
            (max, p) =>
              p["p_" + singleYear.toString()] !== undefined
                ? p["p_" + singleYear.toString()] > max
                  ? p["p_" + singleYear.toString()]
                  : max
                : 0,
            data[0]["p_" + singleYear.toString()]
          );
          predictionMin = data.reduce(
            (min, p) =>
              p["p_" + singleYear.toString()] !== undefined
                ? p["p_" + singleYear.toString()] < min
                  ? p["p_" + singleYear.toString()]
                  : min
                : 999,
            data[0]["p_" + singleYear.toString()]
          );
        } else {
          observedMin = 9999;

          data.forEach((row) => {
            if (observedMax < row[Object.keys(row)[0]]) {
              observedMax = row[Object.keys(row)[0]];
            }
            if (observedMin > row[Object.keys(row)[0]]) {
              observedMin = row[Object.keys(row)[0]];
            }
          });
        }
      } else if (variable === "ur") {
        observedMax = data.reduce(
          (max, p) => (p.ur > max ? p.ur : max),
          data[0].ur
        );
        observedMin = data.reduce(
          (min, p) => (p.ur < min ? p.ur : min),
          data[0].ur
        );
        predictionMax = data.reduce(
          (max, p) =>
            p.p_ur !== undefined ? (p.p_ur > max ? p.p_ur : max) : 0,
          data[0].p_ur
        );
        predictionMin = data.reduce(
          (min, p) =>
            p.p_ur !== undefined ? (p.p_ur < min ? p.p_ur : min) : 999,
          data[0].p_ur
        );
      } else if (variable === "etp") {
        observedMax = data.reduce(
          (max, p) => (p.etp > max ? p.etp : max),
          data[0].etp
        );
        observedMin = data.reduce(
          (min, p) => (p.etp < min ? p.etp : min),
          data[0].etp
        );
        predictionMax = data.reduce(
          (max, p) =>
            p.p_etp !== undefined ? (p.p_etp > max ? p.p_etp : max) : 0,
          data[0].p_etp
        );
        predictionMin = data.reduce(
          (min, p) =>
            p.p_etp !== undefined ? (p.p_etp < min ? p.p_etp : min) : 999,
          data[0].p_etp
        );
      }

      if (observedMax < predictionMax) {
        observedMax = predictionMax;
      }
      if (observedMin > predictionMin) {
        observedMin = predictionMin;
      }
    } else if (this.props.graphicType === "cmp") {
      observedMax = data.reduce(
        (max, p) =>
          p[compareYears[0].toString()] > max
            ? p[compareYears[0].toString()]
            : max,
        data[0][compareYears[0].toString()]
      );
      observedMin = data.reduce(
        (min, p) =>
          p[compareYears[0].toString()] < min
            ? p[compareYears[0].toString()]
            : min,
        data[0][compareYears[0].toString()]
      );
      observedMax2 = data.reduce(
        (max, p) =>
          p[compareYears[1].toString()] > max
            ? p[compareYears[1].toString()]
            : max,
        data[0][compareYears[0].toString()]
      );
      observedMin2 = data.reduce(
        (min, p) =>
          p[compareYears[0].toString()] < min
            ? p[compareYears[1].toString()]
            : min,
        data[0][compareYears[0].toString()]
      );

      if (observedMax < observedMax2) {
        observedMax = observedMax2;
      }
      if (observedMin > observedMin2) {
        observedMin = observedMin2;
      }
    } else if (this.props.graphicType === "anomaly") {
      observedMax = data.reduce(
        (max, p) =>
          p.plus !== undefined ? (p.plus > max ? p.plus : max) : max,
        0
      );
      observedMin = data.reduce(
        (min, p) =>
          p.minus !== undefined ? (p.minus < min ? p.minus : min) : min,
        999
      );
    }

    if (variable === "prec" || variable === "gd") {
      if (this.props.graphicRange === "daily") {
        observedMax = Math.ceil(observedMax / 5) * 5;
        observedMin = Math.floor(observedMin / 5) * 5;
        numberOfTicks = (observedMax - observedMin) / 5;
      } else if (this.props.graphicRange === "decendial") {
        observedMax = Math.ceil(observedMax / 10) * 10;
        observedMin = Math.floor(observedMin / 10) * 10;
        numberOfTicks = (observedMax - observedMin) / 10;
      } else if (this.props.graphicRange === "monthly") {
        observedMax = Math.ceil(observedMax / 25) * 25;
        observedMin = Math.floor(observedMin / 25) * 25;
        numberOfTicks = (observedMax - observedMin) / 25;
      } else if (this.props.graphicRange === "annualy") {
        observedMax = Math.ceil(observedMax / 100) * 100;
        observedMin = Math.floor(observedMin / 100) * 100;
        numberOfTicks = (observedMax - observedMin) / 100;
      }
    } else {
      observedMax = Math.ceil(observedMax / 5) * 5;
      observedMin = Math.floor(observedMin / 5) * 5;

      numberOfTicks = (observedMax - observedMin) / 5;
    }

    if (variable === "u2") {
      numberOfTicks = 5;
    }

    return [observedMax, observedMin, numberOfTicks + 1];
  };

  accumulatedRainGraphYAxisDinamic = (data) => {
    const lastDayValues = Object.values(data[data.length -1])
    lastDayValues.splice(-2,1);
    const value = Math.max(...lastDayValues)
    const valueRound = (Math.round((value + (0.2 * value))/100))*100
    return valueRound;
  }

  //the variable years must be passed as a prop later on, this is here only for tests while the dropdown is not ready
  setGraphic = (data, width, variable) => {
    if (this.props.graphicType === "anomaly") {
      let barGap =
        this.props.graphicRange === "decendial"
          ? -18
          : this.props.graphicRange === "annualy"
          ? -12
          : -46;
      let domain = this.setYDomain(
        this.props.reportTypes[0],
        this.props.graphicRange,
        this.props.graphicType,
        this.props.temperatureControl
      );
      return (
        <ResponsiveContainer width="100%" height="95%">
          <BarChart data={data} onClick={this.onClick} barGap={barGap}>
            <XAxis
              dataKey="name"
              tick={
                <CustomizedAxisTick graphicRange={this.props.graphicRange} />
              }
              interval={0}
              height={45}
              tickCount={36}
            />
            <YAxis
              interval={0}
              tickCount={domain[2]}
              tick={{ fontSize: "12px" }}
              domain={[domain[0], domain[1]]}
            >
              <Label
                angle={-90}
                value={this.setUnitLabel()}
                position="insideLeft"
                offset={10}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <CartesianGrid strokeDasharray="1 1" />
            <Tooltip
              content={
                <CustomTooltip
                  variable={this.props.reportTypes[0]}
                  weatherType={this.props.graphicType}
                  weatherRange={this.props.graphicRange}
                  visualizationType={this.props.visualizationType}
                />
              }
              showTooltips={this.props.showTooltips}
            />
            <Legend
              margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
              align="center"
              verticalAlign="top"
              payload={[
                {
                  value: "Anomalia +",
                  type: "square",
                  id: "plus",
                  color:
                    this.props.reportTypes[0] === "temperatura"
                      ? "#FF0000"
                      : "#0000FF",
                },
                {
                  value: "Anomalia -",
                  type: "square",
                  id: "minus",
                  color:
                    this.props.reportTypes[0] === "temperatura"
                      ? "#0000FF"
                      : "#FF0000",
                },
              ]}
            />
            <ReferenceLine y={0} stroke="black" />
            <Bar
              dataKey={"plus"}
              isAnimationActive={false}
              onClick={this.onClick}
              stroke={this.setColors(
                this.props.reportTypes[0] === "temperatura" ? 0 : 1
              )}
              fill={this.setColors(
                this.props.reportTypes[0] === "temperatura" ? 0 : 1
              )}
            />
            <Bar
              dataKey={"minus"}
              isAnimationActive={false}
              onClick={this.onClick}
              stroke={this.setColors(
                this.props.reportTypes[0] === "temperatura" ? 1 : 0
              )}
              fill={this.setColors(
                this.props.reportTypes[0] === "temperatura" ? 1 : 0
              )}
            />
          </BarChart>
        </ResponsiveContainer>
      );
    } else if (
      this.props.reportTypes.includes("ur") ||
      this.props.reportTypes.includes("etp") ||
      this.props.reportTypes.includes("u2") ||
      this.props.reportTypes.includes("temperatura") ||
      this.props.reportTypes.includes("rs") ||
      this.props.reportTypes.includes("gd")
    ) {
      let lines = this.setLines(
        this.props.yearBegin,
        this.props.yearEnd,
        this.props.compareYears,
        this.props.reportTypes[0]
      );
      let domain = this.setYDomain(
        this.props.reportTypes[0],
        this.props.graphicRange,
        this.props.graphicType,
        this.props.temperatureControl
      );
      let interval =
        this.props.graphicRange === "daily" ? "preserveStartEnd" : 0;
      let tickCount = 36;
      let currentYearGrid = this.setGridValues(data);
      if (currentYearGrid[0] !== 0 && this.props.graphicRange === "daily") {
        interval = currentYearGrid[0];
        tickCount = currentYearGrid[1];
      }
      if (this.props.graphicType === "accumulate") {
        return (
          <ResponsiveContainer width="100%" height="95%">
            <ComposedChart
              data={data}
              onClick={this.onClick}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="name"
                tick={
                  <CustomizedAxisTick graphicRange={this.props.graphicRange} />
                }
                interval={interval}
                height={45}
                tickCount={tickCount}
              />
              <YAxis
                interval={0}
                tick={{ fontSize: "1em" }}
                tickCount={15}
                domain={[0, "auto"]}
              >
                <Label
                  angle={-90}
                  value={this.setUnitLabel()}
                  position="insideLeft"
                  offset={10}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <Tooltip
                content={
                  <CustomTooltip
                    variable={this.props.reportTypes[0]}
                    weatherType={this.props.graphicType}
                    weatherRange={this.props.graphicRange}
                    visualizationType={this.props.visualizationType}
                  />
                }
                showTooltips={this.props.showTooltips}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Legend
                margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                align="center"
                verticalAlign="top"
              />
              {lines.map((line) => {
                return line;
              })}
            </ComposedChart>
          </ResponsiveContainer>
        );
      } else {
        let legend = [];
        if (this.props.graphicType === "climatology") {
          switch (this.props.reportTypes[0]) {
            case "temperatura":
              legend = [
                { value: "Máxima", type: "line", id: "tmax", color: "#FF0000" },
                { value: "Média", type: "line", id: "tmed", color: "#4daf4a" },
                { value: "Mínima", type: "line", id: "tmin", color: "#377eb8" },
                {
                  value: this.formatValue(this.props.yearBegin),
                  type: "line",
                  id: "year",
                  color: "#666666",
                },
              ];
              break;
            case "rs":
            case "gd":
              legend = [
                {
                  value: "Climatologia",
                  type: "line",
                  id: "climatology",
                  color: "#FF0000",
                },
                {
                  value: this.formatValue(this.props.yearBegin),
                  type: "line",
                  id: "year",
                  color: "#666666",
                },
              ];
              break;
            case "u2":
              legend = [
                {
                  value: "Climatologia",
                  type: "line",
                  id: "climatology",
                  color: "#4daf4a",
                },
                {
                  value: this.formatValue(this.props.yearBegin),
                  type: "line",
                  id: "year",
                  color: "#666666",
                },
              ];
              break;
            case "ur":
            case "etp":
              legend = [
                {
                  value: "Climatologia",
                  type: "line",
                  id: "climatology",
                  color: "#0000FF",
                },
                {
                  value: this.formatValue(this.props.yearBegin),
                  type: "line",
                  id: "year",
                  color: "#666666",
                },
              ];
              break;
            default:
              legend = [];
          }
        } else if (this.props.reportTypes.includes("temperatura")) {
          if (this.props.graphicType === "cmp") {
            legend = [
              {
                value: this.props.visualizationType === 'civil'
                  ? this.props.compareYears[0]
                  : `${this.props.compareYears[0]}-${Number(this.props.compareYears[0]) + 1}`,
                type: 'line',
                id: 'year_one',
                color: "#FF0000",
              },
              {
                value: this.props.visualizationType === 'civil'
                  ? this.props.compareYears[1]
                  : `${this.props.compareYears[1]}-${Number(this.props.compareYears[1]) + 1}`,
                type: 'line',
                id: 'year_two',
                color: "#3025c4",
              },
            ]
          } else {
            legend = [
              { value: "Máxima", type: "line", id: "tmax", color: "#FF0000" },
              { value: "Média", type: "line", id: "tmed", color: "#4daf4a" },
              { value: "Mínima", type: "line", id: "tmin", color: "#377eb8" },
            ];
          }
        } else if (
          this.props.graphicType === "cmp" ||
          this.props.graphicType === "accumulate"
        ) {
          legend = [
            {
              value: this.props.visualizationType === 'civil'
                ? this.props.compareYears[0]
                : `${this.props.compareYears[0]}-${Number(this.props.compareYears[0]) + 1}`,
              type: 'line',
              id: 'year_one',
              color: "#FF0000",
            },
            {
              value: this.props.visualizationType === 'civil'
                ? this.props.compareYears[1]
                : `${this.props.compareYears[1]}-${Number(this.props.compareYears[1]) + 1}`,
              type: 'line',
              id: 'year_two',
              color: "#3025c4",
            },
          ]
        }
        return (
          <ResponsiveContainer width="100%" height="95%">
            <LineChart
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              onClick={this.onClick}
            >
              <XAxis
                dataKey="name"
                tick={
                  <CustomizedAxisTick graphicRange={this.props.graphicRange} />
                }
                interval={interval}
                height={45}
                tickCount={tickCount}
              />
              <YAxis
                interval={0}
                tick={{ fontSize: "1em" }}
                tickCount={domain[2]}
                domain={[domain[0], domain[1]]}
              >
                <Label
                  angle={-90}
                  value={this.setUnitLabel()}
                  position="insideLeft"
                  offset={10}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <Tooltip
                content={
                  <CustomTooltip
                    variable={this.props.reportTypes[0]}
                    weatherType={this.props.graphicType}
                    weatherRange={this.props.graphicRange}
                    visualizationType={this.props.visualizationType}
                  />
                }
                yearBegin={this.props.yearBegin}
                showTooltips={this.props.showTooltips}
              />
              <CartesianGrid strokeDasharray="1 1" />
              <Legend
                margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                align="center"
                verticalAlign="top"
                payload={legend}
              />
              {lines.map((line) => {
                return line;
              })}
            </LineChart>
          </ResponsiveContainer>
        );
      }
    } else if (
      ["prec", "heat_wave", "frost_risk", "rainy_days"].includes(
        this.props.reportTypes[0]
      )
    ) {
      let bars = this.setBars(
        this.props.yearBegin,
        this.props.yearEnd,
        this.props.compareYears,
        data
      );
      let lines = this.setLines(
        this.props.yearBegin,
        this.props.yearEnd,
        this.props.compareYears,
        this.props.reportTypes[0]
      );
      let count = 0;
      data.forEach((item) => {
        count += 1;
      });
      let barGap =
        this.props.graphicType === "period"
          ? this.props.graphicRange === "annualy"
            ? data.length < 30
              ? -36
              : -12
            : this.props.graphicRange === "daily"
            ? this.setBarGapSize(count)
            : 10
          : 6;
      let interval =
        this.props.graphicRange === "daily" ? "preserveStartEnd" : 0;
      let domain = this.setYDomain(
        this.props.reportTypes[0],
        this.props.graphicRange,
        this.props.graphicType,
        this.props.temperatureControl
      );
      let tickCount = 36;
      let currentYearGrid = this.setGridValues(data);
      if (currentYearGrid[0] !== 0 && this.props.graphicRange === "daily") {
        interval = currentYearGrid[0];
        tickCount = currentYearGrid[1];
      }
      if (this.props.graphicType !== "accumulate") {
        let legend = [];
        if (this.props.graphicType === "climatology") {
          legend = [
            {
              value: "Climatologia",
              type: "bar",
              id: "climatology",
              color: "#0000FF",
            },
            {
              value: this.formatValue(this.props.yearBegin),
              type: "bar",
              id: "year",
              color: "#FF0000",
            },
          ];
        }
        else if (this.props.graphicType === "cmp") {
          legend = [
            {
              value: this.props.visualizationType === 'civil'
                ? this.props.compareYears[0]
                : `${this.props.compareYears[0]}-${Number(this.props.compareYears[0]) + 1}`,
              type: 'square',
              id: 'year_one',
              color: "#FF0000",
            },
            {
              value: this.props.visualizationType === 'civil'
                ? this.props.compareYears[1]
                : `${this.props.compareYears[1]}-${Number(this.props.compareYears[1]) + 1}`,
              type: 'square',
              id: 'year_two',
              color: "#3025c4",
            },
          ]
        }
        return (
          <ResponsiveContainer width="100%" height="95%">
            <BarChart data={data} onClick={this.onClick} barGap={barGap}>
              <XAxis
                interval={interval}
                dataKey="name"
                tick={
                  <CustomizedAxisTick graphicRange={this.props.graphicRange} />
                }
                height={45}
                tickCount={tickCount}
              />
              <YAxis
                interval={0}
                tickCount={domain[2]}
                tick={{ fontSize: "0.85rem" }}
                domain={[domain[0], domain[1]]}
              >
                <Label
                  angle={-90}
                  value={this.setUnitLabel()}
                  position="insideLeft"
                  offset={10}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <CartesianGrid strokeDasharray="1 1" />
              <Tooltip
                content={
                  <CustomTooltip
                    variable={this.props.reportTypes[0]}
                    weatherType={this.props.graphicType}
                    weatherRange={this.props.graphicRange}
                    visualizationType={this.props.visualizationType}
                  />
                }
                showTooltips={this.props.showTooltips}
                yearBegin={this.props.yearBegin}
              />
              {bars.map((bar) => {
                return bar;
              })}
              <Legend
                margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                align="center"
                verticalAlign="top"
                payload={legend}
              />
            </BarChart>
          </ResponsiveContainer>
        );
      } else {
        let legend = [];

        if(this.props.graphicType === 'accumulate') {
          legend = [
            {
              value: "Média",
              type: "line",
              id: "media",
              color: "#7f7f7f",
            },
            {
              value: this.props.visualizationType === 'civil'
                ? this.props.compareYears[0]
                : `${this.props.compareYears[0]}-${Number(this.props.compareYears[0]) + 1}`,
              type: 'line',
              id: 'year_one',
              color: "#FF0000",
            },
            {
              value: this.props.visualizationType === 'civil'
                ? this.props.compareYears[1]
                : `${this.props.compareYears[1]}-${Number(this.props.compareYears[1]) + 1}`,
              type: 'line',
              id: 'year_two',
              color: "#3025c4",
            },
          ]
        }
        return (
          <ResponsiveContainer width="100%" height="95%">
            <ComposedChart
              data={data}
              onClick={this.onClick}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="name"
                tick={
                  <CustomizedAxisTick graphicRange={this.props.graphicRange} />
                }
                interval={interval}
                height={45}
                tickCount={tickCount}
              />
              <YAxis
                type="number"
                interval={0}
                tick={{ fontSize: "1em" }}
                tickCount={15}
                domain={[0, 'auto']}
                allowDataOverflow={true}
              >
                <Label
                  angle={-90}
                  value={"(mm)"}
                  position="insideLeft"
                  offset={10}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <Tooltip
                content={
                  <CustomTooltip
                    variable={this.props.reportTypes[0]}
                    weatherType={this.props.graphicType}
                    weatherRange={this.props.graphicRange}
                    visualizationType={this.props.visualizationType}
                    compareYears={this.props.compareYears}
                  />
                }
                showTooltips={this.props.showTooltips}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Legend
                margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                align="center"
                verticalAlign="top"
                payload={legend}
              />
              {lines.map((line) => {
                return line;
              })}
            </ComposedChart>
          </ResponsiveContainer>
        );
      }
    } else if (
      this.props.reportTypes.includes("etretp") ||
      this.props.reportTypes.includes("arm")
    ) {
      let interval =
        this.props.graphicRange === "daily" ? "preserveStartEnd" : 0;
      let tickCount = 36;
      let currentYearGrid = this.setGridValues(data);
      if (currentYearGrid[0] !== 0 && this.props.graphicRange === "daily") {
        interval = currentYearGrid[0];
        tickCount = currentYearGrid[1];
      }
      let domain = undefined;

      if (this.props.reportTypes.includes("arm")) {
        domain = this.setYDomain("arm", null, null, null);
      } else {
        domain = this.setYDomain(
          "etp",
          this.props.graphicRange,
          this.props.graphicType,
          this.props.temperatureControl
        );
      }

      if (domain === undefined) {
        domain = [0, "auto", 15];
      }
      if (
        this.props.graphicType === "period" &&
        this.props.temperatureControl === "etretp"
      ) {
        let areas = this.setAreas(
          this.props.yearBegin,
          this.props.yearEnd,
          this.props.compareYears
        );
        return (
          <ResponsiveContainer width="100%" height="95%">
            <AreaChart
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="name"
                tick={
                  <CustomizedAxisTick graphicRange={this.props.graphicRange} />
                }
                interval={interval}
                height={45}
                tickCount={tickCount}
              />
              <YAxis
                tick={{ fontSize: "1em" }}
                interval={0}
                tickCount={domain[2]}
                domain={[domain[0], domain[1]]}
              >
                <Label
                  angle={-90}
                  value={this.setUnitLabel()}
                  position="insideLeft"
                  offset={10}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <CartesianGrid strokeDasharray="1 1" />
              {this.props.showTooltips ? <Tooltip /> : null}
              <Label value="Previsão" />
              {areas.map((area) => {
                return area;
              })}
              <Legend
                margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                align="center"
                verticalAlign="top"
              />
            </AreaChart>
          </ResponsiveContainer>
        );
      } else {
        let lines = this.setLines(
          this.props.yearBegin,
          this.props.yearEnd,
          this.props.compareYears,
          this.props.reportTypes[0]
        );
        return (
          <ResponsiveContainer width="100%" height="95%">
            <LineChart
              data={data}
              onClick={this.onClick}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <XAxis
                dataKey="name"
                tick={
                  <CustomizedAxisTick graphicRange={this.props.graphicRange} />
                }
                height={45}
                interval={interval}
                tickCount={tickCount}
              />
              <YAxis
                interval={0}
                tick={{ fontSize: "1em" }}
                tickCount={domain[2]}
                domain={[domain[0], domain[1]]}
              >
                <Label
                  angle={-90}
                  value={this.setUnitLabel()}
                  position="insideLeft"
                  offset={10}
                  style={{ textAnchor: "middle" }}
                />
              </YAxis>
              <Tooltip
                content={
                  <CustomTooltip
                    variable={this.props.reportTypes[0]}
                    weatherType={this.props.graphicType}
                    weatherRange={this.props.graphicRange}
                    visualizationType={this.props.visualizationType}
                  />
                }
                showTooltips={this.props.showTooltips}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Legend
                margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
                align="center"
                verticalAlign="top"
              />
              {lines.map((line) => {
                return line;
              })}
            </LineChart>
          </ResponsiveContainer>
        );
      }
    } else if (this.props.reportTypes.includes("excdef")) {
      let lines = this.setLines(
        this.props.yearBegin,
        this.props.yearEnd,
        this.props.compareYears,
        this.props.reportTypes[0]
      );
      let domain = this.setYDomain(
        this.props.reportTypes[0],
        null,
        null,
        this.props.temperatureControl
      );
      return (
        <ResponsiveContainer width="100%" height="95%">
          <ComposedChart
            data={data}
            onClick={this.onClick}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis
              dataKey="name"
              tick={
                <CustomizedAxisTick graphicRange={this.props.graphicRange} />
              }
              height={45}
              interval={0}
              tickCount={36}
            />
            <YAxis
              interval={0}
              tick={{ fontSize: "1em" }}
              tickCount={domain[2]}
              domain={[domain[0], domain[1]]}
            >
              <Label
                angle={-90}
                value={"(mm)"}
                position="insideLeft"
                offset={10}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip
              content={
                <CustomTooltip
                  variable={this.props.reportTypes[0]}
                  weatherType={this.props.graphicType}
                  weatherRange={this.props.graphicRange}
                  visualizationType={this.props.visualizationType}
                />
              }
              showTooltips={this.props.showTooltips}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Legend
              margin={{ top: 20, left: 0, right: 0, bottom: 0 }}
              align="center"
              verticalAlign="top"
            />
            {lines.map((line) => {
              return line;
            })}
          </ComposedChart>
        </ResponsiveContainer>
      );
    }
  };
  //#endregion

  render() {
    let data = [];
    let auxiliarTemporary = undefined;
    if (
      this.props.temperatureControl === "tmax" ||
      this.props.temperatureControl === "etr" ||
      this.props.temperatureControl === "def"
    ) {
      auxiliarTemporary = 0;
    } else if (
      this.props.temperatureControl === "tmean" ||
      this.props.temperatureControl === "etretp"
    ) {
      auxiliarTemporary = 2;
    } else {
      auxiliarTemporary = 1;
    }
    if (this.props.graphicType === "anomaly") {
      let dataArray = {};
      if (
        this.props.reportTypes[0] === "temperatura" ||
        this.props.reportTypes[0] === "etretp"
      ) {
        let key =
          this.props.reportTypes[0] === "temperatura"
            ? this.props.reportTypes[0]
            : "bhbox";
        this.props.data[0][key].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((anomaly) => {
              dataArray["name"] = this.props.visualizationType === 'civil'
                ? anomaly["name"]
                : `${anomaly["name"].toString().slice(2)}-${(anomaly["name"] + 1).toString().slice(2)}`;
              dataArray["historic"] = anomaly["historic"]
                ? anomaly["historic"][auxiliarTemporary].toFixed(1)
                : undefined;
              if (anomaly["anomaly"][auxiliarTemporary] > 0) {
                dataArray["plus"] = anomaly["anomaly"][auxiliarTemporary];
              } else if (anomaly["anomaly"][auxiliarTemporary] < 0) {
                dataArray["minus"] = anomaly["anomaly"][auxiliarTemporary];
              } else {
                dataArray["plus"] = 0;
                dataArray["minus"] = 0;
              }
              data.push(dataArray);
              dataArray = {};
            });
          } else {
            if (this.props.visualizationType === 'agricultural') {
              if (tempData["year"] === Number(this.props.yearBegin)) {
                const firstIndex = tempData.data
                  .findIndex(d => ['jul_1', 'jul'].includes(d.name));
                const agriculturalYear = firstIndex >= 0 ? tempData.data.slice(firstIndex) : [];
                agriculturalYear.forEach((anomaly) => {
                  dataArray["name"] = anomaly["name"];
                  dataArray["historic"] = anomaly["historic"]
                    ? anomaly["historic"][auxiliarTemporary].toFixed(1)
                    : undefined;
                  if (anomaly["anomaly"] !== undefined) {
                    if (anomaly["anomaly"][auxiliarTemporary] > 0) {
                      dataArray["plus"] = anomaly["anomaly"][auxiliarTemporary];
                    } else if (anomaly["anomaly"][auxiliarTemporary] < 0) {
                      dataArray["minus"] = anomaly["anomaly"][auxiliarTemporary];
                    } else {
                      dataArray["plus"] = 0;
                      dataArray["minus"] = 0;
                    }
                  }
                  data.push(dataArray);
                  dataArray = {};
                });
              } else if (tempData["year"] === Number(this.props.yearBegin) + 1) {
                const lastIndex = tempData.data
                  .findIndex(d => ['jul_1', 'jul'].includes(d.name));
                const agriculturalYear = tempData.data.slice(0, lastIndex);
                agriculturalYear.forEach((anomaly) => {
                  dataArray["name"] = anomaly["name"];
                  dataArray["historic"] = anomaly["historic"]
                    ? anomaly["historic"][auxiliarTemporary].toFixed(1)
                    : undefined;
                  if (anomaly["anomaly"] !== undefined) {
                    if (anomaly["anomaly"][auxiliarTemporary] > 0) {
                      dataArray["plus"] = anomaly["anomaly"][auxiliarTemporary];
                    } else if (anomaly["anomaly"][auxiliarTemporary] < 0) {
                      dataArray["minus"] = anomaly["anomaly"][auxiliarTemporary];
                    } else {
                      dataArray["plus"] = 0;
                      dataArray["minus"] = 0;
                    }
                  }
                  data.push(dataArray);
                  dataArray = {};
                });
              }
            } else {
              if (tempData["year"].toString() === this.props.yearBegin) {
                tempData["data"].forEach((anomaly) => {
                  dataArray["name"] = anomaly["name"];
                  dataArray["historic"] = anomaly["historic"]
                    ? anomaly["historic"][auxiliarTemporary].toFixed(1)
                    : undefined;
                  if (anomaly["anomaly"] !== undefined) {
                    if (anomaly["anomaly"][auxiliarTemporary] > 0) {
                      dataArray["plus"] = anomaly["anomaly"][auxiliarTemporary];
                    } else if (anomaly["anomaly"][auxiliarTemporary] < 0) {
                      dataArray["minus"] = anomaly["anomaly"][auxiliarTemporary];
                    } else {
                      dataArray["plus"] = 0;
                      dataArray["minus"] = 0;
                    }
                  }
                  data.push(dataArray);
                  dataArray = {};
                });
              }
            }
          }
        });
      } else {
        this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((anomaly) => {
              dataArray["name"] = this.props.visualizationType === 'civil'
                ? anomaly["name"]
                : `${anomaly["name"].toString().slice(2)}-${(anomaly["name"] + 1).toString().slice(2)}`;
              dataArray["historic"] = anomaly["historic"];
              if (anomaly["anomaly"] > 0) {
                dataArray["plus"] = anomaly["anomaly"];
              } else if (anomaly["anomaly"] < 0) {
                dataArray["minus"] = anomaly["anomaly"];
              } else {
                dataArray["plus"] = 0;
                dataArray["minus"] = 0;
              }
              data.push(dataArray);
              dataArray = {};
            });
          } else {
            if (this.props.visualizationType === 'agricultural') {
              if (tempData["year"] === Number(this.props.yearBegin)) {
                const firstIndex = tempData.data
                  .findIndex(d => ['jul_1', 'jul'].includes(d.name));
                const agriculturalYear = firstIndex >= 0 ? tempData.data.slice(firstIndex) : [];
                agriculturalYear.forEach((anomaly) => {
                  dataArray["name"] = anomaly["name"];
                  dataArray["historic"] = anomaly["historic"];
                  if (anomaly["anomaly"] > 0) {
                    dataArray["plus"] = anomaly["anomaly"];
                  } else if (anomaly["anomaly"] < 0) {
                    dataArray["minus"] = anomaly["anomaly"];
                  } else {
                    dataArray["plus"] = 0;
                    dataArray["minus"] = 0;
                  }
                  data.push(dataArray);
                  dataArray = {};
                })
              } else if (tempData["year"] === Number(this.props.yearBegin) + 1) {
                const lastIndex = tempData.data
                  .findIndex(d => ['jun_3', 'jun'].includes(d.name));
                const agriculturalYear = tempData.data.slice(0, lastIndex + 1);
                agriculturalYear.forEach((anomaly) => {
                  dataArray["name"] = anomaly["name"];
                  dataArray["historic"] = anomaly["historic"];
                  if (anomaly["anomaly"] > 0) {
                    dataArray["plus"] = anomaly["anomaly"];
                  } else if (anomaly["anomaly"] < 0) {
                    dataArray["minus"] = anomaly["anomaly"];
                  } else {
                    dataArray["plus"] = 0;
                    dataArray["minus"] = 0;
                  }
                  data.push(dataArray);
                  dataArray = {};
                })
              }
            } else {
              if (tempData["year"].toString() === this.props.yearBegin) {
                tempData["data"].forEach((anomaly) => {
                  dataArray["name"] = anomaly["name"];
                  dataArray["historic"] = anomaly["historic"];
                  if (anomaly["anomaly"] > 0) {
                    dataArray["plus"] = anomaly["anomaly"];
                  } else if (anomaly["anomaly"] < 0) {
                    dataArray["minus"] = anomaly["anomaly"];
                  } else {
                    dataArray["plus"] = 0;
                    dataArray["minus"] = 0;
                  }
                  data.push(dataArray);
                  dataArray = {};
                });
              }
            }
          }
        });
      }
    } else if (this.props.reportTypes.includes("temperatura")) {
      if (this.props.graphicType === "period") {
        this.props["data"][0]["temperatura"].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData) => {
              if (this.props.visualizationType === 'civil') {
                data.push(graphicData)
              } else {
                data.push(
                  {
                    name: `${graphicData["name"].toString().slice(2)}-${(graphicData["name"] + 1).toString().slice(2)}`,
                    tmax: graphicData["tmax"],
                    tmean: graphicData["tmean"],
                    tmin: graphicData["tmin"],
                  }
                )
              }
            });
          } else {
            if (this.props.visualizationType === 'agricultural') {
              if (tempData["year"] === Number(this.props.yearBegin)) {
                const firstIndex = tempData.data
                  .findIndex(d => ['01/07', 'jul_1', 'jul'].includes(d.name));
                const agriculturalYear = firstIndex >= 0 ? tempData.data.slice(firstIndex) : [];
                agriculturalYear.forEach((graphicData) => {
                  data.push(graphicData);
                });
              } else if (tempData["year"] === Number(this.props.yearBegin) + 1) {
                const lastIndex = tempData.data
                  .findIndex(d => ['01/07', 'jul_1', 'jul'].includes(d.name));
                const agriculturalYear = tempData.data.slice(0, lastIndex);
                agriculturalYear.forEach((graphicData) => {
                  data.push(graphicData);
                });
              }
            } else {
              if (tempData["year"].toString() === this.props.yearBegin) {
                tempData["data"].forEach((graphicData) => {
                  data.push(graphicData);
                });
              }
            }
          }
        });

        this.props.data[0]["prediction"].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData) => {
              data.push(graphicData);
            });
          } else {
            if (tempData["year"].toString() === this.props.yearBegin) {
              tempData["data"].forEach((graphicData) => {
                data.push(graphicData);
              });
            } else if (tempData["year"] === Number(this.props.yearBegin) + 1
              && this.props.visualizationType === 'agricultural') {
                tempData["data"].forEach((graphicData) => {
                  data.push(graphicData);
                });
            }
          }
        });
      } else if (this.props.graphicType === "cmp") {
        if (this.props.visualizationType === 'civil') {
          let dataOption = {};
          this.props.data[0]["temperatura"].forEach((tempData) => {
            const currentYear = tempData["year"];
            if (this.props.compareYears.includes(currentYear.toString())) {
              if (data.length === 0) {
                tempData["data"].forEach((graphicData) => {
                  dataOption = {
                    name: graphicData["name"],
                    [currentYear]: graphicData[currentYear][auxiliarTemporary],
                  };
                  if (!leapYear(parseInt(currentYear, 10)) && graphicData["name"] === "28/02") {
                    data.push({ ...dataOption });
                    dataOption["name"] = "29/02";
                  }
                  data.push({ ...dataOption });
                });
              } else {
                tempData["data"].forEach((graphicData, index) => {
                  dataOption = { ...data[index] };
                  dataOption[currentYear] = graphicData[currentYear][auxiliarTemporary];
                  data[index][currentYear] = dataOption[currentYear];
                });
              }
            }
          });
        } else {
          const firstAgriYear = this.getAgriculturalYear(this.props.compareYears[0]);
          const secondAgriYear = this.getAgriculturalYear(this.props.compareYears[1]);

          let dataOption = {};
          
          firstAgriYear.forEach((graphicData, index) => {
            let selectedYear = Object.keys(firstAgriYear[0])[0];
            let currentYear = Object.keys(firstAgriYear[index])[0]
            
            dataOption["name"] = graphicData["name"];
            if (auxiliarTemporary === 0) {
              dataOption[selectedYear] = graphicData[currentYear][0];
            } else if (auxiliarTemporary === 1) {
              dataOption[selectedYear] = graphicData[currentYear][1];
            } else {
              dataOption[selectedYear] = graphicData[currentYear][2];
            }

            data.push(dataOption);
            dataOption = {};

            if (
              leapYear(parseInt(currentYear, 10)) === false &&
              graphicData["name"] === "28/02"
            ) {
              dataOption["name"] = "29/02";
              data.push(dataOption);
              dataOption = {};
            }
          });

          secondAgriYear.forEach((graphicData, index) => {
            let selectedYear = Object.keys(secondAgriYear[0])[0];
            let currentYear = Object.keys(secondAgriYear[index])[0];
            
            if (auxiliarTemporary === 0) {
              data[index][selectedYear] = graphicData[currentYear][0];
            } else if (auxiliarTemporary === 1) {
              data[index][selectedYear] = graphicData[currentYear][1];
            } else {
              data[index][selectedYear] = graphicData[currentYear][2];
            }
          });
        }
      } else if (
        this.props.graphicType === "climatology" &&
        this.props.data[1]
      ) {
        if (this.props.visualizationType === 'agricultural') {
          const firstAgriDate = ['01/07', 'jul_1', 'jul'];

          let firstJulyIndex = this.props.data[0].temperatura
            .findIndex(({ name }) => firstAgriDate.includes(name));
          let agriFirstHalf = this.props.data[0].temperatura.slice(firstJulyIndex);
          let agriSecondHalf = this.props.data[0].temperatura.slice(0, firstJulyIndex);
          let agriData = [...agriFirstHalf, ...agriSecondHalf];
          agriData.forEach((tempData) => { data.push(tempData) });

          const storedData = this.props.data[1].temperatura.filter(({ year }) => {
            const years = [Number(this.props.yearBegin), Number(this.props.yearBegin) + 1];
            return years.includes(year);
          });

          firstJulyIndex = storedData[0].data.findIndex(({ name }) => firstAgriDate.includes(name));
          agriFirstHalf = firstJulyIndex >= 0 ? storedData[0].data.slice(firstJulyIndex) : [];
          firstJulyIndex = storedData[1]?.data.findIndex(({ name }) => firstAgriDate.includes(name));
          agriSecondHalf = firstJulyIndex ? storedData[1].data.slice(0, firstJulyIndex) : [];

          agriData = [...agriFirstHalf, ...agriSecondHalf];
          for (let i = 0; i < data.length; i++) {
            const { tmax, tmean, tmin, year } = agriData[i] || {};
            data[i].c_tmax = tmax;
            data[i].c_tmean = tmean;
            data[i].c_tmin = tmin;
            data[i].year = year;
          }
        } else {
          this.props.data[0]["temperatura"].forEach((tempData) => {
            data.push(tempData);
          });
          let storedData = this.props.data[1]["temperatura"].filter(
            (row) => row.year === parseInt(this.props.yearBegin, 10)
          );
          for (let i = 0; i < data.length; i++) {
            if (storedData[0].data[i]) {
              data[i].c_tmax = storedData[0]["data"][i].tmax;
              data[i].c_tmean = storedData[0]["data"][i].tmean;
              data[i].c_tmin = storedData[0]["data"][i].tmin;
              data[i].year = storedData[0]["data"][i].year;
            } else {
              data[i].c_tmax = undefined;
              data[i].c_tmean = undefined;
              data[i].c_tmin = undefined;
            }
          }
        }
      }
    } else if (this.props.reportTypes.includes("prec")) {
      let lastYear = new Date().getFullYear();
      let temporaryControl = true;
      if (this.props.graphicType === "cmp") {
        if (this.props.visualizationType === 'agricultural') {
          const { compareYears } = this.props;
          const firstAgriYear = this.getAgriculturalYear(compareYears[0], 'prec')
            .map(({ name, prec }) => ({ name, [compareYears[0]]: prec }));
          const secondAgriYear = this.getAgriculturalYear(compareYears[1], 'prec')
            .map(({ name, prec }) => ({ name, [compareYears[1]]: prec }));
          let dataOption = {};

          firstAgriYear.forEach((graphicData, index) => {
            let selectedYear = Object.keys(firstAgriYear[0])[0];
            dataOption["name"] = graphicData["name"];
            dataOption[selectedYear] = graphicData[selectedYear];
            data.push(dataOption);
            dataOption = {};

            if (!leapYear(Number(selectedYear + 1)) && graphicData["name"] === "28/02") {
              dataOption["name"] = "29/02";
              data.push(dataOption);
              dataOption = {};
            }
          });

          secondAgriYear.forEach((graphicData, index) => {
            let selectedYear = Object.keys(secondAgriYear[0])[0];
            data[index][selectedYear] = graphicData[selectedYear];
          });
        } else {
          this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
            if (this.props.compareYears.includes(tempData.year.toString())) {
              let values = {};
              let currentYear = tempData["year"];
              tempData["data"].forEach((graphicData, index) => {
                if (temporaryControl) {
                  values["name"] = graphicData["name"];
                  values[currentYear] = graphicData[this.props.reportTypes[0]];
                  data.push(values);
                  values = {};
                  if (
                    leapYear(parseInt(currentYear, 10)) === false &&
                    graphicData["name"] === "28/02"
                  ) {
                    if (this.props.compareYears.length === 2) {
                      if (
                        leapYear(parseInt(this.props.compareYears[0], 10)) ===
                          true ||
                        leapYear(parseInt(this.props.compareYears[1], 10)) ===
                          true
                      ) {
                        values["name"] = "29/02";
                        data.push(values);
                        values = {};
                      }
                    }
                  }
                } else {
                  if (graphicData["name"] === data[index]["name"]) {
                    data[index][currentYear] =
                      graphicData[this.props.reportTypes[0]];
                  } else {
                    if (index + 1 < data.length) {
                      data[index + 1][currentYear] =
                        graphicData[this.props.reportTypes[0]];
                    }
                  }
                }
              });
              temporaryControl = false;
            }
          });
        }
      } else if (this.props.graphicType !== "accumulate") {
        this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
          let values = {};
          let currentYear = tempData["year"];
          if (this.props.graphicType === "period") {
            if (this.props.graphicRange === "annualy") {
              tempData["data"].forEach((graphicData, index) => {
                values["name"] = this.props.visualizationType === 'civil'
                  ? graphicData["name"]
                  : `${graphicData["name"].slice(2, 4)}-${(Number(graphicData["name"]) + 1).toString().slice(2, 4)}`;
                values[
                  parseInt(graphicData["name"], 10) === lastYear ? "p_" : "p"
                ] = graphicData[this.props.reportTypes[0]];
                values["year"] = currentYear;
                data.push(values);
                values = {};
              });
            } else {
              const currentYear = new Date().getFullYear();
              const { graphicRange } = this.props;

              if (this.props.visualizationType === 'agricultural') {
                if(this.props.yearBegin === currentYear.toString()) {
                  if(graphicRange === 'daily') {
                    const sizePredictions = this.props.data[0].prediction[0].data.length + this.props.data[0].prediction[1].data.length
                    const lastSlice = tempData.data.length - sizePredictions;
                    if (tempData["year"].toString() === this.props.yearBegin) {
                      tempData["data"].slice(0, lastSlice).forEach((graphicData, index) => {
                        values["name"] = graphicData["name"];
                        values["p"] = graphicData[this.props.reportTypes[0]];
                        values["year"] = currentYear;
                        data.push(values);
                        values = {};
                      });
                    } else if (tempData["year"] === Number(this.props.yearBegin) + 1) {
                      tempData["data"].forEach((graphicData, index) => {
                        values["name"] = graphicData["name"];
                        values["p"] = graphicData[this.props.reportTypes[0]];
                        values["year"] = currentYear;
                        data.push(values);
                        values = {};
                      });
                    }
                  } else {
                    if (tempData["year"].toString() === this.props.yearBegin) {
                      tempData["data"].forEach((graphicData, index) => {
                        values["name"] = graphicData["name"];
                        values["p"] = graphicData[this.props.reportTypes[0]];
                        values["year"] = currentYear;
                        data.push(values);
                        values = {};
                      });
                    } else if (tempData["year"] === Number(this.props.yearBegin) + 1) {
                      tempData["data"].forEach((graphicData, index) => {
                        values["name"] = graphicData["name"];
                        values["p"] = graphicData[this.props.reportTypes[0]];
                        values["year"] = currentYear;
                        data.push(values);
                        values = {};
                      });
                    }
                  }
                } else {
                  if (tempData["year"] === Number(this.props.yearBegin)) {
                    const firstIndex = tempData.data
                      .findIndex(d => ['01/07', 'jul_1', 'jul'].includes(d.name));
                    const agriculturalYear = firstIndex >= 0 ? tempData.data.slice(firstIndex) : [];
                    agriculturalYear.forEach((graphicData) => {
                      values["name"] = graphicData["name"];
                      values["p"] = graphicData[this.props.reportTypes[0]];
                      values["year"] = currentYear;
                      data.push(values);
                      values = {};
                    });
                  } else if (tempData["year"] === Number(this.props.yearBegin) + 1 &&
                    (CURRENT_MONTH >= 7 && Number(this.props.yearBegin) === new Date().getFullYear())) {
                    const lastIndex = tempData.data.findIndex(d => ['01/07', 'jul_1', 'jul'].includes(d.name));
                    const agriculturalYear = tempData.data.slice(0, lastIndex);
                    agriculturalYear.forEach((graphicData) => {
                      values["name"] = graphicData["name"];
                      values["p"] = graphicData[this.props.reportTypes[0]];
                      values["year"] = currentYear;
                      data.push(values);
                      values = {};
                    });
                  }
                }
              } else {
                if (tempData["year"].toString() === this.props.yearBegin) {
                  tempData["data"].forEach((graphicData, index) => {
                    values["name"] = graphicData["name"];
                    values["p"] = graphicData[this.props.reportTypes[0]];
                    values["year"] = currentYear;
                    data.push(values);
                    values = {};
                  });
                }
              }
            }
          } else if (
            this.props.graphicType === "climatology" &&
            this.props.data[1]
          ) {
            values["name"] = tempData["name"];
            values["p"] = tempData[this.props.reportTypes[0]];
            data.push(values);
            values = {};

            if (this.props.visualizationType === 'agricultural') {
              const firstAgriDate = ['01/07', 'jul_1', 'jul'];
              let firstJulyIndex;
              let agriFirstHalf;
              let agriSecondHalf;

              if (data.length === 366) {
                firstJulyIndex = data.findIndex(({ name }) => firstAgriDate.includes(name));
                agriFirstHalf = data.slice(firstJulyIndex);
                agriSecondHalf = data.slice(0, firstJulyIndex);
                data = [...agriFirstHalf, ...agriSecondHalf];
              }

              const storedData = this.props.data[1].prec.filter(({ year }) => {
                const years = [Number(this.props.yearBegin), Number(this.props.yearBegin) + 1];
                return years.includes(year);
              });

              firstJulyIndex = storedData[0].data.findIndex(({ name }) => firstAgriDate.includes(name));
              agriFirstHalf = firstJulyIndex >= 0 ? storedData[0].data.slice(firstJulyIndex) : [];
              firstJulyIndex = storedData[1]?.data.findIndex(({ name }) => firstAgriDate.includes(name));
              agriSecondHalf = firstJulyIndex ? storedData[1].data.slice(0, firstJulyIndex) : [];

              const agriData = [...agriFirstHalf, ...agriSecondHalf];
              
              for (let i = 0; i < data.length; i++) {
                if (agriData[i]) {
                  data[i][`c_p`] =
                    agriData[i][this.props.reportTypes[0]];
                  data[i].name = agriData[i].name;
                } else {
                  data[i][`c_p`] = undefined;
                }
              }
            } else {
              let storedData = this.props.data[1].prec.filter(
                (row) => row.year === parseInt(this.props.yearBegin, 10)
              );
  
              for (let i = 0; i < data.length; i++) {
                if (storedData[0].data[i]) {
                  data[i][`c_p`] =
                    storedData[0]["data"][i][this.props.reportTypes[0]];
                  data[i].name = storedData[0]["data"][i].name;
                } else {
                  data[i][`c_p`] = undefined;
                }
              }
            }
          }
        });

        this.props.data[0]["prediction"].forEach((tempData) => {
          let values = {};
          let currentYear = tempData["year"];
          if (this.props.graphicType === "period") {
            if (this.props.graphicRange === "annualy") {
              tempData["data"].forEach((graphicData, index) => {
                values["name"] = graphicData["name"];
                values["p_" + currentYear] = graphicData[`p_${this.props.reportTypes[0]}`];
                data.push(values);
                values = {};
              });
            } else {
              if (tempData["year"].toString() === this.props.yearBegin) {
                tempData["data"].forEach((graphicData, index) => {
                  values["name"] = graphicData["name"];
                  values["p_" + currentYear] = graphicData[`p_${this.props.reportTypes[0]}`];
                  data.push(values);
                  values = {};
                });
              } else if (tempData["year"] === Number(this.props.yearBegin) + 1
                  && this.props.visualizationType === 'agricultural'
                ) {
                tempData["data"].forEach((graphicData, index) => {
                  values["name"] = graphicData["name"];
                  values["p_" + currentYear] = graphicData[`p_${this.props.reportTypes[0]}`];
                  data.push(values);
                  values = {};
                });
              }
            } 
          }
        });
      } else {
        let dataOption = {};
        this.props.data[0][this.props.reportTypes[0]].forEach(
          (tempData, index) => {
            if (
              index !==
              this.props.data[0][this.props.reportTypes[0]].length - 1
            ) {
              let currentYear = tempData["year"];
              if (
                this.props.compareYears.includes(tempData["year"].toString())
              ) {
                if (data.length === 0) {
                  tempData["data"].forEach((graphicData) => {
                    dataOption["name"] = graphicData["name"];
                    dataOption[currentYear] = this.populatingYearsData(graphicData[currentYear], graphicData[currentYear + 1]);
                    data.push(dataOption);
                    dataOption = {};
                    if (
                      leapYear(parseInt(currentYear, 10)) === false &&
                      graphicData["name"] === "28/02"
                    ) {
                      dataOption["name"] = "29/02";
                      data.push(dataOption);
                      dataOption = {};
                    }
                  });
                } else {
                  tempData["data"].forEach((graphicData, index) => {
                    data[index][currentYear] = this.populatingYearsData(graphicData[currentYear], graphicData[currentYear + 1]);
                  });
                }
              }
            } else {
              tempData.forEach((mean, index) => {
                if (index < data.length) data[index]["Média"] = mean["value"];
              });
            }
          }
        );
      }
    } else if (this.props.reportTypes.includes("heat_wave")) {
      let lastYear = new Date().getFullYear();
      this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
        let values = {};
        let currentYear = tempData["year"];
        if (this.props.graphicRange === "annualy") {
          tempData["data"].forEach((graphicData, index) => {
            values["name"] = graphicData["name"];
            values[
              parseInt(graphicData["name"], 10) === lastYear
                ? "p_heat_wave_"
                : "heat_wave"
            ] = graphicData[this.props.reportTypes[0]];
            values["year"] = currentYear;
            data.push(values);
            values = {};
          });
        } else {
          if (tempData["year"].toString() === this.props.yearBegin) {
            tempData["data"].forEach((graphicData, index) => {
              values["name"] = graphicData["name"];
              values["heat_wave"] = graphicData[this.props.reportTypes[0]];
              values["year"] = currentYear;
              data.push(values);
              values = {};
            });
          }
        }
      });

      this.props.data[0]["prediction"].forEach((tempData) => {
        let values = {};
        let currentYear = tempData["year"];
        if (this.props.graphicType === "period") {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData, index) => {
              values["name"] = graphicData["name"];
              values["p_heat_wave_" + currentYear] =
                graphicData["p_" + this.props.reportTypes[0]];
              data.push(values);
              values = {};
            });
          } else {
            if (tempData["year"].toString() === this.props.yearBegin) {
              tempData["data"].forEach((graphicData, index) => {
                values["name"] = graphicData["name"];
                values["p_heat_wave_" + currentYear] =
                  graphicData["p_" + this.props.reportTypes[0]];
                data.push(values);
                values = {};
              });
            }
          }
        }
      });
    } else if (this.props.reportTypes.includes("frost_risk")) {
      let lastYear = new Date().getFullYear();
      this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
        let values = {};
        let currentYear = tempData["year"];
        if (this.props.graphicRange === "annualy") {
          tempData["data"].forEach((graphicData, index) => {
            values["name"] = graphicData["name"];
            values[
              parseInt(graphicData["name"], 10) === lastYear
                ? "p_frost_risk_"
                : "frost_risk"
            ] = graphicData[this.props.reportTypes[0]];
            values["year"] = currentYear;
            data.push(values);
            values = {};
          });
        } else {
          if (tempData["year"].toString() === this.props.yearBegin) {
            tempData["data"].forEach((graphicData, index) => {
              values["name"] = graphicData["name"];
              values["frost_risk"] = graphicData[this.props.reportTypes[0]];
              values["year"] = currentYear;
              data.push(values);
              values = {};
            });
          }
        }
      });

      this.props.data[0]["prediction"].forEach((tempData) => {
        let values = {};
        let currentYear = tempData["year"];
        if (this.props.graphicType === "period") {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData, index) => {
              values["name"] = graphicData["name"];
              values["p_frost_risk_" + currentYear] =
                graphicData["p_" + this.props.reportTypes[0]];
              data.push(values);
              values = {};
            });
          } else {
            if (tempData["year"].toString() === this.props.yearBegin) {
              tempData["data"].forEach((graphicData, index) => {
                values["name"] = graphicData["name"];
                values["p_frost_risk_" + currentYear] =
                  graphicData["p_" + this.props.reportTypes[0]];
                data.push(values);
                values = {};
              });
            }
          }
        }
      });
    } else if (this.props.reportTypes.includes("rainy_days")) {
      let lastYear = new Date().getFullYear();
      this.props.data[0]["rainy_days"].forEach((tempData) => {
        let values = {};
        let currentYear = tempData["year"];
        if (this.props.graphicRange === "annualy") {
          tempData["data"].forEach((graphicData, index) => {
            values["name"] = graphicData["name"];
            values[
              parseInt(graphicData["name"], 10) === lastYear
                ? "p_rainy_days_"
                : "rainy_days"
            ] = graphicData[this.props.reportTypes[0]];
            values["year"] = currentYear;
            data.push(values);
            values = {};
          });
        } else {
          if (tempData["year"].toString() === this.props.yearBegin) {
            tempData["data"].forEach((graphicData, index) => {
              values["name"] = graphicData["name"];
              values["rainy_days"] = graphicData[this.props.reportTypes[0]];
              values["year"] = currentYear;
              data.push(values);
              values = {};
            });
          }
        }
      });

      this.props.data[0]["prediction"].forEach((tempData) => {
        let values = {};
        let currentYear = tempData["year"];
        if (this.props.graphicType === "period") {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData, index) => {
              values["name"] = graphicData["name"];
              values["p_rainy_days_" + currentYear] =
                graphicData["p_" + this.props.reportTypes[0]];
              data.push(values);
              values = {};
            });
          } else {
            if (tempData["year"].toString() === this.props.yearBegin) {
              tempData["data"].forEach((graphicData, index) => {
                values["name"] = graphicData["name"];
                values["p_rainy_days_" + currentYear] =
                  graphicData["p_" + this.props.reportTypes[0]];
                data.push(values);
                values = {};
              });
            }
          }
        }
      });
    } else if (this.props.reportTypes.includes("etretp")) {
      if (this.props.graphicType === "period") {
        this.props.data[0]["bhbox"].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData.forEach((yearData) => {
              yearData["data"].forEach((graphicData) => {
                data.push(graphicData);
              });
            });
          } else {
            if (tempData["year"].toString() === this.props.yearBegin) {
              tempData["data"].forEach((graphicData) => {
                data.push(graphicData);
              });
            }
          }
        });
      } else if (this.props.graphicType === "cmp") {
        let dataOption = {};
        this.props.data[0]["bhbox"].forEach((tempData) => {
          let currentYear = tempData["year"];
          if (this.props.compareYears.includes(tempData["year"].toString())) {
            if (data.length === 0) {
              tempData["data"].forEach((graphicData) => {
                dataOption["name"] = graphicData["name"];
                if (auxiliarTemporary === 0) {
                  dataOption[currentYear] = graphicData[currentYear][0];
                } else {
                  dataOption[currentYear] = graphicData[currentYear][1];
                }
                data.push(dataOption);
                dataOption = {};
                if (
                  leapYear(parseInt(currentYear, 10)) === false &&
                  graphicData["name"] === "28/02"
                ) {
                  dataOption["name"] = "29/02";
                  data.push(dataOption);
                  dataOption = {};
                }
              });
            } else {
              tempData["data"].forEach((graphicData, index) => {
                if (auxiliarTemporary === 0) {
                  data[index][currentYear] = graphicData[currentYear][0];
                } else {
                  data[index][currentYear] = graphicData[currentYear][1];
                }
              });
            }
          }
        });
      }
    } else if (this.props.reportTypes.includes("excdef")) {

      this.props.setStartAndEndDate(this.props.graphicRange);
      
      if (this.props.graphicRange === "decendial") {
        let dataOption = {};
        this.props.data[0]["bhbox"].forEach((tempData, index) => {
          let currentYear = tempData["year"];
          if (index - 1 !== this.props.data[0]["bhbox"].length - 2) {
            if (this.props.compareYears.includes(tempData["year"].toString())) {
              if (!data.length) {
                tempData["data"].forEach((graphicData) => {
                  dataOption["name"] = graphicData["name"];
                  if (auxiliarTemporary === 0) {
                    dataOption[currentYear] = graphicData["def"];
                  } else {
                    dataOption[currentYear] = graphicData["exc"];
                  }
                  data.push(dataOption);
                  dataOption = {};
                });
              } else {
                tempData["data"].forEach((graphicData, index) => {
                  if (auxiliarTemporary === 0) {
                    data[index][currentYear] = graphicData["def"];
                  } else {
                    data[index][currentYear] = graphicData["exc"];
                  }
                });
              }
            }
          } else {
            if (data.length < 36) {
              let nameIndex = data.length;
              while (data.length < 36) {
                data.push({
                  Média: 0,
                  error: 0,
                  name: this.props.data[0]["bhbox"][0]["data"][nameIndex][
                    "name"
                  ],
                });
                nameIndex += 1;
              }
            }
            data.forEach((row, index) => {
              if (auxiliarTemporary === 0) {
                row["Média"] = tempData["mean_def"][index]["sum"];
                row["error"] = [0, tempData["dev_def"][index]["value"]];
              } else {
                row["Média"] = tempData["mean_exc"][index]["sum"];
                row["error"] = [0, tempData["dev_exc"][index]["value"]];
              }
            });
          }
        });
      }
    } else if (this.props.reportTypes.includes("arm")) {
      if (this.props.graphicType === "cmp") {
        let dataOption = {};
        this.props.data[0]["bhbox"].forEach((tempData) => {
          let currentYear = tempData["year"];
          if (this.props.compareYears.includes(tempData["year"].toString())) {
            if (data.length === 0) {
              tempData["data"].forEach((graphicData) => {
                dataOption["name"] = graphicData["name"];
                dataOption[currentYear] = graphicData[currentYear];
                data.push(dataOption);
                dataOption = {};
                if (
                  graphicData["name"] === "28/02" &&
                  leapYear(parseInt(currentYear, 10)) === false
                ) {
                  dataOption["name"] = "29/02";
                  data.push(dataOption);
                  dataOption = {};
                }
              });
            } else {
              let tempIndex = 0;
              for (let index = 0; index < data.length - 1; index++) {
                if (
                  data[index]["name"] === "29/02" &&
                  leapYear(parseInt(currentYear, 10)) === false
                ) {
                  index++;
                }
                data[index][currentYear] =
                  tempData.data[tempIndex++][currentYear];
              }
            }
          }
        });
      }
    } else {
      if (this.props.graphicType === "period") {
        const variable = this.props.reportTypes[0];
        this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData) => {
              if (this.props.visualizationType === 'agricultural') {
                data.push({
                  name: `${graphicData.name.toString().slice(2)}-${(Number(graphicData.name) + 1).toString().slice(2)}`,
                  [variable]: graphicData[variable],
                })
              } else {
                data.push(graphicData);
              }
            });
          } else {
            if (this.props.visualizationType === 'agricultural') {
              const julyDates = ['01/07', 'jul_1', 'jul'];
              if (tempData.year.toString() === this.props.yearBegin) {
                const firstIndex = tempData.data.findIndex(d => julyDates.includes(d.name));
                const agriculturalYear = firstIndex >= 0 ? tempData.data.slice(firstIndex) : [];
                agriculturalYear.forEach((graphicData) => { data.push(graphicData) });
              } else if (tempData.year === Number(this.props.yearBegin) + 1 && this.props.graphicRange !== "daily") {
                const agriculturalYear = tempData.data
                agriculturalYear.forEach((graphicData) => { data.push(graphicData) });
              }
            } else {
              if (tempData["year"].toString() === this.props.yearBegin) {
                tempData["data"].forEach((graphicData) => {
                  data.push(graphicData);
                });
              }
            }
          }
        });

        this.props.data[0]["prediction"].forEach((tempData) => {
          if (this.props.graphicRange === "annualy") {
            tempData["data"].forEach((graphicData) => {
              data.push(graphicData);
            });
          } else {
            if (this.props.visualizationType === 'agricultural') {
              const julyDates = ['01/07', 'jul_1', 'jul'];
              if (tempData.year.toString() === this.props.yearBegin) {
                const firstIndex = tempData.data.findIndex(d => julyDates.includes(d.name));
                const agriculturalYear = firstIndex >= 0 ? tempData.data.slice(firstIndex) : [];
                agriculturalYear.forEach((graphicData) => { data.push(graphicData) });
              } else if (tempData.year === Number(this.props.yearBegin) + 1) {
                const agriculturalYear = tempData.data;
                agriculturalYear.forEach((graphicData) => { data.push(graphicData) });
              }
            } else {
              if (tempData["year"].toString() === this.props.yearBegin) {
                tempData["data"].forEach((graphicData) => {
                  data.push(graphicData);
                });
              }
            }
          }
        });
      } else if (this.props.graphicType === "cmp") {
        let dataOption = {};
        if (this.props.visualizationType === 'agricultural') {
          const { compareYears } = this.props;  
          const janDates = ['01/01', 'jan_1', 'jan'];

          const firstAgriYear = this.getAgriculturalYear(compareYears[0], this.props.reportTypes[0]);
          const secondAgriYear = this.getAgriculturalYear(compareYears[1], this.props.reportTypes[0]);

          let janIndex = firstAgriYear.findIndex(({ name }) => janDates.includes(name));
          firstAgriYear.forEach((graphicData, index) => {
            dataOption.name = graphicData.name; 
            dataOption[compareYears[0]] = index < janIndex
              ? graphicData[compareYears[0]] : graphicData[Number(compareYears[0]) + 1];
            data.push(dataOption);
            dataOption = {};
            if (!leapYear(Number(compareYears[0]) + 1) && graphicData["name"] === "28/02") {
              if (compareYears.some((year) => leapYear(Number(year) + 1))) {
                dataOption["name"] = "29/02";
                data.push(dataOption);
                dataOption = {};
              }
            }
          });

          janIndex = secondAgriYear.findIndex(({ name }) => janDates.includes(name));
          secondAgriYear.forEach((graphicData, index) => {
            data[index][compareYears[1]] = index < janIndex
              ? graphicData[compareYears[1]] : graphicData[Number(compareYears[1]) + 1];
          });
        } else {
          this.props.data[0][this.props.reportTypes[0]].forEach(
            (tempData, index) => {
              let currentYear = tempData["year"];
              if (this.props.compareYears.includes(tempData["year"].toString())) {
                if (data.length === 0) {
                  tempData["data"].forEach((graphicData) => {
                    dataOption["name"] = graphicData["name"];
                    dataOption[currentYear] = graphicData[currentYear];
                    data.push(dataOption);
                    dataOption = {};
                    if (
                      leapYear(parseInt(currentYear, 10)) === false &&
                      graphicData["name"] === "28/02"
                    ) {
                      if (this.props.compareYears.length === 2) {
                        if (
                          leapYear(parseInt(this.props.compareYears[0], 10)) ===
                            true ||
                          leapYear(parseInt(this.props.compareYears[1], 10)) ===
                            true
                        ) {
                          dataOption["name"] = "29/02";
                          data.push(dataOption);
                          dataOption = {};
                        }
                      }
                    }
                  });
                } else {
                  tempData["data"].forEach((graphicData, index) => {
                    data[index][currentYear] = graphicData[currentYear];
                  });
                }
              }
            }
          );
        }
      } else if (this.props.graphicType === "accumulate") {
        let dataOption = {};
        this.props.data[0][this.props.reportTypes[0]].forEach(
          (tempData, index) => {
            if (
              index !==
              this.props.data[0][this.props.reportTypes[0]].length - 1
            ) {
              let currentYear = tempData["year"];
              if (
                this.props.compareYears.includes(tempData["year"].toString())
              ) {
                if (data.length === 0) {
                  tempData["data"].forEach((graphicData) => {
                    dataOption["name"] = graphicData["name"];
                    dataOption[currentYear] = graphicData[currentYear];
                    data.push(dataOption);
                    dataOption = {};
                    if (
                      leapYear(parseInt(currentYear, 10)) === false &&
                      graphicData["name"] === "28/02"
                    ) {
                      dataOption["name"] = "29/02";
                      data.push(dataOption);
                      dataOption = {};
                    }
                  });
                } else {
                  tempData["data"].forEach((graphicData, index) => {
                    if (data[index]["name"] === graphicData["name"]) {
                      data[index][currentYear] = graphicData[currentYear];
                    } else {
                      data[index + 1][currentYear] = graphicData[currentYear];
                    }
                  });
                }
              }
            } else {
              tempData.forEach((mean, index) => {
                if (index < data.length) data[index]["Média"] = mean["value"];
              });
            }
          }
        );
      } else if (
        this.props.graphicType === "climatology" &&
        this.props.data[1]
      ) {
        if (this.props.visualizationType === 'agricultural') {
          const julyDates = ['01/07', 'jul_1', 'jul'];
          let julyFirst = this.props.data[0][this.props.reportTypes[0]]
            .findIndex(({ name }) => julyDates.includes(name));
          const climatologyData = [
            ...this.props.data[0][this.props.reportTypes[0]].slice(julyFirst),
            ...this.props.data[0][this.props.reportTypes[0]].slice(0, julyFirst),
          ];
          climatologyData.forEach((tempData) => { data.push(tempData) });

          if (this.props.data[1][this.props.reportTypes[0]]) {
            let storedData = this.props.data[1][this.props.reportTypes[0]]
              .filter(({ year }) => {
                const selectedYear = Number(this.props.yearBegin);
                return year === selectedYear || year - 1 === selectedYear;
              });

            julyFirst = storedData[0].data.findIndex(({ name }) => julyDates.includes(name));
            const agriFirst = julyFirst >= 0 ? storedData[0].data.slice(julyFirst) : [];
            julyFirst = storedData[1]?.data.findIndex(({ name }) => julyDates.includes(name));
            const agriSecond = julyFirst ? storedData[1].data.slice(0, julyFirst) : [];
  
            const agriData = [...agriFirst, ...agriSecond];
            for (let i = 0; i < data.length; i++) {
              if (agriData[i]) {
                data[i][`c_${this.props.reportTypes[0]}`] = agriData[i][this.props.reportTypes[0]];
                data[i].name = agriData[i].name;
              } else {
                data[i][`c_${this.props.reportTypes[0]}`] = undefined;
              }
            }
          }
        } else {
          this.props.data[0][this.props.reportTypes[0]].forEach((tempData) => {
            data.push(tempData);
          });
  
          if (this.props.data[1][this.props.reportTypes[0]]) {
            let storedData = this.props.data[1][this.props.reportTypes[0]].filter(
              (row) => row.year === parseInt(this.props.yearBegin, 10)
            );
            for (let i = 0; i < data.length; i++) {
              if (storedData[0].data[i]) {
                data[i][`c_${this.props.reportTypes[0]}`] =
                  storedData[0]["data"][i][this.props.reportTypes[0]];
                data[i].name = storedData[0]["data"][i].name;
              } else {
                data[i][`c_${this.props.reportTypes[0]}`] = undefined;
              }
            }
          }
        }
      }
    }
    
    const {
      startDate, endDate, graphicRange,
      startYear, endYear, graphicType,
      reportTypes, compareYears, visualizationType
    } = this.props;

    let newStartDate = startDate;
    let newEndDate = endDate;

    if (graphicType === 'accumulate') {
      data = this.filterCumulativeData(data, compareYears, startDate, endDate);
    }

    if ((reportTypes.includes('excdef') && !['_'].includes(startDate[3]))) {
      newStartDate = visualizationType === 'civil' ? 'jan_1' : 'jul_1';
      newEndDate = visualizationType === 'civil' ? 'dez_3' : 'jun_3';
    }

    const startingIndex = graphicRange !== 'annualy'
      ? data.findIndex(({ name }) => name === newStartDate)
      : data.findIndex(({ year, name }) => this.setYearOrName(year, name) === startYear);
    const endingIndex = graphicRange !== 'annualy'
      ? data.findIndex(({ name }) => name === newEndDate)
      : data.findIndex(({ year, name }) => this.setYearOrName(year, name) === endYear);

    data = data.filter((_d, index) => index >= startingIndex && index <= endingIndex)

    let widthSize = this.props.fullScreen
      ? Math.round(window.innerWidth * FULLSCREEN_WIDTH_FACTOR)
      : Math.round(window.innerWidth * WIDTH_FACTOR);

    return (
      <div style={{ height: "100%" }}>
        {this.setGraphic(data, widthSize, this.props.reportTypes[0])}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportTypes: state.Reports.reportTypes,
    activeDay: state.map.weatherMapDay,
    lastUsedCad: state.Reports.lastUsedCad,
    tBasal: state.Reports.tBasal,
    showTooltips: state.global.showTooltips,
    visualizationType: state.global.visualizationType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMapDay: (day) => {
      dispatch(changeMapDay(day));
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes, attrs));
    },
    toggleLoader: () => {
      dispatch(toggleLoader());
    },
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader());
    },
    generatingMapData: () => {
      dispatch(generatingMapData());
    },
    setMapYear: (year) => {
      dispatch(setMapYear(year));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherGraphic);
