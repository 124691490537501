import React from "react";
import Checkbox from "rc-checkbox";
import InputField from "../common/InputField";

class CultureData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cultures: [],
      yearlyCulteres: this.props.cultures.filter((culture) => culture.type === "Yearly"),
      perennialCulteres: this.props.cultures.filter((culture) => culture.type === "Perennial"),
      active: false,
    };

    this.onNextClick = this.onNextClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
  }

  setCheckStatus(culture) {
    if (culture === "othersYearly") {
      return this.props.showFirstTextField;
    } else if (culture === "othersPerennial") {
      return this.props.showSecondTextField;
    } else {
      return this.props.selectedCultures.indexOf(culture) !== -1;
    }
  }

  active() {
    return this.props.stage === 4 && this.state.active ? true : false;
  }

  onNextClick() {
    if (this.props.stage === 5) {
      return this.props.nextClick();
    } else {
      if (this.props.selectedCultures.length !== 0) {
        this.setState({ active: false });
        return this.props.nextClick();
      } else {
        this.setState({ active: true });
      }

      return this.props.nextClick();
    }
  }

  onBackClick() {
    if (this.props.stage === 5) {
      this.setState({ active: false });
      return this.props.backClick();
    } else {
      return this.props.backClick();
    }
  }

  render() {
    let culturesYearly = this.props.cultures.filter((culture) => {
      if (culture["type"] === "Yearly") {
        return culture;
      } else {
        return false;
      }
    });
    let culturesPerennial = this.props.cultures.filter((culture) => {
      if (culture["type"] === "Perennial") {
        return culture;
      } else {
        return false;
      }
    });
    let cultures = this.props.stage === 4 ? culturesYearly : culturesPerennial;

    return (
      <div className="signin-modal-user-culture">
        <div className="row">
          <div className="cultures-label col s12">
            <label>Escolha as culturas que forem do seu interesse</label>
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <label>
              {this.props.stage === 4
                ? "Culturas Anuais*"
                : "Culturas Perenes*"}
            </label>
          </div>
          {cultures.map((culture, index) => {
            return (
              <div key={culture["name"]} className="culture-option col s4">
                <label>
                  <Checkbox
                    id={culture["name"]}
                    name={culture["name"]}
                    className="checkbox-item"
                    onChange={this.props.onChange}
                    checked={this.setCheckStatus(culture["name"])}
                  />
                  &nbsp; {culture["name"]}
                </label>
              </div>
            );
          })}
          <div key={"others"} className="culture-option col s4">
            <label>
              <Checkbox
                id={this.props.stage === 4 ? "othersYearly" : "othersPerennial"}
                name={
                  this.props.stage === 4 ? "othersYearly" : "othersPerennial"
                }
                className="checkbox-item"
                onChange={this.props.onChange}
                checked={this.setCheckStatus(
                  this.props.stage === 4 ? "othersYearly" : "othersPerennial"
                )}
              />
              &nbsp; {"Outros"}
            </label>
          </div>
          {this.props.showFirstTextField && this.props.stage === 4 ? (
            <div className="col s12">
              <InputField
                name="otherCultures"
                label=""
                placeholder="Ex: Laranja,Manga..."
                type="text"
                onChange={this.props.onTextChange}
                onBlur={this.props.onTextBlur}
                value={this.props.otherCultures}
                error={this.props.errors.otherCultures}
                active={this.props.otherCultures !== ""}
                activeFarm={"a"}
              />
            </div>
          ) : this.props.showSecondTextField && this.props.stage === 5 ? (
            <div className="col s12">
              <InputField
                name="otherCultures2"
                label=""
                placeholder="Ex: Laranja,Manga..."
                type="text"
                onChange={this.props.onTextChange}
                onBlur={this.props.onTextBlur}
                value={this.props.otherCultures2}
                error={this.props.errors.otherCultures2}
                active={this.props.otherCultures2 !== ""}
                activeFarm={"a"}
              />
            </div>
          ) : null}
        </div>
        {this.props.selectedCultures.length === 0 ? (
          <label style={{ marginTop: "35px", color: "red" }}>
            *É necessário selecionar pelo menos um tipo de cultura
          </label>
        ) : null}
        <div className="row button login-area-form-container-inside-buttons-row">
          <button
            className="btn-flat btn-drop"
            style={{ marginRight: "8px" }}
            onClick={this.onBackClick}
          >
            VOLTAR
          </button>
          <button
            disabled={
              this.props.stage === 4
                ? !this.props.selectedCultures.some((selectedCulture) =>
                    this.state.yearlyCulteres.some(
                      (culture) => culture.name === selectedCulture
                    )
                  )
                : !this.props.selectedCultures.some((selectedCulture) =>
                    this.state.perennialCulteres.some(
                      (culture) => culture.name === selectedCulture
                    )
                  )
            }
            className="btn wave-light agrymet-green"
            style={{ marginLeft: "8px", fontSize: "12px" }}
            onClick={this.onNextClick}
          >
            AVANÇAR
          </button>
        </div>
      </div>
    );
  }
}

export default CultureData;
