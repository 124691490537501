import React, { Component } from 'react'

// Components
import PerfectScrollbar from 'react-perfect-scrollbar'
import DropdownField from '../../common/DropdownField'


class CompareFarmsBar extends Component {

  render(){
    let agrytools = ['herb','plan', 'ps', 'fert', 'traf']
    let points = this.props.xavierGridPoints['xavier_points'].filter((point) =>{
      let coords = point['lat'] + ',' + point['lng']
      if(this.props.selectedPointsBhbox.includes(coords)){
        return true
      }else{
        return false
      }
    })

    return(
      <div className="compare-farms-bar">
        <div className="row compare-farms-bar-header">
          <div className='row'>
            <div className='modal-labels'>
              <label className='modal-label-1'>SELECIONE NO MAPA</label>
            </div>
          </div>
          <div className='row'>
            <label className='modal-label-2'>Clique no pontos do mapa para escolher os que deseja comparar depois clique em 'OK'.</label>
          </div>
        </div>
        <div className="compare-farms-bar-points">
          <div className="compare-farms-bar-points-title">Pontos Selecionados</div>
          <PerfectScrollbar className={"compare-farms-bar-points-scroll"}>
            <ul>
              {points.map((point) => {
                let index = this.props.selectedPointsBhbox.indexOf(point['lat'] + ',' + point['lng'])
                return(
                    <li>
                      <div className='list-label'>
                        <i className='material-icons'>{'adjust'}</i>
                        <label>
                          {point['name'] + '-' + point['uf']}
                        </label>
                      </div>
                      {agrytools.includes(this.props.reportTypes[0]) ?
                        null :
                        <div className='input-field select-field'>
                          <DropdownField
                            value={this.props.selectedCadBhbox[index]}
                            id={index.toString()}
                            onChange={this.props.onChange}
                            type={'cad'}
                          />
                        </div>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </PerfectScrollbar>
        </div>
        <div className="compare-farms-bar-btns">
          <button className='btn-flat btn-modal' onClick={this.props.onCancelClick}>CANCELAR</button>
          <button className='btn-flat btn-modal btn-ok' onClick={this.props.onOkClick}>OK</button>
        </div>
      </div>
    )
  }
}

export default CompareFarmsBar
