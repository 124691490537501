import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button } from '@material-ui/core';

function DeleteModal(
  { deleteId, classes, handleDelete, setDeleteId, title, text, confirmMsg }
) {
  return (
    <Modal
      open={ deleteId !== -1 }
      onClose={ () => setDeleteId(-1) }
    >
      <div className={ classes.deleteModal }>
        <h6 className={ classes.modalTitle }>
          { title }
        </h6>
        <h6 className={ classes.modalText }>
          { text }
        </h6>
        <div className={ classes.modalButtons } onClick={ () => setDeleteId(-1) }>
          <Button className={ classes.cancelButton }>
            CANCELAR
          </Button>
          <Button className={ classes.deleteButton } onClick={ handleDelete }>
            { confirmMsg }
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {
  deletId: PropTypes.number,
  classes: PropTypes.object,
  handleDelete: PropTypes.func,
  setDeleteId: PropTypes.func,
}.isRequired;

export default DeleteModal;
