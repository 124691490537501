import React from 'react';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label} from 'recharts';
import CustomizedAxisTick from '../CustomizedAxisTick';

const CustomTooltip = ({ active, payload, label, unit, showTooltips }) => {
  if (active && payload[0] && showTooltips) {
    return (
      <div style={{padding: '8px', backgroundColor: 'white', border: '1px solid black', textAlign: 'center'}}>
        <p className="label">{`${label}`}</p>
        <p className="label">{`${payload[0].value.toFixed(2)} ${unit}`}</p>
      </div>
    );
  }

  return null;
};

export default class EfficiencyBarChart extends React.Component {
  render () {
    let bars
    this.props.reportType === 'eucalyptus-spec-productivity' ?
        
     bars = [
      <Bar dataKey="ce" fill="#327C0E" />,
      <Bar dataKey="ae" fill="#57B828" />,
      <Bar dataKey="rae" fill="#9BFF5D" />
    ]
    : bars = [
      <Bar dataKey="ce" fill="#327C0E" />,
      <Bar dataKey="ae" fill="#57B828" />,
      <Bar dataKey="rae" fill="#9BFF5D" />
    ]

    return (
      <ResponsiveContainer height={280}>
        <BarChart data={this.props.data} margin={{top: 20, right: 30, left: 20, bottom: 5}} barGap={-66}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis height={100} dataKey="name" tick={<CustomizedAxisTick graphicRange={"annualy"}/>} interval={0} />
          <YAxis>
            <Label angle={-90} value="Eficiência (%)" position="left" style={{textAnchor: 'middle'}}/>
          </YAxis>
          <Tooltip content={<CustomTooltip unit={"%"} showTooltips={this.props.showTooltips}/>}/>
          {bars.map(bar => {
            return bar
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
