import React from "react";
import ExtbhFooter from "./reports/graphic/ExtbhFooter";
import BhboxFooter from "./reports/bhbox/BhboxFooter";
import IndexFooter from "./reports/index/IndexFooter";
import AgryFireFooter from "./reports/fire/AgryFireFooter";
import { AGRYTOOLS } from "../shared/utils/consts";

const ReportFooter = ({
  graphicActive,
  weatherGraphic,
  reportTypes,
  lastUsedSoil,
}) => {
  if (!graphicActive && !weatherGraphic) {
    if (reportTypes[0] === "extbh") {
      return <ExtbhFooter />;
    } else if (reportTypes[0] === "bhbox") {
      return <BhboxFooter />;
    } else if (AGRYTOOLS.includes(reportTypes[0])) {
      return (
        <IndexFooter reportTypes={reportTypes} lastUsedSoil={lastUsedSoil} />
      );
    } else if (reportTypes[0] === "fmaac") {
      return <AgryFireFooter />;
    } else if (reportTypes === "favo") {
      return <div></div>;
    }
  } else {
    return null;
  }
};

export default ReportFooter;
