import React, { Component } from 'react'
import { connect } from 'react-redux'
import { downloadData, resetDownload, getUsersList, getProductList } from '../../actions/admin'
import { DataDownloadRefactored } from "./DataDownloadRefactored";

class AdminDataDownload extends Component{
  render(){
    return (
      <div className="row admin-information">
        <DataDownloadRefactored
          downloadData={downloadData}
          downloadDataReturn={this.props.downloadDataReturn}
          resetDownload={this.props.resetDownload}
          getUsersList={this.props.getUsersList}
          getProductList={this.props.getProductList}
          users={this.props.users}
          points={this.props.points}
          loader={this.props.loader}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    downloadDataReturn: state.admin.downloadData,
    users: state.admin.usersList,
    points: state.admin.availablePoints,
    loader: state.global.loader,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetDownload: () => {
      dispatch(resetDownload())
    },
    getUsersList: () => {
      dispatch(getUsersList())
    },
    getProductList: (data) => {
      dispatch(getProductList(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDataDownload)
