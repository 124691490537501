import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AdminApi from "../../api/admin";

export function SmartPremiumOptions({
  backClick,
  nextClick,
  addSelectedSmart,
  smartSelected,
}) {
  const [allSmartOptions, setAllSmartOptions] = useState([]);
  useEffect(() => {
    const getSmartOptions = async () => {
      if (allSmartOptions.length === 0) {
        const response = await AdminApi.getSmartListForCreateUser();
        setAllSmartOptions(response.data.smarts);
      }
    };
    getSmartOptions();
  }, [allSmartOptions]);
  return (
    <Grid container>
      <Grid container item lg={12} xl={12} direction="column">
        {allSmartOptions.length !== 0
          ? allSmartOptions.map((option) => {
              return (
                <Grid item>
                  <FormControlLabel
                    key={option}
                    label={option}
                    onChange={() => addSelectedSmart(option)}
                    control={
                      <Checkbox
                        style={{
                          color: "#8CB63C",
                        }}
                        value={option}
                      />
                    }
                  />
                </Grid>
              );
            })
          : "carregando..."}
      </Grid>
      <Grid container item lg={4} xl={4} style={{marginTop: '50px'}}>
        <Grid item>
          <Button
            style={{
              backgroundColor: "#D2D2D2",
              padding: "8px 30px",
              marginRight: "15px",
            }}
            onClick={backClick}
          >
            VOLTAR
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              backgroundColor:
                smartSelected.length === 0 ? "#D2D2D2" : "#8cb63c",
              color: "#FFF",
              padding: "8px 30px",
            }}
            onClick={nextClick}
            disabled={smartSelected.length === 0}
          >
            AVANÇAR
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
