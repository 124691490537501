import ReportsApi from '../api/reports'
import { createGAEvent } from '../shared/utils/AnalyticsUtils'
export const GENERATE_REPORT = 'GENERATE_REPORT'
export const LOAD_REPORT = 'LOAD_REPORT'
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN'
export const TOGGLE_REPORT_CONTAINER = 'TOGGLE_REPORT_CONTAINER'
export const TOGGLE_YEARS_DROPDOWN = 'TOGGLE_YEARS_DROPDOWN'
export const TOGGLE_FARMS_DROPDOWN = 'TOGGLE_FARMS_DROPDOWN'
export const RESET_REPORT = 'RESET_REPORT'
export const SET_REPORT_TYPES = 'SET_REPORT_TYPES'
export const SET_WEATHER_TYPES = 'SET_WEATHER_TYPES'
export const SET_LAST_USED_CAD = 'SET_LAST_USED_CAD'
export const SET_LAST_VISUALIZED_CAD = 'SET_LAST_VISUALIZED_CAD'
export const SET_TBASAL = 'SET_TBASAL'
export const SET_LAST_USED_SOIL = 'SET_LAST_USED_SOIL'
export const TOGGLE_SOIL_DROPDOWN = 'TOGGLE_SOIL_DROPDOWN'
export const SAVE_REPORT = 'SAVE_REPORT'

export function generateReport(reportTypes, farmAttrs) {
  createGAEvent(reportTypes[0], farmAttrs['type'], farmAttrs['range'])

  return function(dispatch) {
    return ReportsApi.generateReport(reportTypes, farmAttrs)
      .then(report => {
        if(farmAttrs.type === "climatology"){
          let savedAttrs = farmAttrs
          savedAttrs.type = 'period'
          dispatch(generateReportToStore(reportTypes, savedAttrs, report))
        }
        else{
          dispatch(loadReport(report))
        }
      })
      .catch(error => { throw(error) })
  }
}

export function generateReportToStore(reportTypes, farmAttrs, generatedReport) {
  return function(dispatch) {
    return ReportsApi.generateReport(reportTypes, farmAttrs)
      .then(report => {
        dispatch(saveReport(report))
        dispatch(loadReport(generatedReport))
      })
      .catch(error => { throw(error) })
  }
}

export function saveReport(report) {
  return { type: 'SAVE_REPORT', report }
}

export function loadReport(report) {
  return { type: 'LOAD_REPORT', report }
}

export function setReportTypes(reportTypes) {
  return {type: 'SET_REPORT_TYPES', reportTypes}
}

export function resetReport() {
  return { type: 'RESET_REPORT'}
}

export function toggleFullScreen() {
  return { type: 'TOGGLE_FULL_SCREEN' }
}

export function toggleReportContainer() {
  return { type: 'TOGGLE_REPORT_CONTAINER' }
}

export function toggleYearsDropdown() {
  return { type: 'TOGGLE_YEARS_DROPDOWN' }
}

export function toggleSoilDropdown(){
  return { type: 'TOGGLE_SOIL_DROPDOWN'}
}

export function toggleFarmsDropdown() {
  return { type: 'TOGGLE_FARMS_DROPDOWN' }
}

export function setWeatherTypes(graphicType, graphicRange) {
  return {type: 'SET_WEATHER_TYPES', graphicType, graphicRange}
}

export function setLastUsedCad(cad) {
  return {type: 'SET_LAST_USED_CAD', cad}
}

export function setLastVisualizedCad(cad) {
  return {type: SET_LAST_VISUALIZED_CAD, cad}
}

export function setLastUsedSoil(soil) {
  return {type: 'SET_LAST_USED_SOIL', soil}
}

export function setTBasal(tb) {
  return {type: 'SET_TBASAL', tb}
}
