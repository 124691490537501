const initialState = {
    generalInfo: undefined,
    uploadedPoints: [],
    pagination: {},
    points: [],
    message: '',
    status: 0,
    lastEditedRow: {},
    loading: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case "LOAD_PROFILE":
            return Object.assign({}, state, {
                generalInfo: action.profile.user_info,
                uploadedPoints: action.profile.points,
            })
        case "LOAD_POINT_DATA":
            return Object.assign({}, state, {
                pagination: action.data.pagination,
                points: action.data.data
            })
        case "DELETE_POINT_ROW":
            return Object.assign({}, state, { message: 'delete' })
        case "CLEAR_SERVER_MESSAGE":
            return Object.assign( {}, state, { message: ''})
        case "EDIT_POINT_ROW":
            let dataPoints = state.points.slice()
            const index = dataPoints.findIndex(row => row.id === action.data.data.id)
            dataPoints[index] = action.data.data
            return Object.assign({}, state, { points: dataPoints, message: action.data.message })
        case "EDIT_POINT_ROW_FAIL":
            return Object.assign({}, state, { message: 'edit-error'})
        case "TOGGLE_LOADER":
            return Object.assign({}, state, { loading: !state.loading })
        case "RESET_POINTS":
            return Object.assign({}, state, { points: []})
        case "KMZ_UPLOAD_SUCCESS":
            return Object.assign({}, state, { message: action.data.message, status: action.data.status, loading: false });
        case 'KMZ_DELETE_SUCCESS':
            return Object.assign({}, state, {
                message: action.data.message,
                status: action.data.status
            });
        case 'KMZ_UPDATE_SUCESS':
            return Object.assign({}, state, {
                message: action.data.message,
                status: action.data.status
            });
        case "POST_KMZ_FILE":
            return Object.assign({}, state, { loading: true });
        case "KMZ_UPLOAD_ERROR":
            return Object.assign({}, state, {
                message: "Erro ao carregar o arquivo. Por favor tente novamente",
                status: 500
            })
        default:
            return state
    }
}