import { isArray } from "lodash";
import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from "recharts";
import CustomizedAxisTick from "../CustomizedAxisTick";

const CustomTooltip = ({ active, payload, label, unity, showTooltips, tch }) => {
  let percent = 0;
  if (active && showTooltips) {
    if (tch) {
      percent = (payload[0].value / tch - 1) * 100;
    } else if (payload[0].payload.last) {
      percent = (payload[0].value / payload[0].payload.last - 1) * 100;
    }
    return (
      <div
        style={{
          padding: "8px",
          backgroundColor: "white",
          border: "1px solid black",
          textAlign: "center",
        }}
      >
        <p className="label">{`${label}`}</p>
        <p className="label">{`${payload[0].value} ${unity}`}</p>
        {
          payload[0].payload.last && percent > 0 && (
            <p className="label" style={{ color: "green" }}>
              {`${percent.toFixed(1)}%`}
            </p>
          )
        }
        {
          payload[0].payload.last && percent < 0 && (
            <p className="label" style={{ color: "red" }}>
              {`${percent.toFixed(1)}%`}
            </p>
          )
        }
      </div>
    );
  }

  return null;
};

export default class ProductivityAverageCropGraph extends React.Component {
  defineMonthIdBasedOnMonthString = (monthString) => {
    switch (monthString) {
      case "January":
        return 0;
      case "February":
        return 1;
      case "March":
        return 2;
      case "April":
        return 3;
      case "May":
        return 4;
      case "June":
        return 5;
      case "July":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "October":
        return 9;
      case "November":
        return 10;
      case "December":
        return 11;
      default:
        return 0;
    }
  };

  render() {
    let bars = [];
    if (this.props.tch && isArray(this.props.tch)) {
      const auxTchFilterByMonth = this.props.tch.filter((tch) => (
        this.defineMonthIdBasedOnMonthString(this.props.selectedMonth) === tch[0]
      ))[0];

      this.props.data[this.props.data.length - 1].lastSeason =
        auxTchFilterByMonth !== undefined ? auxTchFilterByMonth[1] : 0;
    }

    if (this.props.tch && !isArray(this.props.tch)) {
      this.props.data[this.props.data.length - 1].lastSeason = Number(this.props.tch);
    }

    if (this.props.data.length > 2) {
      bars = [
        <Bar key="veryUnfavorable" dataKey="veryUnfavorable" fill="#FF1419" />,
        <Bar key="unfavorable" dataKey="unfavorable" fill="#FFBF37" />,
        <Bar key="medium" dataKey="medium" fill="#FFFF47" />,
        <Bar key="favorable" dataKey="favorable" fill="#8DCE5E" />,
        <Bar key="veryFavorable" dataKey="veryFavorable" fill="#2174B1" />,
        <Bar key="lastSeason" dataKey="lastSeason" fill="#7E7E7E" />,
      ];
    } else {
      bars = [
        <Bar key="currentCrop" dataKey="currentCrop" fill="#2174B1" />,
        <Bar key="lastSeason" dataKey="lastSeason" fill="#7E7E7E" />,
      ];
    }

    let unity = this.props.unit || "t/ha";
    
    return (
      <ResponsiveContainer height={280}>
        <BarChart
          data={this.props.data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          barGap={this.props.data.length > 2 ? -32 : -96}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            height={85}
            dataKey="name"
            tick={<CustomizedAxisTick graphicRange={"annualy"} />}
            interval={0}
          />
          <YAxis
            tickCount={11}
            domain={
              this.props.ReportType === "eucalyptus-general-productivity" ||
              "eucalyptus-spec-productivity"
                ? [0, this.props.YAxisLimit]
                : [0, 150]
            }
          >
            <Label
              angle={-90}
              value={`Produtividade (${unity})`}
              position="left"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Tooltip
            content={
              <CustomTooltip
                unity={unity}
                showTooltips={this.props.showTooltips} 
                tch={this.props.tch}
              />
            }
          />
          {bars.map((bar) => {
            return bar;
          })}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
