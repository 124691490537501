import React, { useState, useEffect } from 'react';

import { Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';

import * as S from './styles/AutomaticAuditTable';
import { MainHeader, SecondHeader } from './styles/AuditTypography';

import Loader from '../common/Loader';
import AuditTableBody from './AuditTableBody';

import { getServicesStatus } from '../../api/audits';

import getTableHeaders from './utils/AuditTableHelper';

function ServiceStatus() {
  const [checks, setChecks] = useState(null);

  useEffect(() => {
    const getServicesStatusData = async () => {
      const data = await getServicesStatus();
      setChecks(data);
    };
    getServicesStatusData();
  }, []);

  const data = ['robots', 'daily', 'fire', 'prediction',];

  if (!checks) {
    return <Loader active={ true } />
  }

  return (
    <>
      <header>
        <MainHeader component="h2" align="left">
          Status do serviço
        </MainHeader>
        <SecondHeader component="h3" align="left">
          Confira o histórico da auditoria dos serviços
        </SecondHeader>
      </header>
      <S.AutomaticAuditTableContainer>
        <Table 
          sx={{ minWidth: 650 }} 
          size="small" 
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              { 
                checks.map((date, index) => (
                  <S.AutomaticAuditTableColumn key={index}>
                    { getTableHeaders(date) }
                  </S.AutomaticAuditTableColumn>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <AuditTableBody data={data} checks={checks} />
          </TableBody>
        </Table>
      </S.AutomaticAuditTableContainer>
    </>
  );
}

export default ServiceStatus;
