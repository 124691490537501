import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import SimpleNav from '../common/SimpleNav';
import AuditDropdown from './AuditDropdown';
import { AuditDropdownButton } from './styles/AuditDropdown';

import * as S from './styles/AuditHeader'


export default function AuditHeader() {

  const auditoria = [
    ["Auditoria automática dos produtos", "/audit/automaticAudit"],
    ["Status do serviço", "/audit/serviceStatus"]]
 
  const sobre = [
    ["Produtos", "/audit/products"],
    ["Paulo Cesar Sentelhas", "/audit/pauloCesar"]]


  return (
    <>
      <SimpleNav application={'agrymax'} />
      <S.AuditHeaderBox>
        <Grid container justifyContent="center">
          <Grid item sx={{ marginLeft: '50px' }}>
            <AuditDropdown items={["Auditoria", auditoria]} />
          </Grid>
          <AuditDropdownButton
            component={Link}
            to="/audit/forecast"
          >
            Previsão
          </AuditDropdownButton>
          <AuditDropdown items={["Sobre o Agrymax", sobre]} />
          <AuditDropdownButton
            sx={{ position: 'absolute', right: '10px' }}
            component={Link}
            to=""
          >
            Voltar ao agrymax
          </AuditDropdownButton>
        </Grid>
      </S.AuditHeaderBox>
    </>
  );
}
