import React, { Component } from 'react'
import { Dialog, DialogActions, DialogContent, Button, Grid } from "@material-ui/core"

class OptionsModal extends Component {

  componentWillUnmount(){
    this.props.handleClose()
  }

  render() {
    return(
      <Dialog 
        open={this.props.open} 
        onClose={this.props.noClose ? null : this.props.handleClose}
        className="options-modal"
        maxWidth={'sm'} 
        aria-labelledby="form-dialog-title" 
        disableBackdropClick={true}
      >
        <Grid container justify="flex-start" style={{padding: '0 24px 24px'}}>
          <Grid item xs={10}>
            <h5 className="title">{this.props.title}</h5>
          </Grid>
        </Grid>      
        <DialogContent>
          {this.props.form}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.props.recalculate} style={{backgroundColor: "#6aa426", color: "white", marginRight: '8px'}}>
            {this.props.noClose ? "Calcular" : "Recalcular"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default OptionsModal;