import * as React from 'react';
import { IconButton, Typography } from "@material-ui/core";
import { Cancel, Attachment } from "@material-ui/icons";
import { styled } from '@mui/material/styles';

import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudUpload";

import { FileDrop } from "react-file-drop";

const Input = styled('input')({
  display: 'none',
});

export default function EditPointsDropdown({onSubmitFile}) {
  const [fileCsv, setfileCsv] = React.useState();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "45px 0 0 0",
      }}
    >
      <div>
        <FileDrop onDrop={(files) => setfileCsv(files)}>
          {fileCsv ? (
            <span
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Typography variant="body2"style={{
              color: "#9E9E9E",
              height: "40px",
              border: "solid 1px #9E9E9E",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "210px",
              marginLeft: '10px'
            }}>
                {fileCsv[0].name}
              </Typography>
              <IconButton
                size="small"
                onClick={() => setfileCsv(undefined)}
              >
                <Cancel />
              </IconButton>
              <CloudDownloadOutlinedIcon
                onClick={() => onSubmitFile(fileCsv)}
                style={{ cursor: fileCsv ? "pointer" : "" }}
               />
            </span>
          ) : (
            <span htmlFor="icon-button-file" style={{display: "flex",}}>
              <Typography variant="body2" style={{
                color: "#9E9E9E",
                height: "40px",
                border: "solid 1px #9E9E9E",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "210px",
              }}>
                Arraste o arquivo aqui
              </Typography>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onInput={(event) => setfileCsv(event.target.files)}
                />
                <IconButton aria-label="upload picture" component="span">
                  <Attachment/>
                </IconButton>
              </label>
            </span>
          )}
        </FileDrop>
      </div>
    </div>
  )
}
