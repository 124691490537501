import React, { Component } from 'react'
import classnames from 'classnames'

class FarmItem extends Component {
  render() {
    return(
      <li title={this.props.farmName === '' ? 'Ponto ' + this.props.initials : this.props.farmName}>
        <input id={ classnames('farm_' + this.props.value) } name='farm' type='radio' checked={this.props.checked} value={ this.props.value } onChange={ this.props.selectFarm }/>
        <label className={classnames({'farm-label-size-1': this.props.initials.length === 1, 'farm-label-size-2': this.props.initials.length === 2, 'farm-label-size-3': this.props.initials.length === 3,'farm-label-size-4': this.props.initials.length === 4})} htmlFor={ classnames('farm_' + this.props.value) }>{ this.props.initials }</label>
      </li>
    )
  }
}

export default FarmItem
