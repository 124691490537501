import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import RadioButtonGroup from '../../common/RadioButtonGroup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClickOutside from 'react-click-outside'

class CadDropdown extends Component {
  constructor(props){
    super(props)

    this.radioSelect = this.props.radioSelect
  }

  //Helper methods
  renderOptions(){
    let label = this.props.label || 'Padrão do Ponto'

    let soilOptions = [
      {value: this.props.cad, text: 'Padrão do Ponto'},
      {value: 125, text: 'Muito Argiloso'},
      {value: 100, text: 'Argiloso'},
      {value: 75, text: 'Textura Média'},
      {value: 50, text: 'Textura Média Arenosa'},
      {value: 25, text: 'Arenoso'}
    ]

    return(
      <RadioButtonGroup
        label={label}
        name={'options'}
        listOfItems={soilOptions}
        selectedItemCallback={this.handleRadio}
        checkedOption={this.props.radioSelect}
      />
    )
  }

  //#region Render methods
  handleClick = () => {
    this.props.onClick()
  }

  handleRadio = (e, text) => {
    this.props.onClick()
    this.props.onSendClick(e,'soil', text)
  }
  //#endregion

  render() {
    //This treatment is here for AgryIndex Planting box
    let label = this.props.label || 'Padrão do Ponto'

    return(
        <div
          className={
            classnames(
              'header-div-item-outline',
              {'header-div-item-spacing': this.props.rightNeighbor}
            )
          }
        >
          <Link to='#' onClick={this.handleClick}>
            <div className="button button--retangle">
              <div className='button--retangle-icon-left'>
                <i className='material-icons'>{this.props.leftIcon}</i>
              </div>
              <div
                className={
                  classnames(
                    'button--retangle-icon-text',
                    {'button--retangle-icon-text-2-letters': label.length === 10},
                    {'button--retangle-icon-text-3-letters': label.length === 11}
                  )
                }
              >
                {label}
              </div>
              <div className='button--retangle-icon-right'>
                <i className='material-icons'>arrow_drop_down</i>
              </div>
            </div>
          </Link>
          {
            this.props.active && (
              <ClickOutside onClickOutside={this.props.onClick}>
                <div className={classnames('dropdown-div', 'cad-dropdown')}>
                  <div
                    className={
                      classnames(
                        "button button--retangle",
                        {'button--retangle-active': this.props.active},
                        'button--retangle-active-multiple'
                      )
                    }
                  >
                    <i className='material-icons button--retangle-icon-left'>{this.props.leftIcon}</i>
                    <div className='button--retangle-icon-text'>
                      {label}
                    </div>
                  </div>
                  <PerfectScrollbar className="dropdown-scroll">
                    <div className={classnames('checkbox-list', 'years-list')}>
                      {this.renderOptions()}
                    </div>
                  </PerfectScrollbar>
                </div>
              </ClickOutside>
            )
          }
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  cad: state.cad.cad,
});

export default connect(mapStateToProps)(CadDropdown)
