import { styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

export const AutomaticAuditTableColumn = styled(TableCell)({
  backgroundColor: "#EEF2F3",
  fontWeight: '500',
  fontSize: '1.2rem',
  m: 1,
  width: '65px',
  textAlign: 'center',
  padding:'10px, 5px'
});

export const AutomaticAuditTableStub = styled(TableCell)({
  backgroundColor: "#EEF2F3",
  fontWeight: '500',
  m: 1,
  fontSize: '1.2rem',
  width: '155px',
  padding:'5px 10px',
  border: 'none',
});

export const AutomaticAuditTableCell = styled(TableCell)({
  textAlign: 'center',
  border: '1px solid #EEF2F3',
  borderRight: 'none',
  borderBottom: 'none',
  height:'30px',
  padding:'0px',
});

export const AutomaticAuditTableContainer = styled(TableContainer)({
  backgroundColor: '#FCFCFC',
  margin: "50px 15px",
});