import { Grid, Typography } from "@mui/material";
import React from 'react';
import { downloadSpreadsheet, uploadSpreadsheet } from '../../api/admin';
import excelImage from '../../images/excel.png';
import { ButtonUpload, VisuallyHiddenInput } from './styles/ChangeUploadAnDownloadFile';
import Alert from "../common/Alert";


function UploadDataFromValidateStations() {
  const [alert, setAlert] = React.useState(false);
  const [alertData, setAlertData] = React.useState({
    type: '',
    title: '',
    textOne: '',
    textTwo: ''
  });

  const clearAlert = () => {
    setTimeout(() => {
      setAlert(false);
    }, "4000");
  }

  const handleFile = async (e) => {
    let selectFile = e.target.files[0];
    let message;

    const response = await uploadSpreadsheet(selectFile);

    if(response && response.status === 200) {
      message = {
        type: 'sucess',
        title: 'Sucesso!',
        textOne: 'O upload foi concluído com sucesso e as informações',
        textTwo: 'serão incluídas no sistema.'
      }
      setAlertData(message);
      setAlert(true);
      clearAlert();
    } else {
      message = {
        type: 'error',
        title: 'Falha!',
        textOne: 'O formato da planilha não é compatível! Baixe e',
        textTwo: 'preencha a planilha modelo novamente.'
      }
      setAlert(true);
      setAlertData(message);
      clearAlert();
    }
  }

  return (
    <div container className='row admin-information' style={{ margin: '40px', marginTop: '0', width: '70%' }}>
      {alert && <Alert data={alertData} />}
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mt: '40px', mb: '80px' }}>
          <Typography component="h5" variant="h5">
            Fazer upload de dados de estações validadas
          </Typography>
          <Typography
            onClick={() => downloadSpreadsheet()}
            component="body2"
            variant="body2"
            sx={{
                mt: 2,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'translate(11px, 1%)',
                }
              }}
            >
            <img
              style={{ width: '25px', height: '25px', marginRight: '8px' }}
              src={excelImage}
              alt="excel-icon"
            />
            Baixar planilha modelo
          </Typography>
          <ButtonUpload
            component="label"
            variant="contained"
          >
            Fazer Upload
            <VisuallyHiddenInput type="file" onChange={handleFile} />
          </ButtonUpload>
        </Grid>
      </Grid>
    </div>
  );
}

export default UploadDataFromValidateStations;
