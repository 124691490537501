import { combineReducers } from 'redux'

import auth from './auth'
import Application from './application'
import Reports from './reports'
import farms from './farms'
import map from './map'
import global from './global'
import enos from './enos'
import users from './users'
import admin from './admin'
import productivity from './productivity'
import profile from './profile'
import integration from './integration'
import cad from './cad'
import flowering from './flowering'
import schedule from './schedule'
import dashboard from './dashboard'
import notification from './notification'
import generalCaneProductivityReducer from './generalCaneProductivityReducer'
import { loadingBarReducer } from 'react-redux-loading-bar'

export default combineReducers({
  auth,
  Application,
  Reports,
  farms,
  map,
  global,
  enos,
  users,
  admin,
  loadingBar: loadingBarReducer,
  productivity,
  profile,
  integration,
  cad,
  flowering,
  schedule,
  dashboard,
  notification,
  generalCaneProductivityReducer,
});