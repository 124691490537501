import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Label, LabelList, Cell } from "recharts";

const DashboardBarChart = ({ data, label }) => {

    const colors =["#327C0E", "#57B828", "#9BFF5D", "#47E170"]

    return (
        <ResponsiveContainer width="100%" >
            <BarChart data={data} maxBarSize={50} margin={{top: 40, right: 30, left: 30, bottom: 5}}>
                <YAxis>
                    <Label angle={-90} value={label} position="left" style={{textAnchor: 'middle'}}/>
                </YAxis>
                <XAxis dataKey="name"/>
                <Bar dataKey="value">
                    <LabelList dataKey="value" position="top" fill="#000000"/>
                    {data.map((entry, index) => (
                        <Cell key={index} fill={colors[index]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default DashboardBarChart