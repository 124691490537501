import React, { Component } from 'react'
import classnames from 'classnames'

class ReportOption extends Component {

  setFullName(){
    if(this.props.value === 'bhbox'){
      return 'Armazenamento Relativo'
    }else if(this.props.value === 'extbh'){
      return 'Extrato do Balanço Hídrico'
    }else if(this.props.value === 'etretp'){
      return 'Evapotranspiração Real e Potencial'
    }else if(this.props.value === 'excdef'){
      return 'Excedente e Deficiência Hídrica'
    }else if(this.props.value === 'arm'){
      return 'Armazenamento'
    }else if(this.props.value === 'temperatura'){
      return 'Temperatura'
    }else if(this.props.value === 'rs'){
      return 'Radiação Solar'
    }else if(this.props.value === 'gd'){
      return 'Graus-dia'
    }else if(this.props.value === 'u2'){
      return 'Vento a 2 Metros'
    }else if(this.props.value === 'prec'){
      return 'Chuva'
    }else if(this.props.value === 'ur'){
      return 'Umidade Relativa'
    }else if(this.props.value === 'etp'){
      return 'Evapotranspiração Potencial'
    }else if(this.props.value === 'clim'){
      return 'Climatologia'
    }
  }


  render() {
    return(
      <div
        className='option col s4'
        title={this.setFullName()}
        style={{
          display: this.props.hidden ? 'none' : 'flex',
        }}
      >
        <input 
          id={ classnames('reportType_' + this.props.value) }
          name='reportType' type='checkbox' onClick={this.props.onSubmit}
          value={ this.props.value } onChange={ this.props.onChange }
        />
        <label
          htmlFor={ classnames('reportType_' + this.props.value) }
          className={ classnames('option--container', { 'disabled': this.props.disabled, 'active': this.props.reports > -1 }) }
        >
          <img 
            alt={this.props.icon} 
            style={{height: '36px', width: '36px'}} 
            src={this.props.icon}
          />
          <h4 className='option--container-title'>{ this.props.label }</h4>
        </label>
      </div>
    )
  }
}

ReportOption.defaultProps = {
  disabled: false,
  hidden: false
}

export default ReportOption
