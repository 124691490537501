import React from 'react'

import InputField from '../../common/InputField'
import { Grid, Button } from '@material-ui/core'

export function AgrymaxApiManagement(props){
    return(
        <Grid container className='api-control' justify='space-between' alignItems='center'>
            <Grid item>
                <h5 className='title-row'>
                    Chave da API Agrymax
                </h5>
            </Grid>
            <Grid item>
                {props.api_key ?
                    <Button
                        className='green-button api'
                        variant='contained'
                        onClick={props.onClickApiButton}
                    >
                        Deletar chave
                    </Button>
                :
                    <Button
                        className='green-button api'
                        variant='contained'
                        onClick={props.onClickApiButton}
                    >
                        Gerar chave
                    </Button>
                }
            </Grid>
            <Grid container>
                <Grid item xs={8} className='api-key'>
                    <InputField
                        name='api'
                        label='API'
                        type='text'
                        value={props.api_key? props.api_key : ''  }
                        error={undefined}
                        active={true}
                        activeFarm={false}
                    />
                </Grid>
                {props.api_key && 
                    <Grid item xs={8} className='api-key' container justify='space-between'>
                        <Grid item xs={12}>
                            <div className='api-label'>Período permitido para download pela API:</div>
                        </Grid>
                        <Grid item>
                            <InputField
                                name='initial_api_year'
                                label='De'
                                type='text'
                                value={props.fromYear}
                                onChange={props.onChange}
                                error={undefined}
                                active={true}
                                activeFarm={false}
                            />
                        </Grid>
                        <Grid item>
                            <InputField
                                name='final_api_year'
                                label='Até'
                                type='number'
                                value={props.toYear}
                                onChange={props.onChange}
                                error={undefined}
                                active={true}
                                activeFarm={false}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                className='green-button api'
                                variant='contained'
                                onClick={props.changeYearPeriod}>
                                Salvar Período
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

