import React from 'react';

import StyledDropzone from '../common/StyledDropzone';
import UploadFileButton from '../common/UploadFileButton';

import { Typography } from '@material-ui/core';

function KmzDropAndButton({ setFile, classes, setToaster }) {
  return (
    <div>
      <StyledDropzone setFile={ setFile } setToaster={ setToaster } />
      <Typography
        className={ classes.formSubtitle }
        component="h3"
        variant="subtitle1"
        align="center"
      >
        ou
      </Typography>
      <UploadFileButton setFile={ setFile } setToaster={ setToaster } />
    </div>
  );
}

export default KmzDropAndButton;
