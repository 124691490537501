import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import classnames from 'classnames'

import FarmsList from './FarmsList'
import AddFarm from './AddFarm'
import { showToaster } from './common/Toaster'

import { allowMapClickEvent} from '../actions/map'
import {toggleSidebar, toggleMoreFarms} from '../actions/global'


class SidebarHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formActive: false
    }
  }

  //#region Helper methods
  renderFarmsList() {
    if(!this.state.formActive) {
      return(
        <FarmsList selectFarm={ this.handleSelectFarm }/>
      )
    }
  }
  //#endregion

  //#region Render methods
  showAlert = () => {
    const toasterParams = {
      title: 'Local no Mapa',
      message: 'Clique no mapa para selecionar a nova localização.',
      status: true,
      autoClose: false,
      buttons: [
        {
          name: 'CANCELAR',
          function: toast.dismiss
        }
      ]
    }
    showToaster(toasterParams)
  }

  toggleActive = () => {
    this.setState({ formActive: !this.state.formActive })
  }

  handleAlert = () => {
    if(this.props.allowMapClickEvent === true){
      this.props.setMapClickEvent()
    }
  }

  handleSelectFarm = (e) => {
    this.props.selectFarm(e)
    if(!this.props.sidebarActive){
      this.props.toggleSidebar()
    }
    if(this.props.moreFarmsActive){
      this.props.toggleMoreFarmsActive()
    }
  }
  //#endregion

  render() {
    return(
      <div className={classnames('sidebar-header left', {'sidebar-inactive' : !this.props.sidebarActive})}>
        <AddFarm
          active={ this.state.formActive }
          toggleActive={ this.toggleActive }
          sidebar={this.props.sidebarActive}
          toggleSidebar={this.props.toggleSidebar}
          showAlert={this.showAlert}
          moreFarmsActive={this.props.moreFarmsActive}
          toggleMoreFarmsActive={this.props.toggleMoreFarmsActive}/>
        { this.renderFarmsList() }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms,
    allowMapClickEvent: state.map.allowMapClickEvent,
    sidebarActive: state.global.sidebarActive,
    moreFarmsActive: state.global.moreFarmsActive
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMapClickEvent: () => {
      dispatch(allowMapClickEvent())
    },
    toggleSidebar: () =>{
      dispatch(toggleSidebar())
    },
    toggleMoreFarmsActive: () =>{
      dispatch(toggleMoreFarms())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader)
