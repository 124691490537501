import axios from 'axios';
import { authHeader } from "./config"

export const getAutomaticAudit = async (audit) => {
  const END_POINT = process.env.REACT_APP_API + 'api/' + audit;
  try {
    const data = await axios.get(END_POINT, {
      headers: authHeader('axios')
    });
    return data;
  } catch (error) {
  }
};

export const getManualAudit = async () => {
  const END_POINT = process.env.REACT_APP_API + 'api/audits/manual';
  try {
    const { data: { data } } = await axios.get(END_POINT, {
      headers: authHeader('axios'),
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getServicesStatus = async () => {
  const END_POINT = process.env.REACT_APP_API + 'api/services';
  try {
    const { data: { data } } = await axios.get(END_POINT, {
      headers: authHeader('axios'),
    });
    return data;
  } catch (error) {
    return error;
  }
};
