import { authHeader } from "./config";
import { authHeaderUpload } from "./configUpload";
import getGeneratedMapString from "./utils";

class MapApi {
  static generateMapData(reportTypes, attrs) {
    return fetch(
      process.env.REACT_APP_API + getGeneratedMapString(reportTypes, attrs),
      {
        method: "GET",
        headers: authHeader(),
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getNeutralGrid() {
    return fetch(process.env.REACT_APP_API + "api/map/grid", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        initial_date: "1981-01-01",
        final_date: "1981-01-01",
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getXavierGrid() {
    return fetch(process.env.REACT_APP_API + "api/map/names", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getKmzFile() {
    return fetch(process.env.REACT_APP_API + "api/profile/upload_kmz", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static updateKmzFile(id, name, file) {
    const data = new FormData();
    data.append("file", file);
    data.append("name", name);

    return fetch(process.env.REACT_APP_API + `api/profile/upload_kmz?kmz_id=${id}`, {
      method: 'PUT',
      headers: authHeaderUpload(),
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static deleteKmzFile(id) {
    return fetch(process.env.REACT_APP_API + `api/profile/upload_kmz?kmz_id=${id}`, {
      method: "DELETE",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
}

export default MapApi;

