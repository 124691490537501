import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 

import { Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';

import { adminGetPoints } from '../../../actions/admin';

function AvailablePoints({ info, setInfo }) {
  const points = useSelector((state) => state.admin.availablePoints);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminGetPoints(info.source.value));
  }, [dispatch, info]);

  const handleAllChecks = ({ target }) => {
    const pointsIds = target.checked ? points.map((point) => point.id) : [];
    setInfo({ ...info, points: pointsIds });
  };

  const handleSpecificCheck = (e, id) => {
    const newPoints = e.target.checked
      ? [...info.points, id]
      : info.points.filter((point) => point !== id);

    setInfo({ ...info, points: newPoints });
  };

  return (
    <>
      <Typography component="h6" variant="subtitle1">
        Pontos:
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            onChange={handleAllChecks}
            label="Todos"
            control={<Checkbox style={{ color: "#3EBCF6" }} />}
          />
        </Grid>
        {
          points?.map(( { id, name, initials }, index ) => {
            return (
              <Grid item xs={3} key={ index }>
                <FormControlLabel
                  onChange={(e) => handleSpecificCheck(e, id)}
                  label={name || initials}
                  control={
                    <Checkbox
                      style={{ color: "#3EBCF6" }}
                      checked={ info.points.includes(id)}
                    />
                  }
                />
              </Grid>
            );
          })
        }
      </Grid>
    </>
  );
}

export default AvailablePoints;
