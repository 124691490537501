import React from "react"

import Draggable from "react-draggable"
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core"

const DashboardLegend = ({openLegend}) => {

    return (
        <Draggable>
            <Dialog 
                open={openLegend} 
                maxWidth='md'
                style={{pointerEvents: "none"}}
                BackdropProps= {{
                    invisible: true,
                }}
                PaperProps= {{
                    style: {
                        position: "absolute",
                        left: "1%",
                        bottom: "1.5%",
                    }
                }}
            >
                <DialogTitle>
                    Legenda
                    <i className="material-icons float-right">drag_handle</i>
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <div className="productivity">
                        <p><b>PP</b> - Produtividade Potencial </p>
                        <p><b>PA</b> - Produtividade Atingível </p>
                        <p><b>PR</b> - Produtividade Real </p>
                        <p><b>PM</b> - Produtividade Almejada </p>
                    </div>

                    <div className="efficiency">
                        <p><b>EC</b> - Eficiência Climática </p>
                        <p><b>EA</b> - Eficiência Agrícola </p>
                        <p><b>EM</b> - Eficiência Almejada </p>
                    </div>

                    <div className="yield">
                        <p><b>YG_MAN</b> - "Yield Gap" por Manejo </p>
                        <p><b>YG_DH</b>  - "Yield Gap" por Déficit Hídrico </p>
                    </div>
                </DialogContent>
            </Dialog>
        </Draggable>
    )
}

export default DashboardLegend;