import {
  PRODUCTIVITY,
  CANE,
  SOY,
  CORN,
  COTTON,
  WHEAT,
  EUCALYPTUS,
} from "../components/reports/productivity/common/defaultValues";
import { authHeader } from "./config";

function normalizeEfficiency(data) {
  let efficiency =
    data.agriculturalEficiency !== "" &&
    data.agriculturalEficiency !== undefined
      ? parseFloat(data.agriculturalEficiency)
      : parseFloat(PRODUCTIVITY.EFFICIENCY);
  return (efficiency = efficiency > 1 ? efficiency / 100 : efficiency);
}

class ProductivityApi {
  static handleBody(data) {
    const formData = {
      lat: data.lat,
      lon: data.lon,
      cad: data.cad,
      point_id: data.point_id,
      agry_eficiency: normalizeEfficiency(data),
      initial_user_month: data.initialMonth
        ? data.initialMonth
        : PRODUCTIVITY.INITIAL_MONTH,
      final_user_month: data.finalMonth
        ? data.finalMonth
        : PRODUCTIVITY.FINAL_MONTH,
      first_cut_prop:
        data.canePlantedProportion !== null &&
        data.canePlantedProportion !== undefined
          ? data.canePlantedProportion
          : CANE.PLANTED_PROPORTION,
      second_cut_prop:
        data.cane2Proportion !== null && data.cane2Proportion !== undefined
          ? data.cane2Proportion
          : CANE.PLANTED_PROPORTION,
      third_cut_prop:
        data.cane3Proportion !== null && data.cane3Proportion !== undefined
          ? data.cane3Proportion
          : CANE.PLANTED_PROPORTION,
      fourth_cut_prop:
        data.cane4Proportion !== null && data.cane4Proportion !== undefined
          ? data.cane4Proportion
          : CANE.PLANTED_PROPORTION,
      fifth_or_plus_prop:
        data.cane5Proportion !== null && data.cane5Proportion !== undefined
          ? data.cane5Proportion
          : CANE.PLANTED_PROPORTION,
      cycle: data.cycle ? data.cycle : CANE.CYCLE,
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      kdec:
        data.kdec !== null && data.kdec !== undefined ? data.kdec : CANE.KDEC,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
      recalc_flag: data.recalc ? data.recalc : PRODUCTIVITY.RECALC,
      visualization_type: data.visualization_type || 'civil',
    };
    return formData;
  }
  static handleBodyCustomized(data) {
    const formData = {
      month: data.month,
      points_id: data.point_id,
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      cc_user: data.cc_user ? data.cc_user : CANE.CC,
      u_percent_user: data.u_percent_user
        ? data.u_percent_user
        : CANE.U_PERCENT,
      agry_eficiency: normalizeEfficiency(data),
      first_cut_prop:
        data.canePlantedProportion !== null &&
        data.canePlantedProportion !== undefined
          ? data.canePlantedProportion
          : CANE.PLANTED_PROPORTION,
      second_cut_prop:
        data.cane2Proportion !== null && data.cane2Proportion !== undefined
          ? data.cane2Proportion
          : CANE.PLANTED_PROPORTION,
      third_cut_prop:
        data.cane3Proportion !== null && data.cane3Proportion !== undefined
          ? data.cane3Proportion
          : CANE.PLANTED_PROPORTION,
      fourth_cut_prop:
        data.cane4Proportion !== null && data.cane4Proportion !== undefined
          ? data.cane4Proportion
          : CANE.PLANTED_PROPORTION,
      fifth_or_plus_prop:
        data.cane5Proportion !== null && data.cane5Proportion !== undefined
          ? data.cane5Proportion
          : CANE.PLANTED_PROPORTION,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
      kdec:
        data.kdec !== null && data.kdec !== undefined ? data.kdec : CANE.KDEC,
      cut_number: data.cut_number ? data.cut_number : CANE.CUT_NUMBER,
      cycle: data.cycle ? data.cycle : CANE.CYCLE,
      recalc_flag: data.recalc ? data.recalc : PRODUCTIVITY.RECALC,
    };
    return formData;
  }

  static handleBodyCaneProductivity(data) {
    const formData = {
      lat: data.lat,
      lon: data.lon,
      cad: parseInt(data.cad),
      point_id: data.point_id,
      agry_eficiency: normalizeEfficiency(data),
      cane_type: data.cane_type ? data.cane_type : CANE.TYPE,
      cut_number: data.cut_number ? data.cut_number : CANE.CUT_NUMBER,
      cycle: data.cycle ? data.cycle : CANE.CYCLE,
      date: data.date ? data.date : PRODUCTIVITY.DATE,
      cc_user: data.cc_user ? data.cc_user : CANE.CC,
      u_percent_user: data.u_percent_user
        ? data.u_percent_user
        : CANE.U_PERCENT,
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      kdec:
        data.kdec !== null && data.kdec !== undefined ? data.kdec : CANE.KDEC,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
      recalc_flag: data.recalc ? data.recalc : PRODUCTIVITY.RECALC,
    };
    return formData;
  }

  static handleBodySoyProductivity(data, type) {
    const formData = {
      type: type,
      lat: data.lat,
      lon: data.lon,
      cad: data.cad,
      point_id: data.point_id,
      agry_eficiency: normalizeEfficiency(data),
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      cycle: data.cycle ? data.cycle : SOY.CYCLE,
      planting_date: data.date ? data.date : PRODUCTIVITY.DATE,
      cc_user: data.cc_user ? data.cc_user : SOY.CC,
      u_percent_user: data.u_percent_user ? data.u_percent_user : SOY.U_PERCENT,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
    };

    return formData;
  }

  static handleBodyCornProductivity(data, type) {
    const formData = {
      type: type,
      lat: data.lat,
      lon: data.lon,
      cad: data.cad,
      point_id: data.point_id,
      agry_eficiency: normalizeEfficiency(data),
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      cycle: data.cycle ? data.cycle : CORN.CYCLE,
      planting_date: data.date ? data.date : PRODUCTIVITY.DATE,
      cc_user: data.cc_user ? data.cc_user : CORN.CC,
      u_percent_user: data.u_percent_user
        ? data.u_percent_user
        : CORN.U_PERCENT,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
    };

    return formData;
  }

  static handleBodyCottonProductivity(data, type) {
    const formData = {
      type: type,
      lat: data.lat,
      lon: data.lon,
      cad: data.cad,
      point_id: data.point_id,
      agry_eficiency: normalizeEfficiency(data),
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      cycle: data.cycle ? data.cycle : COTTON.CYCLE,
      planting_date: data.date ? data.date : PRODUCTIVITY.DATE,
      cc_user: data.cc_user ? data.cc_user : COTTON.CC,
      u_percent_user: data.u_percent_user
        ? data.u_percent_user
        : CORN.U_PERCENT,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
    };
    return formData;
  }

  static handleBodyWheatProductivity(data, type) {
    const formData = {
      type: type,
      lat: data.lat,
      lon: data.lon,
      cad: data.cad,
      point_id: data.point_id,
      agry_eficiency: normalizeEfficiency(data),
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      cycle: data.cycle ? data.cycle : WHEAT.CYCLE,
      planting_date: data.date ? data.date : PRODUCTIVITY.DATE,
      cc_user: data.cc_user ? data.cc_user : WHEAT.CC,
      u_percent_user: data.u_percent_user
        ? data.u_percent_user
        : WHEAT.U_PERCENT,
      frost_flag:
        data.frost !== null && data.frost !== undefined
          ? data.frost
          : PRODUCTIVITY.FROST,
    };

    return formData;
  }

  static handleBodyEucalyptusProductivity(data, type) {
    const formData = {
      type: type,
      range: data.range ? data.range : EUCALYPTUS.RANGE,
      lat: data.lat,
      lon: data.lon,
      cad: data.cad,
      point_id: data.point_id,
      clone: data.clone ? data.clone : EUCALYPTUS.CLONE,
      agry_eficiency: normalizeEfficiency(data),
      crop_year: data.crop_year ? data.crop_year : PRODUCTIVITY.CROP_YEAR,
      cycle: data.cycle ? data.cycle : EUCALYPTUS.CYCLE,
      planting_date: data.date ? data.date : EUCALYPTUS.DATE,
      tree_bark: data.tree_bark ? data.tree_bark / 100 : EUCALYPTUS.TREE_BARK,
    };

    return formData;
  }

  static generateProductivity(data) {
    const endpoint = `${process.env.REACT_APP_API}api/productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(data.frost_flag ? data : this.handleBody(data)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static getCustomizedData(data) {
    const endpoint = `${process.env.REACT_APP_API}api/productivity/customized?points_id=${data.point_id}&initial_month=${data.initial_month}&final_month=${data.final_month}&crop_year=${data.crop_year}&visualization_type=${data.visualization_type}`;

    return fetch(endpoint, {
      method: "GET",
      headers: authHeader(),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static postCustomizedData(data) {
    const endpoint = `${process.env.REACT_APP_API}/api/productivity/customized`;

    const dataConvertedToObject = Object.assign({}, data);
    const monthlyDataConvertedToObject = Object.assign({}, data.monthly_data);
    dataConvertedToObject["monthly_data"] = monthlyDataConvertedToObject;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(dataConvertedToObject),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static getObservedProductivityData(data) {
    const endpoint = `${
      process.env.REACT_APP_API
    }api/observed_productivity?point_id=${data.point_id}&prod_type=${
      data.prod_type
    }&initial_month=${data.initial_month}&final_month=${
      data.final_month
    }&crop_year=${data.crop_year}&visualization_type=${data.visualization_type}`;

    return fetch(endpoint, {
      method: "GET",
      headers: authHeader(),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static postObservedProductivityData(data) {
    const endpoint = `${
      process.env.REACT_APP_API
    }api/observed_productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(data),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static saveCustomizedData(data) {
    const endpoint = `${process.env.REACT_APP_API}api/productivity/customized`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodyCustomized(data)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static generateSpecificProductivity(data) {
    const endpoint = `${process.env.REACT_APP_API}api/productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodyCaneProductivity(data)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static generateSoyProductivity(data, type) {
    const endpoint = `${process.env.REACT_APP_API}api/soy_productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodySoyProductivity(data, type)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
        return error;
      });
  }

  static generateCornProductivity(data, type) {
    const endpoint = `${process.env.REACT_APP_API}api/corn_productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodyCornProductivity(data, type)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
       return error;
      });
  }

  static generateCottonProductivity(data, type) {
    const endpoint = `${process.env.REACT_APP_API}api/cotton_productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodyCottonProductivity(data, type)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
       return error;
      });
  }

  static generateWheatProductivity(data, type) {
    const endpoint = `${process.env.REACT_APP_API}api/wheat_productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodyWheatProductivity(data, type)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
       return error;
      });
  }

  static generateEucalyptusProductivity(data, type) {
    const endpoint = `${process.env.REACT_APP_API}api/eucalyptus_productivity`;

    return fetch(endpoint, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.handleBodyEucalyptusProductivity(data, type)),
      timeout: 0,
    })
      .then((response) => response.json())
      .catch((error) => {
       return error;
      });
  }
}

export default ProductivityApi;
