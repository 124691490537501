import React, { useEffect, useState } from "react";

import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";

const columns = [
  { id: "year", label: "Ano", width: 157, align: "left" },
  { id: "civil_year", label: "Ano Civil", width: 157, align: "left" },
  { id: "agri_year", label: "Ano Agricultura", width: 157, align: "left" },
  { id: "civil_sem", label: "Semestre Civil", width: 157, align: "left" },
  {
    id: "agri_sem",
    label: "Semestre Agricultura",
    width: 157,
    align: "left",
  },
];

export function EnosListNewComponent({ getEnosList, enos, toggleAddEvent, addFlag, history }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [enosState, setEnos] = useState([]);
  const [isOrdered, setIsOrdered] = useState(false);

  useEffect(() => {
    if (enos === undefined) {
      getEnosList();
    }
  }, [getEnosList, enos]);

  useEffect(() => {
    if (enos !== undefined) {
      setEnos(enos);
    }
  }, [enos]);

  const onRowClick = (rowInfo) => {
    history.push({
      pathname: `/admin/enos/edit/${rowInfo.year}`,
      state: { item: rowInfo },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchByInput = ({ target: { value } }) =>
    setEnos(
      enos.filter(
        (eno) =>
          eno.year.toString().includes(value) ||
          eno.civil_year.toLowerCase().includes(value.toLowerCase()) ||
          eno.agri_year.toLowerCase().includes(value.toLowerCase()) ||
          eno.civil_sem.toLowerCase().includes(value.toLowerCase()) ||
          eno.agri_sem.toLowerCase().includes(value.toLowerCase())
      )
    );

  const sortByColumns = ({ id }) => {
    setIsOrdered(!isOrdered);

    return isOrdered
      ? setEnos(
          enosState.sort(function (a, b) {
            if (a[id] < b[id]) {
              return 1;
            }
            if (a[id] > b[id]) {
              return -1;
            }
            return 0;
          })
        )
      : setEnos(
          enosState.sort(function (a, b) {
            if (a[id] > b[id]) {
              return 1;
            }
            if (a[id] < b[id]) {
              return -1;
            }
            return 0;
          })
        );
  };

  return (
    <>
      {enos ? (
        <Grid container item lg={11} xl={11} style={{ margin: "auto" }}>
          <Grid item lg={12} xl={12}>
            <h5>Enos</h5>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Grid item container xl={4} lg={4} alignItems="center">
              <Grid item>
                <TextField
                  variant="outlined"
                  placeholder="Digite para pesquisar"
                  onChange={searchByInput}
                  inputProps={{
                    style: {
                      height: "28px",
                      margin: "0",
                      border: "1px solid #808080",
                      padding: '1px',
                    }
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4}>
                <SearchIcon
                  style={{
                    backgroundColor: "#6AA426",
                    color: "white",
                    width: "50px",
                    height: "30px",
                    margin: "6px 0 0 0 ",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Link to="/admin/enos/add">
                <Button
                  style={{
                    backgroundColor: "#6AA426",
                    color: "white",
                  }}
                >
                  Adicionar
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} xl={12}>
              <Table>
                <TableHead
                  style={{
                    backgroundColor: "#6AA426",
                    cursor: "pointer",
                    width: "150px",
                  }}
                >
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        onClick={() => sortByColumns(column)}
                        key={index}
                        align={column.align}
                        style={{
                          width: column.width,
                          padding: "0 10px 0 10px ",
                          color: "white",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enosState
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                onClick={() => onRowClick(row)}
                                key={column.id}
                                align={column.align}
                                style={{ padding: "0 10px" }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={enos.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Itens por página"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div>carregando...</div>
      )}
    </>
  );
}
