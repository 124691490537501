const date = new Date();
const noBicestYear = Number(date.getFullYear) % 4 !== 0;

const decendiosArray = [
  {
    name: 'jan_1',
    fistDate: '01/01',
    lastDate: '10/01',
  },
  {
    name: 'jan_2',
    fistDate: '11/01',
    lastDate: '20/01',
  },
  {
    name: 'jan_3',
    fistDate: '21/01',
    lastDate: '31/01',
  },
  {
    name: 'fev_1',
    fistDate: '01/02',
    lastDate: '10/02',
  },
  {
    name: 'fev_2',
    fistDate: '11/02',
    lastDate: '20/02',
  },
  {
    name: 'fev_3',
    fistDate: '21/02',
    lastDate: noBicestYear ? '28/02' : '29/02',
  },
  {
    name: 'mar_1',
    fistDate: '01/03',
    lastDate: '10/03',
  },
  {
    name: 'mar_2',
    fistDate: '11/03',
    lastDate: '20/03',
  },
  {
    name: 'mar_3',
    fistDate: '21/03',
    lastDate: '31/03',
  },
  {
    name: 'abr_1',
    fistDate: '01/04',
    lastDate: '10/04',
  },
  {
    name: 'abr_2',
    fistDate: '11/04',
    lastDate: '20/04',
  },
  {
    name: 'abr_3',
    fistDate: '21/04',
    lastDate: '30/04',
  },
  {
    name: 'mai_1',
    fistDate: '01/05',
    lastDate: '10/05',
  },
  {
    name: 'mai_2',
    fistDate: '11/05',
    lastDate: '20/05',
  },
  {
    name: 'mai_3',
    fistDate: '21/05',
    lastDate: '31/05',
  },
  {
    name: 'jun_1',
    fistDate: '01/06',
    lastDate: '10/06',
  },
  {
    name: 'jun_2',
    fistDate: '11/06',
    lastDate: '20/06',
  },
  {
    name: 'jun_3',
    fistDate: '21/06',
    lastDate: '30/06',
  },
  {
    name: 'jul_1',
    fistDate: '01/07',
    lastDate: '10/07',
  },
  {
    name: 'jul_2',
    fistDate: '11/07',
    lastDate: '20/07',
  },
  {
    name: 'jul_3',
    fistDate: '21/07',
    lastDate: '31/07',
  },
  {
    name: 'ago_1',
    fistDate: '01/08',
    lastDate: '10/08',
  },
  {
    name: 'ago_2',
    fistDate: '11/08',
    lastDate: '20/08',
  },
  {
    name: 'ago_3',
    fistDate: '21/08',
    lastDate: '31/08',
  },
  {
    name: 'set_1',
    fistDate: '01/09',
    lastDate: '10/09',
  },
  {
    name: 'set_2',
    fistDate: '11/09',
    lastDate: '20/09',
  },
  {
    name: 'set_3',
    fistDate: '21/09',
    lastDate: '30/09',
  },
  {
    name: 'out_1',
    fistDate: '01/10',
    lastDate: '10/10',
  },
  {
    name: 'out_2',
    fistDate: '11/10',
    lastDate: '20/10',
  },
  {
    name: 'out_3',
    fistDate: '21/10',
    lastDate: '31/10',
  },
  {
    name: 'nov_1',
    fistDate: '01/11',
    lastDate: '10/11',
  },
  {
    name: 'nov_2',
    fistDate: '11/11',
    lastDate: '20/11',
  },
  {
    name: 'nov_3',
    fistDate: '21/11',
    lastDate: '30/11',
  },
  {
    name: 'dez_1',
    fistDate: '01/12',
    lastDate: '10/12',
  },
  {
    name: 'dez_2',
    fistDate: '11/12',
    lastDate: '20/12',
  },
  {
    name: 'dez_3',
    fistDate: '21/12',
    lastDate: '31/12',
  },
];

const monthsArray = [
  {
    name: 'jan',
    fistDate: '01/01',
    lastDate: '31/01',
  },
  {
    name: 'fev',
    fistDate: '01/02',
    lastDate: '28/02',
  },
  {
    name: 'mar',
    fistDate: '01/03',
    lastDate: '31/03',
  },
  {
    name: 'abr',
    fistDate: '01/04',
    lastDate: '30/04',
  },
  {
    name: 'mai',
    fistDate: '01/05',
    lastDate: '31/05',
  },
  {
    name: 'jun',
    fistDate: '01/06',
    lastDate: '30/06',
  },
  {
    name: 'jul',
    fistDate: '01/07',
    lastDate: '31/07',
  },
  {
    name: 'ago',
    fistDate: '01/08',
    lastDate: '31/08',
  },
  {
    name: 'set',
    fistDate: '01/09',
    lastDate: '30/09',
  },
  {
    name: 'out',
    fistDate: '01/10',
    lastDate: '31/10',
  },
  {
    name: 'nov',
    fistDate: '01/11',
    lastDate: '30/11',
  },
  {
    name: 'dez',
    fistDate: '01/12',
    lastDate: '31/12',
  },
];

const initialDateArray = ['01/01', '01/02', '01/03', '01/04', '01/05', '01/06', '01/07', '01/08', '01/09','01/10', '01/11', '01/12'];

const finalDateArray = ['31/01', '28/02', '31/03', '30/04', '31/05', '30/06', '31/07', '31/08', '30/09','31/10', '30/11', '31/12'];

export default { decendiosArray, monthsArray, initialDateArray, finalDateArray };

