import DashboardApi from "../api/dashboard"
import { toggleLoader } from "./global"

export function getDashboardTable(data) {
    return function(dispatch) {
        dispatch(toggleLoader())
        return DashboardApi.getDashboardTable(data)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(getTableSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function addDashboardCsv(data) {
    return function(dispatch) {
        dispatch(toggleLoader())
        return DashboardApi.addDashboardCsv(data)
            .then(response => {
                dispatch(addDataSuccess(response))
            })
            .catch(error => {throw(error)})
            .finally(dispatch(toggleLoader()))
    }
}

export function addDashboardLine(data) {
    return function(dispatch) {
        dispatch(toggleLoader())
        return DashboardApi.addDashboardLine(data)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(addLineSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function editDashboardLine(data) {
    return function(dispatch) {
        dispatch(toggleLoader())
        return DashboardApi.editDashboardLine(data)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(editLineSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function deleteDashboardLine(data) {
    return function(dispatch) {
        dispatch(toggleLoader())
        return DashboardApi.deleteDashboardLine(data)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(deleteLineSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

export function getDashboardCharts(data) {
    return function(dispatch) {
        dispatch(toggleLoader())
        return DashboardApi.getDashboardChart(data)
            .then(response => {
                dispatch(toggleLoader())
                dispatch(getChartSuccess(response))
            })
            .catch(error => {throw(error)})
    }
}

// Reducer actions
export function setDashboardCulture(culture) {
    return ({ type: "SET_DASHBOARD_CULTURE", culture})
}

export function getTableSuccess(response) {
    return ({ type: "GET_TABLE_SUCCESS", response})
}

export function addDataSuccess(response) {
    return ({ type: "ADD_PAYLOAD_SUCCESS", response})
}

export function addLineSuccess(response) {
    return ({ type: "ADD_DASHBOARD_LINE", response})
}

export function editLineSuccess(response) {
    return ({ type: "EDIT_DASHBOARD_LINE", response})
}

export function deleteLineSuccess(response) {
    return ({ type: "DELETE_DASHBOARD_LINE", response})
}

export function resetDashboardMessage() {
    return ({ type: "RESET_DASHBOARD_MESSAGE" })
}

export function getChartSuccess(response) {
    return ({ type: "GET_CHARTS_SUCCESS", response})
}