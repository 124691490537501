import React, { useState } from 'react';
import  { useSelector, useDispatch } from 'react-redux';

import ScheduleApi from '../../api/schedule';
import { deleteScheduleSuccess, editScheduleSuccess, getSchedules } from '../../actions/schedule';

import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import DeleteModal from './DeleteModal';
import ScheduleTableRow from './SchedulteTableRow';
import CustomTablePagination from './CustomTablePagination';

const styles = (theme) => ({
  tableCellHead: {
    backgroundColor: '#D1FCA0',
    color: 'black',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '600',
    padding: '5px 23px 5px 23px',
  },
  tableCellBody: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    color: '#373737',
    padding: '5px 23px 5px 23px',
  },
  oddCell: {
    backgroundColor: '#EEEEEE',
  },
  evenCell: {
    backgroundColor: '#FFFFFF',
  },
  icons: {
    backgroundColor: '#DDF8BF',
    color: '#373737',
    boxShadow: 'none',
    marginRight: '15px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  deleteModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFFFFF',
    border: '0.2px solid #707070',
    borderRadius: '16px',
    padding: '16px',
  },
  modalTitle: {
    fontSize: '1.2rem',
    fontFamily: 'Roboto',
    color: '#6AA426',
  },
  modalText: {
    fontSize: '1rem',
    fontFamily: 'Roboto',
    color: '#000000',
  },
  modalButtons: {
    margin: '20px 0',
    textAlign: 'center',
  },
  cancelButton: {
    backgroundColor: '#D2D2D2',
    borderRadius: '8px',
    color: '#373737',
    fontSize: '1.2rem',
    marginRight: '20px',
  },
  deleteButton: {
    backgroundColor: '#6AA426',
    borderRadius: '8px',
    color: '#FFFFFF',
    fontSize: '1.2rem',
  },
});

function NewScheduleTable({ classes }) {
  const data = useSelector((state) => state.schedule.schedules);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [editId, setEditId] = useState(-1);
  const [deleteId, setDeleteId] = useState(-1);
  const [update, setUpdate] = useState({ period: 1, dayTime: 'manhã', email: '' });

  const handleChange = ({ target: { name, value } }) => {
    setUpdate({ ...update, [name]: value });
  }

  const submitUpdate = async (newEmail) => {
    try {
      await ScheduleApi.editSchedule(newEmail);
      dispatch(editScheduleSuccess());
      dispatch(getSchedules());
      setEditId(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteEmail = async (id) => {
    try {
      const response = await ScheduleApi.deleteSchedule(id);
      console.log(response);
      dispatch(getSchedules());
      dispatch(deleteScheduleSuccess());
      setDeleteId(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = (row) => {
    const newEmail = {
      id: row.id,
      point_id: row.point_id,
      period: update.period,
      day_time: update.dayTime,
      graphic_product_type: row.graphic_product_type,
      graphic_visualization_type: row.graphic_visualization_type,
      graphic_scale: row.graphic_scale,
      graphic_type: row.graphic_type,
      graphic_tb: null,
      email_list: update.email,
    };
    submitUpdate(newEmail);
  };

  const handleDelete = () => {
    deleteEmail(deleteId);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const rowsPerPage = 5
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div style={ { margin: '10px' } }>
      <Table>
        <TableHead>
          <TableRow table="head">
            <TableCell className={ classes.tableCellHead }>Fazenda</TableCell>
            <TableCell className={ classes.tableCellHead }>Variável/Tipo/Escala</TableCell>
            <TableCell className={ classes.tableCellHead }>Frequência de atualização</TableCell>
            <TableCell className={ classes.tableCellHead }>Período de atualização</TableCell>
            <TableCell className={ classes.tableCellHead }>Email</TableCell>
            <TableCell className={ classes.tableCellHead }>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <ScheduleTableRow
                  key={ row.id }
                  classes={ classes }
                  index={ index }
                  row={ row }
                  editId={ editId }
                  update={ update }
                  handleChange={ handleChange }
                  handleUpdate={ handleUpdate }
                  setDeleteId={ setDeleteId }
                  setEditId={ setEditId }
                />
              )
            )
          }
          {
            emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )
          }
        </TableBody>
        <CustomTablePagination
          page={ page }
          rowsPerPage={ rowsPerPage }
          rows={ data }
          handleChangePage={ handleChangePage }
        />
      </Table>
      <DeleteModal
        deleteId={ deleteId }
        classes={ classes }
        handleDelete={ handleDelete }
        setDeleteId={ setDeleteId }
        title="Tem certeza que deseja excluir o agendamento desse gráfico?"
        text="Se excluir, o envio desse gráfico em seu email será cancelado."
        confirmMsg="EXCLUIR AGENDAMENTO"
      />
    </div>
  );
}

export default withStyles(styles)(NewScheduleTable);
