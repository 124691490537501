import React, { Component, Fragment } from 'react'

//components
import SimpleNav from '../common/SimpleNav'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../common/Loader'
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core'
import moment from "moment"
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
import { MuiThemeProvider } from "@material-ui/core";

import Table from '../common/Table'
import { showToaster } from '../common/Toaster'

import '../../stylesheets/css/components/profile.css'
import calendarTheme from '../../shared/Themes'

class Profile extends Component {
    constructor(props){
        super(props)

        this.state = {
          profileInfo: {},
          data: [],
          editIdx: -1,
          isEditing: false,
          modifyingRow: {},
          toDate: null,
          fromDate: null,
          noDataPeriod: false,
          selectedPoint: -1,
          rowsPerPage: 30,
          currentPage: 0,
          totalItens: 0,
        };
    }

    //Lifecycle methods
    componentDidMount(){
      if(!this.props.generalInfo){
        this.props.loadProfile(false);
      }

      if(this.props.prevSelectedPoint !== -1){
        this.setState({
          selectedPoint: this.props.prevSelectedPoint
        }, () => {
          this.setPointLoadData(
            this.props.prevSelectedPoint,
            this.state.rowsPerPage,
            this.state.currentPage,
            this.state.fromDate !== null ? this.state.fromDate.format('YYYY-MM-DD') : '',
            this.state.toDate !== null ? this.state.toDate.format('YYYY-MM-DD') : ''
          )
        })
      }
    }

    componentWillUnmount(){
      this.props.resetPoints()
    }

    UNSAFE_componentWillReceiveProps(nextProps){
      if(nextProps.points && nextProps.points !== this.props.points){
        this.setState({
          data: nextProps.points,
          totalItens: nextProps.pagination.total_itens,
          noDataPeriod: false
        })
        if(nextProps.points.length < 1){
          this.setState({
            noDataPeriod: true
          })
        }

      }
      if(nextProps.message === 'edit-error'){
        const toasterParams = {
          title: 'ERRO!',
          message: 'Falha ao tentar editar, por favor tente novamente.',
          status: false,
          autoClose: true,
        }    
        showToaster(toasterParams)
      }
      if(nextProps.message === 'delete'){

        this.setPointLoadData(
          this.state.selectedPoint,
          this.state.rowsPerPage,
          this.state.currentPage,
          this.state.fromDate !== null ? this.state.fromDate.format('YYYY-MM-DD') : '',
          this.state.toDate !== null ? this.state.toDate.format('YYYY-MM-DD') : ''
        )
      }
      if(nextProps.message.includes('Dados do dia')){
        const toasterParams = {
          title: 'SUCESSO!',
          message: nextProps.message,
          status: true,
          autoClose: true,
        }    
        showToaster(toasterParams)
      }
      if (nextProps.uploadStatus && nextProps.uploadStatus.status === 200){
        const toasterParams = {
          title: 'SUCESSO!',
          message: nextProps.uploadStatus['message'],
          status: true,
          autoClose: true,
        }

        showToaster(toasterParams)
        this.props.resetUpload()
      } else if (nextProps.uploadStatus && nextProps.uploadStatus.status === 500){
        const toasterParams = {
          title: 'ERRO!',
          message: nextProps.uploadStatus['message'],
          status: false,
          autoClose: true,
        }

        showToaster(toasterParams)
        this.props.resetUpload()
      }

      this.props.clearDeleteMessage()
    }

    //Pagination methods
    handleChangePage = ( event, currentPage ) => {
      this.setState({ currentPage }, () => {
        this.setPointLoadData(
          this.state.selectedPoint,
          this.state.rowsPerPage,
          currentPage,
          this.state.fromDate !== null ? this.state.fromDate.format('YYYY-MM-DD') : '',
          this.state.toDate !== null ? this.state.toDate.format('YYYY-MM-DD') : ''
        )
      })
    }

    handleChangeRowsPerPage = event => {
      this.setState({
        rowsPerPage: event.target.value,
        currentPage: 0
      }, () => {
        this.setPointLoadData(
          this.state.selectedPoint,
          this.state.rowsPerPage,
          this.state.currentPage,
          this.state.fromDate !== null ? this.state.fromDate.format('YYYY-MM-DD') : '',
          this.state.toDate !== null ? this.state.toDate.format('YYYY-MM-DD') : ''
        )
      })
    };

    //Filtering methods
    onChangeDate = key => date => {
      this.setState({ [key]: date}, () => {
        this.setPointLoadData(
          this.state.selectedPoint,
          this.state.rowsPerPage,
          this.state.currentPage,
          this.state.fromDate !== null ? this.state.fromDate.format('YYYY-MM-DD') : '',
          this.state.toDate !== null ? this.state.toDate.format('YYYY-MM-DD') : ''
        )
      })
    }

    onPointChange = event => {

      this.setState({ selectedPoint: event.target.value }, () =>{
        this.setPointLoadData(
          event.target.value,
          this.state.rowsPerPage,
          0,
          this.state.fromDate !== null ? this.state.fromDate.format('YYYY-MM-DD') : '',
          this.state.toDate !== null ? this.state.toDate.format('YYYY-MM-DD') : ''
        )
      })
    }

    //Data management methods
    setPointLoadData(farmId, limit, page, initial_date, final_date)
    {
      this.stopEditing()

      let data = {
        farmId: farmId,
        limit: limit,
        page: page,
        initial_date: initial_date,
        final_date: final_date
      }

      if(data.farmId !== -1){
        this.props.loadPointData(data)
      }
    }

    handleRemove = (row) => {
      const toasterParams = {
        title: 'EXCLUIR LINHA?',
        message: 'Tem certeza que deseja excluir essa linha?',
        status: false,
        autoClose: false,
        buttons: [
          {
            name: 'EXCLUIR',
            style: 'delete',
            func: this.confirmDeleting,
            data: [
              this.state.selectedPoint,
              row.id            
            ]
          },
          {
            name: 'CANCELAR',
            func: toast.dismiss
          }
        ]
      }
  
      showToaster(toasterParams)
    };

    confirmDeleting = (event) => {
      const data = {
        farmId: event.target.dataset.object.split(',')[0],
        farmDataId: event.target.dataset.object.split(',')[1]
      }
      this.props.deletePointRow(data)
    }

    startEditing = (row, i) => {
      this.setState({
        editIdx: i,
        modifyingRow: this.state.data[i]
      });
    };

    stopEditing = () => {
      this.setState({ editIdx: -1 });
    };

    saveEditing = (row, i) => {
      const data = {
        farmId: this.state.selectedPoint,
        updateInfo: this.state.modifyingRow
      }

      let sendEditMessage = false

      for (let [key, value] of Object.entries(this.state.modifyingRow)){
        if(this.props.points[i][key] !== value){
          sendEditMessage = true
          break
        }
      }

      if(sendEditMessage){
        this.props.editPointRow(data)
      }
      this.setState({ editIdx: -1 });
    };

    handleChange = (e) => {

      this.setState({
        modifyingRow : {
          ...this.state.modifyingRow,
          [e.target.name]: e.target.value
        }
      })

    };


    render(){
      return (
        <div className={'user-page'}>
          <Loader active={this.props.loading} />
          <ToastContainer className='toast-alert-container'/>
          <SimpleNav application={'agrymax'} profilePage={true}/>
          {this.props.generalInfo ?
            <Fragment>
              <h5 className='title'>DADOS DA CONTA</h5>

              <Grid container className='personal-data'>
                <Grid container direction='column' justify='flex-start'>
                    {this.props.generalInfo.api_key ?
                      <Grid item xs={12}>
                        <Grid container className='info' justify='flex-start'>
                          <Grid item xs={12} className='info-header'>Chave da API Agrymax:</Grid>
                          <Grid item xs={12} className='info-value'>{this.props.generalInfo.api_key}</Grid>
                        </Grid>
                      </Grid>
                    :
                    <Grid item xs={12}>
                      <Grid container className='info' justify='flex-start'>
                        <Grid item xs={12} className='info-header'>Você não possui uma Chave da API Agrymax</Grid>
                      </Grid>
                    </Grid>
                    }
                </Grid>
              </Grid>

              <h5 className='title'>DADOS DO USUÁRIO</h5>

              <Grid container className='uploaded-data'>

              <Grid item className='filters'>
                <Grid container spacing={40} justify='flex-start' alignItems='center'>
                  <Grid item xs={3} md={6} className='date' >
                    <Grid container justify='flex-start' spacing={16}>
                      <Grid item>
                        Período de:
                      </Grid>
                      <Grid item>
                        <MuiThemeProvider theme={calendarTheme}>
                          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <DatePicker
                              keyboard
                              placeholder={'DD/MM/AAAA'}
                              format={"DD/MM/YYYY"}
                              className="date-picker"
                              value={this.state.fromDate}
                              name='fromDate'
                              maxDate={this.state.toDate !== null? this.state.toDate : undefined}
                              onChange={this.onChangeDate('fromDate')}
                              openTo='year'
                              views={["year", "month", "day"]}
                              clearable={true}
                              clearLabel='Limpar'
                              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                      <Grid item>
                        até:
                      </Grid>
                      <Grid item>
                        <MuiThemeProvider theme={calendarTheme}>
                          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <DatePicker
                              keyboard
                              placeholder={'DD/MM/AAAA'}
                              format={"DD/MM/YYYY"}
                              className="date-picker"
                              value={this.state.toDate}
                              name='toDate'
                              minDate={this.state.fromDate !== null? this.state.fromDate : undefined}
                              minDateMessage="O período deve ser positivo"
                              onChange={this.onChangeDate('toDate')}
                              openTo='year'
                              views={["year", "month", "day"]}
                              clearable={true}
                              clearLabel='Limpar'
                              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={5} >
                    <Grid container justify='flex-start'>
                      <Grid item xs={2}>
                          Unidade:
                      </Grid>
                      <Grid item xs={10}>
                        <MuiThemeProvider theme={calendarTheme}>
                          <FormControl className='unit-form'>
                            <Select
                              className='unit-picker'
                              value={this.state.selectedPoint}
                              onChange={this.onPointChange}
                            >
                              {this.props.uploadedFarms.map(point => (
                                <MenuItem key={point.initials} value={point.farm_id}>{point.name === ''? point.initials : point.initials + ' - ' + point.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MuiThemeProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                {this.props.points.length > 0 ? (
                  <Table
                    tableStyle="table"
                    handleRemove={this.handleRemove}
                    startEditing={this.startEditing}
                    editRowControl={this.state.editIdx}
                    editableColumns={[1, 2, 3, 4, 5, 6]}
                    stopEditing={this.stopEditing}
                    saveEditing={this.saveEditing}
                    handleChange={this.handleChange}
                    data={this.state.data}
                    totalItens={this.state.totalItens}
                    rowsPerPage={this.state.rowsPerPage}
                    currentPage={this.state.currentPage}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    header={[
                      {
                        name: "Data",
                        prop: "date"
                      },
                      {
                        name: "Tmax",
                        prop: "tmax"
                      },
                      {
                        name: "Tmin",
                        prop: "tmin"
                      },
                      {
                        name: "P",
                        prop: "p"
                      },
                      {
                        name: "U2",
                        prop: "u2"
                      },
                      {
                        name: "UR",
                        prop: "ur"
                      },
                      {
                        name: "Qg",
                        prop: "qg"
                      }
                      ]}
                  />
                ) : (
                  <Grid container justify='center' alignItems='center'>
                    <Grid className='paper'>
                      {this.state.noDataPeriod ?
                        <Grid item>
                          Sem entradas para esse período
                        </Grid>
                      :
                        <Grid item>
                          Selecione um ponto
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                )}
            </Grid>
            </Fragment>
          :
            null
          }      
      </div>
      )
    }
}

export default Profile
