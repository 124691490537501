import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
// Components
import Indicator from './Indicator'
import DetailPopover from './DetailPopover'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { agriculturalYear } from '../../../shared/utils/DatesUtils'

// Styles
import '../../../stylesheets/css/components/bhbox.css'
import '../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css'

// utils
import utilsArray from '../../../utils/utilsArray'

class Bhbox extends Component {
  constructor(props) {
      super(props)

      this.state = {
        detailPopover: {
          active: false,
          element: undefined,
          data: undefined,
          dec: undefined,
          month: undefined,
          year: undefined,
          initials: undefined
        },
        activeYear: new Date().getFullYear()
      }
  }

  //Lifecycle methods
  UNSAFE_componentWillReceiveProps() {
    this.setState({ detailPopover: { active: false } })
  }

  //#region Helper methods

  renderDifferentCol(){
    if(this.props.bhboxType){
      return(
        null
      )
    }else{
      return(
        <div className='years-label'><label>ANOS</label></div>
      )
    }
  }

  getDecByDay(day){
    if(day <= 10){
      return 0
    }else if(day > 10 && day <= 20){
      return 1
    }else if(day > 20){
      return 2
    }
  }
  
  predictionIndicator(dec,month,year,data){
    if(data === -1){
      return false
    }
    if(parseInt(year, 10) > parseInt((new Date()).getFullYear(), 10)){
      return true
    }else if((this.props.visualizationType === 'civil' && parseInt(year, 10) === parseInt((new Date()).getFullYear(), 10)) 
          || (this.props.visualizationType === 'agricultural' && parseInt(year, 10) === parseInt((new Date()).getFullYear(), 10)-1 && month < 6)
          || (this.props.visualizationType === 'agricultural' && parseInt(year, 10) === parseInt((new Date()).getFullYear(), 10) && month > 5)
          ){
      if(parseInt(month,10) === (new Date()).getMonth()+1){
        return true
      }else if(parseInt(month,10) === (new Date()).getMonth()){
        let currentDec = this.getDecByDay((new Date()).getDate())
        if(currentDec > dec){
          return false
        }else if(currentDec === dec){
          return true
        }else if (currentDec < dec) {
          return true
        }
      }else{
        return false
      }
    }else{
      return false
    }
  }

  scrollBottom(scrollRef){
    if(scrollRef !== null){
      scrollRef.scrollTop = parseInt(scrollRef.offsetHeight,10) + parseInt(scrollRef.offsetTop,10)
    }
  }

  getPredictionPosition(isPrediction, year, predictionPosition) {
    let position = predictionPosition;

    if (isPrediction) {
        position += 1
    } 
    else {
      position = -1
    }
    if(position === 0 && year === this.state.activeYear+1){
      //In case of new year
      return 3
    }
    return position;
  }

  reorderArray = (array) => {
    const firstPart = array.slice(0, 6)
    const lastPart = array.slice(6, 12)

    return [...lastPart, ...firstPart];
  }

  setMonth = (type) => {
    const { startDate, endDate } = this.props.filterParams;
    const { initialDateArray, finalDateArray } = utilsArray;

    this.reorderArray(initialDateArray);

    const initialDate = this.props.visualizationType === 'civil'
      ? initialDateArray.indexOf(startDate)
      : this.reorderArray(initialDateArray).indexOf(startDate);
    const finalDate = this.props.visualizationType === 'civil'
      ? finalDateArray.indexOf(endDate)
      : this.reorderArray(finalDateArray).indexOf(endDate);

    if (type === 'initial') return initialDate;
    if (type === 'final') return finalDate + 1;
  }

  renderBody(){
    var farmInfo = []

    if(this.props.bhboxType){
      return(
          <Fragment>
            <PerfectScrollbar className={classnames('bhbox-scroll ps ps--theme default', {"bhbox-scroll-multiple": this.props.bhboxType})}>
            {this.props.data.map((row,index) =>{
              if(this.props.farms !== undefined){
                this.props.farms['farms'].forEach(farm => {
                  if(farm.id === row['farm_id']){
                    farmInfo = farm
                  }
                })
              }else{
                this.props.xavierGridPoints['xavier_points'].forEach(point => {
                  if(row['lat'] === point['lat'].toString() && row['lng'] === point['lng'].toString()){
                    farmInfo['name'] = point['name']
                    farmInfo['initials'] = point['uf']
                    farmInfo['cad'] = row['cad']
                  }
                })
              }
              return(
                <div className={classnames('bhbox--labels-data', {'bhbox--labels-data-multiple': this.props.bhboxType})}>
                  <div className={classnames('multiple-farms-label-initials', {'active-detailPopover-date': farmInfo.initials === this.state.detailPopover.initials})} title={farmInfo.name}>{ farmInfo.initials }</div>
                  <div className={classnames('multiple-farms-label-cad', {'active-detailPopover-date': farmInfo.initials === this.state.detailPopover.initials})}>{ farmInfo.cad }</div>
                  {row.bhbox.map(bhbox => {
                    let predictionPosition = -1
                    return(
                      bhbox['data'].slice(this.setMonth('initial'), this.setMonth('final')).map((month, index) => {
                        var monthNum = this.props.visualizationType === 'civil' ? index : agriculturalYear(index)
                        return(
                          month.map((data, subIndex) => {
                            let isPrediction = this.predictionIndicator(subIndex, monthNum, bhbox['year'], data);
                            predictionPosition = this.getPredictionPosition(isPrediction, bhbox['year'], predictionPosition);

                            return(
                              <Indicator
                                actualLengthArray={bhbox['data'].slice(this.setMonth('initial'), this.setMonth('final')).length}
                                soil={this.props.soil}
                                agrytoolsType={this.props.agrytoolsType}
                                key={subIndex}
                                month={ monthNum }
                                year={ bhbox['year'] }
                                initials={farmInfo.initials}
                                data={ data } 
                                dec={ subIndex }
                                prediction={predictionPosition}
                                setDetailPopover={ this.setDetailPopover }
                                visualizationType={this.props.visualizationType} />
                            )
                          })
                        )
                      })
                    )
                  })}
                </div>
              )
            })}
            </PerfectScrollbar>
          </Fragment>
      )
    }else{
      return(
        <Fragment>
            <PerfectScrollbar containerRef={this.scrollBottom} className='bhbox-scroll ps ps--theme default' onScrollY={this.handleScroll}>
            { this.props.data.map(row => {
              if(this.props.activeFarm !== undefined){
                if(row['farm_id'] === this.props.activeFarm['id']){
                  return (
                    row.bhbox.map((bhbox, index) => {
                      var predictionPosition = -1
                      return(
                        <div key={index} className='bhbox--labels-data'>
                          <div className={classnames('year-label', {'active-detailPopover-date': this.state.detailPopover.year === bhbox['year']})}>{ bhbox['year'] }</div>
                          {bhbox['data'].slice(this.setMonth('initial'), this.setMonth('final')).map((month, index) => {
                            var monthNum = this.props.visualizationType === 'civil' ? index : agriculturalYear(index)
                            return(
                              month.map((data, subIndex) => {
                                let isPrediction = this.predictionIndicator(subIndex, monthNum, bhbox['year'], data);
                                predictionPosition = this.getPredictionPosition(isPrediction, bhbox['year'], predictionPosition);
                                
                                return(
                                  <Indicator
                                    actualLengthArray={bhbox['data'].slice(this.setMonth('initial'), this.setMonth('final')).length}
                                    soil={this.props.soil}
                                    agrytoolsType={this.props.agrytoolsType}
                                    key={subIndex}
                                    month={ monthNum }
                                    year={ bhbox['year'] }
                                    data={ data }
                                    dec={ subIndex }
                                    prediction={predictionPosition}
                                    setDetailPopover={ this.setDetailPopover }
                                    fullScreen={this.props.fullScreen}
                                    visualizationType={this.props.visualizationType}
                                  />
                                )
                              })
                            )
                          })
                        }
                      </div>
                    )
                  })
                )
              }else {
                return null
              }
            }else{
              return (
                row.bhbox.map((bhbox) => {
                  let predictionPosition = -1;

                  return(
                    <div className='bhbox--labels-data'>
                      <div className={classnames('year-label', {'active-detailPopover-date': this.state.detailPopover.year === bhbox['year']})}>{ bhbox['year'] }</div>
                      {bhbox['data'].slice(this.setMonth('initial'), this.setMonth('final')).map((month, index) => {
                        let monthNum = this.props.visualizationType === 'civil' ? index : agriculturalYear(index);

                        return(
                          month.map((data, subIndex) => {
                            let isPrediction = this.predictionIndicator(subIndex, monthNum, bhbox['year'], data);
                            predictionPosition = this.getPredictionPosition(isPrediction, bhbox['year'], predictionPosition);
                            
                            return(
                              <Indicator
                                actualLengthArray={bhbox['data'].slice(this.setMonth('initial'), this.setMonth('final')).length}
                                soil={this.props.soil}
                                agrytoolsType={this.props.agrytoolsType}
                                key={subIndex + 1}
                                month={ monthNum }
                                year={ bhbox['year'] }
                                data={ data }
                                dec={ subIndex }
                                prediction={predictionPosition}
                                setDetailPopover={ this.setDetailPopover }
                                fullScreen={this.props.fullScreen}
                                visualizationType={this.props.visualizationType}
                              />
                            )
                          })
                        )
                      })
                    }
                  </div>
                )
              })
             )
            }
          })}
          </PerfectScrollbar>
        </Fragment>
      )
    }
  }
  //#endregion


  //#region Render methods

  setDetailPopover = (el, data, dec, month, year, initials) => {
    this.setState({ detailPopover: { active: true, element: el, data: data, dec: dec, month: month, year: year, initials: initials} })
  }

  disablePopover = () => {
    this.setState({ detailPopover: { active: false } })
  }

  handleScroll = (e) => {
    if(this.state.detailPopover.active){
      this.setState({ detailPopover: { active: false } })
    }
  }
  //#endregion

  render() {
    const months = this.props.visualizationType === 'civil' ? 
    [
      'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'
    ]
    :
    [
      'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ', 'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN'
    ]

    const decs = [
      '1', '2', '3'
    ]

    return(
      <Fragment>
        <div className={classnames("bhbox", {"bhbox-multiple": this.props.bhboxType})}>
          {this.props.bhboxType ?
            <Fragment>
              <div id='multiple-farms-first-td'>
                <label>UP</label>
              </div>
              <div className='multiple-farms-cad'>
                <div>
                  <label>CAD</label>
                </div>
                <div>
                  <label>(mm)</label>
                </div>
              </div>
            </Fragment>
            : null
          }
          <div className="bhbox--labels-months">
            {this.props.bhboxType ? null :
              <div id='first-td'></div>
            }
            {months.slice(this.setMonth('initial'), this.setMonth('final')).map((value, index) => {
              return(
                <div 
                  key={index} 
                  className={classnames('months-labels',
                                        {'active-detailPopover-date' : this.state.detailPopover.month === index},
                                        {'months-labels-multiple': this.props.agrytoolsType === 'bhbox' ? false : this.props.bhboxType},
                                        {'months-bhbox-labels-multiple': this.props.agrytoolsType === 'bhbox' && this.props.bhboxType},
                                        {'last-key': months.slice(this.setMonth('initial'), this.setMonth('final')).length === index + 1})} 
                  colSpan={3}>
                    {value}
                  </div>
              )
            })}
          </div>
          <div className={classnames("bhbox--labels-days", {"bhbox--labels-days-multiple": this.props.agrytoolsType === 'bhbox' ? false : this.props.bhboxType})}>
            {this.renderDifferentCol()}
            {months.slice(this.setMonth('initial'), this.setMonth('final')).map((value,index) =>{
              var monthCom = index
              return(
                decs.map((value, index) => {
                  return(
                    <div 
                    key={value+'_'+index} 
                    className={classnames({'days-labels-multiple': this.props.agrytoolsType === 'bhbox' ? false : this.props.bhboxType},
                                          {'days-bhbox-labels-multiple': this.props.agrytoolsType === 'bhbox' && this.props.bhboxType},
                                          {'active-detailPopover-date' : this.state.detailPopover.month === monthCom && this.state.detailPopover.dec === index},
                                          {'last-key': months.slice(this.setMonth('initial'), this.setMonth('final')).length === monthCom+1 && index === 2})}
                    >
                      {value}
                    </div>
                  )
                })
              )
            })}
          </div>
        </div>
        {this.renderBody()}
        { this.state.detailPopover.active ?
          <DetailPopover
            data={ this.state.detailPopover.data }
            dec={ this.state.detailPopover.dec }
            month={this.state.detailPopover.month}
            year={this.state.detailPopover.year}
            key={this.state.detailPopover.month.toString() + '/' + this.state.detailPopover.year.toString()}
            element={ this.state.detailPopover.element }
            disablePopover={ this.disablePopover }
            fullScreen={ this.props.fullScreen }
            soil={this.props.soil}
            agrytoolsType={this.props.agrytoolsType}
            visualizationType={this.props.visualizationType}
          /> : null }
      </Fragment>
    )
  }
}

export default Bhbox
