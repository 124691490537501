import { authHeader } from './config'
import { authHeaderUpload } from './configUpload'

class PointsApi {
  static multipartPayload(attrs,type) {
    const formData = new FormData()

    for (let key in attrs) {
      if (attrs[key] instanceof Array) {
        formData.append(key, []) // Ensures sending if array is empty
        for (let arr in attrs[key]) {
          formData.append(key, attrs[key][arr])
        }
      }
      else {
        formData.append(key, attrs[key])
      }
    }
    return formData
  }

  static getPoints(data) {
    return fetch(process.env.REACT_APP_API + 'api/points', {
      method: 'GET',
      headers: authHeader(),
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }

  static addPoint(data) {
    return fetch(process.env.REACT_APP_API + 'api/points', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({
        coords: data.coords
      })
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }

  static editPoint(data) {
    return fetch(process.env.REACT_APP_API + 'api/points', {
      method: 'PUT',
      headers: authHeader(),
      body: JSON.stringify({
        name: data.name,
        coords: data.coords,
        cad: data.cad.toString(),
        farm_id: data.farmId,
        initials: data.initials,
        soil_texture: data.soil_texture,
        soil_depth: data.soil_depth
      })
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }

  static deletePoint(id) {
    return fetch(process.env.REACT_APP_API + 'api/points', {
      method: 'DELETE',
      headers: authHeader(),
      body: JSON.stringify({
        farm_id: id
      })
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }

  static uploadData(params) {
    return fetch(process.env.REACT_APP_API + 'api/points/upload', {
      method: 'POST',
      // solution for sending multipart/form-data, it goes wrong if we pass the content-type
      headers: authHeaderUpload(),
      body: this.multipartPayload(params)
    })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      return error
    })
  }
}

export default PointsApi
