import {
  MAP_CLICK_EVENT,
  MAP_CLICK_EDIT_EVENT,
  CHANGE_MAP_DAY,
  LOAD_MAP_DATA,
  SET_SHOW_LAT_LNG,
  RESET_GRID,
  SET_GRID_POINT_NAME,
  SET_GRID_POINT_COORDS,
  SET_MAP_YEAR,
  XAVIER_GRID_SUCCESS,
  XAVIER_BHBOX_EVENT,
  SET_BHBOX_DATE_CLICK,
  GET_KMZ_SUCCESS,
  KMZ_DELETE_SUCCESS
} from '../actions/map'

const initialState = {
  allowMapClickEvent: false,
  allowMapClickEditEvent: false,
  allowXavierBhbox: false,
  weatherMapDay: {label: '' , year: ''},
  mapYear: new Date().getFullYear().toString(),
  grid: undefined,
  showLat: 0,
  showLng: 0,
  selectedGridPointName: '',
  selectedGridPointCoords: {
    lat: '',
    lng: '',
  },
  bhboxDate: {
    dec: '',
    month: '',
    year: ''
  },
  xavierGridPoints: undefined,
  kmz: []
}

export default (state = initialState, action) => {
  switch(action.type) {
    case MAP_CLICK_EVENT:
      return Object.assign({}, state, { allowMapClickEvent: !state.allowMapClickEvent })
    case MAP_CLICK_EDIT_EVENT:
      return Object.assign({}, state, { allowMapClickEditEvent: !state.allowMapClickEditEvent })
    case XAVIER_BHBOX_EVENT:
      return Object.assign({}, state, { allowXavierBhbox: !state.allowXavierBhbox})
    case CHANGE_MAP_DAY:
      return Object.assign({}, state, { weatherMapDay: action.day})
    case LOAD_MAP_DATA:
      return Object.assign({}, state, { grid: action.data})
    case SET_SHOW_LAT_LNG:
      return Object.assign({}, state, {showLat: action.lat, showLng: action.lng})
    case RESET_GRID:
      return Object.assign({}, state, {grid: undefined})
    case SET_GRID_POINT_NAME:
      return Object.assign({}, state, {selectedGridPointName: action.name})
    case SET_GRID_POINT_COORDS:
      return Object.assign({}, state, {selectedGridPointCoords: {lat: action.lat, lng: action.lng}})
    case SET_MAP_YEAR:
      return Object.assign({}, state, {mapYear: action.year})
    case XAVIER_GRID_SUCCESS:
      return Object.assign({}, state, {xavierGridPoints: action.data})
    case SET_BHBOX_DATE_CLICK:
      return Object.assign({}, state, {bhboxDate: {dec: action.dec , month: action.month, year: action.year}})
    case GET_KMZ_SUCCESS:
      return Object.assign({}, state, {kmz: action.payload.data})
    case KMZ_DELETE_SUCCESS:
      return Object.assign({}, state, {kmz: undefined})
    default:
      return state
  }
}
