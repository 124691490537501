import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { confirmUser } from '../actions/users'

class ConfirmUser extends Component {

  componentDidMount(){
    this.props.confirmUser(this.props.match.params.token)
  }

  render() {
    if(this.props.status !== undefined || this.props.errors !== ''){
      return(
        <Redirect push to={'/'} />
      )
    }else{
      return(null)
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    status: state.users.createResult,
    errors: state.users.errors
  }
}

function mapDispatchToProps(dispatch) {
  return {
    confirmUser: (token) => {
      dispatch(confirmUser(token))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUser)
