import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'
import { validate } from 'cnpj'

export default function validateInput(data) {
  let errors = {}
    
  if (!Validator.isEmail(data.identifier)) {
    errors.identifier = 'Forneça um email válido'
  }

  if (!Validator.isLength(data.password, { min: 6, max: 50 })) {
    errors.password = 'A senha deve conter no mínimo 6 caracteres'
  }

  if (!Validator.equals(data.password, data.passwordConfirm)) {
    errors.passwordConfirm = 'As senhas estão diferentes'
  }

  if (Validator.isEmpty(data.cpf_cnpj)) {
    errors.cpf_cnpj = 'Fornecer CPF/CNPJ é necessário'
  } else {
    if(data.cpf_cnpj.length < 15){
      let cpf = data.cpf_cnpj.split('.').join('')
      cpf = cpf.split('-').join('')
      const digits = cpf.split('').map(x => parseInt(x, 10))

      for (let j = 0; j < 2; j++) {
      		let sum = 0

      		for (let i = 0; i < 9 + j; i++) {
      			sum += digits[i] * (10 + j - i)
      		}

      		let checkDigit = 11 - sum % 11

      		if (checkDigit === 10 || checkDigit === 11) {
      			checkDigit = 0
      		}

      		if (checkDigit !== digits[9 + j]) {
      			errors.cpf_cnpj = 'CPF Inválido'
      		}
      }
    }else{
      if(!validate(data.cpf_cnpj)){
        errors.cpf_cnpj = 'CNPJ Inválido'
      }
    }

  }
  return {
    errors,
    isValid: isEmpty(errors)
  }
}
