import { connect } from 'react-redux'
import { getApiKeys, setApiKeys, editApiKeys, deleteApiKeys, getUserStations,
         setUserStation, editUserStation, deleteUserStation, pageUpdateHandle,
         getAvailablePoints, updateIntegration, resetMessage, resetUpdate } from '../actions/integration'

import Integration from '../components/profile/integration/Integration'

function mapStateToProps(state) {
    return {
      keys: state.integration.keys,
      stations: state.integration.stations,
      status: state.integration.status,
      message: state.integration.message,
      update: state.integration.update,
      points: state.integration.points,
      pagination: state.integration.pagination,
      integrationStatus: state.integration.integrationStatus
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getApiKeys: (integration) => {
        dispatch(getApiKeys(integration))
      },
      setApiKeys: (keys) => {
        dispatch(setApiKeys(keys))
      },
      editApiKeys: (keys) => {
        dispatch(editApiKeys(keys))
      },
      deleteApiKeys: (integration) => {
        dispatch(deleteApiKeys(integration))
      },
      getUserStations: (data) => {
        dispatch(getUserStations(data))
      },
      setUserStation: (data) => {
        dispatch(setUserStation(data))
      },
      editUserStation: (data) => {
        dispatch(editUserStation(data))
      },
      deleteUserStation: (farm) => {
        dispatch(deleteUserStation(farm))
      },
      pageUpdateHandle: () => {
        dispatch(pageUpdateHandle())
      },
      resetMessage: () => {
        dispatch(resetMessage())
      },
      updateIntegration: (data) => {
        dispatch(updateIntegration(data))
      },
      getAvailablePoints: (integration) => {
        dispatch(getAvailablePoints(integration))
      },
      resetUpdate: () => {
        dispatch(resetUpdate())
      }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Integration)