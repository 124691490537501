import {
    GET_RISK_SUCCESS,
    RESET_FLOWERING_REPORT
} from "../actions/flowering"

const initialState = {
    data: undefined
}

export default (state = initialState, action) => {
    switch(action.type){
        case GET_RISK_SUCCESS:
            return Object.assign({}, state, { data: action.data })
        case RESET_FLOWERING_REPORT:
             return Object.assign({}, state, { data: undefined })
        default:
            return state
    }
}