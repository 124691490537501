export const tmin = [
  {value: '< -4' , color: '#4B007D'},
  {value: '-4 - -1,9' , color: '#081D9C'},
  {value: '-2 - -0,9' , color: '#1B00CF'},
  {value: ' 0 -  1,9 ' , color: '#2171B5'},
  {value: ' 2 -  3,9 ' , color: '#4292C6'},
  {value: ' 4 -  5,9 ' , color: '#6BAED6'},
  {value: ' 6 -  7,9 ' , color: '#97C5DC'},
  {value: ' 8 -  9,9 ' , color: '#A1CFEC'},
  {value: '10 - 11,9' , color: '#C6DBEF'},
  {value: '12 - 13,9' , color: '#CCE3F9'},
  {value: '14 - 15,9' , color: '#E8F3FF'},
  {value: '16 - 17,9' , color: '#FFFCD9'},
  {value: '18 - 19,9' , color: '#FFF7BC'},
  {value: '>= 20' , color: '#FFF55C'}
]

export const tmax = [
  {value: '< 14' , color: '#CCE3F9'},
  {value: '14 - 15,9' , color: '#E8F3FF'},
  {value: '16 - 17,9' , color: '#FFFCD9'},
  {value: '18 - 19,9' , color: '#FFF7BC'},
  {value: '20 - 21,9' , color: '#FFF55C'},
  {value: '22 - 23,9' , color: '#FFE61E'},
  {value: '24 - 25,9' , color: '#FFD41A'},
  {value: '26 - 27,9' , color: '#FFBF15'},
  {value: '28 - 29,9' , color: '#FF990D'},
  {value: '30 - 31,9' , color: '#FB6A4A'},
  {value: '32 - 33,9' , color: '#EF3B2C'},
  {value: '34 - 35,9' , color: '#CB181D'},
  {value: '36 - 37,9' , color: '#880110'},
  {value: '>= 38' , color: '#52020B'}
]

export const tmed = [
  {value: '< 6' , color: '#6BAED6'},
  {value: '6 - 7,9' , color: '#97C5DC'},
  {value: '8 - 9,9' , color: '#A1CFEC'},
  {value: '10 - 11,9' , color: '#C6DBEF'},
  {value: '12 - 13,9' , color: '#CCE3F9'},
  {value: '14 - 15,9' , color: '#E8F3FF'},
  {value: '16 - 17,9' , color: '#FFFCD9'},
  {value: '18 - 19,9' , color: '#FFF7BC'},
  {value: '20 - 21,9' , color: '#FFF55C'},
  {value: '22 - 23,9' , color: '#FFE61E'},
  {value: '24 - 25,9' , color: '#FFD41A'},
  {value: '26 - 27,9' , color: '#FFBF15'},
  {value: '28 - 29,9' , color: '#FF990D'},
  {value: '>= 30' , color: '#FB6A4A'}
]

export const rs = [
  {value: '< 5' , color: '#FFFFE5'},
  {value: '5 - 9,9' , color: '#FFF7BC'},
  {value: '10 - 14,9' , color: '#FEE391'},
  {value: '15 - 19,9' , color: '#FEC44F'},
  {value: '20 - 24,9' , color: '#FE9929'},
  {value: '25 - 29,9' , color: '#EC7014'},
  {value: '30 - 34,9' , color: '#CC4C02'},
  {value: '35 - 39,9' , color: '#993404'},
  {value: '> 40' , color: '#662506'}
]

export const gd_1 = [
  {value: '0' , color: '#FFFFE5'},
  {value: '0,1 - 3' , color: '#FFF7BC'},
  {value: '3,1 - 6' , color: '#FFFA22'},
  {value: '6,1 - 9' , color: '#FFE61E'},
  {value: '9,1 - 12' , color: '#FFD41A'},
  {value: '12,1 - 15' , color: '#FFBF15'},
  {value: '15,1 - 18' , color: '#FF990D'},
  {value: '18,1 - 21' , color: '#FB6A4A'},
  {value: '21,1 - 24' , color: '#EF3B2C'},
  {value: '24,1 - 27' , color: '#CB181D'},
  {value: '> 27' , color: '#67000D'}
]

export const gd_2 = [
  {value: '0' , color: '#FFFFE5'},
  {value: '0,1 - 20' , color: '#FFF7BC'},
  {value: '20,1 - 40' , color: '#FFFA22'},
  {value: '40,1 - 60' , color: '#FFE61E'},
  {value: '60,1 - 80' , color: '#FFD41A'},
  {value: '80,1 - 100' , color: '#FFBF15'},
  {value: '100,1 - 120' , color: '#FF990D'},
  {value: '120,1 - 140' , color: '#FB6A4A'},
  {value: '140,1 - 160' , color: '#EF3B2C'},
  {value: '160,1 - 180' , color: '#CB181D'},
  {value: '> 180' , color: '#67000D'}
]

export const gd_3 = [
  {value: '< 80' , color: '#FFFFE5'},
  {value: '80 - 99,9' , color: '#FFF7BC'},
  {value: '100 - 149,9' , color: '#FFFA22'},
  {value: '150 - 199,9' , color: '#FFE61E'},
  {value: '200 - 249,9' , color: '#FFD41A'},
  {value: '250 - 299,9' , color: '#FFBF15'},
  {value: '300 - 349,9' , color: '#FF990D'},
  {value: '350 - 449,9' , color: '#FB6A4A'},
  {value: '450 - 549,9' , color: '#EF3B2C'},
  {value: '550 - 599,9' , color: '#CB181D'},
  {value: '> 600' , color: '#67000D'}
]

export const gd_4 = [
  {value: '< 1000' , color: '#FFFFE5'},
  {value: '1000 - 1199,9' , color: '#FFF7BC'},
  {value: '1200 - 1399.9' , color: '#FFFA22'},
  {value: '1400 - 1599,9' , color: '#FFE61E'},
  {value: '1600 - 1799,9' , color: '#FFD41A'},
  {value: '1800 - 1999,9' , color: '#FFBF15'},
  {value: '2000 - 2399,9' , color: '#FF990D'},
  {value: '2400 - 2799,9' , color: '#FB6A4A'},
  {value: '2800 - 3199,9' , color: '#EF3B2C'},
  {value: '3200 - 3399,9' , color: '#CB181D'},
  {value: '> 3400' , color: '#67000D'}
]

export const u2_1 = [
  {value: '0' , color: '#F7FCF5'},
  {value: '0,1 - 1' , color: '#E5F5E0'},
  {value: '1,1 - 2' , color: '#C7E9C0'},
  {value: '2,1 - 3' , color: '#A1D99B'},
  {value: '3,1 - 4' , color: '#74C476'},
  {value: '4,1 - 5' , color: '#41AB5D'},
  {value: '5,1 - 6' , color: '#238B45'},
  {value: '6,1 - 7' , color: '#006D2C'},
  {value: '> 7' , color: '#00441B'},
]

export const u2_2 = [
  {value: '0' , color: '#F7FCF5'},
  {value: '0,1 - 0,5' , color: '#E5F5E0'},
  {value: '0,6 - 1' , color: '#C7E9C0'},
  {value: '1,1 - 1,5' , color: '#A1D99B'},
  {value: '1,6 - 2' , color: '#74C476'},
  {value: '2,1 - 2,5' , color: '#41AB5D'},
  {value: '2,6 - 3' , color: '#238B45'},
  {value: '3,1 - 3,5' , color: '#006D2C'},
  {value: '> 3,5' , color: '#00441B'},
]

export const etp_1 = [
  {value: '0' , color: '#993404'},
  {value: '0,1 - 0,5' , color: '#CC4C02'},
  {value: '0,6 - 1' , color: '#EC7014'},
  {value: '1,1 - 1,5' , color: '#FE9929'},
  {value: '1,6 - 2' , color: '#FEC44F'},
  {value: '2,1 - 2,5' , color: '#FEE391'},
  {value: '2,6 - 3' , color: '#FFF7BC'},
  {value: '3,1 - 3,5' , color: '#C6DBEF'},
  {value: '3,6 - 4' , color: '#9ECAE1'},
  {value: '4,1 - 5' , color: '#6BAED6'},
  {value: '5,1 - 6' , color: '#4292C6'},
  {value: '6,1 - 8' , color: '#2171B5'},
  {value: '> 8' , color: '#08519C'}
]

export const etp_2 = [
  {value: '0' , color: '#993404'},
  {value: '0,1 - 5' , color: '#CC4C02'},
  {value: '5,1 - 10' , color: '#EC7014'},
  {value: '10,1 - 15' , color: '#FE9929'},
  {value: '15,1 - 20' , color: '#FEC44F'},
  {value: '20,1 - 25' , color: '#FEE391'},
  {value: '25,1 - 30' , color: '#FFF7BC'},
  {value: '30,1 - 35' , color: '#C6DBEF'},
  {value: '35,1 - 40' , color: '#9ECAE1'},
  {value: '40,1 - 50' , color: '#6BAED6'},
  {value: '50,1 - 60' , color: '#4292C6'},
  {value: '60,1 - 80' , color: '#2171B5'},
  {value: '> 80' , color: '#08519C'}
]

export const etp_3 = [
  {value: '0' , color: '#993404'},
  {value: '0,1 - 20' , color: '#CC4C02'},
  {value: '20,1 - 30' , color: '#EC7014'},
  {value: '30,1 - 40' , color: '#FE9929'},
  {value: '40,1 - 50' , color: '#FEC44F'},
  {value: '50,1 - 60' , color: '#FEE391'},
  {value: '60,1 - 70' , color: '#FFF7BC'},
  {value: '70,1 - 80' , color: '#C6DBEF'},
  {value: '80,1 - 90' , color: '#9ECAE1'},
  {value: '90,1 - 110' , color: '#6BAED6'},
  {value: '110,1 - 140' , color: '#4292C6'},
  {value: '140,1 - 180' , color: '#2171B5'},
  {value: '> 180' , color: '#08519C'}
]

export const etp_4 = [
  {value: '<= 800' , color: '#993404'},
  {value: '800,1 - 1000' , color: '#CC4C02'},
  {value: '1000,1 - 1200' , color: '#EC7014'},
  {value: '1200,1 - 1600' , color: '#FE9929'},
  {value: '1600,1 - 1800' , color: '#FEC44F'},
  {value: '1800,1 - 2000' , color: '#FEE391'},
  {value: '2000,1 - 2200' , color: '#FFF7BC'},
  {value: '2200,1 - 2400' , color: '#C6DBEF'},
  {value: '2400,1 - 2600' , color: '#9ECAE1'},
  {value: '2600,1 - 2800' , color: '#6BAED6'},
  {value: '2800,1 - 3000' , color: '#4292C6'},
  {value: '3000,1 - 3200' , color: '#2171B5'},
  {value: '> 3200' , color: '#08519C'}
]

export const ur = [
  {value: '< 20' , color: '#F7FCF5'},
  {value: '20 - 29,9' , color: '#F5F5F5'},
  {value: '30 - 39,9' , color: '#DEEBF7'},
  {value: '40 - 49,9' , color: '#C6DBEF'},
  {value: '50 - 59,9' , color: '#9ECAE1'},
  {value: '60 - 69,9' , color: '#6BAED6'},
  {value: '70 - 79,9' , color: '#4292C6'},
  {value: '80 - 89,9' , color: '#2171B5'},
  {value: '>= 90' , color: '#08519C'}
]

export const p_1 = [
  {value: '< 1' , color: 'TRANSP'},
  {value: '1,1 - 5' , color: '#993404'},
  {value: '5,1 - 10' , color: '#CC4C02'},
  {value: '10,1 - 15' , color: '#EC7014'},
  {value: '15,1 - 20' , color: '#FE9929'},
  {value: '20,1 - 25' , color: '#FEC44F'},
  {value: '25,1 - 30' , color: '#FEE391'},
  {value: '30,1 - 35' , color: '#FFF7BC'},
  {value: '35,1 - 40' , color: '#C6DBEF'},
  {value: '40,1 - 50' , color: '#9ECAE1'},
  {value: '50,1 - 60' , color: '#6BAED6'},
  {value: '60,1 - 70' , color: '#4292C6'},
  {value: '70,1 - 80' , color: '#2171B5'},
  {value: '> 80' , color: '#08519C'}
]

export const p_2 = [
  {value: '< 1' , color: 'TRANSP'},
  {value: '1,1 - 25' , color: '#993404'},
  {value: '25,1 - 50' , color: '#CC4C02'},
  {value: '50,1 - 75' , color: '#EC7014'},
  {value: '75,1 - 100' , color: '#FE9929'},
  {value: '100,1 - 125' , color: '#FEC44F'},
  {value: '125,1 - 150' , color: '#FEE391'},
  {value: '150,1 - 200' , color: '#FFF7BC'},
  {value: '200,1 - 250' , color: '#C6DBEF'},
  {value: '250,1 - 300' , color: '#9ECAE1'},
  {value: '300,1 - 400' , color: '#6BAED6'},
  {value: '400,1 - 500' , color: '#4292C6'},
  {value: '500,1 - 600' , color: '#2171B5'},
  {value: '> 600' , color: '#08519C'}
]

export const p_3 = [
  {value: '< 1' , color: 'TRANSP'},
  {value: '1,1 - 25' , color: '#993404'},
  {value: '25,1 - 50' , color: '#CC4C02'},
  {value: '50,1 - 100' , color: '#EC7014'},
  {value: '100,1 - 150' , color: '#FE9929'},
  {value: '150,1 - 200' , color: '#FEC44F'},
  {value: '200,1 - 250' , color: '#FEE391'},
  {value: '250,1 - 300' , color: '#FFF7BC'},
  {value: '300,1 - 350' , color: '#C6DBEF'},
  {value: '350,1 - 400' , color: '#9ECAE1'},
  {value: '400,1 - 500' , color: '#6BAED6'},
  {value: '500,1 - 600' , color: '#4292C6'},
  {value: '600,1 - 700' , color: '#2171B5'},
  {value: '> 700' , color: '#08519C'}
]

export const p_4 = [
  {value: '< 1' , color: 'TRANSP'},
  {value: '1,1 - 400' , color: '#993404'},
  {value: '400,1 - 600' , color: '#CC4C02'},
  {value: '600,1 - 800' , color: '#EC7014'},
  {value: '800,1 - 1000' , color: '#FE9929'},
  {value: '1000,1 - 1200' , color: '#FEC44F'},
  {value: '1200,1 - 1400' , color: '#FEE391'},
  {value: '1400,1 - 1600' , color: '#FFF7BC'},
  {value: '1600,1 - 1800' , color: '#C6DBEF'},
  {value: '1800,1 - 2000' , color: '#9ECAE1'},
  {value: '2000,1 - 2200' , color: '#6BAED6'},
  {value: '2200,1 - 2400' , color: '#4292C6'},
  {value: '2400,1 - 2600' , color: '#2171B5'},
  {value: '> 2600' , color: '#08519C'}
]

export const bhbox = [
  {value: '< 1' , color: '#6C0001'},
  {value: '1 - 10' , color: '#B00004'},
  {value: '10,1 - 20' , color: '#FB0007'},
  {value: '20,1 - 30' , color: '#D9560D'},
  {value: '30,1 - 40' , color: '#FEC30A'},
  {value: '40,1 - 60' , color: '#FFFF54'},
  {value: '60,1 - 80' , color: '#16A53F'},
  {value: '> 80' , color: '#0000FF'}
]

export const agryfire = [
  {value: 'Nulo' , color: '#FFFF54'},
  {value: 'Baixo' , color: '#FEC30A'},
  {value: 'Médio' , color: '#ff8a4a'},
  {value: 'Alto' , color: '#ff5e00'},
  {value: 'Muito Alto' , color: '#ff0000'},
]

export const flowering_risk = [
  {value: 'Muito Alto', color: '#EF3B2C'},
  {value: 'Alto', color: '#FF8A4A'},
  {value: 'Moderado', color: '#FFFA22'},
  {value: 'Baixo', color: '#74C476'},
  {value: 'Muito Baixo', color: '#6BAED6'}
]

export const def = [
  {value: '0 - 0,9' , color: '#FFFFE5'},
  {value: '1 - 9' , color: '#FFF7BC'},
  {value: '10 - 19' , color: '#FFFA22'},
  {value: '20 - 29' , color: '#FFE61E'},
  {value: '30 - 39' , color: '#FFD41A'},
  {value: '40 - 49' , color: '#FFBF15'},
  {value: '50 - 59' , color: '#FF990D'},
  {value: '60 - 69' , color: '#FB6A4A'},
  {value: '70 - 79' , color: '#EF3B2C'},
  {value: '80 - 89' , color: '#CB181D'},
  {value: '>= 90' , color: '#67000D'}
]

export const def_1 = [
  {value: '0',          color: 'TRANSP'},
  {value: '0.1 - 1.0',  color: '#FFF7BC'},
  {value: '1.1 - 2.0',  color: '#FFFA22'},
  {value: '2.1 - 3.0',  color: '#FFE61E'},
  {value: '3.1 - 4.0',  color: '#FFD41A'},
  {value: '4.1 - 5.0',  color: '#FFBF15'},
  {value: '5.1 - 6.0',  color: '#FF990D'},
  {value: '6.1 - 7.0',  color: '#FB6A4A'},
  {value: '7.1 - 8.0',  color: '#EF3B2C'},
  {value: '8.1 - 9.0',  color: '#CB181D'},
  {value: '> 9.0',      color: '#67000D'},
]

export const def_2 = [
  {value: '0',            color: 'TRANSP'},
  {value: '0.1 - 10.0',   color: '#FFF7BC'},
  {value: '10.1 - 20.0',  color: '#FFFA22'},
  {value: '20.1 - 30.0',  color: '#FFE61E'},
  {value: '30.1 - 40.0',  color: '#FFD41A'},
  {value: '40.1 - 50.0',  color: '#FFBF15'},
  {value: '50.1 - 60.0',  color: '#FF990D'},
  {value: '60.1 - 70.0',  color: '#FB6A4A'},
  {value: '70.1 - 80.0',  color: '#EF3B2C'},
  {value: '80.1 - 90.0',  color: '#CB181D'},
  {value: '> 90',        color: '#67000D'},
]

export const def_3 = [
  {value: '0',              color: 'TRANSP'},
  {value: '0.1 - 30.0',     color: '#FFF7BC'},
  {value: '30.1 - 60.0',    color: '#FFFA22'},
  {value: '60.1 - 90.0',    color: '#FFE61E'},
  {value: '90.1 - 120.0',   color: '#FFD41A'},
  {value: '120.1 - 150.0',  color: '#FFBF15'},
  {value: '150.1 - 180.0',  color: '#FF990D'},
  {value: '180.1 - 210.0',  color: '#FB6A4A'},
  {value: '210.1 - 240.0',  color: '#EF3B2C'},
  {value: '240.1 - 270.0',  color: '#CB181D'},
  {value: '> 270.0',        color: '#67000D'},
]

export const def_4 = [
  {value: '0',                color: 'TRANSP'},
  {value: '0.1 - 200.0',      color: '#FFF7BC'},
  {value: '200.1 - 400.0',    color: '#FFFA22'},
  {value: '400.1 - 600.0',    color: '#FFE61E'},
  {value: '600.1 - 800.0',    color: '#FFD41A'},
  {value: '800.1 - 1000.0',   color: '#FFBF15'},
  {value: '1000.1 - 1200.0',  color: '#FF990D'},
  {value: '1200.1 - 1400.0',  color: '#FB6A4A'},
  {value: '1400.1 - 1600.0',  color: '#EF3B2C'},
  {value: '1600.1 - 1800.0',  color: '#CB181D'},
  {value: '> 1800.0',         color: '#67000D'},
]

export const exc = [
  {value: '0 - 0,9' , color: 'TRANSP'},
  {value: '1 - 9' ,   color: '#F7FCF5'},
  {value: '10 - 19' , color: '#F5F5F5'},
  {value: '20 - 29' , color: '#DEEBF7'},
  {value: '30 - 39' , color: '#C6DBEF'},
  {value: '40 - 49' , color: '#9ECAE1'},
  {value: '50 - 59' , color: '#6BAED6'},
  {value: '60 - 69' , color: '#4292C6'},
  {value: '70 - 79' , color: '#2171B5'},
  {value: '80 - 89' , color: '#08519C'},
  {value: '>= 90' ,   color: '#08306B'}
]

export const exc_1 = [
  {value: '0',            color: 'TRANSP' },
  {value: '0.1 - 10.0',   color: '#F7FCF5'},
  {value: '10.1 - 20.0',  color: '#F5F5F5'},
  {value: '20.1 - 30.0',  color: '#DEEBF7'},
  {value: '30.1 - 40.0',  color: '#C6DBEF'},
  {value: '40.1 - 50.0',  color: '#9ECAE1'},
  {value: '50.1 - 60.0',  color: '#6BAED6'},
  {value: '60.1 - 70.0',  color: '#4292C6'},
  {value: '70.1 - 80.0',  color: '#2171B5'},
  {value: '80.1 - 90.0',  color: '#08519C'},
  {value: '> 90',         color: '#08306B'},
]

export const exc_2 = [
  {value: '0',            color: 'TRANSP' },
  {value: '0.1 - 10.0',   color: '#F7FCF5'},
  {value: '10.1 - 20.0',  color: '#F5F5F5'},
  {value: '20.1 - 30.0',  color: '#DEEBF7'},
  {value: '30.1 - 40.0',  color: '#C6DBEF'},
  {value: '40.1 - 50.0',  color: '#9ECAE1'},
  {value: '50.1 - 60.0',  color: '#6BAED6'},
  {value: '60.1 - 70.0',  color: '#4292C6'},
  {value: '70.1 - 80.0',  color: '#2171B5'},
  {value: '80.1 - 90.0',  color: '#08519C'},
  {value: '> 90',         color: '#08306B'},
]

export const exc_3 = [
  {value: '0',              color: 'TRANSP' },
  {value: '0.1 - 50.0',     color: '#F7FCF5'},
  {value: '50.1 - 100.0',   color: '#F5F5F5'},
  {value: '100.1 - 150.0',  color: '#DEEBF7'},
  {value: '150.1 - 200.0',  color: '#C6DBEF'},
  {value: '200.1 - 250.0',  color: '#9ECAE1'},
  {value: '250.1 - 300.0',  color: '#6BAED6'},
  {value: '300.1 - 350.0',  color: '#4292C6'},
  {value: '350.1 - 400.0',  color: '#2171B5'},
  {value: '400.1 - 450.0',  color: '#08519C'},
  {value: '> 450',          color: '#08306B'},
]

export const exc_4 = [
  {value: '0',                color: 'TRANSP' },
  {value: '0.1 - 200.0',      color: '#F7FCF5'},
  {value: '200.1 - 400.0',    color: '#F5F5F5'},
  {value: '400.1 - 600.0',    color: '#DEEBF7'},
  {value: '600.1 - 800.0',    color: '#C6DBEF'},
  {value: '800.1 - 1000.0',   color: '#9ECAE1'},
  {value: '1000.1 - 1200.0',  color: '#6BAED6'},
  {value: '1200.1 - 1400.0',  color: '#4292C6'},
  {value: '1400.1 - 1600.0',  color: '#2171B5'},
  {value: '1600.1 - 1800.0',  color: '#08519C'},
  {value: '> 1800.0',         color: '#08306B'},
]

export const arm_25 = [
  {value: '0 - 2.5' , color: '#100404'},
  {value: '2.6 - 5' , color: '#6C0001'},
  {value: '5.1 - 7.5' , color: '#B00004'},
  {value: '7.6 - 10' , color: '#FB0007'},
  {value: '10,1 - 12.5' , color: '#D9560D'},
  {value: '12.6 - 15' , color: '#FEC30A'},
  {value: '15,1 - 17.5' , color: '#FFFF54'},
  {value: '17.6 - 20' , color: '#16A53F'},
  {value: '20,1 - 22.5' , color: '#2171B5'},
  {value: '22.6 - 25' , color: '#0000FF'}
]

export const arm_50 = [
  {value: '0 - 5' , color: '#100404'},
  {value: '5,1 - 10' , color: '#6C0001'},
  {value: '10,1 - 15' , color: '#B00004'},
  {value: '15,1 - 20' , color: '#FB0007'},
  {value: '20,1 - 25' , color: '#D9560D'},
  {value: '25,1 - 30' , color: '#FEC30A'},
  {value: '30,1 - 35' , color: '#FFFF54'},
  {value: '35,1 - 40' , color: '#16A53F'},
  {value: '40,1 - 45' , color: '#2171B5'},
  {value: '45,1 - 50' , color: '#0000FF'}
]

export const arm_75 = [
  {value: '0 - 7,5' , color: '#100404'},
  {value: '7,6 - 15' , color: '#6C0001'},
  {value: '15,1 - 22,5' , color: '#B00004'},
  {value: '22,6 - 30' , color: '#FB0007'},
  {value: '30,1 - 37,5' , color: '#D9560D'},
  {value: '37,6 - 45' , color: '#FEC30A'},
  {value: '45,1 - 52,5' , color: '#FFFF54'},
  {value: '52,6 - 60' , color: '#16A53F'},
  {value: '60,1 - 67,5' , color: '#2171B5'},
  {value: '67,6 - 75' , color: '#0000FF'}
]

export const arm_100 = [
  {value: '0 - 10' , color: '#100404'},
  {value: '10,1 - 20' , color: '#6C0001'},
  {value: '20,1 - 30' , color: '#B00004'},
  {value: '30,1 - 40' , color: '#FB0007'},
  {value: '40,1 - 50' , color: '#D9560D'},
  {value: '50,1 - 60' , color: '#FEC30A'},
  {value: '60,1 - 70' , color: '#FFFF54'},
  {value: '70,1 - 80' , color: '#16A53F'},
  {value: '80,1 - 90' , color: '#2171B5'},
  {value: '90,1 - 100' , color: '#0000FF'}
]

export const arm_125 = [
  {value: '0 - 12,5' , color: '#100404'},
  {value: '12,6 - 25' , color: '#6C0001'},
  {value: '25,1 - 37,5' , color: '#B00004'},
  {value: '37,6 - 50' , color: '#FB0007'},
  {value: '50,1 - 62,5' , color: '#D9560D'},
  {value: '62,6 - 75' , color: '#FEC30A'},
  {value: '75,1 - 87,5' , color: '#FFFF54'},
  {value: '87,6 - 100' , color: '#16A53F'},
  {value: '100,1 - 112,5' , color: '#2171B5'},
  {value: '112,6 - 125' , color: '#0000FF'}
]

export const arm_150 = [
  {value: '0 - 15' , color: '#100404'},
  {value: '15,1 - 30' , color: '#6C0001'},
  {value: '30,1 - 45' , color: '#B00004'},
  {value: '45,1 - 60' , color: '#FB0007'},
  {value: '60,1 - 75' , color: '#D9560D'},
  {value: '75,1 - 90' , color: '#FEC30A'},
  {value: '90,1 - 105' , color: '#FFFF54'},
  {value: '105,1 - 120' , color: '#16A53F'},
  {value: '120,1 - 135' , color: '#2171B5'},
  {value: '135,1 - 150' , color: '#0000FF'}
]

export const arm_200 = [
  {value: '0 - 20' , color: '#100404'},
  {value: '20,1 - 40' , color: '#6C0001'},
  {value: '40,1 - 60' , color: '#B00004'},
  {value: '60,1 - 80' , color: '#FB0007'},
  {value: '80,1 - 100' , color: '#D9560D'},
  {value: '100,1 - 120' , color: '#FEC30A'},
  {value: '120,1 - 140' , color: '#FFFF54'},
  {value: '140,1 - 160' , color: '#16A53F'},
  {value: '160,1 - 180' , color: '#2171B5'},
  {value: '180,1 - 200' , color: '#0000FF'}
]

export const arm_250 = [
  {value: '0 - 25' , color: '#100404'},
  {value: '25,1 - 50' , color: '#6C0001'},
  {value: '50,1 - 75' , color: '#B00004'},
  {value: '75,1 - 100' , color: '#FB0007'},
  {value: '100,1 - 125' , color: '#D9560D'},
  {value: '125,1 - 150' , color: '#FEC30A'},
  {value: '150,1 - 175' , color: '#FFFF54'},
  {value: '175,1 - 200' , color: '#16A53F'},
  {value: '200,1 - 225' , color: '#2171B5'},
  {value: '225,1 - 250' , color: '#0000FF'}
]

export const arm_300 = [
  {value: '0 - 30' , color: '#100404'},
  {value: '30,1 - 60' , color: '#6C0001'},
  {value: '60,1 - 90' , color: '#B00004'},
  {value: '90,1 - 120' , color: '#FB0007'},
  {value: '120,1 - 150' , color: '#D9560D'},
  {value: '150,1 - 180' , color: '#FEC30A'},
  {value: '180,1 - 210' , color: '#FFFF54'},
  {value: '210,1 - 240' , color: '#16A53F'},
  {value: '240,1 - 270' , color: '#2171B5'},
  {value: '270,1 - 300' , color: '#0000FF'}
]

export const excdef_1 = [
  {value: '< -9' , color: '#67000D'},
  {value: '-9 - -8,1' , color: '#CB181D'},
  {value: '-8 - -7,1' , color: '#EF3B2C'},
  {value: '-7 - -6,1' , color: '#FB6A4A'},
  {value: '-6 - -5,1' , color: '#FF990D'},
  {value: '-5 - -4,1' , color: '#FFBF15'},
  {value: '-4 - -3,1' , color: '#FFD41A'},
  {value: '-3 - -2,1' , color: '#FFE61E'},
  {value: '-2 - -1,1' , color: '#FFFA22'},
  {value: '-1 - -0,1' , color: '#FFF7BC'},
  {value: '0 - 0,9' , color: 'TRANSP'},
  {value: '1 - 1,9' , color: '#F7FCF5'},
  {value: '2 - 2,9' , color: '#F5F5F5'},
  {value: '3 - 3,9' , color: '#DEEBF7'},
  {value: '4 - 4,9' , color: '#C6DBEF'},
  {value: '5 - 5,9' , color: '#9ECAE1'},
  {value: '6 - 6,9' , color: '#6BAED6'},
  {value: '7 - 7,9' , color: '#4292C6'},
  {value: '8 - 8,9' , color: '#2171B5'},
  {value: '>= 9' , color: '#08519C'}
]

export const plan = [
  {value: 'Favorável' , color: '#00B050'},
  {value: 'Razoável' , color: '#FFFF66'},
  {value: 'Desfavorável' , color: '#FF0000'}
]

export const fert = [
  {value: 'Favorável' , color: '#00B050'},
  {value: 'Razoável' , color: '#FFFF66'},
  {value: 'Desfavorável' , color: '#FF0000'}
]

export const traf = [
  {value: 'Favorável' , color: '#00B050'},
  {value: 'Razoável' , color: '#FFFF66'},
  {value: 'Desfavorável' , color: '#FF0000'}
]

export const herb = [
  {value: 'Época Umida', color: '#0000FF'},
  {value: 'Época Semi-Umida', color: '#8DB4E2'},
  {value: 'Época Semi-Seca' , color: '#FFCC00'},
  {value: 'Época Seca' , color: '#FF0000'}
]

export const ps = [
  {value: 'Alto Risco de Compactação', color: '#0000FF'},
  {value: 'Médio Risco de Compactação', color: '#8DB4E2'},
  {value: 'Favorável' , color: '#00B050'},
  {value: 'Médio de Desestruturação' , color: '#FFCC00'},
  {value: 'Alto Risco de Desestruturação' , color: '#FF0000'}
]

export const cresc = [
  {value: 'Muito Severo' , color: '#6C0001'},
  {value: 'Severo' , color: '#B00004'},
  {value: 'Muito Crítico' , color: '#FB0007'},
  {value: 'Crítico' , color: '#D9560D'},
  {value: 'Desfavorável' , color: '#FEC30A'},
  {value: 'Razoável' , color: '#FFFF54'},
  {value: 'Favorável' , color: '#16A53F'},
  {value: 'Muito Favorável' , color: '#0000FF'}
]

export const pulv = [
  {value: 'Favorável' , color: '#00B050'},
  {value: 'Desfavorável' , color: '#FF0000'}
]

export const temp_anomaly_1 = [
  {value: '>= 6' ,      color: '#67000D'},
  {value: '5 - 5,9' ,   color: '#A50F15'},
  {value: '4 - 4,9' ,   color: '#CB181D'},
  {value: '3 - 3,9' ,   color: '#EF3B2C'},
  {value: '2 - 2,9' ,   color: '#FB6A4A'},
  {value: '1 - 1,9' ,   color: '#FC9272'},
  {value: '0,5 - 0,9' , color: '#FCBBA1'},
  {value: '0 - 0,4' ,   color: '#FEE0D2'},
  {value: '-0,5 - -0,1',color: '#DEEBF7'},
  {value: '-1 - -0,6' , color: '#C6DBEF'},
  {value: '-2 - -1,1' , color: '#9ECAE1'},
  {value: '-3 - -2,1' , color: '#6BAED6'},
  {value: '-4 - -3,1' , color: '#4292C6'},
  {value: '-5 - -4,1' , color: '#2171B5'},
  {value: '-6 - -5,1' , color: '#08519C'},
  {value: '< -6' ,      color: '#08306B'}
]

export const temp_anomaly_2 = [
  {value: '>= 3,5' ,    color: '#67000D'},
  {value: '3 - 3,4' ,   color: '#A50F15'},
  {value: '2,5 - 2,9' , color: '#CB181D'},
  {value: '2 - 2,4' ,   color: '#EF3B2C'},
  {value: '1,5 - 1,9' , color: '#FB6A4A'},
  {value: '1 - 1,4' ,   color: '#FC9272'},
  {value: '0,5 - 0,9' , color: '#FCBBA1'},
  {value: '0 - 0,4' ,   color: '#FEE0D2'},
  {value: '-0,5 - -0,1',color: '#DEEBF7'},
  {value: '-1 - -0,6' , color: '#C6DBEF'},
  {value: '-1,5 - -1,1',color: '#9ECAE1'},
  {value: '-2 - -1,6' , color: '#6BAED6'},
  {value: '-2,5 - -2,1',color: '#4292C6'},
  {value: '-3 - -2,6' , color: '#2171B5'},
  {value: '-3,5 - -3,1',color: '#08519C'},
  {value: '< -3,5' ,    color: '#08306B'}
]

export const rs_anomaly_decendial = [
  {value: '>= 8' , color: '#08306B'},
  {value: '6 - 7,9' , color: '#08519C'},
  {value: '5 - 5,9' , color: '#2171B5'},
  {value: '4 - 4,9' , color: '#4292C6'},
  {value: '3 - 3,9' , color: '#6BAED6'},
  {value: '2 - 2,9' , color: '#9ECAE1'},
  {value: '1 - 1,9' , color: '#C6DBEF'},
  {value: '0 - 0,9' , color: '#DEEBF7'},
  {value: '-1 - -0,1' , color: '#FEE0D2'},
  {value: '-2 - -1,1' , color: '#FCBBA1'},
  {value: '-3 - -2,1' , color: '#FC9272'},
  {value: '-4 - -3,1' , color: '#FB6A4A'},
  {value: '-5 - -4,1' , color: '#EF3B2C'},
  {value: '-6 - -5,1' , color: '#CB181D'},
  {value: '-8 - -6,1' , color: '#A50F15'},
  {value: '< -8' , color: '#67000D'}
]

export const rs_anomaly_monthly = [
  {value: '>= 6' , color: '#08306B'},
  {value: '5 - 5,9' , color: '#08519C'},
  {value: '4 - 4,9' , color: '#2171B5'},
  {value: '3 - 3,9' , color: '#4292C6'},
  {value: '2 - 2,9' , color: '#6BAED6'},
  {value: '1 - 1,9' , color: '#9ECAE1'},
  {value: '0,5 - 0,9' , color: '#C6DBEF'},
  {value: '0 - 0,4' , color: '#DEEBF7'},
  {value: '-0,5 - -0,1' , color: '#FEE0D2'},
  {value: '-1 - -0,6' , color: '#FCBBA1'},
  {value: '-2 - -1,1' , color: '#FC9272'},
  {value: '-3 - -2,1' , color: '#FB6A4A'},
  {value: '-4 - -3,1' , color: '#EF3B2C'},
  {value: '-5 - -4,1' , color: '#CB181D'},
  {value: '-6 - -5,1' , color: '#A50F15'},
  {value: '< -6' , color: '#67000D'}
]

export const rs_anomaly_annualy = [
  {value: '>= 3,5' , color: '#08306B'},
  {value: '3 - 3,4' , color: '#08519C'},
  {value: '2,5 - 2,9' , color: '#2171B5'},
  {value: '2 - 2,4' , color: '#4292C6'},
  {value: '1,5 - 1,9' , color: '#6BAED6'},
  {value: '1 - 1,4' , color: '#9ECAE1'},
  {value: '0,5 - 0,9' , color: '#C6DBEF'},
  {value: '0 - 0,4' , color: '#DEEBF7'},
  {value: '-0,5 - -0,1' , color: '#FEE0D2'},
  {value: '-1 - -0,6' , color: '#FCBBA1'},
  {value: '-1,5 - -1,1' , color: '#FC9272'},
  {value: '-2 - -1,6' , color: '#FB6A4A'},
  {value: '-2,5 - -2,1' , color: '#EF3B2C'},
  {value: '-3 - -2,6' , color: '#CB181D'},
  {value: '-3,5 - -3,1' , color: '#A50F15'},
  {value: '< -3,5' , color: '#67000D'}
]

export const gd_anomaly_decendial = [
  {value: '>= 35' , color: '#08306B'},
  {value: '30 - 34,9' , color: '#08519C'},
  {value: '25 - 29,9' , color: '#2171B5'},
  {value: '20 - 24,9' , color: '#4292C6'},
  {value: '15 - 19,9' , color: '#6BAED6'},
  {value: '10 - 14,9' , color: '#9ECAE1'},
  {value: '5 - 9,9' , color: '#C6DBEF'},
  {value: '0 - 4,9' , color: '#DEEBF7'},
  {value: '-5 - -0,1' , color: '#FEE0D2'},
  {value: '-10 - -5,1' , color: '#FCBBA1'},
  {value: '-15 - -10,1' , color: '#FC9272'},
  {value: '-20 - -15,1' , color: '#FB6A4A'},
  {value: '-25 - -20,1' , color: '#EF3B2C'},
  {value: '-30 - -25,1' , color: '#CB181D'},
  {value: '-35 - -30,1' , color: '#A50F15'},
  {value: '< -35' , color: '#67000D'}
]

export const gd_anomaly_monthly = [
  {value: '>= 175' , color: '#08306B'},
  {value: '150 - 174,9' , color: '#08519C'},
  {value: '125 - 149,9' , color: '#2171B5'},
  {value: '100 - 124,9' , color: '#4292C6'},
  {value: '75 - 99,9' , color: '#6BAED6'},
  {value: '50 - 74,9' , color: '#9ECAE1'},
  {value: '25 - 49,9' , color: '#C6DBEF'},
  {value: '0 - 24,9' , color: '#DEEBF7'},
  {value: '-25 - -0,1' , color: '#FEE0D2'},
  {value: '-50 - -25,1' , color: '#FCBBA1'},
  {value: '-75 - -50,1' , color: '#FC9272'},
  {value: '-100 - -75,1' , color: '#FB6A4A'},
  {value: '-125 - -100,1' , color: '#EF3B2C'},
  {value: '-150 - -125,1' , color: '#CB181D'},
  {value: '-175 - -150,1' , color: '#A50F15'},
  {value: '< -175' , color: '#67000D'}
]

export const gd_anomaly_annualy = [
  {value: '>= 600' , color: '#08306B'},
  {value: '400 - 599,9' , color: '#08519C'},
  {value: '300 - 399,9' , color: '#2171B5'},
  {value: '200 - 299,9' , color: '#4292C6'},
  {value: '100 - 199,9' , color: '#6BAED6'},
  {value: '50 - 99,9' , color: '#9ECAE1'},
  {value: '25 - 49,9' , color: '#C6DBEF'},
  {value: '0 - 24,9' , color: '#DEEBF7'},
  {value: '-25 - -0,1' , color: '#FEE0D2'},
  {value: '-50 - -25,1' , color: '#FCBBA1'},
  {value: '-100 - -50,1' , color: '#FC9272'},
  {value: '-200 - -100,1' , color: '#FB6A4A'},
  {value: '-300 - -200,1' , color: '#EF3B2C'},
  {value: '-400 - -300,1' , color: '#CB181D'},
  {value: '-600 - -400,1' , color: '#A50F15'},
  {value: '< -600' , color: '#67000D'}
]

export const u2_anomaly = [
  {value: '>= 2,5' , color: '#08306B'},
  {value: '2 - 2,4' , color: '#08519C'},
  {value: '1,5 - 1,9' , color: '#2171B5'},
  {value: '1 - 1,4' , color: '#4292C6'},
  {value: '0,75 - 0,9' , color: '#6BAED6'},
  {value: '0,5 - 0,74' , color: '#9ECAE1'},
  {value: '0,25 - 0,4' , color: '#C6DBEF'},
  {value: '0 - 0,24' , color: '#DEEBF7'},
  {value: '-0,25 - -0,1' , color: '#FEE0D2'},
  {value: '-0,5 - -0,26' , color: '#FCBBA1'},
  {value: '-0,75 - -0,6' , color: '#FC9272'},
  {value: '-1 - -0,76' , color: '#FB6A4A'},
  {value: '-1,5 - -1,1' , color: '#EF3B2C'},
  {value: '-2 - -1,6' , color: '#CB181D'},
  {value: '-2,5 - -2,1' , color: '#A50F15'},
  {value: '< -2,5' , color: '#67000D'}
]

export const prec_anomaly_decendial = [
  {value: '>= 175' , color: '#08306B'},
  {value: '150 - 174,9' , color: '#08519C'},
  {value: '125 - 149,9' , color: '#2171B5'},
  {value: '100 - 124,9' , color: '#4292C6'},
  {value: '75 - 99,9' , color: '#6BAED6'},
  {value: '50 - 74,9' , color: '#9ECAE1'},
  {value: '25 - 49,9' , color: '#C6DBEF'},
  {value: '0 - 24,9' , color: '#DEEBF7'},
  {value: '-25 - -0,1' , color: '#FEE0D2'},
  {value: '-50 - -25,1' , color: '#FCBBA1'},
  {value: '-75 - -50,1' , color: '#FC9272'},
  {value: '-100 - -75,1' , color: '#FB6A4A'},
  {value: '-125 - -100,1' , color: '#EF3B2C'},
  {value: '-150 - -125,1' , color: '#CB181D'},
  {value: '-175 - -150,1' , color: '#A50F15'},
  {value: '< -175' , color: '#67000D'}
]

export const prec_anomaly_monthly = [
  {value: '>= 300' , color: '#08306B'},
  {value: '200 - 299,9' , color: '#08519C'},
  {value: '150 - 199,9' , color: '#2171B5'},
  {value: '100 - 149,9' , color: '#4292C6'},
  {value: '75 - 99,9' , color: '#6BAED6'},
  {value: '50 - 74,9' , color: '#9ECAE1'},
  {value: '25 - 49,9' , color: '#C6DBEF'},
  {value: '0 - 24,9' , color: '#DEEBF7'},
  {value: '-25 - -0,1' , color: '#FEE0D2'},
  {value: '-50 - -25,1' , color: '#FCBBA1'},
  {value: '-75 - -50,1' , color: '#FC9272'},
  {value: '-100 - -75,1' , color: '#FB6A4A'},
  {value: '-150 - -100,1' , color: '#EF3B2C'},
  {value: '-200 - -150,1' , color: '#CB181D'},
  {value: '-300 - -200,1' , color: '#A50F15'},
  {value: '< -300' , color: '#67000D'}
]

export const prec_anomaly_annualy = [
  {value: '>= 500' , color: '#08306B'},
  {value: '300 - 499,9' , color: '#08519C'},
  {value: '200 - 399,9' , color: '#2171B5'},
  {value: '150 - 199,9' , color: '#4292C6'},
  {value: '100 - 149,9' , color: '#6BAED6'},
  {value: '50 - 99,9' , color: '#9ECAE1'},
  {value: '25 - 49,9' , color: '#C6DBEF'},
  {value: '0 - 24,9' , color: '#DEEBF7'},
  {value: '-25 - -0,1' , color: '#FEE0D2'},
  {value: '-50 - -25,1' , color: '#FCBBA1'},
  {value: '-100 - -50,1' , color: '#FC9272'},
  {value: '-150 - -100,1' , color: '#FB6A4A'},
  {value: '-200 - -150,1' , color: '#EF3B2C'},
  {value: '-300 - -200,1' , color: '#CB181D'},
  {value: '-500 - -300,1' , color: '#A50F15'},
  {value: '< -500' , color: '#67000D'}
]

export const ur_anomaly = [
  {value: '>= 35' , color: '#08306B'},
  {value: '30 - 34,9' , color: '#08519C'},
  {value: '25 - 29,9' , color: '#2171B5'},
  {value: '20 - 24,9' , color: '#4292C6'},
  {value: '15 - 19,9' , color: '#6BAED6'},
  {value: '10 - 14,9' , color: '#9ECAE1'},
  {value: '5 - 9,9' , color: '#C6DBEF'},
  {value: '0 - 4,9' , color: '#DEEBF7'},
  {value: '-5 - -0,1' , color: '#FEE0D2'},
  {value: '-10 - -5,1' , color: '#FCBBA1'},
  {value: '-15 - -10,1' , color: '#FC9272'},
  {value: '-20 - -15,1' , color: '#FB6A4A'},
  {value: '-25 - -20,1' , color: '#EF3B2C'},
  {value: '-30 - -25,1' , color: '#CB181D'},
  {value: '-35 - -30,1' , color: '#A50F15'},
  {value: '< -35' , color: '#67000D'}
]

export const etp_anomaly_decendial = [
  {value: '>= 17,5' , color: '#08306B'},
  {value: '15 - 17,4' , color: '#08519C'},
  {value: '12,5 - 14,9' , color: '#2171B5'},
  {value: '10 - 12,4' , color: '#4292C6'},
  {value: '7,5 - 9,9' , color: '#6BAED6'},
  {value: '5 - 7,4' , color: '#9ECAE1'},
  {value: '2,5 - 4,9' , color: '#C6DBEF'},
  {value: '0 - 2,4' , color: '#DEEBF7'},
  {value: '-2,5 - -0,1' , color: '#FEE0D2'},
  {value: '-5 - -2,6' , color: '#FCBBA1'},
  {value: '-7,5 - -5,1' , color: '#FC9272'},
  {value: '-10 - -7,6' , color: '#FB6A4A'},
  {value: '-12,5 - -10,1' , color: '#EF3B2C'},
  {value: '-15 - -12,6' , color: '#CB181D'},
  {value: '-17,5 - -15,1' , color: '#A50F15'},
  {value: '< -17,5' , color: '#67000D'}
]

export const etp_anomaly_monthly = [
  {value: '>= 25' , color: '#08306B'},
  {value: '20 - 24,9' , color: '#08519C'},
  {value: '15 - 19,9' , color: '#2171B5'},
  {value: '10 - 14,9' , color: '#4292C6'},
  {value: '7,5 - 9,9' , color: '#6BAED6'},
  {value: '5 - 7,4' , color: '#9ECAE1'},
  {value: '2,5 - 4,9' , color: '#C6DBEF'},
  {value: '0 - 2,4' , color: '#DEEBF7'},
  {value: '-2,5 - -0,1' , color: '#FEE0D2'},
  {value: '-5 - -2,6' , color: '#FCBBA1'},
  {value: '-7,5 - -5,1' , color: '#FC9272'},
  {value: '-10 - -7,6' , color: '#FB6A4A'},
  {value: '-15 - -10,1' , color: '#EF3B2C'},
  {value: '-20 - -15,1' , color: '#CB181D'},
  {value: '-25 - -20,1' , color: '#A50F15'},
  {value: '< -25' , color: '#67000D'}
]

export const etp_anomaly_annualy = [
  {value: '>= 250' , color: '#08306B'},
  {value: '200 - 249,9' , color: '#08519C'},
  {value: '150 - 199,9' , color: '#2171B5'},
  {value: '100 - 149,9' , color: '#4292C6'},
  {value: '75 - 99,9' , color: '#6BAED6'},
  {value: '50 - 74,9' , color: '#9ECAE1'},
  {value: '25 - 49,9' , color: '#C6DBEF'},
  {value: '0 - 24,9' , color: '#DEEBF7'},
  {value: '-25 - -0,1' , color: '#FEE0D2'},
  {value: '-50 - -25,1' , color: '#FCBBA1'},
  {value: '-75 - -50,1' , color: '#FC9272'},
  {value: '-100 - -75,1' , color: '#FB6A4A'},
  {value: '-150 - -100,1' , color: '#EF3B2C'},
  {value: '-200 - -150,1' , color: '#CB181D'},
  {value: '-250 - -200,1' , color: '#A50F15'},
  {value: '< -250' , color: '#67000D'}
]

export function agrytoolsName(type){
  if(type === 'plan'){
    return 'Plantio'
  }else if(type === 'herb'){
    return 'Herbicida'
  }else if(type === 'ps'){
    return 'Preparo do Solo'
  }else if(type === 'fert'){
    return 'Adubação'
  }else if(type === 'traf'){
    return 'Tráfego'
  }else if(type === 'cresc'){
    return 'Crescimento'
  }else if(type === 'pulv'){
    return 'Pulverização'
  }
}

export function variableToName(variable){
  if(variable === 'tmax'){
    return 'Temperatura Máxima do Ar (°C)'
  }else if(variable === 'tmean'){
    return 'Temperatura Média do Ar (°C)'
  }else if(variable === 'tmin'){
    return 'Temperatura Mínima do Ar (°C)'
  }else if(variable === 'rs'){
    return 'Radiação Solar (MJ/m²/d)'
  }else if(variable === 'gd'){
    return 'Graus-dia (°C/dia)'
  }else if(variable === 'prec'){
    return 'Chuva (mm)'
  }else if(variable === 'u2'){
    return 'Vento a 2m (m/s)'
  }else if(variable === 'ur'){
    return 'Umidade Relativa do Ar (%)'
  }else if(variable === 'etp'){
    return 'Evapotranspiração Potencial (mm/dia)'
  }else if(variable === 'etr'){
    return 'Evapotranspiração Real (mm/dia)'
  }else if(variable === 'bhbox'){
    return 'AgryBox'
  }else if(variable === 'fmaac'){
    return 'FMA Acumulado'
  }else if(variable === 'def'){
    return 'Déficit Hídrico'
  }else if(variable === 'exc'){
    return 'Excedente Hídrico'
  }else if(variable === 'excdef' || variable === 'extbh'){
    return 'Balanço Hídrico'
  }else if(variable === 'arm'){
    return 'Armazenamento'
  }else if(variable === 'plan'){
    return 'Plantio/Semeadura'
  }else if(variable === 'herb'){
    return 'Aplicação de Herbicidas'
  }else if(variable === 'traf'){
    return 'Condições de Tráfego e Colheita'
  }else if(variable === 'ps'){
    return 'Condições para Preparo do Solo'
  }else if(variable === 'fert'){
    return 'Adubação'
  }else if(variable === 'cresc'){
    return 'Condições de Crescimento das Culturas'
  }else if(variable === 'pulv'){
    return 'Favorabilidade de Pulverização'
  }else if(variable === 'cane-flowering'){
    return 'Risco de Florescimento'
  }
}

export function variablePreposition(variable) {
  if(variable === 'gd') {
    return 'de';
  }
  else if(variable === 'u2') {
    return 'do';
  }
  else {
    return 'da';
  }
}
