import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getEnosList } from '../../actions/admin'
import { EnosListNewComponent } from "./EnosListNewComponent";

class AdminEnosList extends Component{
  render(){
    return (
      <div className="row admin-information">
        <EnosListNewComponent
          enos={this.props.enosList}
          getEnosList={this.props.getEnosList}
          history={this.props.history}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    enosList: state.admin.enosList,
    enosStatus: state.admin.enosStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getEnosList: () => {
      dispatch(getEnosList())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEnosList)
