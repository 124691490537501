import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import KmzDropAndButton from './KmzDropAndButton';
import KmzFilesTable from './KmzFilesTable';

const styles = {
  titleContainer: {
    margin: '50px 0',
  },
  formTitle: {
    color: '#6CB832',
    fontSize: '1.5rem',
  },
};

function KmzAddedFiles({ setFile, classes, setToaster }) {
  const data = useSelector((state) => state.map.kmz);

  return (
    <div style={ { padding: '20px' } }>
      {
        data?.length
          ? (
            <div style={ { marginBottom: '20px' } }>
              <Typography
                className={ classes.formTitle }
                component="h2"
                variant="subtitle1"
                color="primary"
              >
                Adicionar arquivo KMZ
              </Typography>
            </div>
          )
          : (
            <div className={ classes.titleContainer }>
              <Typography
                className={ classes.formTitle }
                component="h2"
                variant="subtitle1"
                align="center"
                color="primary"
              >
                Nenhum arquivo KMZ no momento
              </Typography>
              <Typography
                className={ classes.formSubtitle }
                component="h3"
                variant="subtitle1"
                align="center"
              >
                Para adicionar, você pode arrastar o arquivo no local indicado ou clicar no botão.
              </Typography>
            </div>
          )
      }

      <KmzDropAndButton
        setFile={ setFile }
        classes={ classes }
        setToaster={ setToaster }
      />

      { data?.length && <KmzFilesTable setToaster={ setToaster } /> }
    </div>
  );
}

export default withStyles(styles)(KmzAddedFiles);
