import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import { getProductList, modifyProductAccess, modifyPointLimit, resetServerMessage } from '../../../actions/admin'
import { resetUpload } from '../../../actions/farms'

import { toast } from 'react-toastify';
import { css } from 'glamor';
import { Grid, Button, GridList, GridListTile, Input } from '@material-ui/core';

const wheatImage = '/images/logos/smart-wheat.png'
const caneImage= '/images/logos/smart-cane.png'
const soyImage= '/images/logos/smart-soy.png'
const cornImage = '/images/logos/smart-corn.png'
const cottonImage = '/images/logos/smart-cotton.png'
const eucalyptusImage = '/images/logos/smart-eucalyptus.png'

class ProductManagement extends Component {
    constructor(props){
        super(props)

        this.state = {
          availableFarms: [],
          selectedFarms: [],
          activeProduct: 0,
          currentMax: 0,
          authorizedIds: [],
          searchAvailable: '',
          searchSelected: ''
        }
    }

    //Lifecycle methods
    UNSAFE_componentWillReceiveProps(nextProps){
      if(nextProps.availablePoints !== this.props.availablePoints){
        
        let availableFarmsIds = new Set(nextProps.authorizedPoints.map(({id}) => id))
        
        const availableFarms = nextProps.availablePoints.filter(({ id }) => !availableFarmsIds.has(id))

        this.setState({
          availableFarms: availableFarms
        })
        window.scrollBy(0, 200)
      }
      if(nextProps.authorizedPoints !== this.props.authorizedPoints){

        let ids = nextProps.authorizedPoints.map(({id}) => {
          return id
        })

        this.setState({
          selectedFarms: nextProps.authorizedPoints,
          authorizedIds: ids
        })
        window.scrollBy(0, 200)
      }
      if(nextProps.registrationLimit !== this.props.registrationLimit){
        this.setState({
          currentMax: nextProps.registrationLimit,
          selectedFarms: nextProps.authorizedPoints
        })
        window.scrollBy(0, 200)
      }
      if(nextProps.registrationLimit === null){
        this.setState({
          currentMax: 0,
          selectedFarms: nextProps.authorizedPoints
        })
      }


      if(nextProps.serverStatus === 200){
        this.onShowToast('Sucesso!', nextProps.serverMessage, true)
      }
      else if(nextProps.serverStatus === 500){
        this.onShowToast('Erro!', 'Por favor tente novamente.', false)
      }
    }

    //#region Helper methods

    getProduct(product){
      switch(product){
        case '1':
          return 'cana'
        case '2':
          return 'soja'
        case '3':
          return 'milho'
        case '4':
          return 'algodao'
        case '5':
          return 'trigo'
        case '6':
          return 'eucalipto'
        default:
          return ''
      }
    }
    
    //#region Render methods

    checkIfAuthorized = (farm) => {

      let authorized = false

      this.state.authorizedIds.forEach(id => {
        if(farm.id === id){
          authorized = true
          return
        }
      })

      return authorized
    }

    onChangeActiveFarm = (farm) => {
        if(this.state.selectedFarms.filter(item => item.id === farm.id).length > 0){
          this.setState(prevState => ({
            selectedFarms: prevState.selectedFarms.filter(item => item.id !== farm.id),
            availableFarms: [...this.state.availableFarms, farm]
          }))
        }
        else if(this.state.selectedFarms.length < this.props.registrationLimit){
          this.setState(prevState => ({
            selectedFarms: [...this.state.selectedFarms, farm],
            availableFarms: prevState.availableFarms.filter(item => item.id !== farm.id)
          }))
        }
        else {
          this.onShowToast('Erro!', 'Você chegou ao limite de pontos cadastrados!', false)
        }
    }

    onProductChange = (event) => {

    let data = {
        user_id: this.props.item.id,
        product: ''
    }

    this.setState({activeProduct: event.target.id})

    data.product = this.getProduct(event.target.id)

    this.props.getProductList(data)
    }

    onAddAllPoints = () => {

      const newList = this.state.selectedFarms.concat(this.state.availableFarms)

      this.setState({
        selectedFarms: newList,
        availableFarms: []
      })
    }
    
    onRemoveAllPoints = () => {

      const newList = this.state.availableFarms.concat(this.state.selectedFarms)

      this.setState({
          availableFarms: newList,
          selectedFarms: []
      })
    }

    onChangePermission = name => event => {
        this.setState({[name]: event.target.checked })
    }

    onSaveNewPermissions = () => {

        if(this.state.selectedFarms.length > this.props.registrationLimit){
          this.onShowToast('Erro!', 'Você chegou ao limite de pontos cadastrados.', false)
          return
        }

        let ids = new Set(this.props.authorizedPoints.map(({id}) => id))
        let pointsToAdd = this.state.selectedFarms.filter(({ id }) => !ids.has(id))
        let pointsToRemove = this.state.availableFarms.filter(({ id }) => ids.has(id))
        
        let data = {
          user_id: this.props.item.id.toString(),
          product: '',
          addPoints: [],
          deletePoints: []
        }

        data.product = this.getProduct(this.state.activeProduct)

        data.addPoints = pointsToAdd.map(point => point.id)
        data.deletePoints = pointsToRemove.map(point => point.id)

        if(data.addPoints.length === 0 && data.deletePoints.length === 0){
          this.onShowToast('Erro!', 'Nenhum ponto foi alterado.', false)
          return
        }

        this.props.modifyProductAccess(data)
    }

    onInputChange = (event) => {
      this.setState({ [event.target.name] : event.target.value });
    }

    onMaxNumberChange = () => {

      if(this.state.currentMax === this.props.registrationLimit){
        this.onShowToast("Erro!", 'Esse já é o limite atual.', false)
        return
      }

      if(this.state.currentMax < this.props.authorizedPoints.length){
        this.onShowToast("Erro!", 'O limite não deve ser menor que o número de pontos cadastrados.', false)
        return
      }

      let data = {
        user_id: this.props.item.id.toString(),
        product: '',
        max: this.state.currentMax
      }
  
      data.product = this.getProduct(this.state.activeProduct)

      this.props.modifyPointLimit(data)
    }

    onShowToast(title, msg, status) {
      const Msg = () =>
        <Fragment>
          <div className='row'>
            <div className='col s12 modal-labels'>
              <label className='modal-label-1'>{title}</label>
            </div>
          </div>
          <div className='row'>
            <label className='modal-label-2'>{msg}</label>
          </div>
        </Fragment>
  
      const options = {
        autoClose: 4000,
        type: toast.TYPE.INFO,
        hideProgressBar: false,
        closeButton: false,
        className: css({
          backgroundColor: '#FFF',
          boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
          borderLeft: status ? '6px solid #26a69a' : '6px solid #FF0000'
        }),
        bodyClassName: css({
          height: '135px',
          backgroundColor: '#FFF',
          '& .row':{
            marginBottom: '15px'
          },
          '& .modal-label-1':{
            fontSize: '16px',
            fontWeight: 700,
            color: status ? '#26a69a' : "#FF0000"
          },
          '& .modal-label-2':{
            fontSize: '14px',
            color: '#3E2723'
          },
          '& .btn-modal': {
            padding: '0 1rem',
            fontWeight: 500,
            fontSize: '12px',
            color: '#3E2723'
          }
        }),
        progressClassName: css({
          backgroundColor: status ? "#26a69a" : "#FF0000"
        })
      };
  
      toast.info(<Msg func={toast.dismiss}/>, options);
      this.props.resetServerMessage();
    }
    //#endregion

    render(){
        let fileteredAvailable = this.state.availableFarms.filter((point) => {
          let initials = point.initials.toLowerCase().indexOf(this.state.searchAvailable.toLowerCase())
          let name = point.name.toLowerCase().indexOf(this.state.searchAvailable.toLowerCase())
          if(name !== -1 || initials !== -1){
            return true
          }
          else{
            return false
          }
        })
        let fileteredSelected = this.state.selectedFarms.filter((point) => {
          let initials = point.initials.toLowerCase().indexOf(this.state.searchSelected.toLowerCase())
          let name = point.name.toLowerCase().indexOf(this.state.searchSelected.toLowerCase())
          if(name !== -1 || initials !== -1){
            return true
          }
          else{
            return false
          }
        })

        return (
            <Grid container className='access-control' justify='center'>
            <Grid item xs={12}>
              <Grid container className='title-row' justify='space-between' alignItems='center'>
                <Grid item>
                  <h5 className='title'>
                      Controle de Acesso
                  </h5>
                </Grid>
                <Grid item>
                  {this.state.activeProduct !== 0 ?
                    <Button className='green-button' variant='contained' onClick={this.onSaveNewPermissions}>
                      Salvar
                    </Button>
                  :
                    null
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='management-container'>
              <Grid container spacing={24} >
                  <Grid item md={3}>
                    <Grid container className='product-container'>
                      <Grid item xs={12} className='product-title'>
                        PRODUTOS
                      </Grid>
                      <Grid item xs={12}>
                        <GridList className='product-list' cellHeight={75} cols={1}>
                          <GridListTile key={'Cane'}>
                            <Button className={this.state.activeProduct === '1' ? 'product-button selected' : 'product-button'}>
                              <img 
                                onClick={this.onProductChange}
                                id={1}
                                className='product-item'
                                alt='sugar-logo'
                                src={caneImage}/>
                            </Button>
                          </GridListTile>
                          <GridListTile key={'Soy'}>
                            <Button className={this.state.activeProduct === '2' ? 'product-button selected' : 'product-button'}>
                              <img
                                onClick={this.onProductChange}
                                id={2}
                                className='product-item' 
                                alt='soy-logo' 
                                src={soyImage}/>
                            </Button>                      
                          </GridListTile>
                          <GridListTile key={'Corn'}>
                            <Button className={this.state.activeProduct === '3' ? 'product-button selected' : 'product-button'}>
                              <img
                                onClick={this.onProductChange}
                                id={3}
                                className='product-item' 
                                alt='corn-logo' 
                                src={cornImage}/>
                            </Button>                      
                          </GridListTile>
                          <GridListTile key={'Cotton'}>
                            <Button className={this.state.activeProduct === '4' ? 'product-button selected' : 'product-button'}>
                              <img
                                onClick={this.onProductChange}
                                id={4}
                                className='product-item' 
                                alt='cotton-logo' 
                                src={cottonImage}/>
                            </Button>                      
                          </GridListTile>
                          <GridListTile key={'Wheat'}>
                            <Button className={this.state.activeProduct === '5' ? 'product-button selected' : 'product-button'}>
                              <img
                                onClick={this.onProductChange}
                                id={5}
                                className='product-item' 
                                alt='wheat-logo' 
                                src={wheatImage}/>
                            </Button>                      
                          </GridListTile>
                          <GridListTile key={'Eucalyptus'}>
                            <Button className={this.state.activeProduct === '6' ? 'product-button selected' : 'product-button'}>
                              <img
                                onClick={this.onProductChange}
                                id={6}
                                className='product-item' 
                                alt='eucalyptus-logo' 
                                src={eucalyptusImage}/>
                            </Button>                      
                          </GridListTile>
                        </GridList>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.activeProduct !== 0 ?
                    <Fragment>
                      <Grid item md={4}>
                        <Grid container className='point-container'>
                          <Grid item xs={12} className='point-title'>
                            PONTOS DISPONÍVEIS
                          </Grid>
                          <Grid item xs={12} className='point-search'>
                            <Grid container alignItems='center' justify='space-between' className='point-title'>
                              <Grid item xs={12}>
                                <Input
                                  name='searchAvailable'
                                  disableUnderline={true} 
                                  onChange={this.onInputChange}
                                  placeholder='BUSCAR'
                                />
                              </Grid> 
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <GridList className='point-list' cellHeight={35} cols={1}>
                                {fileteredAvailable.map(farm => (
                                  <GridListTile key={farm.id}>
                                    <Button
                                      className='point-button'
                                      onClick={() => this.onChangeActiveFarm(farm)}>
                                      {farm.name === '' ? farm.initials : farm.name + ' - ' + farm.initials}
                                    </Button>
                                  </GridListTile>
                                ))}
                            </GridList>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={1} container direction="column" justify="space-around" alignItems="center">
                        <Grid item>
                          <Button onClick={this.onAddAllPoints}>
                            <i title='Deletar todos' className='material-icons'>fast_forward</i>
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button onClick={this.onRemoveAllPoints}>
                            <i title='Deletar todos' className='material-icons'>fast_rewind</i>
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item md={4}>
                        <Grid container className='selected-point-container'>
                          <Grid item xs={12} className='selected-point-title'>
                            PONTOS CADASTRADOS
                          </Grid>
                          <Grid item xs={12} className='selected-point-search'>
                            <Grid container alignItems='center' className='selected-point-title'>
                              <Grid item xs={2} lg={3}>
                                <span 
                                  className={(this.state.selectedFarms.length >= this.props.registrationLimit) 
                                  ? 'counter max-selected' 
                                  : 'counter'}>
                                    ({this.state.selectedFarms.length} / {this.props.registrationLimit})
                                </span>
                              </Grid>
                              <Grid item xs={8} lg={7}>
                                <Input
                                  name='searchSelected'
                                  disableUnderline={true} 
                                  onChange={this.onInputChange}
                                  placeholder='BUSCAR'
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <GridList className='selected-point-list' cellHeight={35} cols={1}>
                              {fileteredSelected.map(farm => (
                                <GridListTile key={farm.id}>
                                  <Button 
                                    className={(this.checkIfAuthorized(farm) === true) ? 'authorized selected-point-button' : 'selected-point-button'}
                                    onClick={() => this.onChangeActiveFarm(farm)}>
                                    {farm.name === '' ? farm.initials : farm.name + ' - ' + farm.initials} &nbsp; <span>
                                      <i 
                                        className='material-icons'
                                        style={{fontSize: '11px'}}>
                                        clear</i>
                                    </span>
                                  </Button>
                                </GridListTile>
                              ))}
                            </GridList>
                          </Grid>
                       </Grid>
                  </Grid>
                    </Fragment>
                  :
                    null
                  }  
                </Grid>
            </Grid>
            {this.state.activeProduct !== 0 ?
              <Grid item xs={12}>
                <Grid container alignItems='center' className='maximum-points-container' >
                  <Grid item>
                    Pontos máximos disponíveis:
                  </Grid>
                  <Grid item>
                    (<Input className='limit-input' type='number' name='currentMax' disableUnderline={true} value={this.state.currentMax} onChange={this.onInputChange}/>
                  </Grid>
                  <Grid item>
                    / {this.props.registrationLimit})
                  </Grid>
                  <Grid item>
                    <Button onClick={this.onMaxNumberChange}>
                      Salvar
                    </Button>
                  </Grid>
                </Grid>              
              </Grid>
            :
              null
            }
          </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
      authorizedPoints: state.admin.authorizedPoints,
      availablePoints: state.admin.availablePoints,
      registrationLimit: state.admin.registrationLimit,
      serverMessage: state.admin.serverMessage,
      serverStatus: state.admin.serverStatus
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
        getProductList: (data) => {
          dispatch(getProductList(data))
        },
        modifyProductAccess: (data) => {
          dispatch(modifyProductAccess(data))
        },
        modifyPointLimit: (data) => {
          dispatch(modifyPointLimit(data))
        },
        resetUpload: () => {
          dispatch(resetUpload())
        },
        resetServerMessage: () => {
          dispatch(resetServerMessage())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductManagement)