import React from 'react';
import * as S from './styles/AutomaticAuditFooter'

function Development() {
  return (
    <>
      <S.AutoAuditP sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '60px',
          fontSize: 60,
      }}>
        <S.AutoAuditIconArrow>back_hand</S.AutoAuditIconArrow>
        Pagina em desenvolvimento
        <S.AutoAuditIconArrow>engineering</S.AutoAuditIconArrow>
      </S.AutoAuditP>
      
    </>
  );
}

export default Development;
