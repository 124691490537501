import React, { Fragment } from 'react'

import { toast } from 'react-toastify';
import { css } from 'glamor';

export function showToaster(params) {
  const { title, message, buttons, autoClose, hideProgressBar, onClose, customHeight } = params;
  let body = Array.isArray(message) ? message : [message];
  
  const config = {
    autoClose: autoClose,
    type: toast.TYPE.INFO,
    hideProgressBar: hideProgressBar,
    onClose: onClose || null,
    closeButton: false,
    className: css({
      backgroundColor: '#FFFDF8',
      boxShadow: '0px 3px 6px #0000000A',
      borderLeft: '6px solid #33C94C',
      height: customHeight ? customHeight + "px" : '150px',
    }),
    bodyClassName: css({
      height: customHeight ? customHeight + "px" : '150px',
      backgroundColor: '#FFF',
      '& .row':{
          marginBottom: '15px',
      },
      '& .modal-label-1':{
          fontSize: '16px',
          fontWeight: 700,
          color: '#33C94C',
      },
      '& .modal-label-2':{
          fontSize: '14px',
          color: '#33C94C',
      },
      '& .btn-modal': {
          padding: '0 1rem',
          fontWeight: 500,
          fontSize: '12px',
          color: '#3E2723',
          marginRight: '8px',
          left: '30%',
      },
      '& .btn-modal-delete':{
          padding: '0 1rem',
          fontWeight: 500,
          fontSize: '12px',
          color: '#FFFFFF',
          marginRight: '8px',
          backgroundColor: '#FF0000',
      },
      '& .btn-modal-delete:hover':{
          backgroundColor: '#FF0000 !important',
      }
    }),
    progressClassName: css({
      backgroundColor: '#33C94C',
    })
  };

  return toast(
    <Fragment>
      <div className='row'>
        <div className='modal-labels'>
          <label className='modal-label-1'>{title}</label>
        </div>
      </div>
      <div className='row'>
        {body.map((row) => (
          <Fragment>
            <label className='modal-label-2'>{row}</label>
            <br/>
          </Fragment>
        ))}
      </div>
      <div>
        {buttons && (
          buttons.map(({ style, func, data, name }) => (
              <button 
                className={'btn-flat btn-modal' + (style ? '-' + style : '')} 
                onClick={func}
                data-object={data}
              >
                {name}
              </button>
            )
          )
        )}
      </div>
    </Fragment>,
    config
  );
}  