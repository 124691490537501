import {TOGGLE_SIDEBAR,
        TOGGLE_MORE_FARMS,
        SET_CAD_ERROR,
        TOGGLE_LOADER,
        TOGGLE_LEFT_LOADER,
        GENERATING_MAP,
        TOGGLE_SIDEBAR_LOADER,
        FIX_USER_FLOW,
        TOGGLE_ATR_HELP,
        TOGGLE_CORRECT_TYPES_FLAG,
        TOGGLE_TOOLTIPS,
        TOGGLE_PROFILE_BAR,
        TOGGLE_SCHEDULE,
        TOGGLE_KMZ,
        SET_LISTED_POINT,
        CHANGE_VISUALIZATION_YEAR,
        CHANGE_FILTER_PARAMS,
} from '../actions/global'

const initialState = {
  sidebarActive: false,
  profileActive: false,
  scheduleActive: false,
  kmzActive: false,
  listedPoint: -1, 
  moreFarmsActive: false,
  cadError: false,
  loader: false,
  leftLoader: false ,
  sidebarLoader: false,
  generatingMapData: false,
  userFix: false,
  atrHelp: false,
  correctTypesFlag: false,
  showTooltips: true,
  visualizationType: "civil",
  filterParams: {
    fromPeriod: '01/01',
    toPeriod: '31/12',
    fromIndex: 1,
    toIndex: 366,
    startDate: '01/01',
    endDate: '31/12',
    startYear: '1980',
    endYear: `${new Date().getFullYear()}`,
    startYearAgricultural: '80-81',
    endYearAgricultural: new Date().getMonth() >= 6
      ? `${new Date().getFullYear().toString().slice(2)}-${(new Date().getFullYear() + 1).toString().slice(2)}`
      : `${(new Date().getFullYear() - 1).toString().slice(2)}-${new Date().getFullYear().toString().slice(2)}`
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, {sidebarActive: !state.sidebarActive})
    case TOGGLE_PROFILE_BAR:
      return Object.assign({}, state, {profileActive: !state.profileActive})
    case TOGGLE_SCHEDULE:
      return Object.assign({}, state, {scheduleActive: !state.scheduleActive})
    case TOGGLE_KMZ:
      return Object.assign({}, state, {kmzActive: !state.kmzActive, scheduleActive: false});
    case TOGGLE_MORE_FARMS:
      return Object.assign({}, state, {moreFarmsActive: !state.moreFarmsActive})
    case SET_CAD_ERROR:
      return Object.assign({}, state, {cadError: !state.cadError})
    case TOGGLE_LOADER:
      return Object.assign({}, state, {loader: !state.loader})
    case TOGGLE_LEFT_LOADER:
      return Object.assign({}, state, {leftLoader: !state.leftLoader})
    case GENERATING_MAP:
      return Object.assign({}, state, {generatingMapData: !state.generatingMapData})
    case TOGGLE_SIDEBAR_LOADER:
      return Object.assign({}, state, {sidebarLoader: !state.sidebarLoader})
    case FIX_USER_FLOW:
      return Object.assign({}, state, {userFix: !state.userFix})
    case TOGGLE_ATR_HELP:
      return Object.assign({},state, {atrHelp: !state.atrHelp})
    case TOGGLE_CORRECT_TYPES_FLAG:
      return Object.assign({}, state, {correctTypesFlag: !state.correctTypesFlag})
    case TOGGLE_TOOLTIPS:
      return Object.assign({}, state, {showTooltips: !state.showTooltips})
    case SET_LISTED_POINT:
      return Object.assign({}, state, {listedPoint: action.point})
    case CHANGE_VISUALIZATION_YEAR:
      return Object.assign({}, state, {visualizationType: action.visualization})
    case CHANGE_FILTER_PARAMS:
      return Object.assign({}, state, {filterParams: action.filterParams})
    default:
        return state
  }
}
