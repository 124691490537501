import React from 'react';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

function EmailInput({ classes, name, value, handleChange, addEmailField }) {
  return (
    <Grid
      className={ classes.inputContainer }
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={ 9 }>
        <input
          type="email"
          style={ {
            border: '1px solid #6AA426',
            borderRadius: '8px',
            padding: '0 20px',
            width: '80%',
            height: '34px',
            margin: 0,
          } }
          name={ name }
          value={ value }
          onChange={ (e) => handleChange(e) }
        />
      </Grid>
      {
        name.includes('1') && (
          <Grid item xs={ 3 }>
            <Fab size="small" className={ classes.addIcon } onClick={ addEmailField }>
              <AddIcon/>
            </Fab>
          </Grid>
        )
      }
    </Grid>
  );
}

export default EmailInput;
