import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import validateInput from '../shared/validators/specificProdValidator';
import DownloadXLS from '../shared/utils/DownloadXLS';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import SpecificProductivityBoxplot from '../components/reports/productivity/SpecificProductivityBoxplot';
import ObservedSpecificProductivityChart from '../components/reports/productivity/ObservedSpecificProductivityChart';
import SpecificProductivityOptions from '../components/reports/productivity/SpecificProductivityOptions';
import EfficiencyBarChart from '../components/reports/productivity/EfficiencyBarChart'
import DropdownField from '../components/common/DropdownField';
import { PRODUCTIVITY, CANE, SOY, CORN, COTTON, WHEAT } from "../components/reports/productivity/common/defaultValues"

import IconButton from '@material-ui/core/IconButton';

import { generateSpecificProductivity, generateSoyProductivity, generateCornProductivity, generateCottonProductivity, generateWheatProductivity, setProductivityUndefined, setChangedCadFlag } from '../actions/productivity';
import { toggleReportContainer, setReportTypes } from '../actions/reports';
import { toggleTooltips } from '../actions/global';
import { activePointChange } from '../shared/utils/ProductivityAccess'

const INITIAL_SPECIFIC_STATE = {
  cane_type: CANE.TYPE,
  eficiency: PRODUCTIVITY.EFFICIENCY,
  kdec: CANE.KDEC.toString(),
  obs_value: '',
  cycle: CANE.CYCLE,
  date: null,
  cc_user: CANE.CC,
  u_percent_user: CANE.U_PERCENT,
  cut_number: CANE.CUT_NUMBER,
  frost: PRODUCTIVITY.FROST
}

const INITIAL_SOY_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  obs_value: '',
  cycle: SOY.CYCLE,
  date: null,
  cc_user: SOY.CC,
  u_percent_user: SOY.U_PERCENT,
  frost: PRODUCTIVITY.FROST
}

const INITIAL_CORN_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  obs_value: '',
  cycle: CORN.CYCLE,
  date: null,
  cc_user: CORN.CC,
  u_percent_user: CORN.U_PERCENT,
  frost: PRODUCTIVITY.FROST
}

const INITIAL_COTTON_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  obs_value: '',
  cycle: COTTON.CYCLE,
  date: null,
  cc_user: COTTON.CC,
  u_percent_user: COTTON.U_PERCENT,
  frost: PRODUCTIVITY.FROST
}

const INITIAL_WHEAT_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  obs_value: '',
  cycle: WHEAT.CYCLE,
  date: null,
  cc_user: WHEAT.CC,
  u_percent_user: WHEAT.U_PERCENT
}

class SpecificProductivityContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openOptions: true,
      specific: this.setInitialState(),
      selectedProd: 'PP',
      monthlyCropData: [],
      compareData: [],
      boxplotData: [],
      currentCrop: '',
      lastCropValues: [],
      lastCrop: '',
      selectedFarm: undefined,
      data: undefined,
      currentSeasonAverageData: undefined,
      lastSeasonAverageData: undefined,
      openReport: true,
      errors: {}
    };
  };

  //State initializer method
  setInitialState(){

    switch(this.props.reportType){
      case 'soy-productivity':
        return INITIAL_SOY_STATE
      case 'corn-spec-productivity':
        return INITIAL_CORN_STATE
      case 'cotton-spec-productivity':
        return INITIAL_COTTON_STATE
      case 'wheat-spec-productivity':
        return INITIAL_WHEAT_STATE
      case 'productivity-spec':
        return INITIAL_SPECIFIC_STATE
      default:
        return
    }
  }

  //#region Lifecycle methods

  componentDidMount(){
    const state = Object.assign({}, this.state);
    if (this.props.farms && this.props.activeFarm) {
      const selectedFarm = this.props.farms.find(farm => farm.id === this.props.activeFarm);
      state.selectedFarm = selectedFarm;
    }
    this.setState(state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const state = Object.assign({}, this.state);

    if (nextProps.productivity && nextProps.productivity.data) {
      state.data = nextProps.productivity.data;
      let key = Object.keys(state.data)[0]
      if(state.data[key]['pp_value'].length > 1){
        //boxplot
        let boxReturn = this.generateBoxplotData(nextProps.productivity.data)
        state.boxplotData = boxReturn[0]
        state.currentCrop = boxReturn[1]
        state.lastCropValues = boxReturn[2]
        state.lastCrop = boxReturn[3]
      }else{
        state.monthlyCropData = this.generateMonthlyCropData(nextProps.productivity.data)
        state.compareData = this.generateCompareData(nextProps.productivity.data)
      }
    }

    if (nextProps.farms && nextProps.activeFarm) {
      const selectedFarm = nextProps.farms.find(farm => farm.id === nextProps.activeFarm);
      if(this.state.selectedFarm && this.state.selectedFarm.id !== nextProps.activeFarm){
        state.specific = this.setInitialState()
        state.openOptions = true
        this.props.actions.setProductivityUndefined();
        if(!this.props.reportActive){
          this.props.actions.toggleReportContainer();
        }
      }
      state.selectedFarm = selectedFarm;
    }
    this.setState(state);
  }

  componentDidUpdate(prevProps, prevState){
    activePointChange(prevProps, this.props, this.props.reportType)
    let newState = {}
    if(this.state.data !== undefined && this.props.productivity.data === undefined){
      newState.data = undefined
      newState.compareData = []
      newState.monthlyCropData = []
      newState.boxplotData = []
      newState.openReport = true
      if(this.props.reportType !== prevProps.reportType){
        newState.openOptions = true
        newState.specific = this.setInitialState()
      }else if(this.props.changedCadFlag){
        newState.openOptions = true;
        this.props.actions.setChangedCadFlag(false)
      }
      this.setState(newState);
    }

    if(prevProps.reportActive && !this.props.reportActive){
      this.setState({openReport: false})
    }
  }
  //#endregion

  //#region Render methods
  recalculate = (e) => {
    let { errors, isValid } = validateInput(this.state.specific, this.props.reportType)
    if(!isValid){
      this.setState({errors: errors})
      return
    }

    let cane_type = undefined
    let cut_number = undefined
    let kdec = undefined
    let crop_year = undefined

    const coords = this.state.selectedFarm.coords.split(',');
    const agriculturalEficiency = this.state.specific.eficiency !== '' ? parseFloat(this.state.specific.eficiency) / 100 : '';
    const cycle = this.state.specific.cycle
    const date = moment(this.state.specific.date).format('YYYY-MM-DD')
    const cc_user = this.state.specific.cc_user
    const u_percent_user = this.state.specific.u_percent_user
    const frost = this.state.specific.frost
    const point_id = this.props.activeFarm

    if(this.props.reportType === 'productivity-spec'){
      cane_type = this.state.specific.cut_number > 1 ? 'rest' : 'plant'
      cut_number = this.state.specific.cut_number
      kdec = parseFloat(this.state.specific.kdec.replace(',', '.'))
      crop_year = new Date().getFullYear()

      if(isNaN(kdec)) {
        kdec = undefined
      }
    }

    let data = {}
    if(this.props.reportType === 'productivity-spec'){
      data = {
        cad: this.state.selectedFarm.cad,
        lat: coords[0],
        lon: coords[1],
        agriculturalEficiency,
        cane_type,
        cycle,
        date,
        cut_number,
        cc_user,
        u_percent_user,
        kdec,
        crop_year,
        frost,
        point_id
      };
    }else if(this.props.reportType === 'corn-spec-productivity'){
      data = {
        cad: this.state.selectedFarm.cad,
        lat: coords[0],
        lon: coords[1],
        agriculturalEficiency,
        cycle,
        date,
        cc_user,
        u_percent_user,
        frost,
        point_id,
      }
    }else{
      data = {
        cad: this.state.selectedFarm.cad,
        lat: coords[0],
        lon: coords[1],
        agriculturalEficiency,
        cycle,
        date,
        cc_user,
        u_percent_user,
        frost,
        point_id
      }
    }

    if(this.props.reportType === 'productivity-spec'){
      this.setState({openOptions: false}, () => {
        this.props.actions.setProductivityUndefined();
        this.props.actions.generateSpecificProductivity(data);
      })

    }else{
      this.setState({openOptions: false}, () => {
        this.props.actions.setProductivityUndefined();
        switch(this.props.reportType){
          case 'soy-productivity':
            this.props.actions.generateSoyProductivity(data,'specific')
            break
          case 'corn-spec-productivity':
            this.props.actions.generateCornProductivity(data,'specific')
            break
          case 'cotton-spec-productivity':
            this.props.actions.generateCottonProductivity(data,'specific')
            break
          case 'wheat-spec-productivity':
            this.props.actions.generateWheatProductivity(data, 'specific')
            break
          default:
            return
        }
      })
    }
  }

  generateMonthlyCropData = (data) => {
    let key = Object.keys(data)[0]

    let pp = parseFloat(data[key]['pp_value'][0].toFixed(2));
    let ap = parseFloat(data[key]['ap_value'][0].toFixed(2));
    let rpEstimate = parseFloat(data[key]['rp_value'][0].toFixed(2));
    let rpObserved = this.state.specific.obs_value !== '' ? parseFloat(this.state.specific.obs_value) : undefined;

    if(rpObserved){
      return [
        {name: 'PP', pp},
        {name: 'PA', ap},
        {name: 'PR_Est', rpEstimate},
        {name: 'PR_Obs', rpObserved},
      ]
    }else{
      return [
        {name: 'PP', pp},
        {name: 'PA', ap},
        {name: 'PR_Est', rpEstimate},
      ]
    }
  }

  generateCompareData = (data) => {
    let key = Object.keys(data)[0]

    let ygdh = parseFloat((parseFloat(data[key]['pp_value'][0]) - parseFloat(data[key]['ap_value'][0])).toFixed(2));
    let ygmanEstimate = parseFloat((parseFloat(data[key]['ap_value'][0]) - parseFloat(data[key]['rp_value'][0])).toFixed(2));
    let ygmanObserved = this.state.specific.obs_value !== '' ? parseFloat((parseFloat(data[key]['ap_value'][0]) - parseFloat(this.state.specific.obs_value)).toFixed(2)) : undefined;

    if(ygmanObserved){
      return [
        {name: 'YGdh', ygdh},
        {name: 'YGman_Est', ygmanEstimate},
        {name: 'YGman_Obs', ygmanObserved},
      ]
    }else{
      return [
        {name: 'YGdh', ygdh},
        {name: 'YGman_Est', ygmanEstimate},
      ]
    }
  }

  generateBoxplotData = (data) => {
    let key = Object.keys(data)[0]
    let valuesCurrent = []
    let valuesPast = []
    let insideKeys = Object.keys(data[key])

    insideKeys.forEach(insideKey => {
      let auxValues = []
      if(['pp_value', 'ap_value', 'rp_value'].includes(insideKey)){
        data[key][insideKey].forEach(value => {
          auxValues.push(parseFloat(parseFloat(value).toFixed(2)))
        })
        valuesCurrent.push(auxValues)
      }
    })

    valuesPast.push(parseFloat(parseFloat(data[key]['year'][0]['pp_value'][0]).toFixed(2)))
    valuesPast.push(parseFloat(parseFloat(data[key]['year'][0]['ap_value'][0]).toFixed(2)))
    valuesPast.push(parseFloat(parseFloat(data[key]['year'][0]['rp_value'][0]).toFixed(2)))

    return [valuesCurrent, data[key]['crop'], valuesPast, data[key]['year'][0]['crop']]
  }

  toggleActive = () => {
    this.setState({openReport: !this.state.openReport})
  }

  handleOptionsModal = () => {
    this.setState({openOptions: !this.state.openOptions})
  }

  onChangeProd = (event) => { this.setState({selectedProd: event.target.value}) }

  handleChange = (event) => {
    if(event.target.name === "frost") {
      this.setState({specific: {...this.state.specific, [event.target.name]: !this.state.specific.frost }})
    }
    else {
      this.setState({specific: {...this.state.specific, [event.target.name]: event.target.value}})
    }
  }

  handleDateChange = (event) => {
    this.setState({specific: {...this.state.specific, date: event, obs_value: ""}})
  }

  handleCloseOptionsModal = () => {
    this.setState({openOptions: false})
    if(!this.state.data){
      window.location.reload()
    }
  }

  setTitles = () => {
    const { parameters, productivity } = this.props;
    let titleLine1 = "";
    let titleLine2 = "";
    if(this.state.selectedFarm && this.state.data){
      const farmName = this.state.selectedFarm.name !== "" ? this.state.selectedFarm.name : `Ponto ${this.state.selectedFarm.initials}`;
      const eficiency = parameters.agriculturalEficiency < 1 ? Math.round(parameters.agriculturalEficiency * 100) : parameters.agriculturalEficiency;
      const month = productivity.data !== undefined ? Object.values(productivity.data) : [{}];
      if(this.props.reportType === 'productivity-spec'){
        titleLine1 = `${farmName} | CAD = ${parameters.cad} mm | Tipo de cana = ${parameters.cane_type === 'plant' ? 'Planta' : 'Soca'} | E.A. = ${eficiency}% | Ciclo = ${parameters.cycle} meses`
        titleLine2 = `${parameters.cane_type === 'plant' ? 'Plantio' : 'Rebrota'} = ${parameters.date} | CC = ${parameters.cc_user} | U = ${parameters.u_percent_user} % | Kdec = ${parameters.kdec ? parameters.kdec : '0.22'} | Número do corte = ${parameters.cut_number}`;
      }else{
        titleLine1 = `Safra = ${month[0].crop}`
        titleLine2 = `${farmName} | CAD = ${parameters.cad} mm | E.A. = ${eficiency}% | Ciclo = ${parameters.cycle} dias | CC = ${parameters.cc_user} | U = ${parameters.u_percent_user} %`
      }
    }

    return [titleLine1, titleLine2]
  }

  handlePDFClick = (e) => {
    e.preventDefault()
    const input = document.getElementById('capture-pdf');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0, 200, 120);
      pdf.save("download.pdf");
    })
  }
  //#endregion

  render() {
    let title = this.setTitles()
    let unit
    switch(this.props.reportType){
      case "productivity-spec":
        unit = "t/ha"
        break
      case "cotton-spec-productivity":
        unit = "@/ha"
        break
      default:
        unit = "sacas/ha"
    }

    let cenaries = []
    if(this.state.boxplotData.length > 0){
      let position = this.state.selectedProd === 'PP' ? 0 : this.state.selectedProd === 'PA' ? 1 : 2
      cenaries = [
        {name: 'Muito Desfavorável', md: this.state.boxplotData[position][0], last: this.state.lastCropValues[position]},
        {name: 'Desfavorável', d:this.state.boxplotData[position][1], last: this.state.lastCropValues[position]},
        {name: 'Mediano', me: this.state.boxplotData[position][2], last: this.state.lastCropValues[position]},
        {name: 'Favorável', fa: this.state.boxplotData[position][3], last: this.state.lastCropValues[position]},
        {name: 'Muito Favorável', mf: this.state.boxplotData[position][4], last: this.state.lastCropValues[position]},
        {name: this.state.lastCrop, lastCrop: this.state.lastCropValues[position]}
      ]
    }

    let csvHeaders = undefined
    let csvData = []
    let efficiency = []
    if(this.state.monthlyCropData.length > 0){
      csvData = [
        ['PP', this.state.monthlyCropData[0].pp],
        ['PA', this.state.monthlyCropData[1].ap],
        ['PR_Est', this.state.monthlyCropData[2].rpEstimate],
        ['PR_Obs', this.state.monthlyCropData[3] ? this.state.monthlyCropData[3].rpObserved: ''],
        ['YGdh', this.state.compareData[0].ygdh],
        ['YGman_Est', this.state.compareData[1].ygmanEstimate],
        ['YGman_Obs', this.state.compareData[2] ? this.state.compareData[2].ygmanObserved : '']
      ]

      efficiency = [
        {name: "Eficiência Climática", ce: (this.state.monthlyCropData[1].ap / this.state.monthlyCropData[0].pp) * 100},
        {name: "Eficiência Agrícola", ae: this.props.parameters.agriculturalEficiency * 100}
      ]
      if(this.state.monthlyCropData[3]) {
        efficiency.push({name: "Eficiência Agrícola Real", rae: (this.state.monthlyCropData[3].rpObserved / this.state.monthlyCropData[1].ap) * 100})
      }
    }else if(this.state.boxplotData.length > 0){
      csvData = [
        ['PP_Cen1', this.state.boxplotData[0][0]],
        ['PP_Cen2', this.state.boxplotData[0][1]],
        ['PP_Cen3', this.state.boxplotData[0][2]],
        ['PP_Cen4', this.state.boxplotData[0][3]],
        ['PP_Cen5', this.state.boxplotData[0][4]],
        [`PP_${this.state.lastCrop}`, this.state.lastCropValues[0]],
        ['', ''],
        ['PA_Cen1', this.state.boxplotData[1][0]],
        ['PA_Cen2', this.state.boxplotData[1][1]],
        ['PA_Cen3', this.state.boxplotData[1][2]],
        ['PA_Cen4', this.state.boxplotData[1][3]],
        ['PA_Cen5', this.state.boxplotData[1][4]],
        [`PA_${this.state.lastCrop}`, this.state.lastCropValues[1]],
        ['',''],
        ['PR_Cen1', this.state.boxplotData[2][0]],
        ['PR_Cen2', this.state.boxplotData[2][1]],
        ['PR_Cen3', this.state.boxplotData[2][2]],
        ['PR_Cen4', this.state.boxplotData[2][3]],
        ['PR_Cen5', this.state.boxplotData[2][4]],
        [`PR_${this.state.lastCrop}`, this.state.lastCropValues[2]],
      ]
    }

    return (
      <Fragment>
        <section className={classnames("report--container visible productivity", {active: this.state.openReport})} style={{zIndex: "8"}}>
          <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.state.openReport})} onClick={ this.toggleActive }><i className='material-icons'>play_arrow</i></span>
          <header className='row bhbox-header productivity-header' style={{padding: '6px 8px'}}>
            <div className="prod-options-container" style={{width: '100%'}}>
              {this.state.data &&
                <Fragment>
                  {this.props.reportType === 'productivity-spec' &&
                    <Fragment>
                      <DownloadXLS
                        marginRight={'32px'}
                        headers={csvHeaders}
                        data={csvData}
                      />
                      <IconButton disableRipple={true} aria-label="picture_as_pdf" onClick={this.handlePDFClick}>
                        <i className="material-icons">picture_as_pdf</i>
                      </IconButton>
                    </Fragment>
                  }
                  <IconButton disableRipple={true} aria-label="tooltips" onClick={this.props.actions.toggleTooltips} title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'}>
                    <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                  </IconButton>
                  <IconButton disableRipple={true} aria-label="settings" onClick={this.handleOptionsModal}>
                    <i className="material-icons">settings</i>
                  </IconButton>
                </Fragment>
              }
            </div>
          </header>
          <PerfectScrollbar className={classnames('productivity-scroll ps ps--theme default')}>
            {this.state.data && this.props.farms && this.props.activeFarm ?
              <div id='capture-pdf' className="general-graph" style={{textAlign: 'center', height: '90%'}}>
                {this.props.reportType === 'productivity-spec' &&
                  <Fragment>
                    <h6>{title[0]}</h6>
                    <h6>{title[1]}</h6>
                  </Fragment>
                }
                {(['soy-productivity', 'corn-spec-productivity', 'cotton-spec-productivity', 'wheat-spec-productivity'].includes(this.props.reportType)) &&
                  <div style={{marginBottom: '32px'}}>
                    <h5>{title[0]}</h5>
                    <h5>{title[1]}</h5>
                  </div>
                }
                <Grid container>
                  <Grid item xs={6}>
                    {this.state.monthlyCropData.length > 0 ?
                      <ObservedSpecificProductivityChart
                        className='left-chart'
                        type={'brute'}
                        data={this.state.monthlyCropData}
                        unit={unit}
                        unitText={`Produtividade ${unit}`}
                        showTooltips={this.props.showTooltips}
                      />
                      :
                        null
                      }
                    {this.state.boxplotData.length > 0 ?

                      <SpecificProductivityBoxplot
                        className='left-chart'
                        title={''}
                        keys={['PP', 'PA', 'PRe']}
                        unitText={`Produtividade ${unit}`}
                        unit={unit}
                        currentCrop={this.state.currentCrop}
                        currentCropBoxplotValues={this.state.boxplotData}
                        pastCropName1={this.state.lastCrop}
                        pastCropValues1={this.state.lastCropValues}
                        showTooltips={this.props.showTooltips}
                      />
                      :
                      null
                    }
                  </Grid>
                  <Grid item className='right-chart' xs={6}>
                    {this.state.compareData.length > 0 ?
                      <ObservedSpecificProductivityChart
                        type={'compare'}
                        data={this.state.compareData}
                        unit={unit}
                        unitText={`Produtividade ${unit}`}
                        showTooltips={this.props.showTooltips}
                      />
                    :
                      null
                    }
                    {this.state.boxplotData.length > 0 ?
                      <React.Fragment>
                        <div className="subtitle">
                          <DropdownField
                            value={this.state.selectedProd}
                            options={['PP', 'PA', 'PRe']}
                            onChange={this.onChangeProd}
                            type={'prod'}
                          />
                        </div>
                        <ObservedSpecificProductivityChart
                          type={'compareBox'}
                          data={cenaries} unit={unit}
                          unitText={`Produtividade ${unit}`}
                          showTooltips={this.props.showTooltips}
                        />
                      </React.Fragment>
                      :
                      null
                    }
                  </Grid>
                  {this.state.monthlyCropData.length > 0 &&
                    <Grid item container justify="center">
                      <Grid item xs={12} lg={6}>
                        <EfficiencyBarChart
                          data={efficiency}
                          showTooltips={this.props.showTooltips}
                        />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </div>
              :
              <div className='loading-screen-prod'>
                Calculando...
              </div>
            }

          </PerfectScrollbar>
        </section>
        <SpecificProductivityOptions
          handleDateChange={this.handleDateChange}
          reportType={this.props.reportType}
          noClose={this.state.data ? false : true}
          recalculate={this.recalculate}
          open={this.state.openOptions}
          productivity={this.state.specific}
          handleChange={this.handleChange}
          handleClose={this.handleCloseOptionsModal}
          errors={this.state.errors}
        />
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms.points.farms,
    activeFarm: state.farms.activeFarm,
    productivity: state.productivity,
    parameters: state.productivity.specific,
    reportActive: state.Reports.reportActive,
    reportType: state.Reports.reportTypes,
    changedCadFlag: state.productivity.changedCadFlag,
    showTooltips: state.global.showTooltips
  }
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {
      generateSpecificProductivity,
      setProductivityUndefined,
      toggleReportContainer,
      setReportTypes,
      generateSoyProductivity,
      generateCornProductivity,
      generateCottonProductivity,
      generateWheatProductivity,
      setChangedCadFlag,
      toggleTooltips,
    }), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecificProductivityContainer);
