import React from 'react';

import IndexLabels from './IndexLabels';

const IndexFooter = (props) => {
  const growthLabels = [
    {name: 'Muito Favorável', level: 0},
    {name: 'Favorável', level: 1},
    {name: 'Razoável', level: 2},
    {name: 'Desfavorável', level: 3},
    {name: 'Ctítico', level: 4},
    {name: 'Muito Crítico', level: 5},
    {name: 'Severo', level: 6},
    {name: 'Muito Severo', level: 7},
    {name: 'Previsão', level: 8},
  ];

  const plantFertTrafLabels = [
    {name: 'Desfavorável', level: 9},
    {name: 'Razoável', level: 10},
    {name: 'Favorável', level: 11},
    {name: 'Previsão', level: 8},
  ];

  const herbLabels = [
    {name: 'Época Seca', level: 12},
    {name: 'Época Semi-Seca', level: 13},
    {name: 'Época Semi-Umida', level: 15},
    {name: 'Época Umida', level: 16},
    {name: 'Previsão', level: 8},
  ];

  const soilLabels = [
    {name: 'Alto Risco de Desestruturação', level: 12},
    {name: 'Médio Risco de Desestruturação', level: 13},
    {name: 'Favorável', level: 14},
    {name: 'Médio Risco de Compactação', level: 15},
    {name: 'Alto Risco de Compactação', level: 16},
    {name: 'Previsão', level: 8},
  ];

  const pulvLabels = [
    {name: 'Desfavorável', level: 9},
    {name: 'Favorável', level: 11},
    {name: 'Previsão', level: 8},
  ];

  if(props.reportTypes[0] === 'cresc'){
    return <IndexLabels labels={growthLabels} />;
  }else if(['plan', 'fert', 'traf'].includes(props.reportTypes[0])){
    return <IndexLabels labels={plantFertTrafLabels} />
  }else if(props.reportTypes[0] === 'herb'){
    return <IndexLabels labels={herbLabels} />;
  }else if(props.reportTypes[0] === 'ps'){
    return <IndexLabels labels={soilLabels} />;
  }else if(props.reportTypes[0] === 'pulv'){
    return(
      <footer className='indicators-labels index-indicators-labels'>
        {
          pulvLabels.map(({name, level}) => (
            <div key={name} className={`indicators-labels--item level-${level}`}>
              <div className='label-color'></div>
              <div className='label-text'>
                <p>{name}</p>
              </div>
            </div>
          ))
        }
      </footer>
    );
  }
};

export default IndexFooter;
