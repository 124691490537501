import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'

export default function validateInput(data) {
  let errors = {}

  if(!Validator.isLength(data.password, {min:6, max: 50})) {
    errors.password = 'A senha deve conter no mínimo 6 caracteres'
  }

  if(!Validator.equals(data.password,data.passwordConfirm)){
    errors.passwordConfirm = 'As senhas estão diferentes'
  }

  return {
    errors,
    isValid: isEmpty(errors)
  }
}
