import React, { Component } from "react";
import { cloneDeep } from "lodash";
import validateInput from "../../shared/validators/login";
import { connect } from "react-redux";
import UserData from "./UserData";
import CultureData from "./CultureData";
import UserPlan from "./UserPlan";
import Loader from "../common/Loader";

import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import Steps, { Step } from "rc-steps";

//actions
import { getCultures } from "../../actions/users";
import UserPoints from "./UserPoints";
import { SmartPremiumOptions } from "./SmartPremiumOptions";

class SignInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      userType: "",
      userRole: "",
      userPlan: "",
      cultures: [],
      otherCultures: "",
      otherCultures2: "",
      errors: {},
      showFirstTextField: false,
      showSecondTextField: false,
      points: [],
      smartSelected: [],
    };
  }

  //Lifecycle methods
  componentDidMount() {
    this.props.getCultures();
  }

  //Helper methods
  isValid() {
    const { errors, isValid } = validateInput(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  //#region Render methods
  changeUserType = (type) => {
    this.setState({
      userType: type.target.value,
      userRole: type.target.id.split("_")[1],
      stage: 2,
    });
  };

  changeUserPlan = (event) => {
    this.setState(
      {
        userPlan: event.target.value,
      },
      () => {
        let newStage =
          this.state.userPlan === "basic"
            ? 2
            : this.state.userPlan === "premium"
            ? 4
            : 3;
        this.setState({ stage: newStage });
      }
    );
  };

  backClick = () => {
    this.props.setErrorsToNull();
    if (this.state.userPlan === "premium" && this.state.stage === 4) {
      this.setState({ stage: this.state.stage - 3 });
    } else if (this.state.userPlan === "smart premium" && this.state.stage === 3) {
      this.setState({ stage: this.state.stage - 2 });
    } else if (this.state.userPlan === "basic" && this.state.stage === 4) {
      this.setState({ stage: this.state.stage - 2 });
    } else {
      this.setState({ stage: this.state.stage - 1 });
    }
  };

  nextClick = () => {
    this.props.setErrorsToNull();
    this.setState({
      stage:
        this.state.userPlan === "basic" && this.state.stage === 2
          ? this.state.stage + 2
          : this.state.stage + 1,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onTextBlur = () => {
    let otherCultures = undefined;
    let cultures = undefined;
    if (this.state.stage === 3) {
      otherCultures = this.state.otherCultures
        .split(",")
        .filter((culture) => culture !== "")
        .map((culture) =>
          this.state.otherCultures.split(",").indexOf(culture)
        );
    } else {
      otherCultures = this.state.otherCultures2
        .split(",")
        .filter((culture) => culture !== "");
    }
    cultures = this.state.cultures.slice();
    cultures = cultures.concat(otherCultures);
    this.setState({
      cultures: cultures,
    });
  };

  onCheckboxChange = (e) => {
    if (e.target.id === "othersYearly") {
      this.setState({
        showFirstTextField: !this.state.showFirstTextField,
      });
    } else if (e.target.id === "othersPerennial") {
      this.setState({
        showSecondTextField: !this.state.showSecondTextField,
      });
    } else {
      let selectedCultures = this.state.cultures.slice();
      if (this.state.cultures.indexOf(e.target.id) !== -1) {
        let index = this.state.cultures.indexOf(e.target.id);
        selectedCultures.splice(index, 1);
      } else {
        selectedCultures.push(e.target.id);
      }
      this.setState({
        cultures: selectedCultures,
      });
    }
  };

  addPoint = (point) => {
    this.setState({ points: [...this.state.points, point] });
  };

  deletePoint = (index) => {
    let temp = cloneDeep(this.state.points);
    temp.splice(index, 1);
    this.setState({ points: temp });
  };

  addSelectedSmart = (smart) => {
    const { smartSelected } = this.state;
    if (smartSelected.includes(smart)) {
      const auxSmartSelected = smartSelected.filter(
        (option) => option !== smart
      );
      this.setState({
        smartSelected: auxSmartSelected,
      });
      return;
    }
    this.setState({
      smartSelected: [smart, ...smartSelected],
    });
  };
  //#endregion

  render() {
    return (
      <div className="signin-modal-parent">
        <Loader active={this.props.loader} />
        <Steps labelPlacement="vertical" current={this.state.stage - 1}>
          <Step title="Tipo de Plano" />
          <Step title="Pontos do Usuário" />
          <Step title="Smart liberados" />
          <Step title="Culturas: Anuais" />
          <Step title="Culturas: Perenes" />
          <Step title="Dados Pessoais" />
        </Steps>
        {this.props.errors ? (
          <div className="row errors">{this.props.errors}</div>
        ) : null}
        {this.state.stage === 1 ? (
          <UserPlan
            toggleSignIn={this.props.toggleSignIn}
            userPlan={this.state.userPlan}
            changeUserPlan={this.changeUserPlan}
            backClick={this.backClick}
          />
        ) : this.state.stage === 2 ? (
          <UserPoints
            points={this.state.points}
            addPoint={this.addPoint}
            deletePoint={this.deletePoint}
            backClick={this.backClick}
            nextClick={this.nextClick}
          />
        ) : this.state.stage === 3 ? (
          <SmartPremiumOptions
            smartSelected={this.state.smartSelected}
            addSelectedSmart={this.addSelectedSmart}
            backClick={this.backClick}
            nextClick={this.nextClick}
          />
        ) : this.state.stage === 4 ? (
          <CultureData
            onChange={this.onCheckboxChange}
            onTextChange={this.onChange}
            onTextBlur={this.onTextBlur}
            otherCultures={this.state.otherCultures}
            errors={this.state.errors}
            selectedCultures={this.state.cultures}
            cultures={this.props.cultures}
            backClick={this.backClick}
            nextClick={this.nextClick}
            showFirstTextField={this.state.showFirstTextField}
            showSecondTextField={this.state.showSecondTextField}
            stage={this.state.stage}
          />
        ) : this.state.stage === 5 ? (
          <CultureData
            onChange={this.onCheckboxChange}
            onTextChange={this.onChange}
            onTextBlur={this.onTextBlur}
            otherCultures2={this.state.otherCultures2}
            errors={this.state.errors}
            selectedCultures={this.state.cultures}
            cultures={this.props.cultures}
            backClick={this.backClick}
            nextClick={this.nextClick}
            showFirstTextField={this.state.showFirstTextField}
            showSecondTextField={this.state.showSecondTextField}
            stage={this.state.stage}
          />
        ) : (
          <UserData
            smartSelected={this.state.smartSelected}
            backClick={this.backClick}
            userType={this.state.userType}
            userRole={this.state.userRole}
            userPlan={this.state.userPlan}
            cultures={this.state.cultures}
            points={this.state.points}
            loader={this.props.loader}
            closeModal={this.props.toggleSignIn}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errors: state.users.errors,
    cultures: state.users.cultures,
    loader: state.global.loader,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCultures: () => {
      dispatch(getCultures());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInModal);
