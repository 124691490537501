import React, { useEffect } from "react";

const Boxplot = (props) => {

  useEffect(() => {
    function getYAxisMaxLimit() {
      let maxValues = [];
      for (let i = 0; i < props.currentCropBoxplotValues.length; i++) {
        maxValues.push(Math.max(...props.currentCropBoxplotValues[i]));
      }
      for (let i = 0; i < props.currentCropPointsValues.length; i++) {
        maxValues.push(Math.max(...props.currentCropPointsValues[i]));
      }

      if (props.productivityType !== "eucalyptus-general-productivity") {
        maxValues.push(Math.max(...props.pastCropValues1));
        maxValues.push(Math.max(...props.pastCropValues2));

        for (let i = 0; i < props.pastCropValues3.length; i++) {
          if (Array.isArray(props.pastCropValues3[i])) {
            maxValues.push(Math.max(...props.pastCropValues3[i]));
          }
        }
      }
      return Math.max(...maxValues);
    }

    function getYAxisMinLimit() {
      let minValues = [];
      for (let i = 0; i < props.currentCropBoxplotValues.length; i++) {
        const cropBoxplotValues = props.currentCropBoxplotValues[i]
          ? props.currentCropBoxplotValues[i].slice(1)
          : [];
        minValues.push(Math.min(...cropBoxplotValues));
      }
      for (let i = 0; i < props.currentCropPointsValues.length; i++) {
        const cropPointsValues = props.currentCropPointsValues[i]
          ? props.currentCropPointsValues[i].slice(1)
          : [];
        minValues.push(Math.min(...cropPointsValues));
      }

      return Math.min(...minValues) * 0.2;
    }

    let yAxisMaxTick;
    let yAxisMinTick;

    if (
      [
        "cotton-general-productivity",
        "corn-general-productivity",
        "eucalyptus-general-productivity",
        "soy-general-productivity"
      ].includes(props.productivityType)
    ) {
      yAxisMaxTick = getYAxisMaxLimit();
      yAxisMinTick = getYAxisMinLimit();
    } else {
      yAxisMaxTick = 150;
      yAxisMinTick = 0;
    }

    window.Highcharts.chart("boxplot", {
      chart: {
        type: "boxplot",
        style: {
          fontFamily: "Roboto, sans-serif",
        },
      },

      title: {
        text: props.title,
        style: {
          color: "#000000",
          fontSize: "1.4rem",
          fontWeight: "400",
        },
      },

      legend: {
        enabled: true,
      },

      credits: {
        enabled: false,
      },

      xAxis: {
        categories: props.keys,
        title: {
          text: `${
            props.productivityType === "eucalyptus-general-productivity"
              ? props.rangeFlag === "annually"
                ? "Ano"
                : "Mês"
              : "Mês"
          }`,
        },
        labels: {
          style: {
            fontSize: "15px",
          },
        },
      },

      yAxis: {
        title: {
          text: `Produtividade (${props.unit})`,
        },
        min: yAxisMinTick,
        max: yAxisMaxTick,
        tickAmount: 6,
      },

      tooltip: {
        enabled: props.showTooltips,
      },

      plotOptions: {
        boxplot: {
          color: "#000",
          fillColor: "#FF0000",
          lineWidth: 1,
          medianColor: "#000",
          medianWidth: 1,
          stemColor: "#000",
          stemWidth: 1,
          whiskerColor: "#000",
          whiskerLength: "20%",
          whiskerWidth: 1,
          maxPointWidth: 20,
        },
      },

      series: props.pastTchName
        ? [
            {
              name: props.currentCrop,
              data: props.currentCropBoxplotValues,
              showInLegend: false,
              tooltip: {
                pointFormat: `<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>Limite superior: {point.high} ${props.unit}<br/>Terceiro quartil: {point.q3} ${props.unit}<br/>Mediana: {point.median} ${props.unit}<br/>Primeiro quartil: {point.q1} ${props.unit}<br/>Limite inferior: {point.low} ${props.unit}<br/>`,
              },
            },
            {
              name: props.pastTchName,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              data: props.pastTchValues.map((tchMonthData, index)=> [index, Number(tchMonthData[1])]),
              marker: {
                fillColor: "white",
                lineWidth: 1,
                lineColor: "black",
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
            {
              name: props.currentCrop,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              data: props.currentCropPointsValues,
              marker: {
                fillColor: "red",
                lineWidth: 1,
                lineColor: window.Highcharts.getOptions().colors[0],
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
          ]
        : props.productivityType !== "eucalyptus-general-productivity"
        ? [
            {
              name: props.currentCrop,
              data: props.currentCropBoxplotValues,
              showInLegend: false,
              tooltip: {
                pointFormat: `<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>Limite superior: {point.high} ${props.unit}<br/>Terceiro quartil: {point.q3} ${props.unit}<br/>Mediana: {point.median} ${props.unit}<br/>Primeiro quartil: {point.q1} ${props.unit}<br/>Limite inferior: {point.low} ${props.unit}<br/>`,
              },
            },
            {
              name: props.pastCropName1,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              data: props.pastCropValues1,
              marker: {
                fillColor: "white",
                lineWidth: 2,
                lineColor: "black",
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
            {
              name: props.pastCropName2,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              data: props.pastCropValues2,
              marker: {
                fillColor: "white",
                lineWidth: 2,
                lineColor: "black",
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
            {
              name: props.pastCropName3,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              data: props.pastCropValues3,
              marker: {
                fillColor:
                  props.pastCropBoxplotValue.length > 1 ? "#2174B1" : "white",
                lineWidth: 2,
                lineColor: "black",
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
            {
              name: props.currentCrop,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              data: props.currentCropPointsValues,
              marker: {
                fillColor: "red",
                lineWidth: 1,
                lineColor: window.Highcharts.getOptions().colors[0],
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
          ]
        : [
            {
              name: props.currentCrop,
              data: props.currentCropBoxplotValues,
              showInLegend: false,
              tooltip: {
                pointFormat: `Limite superior: {point.high} ${props.unit}<br/>Terceiro quartil: {point.q3} ${props.unit}<br/>Mediana: {point.median} ${props.unit}<br/>Primeiro quartil: {point.q1} ${props.unit}<br/>Limite inferior: {point.low} ${props.unit}<br/>`,
              },
            },
            {
              name: props.currentCrop,
              data: props.currentCropPointsValues,
              color: window.Highcharts.getOptions().colors[0],
              type: "scatter",
              showInLegend: false,
              marker: {
                fillColor: "red",
                lineWidth: 1,
                lineColor: window.Highcharts.getOptions().colors[0],
              },
              tooltip: {
                pointFormat: `Produtividade: {point.y} ${props.unit}`,
              },
            },
          ],
    });
  }, [props]);

  return <div id="boxplot" style={{ width: "100%" }} />;
};

export default Boxplot;
