import React from 'react'
import {XAxis, YAxis,ResponsiveContainer,CartesianGrid, Tooltip, Legend, Label, LineChart, Line} from 'recharts'
import CustomizedAxisTick from '../reports/CustomizedAxisTick';

export default function WaterStorageChart(props) {

  let domain = []

  if(parseInt(props.cad,10) === 25){
    domain = [0, 30, 8]
  }
  else if(parseInt(props.cad,10) === 50){
    domain = [0, 60, 14]
  }
  else if(parseInt(props.cad,10) === 75){
    domain = [0, 80, 20]
  }
  else if(parseInt(props.cad,10) === 100){
    domain = [0, 110, 12]
  }
  else if(parseInt(props.cad,10) === 125){
    domain = [0, 130, 14]
  }
  else if(parseInt(props.cad,10) === 150){
    domain = [0, 160, 9]
  }
  else if(parseInt(props.cad,10) === 200){
    domain = [0, 220, 12]
  }
  else if(parseInt(props.cad,10) === 250){
    domain = [0, 275, 13]
  }
  else{
    domain = [0, 330, 14]
  }

  const interval = 'daily'
  const tickCount = 26

  return (
    <ResponsiveContainer width="100%" height='100%'>
      <LineChart data={props.data} margin={{top: 10, right: 30, left: 0, bottom: 0}}>
        <XAxis dataKey="name" tick={<CustomizedAxisTick  graphicRange={'daily'}/>} height={80} interval={interval} tickCount={tickCount}/>
        <YAxis interval={0} tick={{fontSize: '1em'}} tickCount={domain[2]} domain={[domain[0], domain[1]]}>
          <Label angle={-90} value={'Armazenamento (mm)'} position='insideLeft' offset={10} style={{textAnchor: 'middle'}} />
        </YAxis>
        {props.showTooltips ? <Tooltip/> : null}
        <CartesianGrid strokeDasharray="3 3"/>
        <Legend margin={{top: 20, left: 0, right: 0, bottom: 0}} align='center' verticalAlign='top'/>
        <Line key={props.data} type="monotone" connectNulls={true} dataKey={'arm'} name={props.crop} strokeWidth={3} stroke='#0000FF' dot={false} activeDot={false} isAnimationActive={false}/>
      </LineChart>
    </ResponsiveContainer>
  )
}
