import { authHeaderUpload } from "./configUpload";

export const uploadProcessLatLot = async (fileParameter) => {
  let formdata = new FormData();
  formdata.append("file", fileParameter[0]);

  let requestOptions = {
    method: "POST",
    headers: authHeaderUpload(),
    body: formdata,
    redirect: "follow",
  };

  return fetch(
    `${process.env.REACT_APP_API}api/admin/process_lat_lon`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => error);
};

export const uploadCSVWithAllInfos = async (fileParameter, product) => {
  let formdata = new FormData();
  formdata.append("file", fileParameter[0]);

  let requestOptions = {
    method: "POST",
    headers: authHeaderUpload(),
    body: formdata,
    redirect: "follow",
  };
  return fetch(
    `${process.env.REACT_APP_API}/api/admin/productivity?product=${product === 'cane' ? 'sugarcane' : product}`,
    requestOptions
  )
    .then((response) => response.text(response))
    .then((result) => JSON.parse(result))
    .catch((error) => error);
};
