import { FormControl, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import utilsArray from '../utils/utilsArray';

export default function MonthSelect({
  type,
  onDateChange, 
  value,
  graphicRange,
  visualizationType,
  startDate,
  graphicType,
  currentMonth
}) {

  const { decendiosArray, monthsArray } = utilsArray;
  const currentYear = graphicType === 'anomaly' ||
    (visualizationType === 'agricultural' && currentMonth < 7)
    ? Number(new Date().getFullYear()) - 1
    : Number(new Date().getFullYear())

  const cretingArrayOfYears = (number) => {
    const array = [];
    for(let i = number; i < currentYear; i++) {
      
      number++
      
      if (visualizationType !== 'civil') {
        array.push(`${number.toString().slice(2)}-${(number + 1).toString().slice(2)}`)
      } else {
        array.push(`${number}`);
      }
    };
    return array;
  }

  const reorderList = (array) => {
    const fistArray = graphicRange === 'decendial' ? array.slice(18, 36) : array.slice(6, 12);
    const lastArray = graphicRange === 'decendial' ? array.slice(0, 18) : array.slice(0, 6);
    const reorderedArray = [...fistArray, ...lastArray];

    if (visualizationType === 'civil') {
      return array;
    } else {
      return reorderedArray;
    }
  }



  return (
    <>
      <FormControl variant="standard" sx={{ margin: "0 5px",  minWidth: 70 }} size="small">
        {graphicRange === 'decendial' && (
            <Select
              name={type}
              value={value}
              onChange={onDateChange}
            >
                {reorderList(decendiosArray).map((month) => (
                    <MenuItem
                      key={month.name}
                      value={type === 'fromPeriod' ? month.fistDate : month.lastDate}>
                        {month.name}
                    </MenuItem>
                ))}
            </Select>
          )}
          {graphicRange === 'monthly' && (
            <Select
              name={type}
              value={value}
              onChange={onDateChange}
            >
                {reorderList(monthsArray).map((month) => (
                    <MenuItem
                      key={month.name}
                      value={type === 'fromPeriod' ? month.fistDate : month.lastDate}>
                        {month.name}
                    </MenuItem>
                ))}
            </Select>
          )}
          {graphicRange === 'annualy' && (
            <Select
              name={type}
              value={value}
              onChange={onDateChange}
            >
                {cretingArrayOfYears(startDate - 1).map((year) => (
                    <MenuItem
                      key={year}
                      value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
          )}
      </FormControl>
    </>
  );
}