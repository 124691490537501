import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import ClickOutside from 'react-click-outside'
import DropdownField from '../../common/DropdownField'

const CalendarDropdown = (props) => {

  function handleClick(){
    props.onClick()
  }

  function onClickOutside(event){
    if(event.target.localName !== "li"){
      props.onClick()
    }
  }
  //#endregion
  let initialDropOptions;
  let finalDropOptions;
 
    initialDropOptions = props.constantMonthOptions.slice()
    finalDropOptions = props.constantMonthOptions.slice()
    if(props.initialMonth){
      let initialMonthIndex = undefined
      let finalMonthIndex = undefined
      props.constantMonthOptions.forEach((option,index) => {
        if(option.value === props.initialMonth.value){
          initialMonthIndex = index
        }
  
        if(option.value === props.finalMonth.value){
          finalMonthIndex = index
        }
      })
  
      if(initialMonthIndex !== undefined){
        finalDropOptions = finalDropOptions.slice(initialMonthIndex)
      }
  
      if(finalMonthIndex !== undefined){
        initialDropOptions = initialDropOptions.slice(0, finalMonthIndex+1)
      }
    }
  
    return(
      <div className={classnames('header-div-item-outline')}>
        <Link to='#' onClick={handleClick}>
          <div className="button button--retangle">
            <div className='button--retangle-icon-text'>
              <i className="material-icons prod-calendar-icon">event</i>
            </div>
          </div>
        </Link>
        {props.active ?
          <ClickOutside onClickOutside={onClickOutside}>
            <Fragment>
              <div className={classnames('dropdown-div-2')}>
              <div className={classnames("button button--retangle", {'button--retangle-active': props.active})}>
                <div className='button--retangle-icon-text'>
                  Selecione um período
                </div>
              </div>
              <div className={classnames('checkbox-list', 'years-list')}>
                <div className="month-selects-container">
                  Mês inicial:
                  <DropdownField
                    value={props.initialMonth["value"]}
                    options={initialDropOptions}
                    onChange={props.onChangeInitialMonth}
                    type={'months'}
                  />
                </div>
                <div className="month-selects-container">
                  Mês final:
                  <DropdownField
                    value={props.finalMonth["value"]}
                    options={finalDropOptions}
                    onChange={props.onChangeFinalMonth}
                    type={'months'}
                  />
                </div>
              </div>
              <footer className='dropdown-buttons'>
                <div>
                  <button id='save' className='btn btn-ok-drop' onClick={handleClick}>OK</button>
                </div>
              </footer>
            </div>
            </Fragment>
          </ClickOutside>
        : null}
      </div>
    )
    
  
}

export default CalendarDropdown
