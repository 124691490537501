import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT_SUCCESS, SET_LOGIN_ERRORS_TO_NULL, SET_REMEMBER_ME} from '../actions/login'

const initialState = {
  isLoggedIn: false,
  user: {},
  errors: {},
  rememberMe: false
}

export default (state=initialState, action) => {
  switch(action.type) {
    case LOGIN_SUCCESS:
      return{
        isLoggedIn: true,
        user: action.data
      }
    case LOGIN_FAILED:
      return Object.assign({}, state, {errors: action.data.errors})
    case LOGOUT_SUCCESS:
      window.location.reload()
      break
    case SET_LOGIN_ERRORS_TO_NULL:
      return Object.assign({}, state, {errors: {}})
    case SET_REMEMBER_ME:
      return Object.assign({}, state, {rememberMe: action.check})  
    default:
      return state
  }
}
