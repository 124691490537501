export default function getGeneratedMapString(reportTypes, attrs){
    if(reportTypes[0] === 'fmaac' || reportTypes === 'fmaac'){
        return `api/map_agryfire?fix_date=${attrs.final_date}`
    }
    else if(reportTypes[0] === 'cane-flowering' || reportTypes === 'cane-flowering'){
        return `api/flowering_risk_map?prediction_days=${attrs.predictionDays}&year=${attrs.year}`
    }
    else{
        return `api/map?initial_date=${attrs.initial_date}&final_date=${attrs.final_date}&variable=${reportTypes}&type=${attrs.type}&range=${attrs.range}&cad=${attrs.cad}&tb=${attrs.tb}&soil=${attrs.soil_texture}&visualization_type=${attrs.visualization_type}`
    }
}
