import React from 'react';

import Checkbox from '@mui/material/Checkbox';

function VariablesSelect({ variables, options, onChange }) {
  const checked = (option) => variables.includes(option);

  return(
    <div className="col s12 variables-select">
      <h6>Selecione as variaveis desejadas</h6>
      {
        options.map(option => {
          const optionString = option.toString();
          return (
            <div className="col s2" key={optionString}>
              <label>
                <Checkbox
                  inputProps={{ id: optionString, name: optionString }}
                  onChange={onChange}
                  checked={checked(option)}
                />
                &nbsp; {optionString}
              </label>
            </div>
          );
        })
      }
    </div>
  );
}

export default VariablesSelect;
