import React, { useState, useCallback, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardTable, addDashboardCsv, addDashboardLine, editDashboardLine, deleteDashboardLine, resetDashboardMessage } from "../../actions/dashboard"

import { Button, Dialog, DialogTitle, DialogContent, Grid, Select, MenuItem, FormControl, Input, FormLabel, DialogActions, TextField } from "@material-ui/core"
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from "material-ui-pickers";
import { showToaster } from "../common/Toaster"

import DashboardTable from "../common/DashboardTable"

import AddIcon from "@material-ui/icons/AddCircle"

import { CANE, SOY, CORN, COTTON, WHEAT } from "../reports/productivity/common/defaultValues"

const ROWS_LIMIT = 15

const DashboardConfig = (props) => {
    const [table, setTable] = useState([])
    const [point, setPoint] = useState("")
    const [date, setDate] = useState(new Date())
    const [cycle, setCycle] = useState("")
    const [desiredEfficiency, setDesiredEfficiency] = useState("")
    const [productivy, setProductivity] = useState("")
    const [cutNumber, setCutNumber] = useState("")
    const [kdec, setKdec] = useState("")

    const [addMethod, setAddMethod] = useState("manual")
    const [file, setFile] = useState(undefined)

    const  tableHeader = [
        {
            name: "Ponto",
            prop: "farm_name"
        },
        {
            name: "Data de Semeadura",
            prop: "planting_date"
        },
        {
            name: "Ciclo",
            prop: "cycle"
        },
        {
            name: "Eficiência Almejada",
            prop: "desired_agry_eficiency"
        },
        {
            name: "Produtividade Real Observada",
            prop: "user_rp_value"
        },
    ]

    if(props.culture === "cane") {
        tableHeader.push({
            name: "Número do Corte",
            prop: "cut_number"
        })
        tableHeader.push({
            name: "Kdec",
            prop: "kdec"
        })
    }

    const dispatch = useDispatch()
    const data = useSelector(state => state.dashboard.data)
    const pagination = useSelector(state => state.dashboard.pagination)
    const message = useSelector(state => state.dashboard.message)

    useEffect(() => {
        if(data) {
            const table = []
            data.forEach((item) => {
                let row = item
                row.agry_eficiency = (item.agry_eficiency * 100).toFixed(2)
                row.desired_agry_eficiency = (item.desired_agry_eficiency * 100).toFixed(2)
                row.user_rp_value = parseFloat(item.user_rp_value).toFixed(2)
                table.push(row)
            })
            setTable(table)
        }
    }, [data])


    useEffect(() => {
        if(message !== "") {
            const toasterParams = {
                title: 'SUCESSO!',
                message: message,
                status: true,
                autoClose: true
            }  
            showToaster(toasterParams)

            const data = {
                culture: props.culture,
                page: pagination.current_page,
                limit: ROWS_LIMIT
            }
            dispatch(getDashboardTable(data))          
        }
        dispatch(resetDashboardMessage())
    }, [dispatch, message, pagination, props.culture])

    function addLine() {
        let error = false
        let message = ["Por favor preencha os seguintes campos:"]
        
        if([point, cycle, productivy].includes("")) {
            error = true
            if(point === "") message.push("Ponto;")
            if(cycle === "") message.push("Ciclo;")
            if(productivy === "") message.push("Produtividade Real Observada;")
        }
        if(props.culture === "cane" && ([kdec, cutNumber].includes(""))) {
            error = true
            if(cutNumber === "") message.push("Número de Corte;")
            if(kdec === "") message.push("Kdec;")
        }
        if(error) {
            const toasterParams = {
                title: 'ERRO!',
                message: message,
                status: false,
                autoClose: false,
                customHeight: 190
              }  
            showToaster(toasterParams)
            return
        }

        const data = {
            point_id: point,
            culture: props.culture,
            planting_date: moment(date).format("YYYY-MM-DD"),
            cycle: cycle,
            agry_eficiency: 0.7,
            desired_agry_eficiency: desiredEfficiency,
            user_rp_value: productivy
        }
        if(props.culture === "cane") {
            data.extraArgs = {
                kdec: parseFloat(kdec),
                cut_number: parseInt(cutNumber, 10)
            }
        }
        dispatch(addDashboardLine(data))
    }

    function uploadFile() {
        let data
        if(file) {
            data = {
                culture: props.culture,
                file: file
            }
            dispatch(addDashboardCsv(data))
        }
        else{
            const toasterParams = {
                title: 'ERRO!',
                message: "Por favor carregue um arquivo CSV",
                status: true,
                autoClose: true
            }  
            showToaster(toasterParams)
        }
    }

    const setUpdate = useCallback((culture, limit, page) => {
        const data = {
            culture: culture,
            page: page, 
            limit: ROWS_LIMIT
        }
        dispatch(getDashboardTable(data))
    }, [dispatch])

    function setEdit(data) {
        dispatch(editDashboardLine(data))
    }

    function setDelete(line) {
        dispatch(deleteDashboardLine(line))
    }

    function changeAddMethod() {
        let newMethod = addMethod === "manual" ? "upload" : "manual"
        setAddMethod(newMethod)
    }

    let pointOptions = [];
    pointOptions.push(props.points.map((point) =>
        <MenuItem key={point.id} value={point.id}>{point.name !== "" ? `${point.initials} - ${point.name}` : `Ponto ${point.initials}`}</MenuItem>
    ))

    let cycleOptions = [];
    let product;
    switch(props.culture) {
        case "cane":
            product = CANE
            break
        case "soy":
            product = SOY
            break
        case "corn":
            product = CORN
            break
        case "cotton":
            product = COTTON
            break
        case "wheat":
            product = WHEAT
            break
        default:
            product = {}
    }

    cycleOptions.push(product.CYCLE_OPTIONS.map((option) =>
        <MenuItem key={option} value={option}>{option}</MenuItem>
    ))

    return (
        <Dialog 
            style={{zIndex:"10002"}}
            open={props.openConfig} 
            maxWidth='xl' 
            disableBackdropClick
        >
            <DialogTitle>
                <Grid item container justify="space-between">
                    <Grid item>
                        Configurações da Dashboard
                    </Grid>
                    <Grid item container spacing={8} alignItems="center" style={{width: "300px"}}>
                        <Grid item>
                            Modo de adição:
                        </Grid>
                        <Grid item>
                            <Button className={addMethod === "manual" ? "green save-button" : ""} onClick={changeAddMethod}>Manual</Button>
                            <Button className={addMethod === "upload" ? "green save-button" : ""} onClick={changeAddMethod}>Upload</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {addMethod === "manual" ?
                    <Grid item container alignItems="center" className="dashboard-settings">
                        <FormControl className="form-control">
                            <FormLabel htmlFor="cycle-label">
                                Selecionar Ponto
                            </FormLabel>
                            <Select
                                className="input-margin-8"
                                value={point}
                                onChange={(e) => setPoint(e.target.value)}
                                >
                                {pointOptions}
                            </Select>
                        </FormControl>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                            <FormControl className="form-control">
                                <FormLabel htmlFor="date-label">
                                    Data de semeadura
                                </FormLabel>
                                <DatePicker
                                    keyboard
                                    className="input-margin-8"
                                    placeholder={'DD/MM/AAAA'}
                                    format={"DD/MM/YYYY"}
                                    margin="dense"
                                    value={date}
                                    onChange={(newDate) => setDate(newDate)}
                                    disableOpenOnEnter
                                    animateYearScrolling={false}
                                    minDate={moment('1982-01-01')}
                                    maxDate={moment().add(1, 'year')}
                                    InputProps={{disableUnderline: true, style: {width: "100%"}, error: true}}
                                    FormHelperTextProps={{error: true, className: "input-error-text" }}
                                    helperText={''}
                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                />
                            </FormControl>
                        </MuiPickersUtilsProvider>
                        <FormControl className="form-control">
                            <FormLabel htmlFor="cycle-label">
                                Ciclo
                            </FormLabel>
                            <Select
                                className="input-margin-8"
                                value={cycle}
                                onChange={(e) => setCycle(e.target.value)}
                                >
                                {cycleOptions}
                            </Select>
                        </FormControl>
                        <FormControl className="form-control">
                            <FormLabel htmlFor="efficiency-label">
                                Eficiência Almejada (%)
                            </FormLabel>
                            <Input
                                className="input-margin-8"
                                value={desiredEfficiency}
                                onChange={(e) => setDesiredEfficiency(e.target.value)}
                                disableUnderline
                            />
                        </FormControl>
                        <FormControl className="form-control">
                            <FormLabel htmlFor="efficiency-label">
                                Produtividade Real Observada {props.unit}
                            </FormLabel>
                            <Input
                                className="input-margin-8"
                                value={productivy}
                                onChange={(e) => setProductivity(e.target.value)}
                                disableUnderline
                            />
                        </FormControl>
                        {props.culture === "cane" &&
                            <Fragment>
                                <FormControl className="form-control">
                                    <FormLabel htmlFor="cutNumber-label">
                                        Número de Corte
                                    </FormLabel>
                                    <Input
                                        className="input-margin-8"
                                        value={cutNumber}
                                        onChange={(e) => setCutNumber(e.target.value)}
                                        disableUnderline
                                    />
                                </FormControl>
                                <FormControl className="form-control">
                                    <FormLabel htmlFor="kdec-label">
                                        Kdec
                                    </FormLabel>
                                    <Input
                                        className="input-margin-8"
                                        value={kdec}
                                        onChange={(e) => setKdec(e.target.value)}
                                        disableUnderline
                                    />
                                </FormControl>
                            </Fragment>
                        }
                        <Button onClick={addLine}>
                            <AddIcon />
                        </Button>
                    </Grid>
                :
                <Grid container alignItems="center" className="dashboard-upload-line">
                    <Button className="green save-button">
                        <a href={`/${props.culture}-dashboard-example.csv`} target="_blank" rel="noopener noreferrer">Baixar exemplo</a>
                    </Button>
                    <input 
                        id="file-upload-button"
                        type="file"
                        accept=".csv"
                        style={{display: "none"}}
                        onChange={(event) => setFile(event.target.files[0])}
                    />
                    <label htmlFor="file-upload-button">
                        <Button className="green save-button" component="span">
                            Upload
                        </Button>
                    </label>
                    <TextField disabled value={file ? file.name : "sem arquivo"} />
                    <Button onClick={uploadFile}>
                        <AddIcon />
                    </Button>
                </Grid>
                }
            </DialogTitle>
            <DialogContent>
                <Grid container className="dashboard-table-container">
                    <DashboardTable
                        editableColumns={[0, 1, 2, 3, 4, 5, 6, 7]}
                        data={table}
                        rowNumberOption={[ROWS_LIMIT]}
                        selectColumns={[0, 2]}
                        selectOptions={
                            [
                                {column: 0, name: "farm_id", options: pointOptions},
                                {column: 2, name: "cycle", options: cycleOptions}
                            ]
                        }
                        dateColumns={[1]}
                        totalItens={pagination.total_itens}
                        extraAuth={props.culture}
                        sendUpdate={setUpdate}
                        sendEdit={setEdit}
                        sendDelete={setDelete}
                        header={tableHeader}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button className="green save-button" onClick={props.toggleConfiguration}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DashboardConfig;