import React from 'react';

import Grid from '@mui/material/Grid';

import { MainHeader, SecondHeader, Caption } from './styles/AuditTypography';
import { StyledText } from './styles/AuditPaulo';

import paulo from '../../images/audit/paulo.jpg';
import formatura from '../../images/audit/formatura.jpg';
import paulo_2 from '../../images/audit/Paulo_Sentelhas_1.png';
import paulo_e_barbara from '../../images/audit/paulo_e_barbara.png';

function PauloCesar() {
  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 12 }>
        <MainHeader component="h2">
          Paulo Cesar Sentelhas
        </MainHeader>
        <SecondHeader component="h3">
          Como o Agrymax começou
        </SecondHeader>
      </Grid>

      <StyledText item xs={ 12 }>
        Movido pelo sonho de ver o que era gerado na ciência sendo colocado em prática no campo,  o  Prof.  Dr.  Paulo  Sentelhas  (1964-2022),  especialista  em  agrometeorologia  e modelagem e professor titular na ESALQ, fundou a Agrymet.
      </StyledText>

      <Grid item xs={ 12 } md={ 8 } container alignContent="flex-start" spacing={4}>
        <Grid item xs={ 12 } container>
          <StyledText item component="p">
            A Agrymet nasceu em 2008 como uma empresa de consultoria que atendia as principais usinas  de  cana-de-açúcar,  produtores  e  empresas  de  insumos  do  setor  agrícola  e florestal, fosse por meio de projetos específicos, boletins agrometeorológicos quinzenais e aulas e palestras que o Prof. Paulo realizava pelo Brasil afora. Com o passar dos anos, o  Prof.  Paulo  se  tornou  a  maior  referência  em  agrometeorologia  no  Brasil  e  América Latina, tanto pelo seu trabalho excepcional desenvolvido no meio científico-acadêmico quanto pela sua facilidade de explicar e aplicar suas pesquisas na prática. Com isso as demandas  foram  aumentando  e  ficou  cada  vez  mais  difícil  de  realizar  análises  tão complexas  de  forma  manual.  Esse  fato  juntamente  com  o  avanço  da  tecnologia  no agronegócio fez com que a Agrymet iniciasse uma transição de empresa de consultoria para uma empresa de tecnologia em 2016.
          </StyledText>
        </Grid>
      </Grid>

      <Grid item xs={ 12 } md={ 4 }>
        <Grid container direction='column'>
          <Grid item>
            <img
              src={ paulo }
              alt="Paulo César Sentelhas"
              width="80%"
              style={{ display: 'block', margin: 'auto' }}
            />
          </Grid>
          <Grid item>
            <Caption align='center'>Paulo César Sentelhas</Caption>
          </Grid>
        </Grid>
      </Grid>

      <StyledText item xs={12}>
        O desafio de construir uma base de dados e um sistema próprio que suportasse análises agrometeorológicas tão robustas a nível nacional foi imenso, mas graças ao encontro de  uma  equipe  de  desenvolvimento  extremamente  capacitada  nasce  o  Agrymax  em 2018, com uma base de dados climáticos de atualmente 41 anos monitorando em torno de 11.600 pontos virtuais e que segue em constante transformação e evolução. Dessa forma, foi possível dar continuidade e expandir o trabalho e o legado deixado pelo Prof. Paulo,  dando  acesso  a  dados  e  informações  extremamente  importantes  ao  setor, permitindo tomadas de decisão mais precisas de acordo com as condições climáticas e a adoção de estratégias para mitigação do risco causado pelas adversidades climáticas.
      </StyledText>

      <Grid item xs={ 12 } md={ 4 }>
        <Grid container justifyContent="center">
          <Grid item>
            <img
              src={ formatura }
              alt=""
              width="100%"
              style={{ display: 'block', margin: 'auto' }}
            />
          </Grid>
          <Grid item>
            <Caption align='center'>Colação Paulo e Bárbara Sentelhas</Caption>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 } md={ 8 } container alignContent="flex-start" spacing={4}>
        <StyledText item component="p">
          Paulo Cesar Sentelhas era formado em Engenharia Agronômica pela Universidade de
          Espírito Santo do Pinhal (UNIPINHAL) - Faculdade de Agronomia e Zootecnia Manoel
          Carlos Gonçalves (1987). Fez mestrado em Agronomia (Agrometeorologia), 1992, e
          doutorado em Agronomia (Irrigação e Drenagem), 1998, ambos pela Esalq. Fez
          pós-doutorado na Universidade de Guelph, no Canadá, de março de 2003 a outubro de
          2004, e no consórcio Universidade de Guelph - Weather Innovations Incorporated em 2008,
          onde atuou como pesquisador visitante. Obteve o título de Livre-Docente em
          Agrometeorologia na Esalq em dezembro de 2004. Atuou como pesquisador no
          IPMET/UNESP (1988-1994) e no IAC (1994-1996). Desde março de 1996 era professor da
          Esalq e ocupava atualmente o cargo de Professor Titular da área de Agrometeorologia.
        </StyledText>
      </Grid>

      <Grid item xs={ 12 } md={ 8 } container alignContent="flex-start" spacing={4}>
        <StyledText item component="p">
        Foi secretário da Sociedade Brasileira de Agrometeorologia de 1995 a 1997 e de 2001 a 2003 e seu presidente no biênio 2005-2007, quando também atuou como presidente da Federação Latinoamericana de Agrometeorologia.
        </StyledText>
        <StyledText item component="p">
        Tinha atuação destacada na área de Agronomia, com ênfase em Agrometeorologia. Como docente,   lecionava na graduação as disciplinas Meteorologia Agrícola e Agrometeorologia Aplicada e, na pós-graduação, a disciplina Agrometeorologia e Doenças de Plantas.
      </StyledText>
      </Grid>
      
      <Grid item xs={ 12 } md={ 4 }>
        <Grid container justifyContent="center">
          <Grid item>
            <img
              src={ paulo_2 }
              alt=""
              width="100%"
              style={{ display: 'block', margin: 'auto' }}
            />
          </Grid>
          <Grid item>
            <Caption align='center'>Paulo César Sentelhas</Caption>
          </Grid>
        </Grid>
      </Grid>

      <StyledText item component="p">
        De 2013 a 2015 foi Editor Científico da revista Scientia Agrícola, Esalq, sendo a partir de  Julho  de  2015  o  Editor-Chefe  da  mesma.  Atuava  como  Editor  Associado  da International Journal of Biometeorology (ISBM - Springer) e como membro dos comitês editoriais da Italian Journal of Agrometeorology (SIA, Itália), International Journal of Plant production  (IJPO  -  Springer),  Applied  Sciences  (Springer)  e  AgriScientia  (Univ.  de Córdoba,  Argentina).  De  2005  a  2013  atuou  como  representante  do  Brasil  junto  ao Grupo de Especialistas em Agrometeorologia da Organização Mundial de Meteorologia (OMM-ONU). Era membro da SBAgro (Sociedade Brasileira de Agrometeorologia) e da INSAM (International Society of Agricultural Meteorology). De 2012 a 2016 foi membro do Comitê Julgador do Mumm Award da OMM e de 2014 a 2016 do juri do Environmental Respect  Award  da  DuPont-Brasil.  Em  2019  recebeu  o  Prêmio  Sergio  Westphalen  da SBAgro como reconhecimento por suas contribuições para a área de Agrometeorologia do Brasil.
      </StyledText>

      <Grid item xs={ 12 } md={ 4 }>
        <Grid container justifyContent="center">
          <Grid item>
            <img
              src={ paulo_e_barbara }
              alt="Paulo César e Bárbara Sentelhas"
              width="100%"
              style={{ display: 'block', margin: 'auto' }}
            />
          </Grid>
          <Grid item>
            <Caption align='center'>Paulo César e Bárbara Sentelhas</Caption>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={ 12 }
        md={ 8 }
        container
        alignContent="flex-start"
        spacing={4}
        sx={{ marginBottom: '50px' }}
      >
        <StyledText item component="p">
          Doze vezes professor homenageado pelas Turmas de Engenharia Agronômica, autor de
          224 artigos, orientador de 30 mestres e 28 doutores e 5 pós-doutorandos e 90 graduandos.
        </StyledText>
        <StyledText item component="p">
          Além de toda contribuição científica e acadêmica mencionada acima, Paulo se destacou e
          contribuiu por muitos anos como consultor e palestrante na área de agrometeorologia,
          atendendo grandes empresas Cosan (Raízen), Dupont (Corteva), Bayer, BASF, Vale,
          Syngenta, Bracell, Pepsico,grandes empresas do setor sucroalcooleiro entre outras. Era
          membro efetivo e atuante do Comitê Estratégico Soja Brasil (CESB) e uma referência em
          modelagem climática e Yield Gap.
        </StyledText>
      </Grid>
    </Grid>
  );
}

export default PauloCesar;
