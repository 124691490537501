import React, { Component, Fragment } from "react";
import classnames from "classnames";
// Components
import Indicator from "../bhbox/Indicator";
import PerfectScrollbar from "react-perfect-scrollbar";

//utils
import { numberToMonth } from "../../../shared/utils/DatesUtils";

// Styles
import "../../../stylesheets/css/components/bhbox.css";
import "../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css";
import { connect } from "react-redux";

class AgryIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      detailPopover: {
        active: false,
        element: undefined,
        data: undefined,
        dec: undefined,
        month: undefined,
        year: undefined,
        initials: undefined,
      },
      activeMonth: new Date().getMonth() + 1,
      activeYear: new Date().getFullYear(),
    };
  }

  //Lifecycle methods
  UNSAFE_componentWillReceiveProps() {
    this.setState({ detailPopover: { active: false } });
  }

  //#region Helper methods

  renderDifferentCol() {
    return !this.props.bhboxType && (
      <div className="years-label">
        <label>ANOS</label>
      </div>
    );
  }

  getDecByDay(day) {
    if (day <= 10) {
      return 0;
    } else if (day > 10 && day <= 20) {
      return 1;
    } else if (day > 20) {
      return 2;
    }
  }

  predictionIndicator(day, month, year, data, lastDay) {
    if (
      data === -1 || (
        this.state.activeMonth === 12 &&
        this.state.activeYear !== year - 1 &&
        month === 1
      )
    ) {
      return -1;
    }

    if (year === this.state.activeYear || year === this.state.activeYear + 1) {
      if (day === 5) {
        return 0;
      } else if (day > 5) {
        return lastDay ? 2 : 1;
      }
    }

    return -1;
  }

  scrollBottom(scrollRef) {
    if (scrollRef !== null) {
      scrollRef.scrollTop =
        parseInt(scrollRef.offsetHeight, 10) +
        parseInt(scrollRef.offsetTop, 10);
    }
  }

  renderBody() {
    var farmInfo = [];

    if (this.props.bhboxType) {
      return (
        <Fragment>
          <PerfectScrollbar
            className={classnames("index-scroll ps ps--theme default", {
              "index-scroll-multiple": this.props.bhboxType,
            })}
          >
            {this.props.data.map((row, index) => {
              if (this.props.farms !== undefined) {
                this.props.farms["farms"].forEach((farm) => {
                  if (farm.id === row["farm_id"]) {
                    farmInfo = farm;
                  }
                });
              } else {
                this.props.xavierGridPoints["xavier_points"].forEach(
                  (point) => {
                    if (
                      row["lat"] === point["lat"].toString() &&
                      row["lng"] === point["lng"].toString()
                    ) {
                      farmInfo["name"] = point["name"];
                      farmInfo["initials"] = point["uf"];
                      farmInfo["cad"] = row["cad"];
                    }
                  }
                );
              }
              return (
                <div
                  className={classnames("bhbox--labels-data", {
                    "index--labels-data-multiple": this.props.bhboxType,
                  })}
                >
                  <div
                    className={classnames("multiple-farms-label-initials")}
                    title={farmInfo.name}
                  >
                    {farmInfo.initials}
                  </div>
                  {row.bhbox.map((yearRow, index) => {
                    return yearRow["data"].map((day, index) => {
                      let lastDay = false;
                      if (index === yearRow["data"].length - 1) {
                        lastDay = true;
                      }
                      if (index < yearRow["data"].length - 1) {
                        if (
                          yearRow["data"][index]["day"] !== 0 &&
                          yearRow["data"][index + 1]["day"] === 0
                        ) {
                          lastDay = true;
                        }
                      }
                      return (
                        <Indicator
                          soil={this.props.soil}
                          agrytoolsType={this.props.agrytoolsType}
                          key={index}
                          index={index}
                          initials={farmInfo.initials}
                          month={day["month"]}
                          year={day["year"]}
                          data={[day["value"]]}
                          prediction={this.predictionIndicator(
                            index,
                            day["month"],
                            day["year"],
                            day["value"],
                            lastDay
                          )}
                          setDetailPopover={this.setDetailPopover}
                          fullScreen={this.props.fullScreen}
                        />
                      );
                    });
                  })}
                </div>
              );
            })}
          </PerfectScrollbar>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <PerfectScrollbar
            containerRef={this.scrollBottom}
            className="index-scroll ps ps--theme default"
            onScrollY={this.handleScroll}
          >
            {this.props.data.map((row) => {
              if (this.props.activeFarm !== undefined) {
                if (row["farm_id"] === this.props.activeFarm["id"]) {
                  return row.bhbox.map((yearRow, index) => {
                    return (
                      <div
                        key={index + "_" + yearRow["year"]}
                        className="bhbox--labels-data"
                      >
                        <div className={classnames("year-label")}>
                          {yearRow["year"]}
                        </div>
                        {yearRow["data"].map((day, index) => {
                          let lastDay = false;
                          if (index === yearRow["data"].length - 1) {
                            lastDay = true;
                          }

                          if (index < yearRow["data"].length - 1) {
                            if (
                              yearRow["data"][index]["day"] !== 0 &&
                              yearRow["data"][index + 1]["day"] === 0
                            ) {
                              lastDay = true;
                            }
                          }
                          return (
                            <Indicator
                              soil={this.props.soil}
                              agrytoolsType={this.props.agrytoolsType}
                              key={index}
                              index={index}
                              day={day["day"]}
                              month={day["month"]}
                              year={day["year"]}
                              data={[day["value"]]}
                              onClick={this.onIndicatorClick}
                              prediction={this.predictionIndicator(
                                index,
                                day["month"],
                                day["year"],
                                day["value"],
                                lastDay
                              )}
                              setDetailPopover={this.setDetailPopover}
                              fullScreen={this.props.fullScreen}
                            />
                          );
                        })}
                      </div>
                    );
                  });
                } else {
                  return null;
                }
              } else {
                return row.bhbox.map((yearRow, index) => {
                  return (
                    <div
                      key={index + "_" + yearRow["year"]}
                      className="bhbox--labels-data"
                    >
                      <div className={classnames("year-label")}>
                        {yearRow["year"]}
                      </div>
                      {yearRow["data"].map((day, index) => {
                        return (
                          <Indicator
                            soil={this.props.soil}
                            agrytoolsType={this.props.agrytoolsType}
                            key={index}
                            index={index}
                            day={day["day"]}
                            month={day["month"]}
                            year={day["year"]}
                            data={[day["value"]]}
                            onClick={this.onIndicatorClick}
                            prediction={this.predictionIndicator(
                              index,
                              day["month"],
                              day["year"],
                              day["value"]
                            )}
                            setDetailPopover={this.setDetailPopover}
                            fullScreen={this.props.fullScreen}
                          />
                        );
                      })}
                    </div>
                  );
                });
              }
            })}
          </PerfectScrollbar>
        </Fragment>
      );
    }
  }
  //#endregion

  //#region Render methods

  setDetailPopover = (el, data, dec, month, year, initials) => {
    this.setState({
      detailPopover: {
        active: true,
        element: el,
        data: data,
        dec: dec,
        month: month,
        year: year,
        initials: initials,
      },
    });
  };

  disablePopover = () => {
    this.setState({ detailPopover: { active: false } });
  };

  handleScroll = (e) => {
    if (this.state.detailPopover.active) {
      this.setState({ detailPopover: { active: false } });
    }
  };

  onIndicatorClick = (day, month, year) => {
    let mapAttrs = {
      initial_date: year + "-" + month + "-" + day,
      final_date: year + "-" + month + "-" + day,
      type: "period",
      range: "daily",
      cad: this.props.lastUsedCad,
      tb: 10,
      soil_texture: this.props.lastUsedSoil,
      visualization_type: this.props.visualizationType,
    };

    let dayString = day;
    let monthString = month;
    if (parseInt(day, 10) < 10) {
      dayString = "0" + day;
    }

    if (parseInt(month, 10) < 10) {
      monthString = "0" + month;
    }
    let dayArray = {};
    dayArray["label"] = dayString + "/" + monthString;
    dayArray["year"] = year;
    dayArray["variable"] = this.props.agrytoolsType;

    this.props.setMapDay(dayArray);
    this.props.setMapYear(dayArray["year"]);
    this.props.generatingMapData();
    this.props.toggleLoader();
    this.props.generateMapData([this.props.agrytoolsType], mapAttrs);
  };
  //#endregion

  render() {
    let numberOfDays = [];
    let stopConst = false;
    let daysData = [];
    if (this.props.reportTypes !== "favo") {
      this.props.data.forEach((row) => {
        if (stopConst) {
          return;
        }
        let currentMonth = 0;
        let numberOfDaysAux = 0;
        row.bhbox[0]["data"].forEach((data) => {
          if (currentMonth !== data["month"]) {
            if (numberOfDaysAux !== 0) {
              numberOfDays.push({ days: numberOfDaysAux, month: currentMonth });
            }
            currentMonth = data["month"];
            numberOfDaysAux = 1;
          } else {
            numberOfDaysAux += 1;
          }
        });
        numberOfDays.push({ days: numberOfDaysAux, month: currentMonth });
        stopConst = true;
      });
      daysData = [this.props.data[0]];
    }

    return (
      <Fragment>
        {this.props.reportTypes !== "favo" && (
          <>
            <div
              className={classnames("bhbox", {
                "bhbox-multiple": this.props.bhboxType,
              })}
            >
              {this.props.bhboxType ? (
                <Fragment>
                  <div id="multiple-farms-first-td-index">
                    <label>UP</label>
                  </div>
                </Fragment>
              ) : null}
              <div className="bhbox--labels-months">
                {this.props.bhboxType ? null : <div id="first-td"></div>}
                {numberOfDays.map((month, index) => {
                  let monthString = "";
                  if (month["month"] >= 10) {
                    monthString = month["month"].toString();
                  } else {
                    monthString = "0" + month["month"].toString();
                  }
                  let divNumber = this.props.bhboxType ? "36" : "38";
                  return (
                    <div
                      key={month["month"]}
                      className={classnames("months-labels", {
                        "last-key": numberOfDays.length === index + 1,
                      })}
                      colSpan={3}
                      style={{
                        width:
                          "calc(" +
                          (month["days"] * 100).toString() +
                          "%/" +
                          divNumber +
                          ")",
                        fontSize: month["days"] < 3 ? "9px" : "13px",
                      }}
                    >
                      {numberToMonth(monthString).substring(0, 3).toUpperCase()}
                    </div>
                  );
                })}
              </div>
              <div className={classnames("bhbox--labels-days")}>
                {this.renderDifferentCol()}

                {daysData.map((row) => {
                  return row.bhbox[0]["data"].map((data, index) => {
                    return (
                      <div
                        key={data["day"] + "_" + data["month"]}
                        className={classnames(
                          { "days-labels-multiple": this.props.bhboxType },
                          {
                            "last-key":
                              row.bhbox[0]["data"].length === index + 1,
                          }
                        )}
                      >
                        {data["day"]}
                      </div>
                    );
                  });
                })}
              </div>
            </div>
            {this.renderBody()}
          </>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportTypes: state.Reports.reportTypes,
  };
}

export default connect(mapStateToProps, null)(AgryIndex);
