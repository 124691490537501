import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'

// Actions
import { addFarm } from '../actions/farms'
import { allowMapClickEvent} from '../actions/map'
import { setActiveFarm } from '../actions/farms'
import {toggleReportContainer} from '../actions/reports'
import { toggleLoader } from '../actions/global'

class AddFarm extends Component {
  constructor(props) {
    super(props)

    this.geocoder = new window.google.maps.Geocoder()
    this.autocomplete = undefined

  }

  handleKeyPress(event) {
    // [Enter] should not submit the form when choosing an address.
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  componentDidMount() {
    const node = ReactDOM.findDOMNode(this)
    let addEvent

    if(node) {
      addEvent = node.addEventListener || node.attachEvent
      addEvent("keypress", this.handleKeyPress, false)
    }
  }

  componentDidUpdate() {
    let input = this.geoInput
    if(input !== undefined && input !== null){
      let options = {}
      this.autocomplete = new window.google.maps.places.Autocomplete(input, options)
      this.autocomplete.addListener('place_changed', this.findGeocode)
    }
  }

  findGeocode = () => {
    let place = this.autocomplete.getPlace()
    let coords = place.geometry.location.lat().toFixed(3).toString() + "," + place.geometry.location.lng().toFixed(3).toString()
    let attrs = {
      user: localStorage.Token ? localStorage.Token : sessionStorage.Token,
      coords: coords
    }
    this.props.addNewFarm(attrs)
    this.props.toggleActive()
    this.props.toggleLoader()
  }

  handleClick = () => {
    this.props.toggleActive()
    
    if(this.props.sidebar){
      this.props.toggleSidebar()
    }
    if(this.props.reportActive){
      this.props.toggleReportContainer()
    }
    if(this.props.moreFarmsActive){
      this.props.toggleMoreFarmsActive()
    }
  }

  handleAddLocationClick = () => {
    if (this.props.allowMapClickEvent === false) {
      this.props.setMapClickEvent();
    }
    this.props.toggleActive()
    this.props.showAlert()
  }

  componentShape() {
    if(this.props.user.agrymax_plan === "basic") {
      return null
    }
    if(this.props.active) {
      return(
        <ul className='formItem'>
          <li className='formItem--input'>
            <input ref={ (input) => { this.geoInput = input } } type='text' placeholder='Localize no mapa' name='location' />
          </li>
          <li>
            <Link to="#" title='Adicionar Por Clique' className='button button--square button--square-default button--square-nav' onClick={ this.handleAddLocationClick}>
              <i className='material-icons'>add_location</i>
            </Link>
            <Link to="#" title='Cancelar' className='button button--square button--square-default button--square-nav button--square-nav-close' onClick={ this.props.toggleActive }>
              <i className='material-icons'>clear</i>
            </Link>
          </li>
        </ul>
      )
    } else {
      return(
        <Link to='#' title='Adicionar Ponto' className='button button--square button--square-default button--square-nav left' onClick={ this.handleClick }>
          <i className='material-icons'>add</i>
        </Link>
      )
    }
  }

  render() {
    return(
      this.componentShape()
    )
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms.points,
    activeFarm: state.farms.activeFarm,
    reportActive: state.Reports.reportActive,
    user: state.auth.user,
    allowMapClickEvent: state.map.allowMapClickEvent
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNewFarm: (coords) => {
      dispatch(addFarm(coords))
    },
    setMapClickEvent: () => {
      dispatch(allowMapClickEvent())
    },
    setActiveFarm: (id) =>{
      dispatch(setActiveFarm(id))
    },
    toggleReportContainer: () =>{
      dispatch(toggleReportContainer())
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFarm)
