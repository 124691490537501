import React, { Component } from 'react'
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from "material-ui-pickers";
import { CANE, SOY, CORN, COTTON, WHEAT, EUCALYPTUS } from "./common/defaultValues"

moment.locale('pt');

class SpecificProductivityOptions extends Component {
  componentWillUnmount(){
    this.props.handleClose()
  }

  setCycleOptions = () => {
    if(this.props.reportType === 'productivity-spec'){
      return (
        <Select
          inputProps={{name: 'cycle', id: 'cycle-input'}}
          className={"prod-months-dropdown"}
          value={this.props.productivity.cycle}
          onChange={this.props.handleChange}
          name="cycle"
        >
          {CANE.CYCLE_OPTIONS.map(option => 
            <MenuItem value={option}>{option}</MenuItem>
          )}
        </Select>
      )
    }else if(this.props.reportType === 'corn-spec-productivity'){
      return(
        <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.productivity.cycle} onChange={this.props.handleChange} name="cycle">
          {CORN.CYCLE_OPTIONS.map(option => 
            <MenuItem value={option}>{option}</MenuItem>
          )}          
        </Select>
      )
    }else if(this.props.reportType === 'cotton-spec-productivity'){
      return(
        <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.productivity.cycle} onChange={this.props.handleChange} name="cycle">
          {COTTON.CYCLE_OPTIONS.map(option => 
            <MenuItem value={option}>{option}</MenuItem>
          )}
        </Select>
      )
    }else if(this.props.reportType === 'wheat-spec-productivity'){
      return(
        <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.productivity.cycle} onChange={this.props.handleChange} name="cycle">
          {WHEAT.CYCLE_OPTIONS.map(option => 
            <MenuItem value={option}>{option}</MenuItem>
          )}
        </Select>
      )
    }else if(this.props.reportType === 'eucalyptus-spec-productivity'){
      return(
        <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.productivity.cycle} onChange={this.props.handleChange} name="cycle">
          {EUCALYPTUS.CYCLE_OPTIONS.map(option => 
            <MenuItem value={option}>{option}</MenuItem>
          )}
        </Select>
      )
    }else{
      return(
        <Select inputProps={{name: 'cycle', id: 'cycle-input'}} className={"prod-months-dropdown"} value={this.props.productivity.cycle} onChange={this.props.handleChange} name="cycle">
          {SOY.CYCLE_OPTIONS.map(option => 
            <MenuItem value={option}>{option}</MenuItem>
          )}
        </Select>
      )
    }
  }

  render() {
    const dateThreshold = moment(this.props.productivity.date).add(this.props.productivity.cycle, this.props.reportType === "productivity-spec" ? "months" : "days")
    return(
      <Dialog open={this.props.open} onClose={this.props.noClose ? null : this.props.handleClose} maxWidth={'sm'} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
        <DialogTitle id="form-dialog-title">Configuração da produtividade específica</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <div>
              {this.props.reportType === 'productivity-spec' &&
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', flexWrap: 'wrap', width: '45%'}}>
                    <TextField
                      margin={"dense"}
                      name="cut_number"
                      type="text"
                      label={"Número do corte"}
                      value={this.props.productivity.cut_number}
                      onChange={this.props.handleChange}
                      InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                      className="text-fields"
                      FormHelperTextProps={{error: true, className: "input-error-text" }}
                      helperText={this.props.errors.cut_number ? this.props.errors.cut_number : ''}
                    />
                  </div>
                  <div style={{display: 'flex', flexWrap: 'wrap', width: '45%'}}>
                    <TextField
                      margin={"dense"}
                      name="kdec"
                      type="text"
                      label={"Kdec"}
                      value={this.props.productivity.kdec}
                      onChange={this.props.handleChange}
                      InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                      className="text-fields"
                      FormHelperTextProps={{error: true, className: "input-error-text" }}
                      helperText={this.props.errors.kdec ? this.props.errors.kdec : ''}
                    />
                  </div>
                </div>
              }
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                  name="eficiency"
                  type="text"
                  value={this.props.productivity.eficiency}
                  label={this.props.reportType === 'eucalyptus-spec-productivity' ? 'Eficiência Florestal' :  "Eficiência Agrícola (%)"}
                  onChange={ this.props.handleChange }
                  InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                  className="text-fields"
                  style={{width: '70%'}}
                  FormHelperTextProps={{error: true, className: "input-error-text" }}
                  helperText={this.props.errors.eficiency ? this.props.errors.eficiency : ''}
                />
                <FormControl>
                  <InputLabel htmlFor="cycle-input">Ciclo</InputLabel>
                  {this.setCycleOptions()}
                </FormControl>
              </div>
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <DatePicker
                  keyboard
                  placeholder={'DD/MM/AAAA'}
                  label={this.props.reportType === 'productivity-spec' ? this.props.productivity.cut_number > 1 ? "Data de brotação" : "Data de plantio" : "Data de plantio"}
                  format={"DD/MM/YYYY"}
                  margin="dense"
                  fullWidth
                  value={this.props.productivity.date}
                  onChange={this.props.handleDateChange}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  minDate={this.props.reportType === 'productivity-spec' ? moment('1982-01-01') : moment('1981-01-01')}
                  maxDate={['soy-productivity', 'productivity-spec'].includes(this.props.reportType) ? undefined : moment().add(1, 'year')}
                  InputProps={{disableUnderline: true, style: {width: "100%"}, error: true}}
                  FormHelperTextProps={{error: true, className: "input-error-text" }}
                  helperText={this.props.errors.date ? this.props.errors.date : ''}
                  mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                />
              </MuiPickersUtilsProvider>
              { this.props.reportType !== 'eucalyptus-spec-productivity' ? <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                  margin={"dense"}
                  name="cc_user"
                  type="text"
                  label={"Índice de colheita"}
                  value={this.props.productivity.cc_user}
                  onChange={this.props.handleChange}
                  InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                  className="text-fields"
                  style={{width: '45%'}}
                  FormHelperTextProps={{error: true, className: "input-error-text" }}
                  helperText={this.props.errors.cc_user ? this.props.errors.cc_user : ''}
                />
                <TextField
                  margin={"dense"}
                  name="u_percent_user"
                  type="text"
                  label={this.props.reportType === 'productivity-spec' ? "Umidade do colmo (%)" : "Umidade do grão (%)"}
                  value={this.props.productivity.u_percent_user}
                  onChange={this.props.handleChange}
                  InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                  className="text-fields"
                  style={{width: '47%'}}
                  FormHelperTextProps={{error: true, className: "input-error-text" }}
                  helperText={this.props.errors.u_percent_user ? this.props.errors.u_percent_user : ''}
                />
              </div>
              : 
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <FormControl>
                  <InputLabel htmlFor="range-input">Unidade</InputLabel>
                  <Select 
                    inputProps={{name: 'unity', id: 'range-input'}}
                    className={"prod-months-dropdown"}
                    style={{ width:`100%` }}
                    value={ this.props.productivity.unity }  //aqui!!
                    onChange={this.props.handleChange}
                    name="range"
                  >
                    <MenuItem value={'m³/ha'}>m³/ha</MenuItem>
                    <MenuItem value={'m³/ha/ano (IMA)'}>m³/ha/ano (IMA)</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel htmlFor="clone-input">Clone</InputLabel>
                  <Select
                    inputProps={{name: 'clone', id: 'clone-input'}}
                    className={"prod-months-dropdown"}
                    value={this.props.productivity.clone}
                    onChange={this.props.handleChange}
                    name="clone"
                  >
                    <MenuItem value={'Plastico'}>Plástico</MenuItem>
                    <MenuItem value={'Subtropical'}>Subtropical</MenuItem>
                    <MenuItem value={'Tropical'}>Tropical</MenuItem>
                  </Select>
                </FormControl>
              </div>}
              <TextField
                fullWidth
                margin={"dense"}
                name="obs_value"
                type="text"
                value={this.props.productivity.obs_value}
                disabled={!moment(this.props.productivity.date).isValid() || moment().add(30, "days") < dateThreshold}
                label={"Produtividade observada"}
                onChange={this.props.handleChange}
                InputProps={{disableUnderline: true, style: {width: "100%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                className="text-fields"
              />
              {!["wheat-spec-productivity", "eucalyptus-spec-productivity"].includes(this.props.reportType) &&
                <div style={{display: 'flex', justifyContent: 'space-between', fontSize: "1rem"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.productivity.frost}
                        onChange={this.props.handleChange}
                        name="frost"
                        style={{color: "green"}}
                      />
                    }
                    label="Penalização de Geada"
                  />
                </div>
              }
              {this.props.reportType === 'eucalyptus-spec-productivity' ?
                <div style={{display: 'flex', justifyContent: 'space-between', fontSize: "1rem"}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.productivity.bark}
                        onChange={this.props.handleChange}
                        name="bark"
                        style={{color: "green"}}
                      />
                    }
                    label="Proporção da Casca"
                  />
                  <TextField
                    name="tree_bark"
                    type="text"
                    value={this.props.productivity.tree_bark ? this.props.productivity.tree_bark : '' }
                    label="Proporção da casca (%)"
                    onChange={ this.props.handleChange }
                    InputProps={{disableUnderline: true, style: {width: "100%", textAlign:"right"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
                    className="text-fields"
                    disabled={this.props.productivity.bark ? false : true }
                    style={{width: '50%'}}
                    FormHelperTextProps={{error: true, className: "input-error-text" }}
                    helperText={this.props.errors.eficiency ? this.props.errors.eficiency : ''}
                  />
                </div>
              : null}
            </div>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.props.recalculate} style={{backgroundColor: "#6aa426", color: "white", marginRight: '8px'}}>
            {this.props.noClose ? "Calcular" : "Recalcular"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default SpecificProductivityOptions;
