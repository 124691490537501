import React, { Component } from 'react'
import { Router } from 'react-router'
import routes from '../routes'
import history from '../components/common/history'
import ReactGA from 'react-ga';

//Styles
import '../stylesheets/css/materialize.css'
import '../stylesheets/css/components/sidebar.css'
import '../stylesheets/css/components/navbar.css'
import '../stylesheets/css/components/app.css'
import '../stylesheets/css/common/common.css'
import '../stylesheets/css/components/radio.css'
import '../stylesheets/css/errorBoudaries/apiErrorBoundaries.css'
import '../stylesheets/css/components/login.css'
import '../stylesheets/css/components/admin.css'
import '../stylesheets/css/components/upload.css'

const trackingId = process.env.REACT_APP_TRACKING_ID

class App extends Component {
  constructor(props){
    super(props)
    ReactGA.initialize(trackingId);
  }

  render() {
    return(
      <Router history={history}>
        {routes}
      </Router>
    )
  }
}



export default App
