import { connect } from 'react-redux'

//actions
import { uploadData, getFarms, resetUpload} from '../actions/farms'
import { toggleLoader } from '../actions/global'

//components
import UploadPage from '../components/upload/UploadPage'

function mapStateToProps(state){
  return{
    application: state.Application.application,
    farms: state.farms.points,
    loader: state.global.loader,
    result: state.users.createResult,
    uploadStatus: state.farms.uploadStatus
  }
}

function mapDispatchToProps(dispatch){
  return{
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    uploadData: (params) => {
      dispatch(uploadData(params))
    },
    getFarms: (user) => {
      dispatch(getFarms(user))
    },
    resetUpload: () => {
      dispatch(resetUpload())
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UploadPage)
