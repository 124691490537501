import React, { useEffect, useState } from "react";
import {
  Button,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from "@material-ui/core";
import calendarTheme from "../../../../shared/Themes";
import {
  setFinalPeriod,
  setInitialPeriod,
} from "../../../../actions/GeneralCaneProductivityAction";
import { useDispatch, useSelector } from "react-redux";

import { civilMonths, agriculturalMonths } from '../../../../shared/utils/months';

export function Step2GeneralCaneModal({
  handleBack,
  handleNext,
}) {
  const [initalMonthsToSelect, setInitalMonthsToSelect] = useState([]);
  const [finalMonthsToSelect, setFinalMonthsToSelect] = useState([]);

  const dispatch = useDispatch();
  const { period, visualizationType } = useSelector(
    (state) => state.generalCaneProductivityReducer
  );

  const months = visualizationType === 'civil' ? civilMonths : agriculturalMonths;

  useEffect(() => {
    let months;

    if (visualizationType === 'civil') {
      months = civilMonths;
      dispatch(setInitialPeriod(0));
      dispatch(setFinalPeriod(11));
    } else {
      months = agriculturalMonths;
      dispatch(setInitialPeriod(6));
      dispatch(setFinalPeriod(5));
    }
    
    setInitalMonthsToSelect(months);
    setFinalMonthsToSelect(months);
  }, [visualizationType, dispatch]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "initialMonth") {
      dispatch(setInitialPeriod(value));

      const selectedIndex = months.findIndex((month) => month.id === value);
      const finalMonths = months.filter((_month, index) => index >= selectedIndex);

      setFinalMonthsToSelect(finalMonths);

      if (period.finalMonth < value) {
        dispatch(setFinalPeriod(value));
      }
    } else {
      dispatch(setFinalPeriod(value));
    }
  };

  return (
    <MuiThemeProvider theme={calendarTheme}>
      <div
        style={{
          width: "29%",
          margin: "30px 0 0 14.5%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <InputLabel
            style={{ width: "141px", fontSize: "12px", display: "block" }}
            shrink
            id="initialMonthLabel"
          >
            Período Inicial
          </InputLabel>
          <Select
            style={{ width: "100%" }}
            labelid="initialMonthLabel"
            id="initialMonth"
            name="initialMonth"
            value={period.initialMonth}
            onChange={handleChange}
          >
            {initalMonthsToSelect.map((month) => (
              <MenuItem
                value={month.id}
                key={`initalMonthsToSelect${month.id}`}
              >
                {month.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <InputLabel
            style={{ width: "141px", fontSize: "12px", display: "block" }}
            shrink
            id="finalMonthLabel"
          >
            Período Final
          </InputLabel>
          <Select
            style={{ width: "100%" }}
            labelid="finalMonthLabel"
            id="finalMonth"
            name="finalMonth"
            value={period.finalMonth}
            onChange={handleChange}
          >
            {finalMonthsToSelect.map((month) => (
              <MenuItem value={month.id} key={`finalMonthsToSelect${month.id}`}>
                {month.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "52px 16.5% 0 0",
        }}
      >
        <Button
          style={{
            color: "#707070",
            width: "170px",
            height: "33px",
          }}
          onClick={handleBack}
        >
          Cancelar
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(106, 164, 38)",
            color: "white",
            width: "170px",
            height: "33px",
          }}
          onClick={handleNext}
        >
          Próximo
        </Button>
      </div>
    </MuiThemeProvider>
  );
}
