export const PRODUCTIVITY = {
    EFFICIENCY: 70,
    CROP_YEAR: new Date().getFullYear(),
    DATE: '2009-01-01',
    INITIAL_MONTH: 1,
    FINAL_MONTH: 12,
    FROST: false,
    RECALC: false
}

export const CANE = {
    PLANTED_PROPORTION: 20,
    CYCLE: 12,
    CYCLE_OPTIONS: [10, 11, 12, 13, 14, 15, 16, 17, 18],
    KDEC: 0.22,
    TYPE: "plant",
    CUT_NUMBER: 1,
    CC: 0.77,
    U_PERCENT: 75
}

export const SOY = {
    CYCLE: 110,
    CYCLE_OPTIONS: [90, 100, 110, 120, 130, 140, 150],
    CC: 0.40,
    U_PERCENT: 13
}

export const CORN = {
    CYCLE: 130,
    CYCLE_OPTIONS: [100, 110, 120, 130, 140, 150],
    CC: 0.55,
    U_PERCENT: 15
}

export const COTTON = {
    CYCLE: 130,
    CYCLE_OPTIONS: [130, 140, 150, 160, 170],
    CC: 0.5,
    U_PERCENT: 7
}

export const WHEAT = {
    CYCLE: 100,
    CYCLE_OPTIONS: [100, 110, 120, 130, 140],
    CC: 0.5,
    U_PERCENT: 13
}

export const EUCALYPTUS = {
    CYCLE: 7,
    CYCLE_OPTIONS: [5, 6, 7],
    RANGE: "monthly",
    DATE: `${new Date().getFullYear() - 7}-01-01`,
    CLONE: "Plastico",
    UNITY: "m³/ha",
    BARK: false,
    TREE_BARK: 0,
}
