import ProductivityApi from '../api/productivity';
import { createGAEvent } from '../shared/utils/AnalyticsUtils'

import { resetGrid } from './map';

export const ON_GENERATE_PRODUCTIVITY_SUCCESS = 'ON_GENERATE_PRODUCTIVITY_SUCCESS';
export const SAVE_REQUEST_DATA = 'SAVE_REQUEST_DATA';
export const GET_REQUEST_DATA = 'GET_REQUEST_DATA';
export const SET_PRODUCTIVITY_UNDEFINED = 'SET_PRODUCTIVITY_UNDEFINED'
export const SAVE_SPECIFIC_PRODUCTIVITY_REQUEST_DATA = 'SAVE_SPECIFIC_PRODUCTIVITY_REQUEST_DATA'
export const SET_CHANGED_CAD_FLAG = 'SET_CHANGED_CAD_FLAG'
export const ON_GENERATE_EUCALYPTUS_PRODUCTIVITY_SUCCESS = 'ON_GENERATE_EUCALYPTUS_PRODUCTIVITY_SUCCESS'
export const SET_IS_GRAPHIC_LOADING_CANE = 'SET_IS_GRAPHIC_LOADING_CANE'

export function generateProductivity(data) {
  createGAEvent('productivity', '', '')
  return function (dispatch) {
    dispatch(saveRequestData(data));
    dispatch(setIsGraphicLoading());
    return ProductivityApi.generateProductivity(data)
      .then((response) => {
        dispatch(onGenerateProductivitySuccess(response));
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => dispatch(setIsGraphicLoading()));
  }
}


export function saveCustomizedProductivity(data) {
  createGAEvent('productivity', '', '')
  return function (dispatch) {
    dispatch(saveRequestData(data));
    return ProductivityApi.saveCustomizedData(data)
      .catch(error => { throw (error) })
  }
}

export function getCustomizedProductivity(data) {
  return function (dispatch) {
    return ProductivityApi.getCustomizedData(data)
      .then(response => {
        dispatch(getRequestData(response))})
      .catch(error => { throw (error) })
  }
}

export function generateSpecificProductivity(data) {
  createGAEvent('productivity-spec', '', '')

  return function (dispatch) {
    dispatch(saveSpecificProductivityRequestData(data));

    return ProductivityApi.generateSpecificProductivity(data)
      .then(response => { dispatch(onGenerateProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}

export function generateSpecificEucalyptusProductivity(data, type) {
  createGAEvent('eucalyptus-spec-productivity', '', '')
  return function (dispatch) {
    dispatch(saveRequestData(data));
    dispatch(resetGrid());

    return ProductivityApi.generateEucalyptusProductivity(data, type)
      .then(response => {
        dispatch(onGenerateEucalyptusProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}

export function generateSoyProductivity(data, type) {
  let reportName = type === 'general' ? 'soy-general-productivity' : 'soy-productivity'
  createGAEvent(reportName, '', '')

  return function (dispatch) {
    dispatch(saveSpecificProductivityRequestData(data));
    dispatch(resetGrid());

    return ProductivityApi.generateSoyProductivity(data, type)
      .then(response => {
        dispatch(onGenerateProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}

export function generateCornProductivity(data, type) {
  let reportName = type === 'general' ? 'corn-general-productivity' : 'corn-spec-productivity'
  createGAEvent(reportName, '', '')

  return function (dispatch) {
    dispatch(saveSpecificProductivityRequestData(data));
    dispatch(resetGrid());

    return ProductivityApi.generateCornProductivity(data, type)
      .then(response => {
        dispatch(onGenerateProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}

export function generateCottonProductivity(data, type) {
  let reportName = type === 'general' ? 'cotton-general-productivity' : 'cotton-spec-productivity'
  createGAEvent(reportName, '', '')

  return function (dispatch) {
    dispatch(saveSpecificProductivityRequestData(data));
    dispatch(resetGrid());

    return ProductivityApi.generateCottonProductivity(data, type)
      .then(response => {
        dispatch(onGenerateProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}

export function generateWheatProductivity(data, type) {
  let reportName = type === 'general' ? 'wheat-general-productivity' : 'wheat-spec-productivity'
  createGAEvent(reportName, '', '')

  return function (dispatch) {
    dispatch(saveSpecificProductivityRequestData(data));
    dispatch(resetGrid());

    return ProductivityApi.generateWheatProductivity(data, type)
      .then(response => {
        dispatch(onGenerateProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}


export function generateEucalyptusProductivity(data, type) {
  let reportName = type === 'general' ? 'eucalyptus-general-productivity' : 'eucalyptus-spec-productivity'
  createGAEvent(reportName, '', '')

  return function (dispatch) {
    dispatch(saveRequestData(data));
    dispatch(resetGrid());

    return ProductivityApi.generateEucalyptusProductivity(data, type)
      .then(response => {
        dispatch(onGenerateEucalyptusProductivitySuccess(response)) })
      .catch(error => { throw (error) })
  }
}

export function setProductivityUndefined() {
  return { type: SET_PRODUCTIVITY_UNDEFINED }
}

export function onGenerateProductivitySuccess(data) {
  return { type: ON_GENERATE_PRODUCTIVITY_SUCCESS, payload: data };
}

export function setIsGraphicLoading() {
  return { type: SET_IS_GRAPHIC_LOADING_CANE };
}

export function onGenerateEucalyptusProductivitySuccess(data) {
  return { type: ON_GENERATE_EUCALYPTUS_PRODUCTIVITY_SUCCESS, payload: data };
}

export function saveRequestData(data) {
  return { type: SAVE_REQUEST_DATA, payload: data };
}

export function getRequestData(data) {
  return { type: GET_REQUEST_DATA, payload: data };
}


export function saveSpecificProductivityRequestData(data) {
  return { type: SAVE_SPECIFIC_PRODUCTIVITY_REQUEST_DATA, payload: data };
}

export function setChangedCadFlag(value) {
  return { type: SET_CHANGED_CAD_FLAG, payload: value}
}
