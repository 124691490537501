import { authHeader } from './config'

class FloweringRiskApi {
    static getFloweringRisk(data) {
        return fetch(process.env.REACT_APP_API + `api/flowering_risk?farm_id=${data.farmId}&prediction_days=${data.predictionDays}&year=${data.year}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }
}

export default FloweringRiskApi