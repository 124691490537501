const initialState = {
    historyList: [],
    detailed: [],
    specific: [],
    detailedList: []
}

export default (state = initialState, action) => {
    switch(action.type) {
        case "GET_USER_NOTIFICATIONS":
            return Object.assign({}, state, { historyList: action.response.all, detailedList: action.response.new })
        case "GET_ADMIN_NOTIFICATIONS":
            return Object.assign({}, state, { historyList: action.response.data, pagination: action.response.pagination })
        case "GET_DETAILED_NOTIFICATION":
            return Object.assign({}, state, { detailed: action.response.data })
        case "GET_DETAILED_NOTIFICATION_LIST":
            let list = []
            list.push(action.response.specific[0])

            return Object.assign({}, state, { specific: list })
            case "RESET_SPECIFIC_DATA":
                return Object.assign({}, state, { specific: [] })
        case "RESET_DATA":
            return Object.assign({}, state, { historyList: [] })
        case "RESET_DETAILED":
            return Object.assign({}, state, { detailed: [] })
        default:
            return state
    }
}