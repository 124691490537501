import '../../stylesheets/css/components/radio.css'

import React, { Component } from 'react'

export default class RadioButtonGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      optionsVal: this.props.checkedOption?.toString(),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      optionsVal: nextProps.checkedOption
    })
  }

  handleQueryOpChange(e, text) {
    this.setState({
      optionsVal: e.target.value
    })
    this.props.selectedItemCallback(e.target.value, text)
  }

  setSelected(item) {
    return this.props.label
      ? Number(this.state.optionsVal) === item.value && this.props.label === item.text
      : Number(this.state.optionsVal) === item.value;
  }

  render() {
    
    const renderItems = (item) => {
      return (
          <label
            key={item.text}
            className={
              'btn-item radio-button-item ' +
              (this.state.optionsVal === item.value ? 'checked' : 'not-checked')
            }
          >
            <input
              type="radio"
              name={this.props.name}
              disabled={this.props.allDisabled}
              value={item.value}
              checked={this.setSelected(item) || this.state.optionsVal === item.value}
              onChange={(e) => this.handleQueryOpChange(e, item.text)}
              id={item.value}
            />
            <span className="radio-dot"></span>
            <span className="radio-button-item-word">{item.text}</span>
          </label>
        );
    };

    return (
      <div className='radio-button-group'>
        {this.props.listOfItems.map((item) => renderItems(item))}
      </div>
    );
  }
}
