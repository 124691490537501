import React, { Component } from 'react'
import {Grid, Paper} from '@material-ui/core'


class UploadInfo extends Component {
  render() {
    return(
      <div className="upload-exp">
        <h5 className='title green-bold'>Como fazer?</h5>
        <ol>
          <li>Selecione acima o ponto previamente cadastrado para o qual você deseja fazer o upload de seus dados.</li>
          <li>Selecione em seu computador o arquivo do tipo <b className='green-bold'>.xlsx</b> previamente preparado com os seus dados meteorológicos, 
              seguindo exatamente este modelo de arquivo:
              <a className='green-bold' href="/example.xlsx" target="_blank"> Modelo</a>
          </li>
        </ol>
        <h5 className='title green-bold'>Sobre o arquivo</h5>
        <p>
          A ordem das colunas do arquivo a ser anexado e enviado ao sistema deve ser a mesma do modelo disponibilizado acima,
          ou seja, data no formato <b className='green-bold'>AAAA-MM-DD (2018-08-21)</b>, com os seguintes dados:
        </p>
        <Grid container justify='space-between'>
          <Grid item md={6}>
            <ul>
              <li><b className='tab'>Tmax </b>= temperatura máxima<b> (°C)</b></li>
              <li><b className='tab'>Tmin </b>= temperatura mínima<b> (°C)</b></li>
              <li><b className='tab'>P    </b>= precipitação<b> (mm)</b></li>
              <li><b className='tab'>U2   </b>= velocidade média do vento a 2 metros<b> (m/s)</b></li>
              <li><b className='tab'>UR   </b>= umidade relativa<b> (%)</b></li>
              <li><b className='tab'>Qg   </b>= radiação solar<b> (MJ/m2dia)</b></li>
              <li><b className='tab'>U10  </b>= velocidade média do vento a 10 metros<b> (m/s)<span className='green-bold'> *</span></b></li>
            </ul>
          </Grid>
          <Grid item md={5}>
            <Paper className='side-panel'>
              <p><span className='green-bold'>*</span> A velocidade do vento a 10 m somente deverá ser inserida caso os dados dessa variável a 2 m não esteja disponível.
              No caso de ausência de algum dado, a célula correspondente a ele deverá estar vazia. Se houver ausência de todos os dados,
                as células deverão estar vazias, porém a data deverá ser mantida, ou seja, é obrigatório se reportar todos os dias.</p>
            </Paper>
          </Grid>
        </Grid>
      
        <p>Os dados enviados serão processados e caso ocorra algum erro o sistema retornará com um aviso.</p>

        <h5 className='title green-bold'>Atualização do Banco de Dados</h5>

        <p>Para atualizar o banco é preciso adicionar apenas os novos dados. Todos os dados já inseridos ficam registrados no sistema e não devem ser inseridos novamente.</p>

        <Grid container justify='space-evenly' alignContent='center' className='footer'>
          <Grid item md={3}>
            <i className='upload-icon material-icons'>security</i>
            <div>Todos os dados inseridos são de uso exclusivo do usuário,
               sendo em nenhum momento divulgado para outros usuários do sistema Agrymax.</div>
          </Grid>
          <Grid item md={3}>
            <i className='upload-icon material-icons'>contact_support</i>
            <div>Qualquer dúvida entre em contato com nosso suporte</div>
            <div>E-mail/Skype: contato@agrymet.com.br</div> 
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default UploadInfo
