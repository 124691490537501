import React from 'react';

import '../stylesheets/css/email_authorization.css';

function EmailAuthorization({ location }) {
  const { search } = location;

  return (
    <div className="main-container">
      <header>
        <img
          className="header-image"
          src="http://cdn.mcauto-images-production.sendgrid.net/932a47a2ed8f2d25/fb82256c-65a5-48ea-9ed8-342bf1770eb4/912x226.png"
          alt="Agrymax Logo"
        />
      </header>
      <main>
        <div className="message-container">
          <h2 className="subscription">
            Inscrição do Relatório Agrometeorológico confirmada!
          </h2>
          <p>
            Você vai receber periodicamente em seu email os gráficos para compor o seu Relatório Agrometeorológico.
          </p>
        </div>
        <div className="social-container">
          <div>
            <a href="https://www.facebook.com/agrymet/">
              <img
                className="icon-image"
                src="https://mc.sendgrid.com/assets/social/white/facebook.png"
                alt="facebook logo"
              />
            </a>
            <a href="https://instagram.com/agrymet?igshid=ymmymta2m2y=">
              <img
                className="icon-image"
                src="https://mc.sendgrid.com/assets/social/white/instagram.png"
                alt="facebook logo"
              />
            </a>
            <a href="https://www.linkedin.com/company/agrymet/">
              <img
                className="icon-image"
                src="https://mc.sendgrid.com/assets/social/white/linkedin.png"
                alt="facebook logo"
              />
            </a>
          </div>
          <div className="info-container">
            <h4>Agrymet</h4>
            <p>Rua Cezira Giovanoni Moretti, 955 - Santa Rosa Ipes, Sala 610 - Piracicaba/SP</p>
          </div>
        </div>
      </main>
      <footer>
        <p>
          <a className='unsubscribe' href={`/api/email_unsubscribe${search}`}>
            { 'Unsubscribe ' }
          </a>
          -
          <a className="unsubscribe" href={`/api/email_unsubscribe${search}`}>
            {' Unsubscribe Preferences'}
          </a>
        </p>
      </footer>
    </div>
  );
}

export default EmailAuthorization;
