import React, { Component } from 'react'
import classnames from 'classnames'

class TypesOption extends Component {

  render() {
    return(
      <div id={this.props.label} className='type-option col s12' onMouseEnter={this.props.onHover}>
        <input id={ classnames('reportType_' + this.props.label) } name='reportType' type='radio' onClick={this.props.onClick} value={ this.props.value } onChange={ this.props.onChange } />
        <label htmlFor={ classnames('reportType_' + this.props.label) } className={ classnames('option--container', { 'disabled': this.props.disabled, 'active': this.props.reports > -1 }) }>
          <h6 className='option--container-title'>{ this.props.label }</h6>
        </label>
      </div>
    )
  }
}

export default TypesOption
