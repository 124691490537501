import React from "react";
import classnames from "classnames";

const InputField = ({
  name,
  value,
  label,
  error,
  type,
  onChange,
  active,
  activeFarm = "",
  onFocus = null,
  loginInputActive = false,
  onBlur = null,
  labelActive = false,
  placeholder = "",
  length = 100,
  disabled = undefined,
  pattern = undefined,
  title = undefined,
}) => {
  if (value !== "") {
    if (name === "LAT") {
      value = value.split(",")[0];
    } else if (name === "LNG") {
      value = value.split(",")[1];
    }
  }

  return (
    <div className={classnames("input-field",{"input-active": loginInputActive},
                                {"col s8 ": name === 'name'},{"col s4 ": name === 'initials'},
                                {"col s5": (name === 'LAT' && activeFarm !== '') || (name === 'LNG' && activeFarm !== '')},
                                {"col s6": (name === 'LAT' && activeFarm === '') || (name === 'LNG' && activeFarm === '') || (name === "eficiency" || name === "cane") })}>
      <input
        name={name}
        title={title}
        pattern={pattern}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        className={classnames(
          "validate",
          { invalid: error },
          { valid: active && !error }
        )}
        id={name}
        disabled={disabled ? disabled : activeFarm !== "" ? false : true}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={length}
        autoComplete="on"
      />
      <label
        className={classnames({ active: active })}
        htmlFor={name}
        data-error={error}
      >
        {label}
      </label>
      {name === "initials" ? (
        labelActive === true ? (
          <label className="length-label">máx 4 caracteres</label>
        ) : null
      ) : null}
    </div>
  );
};

export default InputField;
