import { authHeader } from "./config";
import axios from 'axios';

export default class AdminApi {
  static getAllUsers() {
    return fetch(process.env.REACT_APP_API + "api/admin/users", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static setUserStatus(user) {
    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");
    return fetch(
      process.env.REACT_APP_API + `api/admin/user_status?user=${user}`,
      {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static editUser(user, password) {
    return fetch(process.env.REACT_APP_API + "api/admin/user_edit", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        user: user.id,
        agrymax_plan: user.agrymax_plan,
        email: user.email,
        cpf_cnpj: user.cpf_cnpj,
        address: user.address,
        city: user.city,
        uf: user.uf,
        password: password,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static createApiKey(user) {
    return fetch(process.env.REACT_APP_API + `api/admin/user/api_key`, {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        user_id: user,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static deleteApiKey(user) {
    return fetch(process.env.REACT_APP_API + `api/admin/user/api_key`, {
      method: "DELETE",
      headers: authHeader(),
      body: JSON.stringify({
        user_id: user,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static setApiDates(data) {
    return fetch(process.env.REACT_APP_API + `api/admin/user/api_key`, {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        user_id: data.user,
        initial_api_year: data.fromYear,
        final_api_year: data.toYear,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static downloadData(points, variables, initialDate, finalDate) {
    return fetch(process.env.REACT_APP_API + "api/admin/download_data", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        points: points,
        variables: variables,
        initial_date: initialDate,
        final_date: finalDate,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static downloadProductivityData(data) {
    let path;

    let auxPointStringForEndpoint = "";
    if (data.idsFarmsOfCheckbox.length !== 0) {
      data.idsFarmsOfCheckbox.map(
        (id) => (auxPointStringForEndpoint += `&points_ids=${id}`)
      );
    } else {
      data.points.map(
        (point) =>
          (auxPointStringForEndpoint += `coords=${point.lat},${point.lng}&`)
      );
    }

    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");

    switch (data.type) {
      case "cane":
        path =
          data.idsFarmsOfCheckbox.length !== 0
            ? `api/admin/cane_productivity?cad=${data.cad}&date=${
                data.date
              }&cc_user=${data.cc_user}&u_percent_user=${
                data.u_percent_user
              }&agry_eficiency=${data.agry_eficiency}&kdec=${data.kdec}&cycle=${
                data.cycle
              }&cut_number=${data.cut_number}&cane_type=${
                data.cane_type + data.years
              }${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}${auxPointStringForEndpoint}`
            : `api/admin/cane_productivity?${auxPointStringForEndpoint}cad=${
                data.cad
              }&date=${data.date}&cc_user=${data.cc_user}&u_percent_user=${
                data.u_percent_user
              }&agry_eficiency=${data.agry_eficiency}&kdec=${data.kdec}&cycle=${
                data.cycle
              }&cut_number=${data.cut_number}&cane_type=${
                data.cane_type + data.years
              }${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}`;
        break;
      case "soy":
        path =
          data.idsFarmsOfCheckbox.length !== 0
            ? `api/admin/soy_productivity?cad=${data.cad}&planting_date=${
                data.date
              }&cc_user=${data.cc_user}&u_percent_user=${
                data.u_percent_user
              }&agry_eficiency=${data.agry_eficiency}&cycle=${
                data.cycle + data.years
              }${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}${auxPointStringForEndpoint}`
            : `api/admin/soy_productivity?${auxPointStringForEndpoint}cad=${
                data.cad
              }&planting_date=${data.date}&cc_user=${
                data.cc_user
              }&u_percent_user=${data.u_percent_user}&agry_eficiency=${
                data.agry_eficiency
              }&cycle=${data.cycle + data.years}${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}`;
        break;
      case "corn":
        path =
          data.idsFarmsOfCheckbox.length !== 0
            ? `api/admin/corn_productivity?cad=${data.cad}&planting_date=${
                data.date
              }&cc_user=${data.cc_user}&u_percent_user=${
                data.u_percent_user
              }&agry_eficiency=${data.agry_eficiency}&cycle=${
                data.cycle + data.years
              }${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}${auxPointStringForEndpoint}`
            : `api/admin/corn_productivity?${auxPointStringForEndpoint}cad=${
                data.cad
              }&planting_date=${data.date}&cc_user=${
                data.cc_user
              }&u_percent_user=${data.u_percent_user}&agry_eficiency=${
                data.agry_eficiency
              }&cycle=${data.cycle + data.years}${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}`;
        break;
      case "cotton":
        path =
          data.idsFarmsOfCheckbox.length !== 0
            ? `api/admin/cotton_productivity?cad=${data.cad}&planting_date=${
                data.date
              }&cc_user=${data.cc_user}&u_percent_user=${
                data.u_percent_user
              }&agry_eficiency=${data.agry_eficiency}&cycle=${
                data.cycle + data.years
              }${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}${auxPointStringForEndpoint}`
            : `api/admin/cotton_productivity?${auxPointStringForEndpoint}cad=${
                data.cad
              }&planting_date=${data.date}&cc_user=${
                data.cc_user
              }&u_percent_user=${data.u_percent_user}&agry_eficiency=${
                data.agry_eficiency
              }&cycle=${data.cycle + data.years}${
                data.point_id !== undefined ? "&point_id=" + data.point_id : ""
              }&frost_flag=${data.frost}`;
        break;
      default:
        break;
    }

    return fetch(process.env.REACT_APP_API + path, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static downloadGeneralProductivityData(data) {
    const cropYear = `crop_year=${data.cropYear}`;
    const visualizationType = `&visualization_type=${data.visualizationType}`;
    const points = data.points.map((point) => `&points=${point}`).join('');

    const path = `/api/admin/productivity/sugarcane/average?${cropYear}${visualizationType}${points}`;

    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");

    return fetch(process.env.REACT_APP_API + path, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static downloadIndexData(data) {
    let auxPointStringForEndpoint = "",
      endpoint = "";
    if (data.idsFarmsOfCheckbox.length !== 0) {
      data.idsFarmsOfCheckbox.map(
        (id) => (auxPointStringForEndpoint += `&points_ids=${id}`)
      );
    } else {
      data.points.map((point) => {
        return (auxPointStringForEndpoint += `coords=${point.lat},${point.lng}&`);
      });
    }

    endpoint =
      data.idsFarmsOfCheckbox.length !== 0
        ? `api/admin/agryindex?cad=${data.cad}&initial_year=${data.initial_year}&final_year=${data.final_year}&variable=${data.variable}&soil_texture=${data.soil_texture}${auxPointStringForEndpoint}`
        : `api/admin/agryindex?${auxPointStringForEndpoint}cad=${data.cad}&initial_year=${data.initial_year}&final_year=${data.final_year}&variable=${data.variable}&soil_texture=${data.soil_texture}`;

    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");

    return fetch(process.env.REACT_APP_API + endpoint, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getEnosList() {
    return fetch(process.env.REACT_APP_API + "api/admin/enos", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static addEnos(newEnos) {
    return fetch(process.env.REACT_APP_API + "api/admin/enos", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        year: newEnos.year,
        civil_year: newEnos.civil_year,
        agri_year: newEnos.agri_year,
        civil_sem: newEnos.civil_sem,
        agri_sem: newEnos.agri_sem,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static editEnos(newEnos) {
    return fetch(process.env.REACT_APP_API + "api/admin/enos", {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        year: newEnos.year,
        civil_year: newEnos.civil_year,
        agri_year: newEnos.agri_year,
        civil_sem: newEnos.civil_sem,
        agri_sem: newEnos.agri_sem,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getProductList(data) {
    return fetch(
      process.env.REACT_APP_API +
        `api/admin/user/points?user_id=${data.user_id}&product=${data.product}`,
      {
        method: "GET",
        headers: authHeader(),
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static getSmartListForCreateUser() {
    return fetch(process.env.REACT_APP_API + `api/smarts`, {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static modifyProductAccess(data) {
    return fetch(process.env.REACT_APP_API + `api/admin/user/points`, {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        user_id: data.user_id,
        points_add: data.addPoints,
        points_delete: data.deletePoints,
        product: data.product,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static modifyPointLimit(data) {
    return fetch(
      process.env.REACT_APP_API + `api/admin/user/update_max_points`,
      {
        method: "PUT",
        headers: authHeader(),
        body: JSON.stringify({
          user_id: data.user_id,
          product: data.product,
          max: data.max,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static adminGetPoints(user) {
    return fetch(
      process.env.REACT_APP_API + `api/admin/user/plan_points?user_id=${user}`,
      {
        method: "GET",
        headers: authHeader(),
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static adminUpdatesPoints(data) {
    return fetch(process.env.REACT_APP_API + `api/admin/user/plan_points`, {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        user_id: data.user_id,
        points_add: data.points_add ? data.points_add : [],
        points_delete: data.points_delete ? data.points_delete : [],
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static async getUserTokenToAcessUserAccountWithAdmin(id) {
    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");
    const tokenUser = await fetch(
      process.env.REACT_APP_API + `api/admin/user/token?id=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
    localStorage.clear();
    window.open("/", "_blank");
    localStorage.setItem("Token", tokenUser.authentication_token);
  }

  static async transferPoints(data) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}api/admin/user/transfer`,
        {
          method: 'POST',
          headers: authHeader(),
          body: JSON.stringify(data),
        }
      );
      const { message } = await response.json();
      return message;
    } catch (error) {
      return error
    }
  }

  static async exportIntegrationData(form) {
    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");
    
    const { integration, user_id, start_date, end_date, additional_points } = form;
    const points = additional_points.map((point) => `&points=${point}`).join(''); 
    const query = `user_id=${user_id}&start_date=${start_date}&end_date=${end_date}&integration=${integration}${points}`;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API}api/admin/export/integrations?${query}`,
        {
          method: 'GET',
          headers: { Authorization: "Bearer " +  token}
        },
      );
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getUserIntegrations(id) {
    const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");
  
    const response = await fetch(
      `${process.env.REACT_APP_API}api/admin/export/options?user_id=${id}`,
      { method: 'GET', headers: { Authorization: "Bearer " +  token} },
    );
    const integrations = await response.json();

    return integrations;
  }
}

export const getUserPremiumSmarts = async (id) => {
  const END_POINT = process.env.REACT_APP_API + 'api/admin/user/smarts?user_id=' + id;
  try {
    const data = await axios.get(END_POINT, {
      headers: authHeader('axios')
    });
    return data;
  } catch (error) {
  }
};

export const putUserPremiumSmarts = async (id, authorizedSmarts) => {
  return fetch(process.env.REACT_APP_API + 'api/admin/user/smarts?user_id=' + id, {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({
      user_id: id,
      authorized: authorizedSmarts,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return error;
    });
};

export const downloadSpreadsheet = async () => {
  return fetch(process.env.REACT_APP_API + "api/admin/station-data-template", {
    method: "GET",
    headers: authHeader(),
  })
    .then((response) => {
        response.blob().then((blob) => {
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'validated_data_formatted.xlsx'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      });
      const data = response;
      return data;
    })
    .catch((error) => {
      return error;
    });
}

export const uploadSpreadsheet = async (newFile) => {
  const token = localStorage.getItem("Token")
      ? localStorage.getItem("Token")
      : sessionStorage.getItem("Token");
    try {
      const url = `${process.env.REACT_APP_API}api/admin/station-data`;
      const formData = new FormData();
      formData.append('file',newFile)
      formData.append('station_provider', 'inmet')
      const config = {
          headers: {
              'Authorization': "Bearer " +  token,
              'content-type': 'multipart/form-data'
          }
      }
      const response = await axios.post(url, formData, config);
      return response;
    } catch (error) {
    }
}
