import React, { Component } from 'react'

class SpecificProductivityBoxplot extends Component {
  
  getYAxisLimit = () => {
    let values = []
    for(let i = 0; i < this.props.currentCropBoxplotValues.length; i++){
      values.push(Math.max(...this.props.currentCropBoxplotValues[i]))
    }

    if (this.props.reportType !== "eucalyptus-spec-productivity" || !this.props.reportType){
      values.push(Math.max(...this.props.pastCropValues1))
    }
    

    return Math.max(...values)
  }

  shouldComponentUpdate(nextProps) {
    let newData = Object.assign({}, nextProps)
    let currentData = Object.assign({}, this.props)

    delete newData.title
    delete currentData.title

    if(JSON.stringify(newData) === JSON.stringify(currentData)) {
      return false
    }

    return true
  }

  componentDidMount() {
    let {currentCrop} = this.props
    let {unit} = this.props
    
    if (this.props.reportType !== "eucalyptus-spec-productivity" || !this.props.reportType){
      window.Highcharts.chart('boxplot', {
        chart: {
          type: 'boxplot',
          style: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '1em'
          }
        },
  
        tooltip: {
          enabled: this.props.showTooltips,
          useHTML: true,
          shared: false,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          style: {
            fontSize: '15px'
          },
          formatter: function() {
            var text = '';
            if(this.series.name === currentCrop) {
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/>Limite superior: ${this.point.high} ${unit}<br/>Terceiro quartil: ${this.point.q3} ${unit}<br/>Mediana: ${this.point.median} ${unit}<br/>Primeiro quartil: ${this.point.q1} ${unit}<br/>Limite inferior: ${this.point.low} ${unit}<br/>`
            }
            else{
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/> Produtividade: ${this.point.y} ${unit}`
            }
            return text;
          }
        },
  
        title: {
          text: this.props.title,
          style: {
            color: "#000000",
            fontSize: "1.4rem",
            fontWeight: "400",
          }
        },
  
        legend: {
            enabled: true
        },
  
        credits: {
          enabled: false
        },
  
        xAxis: {
            categories: this.props.keys,
            title: {
                text: ''
            },
            labels: {
              style: {
                fontSize: '15px'
              }
            }
        },
  
        yAxis: {
          max: this.getYAxisLimit(),
          title: {
            text: this.props.unitText,
            style: {
              color: 'black'
            }
          },
          labels: {
            style: {
              font: '1em Roboto, sans-serif'
            }
          }
        },
  
        plotOptions: {
          boxplot: {
            color: '#000',
            fillColor: '#FF0000',
            lineWidth: 1,
            medianColor: '#000',
            medianWidth: 1,
            stemColor: '#000',
            stemWidth: 1,
            whiskerColor: '#000',
            whiskerLength: '20%',
            whiskerWidth: 1,
            maxPointWidth: 20
          },
        },
  
        labels: {
          style: {
            fontSize: '15px'
          }
        },
  
        series: [{
          name: this.props.currentCrop,
          data: this.props.currentCropBoxplotValues,
          showInLegend: false
        }, {
          name: this.props.pastCropName1,
          color: window.Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: this.props.pastCropValues1,
          marker: {
            fillColor: '#7CB5EC',
            lineWidth: 1,
            lineColor: 'black',
            radius: 6
          }
        },
        {
          name: this.props.currentCrop,
          color: window.Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: [],
          marker: {
            fillColor: 'red',
            lineWidth: 1,
            lineColor: 'black',
            radius: 6,
            symbol: 'circle'
          }
        }
      ]
    });
  } else {
      const { cycle } = this.props;
      window.Highcharts.chart('boxplot', {
        chart: {
          type: 'boxplot',
          style: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '1em'
          }
        },
        
        tooltip: {
          enabled: this.props.showTooltips,
          useHTML: true,
          shared: false,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          style: {
            fontSize: '15px'
          },
          formatter: function() {
            var text = '';
            if(this.series.name === currentCrop) {
              text = `<span style="color:${this.point.color}">●</span> 
              <b> ${this.series.name}</b>
              <br/>Limite superior: ${parseFloat(this.point.high / cycle).toFixed(2)} ${unit}<br/>
              Terceiro quartil: ${parseFloat(this.point.q3 / cycle).toFixed(2)} ${unit}<br/>
              Mediana: ${parseFloat(this.point.median / cycle).toFixed(2)} ${unit}<br/>
              Primeiro quartil: ${parseFloat(this.point.q1 / cycle).toFixed(2)} ${unit}<br/>
              Limite inferior: ${parseFloat(this.point.low / cycle).toFixed(2)} ${unit}<br/>`
            }
            else{
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/> Produtividade: ${this.point.y} ${unit}`
            }
            return text;
          }
        },
  
        title: {
          text: this.props.title,
          style: {
            color: "#000000",
            fontSize: "1.4rem",
            fontWeight: "400",
          }
        },
  
        legend: {
            enabled: true
        },
  
        credits: {
          enabled: false
        },
  
        xAxis: {
            categories: this.props.keys,
            title: {
                text: ''
            },
            labels: {
              style: {
                fontSize: '15px'
              }
            }
        },
  
        yAxis: {
          max: this.getYAxisLimit(),
          title: {
            text: this.props.unitText,
            style: {
              color: 'black'
            }
          },
          labels: {
            style: {
              font: '1em Roboto, sans-serif'
            }
          }
        },
  
        plotOptions: {
          boxplot: {
            color: '#000',
            fillColor: '#FF0000',
            lineWidth: 1,
            medianColor: '#000',
            medianWidth: 1,
            stemColor: '#000',
            stemWidth: 1,
            whiskerColor: '#000',
            whiskerLength: '20%',
            whiskerWidth: 1,
            maxPointWidth: 20
          },
        },
  
        labels: {
          style: {
            fontSize: '15px'
          }
        },
  
        series: [{
          name: this.props.currentCrop,
          data: this.props.currentCropBoxplotValues,
          showInLegend: false
        }, 
        {
          name: this.props.currentCrop,
          color: window.Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: [],
          marker: {
            fillColor: 'red',
            lineWidth: 1,
            lineColor: 'black',
            radius: 6,
            symbol: 'circle'
          }
        }
        ]
      });
    }
  }

  componentDidUpdate() {
    let currentCrop = this.props.currentCrop
    let unit = this.props.unit

    if (this.props.reportType !== "eucalyptus-spec-productivity" || !this.props.reportType){
      window.Highcharts.chart('boxplot', {
        chart: {
          type: 'boxplot',
          style: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '1em'
          }
        },
  
        tooltip: {
          enabled: this.props.showTooltips,
          useHTML: true,
          shared: false,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          style: {
            fontSize: '15px'
          },
          formatter: function() {
            var text = '';
            if(this.series.name === currentCrop) {
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/>Limite superior: ${this.point.high} ${unit}<br/>Terceiro quartil: ${this.point.q3} ${unit}<br/>Mediana: ${this.point.median} ${unit}<br/>Primeiro quartil: ${this.point.q1} ${unit}<br/>Limite inferior: ${this.point.low} ${unit}<br/>`
            }
            else{
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/> Produtividade: ${this.point.y} ${unit}`
            }
            return text;
          }
        },
  
        title: {
          text: this.props.title,
          style: {
            color: "#000000",
            fontSize: "1.4rem",
            fontWeight: "400",
          }
        },
  
        legend: {
            enabled: true
        },
  
        credits: {
          enabled: false
        },
  
        xAxis: {
            categories: this.props.keys,
            title: {
                text: ''
            },
            labels: {
              style: {
                fontSize: '15px'
              }
            }
        },
  
        yAxis: {
          max: this.getYAxisLimit(),
          title: {
            text: this.props.unitText,
            style: {
              color: 'black'
            }
          },
          labels: {
            style: {
              font: '1em Roboto, sans-serif'
            }
          }
        },
  
        plotOptions: {
          boxplot: {
            color: '#000',
            fillColor: '#FF0000',
            lineWidth: 1,
            medianColor: '#000',
            medianWidth: 1,
            stemColor: '#000',
            stemWidth: 1,
            whiskerColor: '#000',
            whiskerLength: '20%',
            whiskerWidth: 1,
            maxPointWidth: 20
          },
        },
  
        labels: {
          style: {
            fontSize: '15px'
          }
        },
  
        series: [{
          name: this.props.currentCrop,
          data: this.props.currentCropBoxplotValues,
          showInLegend: false
        }, {
          name: this.props.pastCropName1,
          color: window.Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: this.props.pastCropValues1,
          marker: {
            fillColor: '#7CB5EC',
            lineWidth: 1,
            lineColor: 'black',
            radius: 6
          }
        },
        {
          name: this.props.currentCrop,
          color: window.Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: [],
          marker: {
            fillColor: 'red',
            lineWidth: 1,
            lineColor: 'black',
            radius: 6,
            symbol: 'circle'
          }
        }
        ]
      });
    } else {
      window.Highcharts.chart('boxplot', {
        chart: {
          type: 'boxplot',
          style: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '1em'
          }
        },
  
        tooltip: {
          enabled: this.props.showTooltips,
          useHTML: true,
          shared: false,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          style: {
            fontSize: '15px'
          },
          formatter: function() {
            var text = '';
            if(this.series.name === currentCrop) {
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/>Limite superior: ${this.point.high} ${unit}<br/>Terceiro quartil: ${this.point.q3} ${unit}<br/>Mediana: ${this.point.median} ${unit}<br/>Primeiro quartil: ${this.point.q1} ${unit}<br/>Limite inferior: ${this.point.low} ${unit}<br/>`
            }
            else{
              text = `<span style="color:${this.point.color}">●</span> <b> ${this.series.name}</b><br/> Produtividade: ${this.point.y} ${unit}`
            }
            return text;
          }
        },
  
        title: {
          text: this.props.title,
          style: {
            color: "#000000",
            fontSize: "1.4rem",
            fontWeight: "400",
          }
        },
  
        legend: {
            enabled: true
        },
  
        credits: {
          enabled: false
        },
  
        xAxis: {
            categories: this.props.keys,
            title: {
                text: ''
            },
            labels: {
              style: {
                fontSize: '15px'
              }
            }
        },
  
        yAxis: {
          max: this.getYAxisLimit(),
          title: {
            text: this.props.unitText,
            style: {
              color: 'black'
            }
          },
          labels: {
            style: {
              font: '1em Roboto, sans-serif'
            }
          }
        },
  
        plotOptions: {
          boxplot: {
            color: '#000',
            fillColor: '#FF0000',
            lineWidth: 1,
            medianColor: '#000',
            medianWidth: 1,
            stemColor: '#000',
            stemWidth: 1,
            whiskerColor: '#000',
            whiskerLength: '20%',
            whiskerWidth: 1,
            maxPointWidth: 20
          },
        },
  
        labels: {
          style: {
            fontSize: '15px'
          }
        },
  
        series: [{
          name: this.props.currentCrop,
          data: this.props.currentCropBoxplotValues,
          showInLegend: false
        }, 
        {
          name: this.props.currentCrop,
          color: window.Highcharts.getOptions().colors[0],
          type: 'scatter',
          data: [],
          marker: {
            fillColor: 'red',
            lineWidth: 1,
            lineColor: 'black',
            radius: 6,
            symbol: 'circle'
          }
        }
        ]
      });
    }

    
  }

  render(){
    return(
      <div id='boxplot' style={{height: '80%', width: '90%', marginTop: '12%'}}>

      </div>
    )
  }
}

export default SpecificProductivityBoxplot
