import React, { Component } from 'react'
import InputField from '../common/InputField'

class EnosForm extends Component{
  render(){
    return(
      <div className="enos-form-container">
        <div className="enos-form-container-title">
          {this.props.isEditing ?
            <h5>Editar Ano</h5>
          :
            <h5>Cadastrar Novo Ano</h5>
          }
        </div>

        <div className="row enos-form">
          <form>
            <div className="col s12 year-info">
              <div className="col s3">
                <InputField
                  name='year'
                  label={this.props.isEditing? 'Ano' : 'Novo Ano'}
                  type='text'
                  onChange={ this.props.onChange }
                  value={ this.props.item.year }
                  error={ undefined }
                  active={ this.props.item.year !== ''}
                  activeFarm={'a'}
                />
              </div>
            </div>
            <div className="col s12 enos-info">
              <div className="col s3">
                <InputField
                  name='civil_year'
                  label='Ano Civil'
                  type='text'
                  onChange={ this.props.onChange }
                  value={ this.props.item.civil_year }
                  error={ undefined }
                  active={ this.props.item.civil_year !== ''}
                  activeFarm={'a'}
                />
              </div>
              <div className="col s3">
                <InputField
                  name='agri_year'
                  label='Ano Agricultura'
                  type='text'
                  onChange={ this.props.onChange }
                  value={ this.props.item.agri_year }
                  error={ undefined }
                  active={ this.props.item.agri_year !== ''}
                  activeFarm={'a'}
                />
              </div>
              <div className="col s3">
                <InputField
                  name='civil_sem'
                  label='Semestre Civil'
                  type='text'
                  onChange={ this.props.onChange }
                  value={ this.props.item.civil_sem }
                  error={ undefined }
                  active={ this.props.item.civil_sem !== ''}
                  activeFarm={'a'}
                />
              </div>
              <div className="col s3">
                <InputField
                  name='agri_sem'
                  label='Semestre Agricultura'
                  type='text'
                  onChange={ this.props.onChange }
                  value={ this.props.item.agri_sem }
                  error={ undefined }
                  active={ this.props.item.agri_sem !== ''}
                  activeFarm={'a'}
                />
              </div>
            </div>
          </form>
          <div className="row button-row">
            <button className='btn-flat btn-drop' onClick={this.props.returnToList}>VOLTAR</button>
            <button className='btn wave-light green' onClick={this.props.addSubmit} style={{marginLeft: '8px', fontSize: '12px'}}>{this.props.isEditing ? "SALVAR" : "CRIAR"}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default EnosForm
