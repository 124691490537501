import React from 'react'
import classnames from 'classnames'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { translateMonth } from '../../shared/utils/DatesUtils'

const DropdownField = ({ value, onChange,type, name, cadError, disabled, id='sidebar-select', options=[], user=undefined, smartPermissions}) => {
  let smartCaneAccess = false
  let smartSoyAccess = false
  let smartCornAccess = false
  let smartCottonAccess = false
  let smartWheatAccess = false
  let smartEucalyptus = false
  if(smartPermissions){
    if(smartPermissions['smart']){
      smartCaneAccess = smartPermissions['smart'].products.includes('cana') ? true : false
      smartSoyAccess = smartPermissions['smart'].products.includes('soja') ? true : false
      smartCornAccess = smartPermissions['smart'].products.includes('milho') ? true : false
      smartCottonAccess = smartPermissions['smart'].products.includes('algodao') ? true : false
      smartWheatAccess = smartPermissions['smart'].products.includes('trigo') ? true : false
      smartEucalyptus = smartPermissions['smart'].products.includes('eucalipto') ? true : false
    }
  }

  if(type === 'cad'){
    return(
      <select id={type} value={value} onChange={onChange} disabled={disabled} className={classnames(id,{'cadError': cadError}, {disabled: disabled})}>
        <option value="0" disabled>mm</option>
        <option value="25">25 mm</option>
        <option value="50">50 mm</option>
        <option value="75">75 mm</option>
        <option value="100">100 mm</option>
        <option value="125">125 mm</option>
        <option value="150">150 mm</option>
        <option value="200">200 mm</option>
        <option value="250">250 mm</option>
        <option value="300">300 mm</option>
      </select>
    )
  }else if(type === 'soil-texture'){
    return(
      <Select id='soil_texture' name='soil_texture' value={value} onChange={onChange} disabled={disabled} className={classnames(id,{disabled: disabled})} disableUnderline>
        <MenuItem value="null" disabled>Selecionar</MenuItem>
        <MenuItem value="veryClayey">Muito Argilosa</MenuItem>
        <MenuItem value="clayey">Argilosa</MenuItem>
        <MenuItem value="mediumTexture">Média</MenuItem>
        <MenuItem value="sandyAverageTexture">Média Arenosa</MenuItem>
        <MenuItem value="sandy">Arenosa</MenuItem>
      </Select>
    )
  }else if(type === 'soil-depth'){
    return(
      <Select id='soil_depth' name='soil_depth' value={value} onChange={onChange} disabled={disabled} className={classnames(id,{disabled: disabled})} disableUnderline>
        <MenuItem value="null" disabled>Selecionar</MenuItem>
        <MenuItem value="veryShallow">Muito Rasa</MenuItem>
        <MenuItem value="shallow">Rasa</MenuItem>
        <MenuItem value="moderatelyDeep">Média</MenuItem>
        <MenuItem value="deep">Profunda</MenuItem>
        <MenuItem value="veryDeep">Muito Profunda</MenuItem>
      </Select>
    )
  }else if(type === 'months'){
    return(
      <Select className={"prod-months-dropdown"} value={value} onChange={onChange} name="months" style={{marginLeft: '8px'}}>
        {options.map(option => {
          return(
            <MenuItem key={option["value"]} value={option["value"]} data-box={option["box"]}>{translateMonth(option["value"])}</MenuItem>
          )
        })}
      </Select>
    )
  }else if(type === 'years'){
    return(
      <Select className={"prod-months-dropdown"} value={value} onChange={onChange} name="years" style={{marginLeft: '8px'}}>
        {options.map(option => {
          return(
            <MenuItem key={option["value"]} value={option["value"]} data-box={option["box"]}>{option["value"]}</MenuItem>
          )
        })}
      </Select>
    )
  }else if(type === 'crop'){
    return(
      <Select className={"prod-months-dropdown"} value={value} onChange={onChange} name="crop" style={{marginRight: '8px',marginLeft: '8px'}}>
        {options.map(option => {
          return(
            <MenuItem key={option} value={option}>{option}</MenuItem>
          )
        })}
      </Select>
    )
  }else if(type === 'prod'){
    return(
      <Select className={"prod-months-dropdown"} value={value} onChange={onChange} name={name} style={{marginRight: '8px',marginLeft: '8px'}}>
        {options.map(option => {
          return(
            <MenuItem key={option} value={option}>{option}</MenuItem>
          )
        })}
      </Select>
    )
  }else{
    const itemStyle = {
      padding: "20px",
      justifyContent: "center",
    };

    const imgStyle = {
      height: "35px",
      display: "flex",
      margin: "0 auto"
    };

    const smartImgStyle = {
      height: "45px",
      display: "flex",
      margin: "0 auto"
    }

    return (
      <Select
        value={value}
        onChange={onChange}
        name="Produtos"
        fullWidth
        inputProps={{
          name: 'product',
          id: 'product'
        }}
        style={{
          marginBottom: "20px",
          width: "240px"
        }}
      >
        <MenuItem value="weather" style={itemStyle}> <img alt='weather-logo' style={imgStyle} src="/images/logos/weather.png" /></MenuItem>
        <MenuItem value="bhbox" style={itemStyle}> <img alt='bhbbox-logo' style={imgStyle} src="/images/logos/box.png"     /></MenuItem>
        <MenuItem value="tools" style={itemStyle}> <img alt='agryindex-logo' style={imgStyle} src="/images/logos/index.png"   /></MenuItem>
        <MenuItem value="fire" style={itemStyle}> <img alt='fire-logo' style={imgStyle} src="/images/logos/fire.png"   /></MenuItem>
        {user && smartCaneAccess &&
          <MenuItem value="atrmax" style={itemStyle}> <img alt='sugar-logo' style={smartImgStyle} src="/images/logos/smart-cane.png" /></MenuItem>
        }
        {user && smartSoyAccess &&
          <MenuItem value="smart" style={itemStyle}> <img alt='soy-logo' style={smartImgStyle} src="/images/logos/smart-soy.png"/></MenuItem>
        }
        {user && smartCornAccess &&
          <MenuItem value="corn" style={itemStyle}> <img alt='corn-logo' style={smartImgStyle} src="/images/logos/smart-corn.png"/></MenuItem>
        }
        {user && smartCottonAccess &&
          <MenuItem value="cotton" style={itemStyle}> <img alt='cotton-logo' style={smartImgStyle} src="/images/logos/smart-cotton.png"/></MenuItem>
        }
        {user && smartWheatAccess &&
          <MenuItem value="wheat" style={itemStyle}> <img alt='wheat-logo' style={smartImgStyle} src="/images/logos/smart-wheat.png"/></MenuItem>
        }
        {user && smartEucalyptus &&
          <MenuItem value="eucalyptus" style={itemStyle}> <img alt='eucalyptus-logo' style={smartImgStyle} src="/images/logos/smart-eucalyptus.png"/></MenuItem>
        }
      </Select>
    )
  }
}

export default DropdownField
