import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux'
import {useDropzone} from 'react-dropzone';

import { uploadKmzFile } from "../../actions/profile";

const baseStyle = {
  width: '30%',
  margin: 'auto',
  padding: '10px 20px',
  borderRadius: 5,
  backgroundColor: '#6CB832',
  color: '#ffffff',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#6CB832',
  cursor: 'pointer',
};

const acceptStyle = {
  borderColor: '#6CB832',
};

function UploadFileButton({ setToaster }) {
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isFocused, isDragAccept } = useDropzone({
    onDrop: (acceptedFiles) => {
      dispatch(uploadKmzFile(acceptedFiles[0]))
        .then(() => {
          setToaster(true);
        });
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
  }), [isFocused, isDragAccept]);

  return (
    <div>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Adicionar arquivo KMZ</p>
      </div>
    </div>
  );
}

export default UploadFileButton;
