import * as React from 'react';
import {Button, Menu, MenuItem } from '@mui/material';

const graphicsAtrMaxData = [
  {
    graphicNumber: 1,
    graphicName: 'ATR Média',
  },
  {
    graphicNumber: 2,
    graphicName: 'Chuva Acumulada Média',
  }
];

export default function SelectGraphicAtrMax(props) {
  const { changeGraphic, currentGraphic } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        sx={{
          mt: 0.6,
          height: '27px',
          textTransform: 'none',
          color: 'rgba(62, 39, 35, 0.87)',
          cursor: 'pointer',
          fontWeight: 'normal',
          '&:hover': {
            background: 'none',
          },
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <i style={{ color: 'grey', marginRight: '6px' }} className="material-icons">show_chart</i>
        Visualizar
        <i style={{ color: 'grey' }} className='material-icons'>{!anchorEl ? 'arrow_drop_down' : 'arrow_drop_up'}</i>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {graphicsAtrMaxData.map((item) => (
          <MenuItem
            key={item.graphicNumber}
            sx={{
              background: currentGraphic === item.graphicNumber ? '#6bb324' : '#FFFFFF',
              color: currentGraphic === item.graphicNumber ? '#FFFFFF' :'rgba(62, 39, 35, 0.87)',
              '&:hover': {
                background: currentGraphic === item.graphicNumber ? '#6bb324' : '#FFFFFF',
                color: currentGraphic === item.graphicNumber ? '#FFFFFF' :'rgba(62, 39, 35, 0.87)',
              },
            }}
            onClick={() => {
              changeGraphic(item.graphicNumber);
              handleClose();
            }}
          >
            <i
            style={{
              color: currentGraphic === item.graphicNumber ? '#FFFFFF' : 'grey', marginRight: '5px',
              }}
              className="material-icons"
            >
              show_chart
            </i>
            {item.graphicName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
