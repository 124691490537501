import AdminApi from "../api/admin";
import { toggleLoader } from "./global";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const SET_USERS_STATUS_SUCCESS = "SET_USERS_STATUS_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const START_DOWNLOAD = "START_DOWNLOAD";
export const RESET_DOWNLOAD = "RESET_DOWNLOAD";
export const GET_ENOS_SUCCESS = "GET_ENOS_SUCCESS";
export const ADD_ENOS_STATUS = "ADD_ENOS_STATUS";
export const GET_POINT_LIST = "GET_POINT_LIST";
export const MODIFY_PRODUCT_ACCESS = "MODIFY_PRODUCT_ACCESS";
export const MODIFY_REGISTRATION_LIMIT = "MODIFY_REGISTRATION_LIMIT";
export const RESET_SERVER_MESSAGE = "RESET_SERVER_MESSAGE";
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";
export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS";
export const RESET_STATUS_ANOS = "RESET_STATUS_ANOS";
export const SET_API_DATES_SUCESS = "SET_API_DATES_SUCESS";
export const UPDATE_USER_POINTS = "UPDATE_USER_POINTS";
export const ADMIN_GET_POINTS_SUCCESS = "ADMIN_GET_POINTS_SUCCESS";

//------------USERS SECTION----------------------------------
export function getUsersList() {
  return function (dispatch) {
    return AdminApi.getAllUsers()
      .then((users) => {
        dispatch(getAllUsersSuccess(users));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function setUserStatus(user) {
  return function (dispatch) {
    return AdminApi.setUserStatus(user)
      .then((userResponse) => {
        dispatch(setUserStatusSuccess(userResponse));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function editUser(user, password) {
  return function (dispatch) {
    return AdminApi.editUser(user, password)
      .then((userResponse) => {
        dispatch(editUserSuccess(userResponse));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function createApiKey(user) {
  return function (dispatch) {
    return AdminApi.createApiKey(user)
      .then((response) => {
        dispatch(createApiKeySuccess(user, response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteApiKey(user) {
  return function (dispatch) {
    return AdminApi.deleteApiKey(user)
      .then((response) => {
        dispatch(deleteApiKeySuccess(user, response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function setApiDates(data) {
  return function (dispatch) {
    return AdminApi.setApiDates(data)
      .then((response) => {
        dispatch(setApiDatesSucess(data, response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getAllUsersSuccess(users) {
  return { type: "GET_ALL_USERS_SUCCESS", users };
}

export function setUserStatusSuccess(user) {
  return { type: "SET_USERS_STATUS_SUCCESS", user };
}

export function editUserSuccess(user) {
  return { type: "EDIT_USER_SUCCESS", user };
}

export function createApiKeySuccess(user, response) {
  return { type: "CREATE_API_KEY_SUCCESS", user, response };
}

export function deleteApiKeySuccess(user, response) {
  return { type: "DELETE_API_KEY_SUCCESS", user, response };
}

export function setApiDatesSucess(data, response) {
  return { type: "SET_API_DATES_SUCESS", data, response };
}

//-------------ENOS SECTION-----------------------------------

export function getEnosList() {
  return function (dispatch) {
    return AdminApi.getEnosList()
      .then((enos) => {
        dispatch(getEnosListSuccess(enos));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addEnos(newEnos) {
  return function (dispatch) {
    return AdminApi.addEnos(newEnos)
      .then((status) => {
        dispatch(addEnosStatus(status));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function editEnos(enos) {
  return function (dispatch) {
    return AdminApi.editEnos(enos)
      .then((status) => {
        dispatch(addEnosStatus(status));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getEnosListSuccess(enos) {
  return { type: "GET_ENOS_SUCCESS", enos };
}

export function addEnosStatus(status) {
  return { type: "ADD_ENOS_STATUS", status };
}

export function resetEnosStatus() {
  return { type: "RESET_STATUS_ANOS" };
}

//---------------DATA DOWNLOAD SECTION------------------------

export function downloadData(points, variables, initialDate, finalDate) {
  return AdminApi.downloadData(points, variables, initialDate, finalDate)
    .then((userResponse) => userResponse)
    .catch((error) => {
      throw error;
    });
}

export function downloadProductivityData(data) {
  return AdminApi.downloadProductivityData(data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function downloadIndexData(data) {
  return AdminApi.downloadIndexData(data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function downloadGeneralProductivityData(data) {
  return AdminApi.downloadGeneralProductivityData(data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}

export function startDownload(data) {
  return { type: "START_DOWNLOAD", data };
}

export function resetDownload() {
  return { type: "RESET_DOWNLOAD" };
}

//-------------USERS POINT CONTROL SECTION----------------------

export function getProductList(data) {
  return function (dispatch) {
    return AdminApi.getProductList(data)
      .then((response) => {
        dispatch(getList(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function modifyProductAccess(data) {
  return function (dispatch) {
    return AdminApi.modifyProductAccess(data)
      .then((response) => {
        dispatch(getProductList(data));
        dispatch(addAccessSucess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function modifyPointLimit(data) {
  return function (dispatch) {
    return AdminApi.modifyPointLimit(data)
      .then((response) => {
        dispatch(modidyLimitSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function adminGetPoints(user) {
  return function (dispatch) {
    dispatch(toggleLoader());
    return AdminApi.adminGetPoints(user)
      .then((response) => {
        dispatch(toggleLoader());
        dispatch(adminGetPointSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function adminUpdatePoints(data) {
  return function (dispatch) {
    dispatch(toggleLoader());
    return AdminApi.adminUpdatesPoints(data)
      .then((response) => {
        dispatch(toggleLoader());
        dispatch(updatePointsSuccess(response));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getList(data) {
  return { type: "GET_POINT_LIST", data };
}

export function addAccessSucess(data) {
  return { type: "MODIFY_PRODUCT_ACCESS", data };
}

export function modidyLimitSuccess(data) {
  return { type: "MODIFY_REGISTRATION_LIMIT", data };
}

export function resetServerMessage() {
  return { type: "RESET_SERVER_MESSAGE" };
}

export function adminGetPointSuccess(response) {
  return { type: "ADMIN_GET_POINTS_SUCCESS", response };
}

export function updatePointsSuccess(data) {
  return { type: "UPDATE_USER_POINTS", data };
}
