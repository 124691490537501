import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUsersList } from '../../actions/admin'
import SignInModal from '../signin/SignInModal'
import { setErrorsToNull } from '../../actions/users'

class AdminAddUser extends Component{
  constructor(props){
    super(props)
    this.state = {
      signin: false,
    }
  }

  toggleSignIn = () => {
    this.props.history.push('/admin/users')
  }

  render(){
    return(
      <div className='row admin-information'>
        <div style={{padding: '48px'}}>
          <SignInModal setErrorsToNull={this.props.setErrorsToNull} toggleSignIn={this.toggleSignIn}/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    usersList: state.admin.usersList,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUsersList: () => {
      dispatch(getUsersList())
    },
    setErrorsToNull: () => {
      dispatch(setErrorsToNull())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAddUser)
