import {
  ON_GENERATE_PRODUCTIVITY_SUCCESS,
  SAVE_REQUEST_DATA,
  GET_REQUEST_DATA,
  SET_PRODUCTIVITY_UNDEFINED,
  SAVE_SPECIFIC_PRODUCTIVITY_REQUEST_DATA,
  SET_CHANGED_CAD_FLAG,
  ON_GENERATE_EUCALYPTUS_PRODUCTIVITY_SUCCESS,
  SET_IS_GRAPHIC_LOADING_CANE,
} from "../actions/productivity";

import ProductivityApi from '../api/productivity';


const initialState = {
  isGraphicLoading: false,
  lat: undefined,
  lon: undefined,
  cad: undefined,
  config: {},
  crop_year:              ProductivityApi.DEFAULT_CROP_YEAR,
  initialMonth:          ProductivityApi.DEFAULT_INITIAL_MONTH,
  finalMonth:            ProductivityApi.DEFAULT_FINAL_MONTH,
  agriculturalEficiency: ProductivityApi.DEFAULT_AGRYCULTURAL_EFICIENCY,
  canePlantedProportion: ProductivityApi.DEFAULT_CANE_PLANTED_PROPORTION,
  cycle:                 ProductivityApi.DEFAULT_CYCLE,
  kdec:                  ProductivityApi.DEFAULT_KDEC,
  changedCadFlag: false,
  data: undefined,
  created_at: undefined,
  next_update: undefined,
  specific: {
    lat: undefined,
    lon: undefined,
    cad: undefined,
    crop_year: ProductivityApi.DEFAULT_CROP_YEAR,
    agry_eficiency: ProductivityApi.DEFAULT_AGRYCULTURAL_EFICIENCY,
    kdec: ProductivityApi.DEFAULT_KDEC,
    cane_type: ProductivityApi.DEFAULT_CANE_TYPE,
    cycle: ProductivityApi.DEFAULT_CYCLE,
    date: ProductivityApi.DEFAULT_DATE,
    cut_number: ProductivityApi.DEFAULT_CUT_NUMBER,
    cc_user: ProductivityApi.DEFAULT_CC,
    u_percent_user: ProductivityApi.DEFAULT_U_PERCENT
  }
}

export default (state = initialState, action) => {
  switch(action.type) {
    case ON_GENERATE_PRODUCTIVITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.report,
        armData: action.payload.arm,
        etrData: action.payload.etretp,
        created_at: action.payload.created_at,
        next_update: action.payload.next_update
      };
    }
    case SET_IS_GRAPHIC_LOADING_CANE: {
      return {
        ...state,
        isGraphicLoading: !state.isGraphicLoading
      };
    }
    case ON_GENERATE_EUCALYPTUS_PRODUCTIVITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.report,
        armData: action.payload.arm ? action.payload.arm : null,
        etrData: action.payload.etretp ? action.payload.etretp : null,
        created_at: action.payload.created_at ? action.payload.created_at : null,
        next_update: action.payload.next_update ? action.payload.next_update : null,
        range_flag: action.payload.report !== undefined ? Number.isNaN(parseInt(Object.keys(action.payload.report)[0])) ? "monthly" : "annually" : null
      };
    }

    case SAVE_REQUEST_DATA: {
      let newState = Object.assign({}, state);
      for (let key in action.payload) {
        newState[key] = action.payload[key];
      }
      return newState;
    }
    case GET_REQUEST_DATA: {
      return Object.assign({}, state, initialState, {...state, config: action.payload})
    }
    case SET_PRODUCTIVITY_UNDEFINED:
      return Object.assign({}, state, initialState, {cad: state.cad})
    case SAVE_SPECIFIC_PRODUCTIVITY_REQUEST_DATA:
      return Object.assign({}, state, {specific: action.payload})
    case SET_CHANGED_CAD_FLAG:
      return Object.assign({}, state, {changedCadFlag: action.payload})
    default:
      return state;
  }
}
