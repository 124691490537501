import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import { connect } from 'react-redux'
import IndicatorColor from './IndicatorColor'
import { AGRYTOOLS } from '../../../shared/utils/consts'

import { setBhboxDateClick } from '../../../actions/map'

class Indicator extends Component {

  //Render methods
  handleClick = () => {

    if(this.props.onClick !== undefined){
      this.props.onClick(this.props.day, this.props.month, this.props.year)
    }else{
      let el = ReactDOM.findDOMNode(this)
      var data = this.props.data
      var dec = this.props.dec
      var month = this.props.month
      var year = this.props.year
      var initials = this.props.initials
      this.props.setDetailPopover(el, data, dec, month, year, initials)
      this.props.setBhboxDateClick(dec+1,month+1,year)
    }
  }

  render() {
    let length = this.props.data.length
    let lastData = this.props.data[length-1]
    let lengthArrayAgricultural = this.props.actualLengthArray >= 7
      ? this.props.actualLengthArray - 7
      : this.props.actualLengthArray + 5

    return(
      <div className={classnames('bhbox--indicator',
                      {'last-key': this.props.index === 35 ||
                        (this.props.visualizationType === 'agricultural'
                        && (this.props.dec === 2 && this.props.month === lengthArrayAgricultural)) ||
                        (this.props.visualizationType === 'civil'
                        && (this.props.dec === 2 && this.props.month === this.props.actualLengthArray - 1))},
                      {'first-prediction-indicator': this.props.prediction === 0 && AGRYTOOLS.includes(this.props.agrytoolsType)},
                      {'middle-prediction-indicator': this.props.prediction === 1 && AGRYTOOLS.includes(this.props.agrytoolsType)},
                      {'last-prediction-indicator': this.props.prediction === 2 && AGRYTOOLS.includes(this.props.agrytoolsType)}
                    )}>
        <IndicatorColor soil={this.props.soil} agrytoolsType={this.props.agrytoolsType} prediction={this.props.prediction} data={ lastData } onClick={this.handleClick } />
      </div>
    )
  }
}

function mapDispatchToProps(dispatch){
  return{
    setBhboxDateClick: (dec,month,year) =>{
      dispatch(setBhboxDateClick(dec,month,year))
    }
  }
}

export default connect(null,mapDispatchToProps)(Indicator)
