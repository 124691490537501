import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export const ProductContainer = styled(Grid)({
  backgroundColor: '#EAEAEA',
  boxShadow: '0px 3px 6px #0000000A',
  borderRadius: '8px',
  padding: '2em',
});

export const ConnectLine = styled(Box)((props) => ({
  width: '120%',
  height: '2px',
  marginTop: '12px',
  borderRadius: '15px',
  marginLeft: '-10px',
  backgroundColor: props.color,
  zIndex: '-1000',
}));

export const Seal = styled(Box)(({
  position: 'absolute',
  top: '-20px',
  left: '1100px',
}));
