import React, { Component, Fragment } from 'react'
import classnames from 'classnames'
// Components
import Indicator from './Indicator'
import DetailPopover from './DetailPopover'
import PerfectScrollbar from 'react-perfect-scrollbar'

// Styles
import '../../../stylesheets/css/components/fire.css'
import '../../../../node_modules/react-perfect-scrollbar/dist/css/styles.css'

class AgryFire extends Component {
  constructor(props) {
      super(props)

      this.state = {
        detailPopover: {
          active: false,
          element: undefined,
          data: undefined,
          dec: undefined,
          month: undefined,
          year: undefined,
          initials: undefined
        },
        activeYear: new Date().getFullYear()
      }
  }

  //Lifecycle methods

  UNSAFE_componentWillReceiveProps() {
    this.setState({ detailPopover: { active: false } })
  }

  //#region Helper methods

  renderDifferentCol(){
    return(
      <div className='years-label'><label>ANOS</label></div>
    )
  }

  getDecByDay(day){
    if(day <= 10){
      return 0
    }else if(day > 10 && day <= 20){
      return 1
    }else if(day > 20){
      return 2
    }
  }

  predictionIndicator(dec,month,year,data){
    if(data === -1){
      return false
    }
    if(parseInt(year, 10) > parseInt((new Date()).getFullYear(), 10)){
      return true
    }else if(parseInt(year, 10) === parseInt((new Date()).getFullYear(), 10)){
      if(parseInt(month,10) > (new Date()).getMonth()){
        return true
      }else if(parseInt(month,10) === (new Date()).getMonth()){
        let currentDec = this.getDecByDay((new Date()).getDate())
        if(currentDec > dec){
          return false
        }else if(currentDec === dec){
          return true
        }else if (currentDec < dec) {
          return true
        }
      }else{
        return false
      }
    }else{
      return false
    }
  }

  scrollBottom(scrollRef){
    if(scrollRef !== null){
      scrollRef.scrollTop = parseInt(scrollRef.offsetHeight,10) + parseInt(scrollRef.offsetTop,10)
    }
  }

  getPredictionPosition(isPrediction, isLastMonthWithData, index, data, month, year, predictionPosition) {
    let position = predictionPosition;

    if (isPrediction) {
      if (isLastMonthWithData) {
        if (index < 2 && data !== -1) {
          if (month[index + 1] === -1 ) {
            position = 3
          } else {
            position += 1
          }
        } else if (index === 2 && data !== -1) {
          position = 3
        } else {
          position = -1
        }
      } else {
        position += 1
      }
    } else {
      position = -1
    }
    if(position === 0 && year === this.state.activeYear+1){
      //In case of new year
      return 3
    }
    return position;
  }

  renderBody(){
    return(
        <Fragment>
          <PerfectScrollbar containerRef={this.scrollBottom} className='fire-scroll ps ps--theme default' onScrollY={this.handleScroll}>
          { this.props.data.map(row => {
            if(this.props.activeFarm !== undefined){
              if(row['farm_id'] === this.props.activeFarm['id']){
                return (
                  row.agryfire.map((agryfire, index) => {
                    var predictionPosition = -1
                    return(
                      <div key={index} className='fire--labels-data'>
                        <div className={classnames('year-label', {'active-detailPopover-date': this.state.detailPopover.year === agryfire['year']})}>{ agryfire['year'] }</div>
                        {agryfire['data'].map((month, index) => {
                          var monthNum = index
                          var lastMonthWithData = false
                          if(index <= agryfire["data"].length - 1){
                            if(agryfire["data"][index][0] !== -1 && agryfire["data"][index][0] === -1){
                              lastMonthWithData = true
                            }
                          }
                          return(
                            month.map((data, subIndex) => {
                              let isPrediction = this.predictionIndicator(subIndex, monthNum, agryfire['year'], data);

                              predictionPosition = this.getPredictionPosition(isPrediction, lastMonthWithData, subIndex, data, monthNum, agryfire['year'], predictionPosition);

                              return(
                                <Indicator
                                  soil={this.props.soil}
                                  agrytoolsType={this.props.agrytoolsType}
                                  key={subIndex}
                                  month={ monthNum }
                                  year={ agryfire['year'] }
                                  data={ data }
                                  dec={ subIndex }
                                  prediction={predictionPosition}
                                  setDetailPopover={ this.setDetailPopover }
                                  fullScreen={this.props.fullScreen}
                                />
                              )
                            })
                          )
                        })
                      }
                    </div>
                  )
                })
              )
            }else {
              return null
            }
          }else{
            return (
              row.agryfire.map((agryfire) => {
                let predictionPosition = -1;

                return(
                  <div className='fire--labels-data'>
                    <div className={classnames('year-label', {'active-detailPopover-date': this.state.detailPopover.year === agryfire['year']})}>{ agryfire['year'] }</div>
                    {agryfire['data'].map((month, index) => {
                      let monthNum = index;
                      let isLastMonthWithData = false;

                      if (index <= agryfire["data"].length - 1) {
                        if (agryfire["data"][index][0] !== -1 && agryfire["data"][index][0] === -1) {
                          isLastMonthWithData = true;
                        }
                      }

                      return(
                        month.map((data, index) => {
                          let isPrediction = this.predictionIndicator(index, monthNum, agryfire['year'], data);

                          predictionPosition = this.getPredictionPosition(isPrediction, isLastMonthWithData, index, data, monthNum, agryfire['year'], predictionPosition);

                          return(
                            <Indicator
                              soil={this.props.soil}
                              agrytoolsType={this.props.agrytoolsType}
                              month={ monthNum }
                              year={ agryfire['year'] }
                              data={ data }
                              dec={ index }
                              prediction={predictionPosition}
                              setDetailPopover={ this.setDetailPopover }
                              fullScreen={this.props.fullScreen}
                            />
                          )
                        })
                      )
                    })
                  }
                </div>
              )
            })
           )
          }
        })}
        </PerfectScrollbar>
      </Fragment>
    )
  }
  //#endregion

  //#region Render methods
  
  setDetailPopover = (el, data, dec, month, year, initials) => {
    this.setState({ detailPopover: { active: true, element: el, data: data, dec: dec, month: month, year: year, initials: initials} })
  }

  disablePopover = () => {
    this.setState({ detailPopover: { active: false } })
  }

  handleScroll = (e) => {
    if(this.state.detailPopover.active){
      this.setState({ detailPopover: { active: false } })
    }
  }
  //#endregion

  render() {
    const months = [
      'JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'
    ]

    const decs = [
      '1', '2', '3'
    ]

    return(
      <Fragment>
        <div className={classnames("fire")}>
          <div className="fire--labels-months">
            <div id='first-td'></div>
            {months.map((value, index) => {
              return(
                <div
                  key={index}
                  className={classnames('months-labels',
                                        {'active-detailPopover-date' : this.state.detailPopover.month === index},
                                        {'last-key': months.length === index+1})}
                  colSpan={3}>
                    {value}
                </div>
              )
            })}
          </div>
          <div className={classnames("fire--labels-days")}>
            {this.renderDifferentCol()}
            {months.map((value,index) =>{
              var monthCom = index
              return(
                decs.map((value, index) => {
                  return(
                    <div
                      key={value+'_'+index}
                      className={classnames({'active-detailPopover-date' : this.state.detailPopover.month === monthCom && this.state.detailPopover.dec === index},
                      {'last-key': months.length === monthCom+1 && index === 2})}
                    >
                      {value}
                    </div>
                  )
                })
              )
            })}
          </div>
        </div>
        {this.renderBody()}
        { this.state.detailPopover.active ?
          <DetailPopover
            data={ this.state.detailPopover.data }
            dec={ this.state.detailPopover.dec }
            month={this.state.detailPopover.month}
            year={this.state.detailPopover.year}
            key={this.state.detailPopover.month.toString() + '/' + this.state.detailPopover.year.toString()}
            element={ this.state.detailPopover.element }
            disablePopover={ this.disablePopover }
            fullScreen={ this.props.fullScreen }
            soil={this.props.soil}
            agrytoolsType={this.props.agrytoolsType}
          /> : null }
      </Fragment>
    )
  }
}

export default AgryFire
