import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Libs
import applicationConfig from '../../lib/applications'

// DEV HINT: The link there is a hack to the back action work to make that the correct way we would have to change the way the app treats his states
//           the states of components is reseted when the page changes and thats the problem

class SimpleNav extends Component {
  render(){
    return(
      <nav style={ applicationConfig.applications[this.props.application]['styles'] }>
        <div className='nav-wrapper col s8'>
          <Link to='/'>
            <img
              alt='agrymax-logo'
              src='/images/agrymax_branco.png'
              style={{ width:'156px' }}
              className="brand-logo center landing-logo"
            />
          </Link>
          <div>
            <ul id='nav-mobile' className='right hide-on-med-and-down'>
              {
                this.props.profilePage && (
                  <li title='Integração com Field Climate'>
                    <Link to='/integration'>
                      <i className='material-icons'>dns</i>
                    </Link>
                  </li>
                )
              }
              {
                this.props.integrationPage && (
                  <li title='Dados do usuário'>
                    <Link to='/user-data'>
                      <i className='material-icons'>dvr</i>
                    </Link>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default SimpleNav
