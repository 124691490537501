import { connect } from 'react-redux'
import Report from '../components/Report'

import { toggleFullScreen, toggleReportContainer,toggleSoilDropdown,setReportTypes,setTBasal, setLastUsedCad, setLastVisualizedCad,toggleYearsDropdown,setLastUsedSoil, toggleFarmsDropdown, resetReport, generateReport, generateReportToStore, setWeatherTypes} from '../actions/reports'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {setActiveFarm } from '../actions/farms'
import { getEnos } from '../actions/enos'
import {
  generatingMapData,
  toggleLoader,
  toggleLeftLoader,
  toggleAtrHelp,
  toggleCorrectTypesFlag,
  toggleTooltips,
  changeVisualizationYear,
  changeFilterParams,
} from '../actions/global'
import { setMapYear, allowXavierBhbox , generateMapData, changeMapDay, resetGrid } from '../actions/map'
import { createSchedule, resetScheduleStatus } from '../actions/schedule'

function mapStateToProps(state) {
  return {
    farms: state.farms.points,
    activeFarm: state.farms.activeFarm,
    farmAttrs: state.Reports.farmAttrs,
    report: state.Reports.report,
    storedReport: state.Reports.storedReport,
    fullScreen: state.Reports.fullScreen,
    active: state.Reports.reportActive,
    yearsDropdownActive: state.Reports.yearsDropdown,
    farmsDropdownActive: state.Reports.farmsDropdown,
    reportTypes: state.Reports.reportTypes,
    graphicType: state.Reports.graphicType,
    graphicRange: state.Reports.graphicRange,
    enos: state.enos.enos,
    selectedGridPointName: state.map.selectedGridPointName,
    selectedGridPointCoords: state.map.selectedGridPointCoords,
    user: state.auth.user,
    xavierGrid: state.map.xavierGridPoints,
    allowXavierBhboxVariable: state.map.allowXavierBhbox,
    mapYear: state.map.mapYear,
    tBasal: state.Reports.tBasal,
    lastUsedCad: state.Reports.lastUsedCad,
    lastVisualizedCad: state.Reports.lastVisualizedCad,
    weatherMapDay: state.map.weatherMapDay,
    lastUsedSoil: state.Reports.lastUsedSoil,
    soilDropdownActive: state.Reports.soilDropdown,
    correctTypesFlag: state.global.correctTypesFlag,
    atrHelp: state.global.atrHelp,
    leftLoader: state.global.leftLoader,
    showTooltips: state.global.showTooltips,
    visualizationType: state.global.visualizationType,
    filterParams: state.global.filterParams,
    scheduleStatus: state.schedule.status,
    cad: state.cad.cad
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleFullScreen: () => {
      dispatch(toggleFullScreen())
    },
    toggleReportContainer: () => {
      dispatch(toggleReportContainer())
    },
    toggleYearsDropdown: () => {
      dispatch(toggleYearsDropdown())
    },
    toggleFarmsDropdown: () => {
      dispatch(toggleFarmsDropdown())
    },
    showLoading: () => {
      dispatch(showLoading())
    },
    hideLoading: () => {
      dispatch(hideLoading())
    },
    setActiveFarm: (id) => {
      dispatch(setActiveFarm(id))
    },
    toggleReportUndefined: () => {
      dispatch(resetReport())
    },
    generateReport: (type, attr) =>  {
      dispatch(generateReport(type, attr))
    },
    generateReportToStore: (type, attr) => {
      dispatch(generateReportToStore(type, attr))
    },
    setWeatherTypes: (graphicType, graphicRange) => {
      dispatch(setWeatherTypes(graphicType,graphicRange))
    },
    getEnos: (type) => {
      dispatch(getEnos(type))
    },
    setMapYear: (year) => {
      dispatch(setMapYear(year))
    },
    allowXavierBhbox: () => {
      dispatch(allowXavierBhbox())
    },
    setReportTypes: (reportTypes) => {
      dispatch(setReportTypes(reportTypes))
    },
    setTBasal: (tb) => {
      dispatch(setTBasal(tb))
    },
    setLastUsedCad: (cad) => {
      dispatch(setLastUsedCad(cad))
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes,attrs))
    },
    generatingMapData: () => {
      dispatch(generatingMapData())
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader())
    },
    setMapDay: (day) => {
      dispatch(changeMapDay(day))
    },
    toggleSoilDropdown: () => {
      dispatch(toggleSoilDropdown())
    },
    setLastUsedSoil: (soil) => {
      dispatch(setLastUsedSoil(soil))
    },
    toggleAtrHelp: () => {
      dispatch(toggleAtrHelp())
    },
    setLastVisualizedCad: cad => {
      dispatch(setLastVisualizedCad(cad))
    },
    toggleCorrectTypesFlag: () => {
      dispatch(toggleCorrectTypesFlag())
    },
    toggleTooltips: () => {
      dispatch(toggleTooltips())
    },
    resetGrid: () => {
      dispatch(resetGrid())
    },
    changeVisualizationYear: visualization => {
      dispatch(changeVisualizationYear(visualization))
    },
    changeFilterParams: filterParams => {
      dispatch(changeFilterParams(filterParams))
    },
    createSchedule: data => {
      dispatch(createSchedule(data))
    },
    resetScheduleStatus: () => {
      dispatch(resetScheduleStatus())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
