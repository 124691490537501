import {CREATE_USER_RESULT, CREATE_USER_FAIL, SET_ERRORS_TO_NULL, CONFIRM_USER_SUCCESS, CONFIRM_USER_FAILED, SET_CULTURES} from '../actions/users'

const initialState = {
  createResult: undefined,
  cultures: [],
  errors: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_RESULT:
      return Object.assign({}, state, {createResult: action.result})
    case CREATE_USER_FAIL:
      return Object.assign({}, state, {errors: action.result['message']})
    case CONFIRM_USER_SUCCESS:
      return Object.assign({}, state, {createResult: action.result})
    case CONFIRM_USER_FAILED:
      return Object.assign({}, state, {errors: action.result['message']})
    case SET_ERRORS_TO_NULL:
      return Object.assign({}, state, {errors: ''})
    case SET_CULTURES:
      return Object.assign({}, state, {cultures: action.cultures})
    default:
        return state
  }
}
