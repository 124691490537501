import {
  RESET_MODAL_STATE,
  SET_CROP_YEAR,
  SET_VISUALIZATION_TYPE,
  SET_CROP_INFO,
  SET_FINAL_PERIOD,
  SET_INITIAL_PERIOD,
  SET_IS_MODAL_OPEN,
  SET_IS_THIRD_STEP_BUTTON_DISABLED,
  SET_IS_USING_MODAL,
  SET_MONTHLY_DATA,
  SET_MONTHS_FOR_THIRD_STEP_TABLE,
  SET_MONTHS_WITH_DEFAULT_VALUES,
  SET_TCH,
  SET_USE_PRECIOUS_CROP,
} from "../actions/GeneralCaneProductivityAction";

const initialState = {
  isUsingCustomizedModal: false,
  isModalOpen: false,
  cropYear: new Date().getFullYear(),
  visualizationType: 'civil',
  monthsWithDefaultValues: [],
  monthlyData: {},
  monthsForThirdStepTable: [],
  usePreviousCrop: true,
  tch: [],
  isThirdStepButtonDisabled: true,
  period: { initialMonth: 0, finalMonth: 11 },
  cropInfo: {
    useDefaultValues: false,
    kdec: 0.22,
    cycle: 12,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_USING_MODAL:
      return Object.assign({}, state, {
        isUsingCustomizedModal: action.isUsingCustomizedModal,
      });
    case SET_IS_MODAL_OPEN:
      return Object.assign({}, state, { isModalOpen: action.isModalOpen });
    case SET_CROP_YEAR:
      return Object.assign({}, state, { cropYear: action.cropYear });
    case SET_VISUALIZATION_TYPE:
      return Object.assign({}, state, {visualizationType: action.visualizationType});
    case SET_INITIAL_PERIOD:
      return Object.assign({}, state, {
        period: {
          initialMonth: action.initialMonth,
          finalMonth: state.period.finalMonth,
        },
      });
    case SET_FINAL_PERIOD:
      return Object.assign({}, state, {
        period: {
          initialMonth: state.period.initialMonth,
          finalMonth: action.finalMonth,
        },
      });
    case SET_MONTHLY_DATA:
      return Object.assign({}, state, {
        monthlyData: {
          ...state.monthlyData,
          ...action.monthlyData,
        },
      });
    case SET_CROP_INFO:
      return Object.assign({}, state, {
        cropInfo: {
          ...state.cropInfo,
          ...action.cropInfo,
        },
      });
    case SET_MONTHS_FOR_THIRD_STEP_TABLE:
      return Object.assign({}, state, {
        monthsForThirdStepTable: action.monthsForThirdStepTable,
      });
    case SET_MONTHS_WITH_DEFAULT_VALUES:
      return Object.assign({}, state, {
        monthsWithDefaultValues: action.monthsWithDefaultValues,
      });
    case SET_IS_THIRD_STEP_BUTTON_DISABLED:
      return Object.assign({}, state, {
        isThirdStepButtonDisabled: action.isThirdStepButtonDisabled,
      });
    case SET_TCH:
      return Object.assign({}, state, {
        tch: {
          ...state.tch,
          ...action.tch,
        },
      });
    case SET_USE_PRECIOUS_CROP:
      return Object.assign({}, state, {
        usePreviousCrop: action.usePreviousCrop,
      });
    case RESET_MODAL_STATE:
      return Object.assign({}, state, {
        ...initialState,
        period: state.period,
        usePreviousCrop: state.usePreviousCrop,
        visualizationType: state.visualizationType,
      });
    default:
      return state;
  }
};
