import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { isEqual, uniqBy } from "lodash"

import { toggleFullScreen, toggleReportContainer } from "../actions/reports"
import { getDashboardTable, getDashboardCharts } from "../actions/dashboard"

import { Paper, Grid, IconButton, Tooltip, withStyles } from '@material-ui/core';

import DashboardDropdown from "../components/dashboard/DashboardDropdown"
import DashboardLegend from "../components/dashboard/DashboardLegend"
import DashboardConfig from "../components/dashboard/DashboardConfig"
import DashboardBarChart from "../components/dashboard/charts/DashboardBarChart"
import DashboardPieChart from "../components/dashboard/charts/DashboardPieChart"
import DashboardStackedBarChart from "../components/dashboard/charts/DashboardStackedBarChart"
import { productivityGlossary, smartAccessGlossary } from "../shared/utils/Glossary"
import { showToaster } from "../components/common/Toaster"

import '../stylesheets/css/components/flowering.css'
import '../stylesheets/css/components/dashboard.css'

const ROWS_LIMIT = 15

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "13px"
    }
})(Tooltip)

class Dashboard extends Component{
    constructor(props){
        super(props)

        this.state = {
            openConfig: false,
            openLegend: false,
            availablePoints: [],
            title: "",
            selectedPoint: [],
            selectedYear: [],
            dropdownPoints: [],
            dropdownYears: [],
            productivityChart: [],
            ygChart: [],
            efficiencyChart: [],
            ygPieChart: [],
            stackedBarChart: [],
        }
    }

    componentDidMount() {
        if(this.props.farms) {
            const points = this.props.farms.filter(point => point.smart && point.smart.products.indexOf(smartAccessGlossary(this.props.culture)) !== -1)
            this.setState({
                availablePoints: points
            })
        }

        const tableData = {
            culture: this.props.culture,
            page: 0,
            limit: ROWS_LIMIT
        }

        this.props.getDashboardTable(tableData)
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.culture !== prevProps.culture) {
            if(this.props.farms) {
                const points = this.props.farms.filter(point => point.smart && point.smart.products.indexOf(smartAccessGlossary(this.props.culture)) !== -1)
                this.setState({
                    availablePoints: points
                })
            }
    
            const tableData = {
                culture: this.props.culture,
                page: 0,
                limit: ROWS_LIMIT
            }
    
            this.setState({
                title: "",
                productivityChart: [],
                ygChart: [],
                efficiencyChart: [],
                ygPieChart: [],
                stackedBarChart: [],
            }, () => {
                this.props.getDashboardTable(tableData)
            })
        }

        if(this.props.tableData && !isEqual(this.props.tableData, prevProps.tableData)) {
            
            if(this.props.tableData.length > 0) {
                const data = {
                culture: this.props.culture,
                points: [this.props.options[0].farm_id],
                harvests: [this.props.options[0].harvests[this.props.options[0].harvests.length-1]]
            }
    
                this.props.getDashboardCharts(data)
            }
            else if(this.props.tableData.length === 0) {
                this.setState({
                    openConfig: true
                })
            }

            let points = []
            let years = new Set()

            this.props.options.forEach(point => {
                points.push({name: point.farm_name, value: point.farm_id, firstHarvest: point.harvests[0]})
                point.harvests.map(year => years.add(year))
            })

            this.setState({
                dropdownPoints: points,
                dropdownYears: [...years].sort()
            })
        }


        if(this.props.farms && (this.props.farms !== prevProps.farms)) {
            const points = this.props.farms.filter(point => point.smart && point.smart.products.indexOf(smartAccessGlossary(this.props.culture)) !== -1)
            this.setState({
                availablePoints: points
            })
        }

        if(this.props.info && this.props.info !== prevProps.info) {
            if(this.props.info.length === 1) {
                if(this.props.info[0].values.length === 1) {
                    if(this.props.info[0].values[0].ap_value) {
                        const { pp_value, ap_value, rp_value, mp_value, ygdh, ygman, ygdh_percent, ygman_percent, ec, ea, desired_agry_eficiency } = this.props.info[0].values[0]

                        const prod = [
                            {name: "PP", value: pp_value},
                            {name: "PA", value: ap_value},
                            {name: "PR", value: rp_value},
                            {name: "PM", value: mp_value}
                        ]
                        const yg = [
                            {name: "YG_DH", value: ygdh},
                            {name: "YG_MAN", value: ygman},
                        ]
                        const efficiency =
                            ea !== null ?
                                [
                                    {name: "EC", value: ec},
                                    {name: "EA", value: ea},
                                    {name: "EM", value: desired_agry_eficiency}
                                ]
                            :
                                [
                                    {name: "EC", value: ec},
                                    {name: "EM", value: desired_agry_eficiency}
                                ]
                        const ygPie = [
                            {name: "YG_DH", value: ygdh_percent},
                            {name: "YG_MAN", value: ygman_percent}
                        ]
                        
                        this.setState({
                            title: `${this.props.info[0].farm_name}  -  CAD = ${this.props.info[0].cad}mm  -  Safra ${this.props.info[0].values[0].crop}`,
                            productivityChart: prod,
                            ygChart: yg,
                            efficiencyChart: efficiency,
                            ygPieChart: ygPie,
                            stackedBarChart: []
                        })
                    }
                    else {
                        const toasterParams = {
                            title: 'ERRO!',
                            message: <span>Você não possui a relação <b>{this.props.info[0].farm_name} -  Safra {this.props.info[0].values[0].crop.slice(0, 4)}</b>.</span>,
                            status: false,
                            autoClose: true
                        }
                        showToaster(toasterParams)

                        this.setState({
                            productivityChart: [],
                            stackedBarChart: [],
                            title: "",
                            openConfig: true
                        })
                    }
                }
                else {
                    let stacked = []
    
                    this.props.info[0].values.map((item) => 
                        stacked.push({
                            name: item.crop,
                            rp: item.rp_value,
                            ygm: item.ygman,
                            ygdh: item.ygdh
                        })
                    )
    
                    this.setState({
                        title: `Produtividade da Cultura  de ${productivityGlossary(this.props.culture)}  -  ${this.props.info[0].farm_name}  -  CAD = ${this.props.info[0].cad}mm`,
                        stackedBarChart: stacked
                    })
                }
            }
            else if(this.props.info.length > 1) {
                let stacked = []
    
                this.props.info.map((item) => 
                    stacked.push({
                        name: item.farm_name,
                        rp: item.values[0].rp_value,
                        ygm: item.values[0].ygman,
                        ygdh: item.values[0].ygdh
                    })
                )
    
                this.setState({
                    title: `Produtividade da Cultura  de ${productivityGlossary(this.props.culture)}  -  Safra ${this.props.info[0].values[0].crop}`,
                    stackedBarChart: stacked
                })
            }
        }

        if(this.props.options) {

        }
    }

    toggleConfiguration = () => {
        this.setState({
            openConfig: !this.state.openConfig
        })
    }

    toggleLegend = () => {
        this.setState({
            openLegend: !this.state.openLegend
        })
    }

    toggleActive = () => {
        this.props.toggleReportContainer()
    }

    updateYearList = () => {
        let years = new Set()

        this.props.options.forEach(option => {
            if(this.state.selectedPoint.length === 0) {
                option.harvests.forEach(years.add, years)
            }
            else if(this.state.selectedPoint.includes(option.farm_id.toString())) {
                option.harvests.forEach(years.add, years)
            }
        })    


        this.setState({          
            dropdownYears: [...years].sort()
        }, () => {
            this.setState({
                selectedYear: this.state.selectedYear.filter(year => this.state.dropdownYears.some(item => item.toString() === year))
            })
        })
    }

    updatePointList = () => {
        let points = []

        this.props.options.forEach(point => {
            let addPoint = false

            if(this.state.selectedYear.length === 0) {
                points.push({name: point.farm_name, value: point.farm_id, firstHarvest: point.harvests[0]})
            }
            else {
                this.state.selectedYear.forEach(year => {
                    if(point.harvests.includes(parseInt(year, 10))) {
                        addPoint = true
                    }
                })
                
                if(addPoint) {
                    points.push({name: point.farm_name, value: point.farm_id, firstHarvest: point.harvests[0]})
                }
            }
        })

        this.setState({
            dropdownPoints: points,
        }, () => {
            let test = this.state.selectedPoint.filter(point => this.state.dropdownPoints.some(item => item.value.toString() === point))
            this.setState({
                selectedPoint: test
            })
        })
    }

    handleCheckData = (event, selector) => {
        const selectedData = selector === "Pontos" ? "selectedPoint" : "selectedYear"
        const availableData = selector === "Pontos" ? "dropdownPoints" : "dropdownYears"
        const dropdownUpdate = selector === "Pontos" ? this.updateYearList : this.updatePointList

        let selected = this.state[selectedData].slice()

        if(event.target.id === "all") {
            if(event.target.checked) {
                this.state[availableData].forEach(point => {
                    if(point.value) {
                        if(!selected.includes(point.value.toString())) {
                            selected.push(point.value.toString())
                        }
                    }
                    else {
                        if(!selected.includes(point.toString())) {
                            selected.push(point.toString())
                        }
                    }
                })

                this.setState({
                    [selectedData]: selected.slice()
                }, () => {
                    this.setDropdownItems(selectedData)
                    dropdownUpdate()
                })
            }
            else {
                this.setState({
                    [selectedData]: []
                }, () => {
                    this.setDropdownItems(selectedData)
                    dropdownUpdate()
                })
            }
        }
        else {
            let data = this.state[selectedData]

            if(event.target.checked) {
                data.push(event.target.id.toString())
                this.setState({
                    [selectedData]: data
                }, () => {
                    this.setDropdownItems(selectedData)
                    dropdownUpdate()
                })
            }
            else {
                if(this.state[selectedData].includes(event.target.id.toString())) {
                    let index = this.state[selectedData].indexOf(event.target.id.toString())
                    data.splice(index, 1)
                    this.setState({
                        [selectedData]: data
                    }, () => {
                        dropdownUpdate()
                    })
                }
            }
        }
    }

    clearCheckedData = (selector) => {
        const selectedData = selector === "Pontos" ? "selectedPoint" : "selectedYear"

        this.setState({
            [selectedData]: []
        }, () => {
            this.setDropdownItems()
        })

    }

    sendCheckedData = (label) => {
        let data = {}
        data.culture = this.props.culture

        if(label === "Pontos") {
            data.points = this.state.selectedPoint
            data.harvests = this.state.selectedPoint.length > 1 ? [this.state.selectedYear[0]] : this.state.selectedYear
        }
        else {
            data.points = this.state.selectedYear.length > 1 ? [this.state.selectedPoint[0]] : this.state.selectedPoint
            data.harvests = this.state.selectedYear
        }

        this.props.getDashboardCharts(data)
    }

    setDropdownItems(selectedData = "") {
        let years = new Set()
        let points = new Set()

        if(selectedData === "") {
            this.props.options.forEach(option => {
                points.add({
                    name: option.farm_name,
                    value: option.farm_id
                })
                option.harvests.forEach(year => {
                    years.add(year)
                })
            })
            this.setState({
                dropdownPoints: [...points],
                dropdownYears: [...years].sort()
            })
        }
        else if(selectedData === "selectedPoint") {
            if(this.state.selectedPoint.length > 0) {
                this.state.selectedPoint.forEach(point => {
                    this.props.options.filter(option => option.farm_id.toString() === point.toString()).forEach(option => option.harvests.map(year => years.add(year)))
                })
            }
            else {
                this.props.options.forEach(option => {
                    option.harvests.forEach(year => {
                        years.add(year)
                    })
                })
            }

            this.setState({
                dropdownYears: [...years].sort()
            })
        }
        else if(selectedData === "selectedYear") {
            if(this.state.selectedYear.length > 0) {
                this.state.selectedYear.forEach(year => {
                    this.props.options.filter(option => option.harvests.some(item => item.toString() === year.toString())).forEach(option => points.add({
                        name: option.farm_name,
                        value: option.farm_id
                    }))
                })
            }
            else {
                this.props.options.forEach(option => {
                    points.add({
                        name: option.farm_name,
                        value: option.farm_id
                    })
                })
            }

            this.setState({
                dropdownPoints: uniqBy([...points], "value" )
            })
        }
    }

    renderDashboard(unit) {
        if(this.state.stackedBarChart.length > 0) {
            return (
                <Grid container item className="chart-container padding-16">
                    <Paper className="col chart">
                        <DashboardStackedBarChart data={this.state.stackedBarChart} label={`Produtividade ${unit}`} />
                    </Paper>
                </Grid>
            )
        }
        if(this.state.productivityChart[0] && this.state.productivityChart[0].value) {
            return (
                <Fragment>
                <Grid container className="chart-container row">
                    <Grid item xs={6} className="left top">
                        <Paper className="col chart">
                            <DashboardBarChart data={this.state.productivityChart} label={`Produtividade ${unit}`} />
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className="right top">
                        <Paper className="col chart">
                            <DashboardBarChart data={this.state.efficiencyChart} label="Eficiência (%)" />
                        </Paper>
                    </Grid>      
                </Grid>
                <Grid container className="chart-container row">
                    <Grid item xs={6} className="left bottom">
                        <Paper className="col chart">
                            <DashboardBarChart data={this.state.ygChart} label={`Y|e|d Gap ${unit}`} />
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className="right bottom">
                        <Paper className="col chart">
                            <DashboardPieChart data={this.state.ygPieChart} />
                        </Paper>
                    </Grid>      
                </Grid>
            </Fragment>
            )
        }
        else {
            return (
                <Grid container item className="chart-container padding-16">
                    <Paper className="col chart" />
                </Grid>
            )
        }
    }

    render() {
        const unit = this.props.culture === "cotton" ? "(@/ha)" : "(sacas/ha)"
        return (
            <Fragment>
                <DashboardLegend openLegend={this.state.openLegend} />
                <DashboardConfig toggleConfiguration={this.toggleConfiguration} openConfig={this.state.openConfig} points={this.state.availablePoints} culture={this.props.culture} unit={unit} />
                <section className={ classnames('report--container', { 'active': this.props.reportActive, 'full-screen': this.props.fullScreen, 'visible': this.props.reportTypes !== ''}) }>
                    <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.props.reportActive})} onClick={this.toggleActive}><i className='material-icons'>play_arrow</i></span>
                    <header className='row dashboard-header'>
                        <div title="Fullscreen" className="valign-wrapper">
                            <span className="valign-wrapper" onClick={e => {
                                e.preventDefault()
                                this.props.toggleFullScreen()
                            }}>
                                <i className='material-icons'>{ this.props.fullScreen ? 'picture_in_picture_alt' : 'aspect_ratio' }</i>
                            </span>
                        </div>
                        <div className="valign-wrapper title">
                            {this.state.title}
                        </div>
                        <div className="header-div">
                            <DashboardDropdown 
                                label="Pontos" 
                                data={this.state.dropdownPoints} 
                                checkedData={this.state.selectedPoint} 
                                handleCheckData={this.handleCheckData}
                                clearCheckedData={this.clearCheckedData}
                                sendCheckedData={this.sendCheckedData}
                            />
                            <DashboardDropdown 
                                label="Safras" 
                                data={this.state.dropdownYears} 
                                checkedData={this.state.selectedYear}
                                handleCheckData={this.handleCheckData}
                                clearCheckedData={this.clearCheckedData}
                                sendCheckedData={this.sendCheckedData}
                            />
                        </div>
                        <CustomTooltip title="Configurar pontos">
                            <IconButton disableRipple={true} aria-label="settings" onClick={this.toggleConfiguration} className="config-btn">
                                <i className="material-icons">settings</i>
                            </IconButton>
                        </CustomTooltip>

                        <CustomTooltip title="Exibir legenda" style={{fontSize: "13px"}}>
                            <IconButton disableRipple={true} aria-label="legend" onClick={this.toggleLegend} className="config-btn">
                                <i className="material-icons">help</i>
                            </IconButton>
                        </CustomTooltip>
                    </header>
                    <PerfectScrollbar className={classnames("dashboard-scroll ps ps--theme default")}>
                        {this.renderDashboard(unit)}
                    </PerfectScrollbar>
                </section>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
      farms: state.farms.points.farms,
      reportActive: state.Reports.reportActive,
      fullScreen: state.Reports.fullScreen,
      reportTypes: state.Reports.reportTypes,
      culture: state.dashboard.culture,
      info: state.dashboard.charts,
      tableData: state.dashboard.data,
      availablePoints: state.dashboard.points,
      availableYears: state.dashboard.years,
      options: state.dashboard.options
    }
};

function mapDispatchToProps(dispatch) {
    return {
        toggleFullScreen: () => {
            dispatch(toggleFullScreen())
        },
        toggleReportContainer: () => {
            dispatch(toggleReportContainer())
        },
        getDashboardCharts: (data) => {
            dispatch(getDashboardCharts(data))
        },
        getDashboardTable: (data) => {
            dispatch(getDashboardTable(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
