import { authHeader } from './config'

class IntegrationApi {
    static getApiKeys(integration){
        return fetch(process.env.REACT_APP_API + `api/profile/integration/keys?integration=${integration}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static setApiKeys(keys){
        return fetch(process.env.REACT_APP_API + 'api/profile/integration/keys', {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                publicKey: keys.public,
                secretKey: keys.private,
                integration: keys.integration
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static editApiKeys(keys){
        return fetch(process.env.REACT_APP_API + 'api/profile/integration/keys', {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify({
                publicKey: keys.public,
                secretKey: keys.private,
                integration: keys.integration
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static deleteApiKeys(integration){
        return fetch(process.env.REACT_APP_API + 'api/profile/integration/keys', {
            method: 'DELETE',
            headers: authHeader(),
            body: JSON.stringify({
                integration: integration
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static getUserStations(data){
        return fetch(process.env.REACT_APP_API + `api/profile/integration/stations?limit=${data.limit}&page=${data.page}&integration=${data.integration}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static setUserStation(data){
        return fetch(process.env.REACT_APP_API + 'api/profile/integration/stations', {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                farmId: data.farmId,
                stationId: data.stationId,
                integration: data.integration
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static editUserStation(data){
        return fetch(process.env.REACT_APP_API + 'api/profile/integration/stations', {
            method: 'PUT',
            headers: authHeader(),
            body: JSON.stringify({
                farmId: data.farmId,
                stationId: data.stationId,
                integration: data.integration
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static deleteUserStation(data){
        return fetch(process.env.REACT_APP_API + 'api/profile/integration/stations', {
            method: 'DELETE',
            headers: authHeader(),
            body: JSON.stringify({
                farmId: data.farm,
                integration: data.integration
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static getAvailablePoints(integration){
        return fetch(process.env.REACT_APP_API + `api/profile/integration/stations/options?integration=${integration}`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static updateIntegration(data){
        return fetch(process.env.REACT_APP_API + "api/profile/integration/agro1/integrate", {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                id: data.rowId,
                startDate: data.startDate,
                endDate: data.endDate
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }


}

export default IntegrationApi