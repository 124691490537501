import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Typography } from "@material-ui/core";

import KmzAddedFiles from './KmzAddedFiles';
import { showToaster } from '../common/Toaster';
import Loader from "../common/Loader";

import { toggleKmz } from '../../actions/global';
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles';

const styles = {
  containerTitle: {
    marginLeft: '25px',
    marginTop: '5px',
  }
};

const Schedule = ({ classes }) => {
    const [toaster, setToaster] = useState(false);

    const dispatch = useDispatch();
    const loading = useSelector(state => state.profile.loading);
    const kmzActive = useSelector(state => state.global.kmzActive);
    const message = useSelector(state => state.profile.message);
    const status = useSelector(state => state.profile.status);

    useEffect(() => {
      if (toaster) {
        let toasterParams = {};
        if (status === 200) {
          toasterParams = {
            title: 'SUCESSO!',
            message,
            status: true,
            autoClose: true,
          };
        } else if (status === 413) {
          toasterParams = {
            title: 'ERRO!',
            message: 'Arquivo ultrapassa o limite de tamanho',
            status: false,
            autoClose: true,
          };
        } else {
          toasterParams = {
            title: 'ERRO!',
            message: message || 'Erro ao carregar o arquivo. Por favor, tente novamente.',
            status: false,
            autoClose: true,
          };
        }
        showToaster(toasterParams);
        setToaster(false);
      }
    }, [toaster, message, status]);

    function toggleActive() {
        dispatch(toggleKmz())
    }

    return (
      <Fragment>
        <Loader active={ loading } />
        <section
          className={ classnames('report--container visible', { 'active': kmzActive, 'toggler-hide': !kmzActive }) }
        >
          <span
            className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !kmzActive})}
            onClick={toggleActive}
          >
            <i className='material-icons'>play_arrow</i>
          </span>
          <header className='row bhbox-header'>
            <Typography
              className={ classes.containerTitle }
              component="h1"
              variant="h5"
              align="center"
            >
              Arquivos KMZ
            </Typography>
          </header>
          <PerfectScrollbar className='schedule' style={ { margin: 0 } }>
            <KmzAddedFiles setToaster={ setToaster } />
          </PerfectScrollbar>
        </section>
      </Fragment>
    )
}
  
export default withStyles(styles)(Schedule);
