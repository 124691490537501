import React from "react"

const YoutubePlayer = ({source, className}) => {

    let url = source.replace("watch?v=", "embed/").split("&t=")[0]


    if (source) {
        var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(regExp)) {
            return (
                <div className={className}>
                    <iframe src={url} title="video-tutorial" className="content" />
                </div>
            )
        }
    }
    return null;
}

export default YoutubePlayer;