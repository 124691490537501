import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from "moment"

import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import SearchDropdown from '../common/SearchDropdown';
import InputField from '../common/InputField';
import AvailablePoints from './transfer/AvailablePoints';
import DownloadScreen from './DownloadScreen';
import Loader from '../common/Loader';

import { getUsersList } from '../../actions/admin';

import AdminApi from '../../api/admin';

function UserDataExport() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.admin.usersList);

  const [form, setForm] = useState({
    source: '',
    integration: '',
    initialDate: '',
    finalDate: '',
    points: [],
  });
  const [integrations, setIntegrations] = useState([]);
  const [addPoints, setAddPoints] = useState(false);
  const [linkToDownload, setLinkToDownload] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  useEffect(() => {
    const changeUserIntegrations = async () => {
      const integrations = await AdminApi.getUserIntegrations(form.source.value);
      const integrationsArray = getIntegrationsArray(integrations);
      setIntegrations(integrationsArray);
    };

    if (form.source) {
      changeUserIntegrations();
    }
  }, [form.source]);

  useEffect(() => {
    if (linkToDownload) {
      setLoading(false);
    }
  }, [linkToDownload]);

  const handleChange = (e) => {
    setForm((prevForm) => ({ ...prevForm, [e.name]: e }));
  };
  
  const handleDate = (e) => {
    e.persist();
    setForm((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));
  };

  const getSourceOptions = () => {
    return users.length
      ? users
        .map((user) => ({ value: user.id, label: user.email, name: 'source', }))
        .sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
      : [];
  };

  const isButtonDisabled = () => {
    const { source, integration, initialDate, finalDate } = form;
    
    return !source || !integration || !initialDate || moment(initialDate).isAfter(finalDate);
  };

  const handleClick = async () => {
    setIsLoadingData(true);
    
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const endDate = `${year}-${month <= 9 ? '0' + month : month}-${day <= 9 ? '0' + day : day}`;
    
    const { source, integration, initialDate, finalDate, points } = form;

    const data = {
      user_id: source.value,
      start_date: initialDate,
      end_date: finalDate ?? endDate,
      integration: integration.value,
      additional_points: points,
    };

    const response = await AdminApi.exportIntegrationData(data);

    setLinkToDownload(response);
  };

  const resetState = () => {
    window.open(linkToDownload, '_blank');
    setForm({ source: '', integration: '', date: '', points: [] });
    setAddPoints(false);
    setLinkToDownload(null);
  }

  const getIntegrationsArray = (integrations) => {
    const keys = Object.keys(integrations);

    if (keys.length) {
      return keys.map((key) => {
        switch (key) {
          case 'agro1':
            return { value: 'agro1', label: 'Agro1', name: 'integration' };
          case 'construserv':
            return  { value: 'construserv', label: 'Construserv', name: 'integration' };
          case 'field_climate':
            return { value: 'fieldclimate', label: 'Field Climate', name: 'integration' };
          case 'fieldpro':
            return { value: 'fieldpro', label: 'FieldPRO', name: 'integration' };
          case 'zeus':
            return { value: 'zeus', label: 'Zeus', name: 'integration' };
          default:
            return { value: 'user', label: 'Dados do usuário', name: 'integration' };
        }
      });
    }

    return [];
  }

  return (
    <div container className='row admin-information' style={{ margin: '40px', width: '70%' }}>
      <Loader active={isLoading} />
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ mb: linkToDownload ? '80px' : 0 }}>
          <Typography component="h5" variant="h5">
            Exportar Dados de Usuário
          </Typography>
        </Grid>
        {
          isLoadingData
            ? (
              <DownloadScreen
                downloadLink={linkToDownload}
                resetState={resetState}
                isClicked={null}
                datasetTypeValue={null}
              />
            )
            : (
              <Grid item container sx={{ width: '40em' }}>
                <Grid item xs={12}>
                  <Typography component="h6" variant="subtitle1">
                    Selecione o usuário de origem:
                  </Typography>
                  <SearchDropdown
                    name="source"
                    className="dataset-select"
                    value={form.source}
                    onChange={handleChange}
                    isSearchable
                    options={getSourceOptions()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h6" variant="subtitle1">
                    Selecione a integração:
                  </Typography>
                  <SearchDropdown
                    name="integration"
                    className="dataset-select"
                    value={form.integration}
                    onChange={handleChange}
                    isSearchable
                    options={integrations}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h6" variant="subtitle1">
                    Selecione a data de início:
                  </Typography>
                  <InputField
                    placeholder='AAAA-MM-DD'
                    name="initialDate"
                    type="date"
                    onChange={handleDate}
                    value={form.initialDate}
                    active
                    activeFarm="a"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h6" variant="subtitle1">
                    Selecione a data final (Opcional):
                  </Typography>
                  <InputField
                    placeholder='AAAA-MM-DD'
                    name="finalDate"
                    type="date"
                    onChange={handleDate}
                    value={form.finalDate}
                    active
                    activeFarm="a"
                  />
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addPoints}
                            onChange={() => setAddPoints((prev) => !prev)}
                          /> 
                        }
                        label="Gostaria de adicionar pontos a mais?"
                        checked={addPoints}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      { 
                        (form.source && addPoints)
                          ? <AvailablePoints info={form} setInfo={setForm} />
                          : null
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container sx={ {my: '2em'} }>
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: '#6AA426',
                        '&:hover': {
                          backgroundColor: '#6AA446'
                        }
                      }}
                      variant="contained"
                      disabled={isButtonDisabled()}
                      onClick={handleClick}
                    >
                      Exportar Dados
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )
        }
      </Grid>
    </div>
  );
}

export default UserDataExport;
