import PointsApi from '../api/points'
import { toggleSidebar, toggleLoader, toggleSidebarLoader } from './global'

export const NEW_FARM = 'NEW_FARM'
export const EDIT_FARM = 'EDIT_FARM'
export const SET_ACTIVE = 'SET_ACTIVE'
export const SET_FARMS = 'SET_FARMS'
export const EDIT_COORDS = 'EDIT_COORDS'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const ADDING_FLAG = 'ADDING_FLAG'
export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const UPLOAD_DATA = 'UPLOAD_DATA'
export const RESET_UPLOAD = 'RESET_UPLOAD'

export function addFarm(data) {
  return function(dispatch, getState){
    return PointsApi.addPoint(data)
      .then(point => {
        const itens = getState().global
        dispatch(newFarm(point))
        dispatch(setActiveFarm(point['newFarm'][0].id))
        if(itens['loader']){
          dispatch(toggleLoader())
          dispatch(toggleSidebar())
        }else if(itens['sidebarLoader']){
          dispatch(toggleSidebarLoader())
        }
        dispatch(setEditMode(point['newFarm'][0].id.toString()))
        dispatch(addingFlag(true))
      })
      .catch(error => {throw(error)})
  }
}

export function getFarms(user) {
  return function(dispatch){
    return PointsApi.getPoints(user)
      .then(points => {
        dispatch(setFarms(points))
        dispatch(toggleLoader())
      })
      .catch(error => {throw(error)})
  }
}

export function editFarm(id, attrs){
  return function(dispatch){
    return PointsApi.editPoint(attrs)
      .then(point => {
        dispatch(editFarmSuccess(point))
        dispatch(toggleLoader())
        dispatch(toggleSidebar())
      })
      .catch(error => {throw(error)})
  }
}

export function uploadData(params){
  return function(dispatch){
    return PointsApi.uploadData(params)
      .then(response => {
        dispatch(uploadSuccess(response))
      })
      .catch(error => {throw(error)})
  }
}

export function uploadSuccess(response){
  return {type: 'UPLOAD_DATA', response}
}

export function resetUpload(){
  return {type: 'RESET_UPLOAD'}
}

export function setFarms(points) {
  return{
    type: 'SET_FARMS',
    points
  }
}
export function editFarmSuccess(point) {
  return {type: 'EDIT_FARM', point}
}

export function newFarm(point) {
  return {
    type: 'NEW_FARM',
    point
  }
}

export function setActiveFarm(id) {
  return {
    type: 'SET_ACTIVE',
    id
  }
}

export function saveAuxEditedCoords(coords, auxCoords){
  return{
    type: 'EDIT_COORDS',
    coords,
    auxCoords
  }
}

export function deleteSuccess(point){
  return {
    type: 'DELETE_SUCCESS',
    point
  }
}

export function deletePoint(farmId){
  return function(dispatch){
    return PointsApi.deletePoint(farmId).then(() =>{
      dispatch(deleteSuccess(farmId))
      dispatch(toggleLoader())
    }).catch(error => {throw(error)})
  }
}

export function setEditMode(mode){
  return {
    type: 'SET_EDIT_MODE',
    mode
  }
}

export function addingFlag(addingFlag){
  return {
    type: 'ADDING_FLAG',
    addingFlag
  }
}
