import { Grid, Typography } from '@mui/material';
import React from 'react';

export default function Alert(props) {
  const { data } = props;
  return (
    <Grid
      pt={2}
      position="absolute"
      right={0}
      top={0}
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width={400}
      borderLeft="solid 5px #34ca4c"
    >
      <Typography
        ml={2}
        mb={2}
        color={data.type === 'sucess' ? 'green' : 'red'}
      >
        {data.title}
      </Typography>
      <Typography ml={2}>{data.textOne}</Typography>
      <Typography ml={2}>{data.textTwo}</Typography>
      <Grid mt={5} width="30%" borderBottom="solid 3px #34ca4c" />
    </Grid>
  );
}
