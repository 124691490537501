import React, { useEffect, useState } from "react";
import AdminApi from '../../api/admin.js'

import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";

const columns = [
  { id: "email", label: "Email", width: 157 },
  { id: "agrymax_plan", label: "Plano", width: 157 },
  {
    id: "point_count",
    label: "Quantidade de pontos",
    width: 157,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "confirmed_at",
    label: "Ativo",
    width: 157,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "last_login",
    label: "Último acesso",
    width: 157,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "acessAsUser",
    label: "Acessar como usuário",
    width: 157,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

export function UserListNewComponent({ getAllUsers, users, history }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [usersToFilter, setUsersToFilter] = useState([]);
  const [isOrdered, setIsOrdered] = useState(false);

  useEffect(() => {
    if (users.length === 0) {
      getAllUsers();
    }
  }, [getAllUsers, users.length]);

  useEffect(() => {
    if (users.length !== 0) {
      setUsersToFilter(users);
    }
  }, [users]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onRowClick = (userId) => {
    history.push(`/admin/users/edit/${userId}`);
  };

  const filterByEmailOrPlan = ({ target: { value } }) =>
    setUsersToFilter(
      users.filter(
        (user) =>
          user.email.includes(value) || user.agrymax_plan.includes(value)
      )
    );

  const sortByColumns = ({ id }) => {
    setIsOrdered(!isOrdered);
    let auxArrayLastLoginWithoutRegister = [];

    if (id !== "last_login") {
      return !isOrdered
        ? setUsersToFilter(
            usersToFilter.sort(function (a, b) {
              if (a[id] < b[id]) {
                return 1;
              }
              if (a[id] > b[id]) {
                return -1;
              }
              return 0;
            })
          )
        : setUsersToFilter(
            usersToFilter.sort(function (a, b) {
              if (a[id] > b[id]) {
                return 1;
              }
              if (a[id] < b[id]) {
                return -1;
              }
              return 0;
            })
          );
    }
    auxArrayLastLoginWithoutRegister = usersToFilter.filter(
      (user) => user.last_login === "Sem registro"
    );
    const auxArrayWithLoginRegister = usersToFilter
      .filter((user) => user.last_login !== "Sem registro")
      .sort(function (a, b) {
        return !isOrdered
          ? new Date(
              `${b.last_login.split("/")[2]}-${b.last_login.split("/")[1]}-${
                b.last_login.split("/")[0]
              }`
            ) -
              new Date(
                `${a.last_login.split("/")[2]}-${a.last_login.split("/")[1]}-${
                  a.last_login.split("/")[0]
                }`
              )
          : new Date(
              `${a.last_login.split("/")[2]}-${a.last_login.split("/")[1]}-${
                a.last_login.split("/")[0]
              }`
            ) -
              new Date(
                `${b.last_login.split("/")[2]}-${b.last_login.split("/")[1]}-${
                  b.last_login.split("/")[0]
                }`
              );
      });
    setUsersToFilter(
      auxArrayWithLoginRegister.concat(auxArrayLastLoginWithoutRegister)
    );
  };

  return (
    <>
      {users.length !== 0 ? (
        <Grid container item lg={11} xl={11} style={{ margin: "auto" }}>
          <Grid item lg={12} xl={12}>
            <h5>Usuários Cadastrados</h5>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Grid item container xl={4} lg={4} alignItems="center">
              <Grid item>
                <TextField
                  variant="outlined"
                  placeholder="Digite email ou plano"
                  onChange={filterByEmailOrPlan}
                  inputProps={{
                    style: {
                      height: "28px",
                      margin: "0",
                      border: "1px solid #808080",
                      padding: '1px',
                    }
                  }}
                />
              </Grid>
              <Grid item xl={4} lg={4}>
                <SearchIcon
                  style={{
                    backgroundColor: "#6AA426",
                    color: "white",
                    width: "50px",
                    height: "30px",
                    margin: "6px 0 0 0 ",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Link to="/admin/users/add">
                <Button
                  style={{
                    backgroundColor: "#6AA426",
                    color: "white",
                  }}
                >
                  Adicionar usuário
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} xl={12}>
              <Table>
                <TableHead
                  style={{
                    backgroundColor: "#6AA426",
                    cursor: "pointer",
                    width: "150px",
                  }}
                >
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        onClick={() => sortByColumns(column)}
                        key={index}
                        align={column.align}
                        style={{
                          width: column.width,
                          padding: "0 10px 0 10px ",
                          color: "white",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersToFilter
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                onClick={() =>
                                  column.id === "acessAsUser"
                                    ? ""
                                    : onRowClick(row.id)
                                }
                                key={column.id}
                                align={column.align}
                                style={{ padding: "0 10px" }}
                              >
                                {column.id === "acessAsUser" ? (
                                  <Button
                                    onClick={() => AdminApi.getUserTokenToAcessUserAccountWithAdmin(row.id)}
                                    style={{
                                      fontSize: "10px",
                                      backgroundColor: "#6AA426",
                                      color: "white",
                                    }}
                                  >
                                    Acessar conta
                                  </Button>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Itens por página"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div>carregando...</div>
      )}
    </>
  );
}
