import { authHeader } from "./config"

const emails = "api/email_register"

class ScheduleApi {
    static getSchedules(data){
        return fetch(process.env.REACT_APP_API + emails, {
            method: "GET",
            headers: authHeader(),
        })
        .then(response => response.json())
        .catch(error => error)
    }

    static createSchedule(data){
        return fetch(process.env.REACT_APP_API + emails, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify({
                farm_id: data.farm_id,
                period: data.period,
                day_time: data.day_time,
                report_args: data.report_args
            })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static editSchedule(data){
        return fetch(process.env.REACT_APP_API + emails, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(data),
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }

    static deleteSchedule(id){
        return fetch(process.env.REACT_APP_API + emails, {
            method: "DELETE",
            headers: authHeader(),
            body: JSON.stringify({ id })
        })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            return error
        })
    }
}

export default ScheduleApi