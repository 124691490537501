import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import Checkbox from 'rc-checkbox'
import RadioButtonGroup from '../../common/RadioButtonGroup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClickOutside from 'react-click-outside'

import 'rc-checkbox/assets/index.css'

const DATE = new Date();
const CURRENT_MONTH = DATE.getMonth() + 1;

const DIV_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};

const CAD_OPTIONS = [
  {value: '25', text: '25 mm'},
  {value: '50', text: '50 mm'},
  {value: '75', text: '75 mm'},
  {value: '100', text: '100 mm'},
  {value: '125', text: '125 mm'},
  {value: '150', text: '150 mm'},
  {value: '200', text: '200 mm'},
  {value: '250', text: '250 mm'},
  {value: '300', text: '300 mm'},
];

const VISUALIZATION_OPTIONS = [
  {value: 'civil', text: 'Civil'},
  {value: 'agricultural', text: 'Agrícola'}
]

class WeatherDropdown extends Component {
  constructor(props){
    super(props)
    this.state = {
      allDisabled: this.props.radioSelect === '0' ? true : false
    }

    this.radioSelect = this.props.radioSelect
    this.rangeSelect = this.props.rangeSelect
  }

  //Lifecycle methods
  componentDidMount(){
    if(this.props.optionsType === 'years'){
      let attr = {
        type: 'N',
        yearType: 'civil',
        yearRange: 'all'
      }
      this.props.getEnos(attr)
    }
  }

  //#region Helper methods
  setCheckStatus(year){
    if(year === 'all'){
      let yearsFlag = true
      this.props.years.forEach(year => {
        if(!this.props.checkedData.includes(year.toString())){
          yearsFlag = false
        }
      })
      return yearsFlag
    }else{
      return this.props.checkedData.indexOf(year.toString()) !== -1
    }
  }

  renderOptions(filteredData){
    let radioOptions = []
    if(['prec', 'gd', 'rs', 'etp'].includes(this.props.weatherType)){
      radioOptions = [
        {value: 'period' , text: 'Período'},
        {value: 'cmp' , text: 'Comparação'},
        {value: 'anomaly', text: 'Anomalia'},
        {value: 'accumulate', text: 'Acumulado'},
        {value: 'climatology', text: 'Climatologia'}
      ]
    }else if(["heat_wave", "frost_risk", "rainy_days"].includes(this.props.weatherType)) {
      radioOptions = [
        {value: 'period' , text: 'Período'}
      ]
    }else{
      radioOptions = [
        {value: 'period' , text: 'Período'},
        {value: 'cmp' , text: 'Comparação'},
        {value: 'anomaly', text: 'Anomalia'},
        {value: 'climatology', text: 'Climatologia'}
      ]
    }
    let radioOptions2 = []

    if(this.radioSelect !== '0'){
      if(this.radioSelect === 'period'){
        radioOptions2 = [
          {value: 'daily' , text: 'Diário'},
          {value: 'decendial' , text: 'Decendial'},
          {value: 'monthly' , text: 'Mensal'},
          {value: 'annualy', text: 'Anual'}
        ]
      }else if(this.radioSelect === 'cmp' || this.radioSelect === 'climatology'){
        radioOptions2 = [
          {value: 'daily' , text: 'Diário'},
          {value: 'decendial' , text: 'Decendial'},
          {value: 'monthly' , text: 'Mensal'}
        ]
      }else if(this.radioSelect === 'anomaly'){
        radioOptions2 = [
          {value: 'decendial' , text: 'Decendial'},
          {value: 'monthly' , text: 'Mensal'},
          {value: 'annualy', text: 'Anual'}
        ]
      }else if(this.radioSelect === 'accumulate'){
        radioOptions2 = [
          {value: 'daily' , text: 'Diário'}
        ]
      }
    }else{
      radioOptions2 = [
        {value: 'daily' , text: 'Diário'},
        {value: 'decendial' , text: 'Decendial'},
        {value: 'monthly' , text: 'Mensal'},
        {value: 'annualy', text: 'Anual'}
      ]
    }

    if(["exc", "def"].includes(this.props.weatherType)) {
      radioOptions = [
        {value: 'period' , text: 'Período'},
        {value: 'cmp' , text: 'Comparação'}
      ]
      if(this.radioSelect !== '0'){
        if(this.radioSelect === 'period'){
          radioOptions2 = [
            {value: 'daily' , text: 'Diário'},
            {value: 'decendial' , text: 'Decendial'},
            {value: 'monthly' , text: 'Mensal'},
            {value: 'annualy' , text: 'Anual'}
          ]
        }else if(this.radioSelect === 'cmp'){
          radioOptions2 = [
            {value: 'daily' , text: 'Diário'},
            {value: 'decendial' , text: 'Decendial'},
            {value: 'monthly' , text: 'Mensal'}
          ]
        }
      }else{
        radioOptions2 = [
          {value: 'daily' , text: 'Diário'},
          {value: 'decendial' , text: 'Decendial'},
          {value: 'monthly' , text: 'Mensal'},
        ]
      }
    }

    if(this.props.weatherType === 'etretp'){
      radioOptions = [
        {value: 'period' , text: 'Período'},
        {value: 'cmp' , text: 'Comparação'},
        {value: 'anomaly', text: 'Anomalia'}
      ]
      if(this.radioSelect !== '0'){
        if(this.radioSelect === 'period'){
          radioOptions2 = [
            {value: 'daily' , text: 'Diário'},
            {value: 'decendial' , text: 'Decendial'},
            {value: 'monthly' , text: 'Mensal'},
            {value: 'annualy', text: 'Anual'}
          ]
        }else if(this.radioSelect === 'cmp'){
          radioOptions2 = [
            {value: 'daily' , text: 'Diário'},
            {value: 'decendial' , text: 'Decendial'},
            {value: 'monthly' , text: 'Mensal'}
          ]
        }else if(this.radioSelect === 'anomaly'){
          radioOptions2 = [
            {value: 'decendial' , text: 'Decendial'},
            {value: 'monthly' , text: 'Mensal'}
          ]
        }
      }else{
        radioOptions2 = [
          {value: 'daily' , text: 'Diário'},
          {value: 'decendial' , text: 'Decendial'},
          {value: 'monthly' , text: 'Mensal'},
          {value: 'annualy', text: 'Anual'}
        ]
      }
    }
    if(this.props.optionsType === 'radio'){
      return(
        <div>
          <div style={DIV_STYLE}>
            <p style={{ marginTop: 5, marginLeft: 5 }}>Tipo</p>
            <RadioButtonGroup
              name={'type_options'}
              allDisabled={false}
              listOfItems={radioOptions}
              selectedItemCallback={this.handleRadioGraphic}
              checkedOption={this.radioSelect === '0' ? '' : this.radioSelect}
            />
          </div>
          <div style={DIV_STYLE}>
            <p style={{ marginTop: 5, marginLeft: 5 }}>Escala</p>
            <RadioButtonGroup
              name={'range_options'}
              allDisabled={this.state.allDisabled}
              listOfItems={radioOptions2}
              selectedItemCallback={this.handleRadioRange}
              checkedOption={this.rangeSelect === '0' ? '' : this.rangeSelect}
            />
          </div>
        </div>
      )
    }else if(this.props.optionsType === 'variables'){
      let indexVariable = ['plan', 'herb', 'ps', 'fert', 'traf', 'cresc']
      if(this.props.visualizationDropdown){
        radioOptions = VISUALIZATION_OPTIONS
      }else if(this.props.weatherType === 'rainy_days'){
        radioOptions = [
          {value: "0", text: "Maior que 0mm"},
          {value: "5", text: "Maior ou igual a 5mm"}
        ]
      }else if(this.props.weatherType === 'temperatura'){
        radioOptions = [
          {value: 'tmax', text: 'Temperatura Máxima'},
          {value: 'tmin', text: 'Temperatura Mínima'},
          {value: 'tmean', text: 'Temperatura Média'}
        ]
      }else if(this.props.weatherType === 'excdef'){
        if (this.props.isCadDropdown) {
          radioOptions = CAD_OPTIONS;
        } else {
          if(this.props.label === "DEF") {
            radioOptions = [{value: 'exc', text: 'EXC'}]
          } else {
            radioOptions = [{value: 'def', text: 'DEF'}]
          }
        }
      }else if(this.props.weatherType === 'gd'){
        radioOptions = [
          {value: '5', text: '5 °C'},
          {value: '10', text: '10 °C'},
          {value: '15', text: '15 °C'},
          {value: '20', text: '20 °C'}
        ]
      }else if(this.props.weatherType === 'arm' || this.props.weatherType === 'extbh' || (this.props.isCadDropdown && (this.props.weatherType === 'bhbox' || this.props.weatherType === 'etretp' || indexVariable.includes(this.props.weatherType)))){
        radioOptions = CAD_OPTIONS;
      }else if(this.props.weatherType === 'atrmax'){
        radioOptions = [
          {value: 'early', text: 'Precoce'},
          {value: 'medium', text: 'Média'},
          {value: 'late', text: 'Tardia'},
        ]
      } else if (this.props.weatherType === 'heat_wave') {
        radioOptions = [
          { value: '32', text: 'Maior do que 32ºC' },
          { value: '35', text: 'Maior do que 35ºC' },
        ];
      } else{
        if(this.props.weatherGraphicType === 'period'){
          radioOptions = [
            {value: 'etr', text: 'ETR'},
            {value: 'etp', text: 'ETP'},
            {value: 'etretp', text: 'ETR/ETP'}
          ]
        }else{
          radioOptions = [
            {value: 'etr', text: 'ETR'},
            {value: 'etp', text: 'ETP'}
          ]
        }
      }

      return(
        <RadioButtonGroup
          name={'options'}
          listOfItems={radioOptions}
          selectedItemCallback={this.handleRadioVariables}
          checkedOption={this.radioSelect === '0' ? '' : this.radioSelect}
        />
      )
    }else{
      const graphicAgriculturalOutOffDate = CURRENT_MONTH < 7 && this.props.visualizationType === 'agricultural';

      const yearsChecked = Number(new Date().getFullYear()) + 1 === this.props.years[Number(this.props.years.length) - 1] ||
      (Number(new Date().getFullYear()) === this.props.years[Number(this.props.years.length) - 1] && graphicAgriculturalOutOffDate)
      ? this.props.years.slice(0, this.props.years.length - 1)
      : this.props.years

      if(this.props.weatherGraphicType === 'cmp' || this.props.weatherGraphicType === 'accumulate'){
        if (this.props.visualizationType === 'agricultural') {
          return(
            <ul>
              {yearsChecked.map(year => {
                return(
                  <li key={year}>
                    <label>
                      <Checkbox
                        id={year.toString()}
                        name={year.toString()}
                        className='checkbox-item'
                        onChange={this.props.onChange}
                        checked={this.setCheckStatus(year)}
                        disabled={this.setDisabled(year)}
                      />
  
                      &nbsp; {`${year}/${year + 1}`}
                    </label>
                  </li>
                )
              })
            }
          </ul>
        )
        } else {
          return(
            <ul>
              {this.props.years.map(year => {
                return(
                  <li key={year}>
                    <label>
                      <Checkbox
                        id={year.toString()}
                        name={year.toString()}
                        className='checkbox-item'
                        onChange={this.props.onChange}
                        checked={this.setCheckStatus(year)}
                        disabled={this.setDisabled(year)}
                      />
  
                      &nbsp; {year.toString()}
                    </label>
                  </li>
                )
              })
            }
          </ul>
        )
        }
    }else{
      let radioYearsOptions = [];
      let option = {};
      const yearsList = graphicAgriculturalOutOffDate
        ? this.props.years.slice(0, this.props.years.length - 1)
        : this.props.years;
        yearsList.forEach(year => {
        if(this.props.graphicControl){
          option['value'] = year.toString()
          option['text'] = this.props.visualizationType === 'civil'
            ? year.toString()
            : `${year.toString()}/${year + 1}`
          radioYearsOptions.push(option)
          option = {}
        }else{
          if(parseInt(year, 10) >= parseInt(this.props.yearBegin,10)){
            option['value'] = year.toString()
            option['text'] = year.toString()
            radioYearsOptions.push(option)
            option = {}
          }
        }
      })
      if(this.props.graphicControl){
        return(
          <RadioButtonGroup
            listOfItems={radioYearsOptions}
            selectedItemCallback={this.handleRadio}
            checkedOption={this.radioSelect === '0' ? '' : this.radioSelect}
          />
        )
      }else{
        return(
          <RadioButtonGroup
            listOfItems={radioYearsOptions}
            selectedItemCallback={this.handleRadio2}
            checkedOption={this.radioSelect === '0' ? '' : this.radioSelect}
          />
        )
      }
    }
    }
  }

  cadSelector(type, chart) {
    const bhboxProducts = ['arm', 'bhbox', 'etretp', 'extbh', 'excdef']
    const indexProducts = ['herb', 'plan', 'ps', 'fert', 'traf','cresc']
    if((bhboxProducts.includes(chart) || indexProducts.includes(chart)) && type === 'variables'){
      return true
    }

    return false
  }  

  //#region Render methods
  onEnosClick = (event) =>{
    let attr = {}
    if(event.target.id === 'all'){
      attr = {
        type: 'N',
        yearType: 'civil',
        yearRange: 'all'
      }
    }else{
      attr = {
        type: event.target.id,
        yearType: 'civil',
        yearRange: 'yearly'
      }
    }
    this.props.getEnos(attr)
  }

  handleClick = () => {
    this.props.onClick()
  }

  handleSend = (e) => {
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,e.target.id,this.rangeSelect)
  }

  handleRadio = (e) => {
    this.radioSelect = e.toString()
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,'begin',this.rangeSelect)
  }

  handleRadio2 = (e) => {
    this.radioSelect = e.toString()
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,'end',this.rangeSelect)
  }

  handleRadioVariables = (e) => {
    this.radioSelect = e.toString()
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,'variables',this.rangeSelect)
  }

  handleRadioGraphic = (e) => {
    this.radioSelect = e.toString()
    this.rangeSelect = ''
    this.setState({
      allDisabled: false
    })
    this.forceUpdate()
  }

  handleRadioRange = (e) => {
    this.rangeSelect = e.toString()
    this.props.onClick()
    this.props.onSendClick(this.radioSelect,'graphic',this.rangeSelect)
  }

  setDisabled = (year) => {
    if(this.props.checkedData.length <= 2 && this.props.checkedData.indexOf(year.toString()) !== -1){
      return false
    }else if(this.props.checkedData.length === 2){
      return true
    }
  }

  onClickOutside = () => {
    this.props.onClick()

    if(this.props.label === 'Gráfico'){
      this.radioSelect = this.props.radioSelect
      this.rangeSelect = this.props.rangeSelect
    }
  }

  handleClear = () => {
    if (this.props.clear) {
      this.props.clear();
    }
  }
  //#endregion

  render() {
    let filteredData = []
    filteredData = this.props.data

    return(
      <div className={classnames('header-div-item-outline', {'header-div-item-spacing': this.props.rightNeighbor})}>
        <Link to='#' onClick={this.handleClick}>
          <div className="button button--retangle">
            <div className='button--retangle-icon-left'>
              <i className='material-icons'>{this.props.leftIcon}</i>
            </div>
            <div className='button--retangle-icon-text'>
              {this.props.label}
            </div>
            <div className='button--retangle-icon-right'>
              <i className='material-icons'>arrow_drop_down</i>
            </div>
          </div>
        </Link>
        {this.props.active ?
          <ClickOutside onClickOutside={this.onClickOutside}>
            <div className={classnames('dropdown-div', {'weather-dropdown-variables': this.props.optionsType === 'variables'}, {'cad-dropdown': this.cadSelector(this.props.optionsType,this.props.weatherType)})}>
              <div className={classnames("button button--retangle",
                {'button--retangle-active': this.props.active},
                {'button--retangle-active-multiple': this.props.optionsType === 'radio'},
                {'button--retangle-years': this.props.optionsType === 'years' && this.props.active})}>
                <i className='material-icons button--retangle-icon-left'>{this.props.leftIcon}</i>
                <div className='button--retangle-icon-text'>
                  {this.props.label}
                </div>
              </div>
              {this.props.optionsType === 'years' ?
              <div className={classnames("button button--retangle", {'button--retangle-active-events': this.props.active}, {'button--retangle-active-multiple': this.props.optionsType === 'radio'})}>
                {this.props.weatherGraphicType !== 'period' ?
                <div className="col s12">
                  <button className="btn-flat btn-drop btn-events" id='EN' onClick={this.onEnosClick}>{'El Niño'}</button>
                  <button className="btn-flat btn-drop btn-events" id='LN' onClick={this.onEnosClick}>{'La Niña'}</button>
                  <button className="btn-flat btn-drop btn-events" id='N' onClick={this.onEnosClick}>{'Neutro'}</button>
                  <button className="btn-flat btn-drop btn-events" id='all' onClick={this.onEnosClick}>{'TODOS'}</button>
                </div>
                : null}
              </div>
              : null
            }
            <PerfectScrollbar className={classnames(
              {"dropdown-scroll": this.props.optionsType !== 'years'},
              {"dropdown-year-scroll": this.props.optionsType === 'years'},
              {'dropdown-scroll-without-btns': this.props.weatherGraphicType !== 'cmp' && this.props.weatherGraphicType !== 'accumulate'},
            )}>
              <div className={classnames('checkbox-list',{'radio-list': this.props.weatherGraphicType !== 'cmp'}, 'years-list')}>
                {this.renderOptions(filteredData)}
              </div>
            </PerfectScrollbar>

            {(this.props.weatherGraphicType === 'cmp' || this.props.weatherGraphicType === 'accumulate') && this.props.optionsType !== 'variables'?
            <footer className='dropdown-buttons'>
              {this.props.optionsType === 'years' ? <div onClick={this.handleClear}><img className="img-clear" src="/images/clear-icon.png" alt="LIMPAR"/></div> : null}
              <div>
                <button
                  id={
                    this.props.optionsType === 'radio'
                      ? 'graphic'
                      : this.props.graphicControl ? 'begin'
                      : this.props.optionsType === 'variables' ? 'variables'
                      : 'end'
                    }
                  className='btn btn-ok-drop right'
                  onClick={this.handleSend}
                  disabled={
                    this.props.checkedData.length === 0 || (
                      this.props.weatherType !== 'arm' &&
                      this.props.weatherGraphicType === 'cmp' && 
                      this.props.checkedData.length !== 2
                    )
                  }
                >
                  OK
                </button>
              </div>
            </footer>
            : null }
          </div>
        </ClickOutside>
        : null}
      </div>
    )
  }
}

export default WeatherDropdown
