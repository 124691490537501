import React from 'react';
import { useParams } from 'react-router';
import Container from '@mui/material/Container';

import AuditHeader from './AuditHeader';
import AutomaticAudit from './AutomaticAudit';
import ServiceStatus from './ServiceStatus';
import AutomaticAuditFooter from './AutomaticAuditFooter';
import Products from './Products';
import PauloCesar from './PauloCesar';
import Development from './Development';
import Forecast from './Forecast';

const automaticAudit = { 
  enFields: [
    "weather", "box", "index", "fire", 'sugarcane',
    'soy', 'corn', 'cotton', 'wheat', 'eucalyptus',
  ],
  endPoint: 'audits'
};

function auditPage(page) {
  switch (page) {
    case undefined:
    case "automaticAudit":
      return (
        <React.Fragment>
          <AutomaticAudit fields = {automaticAudit}/>
        </React.Fragment>
      );
      
    case "serviceStatus":
      return <ServiceStatus />

    case "forecast":
      return (<Forecast />)

    case 'products':
      return <Products />

    case "pauloCesar":
      return <PauloCesar/>

    default:
      return <Development/>

    // default:
    //   return (<AutomaticAudit fields = {automaticAudit}/>)
  }
}

export default function AuditHome() {
  const { page } = useParams();

  return (
    <React.Fragment>
      <AuditHeader/>
      <Container maxWidth="lg" sx={{ marginTop: "3%" }}>
        {auditPage(page)}
      </Container>
      { (page === "automaticAudit" || page === undefined) && <AutomaticAuditFooter/> }
    </React.Fragment>
  );
}
