import React from 'react';

import { unstable_Box as Box } from '@material-ui/core/Box';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

function PeriodRadios({ classes, handlePeriod }) {
  return (
    <Box className={ classes.modalSection }>
      <Typography>
        Qual período do dia você deseja receber a atualização do gráfico em seu email?
      </Typography>
      <RadioGroup>
        <FormControlLabel
          className={ classes.radioLabel }
          control={ <Radio className={ classes.radioButtons } color="default" /> }
          value="manhã"
          label="Manhã"
          onChange={ handlePeriod }
        />
        <FormControlLabel
          className={ classes.radioLabel }
          control={ <Radio className={ classes.radioButtons } color="default" /> }
          value="tarde"
          label="Tarde"
          onChange={ handlePeriod }
        />
        <FormControlLabel
          className={ classes.radioLabel }
          control={ <Radio className={ classes.radioButtons } color="default" /> }
          value="noite"
          label="Noite"
          onChange={ handlePeriod }
        />
      </RadioGroup>
    </Box>
  );
}

export default PeriodRadios;
