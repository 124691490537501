import React, { Fragment } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  TablePagination
} from "@material-ui/core";

import '../../stylesheets/css/components/table.css'

import EditIcon from "@material-ui/icons/Edit"
import TrashIcon from "@material-ui/icons/Delete"
import CheckIcon from "@material-ui/icons/Check"
import CancelIcon from "@material-ui/icons/Cancel"
import UpdateIcon from "@material-ui/icons/Update"

const row = (
  x,
  i,
  header,
  handleRemove,
  handleUpdate,
  startEditing,
  editRowControl,
  editableColumns,
  updateButton,
  toggleUpdate,
  canEditRow,
  handleChange,
  stopEditing,
  saveEditing,
  externalEdition
) => {
  const isEditing = editRowControl === i;
  return (
    <TableRow key={`tr-${i}`} className={i%2 !== 0 ? 'gray row' : 'row'}>
      {header.map((y, k) => (
        <TableCell key={`trc-${k}`} align='center' className='cell'>
          {editableColumns.includes(k) ? (
            isEditing ? (
              <TextField
                name={y.prop}
                onChange={e => handleChange(e)}
                defaultValue={x[y.prop]}
                inputProps={{ maxLength: 20 }}
              />
            ) : (
              x[y.prop]
            ))
            :
            x[y.prop]
          }
        </TableCell>
      ))}
      {updateButton &&
        <TableCell className={'button-cell'}>
          <Button onClick={() => toggleUpdate(x)}>
            <UpdateIcon />
          </Button>
        </TableCell>
      }
      {editableColumns.length > 0 ?
        <TableCell className={'button-cell'}>
          {isEditing ? (
            <Button disabled={canEditRow} onClick={() => saveEditing(x, i)}>
              <CheckIcon />
            </Button>
          ) : (
            <Button disabled={canEditRow} onClick={() => startEditing(x, i)}>
              <EditIcon />
            </Button>
          )}
        </TableCell>
      : null }
      <TableCell className={'button-cell'}>
        {isEditing ? (
          <Button disabled={canEditRow} onClick={() => stopEditing()}>
            <CancelIcon />
          </Button>
        ) : (
          <Fragment>
            {externalEdition &&
              <Button onClick={() => startEditing(x, i)}>
                <EditIcon />
              </Button>
            }
              <Button disabled={canEditRow} onClick={() => handleRemove(x)}>
                <TrashIcon/>
              </Button>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ({
  data,
  header,
  handleRemove,
  startEditing,
  externalEdition,
  editRowControl,
  editableColumns = [],
  updateButton,
  toggleUpdate,
  canEditRow,
  handleChange,
  handleUpdate,
  stopEditing,
  saveEditing,
  currentPage,
  rowsPerPage,
  rowsPerPageOptions = [30, 60, 120],
  totalItens,
  handleChangeRowsPerPage,
  handleChangePage,
  tableStyle
}) => (
  <Grid className={tableStyle}>
    <Table>
      <TableHead>
        <TableRow className={'header-row'}>
          {header.map((x, i) => (
            <TableCell key={`thc-${i}`} align='center' className={'header-cell'}>{x.name}</TableCell>
          ))}
          {updateButton && <TableCell/>}
          {editableColumns.length > 0 ? <TableCell/> : null }
          <TableCell className={"header-cell"}>{externalEdition? "Ações" : ""}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>    
        {data.map((x, i) =>
          row(
            x,
            i,
            header,
            handleRemove,
            handleUpdate,
            startEditing,
            editRowControl,
            editableColumns,
            updateButton,
            toggleUpdate,
            canEditRow,
            handleChange,
            stopEditing,
            saveEditing,
            externalEdition
          )
        )}
      </TableBody>
    </Table>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        count={totalItens}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
      />
  </Grid>
);