import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import forecastability from '../../images/audit/forecastability.png';
import evolution from '../../images/audit/evolution.png';

import auditTheme from '../../shared/auditTheme';

function Forecast() {
  return (
    <ThemeProvider theme={auditTheme}>
      <Grid container direction="column">
        <Grid item sx={{ my: '3em' }}>
          <Typography component="h2" variant="auditHeader">
            Como funciona o modelo de previsão do tempo?
          </Typography>
        </Grid>
        <Grid item direction="row">
          <Typography component="p" align="left" variant="auditParagraph" paragraph>
            Falar sobre previsão do tempo diz respeito a uma metodologia muito complexa que envolve equipamentos para coletas de dados e criação de modelos situacionais, que podem variar em assertividade e precisão. Isso foi permitido a partir de diversos avanços científicos que ocorreram a partir do século XIX, na evolução da termodinâmica que resultou a compreensão dos princípios físicos fundamentas que regem a atmosfera, com isso conseguindo criar os modelos de circulação geral da atmosfera (MCGAs) que seriam os modelos de previsão. O Brasil aderiu o MCGA rotineiramente para realizar as previsões no final de 1994 com implantação do Centro de Previsão do Tempo (CPTEC) e o Instituto Nacional de Pesquisas Espaciais (INPE).
          </Typography>
        </Grid>
        <Grid item container spacing={8}>
          <Grid item md={6}>
            <Typography component="p" align="left" variant="auditParagraph" paragraph>
              Os modelos de previsão atualmente são códigos modernos que representam aproximações numéricas de equações matemáticas que refletem as leis da física, que controlam os movimentos da atmosfera e as interações com componentes do sistema climático e com isso permite a criação dos cenários e geração da previsão. Para realizar esses cálculos é necessário o uso de supercomputadores, que atualmente são capazes de resolver em torno de um quintilhão de operações de soma e subtração por segundo, os quais são utilizados para fazer a previsão do tempo. Esses modelos são alimentados por informações meteorológicas, por isso a importância das coletas de dados.
            </Typography>
            <Typography component="p" align="left" variant="auditParagraph" paragraph>
              A coleta de dados é uma etapa crucial para o processo de previsão, e ela pode ser feita de diversas formas, seja através de aparelhos de medição na terra como estações e balões meteorológicos que são equipados com termômetros, barômetros, anemômetros, pluviômetros e outros sensores, responsáveis por captar variáveis como temperatura, umidade relativa do ar, pressão atmosférica, volume de chuva, direção e velocidade do vento, grau de insolação e duração de ação do Sol. Existe também a possibilidade de captar dados no espaço, que são representados por satélites, como é o caso da previsão disponibilizada pelo NOAA (Administração Oceânica e Atmosférica Nacional – EUA).
            </Typography>
            <Typography component="p" align="left" variant="auditParagraph" paragraph>
              Com esses dados meteorológicos é possível definir qual é o estado da atmosfera, determinando cada elemento espacial do modelo. Além disso, com o avanço dos satélites disponíveis também é possível coletar outras informações, como por exemplo o estado da superfície do mar, fazendo com que a previsão dos modelos melhore. A figura 1 abaixo mostra o avanço dos modelos e a inserção de novas variáveis ao longo do tempo. Quanto mais escura a cor indica um aumento da sua complexidade no sistema.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <img src={evolution} alt="evolution" />
            <Typography component="p" variant="caption" align="left" sx={{ color: '#707070' }}>
              Fonte: IPCC, 2007
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ my: '3em' }}>
          <Typography component="h2" variant="auditHeader">
            A previsão do tempo é confiável?
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p" align="left" variant="auditParagraph" paragraph>
            Segundo o INPE, estima-se que a precisão de uma previsão do tempo pode chegar a 90%, em casos de análise de previsão até 24 horas. Atualmente consegue fazer uma previsão de sete dias com altos índices de acerto. Portanto, é preciso ter consciência de que quanto mais distante for a previsão, ou seja de longo prazo, menor é a probabilidade de acerto.
          </Typography>
          <Typography component="p" align="left" variant="auditParagraph" paragraph>
            No entanto há limitações e, por isso, é impossível se fazer previsões precisas de quando inicia a estação chuvosa e como vai ser, o tempo de duração de um veranico com meses de antecedências. Para isso há necessidade de um maior conhecimento científico sobre os fenômenos meteorológicos e melhores meios computacionais e observacionais.
          </Typography>
          <Typography component="p" align="left" variant="auditParagraph" paragraph>
            Além do Brasil ser um país tropical com muita variabilidade climática, outra causa das incertezas na previsão para o país é a defasagem do equipamento que é utilizado aqui, segundo especialistas, a maior parte da tecnologia meteorológica foi desenvolvida paras países de latitude média e o Brasil tem a maior parte do seu território na região intertropical, fazendo com que os modelos não captem certas variabilidades e particularidades do país.
          </Typography>
        </Grid>
        <Grid item sx={{ my: '3em' }}>
          <Typography component="h2" variant="auditHeader">
            Como funciona a assertividade da previsão
          </Typography>
        </Grid>
        <Grid item container spacing={8}>
          <Grid item md={6}>
            <Typography component="p" align="left" variant="auditParagraph" paragraph>
              A base cientifica para as previsões numéricas de clima deriva principalmente da previsibilidade das condições em torno e sobretudo da temperatura da superfície do mar (TSM), devido à grande influência desta na determinação das condições atmosféricas futuras. No Brasil é possível fazer previsões de confiabilidade relativamente alta de chuva para a Região Nordeste devido à sua grande dependência com o estado dos oceanos Atlântico e Pacífico tropicais. Provavelmente essa seja a região continental, em todo o globo, com maior previsibilidade climática. Além dela, o Sul do Brasil apresenta previsibilidade climática de confiabilidade média, como mostra na figura 2.
            </Typography>
            <Typography component="p" align="left" variant="auditParagraph" paragraph>
              Por outro lado, existem regiões em que a previsibilidade climática é mais baixa. No Brasil, as regiões Sudeste e Centro-Oeste apresentam as menores confiabilidades devido à menor dependência com relação ao estado dos oceanos e à grande variedade e variabilidade dos sistemas meteorológicos que as atingem. Os fenômenos que contribuem para a ocorrência de chuva nessas regiões são controlados no período chuvoso, fundamentalmente, pelas condições de umidade e instabilidade do ar e por sistemas meteorológicos transientes, notadamente as frentes frias que organizam as chuvas sobre grandes áreas. No período de inverno, as frentes frias podem provocar quedas significativas de temperatura, mas normalmente não conseguem gerar precipitações abundantes. Portanto, uma previsão climática nesse sentido exigiria prever com precisão o comportamento, o deslocamento, o número e a intensidade de sistemas meteorológicos transientes, fato que é praticamente impossível.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <img src={forecastability} alt="forecastability" />
            <Typography component="p" variant="caption" align="left" sx={{ color: '#707070' }}>
              Fonte: Sampaio e Dias
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ my: '3em' }}>
          <Typography component="p" variant="auditCaption" align="left" paragraph>
            Fontes:
          </Typography>
          <a
            href="https://www.uol.com.br/tilt/noticias/redacao/2022/10/07/esperava-chuva-e-ela-nao-veio-por-que-previsao-do-tempo-erra-tanto.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography component="p" variant="auditCaption" align="left" paragraph>
              https://www.uol.com.br/tilt/noticias/redacao/2022/10/07/esperava-chuva-e-ela-nao-veio-por-que-previsao-do-tempo-erra-tanto.htm
            </Typography>
          </a>
          <a
            href="https://www.revistas.usp.br/revusp/article/view/99179"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography component="p" variant="auditCaption" align="left" paragraph>
              SAMPAIO, G.; DIAS, P. L. da S. Evolução dos Modelos Climáticos e de Previsão de Tempo e Clima. Revista USP, [S. l.], n. 103, p. 41-54, 2014. DOI: 10.11606/issn.2316-9036.v0i103p41-54. Disponível em: https://www.revistas.usp.br/revusp/article/view/99179. Acesso em: 31 out. 2022.
            </Typography>
          </a>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Forecast;
