import React from 'react'

function Loader({ active }) {
  if (!active) {
    return null;
  }

  return (
    <div>
      <div className="overlay">
        <div className="overlay-content">
          <div className="loader"/>
        </div>
      </div>
    </div>
  );
}

export default Loader;
