import React, { Fragment, Component } from "react"
import { connect } from "react-redux"
import classnames from "classnames"
import { isEqual } from "lodash"

import { toggleReportContainer, generateReport, resetReport, toggleFarmsDropdown, toggleYearsDropdown, setWeatherTypes } from "../actions/reports"
import { changeMapDay, setMapYear, generateMapData } from "../actions/map"
import { getEnos } from '../actions/enos'
import { generatingMapData, toggleLoader, toggleLeftLoader, changeVisualizationYear, changeFilterParams } from '../actions/global'

import WeatherDropdown from "../components/reports/weather/WeatherDropdown"
import BhboxBarChart from "../components/bhbox/BhboxBarChart"
import VisualizationDropdown from "../components/reports/productivity/VisualizationDropdown"

import {numberToMonth, monthToNumber, leapYear, setDates, setDecendialNumber, agriculturalYearSubtraction} from '../shared/utils/DatesUtils'
import FilterMap from "./FilterMap"

const DATE = new Date();
const CURRENT_YEAR = DATE.getFullYear();
const CURRENT_MONTH = DATE.getMonth() + 1
const currentAgriculturalYearDoesNotExist = CURRENT_YEAR && CURRENT_MONTH < 7;

class BhboxProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: undefined,
            yearBegin: ((new Date()).getFullYear()).toString(),
            yearEnd: ((new Date()).getFullYear()).toString(),
            selectedYear: ((new Date()).getFullYear()).toString(),
            compareYears: [((new Date()).getFullYear() - 1).toString(),((new Date()).getFullYear()).toString()],
            radioSelect: "0",
            rangeSelect: '0',
            checkedData: [],
            bhBoxCheckedData: {
              EN: [],
              LN: [],
              N: [],
              all: []
            },
            bhBoxSelectedEnoEvent: "all",
        }
    }

    shouldComponentUpdate

    componentDidUpdate(prevProps) {
      if(this.props.report === undefined && !isEqual(this.props.report, prevProps.report)) {
          this.setState({
              data: undefined
          })
      }
      if(this.props.report && this.props.report.report && !isEqual(this.props.report, prevProps.report)) {
          this.setState({
              data: this.props.report.report[0]
          })
      }
    }

    toggleWeatherGraphicTypeDropdown = () => {
        this.setState({
          weatherGraphicTypeControl: !this.state.weatherGraphicTypeControl
        })
    }

    handleAgriculturalYearDoesNonExistent = (type) => {
      if (type === 'agricultural') {
        this.props.setMapYear(`${CURRENT_YEAR - 1}`);
        this.setState({
            yearBegin: `${CURRENT_YEAR - 1}`,
            yearEnd: `${CURRENT_YEAR - 1}`,
        });
      } else {
        this.props.setMapYear(`${CURRENT_YEAR }`);
        this.setState({
            yearBegin: `${CURRENT_YEAR}`,
            yearEnd: `${CURRENT_YEAR}`,
        });
      }
    }

    setLastDay(dec, month, year){
      let months30 = ['04', '06', '09', '11']
      let months31 = ['01', '03', '05', '07', '08', '10', '12']
      let today = new Date()
      let day = today.getDate()
      let currentMonth = today.getMonth() + 1
      let currentYear = today.getFullYear()
  
      if(day < 10) {
          day = '0' + day
      }
  
      if(currentMonth < 10) {
          currentMonth = '0' + currentMonth
      }
  
      let currentYearCompare = currentYear === year ? true : false
      let lastDay = undefined
  
      if(dec === '1'){
        lastDay = '10'
      }else if(dec === '2'){
        lastDay = '20'
      }else{
        if(months30.includes(month)){
          lastDay = '30'
        }else if(months31.includes(month)){
          lastDay = '31'
        }else{
          if(leapYear(year)){
            lastDay = '29'
          }else{
            lastDay = '28'
          }
        }
      }
  
      if(currentYearCompare){
        return day
      }
  
      return lastDay
    }

    handleCheckData = (newData, selectorType=null) => {
      if(newData.target['name'] === 'all_farms'){
        let selectedFarms = this.state.farmsCheckedData.slice()
  
        if(newData.target['checked']){
          this.props.farms['farms'].forEach(farm => {
            if(!selectedFarms.includes(farm['id'].toString())){
              selectedFarms.push(farm['id'].toString())
            }
          })
  
          this.setState({
            farmsCheckedData: selectedFarms
          })
        }else{
          this.setState({
            farmsCheckedData: []
          })
        }
      }else if(newData.target['id'] === 'all'){
        let selected = this.state.checkedData.slice()
        const { bhBoxCheckedData, bhBoxSelectedEnoEvent } = this.state;
  
        if (newData.target['checked']) {
          this.props.enos.forEach(year => {
            if (!selected.includes(year.toString()))
              selected.push(year.toString());
  
            if (!bhBoxCheckedData[bhBoxSelectedEnoEvent].includes(year.toString()))
              bhBoxCheckedData[bhBoxSelectedEnoEvent].push(year.toString());
          });
  
          this.setState({
            checkedData: selected.slice(),
            bhBoxCheckedData: bhBoxCheckedData,
          });
        } else {
          bhBoxCheckedData[bhBoxSelectedEnoEvent] = [];
  
          this.setState({
            checkedData: [],
            bhBoxCheckedData
          });
        }
      }else if(newData.length < 1){
        if(selectorType === 'points'){
          this.setState({
            farmsCheckedData: newData
          })
        }else{
          this.setState({
            checkedData: newData
          })
        }
      }else if(newData.length > 1){
        if(selectorType === 'points'){
          this.setState({
            farmsCheckedData: newData
          })
        }else{
          this.setState({
            checkedData: newData
          })
        }
      }else{
        let data = undefined
        if(selectorType === 'points'){
          data = this.state.farmsCheckedData
          if(newData.target['checked']){
            data.push(newData.target['id'].toString())
            this.setState({
              farmsCheckedData: data
            })
          }else{
            if(this.state.farmsCheckedData.includes(newData.target['id'].toString())){
              let index = this.state.farmsCheckedData.indexOf(newData.target['id'].toString())
              data.splice(index, 1)
              this.setState({
                farmsCheckedData: data
              })
            }
          }
        }else{
          data = this.state.checkedData;
          let bhBoxCheckedDataCopy = this.state.bhBoxCheckedData;
          let selectedEnoEvent = this.state.bhBoxSelectedEnoEvent;
  
          if (newData.target['checked']) {
            data.push(newData.target['id'].toString())
            bhBoxCheckedDataCopy[selectedEnoEvent].push(newData.target['id'].toString())
  
            this.setState({
              checkedData: data,
              bhBoxCheckedData: bhBoxCheckedDataCopy,
            })
          } else {
            let nextState = {};
  
            if(this.state.checkedData.includes(newData.target['id'].toString())){
              let index = this.state.checkedData.indexOf(newData.target['id'].toString());
  
              data.splice(index, 1);
              nextState.checkedData = data;
            }
  
            if (bhBoxCheckedDataCopy[selectedEnoEvent].includes(newData.target['id'].toString())) {
              let index = bhBoxCheckedDataCopy[selectedEnoEvent].indexOf(newData.target['id'].toString());
  
              bhBoxCheckedDataCopy[selectedEnoEvent].splice(index, 1);
              nextState.bhBoxCheckedDataCopy = bhBoxCheckedDataCopy;
            }
  
            if (nextState.checkedData || nextState.bhBoxCheckedDataCopy) {
              this.setState(nextState);
            }
          }
        }
      }
    }

    getDatesToChartChange = (newType, newRange, variable) => {
      let biggestCompareYear = this.state.compareYears.length === 2 ? this.state.compareYears[0] > this.state.compareYears[1] ? this.state.compareYears[0] : this.state.compareYears[1] : this.state.compareYears[0]
      let dayAndMonth = []
      let dayInfo = {}
      let expectedYear = newType === 'cmp' ? biggestCompareYear : this.props.weatherMapDay['year']
      let formattedDates = undefined
  
      if(this.props.graphicRange === newRange){
        if(newRange === 'daily'){
          dayAndMonth = this.props.weatherMapDay['label'].split('/')
          dayInfo = {
            label: this.props.weatherMapDay['label'],
            year: expectedYear,
            variable: variable,
            initial_date: expectedYear +'-'+ dayAndMonth[1].toString() +'-'+ dayAndMonth[0].toString(),
            final_date: expectedYear +'-'+ dayAndMonth[1].toString() +'-'+ dayAndMonth[0].toString()
          }
        }else if(newRange === 'decendial'){
          dayAndMonth = this.props.weatherMapDay['label'].split('_')
          formattedDates = setDates(monthToNumber(dayAndMonth[0]),dayAndMonth[1], expectedYear)
  
          dayInfo = {
            label: this.props.weatherMapDay['label'],
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1]
          }
        }else if(newRange === 'monthly'){
          dayAndMonth = this.props.weatherMapDay['label']
          formattedDates = setDates(monthToNumber(dayAndMonth),'4', expectedYear)
  
          dayInfo = {
            label: this.props.weatherMapDay['label'],
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1]
          }
        }else{
          formattedDates = setDates(undefined, '5', expectedYear)
          dayInfo = {
            label: undefined,
            year: expectedYear,
            variable: variable,
            initial_date: formattedDates[0],
            final_date: formattedDates[1]
          }
        }
      }else{
        if(this.props.graphicRange === 'daily'){
          dayAndMonth = this.props.weatherMapDay['label'] ? this.props.weatherMapDay['label'].split('/') : undefined
          if(newRange === 'decendial'){
            dayAndMonth[0] = setDecendialNumber(parseInt(dayAndMonth[0],10))
            dayAndMonth[1] = dayAndMonth[1] === undefined ? this.props.weatherMapDay.initial_date.substring(5, 7) : dayAndMonth[1]
            formattedDates = setDates(dayAndMonth[1],dayAndMonth[0], expectedYear)
  
            dayInfo = {
              label: numberToMonth(dayAndMonth[1]) + '_' + dayAndMonth[0],
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }else if(newRange === 'monthly'){
            dayAndMonth[1] = dayAndMonth[1] === undefined ? monthToNumber(dayAndMonth[0]) : dayAndMonth[1]
            formattedDates = setDates(dayAndMonth[1], '4', expectedYear)
            dayInfo = {
              label: numberToMonth(dayAndMonth[1]),
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }else{
            formattedDates = setDates(undefined, '5', expectedYear)
            dayInfo = {
              label: undefined,
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }
        }else if(this.props.graphicRange === 'decendial'){
          dayAndMonth = this.props.weatherMapDay['label'] ? this.props.weatherMapDay['label'].split('_') : undefined
          if(newRange === 'daily'){
            let lastDay = this.setLastDay(dayAndMonth[1], monthToNumber(dayAndMonth[0].toString()), parseInt(expectedYear,10))
            dayInfo = {
              label: lastDay + '/' + monthToNumber(dayAndMonth[0].toString()),
              year: expectedYear,
              variable: variable,
              initial_date: expectedYear +'-'+ monthToNumber(dayAndMonth[0].toString()) +'-'+ lastDay,
              final_date: expectedYear +'-'+ monthToNumber(dayAndMonth[0].toString()) +'-'+ lastDay
            }
          }else if(newRange === 'monthly'){
            formattedDates = setDates(monthToNumber(dayAndMonth[0].toString()), '4', expectedYear)
            dayInfo = {
              label: dayAndMonth[0],
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }else{
            formattedDates = setDates(undefined, '5', expectedYear)
            dayInfo = {
              label: undefined,
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }
        }else if(this.props.graphicRange === 'monthly'){
          dayAndMonth = this.props.weatherMapDay['label']
          if(newRange === 'daily'){
            let lastDay = this.setLastDay('3', monthToNumber(dayAndMonth.toString()), parseInt(expectedYear,10))
            dayInfo = {
              label: lastDay + '/' + monthToNumber(dayAndMonth.toString()),
              year: expectedYear,
              variable: variable,
              initial_date: expectedYear +'-'+ monthToNumber(dayAndMonth.toString()) +'-'+ lastDay,
              final_date: expectedYear +'-'+ monthToNumber(dayAndMonth.toString()) +'-'+ lastDay
            }
          }else if(newRange === 'decendial'){
            let today = new Date()
            let day = today.getDate()
  
            if(day < 10) {
                day = '0' + day
            }
  
            let currentDayDecendial = setDecendialNumber(parseInt(day,10))
            formattedDates = setDates(monthToNumber(dayAndMonth.toString()), currentDayDecendial, expectedYear)
            dayInfo = {
              label: dayAndMonth + '_' + currentDayDecendial,
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }else{
            formattedDates = setDates(undefined, '5', expectedYear)
            dayInfo = {
              label: undefined,
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }
        }else{
          let compareDate = new Date()
          if(newRange === 'daily'){
            let day = compareDate.getDate()
            let month = compareDate.getMonth() + 1
            if(month<10) {
              month = '0' + month
            }
  
            if(day < 10){
              day = '0' + day
            }
  
            dayInfo = {
              label: compareDate.getFullYear() === parseInt(expectedYear,10) ? day + '/' + month : '31/12',
              year: expectedYear,
              variable: variable,
              initial_date: compareDate.getFullYear() === parseInt(expectedYear,10) ? expectedYear + '-' + month + '-' + day: expectedYear +'-12-31',
              final_date: compareDate.getFullYear() === parseInt(expectedYear,10) ? expectedYear + '-' + month + '-' + day: expectedYear +'-12-31',
            }
          }else if(newRange === 'decendial'){
            let month = compareDate.getMonth() + 1
            if(month<10) {
              month = '0' + month
            }
            if(compareDate.getFullYear() !== parseInt(expectedYear,10)){
              month = '12'
            }
  
            formattedDates = setDates(monthToNumber(month.toString()), '3', expectedYear)
            dayInfo = {
              label: compareDate.getFullYear() === parseInt(expectedYear,10) ? numberToMonth(month.toString()) + '_3' : 'dez_3',
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }else{
            let month = compareDate.getMonth() + 1
            if(month<10) {
              month = '0' + month
            }
            formattedDates = setDates(month, '4', expectedYear)
            dayInfo = {
              label: compareDate.getFullYear() === parseInt(expectedYear,10) ? numberToMonth(month.toString()) : numberToMonth('12'),
              year: expectedYear,
              variable: variable,
              initial_date: formattedDates[0],
              final_date: formattedDates[1]
            }
          }
        }
      }   
  
      return dayInfo
    }

    getDatesToSameChart = (variable=null,year=null) => {
      let dayAndMonth = []
       let dayInfo = {}
       let expectedYear = year === null ? this.props.weatherMapDay['year'] : year
       let formattedDates = undefined
       let subtractYear = false
   
      if(this.props.reportTypes[0] !== "temperatura"){
         subtractYear = year === null ? agriculturalYearSubtraction(this.props.visualizationType, this.props.report.report[0]) : false
      }
   
      if(this.props.graphicRange === 'daily' && this.props.reportTypes[0] !== 'excdef'){
         dayAndMonth = this.props.weatherMapDay['label'].split('/')
         dayInfo = {
           label: this.props.weatherMapDay['label'],
           year: subtractYear? expectedYear-1 : expectedYear,
           variable: variable,
           initial_date: subtractYear? expectedYear-1 +'-'+ dayAndMonth[1].toString() +'-'+ dayAndMonth[0].toString() : expectedYear +'-'+ dayAndMonth[1].toString() +'-'+ dayAndMonth[0].toString(),
           final_date: subtractYear? expectedYear-1 +'-'+ dayAndMonth[1].toString() +'-'+ dayAndMonth[0].toString() : expectedYear +'-'+ dayAndMonth[1].toString() +'-'+ dayAndMonth[0].toString()
         }
      }else if(this.props.graphicRange === 'decendial' || this.props.reportTypes[0] === 'excdef'){
         dayAndMonth = this.props.weatherMapDay['label'].split('_')
         formattedDates = setDates(monthToNumber(dayAndMonth[0]),dayAndMonth[1], expectedYear)
   
         dayInfo = {
           label: this.props.weatherMapDay['label'],
           year: expectedYear,
           variable: variable,
           initial_date: formattedDates[0],
           final_date: formattedDates[1]
         }
      }else if(this.props.graphicRange === 'monthly'){
         dayAndMonth = this.props.weatherMapDay['label'].split('/')
         if(dayAndMonth.length > 1){
           dayAndMonth = dayAndMonth[1]
         }else{
           dayAndMonth = this.props.weatherMapDay['label'].split('_')
           if(dayAndMonth.length > 1){
             dayAndMonth = dayAndMonth[1]
           }else{
             dayAndMonth = this.props.weatherMapDay['label']
           }
         }
         formattedDates = setDates(monthToNumber(dayAndMonth),'4', expectedYear)
         dayInfo = {
           label: this.props.weatherMapDay['label'],
           year: expectedYear,
           variable: variable,
           initial_date: formattedDates[0],
           final_date: formattedDates[1]
         }
      }else{
         formattedDates = setDates(undefined,'5', expectedYear)
   
         dayInfo = {
           label: this.props.weatherMapDay['label'],
           year: expectedYear,
           variable: variable,
           initial_date: formattedDates[0],
           final_date: formattedDates[1]
         }
      }
      return dayInfo
    }

    handleVisualizationType = (visualization = 'civil') => {

    let startDate;
    let endDate;

    if (visualization === 'agricultural') {

      if (currentAgriculturalYearDoesNotExist && this.props.mapYear === CURRENT_YEAR.toString()) {
        this.handleAgriculturalYearDoesNonExistent(visualization);
      }

      switch (this.props.graphicRange) {
        case 'daily':
          startDate = '01/07';
          endDate = '30/06';
          break;
        case 'decendial':
          startDate = 'jul_1';
          endDate = 'jun_3';
          break;
        case 'monthly':
          startDate = 'jul';
          endDate = 'jun';
          break;
        case 'annualy':
          startDate = '1980';
          endDate = '2023';
          break;
        default:
          startDate = '01/07';
          endDate = '30/06';
          break;
      }

      this.props.changeFilterParams({
        ...this.props.filterParams,
        fromPeriod: '01/07',
        toPeriod: '30/06',
        fromIndex: 1,
        toIndex: 366,
        startDate,
        endDate,
      });
    } else {

      if (currentAgriculturalYearDoesNotExist && this.props.mapYear === `${CURRENT_YEAR - 1}`) {
        this.handleAgriculturalYearDoesNonExistent(visualization);
      }

      switch (this.props.graphicRange) {
        case 'daily':
          startDate = '01/01';
          endDate = '31/12';
          break;
        case 'decendial':
          startDate = 'jan_1';
          endDate = 'dez_3';
          break;
        case 'monthly':
          startDate = 'jan';
          endDate = 'dez';
          break;
        default:
          startDate = '01/01';
          endDate = '31/12';
          break;
      }

      this.props.changeFilterParams({
        ...this.props.filterParams,
        fromPeriod: '01/01',
        toPeriod: '31/12',
        fromIndex: 1,
        toIndex: 366,
        startDate,
        endDate,
      });
    }

      if (this.props.visualizationType === visualization) {
        return;
      }

      const farm = this.props.farms?.farms.find(farm => farm.id === this.props.activeFarm);
      const farmAttrs = {
        type: this.props.graphicType,
        range: this.props.graphicRange,
        initial_date: '1980',
        final_date: ((new Date()).getFullYear() + 1).toString(),
        tb: this.props.tBasal,
        visualization_type: visualization,
      };

      if(farm === undefined){
        const { lat, lng } = this.props.selectedGridPointCoords;
        farmAttrs.coords = lat + ',' + lng;
        farmAttrs.farmId = -1;
        farmAttrs.cad = this.props.lastUsedCad;
      }else{
        farmAttrs.name = farm.name;
        farmAttrs.coords = farm.coords;
        farmAttrs.farmId = this.props.activeFarm;
        farmAttrs.cad = farm.cad;
        farmAttrs.initials = farm.initial;
      }

      this.props.changeVisualizationYear(visualization);
      this.props.toggleReportUndefined()
      this.props.generateReport(this.props.reportTypes, farmAttrs)
    }

    dropdownSendClick = (e = null, second = null, third = null) => {
      let startDate;
      let endDate;

      switch (third) {
        case 'daily':
          startDate = '01/01';
          endDate = '31/12';
          break;
        case 'decendial':
          startDate = 'jan_1';
          endDate = 'dez_3';
          break;
        case 'monthly':
          startDate = 'jan';
          endDate = 'dez';
          break;
        default:
          startDate = '01/01';
          endDate = '31/12';
          break;
      }

      this.props.changeFilterParams({
        ...this.props.filterParams,
        fromPeriod: '01/01',
        toPeriod: '31/12',
        fromIndex: 1,
        toIndex: 366,
        startDate,
        endDate,
      });
      
      
      let day = undefined
      let variable = undefined
      let mapAttrs = {}

      let farm = undefined
      if(this.props.farms !== undefined){
        farm = this.props.farms['farms'].filter(farm => farm.id === this.props.activeFarm)[0]
      }
      let farmAttrs = undefined
  
      if(e === '0' && this.props.graphicType !== 'cmp' && this.props.graphicType !== 'accumulate'){
        return
      }
      if(second === 'begin'){
        let mapYear = undefined
        if(this.props.graphicType === 'cmp' || this.props.graphicType === 'accumulate'){
          if(this.state.checkedData.length > 0){
            let years = this.state.checkedData.slice()
            if(years.length === 2){
              let biggest = years[0] > years[1] ? years[0] : years[1]
              this.props.setMapYear(biggest)
              mapYear = biggest
            }else{
              this.props.setMapYear(years[0])
              mapYear = years[0]
            }
            this.setState({
              compareYears: this.state.checkedData.slice(),
              temperatureControl: this.state.temperatureControl,
              })
          }
        }else{
          if(e > this.state.yearEnd){
            this.setState({
              yearBegin: this.state.yearEnd,
              yearEnd: e,
              beginSelect: this.state.yearEnd
            })
          }else{
            this.setState({
              yearBegin: e,
              yearEnd: this.state.yearEnd,
              beginSelect: e
            })
          }
          this.props.setMapYear(e)
          mapYear = e
        }
  
        variable = this.props.reportTypes[0]
        day = this.getDatesToSameChart(variable, mapYear)
  
        mapAttrs = {
          initial_date: day['initial_date'],
          final_date: day['final_date'],
          type: this.props.graphicType !== undefined ? this.props.graphicType : 'period',
          range: this.props.reportTypes[0] === 'bhbox' ? 'daily' : this.props.graphicRange !== undefined ? this.props.graphicRange : 'daily',
          cad: 75,
          tb: this.props.tBasal,
          soil_texture: this.props.lastUsedSoil,
          visualization_type: this.props.visualizationType
        }        
      }else if(second === 'graphic'){
        if(farm === undefined){
          let gridCoords = this.props.selectedGridPointCoords.lat + ',' + this.props.selectedGridPointCoords.lng
          farmAttrs = {
            coords: gridCoords,
            farmId: -1,
            cad: this.props.lastUsedCad,
            type: e,
            range: third,
            initial_date: '1980',
            final_date: ((new Date()).getFullYear() + 1).toString(),
            tb: this.props.tBasal,
            visualization_type: this.props.visualizationType
          }
        }else{
          farmAttrs = {
            name: farm.name,
            coords: farm.coords,
            farmId: this.props.activeFarm,
            cad: farm.cad,
            initials: farm.initials,
            type: e,
            range: third,
            initial_date: '1980',
            final_date: ((new Date()).getFullYear() + 1).toString(),
            tb: this.props.tBasal,
            visualization_type: this.props.visualizationType
          }
        }
  
        this.setState({
          radioSelect: e,
          rangeSelect: third
        })
  
        variable = this.props.reportTypes[0]
        day = this.getDatesToChartChange(e, third, variable)
        mapAttrs = {
          initial_date: day['initial_date'],
          final_date: day['final_date'],
          type: e,
          range: third,
          cad: 75,
          tb: this.props.tBasal,
          soil_texture: this.props.lastUsedSoil,
          visualization_type: this.props.visualizationType
        }
  
        if(e === 'cmp'){
          this.props.setMapYear(this.state.compareYears.length === 2 ? this.state.compareYears[0] > this.state.compareYears[1] ? this.state.compareYears[0] : this.state.compareYears[1] : this.state.compareYears[0])
        }
  
        this.props.setWeatherTypes(e, third)
        this.props.toggleReportUndefined()
        this.props.generateReport(this.props.reportTypes, farmAttrs)
      }

      this.props.setMapDay(day)
      this.props.generatingMapData()
      this.props.generateMapData(variable, mapAttrs)
      this.props.toggleLeftLoader();
    }

    setDropdownLabels(type) {
        let newLabel = '';
        if(this.state.bhboxType ||
            this.state.graphicActive ||
            this.state.weatherGraphic ||
            this.props.reportTypes.includes('exc') ||
            this.props.reportTypes.includes('def')
        ){
          if(type === 'farms'){
            return (this.state.numberOfFarms.toString() + ' Unidades')
          }else if(type === 'years'){
            return ('Em ' + this.selectedYear)
          }else if(type === 'graphic_years'){
            return (this.selectedYear)
          }else if(type === 'begin'){
            if(this.props.graphicType === 'cmp' || this.props.graphicType === 'accumulate'){
              return('Anos')
            }else{
              newLabel = this.props.visualizationType === 'civil'
                ? this.state.yearBegin
              : `${this.state.yearBegin}/${+this.state.yearBegin + 1}`
              return newLabel
            }
          }else if(type === 'end'){
            return (this.state.yearEnd)
          }else{
            return 'Visualização'
          }
        }else{
          let farm = undefined
          if(this.props.farms !== undefined){
            farm = this.props.farms['farms'].filter(farm => farm.id === this.props.activeFarm)[0]
          }
          if(type === 'farms' && this.props.activeFarm !== -1){
            return ('Unidade ' + farm.initials)
          }else{
            return ('Comparar Anos')
          }
        }
    }

    showHeader() {
        return (
            <Fragment>
                <WeatherDropdown
                    leftIcon='show_chart'
                    label={'Tipo de Gráfico'}
                    active={this.state.weatherGraphicTypeControl }
                    onClick={this.toggleWeatherGraphicTypeDropdown}
                    data={this.state.data}
                    checkedData={this.state.checkedData}
                    onChange={ this.handleCheckData }
                    onSendClick={ this.dropdownSendClick }
                    selectedYear={this.state.selectedYear}
                    graphicControl={false}
                    yearBegin={this.state.yearBegin}
                    optionsType={'radio'}
                    weatherType={this.props.reportTypes[0]}
                    radioSelect={this.state.radioSelect}
                    rangeSelect={this.state.rangeSelect}
                    rightNeighbor={true}
                    visualizationType={this.props.visualizationType}
                />
                {this.props.graphicRange === 'annualy' ? null :
                    <WeatherDropdown
                        leftIcon='event'
                        label={this.setDropdownLabels('begin')}
                        active={this.props.farmsDropdownActive }
                        onClick={this.props.toggleFarmsDropdown }
                        data={this.state.data}
                        checkedData={this.state.checkedData}
                        onChange={ this.handleCheckData }
                        onSendClick={ this.dropdownSendClick }
                        selectedYear={this.state.selectedYear}
                        graphicControl={true}
                        yearBegin={this.state.yearBegin}
                        optionsType={'years'}
                        weatherType={this.props.reportTypes[0]}
                        weatherGraphic={this.props.graphicType}
                        weatherGraphicType={this.props.graphicType}
                        years={this.props.enos}
                        getEnos={this.props.getEnos}
                        radioSelect={this.state.beginSelect}
                        rightNeighbor={true}
                        clear={this.clearCheckedData}
                        visualizationType={this.props.visualizationType}
                    />
                }
                <VisualizationDropdown
                  visualizationType={this.props.visualizationType}
                  handleVisualizationType={this.handleVisualizationType}
                />
                <FilterMap
                  visualizationType={this.props.visualizationType}
                  graphicRange={this.props.graphicRange}
                  graphicType={this.props.graphicType}
                  changeFilterParams={this.props.changeFilterParams}
                  filterParams={this.props.filterParams}
                />
            </Fragment>
        )
    }

    showTitle() {
        let farm = undefined;

        if(this.props.report !== undefined) {
            if(this.props.farms !== undefined) {
                farm = this.props.farms["farms"].filter(farm => farm.id === this.props.activeFarm)[0]
            }
            let farmNameWithYear = "";

            if(farm === undefined) {
                farmNameWithYear = this.props.selectedGridPointName;
            }
            else {
                farmNameWithYear = farm.name === "" ? "Ponto " + farm.initials : farm.name
            }

            switch(this.props.reportTypes[0]) {
                case "exc":
                  farmNameWithYear += " | Excedente Hídrico";
                  break;
                case "def":
                  farmNameWithYear += " | Deficit Hídrico"
                  break;
                default:
                  break;
            }

            if(this.props.graphicType === "period") {
                farmNameWithYear = `${farmNameWithYear} | ${this.props.visualizationType === 'civil'
                  ? this.state.yearBegin
                  : `${this.state.yearBegin} - ${+this.state.yearBegin + 1}`
                }`
            }

            return(
                <div className={'row farm-info-header'}>
                    <div className='col s12 bhbox--labels-farm-name bhbox--labels-farm-name-weather'>{this.props.activeFarm !== -1 ? farmNameWithYear : farmNameWithYear}</div>
                </div>
            )

        }
    }

    showReport() {
      if(this.props.report) {
        return (
            <BhboxBarChart
                showTooltips={this.props.showTooltips}
                graphicType={this.props.graphicType}
                graphicRange={this.props.graphicRange}
                yearBegin={this.state.yearBegin}
                yearEnd={this.state.yearEnd}
                data={this.state.data}
                fullScreen={this.props.fullScreen}
                reportTypes={this.props.reportTypes[0]}
                compareYears={this.state.compareYears}
                tb={this.props.tBasal}
                soil_texture={this.props.lastUsedSoil}
                visualization_type={this.props.visualizationType}
                lastUsedCad={this.props.lastUsedCad}
                filterParams={this.props.filterParams}
            />
        )
        }
        else {
          return null
        }
    }

    render() {

        return (
            <Fragment>
                <section className={ classnames('report--container', { 'active': this.props.active, 'full-screen': this.props.fullScreen, 'visible': this.props.reportTypes !== '' , 'toggler-hide': !this.props.active && this.props.report === undefined}) }>
                    <span
                      className={
                        classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.props.active}
                        )
                      }
                      onClick={ this.props.toggleActive }
                    >
                        <i className='material-icons'>play_arrow</i>
                    </span>
                    <header className='row bhbox-header'>
                        <div title='Fullscreen' className='valign-wrapper'>
                            <span className='valign-wrapper' onClick={ e => {
                                e.preventDefault()
                                this.props.toggleFullScreen()
                            } }><i className='material-icons'>{ this.props.fullScreen ? 'picture_in_picture_alt' : 'aspect_ratio' }</i></span>
                        </div>
                        <div className={classnames('header-div')}>
                            {this.state.data && this.showHeader()}
                        </div>
                        <div className="header-graphic" style={{marginRight: '4px'}}>
                            <span title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'} onClick={this.props.toggleTooltips}>
                                <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                            </span>
                        </div>
                    </header>
                    {this.state.data && this.showTitle()}
                    {this.state.data ?
                        <div className={classnames('report--content', 'bar-graphic')}>
                            {this.showReport()}
                        </div>
                    :
                        <div className='loading-screen'>
                            Calculando...
                        </div>
                    }
                </section>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        farms: state.farms.points,
        activeFarm: state.farms.activeFarm,
        active: state.Reports.reportActive,
        fullScreen: state.Reports.fullScreen,
        reportTypes: state.Reports.reportTypes,
        report: state.Reports.report,
        graphicType: state.Reports.graphicType,
        graphicRange: state.Reports.graphicRange,
        yearsDropdownActive: state.Reports.yearsDropdown,
        farmsDropdownActive: state.Reports.farmsDropdown,
        selectedGridPointName: state.map.selectedGridPointName,
        selectedGridPointCoords: state.map.selectedGridPointCoords,
        lastUsedCad: state.Reports.lastUsedCad,
        weatherMapDay: state.map.weatherMapDay,
        showTooltips: state.global.showTooltips,
        visualizationType: state.global.visualizationType,
        enos: state.enos.enos,
        mapYear: state.map.mapYear,
        filterParams: state.global.filterParams,
    }
}

function MapDispatchToProps(dispatch) {
    return {
        toggleYearsDropdown: () => {
            dispatch(toggleYearsDropdown())
        },
          toggleFarmsDropdown: () => {
            dispatch(toggleFarmsDropdown())
        },
        getEnos: (type) => {
            dispatch(getEnos(type))
        },
        setWeatherTypes: (graphicType, graphicRange) => {
          dispatch(setWeatherTypes(graphicType,graphicRange))
        },
        toggleReportUndefined: () => {
          dispatch(resetReport())
        },
        generateReport: (type, attr) =>  {
          dispatch(generateReport(type, attr))
        },
        setMapDay: (day) => {
          dispatch(changeMapDay(day))
        },
        setMapYear: (year) => {
          dispatch(setMapYear(year))
        },
        generateMapData: (reportTypes, attrs) => {
          dispatch(generateMapData(reportTypes,attrs))
        },
        generatingMapData: () => {
          dispatch(generatingMapData())
        },
        toggleLoader: () => {
          dispatch(toggleLoader())
        },
        toggleLeftLoader: () => {
          dispatch(toggleLeftLoader())
        },
        toggleActive: () => {
          dispatch(toggleReportContainer())
        },
        changeVisualizationYear: (visualization) => {
          dispatch(changeVisualizationYear(visualization))
        },
        changeFilterParams: filterParams => {
          dispatch(changeFilterParams(filterParams))
        },
    }
}

export default connect(mapStateToProps, MapDispatchToProps)(BhboxProducts);