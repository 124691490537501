import { Typography, styled } from '@mui/material';

export const MainHeader = styled(Typography)({
  color: '#373737',
  fontSize: '2.3rem',
  fontWeight: '500',
});

export const SecondHeader = styled(Typography)({
  color: '#707070',
  fontSize: '1.3rem',
});

export const Paragraph = styled(Typography)({
  color: '#373737',
  fontSize: '1.3rem',
  textAlign: 'left',
  lineHeight: 1.5,
});

export const Caption = styled(Typography)({
  color: '#707070',
  textAlign: 'center',
});
