import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { AGRYTOOLS } from "../shared/utils/consts"

import * as mapColors from '../shared/utils/MapColorsUtils'

class MapColorsInfo extends Component {

  setValuesArray = () => {
    if (this.props.mapDay.variable === undefined || ["etretp", "atrmax", "heat_wave", "frost_risk", "rainy_days"].includes(this.props.mapDay.variable)) {
      return []
    }

    if(this.props.graphicType === 'anomaly'){
      if(['tmax', 'tmin', 'tmean'].includes(this.props.mapDay['variable'])){
        if(this.props.graphicRange === 'annualy'){
          return mapColors.temp_anomaly_2.slice()
        }else{
          return mapColors.temp_anomaly_1.slice()
        }
      }else if(['u2', 'ur'].includes(this.props.mapDay['variable'])){
        return mapColors[`${this.props.mapDay['variable']}_anomaly`].slice()
      }else if(['etp', 'etr'].includes(this.props.mapDay['variable'])){
        return mapColors[`etp_anomaly_${this.props.graphicRange}`].slice()
      }else{
        return mapColors[`${this.props.mapDay['variable']}_anomaly_${this.props.graphicRange}`].slice()
      }
    }else{
      if(this.props.mapDay['variable'] === 'tmean'){
        return mapColors.tmed.slice()
      }else if(this.props.mapDay['variable'] === 'gd'){
        if(this.props.graphicRange === 'daily'){
          return mapColors.gd_1.slice()
        }else if(this.props.graphicRange === 'decendial'){
          return mapColors.gd_2.slice()
        }else if(this.props.graphicRange === 'monthly'){
          return mapColors.gd_3.slice()
        }else{
          return mapColors.gd_4.slice()
        }
      }else if(this.props.mapDay['variable'] === 'u2'){
        if(this.props.graphicRange === 'daily' || this.props.graphicRange === 'decendial'){
          return mapColors.u2_1.slice()
        }else{
          return mapColors.u2_2.slice()
        }
      }else if(this.props.mapDay['variable'] === 'etp' || this.props.mapDay['variable'] === 'etr'){
        if(this.props.graphicRange === 'daily'){
          return mapColors.etp_1.slice()
        }else if(this.props.graphicRange === 'decendial'){
          return mapColors.etp_2.slice()
        }else if(this.props.graphicRange === 'monthly'){
          return mapColors.etp_3.slice()
        }else{
          return mapColors.etp_4.slice()
        }
      }else if(this.props.mapDay['variable'] === 'prec'){
        if(this.props.graphicRange === 'daily'){
          return mapColors.p_1.slice()
        }else if(this.props.graphicRange === 'decendial'){
          return mapColors.p_2.slice()
        }else if(this.props.graphicRange === 'monthly'){
          return mapColors.p_3.slice()
        }else{
          return mapColors.p_4.slice()
        }
      }else if(this.props.mapDay['variable'] === 'fmaac'){
        return mapColors.agryfire.slice()
      }else if(this.props.mapDay['variable'] === 'excdef' || this.props.mapDay['variable'] === 'extbh'){
        return mapColors.excdef_1.slice()
      }else if(this.props.mapDay['variable'] === 'arm'){
        return mapColors[`arm_${this.props.lastUsedCad}`].slice()
      }else if(this.props.mapDay['variable'] === 'cane-flowering'){
        return mapColors.flowering_risk.slice()
      }else if(this.props.mapDay['variable'] === 'exc'){
        switch(this.props.graphicRange){
          case 'daily':
            return mapColors.exc_1.slice();
          case 'decendial':
            return mapColors.exc_2.slice();
          case 'monthly':
            return mapColors.exc_3.slice();
          case 'annualy':
            return mapColors.exc_4.slice();
          default:
            return [];
        }
      }else if(this.props.mapDay['variable'] === "def"){
        switch(this.props.graphicRange){
          case 'daily':
            return mapColors.def_1.slice();
          case 'decendial':
            return mapColors.def_2.slice();
          case 'monthly':
            return mapColors.def_3.slice();
          case 'annualy':
            return mapColors.def_4.slice();
          default:
            return [];
        }
      }else{
        return mapColors[this.props.mapDay['variable']].slice()
      }
    }
  }

  setUnitLabel = () => {
    if(['tmax', 'tmin', 'tmean'].includes(this.props.mapDay['variable'])){
      return '(°C)'
    }else if(this.props.mapDay['variable'].includes('rs')){
      return '(MJ/m²/d)'
    }else if(this.props.mapDay['variable'].includes('gd')){
      return '(°C dia)'
    }else if(this.props.mapDay['variable'].includes('u2')){
      return '(m/s)'
    }else if(['prec', 'etp', 'excdef', 'etretp', 'cresc', 'etr', 'arm', 'exc', 'def', 'extbh'].includes(this.props.mapDay['variable'])){
      return '(mm)'
    }else if(['ur', 'bhbox', 'cane-flowering'].includes(this.props.mapDay['variable'])){
      return '(%)'
    }else if(AGRYTOOLS.includes(this.props.mapDay['variable'])){
      return '(%)'
    }else if(this.props.mapDay['variable'].includes('fmaac')){
      return ''
    }else{
      return 'ERRO'
    }
  }

  setName = () => {
    let nameString = ''
    if(this.props.mapDay['variable'] === 'tmean'){
      nameString = 'TMED ' + this.setUnitLabel()
    }else if(AGRYTOOLS.includes(this.props.mapDay['variable'])){
      nameString = mapColors.agrytoolsName(this.props.mapDay['variable'])
    }else if(this.props.mapDay['variable'] === 'bhbox'){
      nameString = 'ARM/CAD ' + this.setUnitLabel()
    }else if(this.props.mapDay['variable'] === 'u2'){
      nameString = this.props.mapDay['variable'].toUpperCase() + 'm ' + this.setUnitLabel()
    }else if(this.props.mapDay['variable'] === 'cane-flowering'){
      nameString = 'RISCO ' + this.setUnitLabel()
    }else{
      nameString = this.props.mapDay['variable'].toUpperCase() + ' ' + this.setUnitLabel()
    }

    return nameString
  }

  render() {
    let iterable = this.setValuesArray()
    if(this.props.grid !== undefined && this.props.xavierGrid){
      return(
        <div className={classnames("map-colors-info",
                                  {'map-colors-info-report-open': this.props.reportActive},
                                  {"map-colors-info-accumulate": (['prec', 'gd'].includes(this.props.mapDay['variable']) && this.props.graphicRange === 'annualy') || (parseInt(this.props.lastUsedCad, 10) > 100 && parseInt(this.props.lastUsedCad, 10) !== 125)},
                                  {"map-colors-info-index-widths": AGRYTOOLS.includes(this.props.mapDay['variable'])},
                                  {"map-colors-info-index-1": ['plan', 'fert', 'traf'].includes(this.props.mapDay['variable']) || (['exc', 'def'].includes(this.props.mapDay['variable']) && this.props.graphicRange === "annualy") },
                                  {"map-colors-info-index-2": this.props.mapDay['variable'] === 'cresc' || parseInt(this.props.lastUsedCad, 10) === 125 || this.props.graphicType === 'anomaly'},
                                  {"map-colors-info-index-3": this.props.mapDay['variable'] === 'herb' || (this.props.mapDay['variable'] === 'etp' && this.props.graphicRange === 'annualy')},
                                  {"map-colors-info-index-4": this.props.mapDay['variable'] === 'ps'}
                                  )}>
          <div className="map-colors-variable-name-and-unit">
            {this.setName()}
          </div>
          {iterable.map(row =>{
            return(
              <div key={row['value']} className="inner-div">
                <div className={classnames("colors-square", {'colors-square-transp': row['color'] === 'TRANSP'})}
                      style={ {
                        backgroundColor: row['color'] === 'TRANSP' ? 'rgba(0,0,0,0)' : row['color'],
                        border: row['color'] === 'TRANSP' ? '2px solid #7f7f7f' : '0',
                      } } >
                </div>
                <div className="label-div">
                  <label>{row['value']}</label>
                </div>
              </div>
            )
          })}

        </div>
      )
    }else{
      return(null)
    }
  }
}

function mapStateToProps(state) {
  return {
    mapYear: state.map.mapYear,
    mapDay: state.map.weatherMapDay,
    graphicRange: state.Reports.graphicRange,
    graphicType: state.Reports.graphicType,
    reportActive: state.Reports.reportActive,
    grid: state.map.grid,
    lastUsedCad: state.Reports.lastUsedCad,
    lastUsedSoil: state.Reports.lastUsedSoil
  }
}

export default connect(mapStateToProps, null)(MapColorsInfo)
