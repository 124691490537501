import ReactGA from 'react-ga'

function getVariableName(variable, variation){
  if(variable === 'temperatura'){
    return 'Temperatura do ar'
  }else if(variable === 'rs'){
    return 'Radiação solar'
  }else if(variable === 'gd'){
    return 'Graus-dia'
  }else if(variable === 'prec'){
    return 'Chuva'
  }else if(variable === 'u2'){
    return 'Velocidade do Vento a 2m'
  }else if(variable === 'ur'){
    return 'Umidade relativa do ar'
  }else if(variable === 'etp'){
    return 'Evapotranspiração Potencial'
  }else if(variable === 'excdef'){
    return 'EXC/DEF'
  }else if(variable === 'etretp'){
      return 'Evapotranspiração Real e Potencial'
  }else if(variable === 'arm'){
    return('Armazenamento')
  }else if(variable === 'atrmax'){
    return('ATRMax')
  }else if(variable === 'bhbox'){
    return 'ARM/CAD'
  }else if(variable === 'extbh'){
    return 'Extrato BH'
  }else if(variable === 'plan'){
    return 'Plantio/Semeadura'
  }else if(variable === 'herb'){
    return 'Herbicidas'
  }else if(variable === 'ps'){
    return 'Preparo do Solo'
  }else if(variable === 'traf'){
    return 'Tráfego e Colheita'
  }else if(variable === 'fert'){
    return 'Adubação'
  }else if(variable === 'cresc'){
    return 'Crescimento'
  }else if(variable === 'fmaac'){
    return 'Agryfire'
  }else if(variable === 'productivity'){
    return 'Produtividade geral de cana'
  }else if(variable === 'productivity-spec'){
    return 'Produtividade específica de cana'
  }else if(variable === 'cane-flowering'){
    return 'Risco de florescimento'
  }else if(variable === 'soy-productivity'){
    return 'Produtividade específica de soja'
  }else if(variable === 'soy-general-productivity'){
    return 'Produtividade geral de soja'
  }else if(variable === 'corn-spec-productivity'){
    return 'Produtividade específica de milho'
  }else if(variable === 'corn-general-productivity'){
    return 'Produtividade geral de milho'
  }
}


function getType(type){
  if(type === 'period'){
    return 'Período'
  }else if(type === 'cmp'){
    return 'Comparação'
  }else if(type === 'anomaly'){
    return 'Anomalia'
  }else if(type === 'climatology'){
    return 'Climatologia'
  }else if(type === 'accumulate'){
    return 'Acumulado'
  }
}

function getRange(range){
  if(range === 'daily'){
    return 'Diário'
  }else if(range === 'decendial'){
    return 'Decendial'
  }else if(range === 'monthly'){
    return 'Mensal'
  }else{
    return 'Anual'
  }
}

export function createGAEvent(variable, type, range){
  let category = `${getVariableName(variable)}-${getType(type)}-${getRange(range)}`
  let onlyNameVariables = [
    'bhbox',
    'fmmac',
    'plan',
    'herb',
    'ps',
    'traf',
    'atrmax',
    'fert',
    'cresc',
    'productivity',
    'productivity-spec',
    'cane-flowering',
    'soy-productivity',
    'soy-general-productivity',
    'corn-spec-productivity',
    'corn-general-productivity',
    'eucalyptus-general-productivity'
  ]

  if(onlyNameVariables.includes(variable)){
    category = `${getVariableName(variable)}`
  }

  ReactGA.event({
    category: category,
    action: `Usuário gerou o relatório ${category}`
  })
}
