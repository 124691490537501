import React, { Component } from 'react'

class AtrMaxHelp extends Component {
  render() {
    return(
      <div className="atr-help">
        <div className='row title'>
          <h4>COMO INTERPRETAR O GRÁFICO?</h4>
        </div>
        <div className="row">
          <div className="col s6 help-item">
            <img src='/images/teste.png' alt=''></img>
            <div>
              <p>
                Sempre que a linha de maturação do ano estiver acima da média + desvio padrão teremos:
              </p>
              <p><b>Canavial com Boa Maturação Natural</b></p>
              <p>Faixa com ganhos moderados {'( < 2 kg/t)'} com uso de maturadores</p>
            </div>
          </div>
          <div className="col s6 help-item">
            <img src='/images/teste.png' alt=''></img>
            <div>
              <p>
                Sempre que a linha de maturação do ano estiver entre a média e o desvio padrão positivo teremos:
              </p>
              <p><b>Canavial com Maturação Natural Média</b></p>
              <p>Faixa com ganhos dentro do esperado {'( < 2 - 5 kg/t)'} com uso de maturadores</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s6 help-item">
            <img src='/images/teste.png' alt=''></img>
            <div>
              <p>
                Sempre que a linha de maturação do ano estiver entre a média e o desvio padrão negativo teremos:
              </p>
              <p><b>Canavial com Boa Maturação Baixa</b></p>
              <p>Faixa com ganhos significativos {'( < 5 - 7 kg/t)'} com uso de maturadores</p>
            </div>
          </div>
          <div className="col s6 help-item">
            <img src='/images/teste.png' alt=''></img>
            <div>
              <p>
                Sempre que a linha de maturação do ano estiver abaixo da média - desvio padrão teremos:
              </p>
              <p><b>Canavial com Boa Maturação Muito Baixa</b></p>
              <p>Faixa com alto potencial de ganho {'( > 7 kg/t)'} com uso de maturadores</p>
            </div>
          </div>
        </div>
        <div className="close-icon">
          <span onClick={this.props.close}>
            <i className='material-icons'>clear</i>
          </span>
        </div>
      </div>
    )
  }
}

export default AtrMaxHelp
