import React, { Component } from 'react'
import { isEqual } from "lodash"

import SimpleNav from '../../common/SimpleNav'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../common/Loader'
import { showToaster } from '../../common/Toaster'

import { Grid, Input, Select, Button, MenuItem, FormControl, FormHelperText, InputAdornment, IconButton } from '@material-ui/core'
import EditIcon from "@material-ui/icons/Edit"
import TrashIcon from "@material-ui/icons/Delete"
import CheckIcon from "@material-ui/icons/Check"
import CancelIcon from "@material-ui/icons/Cancel"
import AddCircle from "@material-ui/icons/AddCircle"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import '../../../stylesheets/css/components/integration.css'

import Table from '../../common/Table'
import UpdateModal from "./UpdateModal"

class Integration extends Component {
    constructor(props){
        super(props)

        this.state = {
            publicKey: '',
            privateKey: '',
            isEditingKeys: false,
            stations: [],
            newPoint: '',
            new_station_id: '',
            new_pluviometric_id: '',
            stationEditing: -1,
            station_id: '',
            pluviometric_id: '',
            deletingStationId: '',
            rowsPerPage: 30,
            currentPage: 0,
            totalItens: 0,
            integration: '',
            tableHeader: [],
            loading: false,
            fromDate: null,
            toDate: null,
            currentRow: {},
            showPassword: false
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.integration !== this.state.integration){
            let header = {};
            if(this.state.integration === "agro1") {
                header = [
                    {
                        name: "Ponto",
                        prop: "farm_coord"
                    },
                    {
                        name: "Nome",
                        prop: "farm_name"
                    },
                    {
                        name: "Sigla",
                        prop: "farm_initials"
                    },
                    {
                        name: "ID da Fazenda",
                        prop: "station_id"
                    },
                    {
                        name: "ID do Pluviométrico",
                        prop: "pluviometric_id"
                    }
                ]
            }
            else {
                header = [
                    {
                        name: "Ponto",
                        prop: "farm_coord"
                    },
                    {
                        name: "Nome",
                        prop: "farm_name"
                    },
                    {
                        name: "Sigla",
                        prop: "farm_initials"
                    },
                    {
                        name: "ID da Estação",
                        prop: "station_id"
                    }     
                ]
            }

            this.setState({
                tableHeader: header
            })
            this.refreshPage()
        }
        if(this.props.points.length > 0 && (prevProps.points !== this.props.points)){
            this.setState({
                loading: false
            })
        }
        if(this.props.keys !== prevProps.keys && this.props.points !== prevProps.points){
            this.setState({
                loading: false
            })
        }
        if(!isEqual(this.props.keys, prevProps.keys)) {
            this.setState({
                loading: false
            })
        }
        if(this.props.integrationStatus !== prevProps.integrationStatus && this.props.integrationStatus !== '') {
            let toasterParams
            switch(this.props.integrationStatus){
                case 200:
                    toasterParams = {
                        title: 'SUCESSO!',
                        message: this.props.message,
                        status: true,
                        autoClose: true
                    }            
                    showToaster(toasterParams)
                    break
                default:
                    toasterParams = {
                        title: 'ERRO!',
                        message: this.props.message,
                        status: false,
                        autoClose: true
                    }            
                    showToaster(toasterParams)
                    break
            }
            this.props.resetMessage()

            this.setState({
                loading: false
            })
        }
        if(this.props.status !== "" && this.props.status !== prevProps.status && this.props.message !== 'Estações recuperadas com sucesso!'){
            let toasterParams
            switch(this.props.status){
                case 200:
                    toasterParams = {
                        title: 'SUCESSO!',
                        message: this.props.message,
                        status: true,
                        autoClose: true
                    }            
                    showToaster(toasterParams)
                    break
                case 0:
                    toasterParams = {
                        title: 'ERRO!',
                        message: this.props.message,
                        status: false,
                        autoClose: true
                    }            
                    showToaster(toasterParams)
                    break
                default:
                    return
            }
            this.props.resetMessage()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState){
        if(nextProps.keys !== this.props.keys){
            if(!nextProps.keys){
                this.setState({
                    publicKey: '',
                    privateKey: ''
                })
            }
            else {
                this.setState({
                    publicKey: nextProps.keys.public_key,
                    privateKey: nextProps.keys.secret_key
                })
            }
        }
        if(nextProps.points && (nextProps.stations && nextProps.stations !== this.props.stations)){
            let data = []
            if(this.state.integration === "agro1"){
                nextProps.stations.map(item => {
                    return (
                        data.push({
                            line_id: item.line_id,
                            farm_coord: item.farm_coord,
                            farm_id: item.farm_id,
                            farm_initials: item.farm_initials,
                            farm_name: item.farm_name,
                            station_id: item.station_id.split(",")[0],
                            pluviometric_id: item.station_id.split(",")[1]
                        })
                    )
                })
            }
            else {
                data = nextProps.stations
            }
            this.setState({
                totalItens: nextProps.pagination.total_itens,
                stations: data,
                deletingStation: '',
            })
        }
        if(nextProps.update === true){
            this.refreshPage()
        }
    }

    //Pagination methods
    handleChangePage = ( event, currentPage ) => {
        this.setState({
            currentPage: currentPage
        }, () => {
            const data = {
                limit: this.state.rowsPerPage,
                page: currentPage,
                integration: this.state.integration
            }
            this.props.getUserStations(data)
        })        
    }
    
    handleChangeRowsPerPage = event => {
        this.setState({ 
            rowsPerPage: event.target.value,
            currentPage: 0
        }, () => {
            const data = {
                limit: this.state.rowsPerPage,
                page: this.state.currentPage,
                integration: this.state.integration
            }
            this.props.getUserStations(data)
        })
    };

    handleUpdateModal = (row) => {
        this.setState({
            currentRow: row.farm_id ? row : {}
        })
    }

    onSendIntegrationUpdate = (data) => {
        this.props.updateIntegration(data)
        this.setState({
            loading: true
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleKeyEditing = (bool) => {
        this.setState({
            isEditingKeys: bool
        })
    }

    refreshPage = () => {
        const data = {
            limit: this.state.rowsPerPage,
            page: this.state.currentPage,
            integration: this.state.integration
        }
        this.props.getApiKeys(this.state.integration)
        this.props.getUserStations(data)
        this.props.getAvailablePoints(this.state.integration)
        this.props.resetUpdate()

        this.setState({
            loading: true
        })
    }

    setKeys = (type) => {
        if((['field_climate'].includes(this.state.integration) && (this.state.publicKey === '' || this.state.privateKey === ''))
        || this.state.publicKey === ''){
            return
        }

        let keys = {
            public: this.state.publicKey,
            private: this.state.privateKey,
            integration: this.state.integration
        }

        this.handleKeyEditing(false)

        switch(type){
            case 'new':
                this.props.setApiKeys(keys)
                this.setState({
                    loading: true
                })
                break
            case 'edit':
                if(this.state.publicKey === this.props.keys.public_key && this.state.privateKey === this.props.keys.secret_key){
                    const toasterParams = {
                        title: 'ERRO!',
                        message: 'Não houve alteração nas chaves.',
                        status: false,
                        autoClose: true
                    }            
                    showToaster(toasterParams)
                    return
                }
                else{
                    this.setState({
                        loading: true
                    })
                    this.props.editApiKeys(keys)
                    return
                }
            default:
                return
        }

    }

    deleteKeys = () => {
        const toasterParams = {
            title: 'EXCLUIR CHAVES?',
            message: 'Tem certeza que deseja excluir essas chaves? A integração deixará de funcionar.',
            status: false,
            autoClose: false,
            buttons: [
              {
                name: 'EXCLUIR',
                style: 'delete',
                func: this.onKeyDeletion,
              },
              {
                name: 'CANCELAR',
                func: toast.dismiss
              }
            ]
        }
    
        showToaster(toasterParams)
    }

    onKeyDeletion = () => {
        this.props.deleteApiKeys(this.state.integration)
        this.setState({
            publicKey: '',
            privateKey: '',
            loading: true
        })
    }

    onPointSelect = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    onSetNewStation = () => {
        let data = {
            farmId: this.state.newPoint,
            stationId: this.state.integration === "agro1" ? this.state.new_station_id + "," + this.state.new_pluviometric_id : this.state.new_station_id,
            integration: this.state.integration,
        }

        if(data.farmId === ''){
            const toasterParams = {
                title: 'ERRO!',
                message: 'Por favor selecione um ponto.',
                status: false,
                autoClose: true
            }        
            showToaster(toasterParams)
            return
        }
        if(data.stationId === ''){
            const toasterParams = {
                title: 'ERRO!',
                message: 'Por favor preencha o ID da Estação.',
                status: false,
                autoClose: true
            }        
            showToaster(toasterParams)
            return
        }

        this.props.setUserStation(data)
        this.setState({
            newPoint: '',
            new_station_id: '',
            new_pluviometric_id: '',
            loading: true
        })
    }

    onStationEdit = (row, i) => {
        this.setState({
            stationEditing: i,
            station_id: row.station_id
        })
    }

    onStationStopEdit = () => {
        this.setState({
            stationEditing: -1
        })
    }

    onStationEditSend = (row) => {
        if(this.state.station_id === ''){
            const toasterParams = {
                title: 'ERRO!',
                message: 'Por favor preencha o ID da Estação',
                status: false,
                autoClose: true
            }        
            showToaster(toasterParams)
            this.onStationStopEdit()
            return
        }
        else if(this.state.station_id === row.station_id){
            const toasterParams = {
                title: 'ERRO!',
                message: 'Não houve alteração nas chaves',
                status: false,
                autoClose: true
            }        
            showToaster(toasterParams)
            this.onStationStopEdit()
            return
        }

        const data = {
            farmId: row.farm_id,
            stationId: this.state.integration === "agro1" ? this.state.station_id + "," + this.state.pluviometric_id : this.state.station_id,
            integration: this.state.integration
        }

        this.onStationStopEdit()
        this.props.editUserStation(data)
        
        this.setState({
            station_id: '',
            pluviometric_id: '',
            loading: true
        })
    }

    onStationDelete = (row) => {
        toast.dismiss()

        this.setState({
            stationEditing: -1,
            deletingStationId: row.farm_id
        }, () => {
            const toasterParams = {
                title: 'EXCLUIR ESSA RELAÇÃO?',
                message: `${row.farm_name !== ''? 'Ponto ' + row.farm_name : 'Ponto ' + row.farm_initials} e Estação ${row.station_id}`,
                status: false,
                autoClose: false,
                buttons: [
                  {
                    name: 'EXCLUIR',
                    style: 'delete',
                    func: this.onStationDeletion,
                  },
                  {
                    name: 'CANCELAR',
                    func: toast.dismiss
                  }
                ]
            }        
            showToaster(toasterParams)
        })
    }

    onStationDeletion = () => {
        const data = {
            farm: this.state.deletingStationId,
            integration: this.state.integration
        }
        this.setState({
            loading: true
        })
        this.props.deleteUserStation(data)
    }

    handlePasswordVisibility = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    render(){
        const keys = this.props.keys


        return(
            <div className={'integration-page'}>
                <Loader active={this.state.loading} />
                <ToastContainer className='toast-alert-container'/>
                <UpdateModal toggleUpdate={this.handleUpdateModal} row={this.state.currentRow} sendUpdate={this.onSendIntegrationUpdate} />

                <SimpleNav application={'agrymax'} integrationPage={true}/>

                <Grid container>
                    <Grid item xs={12} className={'first-row'}>
                        <h5 className={'title'}>INTEGRAÇÃO COM APIS EXTERNAS</h5>                    
                        <Grid className={'keys-paper'}>  
                            <Grid container justify='flex-start' alignItems='flex-end' className={'keys-container'}>                 
                                <Grid item container xs={12} className='row'>
                                    <Grid item xs={12} className={'header'}>API:</Grid>
                                    <Grid item xs={12} md={4} className={'selector'}>
                                        <Select
                                            name='integration' 
                                            fullWidth
                                            onChange={this.onPointSelect}
                                            value={this.state.integration}>
                                                <MenuItem key='field_climate' value='field_climate'>Field Climate</MenuItem>
                                                <MenuItem key='fieldpro' value='fieldpro'>FieldPRO</MenuItem>
                                                <MenuItem key='construserv' value='construserv'>Construserv</MenuItem>
                                                <MenuItem key='agro1' value='agro1'>Agro1</MenuItem>
                                                <MenuItem key='zeus' value='zeus'>Zeus</MenuItem>
                                        </Select>
                                        </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} md={4} className='row margin-right'>
                                        <Grid container>
                                            <Grid item xs={8} md={9} className='key-header'>
                                                {this.state.integration === "zeus" ? "E-mail:" : "Chave Pública:" }
                                            </Grid>
                                            <Grid item xs={4} md={3}>
                                                <Grid container>
                                                {keys ?
                                                    <Grid item className={'keys-button'}>
                                                        <Grid container justify='flex-end'>
                                                            <Grid item xs={6}>
                                                                {this.state.isEditingKeys?
                                                                    <Button onClick={() => this.setKeys('edit')}><CheckIcon/></Button>
                                                                :
                                                                    <Button onClick={() => this.handleKeyEditing(true)}><EditIcon/></Button>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {this.state.isEditingKeys?
                                                                    <Button onClick={() => this.handleKeyEditing(false)}><CancelIcon/></Button>
                                                                    :
                                                                    <Button onClick={this.deleteKeys}><TrashIcon/></Button>
                                                                }
                                                            </Grid>
                                                        </Grid>                         
                                                    </Grid>
                                                :
                                                    <Grid item xs={12} className={'keys-button'}>
                                                        <Button onClick={() => this.setKeys('new')}><CheckIcon/></Button>
                                                    </Grid>
                                                }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Input 
                                            name='publicKey' 
                                            onChange={this.handleChange}
                                            fullWidth
                                            value={this.state.publicKey}
                                            disabled={keys? (this.props.keys.public_key && !this.state.isEditingKeys) : false}/>
                                    </Grid>
                                    {['field_climate', 'zeus'].includes(this.state.integration) ?
                                        <Grid item xs={12} md={4} className='row'>
                                            <Grid container>
                                                <Grid item xs={12} className='key-header'>
                                                    {this.state.integration === "zeus" ? "Senha:" : "Chave Privada:" }
                                                </Grid>
                                            </Grid>
                                            <Input 
                                                name='privateKey' 
                                                onChange={this.handleChange}
                                                fullWidth
                                                value={this.state.privateKey}
                                                type={this.state.integration === "zeus" && !this.state.showPassword ? "password" : "text"}
                                                endAdornment={
                                                    this.state.integration === "zeus" ?
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="Visibilidade da senha"
                                                                onClick={this.handlePasswordVisibility}
                                                            >
                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    :
                                                    null
                                                }
                                                disabled={keys? !this.state.isEditingKeys : false}/>
                                        </Grid>
                                    : null }
                                </Grid>                      
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={'second-row'}>
                        <h5 className={'title'}>GERENCIAMENTO DE ESTAÇÕES</h5>

                        <Grid container className={'input-form'} alignItems='center'>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <Grid container className={'row'}>
                                    <Grid item xs={12} className={'header'}>Ponto:</Grid>
                                    <Grid item xs={12} className={'selector'}>
                                        <Select
                                            name='newPoint'
                                            onChange={this.onPointSelect}
                                            disabled={!keys}
                                            value={this.state.newPoint}>
                                                {this.props.points.map(farm => (
                                                    <MenuItem key={farm.value} value={farm.value}>
                                                        {farm.label}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                        </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <Grid container className={'row label'}>
                                    <Grid item xs={12} className={'header'}>{this.state.integration === "agro1"? "ID da Fazenda" : "ID da Estação"}</Grid>
                                    <Grid item xs={12} className={'station'}>
                                        <FormControl margin='dense'>
                                            <Input
                                                name='new_station_id' 
                                                onChange={this.handleChange}
                                                disabled={!keys}
                                                value={this.state.new_station_id}
                                                inputProps={{ maxLength: 20 }}/>
                                        </FormControl>
                                        <FormHelperText className={'helper'}>SENSÍVEL A MAIÚSCULAS</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {this.state.integration === "agro1" && 
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <Grid container className={'row label'}>
                                        <Grid item xs={12} className={'header'}>ID do Pluviométrico:</Grid>
                                        <Grid item xs={12} className={'station'}>
                                            <FormControl margin='dense'>
                                                <Input
                                                    name='new_pluviometric_id' 
                                                    onChange={this.handleChange}
                                                    disabled={!keys}
                                                    value={this.state.new_pluviometric_id}
                                                    inputProps={{ maxLength: 20 }}/>
                                            </FormControl>
                                            <FormHelperText className={'helper'}>SENSÍVEL A MAIÚSCULAS</FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item className={'button-grid'}>
                                <Button disabled={!keys} onClick={this.onSetNewStation}>
                                    <AddCircle/>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                {this.state.tableHeader.length > 0 &&
                                    <Table
                                        data={this.state.stations}
                                        editRowControl={this.state.stationEditing}
                                        editableColumns={this.state.integration === "agro1" ? [3, 4] : [3]}
                                        updateButton={this.state.integration === "agro1"}
                                        toggleUpdate={this.handleUpdateModal}
                                        canEditRow={!keys}
                                        startEditing={this.onStationEdit}
                                        stopEditing={this.onStationStopEdit}
                                        saveEditing={this.onStationEditSend}
                                        handleChange={this.handleChange}
                                        handleRemove={this.onStationDelete}
                                        totalItens={this.state.totalItens}
                                        rowsPerPage={this.state.rowsPerPage}
                                        currentPage={this.state.currentPage}
                                        handleChangePage={this.handleChangePage}
                                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        header={this.state.tableHeader}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Integration