import React, { Component} from 'react'
import { EUCALYPTUS } from "../common/defaultValues"

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import moment from "moment";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

moment.locale('pt');

class GeneralEucalyptusForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      month: 0
    }
  }
  
  render() {    
    return(
      <FormControl component="fieldset">
        <div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <FormControl>
              <InputLabel htmlFor="range-input">Visualização</InputLabel>
                <Select inputProps={{name: 'range', id: 'range-input'}} className={"prod-months-dropdown"} value={this.props.currentState.range} onChange={this.props.handleChange} name="range">
                  <MenuItem value={'monthly'}>Mensal</MenuItem>
                  <MenuItem value={'annually'}>Anual</MenuItem>
                </Select>
            </FormControl>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between' , marginTop:"10px"}}>
            <TextField
              name="plantingYear"
              type="text"
              value={this.props.currentState.plantingYear}
              label="Ano do plantio"
              onChange={ this.props.handleChange }
              InputProps={{disableUnderline: true, style: {width: "70%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              className="text-fields"
              style={{width: "70%"}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.props.errors.plantingYear ? this.props.errors.plantingYear : ''}
            />        
            {this.props.monthSelection ?
              <FormControl>
                <InputLabel htmlFor="month-input">Mês plantio</InputLabel>
                <Select className={"prod-months-dropdown"} value={this.props.month} onChange={this.props.onChangeMonth}>
                  <MenuItem value={1}>Janeiro</MenuItem>
                  <MenuItem value={2}>Fevereiro</MenuItem>
                  <MenuItem value={3}>Março</MenuItem>
                  <MenuItem value={4}>Abril</MenuItem>
                  <MenuItem value={5}>Maio</MenuItem>
                  <MenuItem value={6}>Junho</MenuItem>
                  <MenuItem value={7}>Julho</MenuItem>
                  <MenuItem value={8}>Agosto</MenuItem>
                  <MenuItem value={9}>Setembro</MenuItem>
                  <MenuItem value={10}>Outubro</MenuItem>
                  <MenuItem value={11}>Novembro</MenuItem>
                  <MenuItem value={12}>Dezembro</MenuItem>
                </Select>
              </FormControl> : null
            }
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between' , marginTop:"20px"}}>
            <TextField
              name="eficiency"
              type="text"
              value={this.props.currentState.eficiency}
              label="Eficiência Florestal (%)"
              onChange={ this.props.handleChange }
              InputProps={{disableUnderline: true, style: {width: "70%"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              className="text-fields"
              style={{width: '70%'}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.props.errors.eficiency ? this.props.errors.eficiency : ''}
            />
            <FormControl>
              <InputLabel htmlFor="cycle-input">Ciclo</InputLabel>
              <Select inputProps={{name: 'cycle', id: 'cycle-input'}} style={{width: "100%"}} className={"prod-months-dropdown"} value={this.props.currentState.cycle} onChange={this.props.handleChange} name="cycle">
                {EUCALYPTUS.CYCLE_OPTIONS.map(option => <MenuItem value={option}>{option}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between' , marginTop:"20px"}}>
            <FormControl>
              <InputLabel htmlFor="range-input">Unidade</InputLabel>
              <Select inputProps={{name: 'unity', id: 'range-input'}} className={"prod-months-dropdown"} style={{width:`100%`}}value={this.props.currentState.unity ? this.props.currentState.unity : 'm³/ha' } onChange={this.props.handleChange} name="range">
                <MenuItem value={'m³/ha'}>m³/ha</MenuItem>
                <MenuItem value={'m³/ha/ano (IMA)'}>m³/ha/ano (IMA)</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="clone-input">Clone</InputLabel>
              <Select inputProps={{name: 'clone', id: 'clone-input'}} className={"prod-months-dropdown"} value={this.props.currentState.clone} onChange={this.props.handleChange} name="clone">
                <MenuItem value={'Plastico'}>Plástico</MenuItem>
                <MenuItem value={'Subtropical'}>Subtropical</MenuItem>
                <MenuItem value={'Tropical'}>Tropical</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.currentState.bark}
                  onChange={this.props.handleChange}
                  name="bark"
                  style={{color: "green", marginTop: "10px"}}
                />
              }
              label="Proporção da casca"
            />
            <TextField
              name="tree_bark"
              type="text"
              value={this.props.currentState.tree_bark ? this.props.currentState.tree_bark : '' }
              label="Proporção da casca (%)"
              onChange={ this.props.handleChange }
              InputProps={{disableUnderline: true, style: {width: "100%", textAlign:"right"}, error: true, inputProps: {style: {height: '2rem', margin: 0} }}}
              className="text-fields"
              disabled={this.props.currentState.bark ? false : true }
              style={{width: '50%'}}
              FormHelperTextProps={{error: true, className: "input-error-text" }}
              helperText={this.props.errors.eficiency ? this.props.errors.eficiency : ''}
            />
          </div>
        </div>
      </FormControl>
    )
  }
}

export default GeneralEucalyptusForm;
