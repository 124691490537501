import {NEW_FARM,
        EDIT_FARM,
        SET_ACTIVE,
        SET_FARMS,
        EDIT_COORDS,
        SET_EDIT_MODE,
        DELETE_SUCCESS,
        ADDING_FLAG,
        UPLOAD_DATA,
        RESET_UPLOAD
} from '../actions/farms'

const initialState = {
  activeFarm: -2,
  points: undefined,
  editCoords: '',
  editMode: '',
  addingFlag: false,
  uploadStatus: undefined
}

const farms = (state = initialState, action) => {

  switch(action.type) {
    case NEW_FARM:
      let points = state.points
      points = state.points
      points['farms'].push(action.point['newFarm'][0])
      return Object.assign({}, state, {points: points})
    case EDIT_FARM:
      let newArray = state.points['farms'].filter(farm => farm.id !== action.point['id'])
      newArray.push(action.point)
      newArray.sort(function(a,b) {return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);} )
      return Object.assign({}, state, {
        points: {
          farms: newArray
        }
      })
    case SET_ACTIVE:
        return Object.assign({}, state, {
          activeFarm: action.id
        })
    case SET_FARMS:
        return Object.assign({}, state, {points: action.points})
    case EDIT_COORDS:
        if(action.coords !== null){
          let newPoints = state.points['farms'].filter(farm => farm.id !== state.activeFarm)
          let point = state.points['farms'].filter(farm => farm.id === state.activeFarm)
          point[0]['coords'] = action.coords
          newPoints.push(point[0])
          return Object.assign({}, state, {editCoords: action.auxCoords, points: {farms: newPoints}})
        }else{
          return Object.assign({}, state, {editCoords: action.auxCoords})
        }
    case SET_EDIT_MODE:
      return Object.assign({}, state, {editMode: action.mode})
    case DELETE_SUCCESS:
      let newPoints = state.points['farms'].filter(farm => farm.id !== action.point);
      return Object.assign({}, state, {points: {farms: newPoints}})
    case ADDING_FLAG:
      return Object.assign({}, state, {addingFlag: action.addingFlag})
    case UPLOAD_DATA:
      return Object.assign({}, state, {uploadStatus: action.response})
    case RESET_UPLOAD:
      return Object.assign({}, state, {uploadStatus: undefined})
    default:
      return state
  }
}

export default farms
