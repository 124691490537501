import NotificationApi from "../api/notification"
import { toggleLoader } from "./global"

export function getUserNotifications(id) {
    return function(dispatch){
        return NotificationApi.getUserNotifications(id)
            .then(response => {
                dispatch({type: "GET_USER_NOTIFICATIONS", response})
            })
            .catch(error => {
                throw(error)
            })
    }
}

export function getAdminNotifications(id ="", data) {
    return function(dispatch){
        dispatch(toggleLoader())
        return NotificationApi.getAdminNotifications(id ="", data)
            .then(response => {
                dispatch({type: "GET_ADMIN_NOTIFICATIONS", response})
            })
            .catch(error => {
                throw(error)
            })
            .finally(() => {
                dispatch(toggleLoader())
            })
    }
}

export function getAdminDetailedNotification(id) {
    return function(dispatch){
        dispatch(toggleLoader())
        return NotificationApi.getAdminNotifications(id)
            .then(response => {
                dispatch({type: "GET_DETAILED_NOTIFICATION", response})
            })
            .catch(error => {
                throw(error)
            })
            .finally(() => {
                dispatch(toggleLoader())
            })
    }
}

export function getUserDetailedNotification(id) {
    return function(dispatch){
        dispatch(toggleLoader())
        return NotificationApi.getUserNotifications(id)
            .then(response => {
                dispatch({type: "GET_DETAILED_NOTIFICATION_LIST", response})
            })
            .catch(error => {
                throw(error)
            })
            .finally(() => {
                dispatch(toggleLoader())
            })
    }
}


export function createNotification(data) {
    return function(dispatch) {
        return NotificationApi.createNotification(data)
            .then(response => {
                dispatch(() => ({ type: "CREATE_NOTIFICATION", response}))
            })
            .catch(error => {
                throw(error)
            })
    }
}

export function editNotification(data) {
    return function(dispatch) {
        return NotificationApi.editNotification(data)
            .then(response => {
                dispatch(() => ({ type: "EDIT_NOTIFICATION", response}))
            })
            .catch(error => {
                throw(error)
            })
    }
}

export function deleteNotification(id) {
    return function(dispatch) {
        return NotificationApi.deleteNotification(id)
            .then(response => {
                dispatch(() => ({ type: "DELETE_NOTIFICATION", response}))
            })
            .catch(error => {
                throw(error)
            })
    }
}

export function resetNotifications() {
    return ({ type: "RESET_DATA"}) 
}

export function resetSpecificNotifications() {
    return ({ type: "RESET_SPECIFIC_DATA"}) 
}

export function resetDetailedNotification() {
    return ({ type: "RESET_DETAILED"})
}

export function pageUpdateHandle(){
    return({type: 'PAGE_UPDATE'})
}