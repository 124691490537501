import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { isEqual } from "lodash"

import { getAdminNotifications, getUserNotifications, getAdminDetailedNotification, deleteNotification, resetNotifications, resetDetailedNotification } from "../../../actions/notification"

import { Grid } from "@material-ui/core"

import NotificationHistory from "./NotificationHistory"
import NotificationForm from "./NotificationForm"
import Loader from "../../common/Loader"

class AdminNotifications extends Component{
    constructor(props){
        super(props)
    
        this.state= {
            openForm: false,
            data: [],
            historyUpdate: false,
            rowsPerPage: 30,
            currentPage: 0,
            totalItens: 0,

        }
    }
    

    componentDidMount() {
        // eslint-disable-next-line
        let id
        let data = {
            limit: this.state.rowsPerPage,
            page: this.state.currentPage,
        }
        this.props.resetNotifications()
        this.props.getAdminNotifications(id = "", data )
    }

    componentDidUpdate(prevProps, prevState) {
        if(!isEqual(prevProps.notificationList, this.props.notificationList)) {
            if (this.props.notificationList !== undefined)
            this.setState({
                data: this.props.notificationList
            })
        }
        if(prevState.historyUpdate === false && this.state.historyUpdate === true) {
            // eslint-disable-next-line
            let id
            let data = {
                limit: this.state.rowsPerPage,
                page: this.state.currentPage,
            }
            this.props.getAdminNotifications(id = "", data)
            this.setState({
                historyUpdate: false
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState){
        // eslint-disable-next-line
        if((this.props.pagination !== nextProps.pagination && nextProps.pagination != undefined) || (this.props.pagination === nextProps.pagination && nextProps.notificationList != undefined) ){
            if (nextProps.pagination){
                this.setState({
                    totalItens: nextProps.pagination.total_itens
                })
            }
            
        }
    }

    toggleNotificationForm = (newNotification = false) => {
        this.props.resetDetailedNotification()
        this.setState({
            openForm: !this.state.openForm,
            historyUpdate: newNotification
        })
    }

    notificationEdit = (event) => {
        this.props.getDetailedNotification(event.id)
        this.toggleNotificationForm()
    }
    
    notificationDelete = (event) => {
        // eslint-disable-next-line
        let id
        let data = {
            limit: this.state.rowsPerPage,
            page: this.state.currentPage,
        }
        this.props.deleteNotification(event.id)
        this.props.getAdminNotifications(id = "", data)
    }

    handleChangePage = ( event, currentPage ) => {
        // eslint-disable-next-line
        let id
        this.setState({
            currentPage: currentPage
        }, () => {
            const data = {
                limit: this.state.rowsPerPage,
                page: currentPage,
            }
            this.props.getAdminNotifications(id = "",data)
        })        
    }

    handleChangeRowsPerPage = event => {
        // eslint-disable-next-line
        let id
        this.setState({ 
            rowsPerPage: event.target.value,
            currentPage: 0
        }, () => {
            const data = {
                limit: this.state.rowsPerPage,
                page: this.state.currentPage,
            }
            this.props.getAdminNotifications(id = "", data)
        })
    };

    render(){
        return(
            <div className='row admin-information'>
                <Loader active={this.props.loader} />
                <Grid container className="row notification-container">
                    <Grid item>
                        <h5>Notificações de Atualização</h5>
                    </Grid>
                    {this.state.openForm ?
                        <NotificationForm toggleForm={this.toggleNotificationForm} detailedNotification={this.props.detailedNotification} />
                    :
                        <Fragment>
                            <Grid item container justify="flex-end" className="add-notification-button-container">
                                <button className='btn wave-light green' onClick={() => this.toggleNotificationForm()} style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                                    <i className='material-icons' style={{marginRight: '8px'}}>add_alert</i>
                                    <span>NOVA NOTIFICAÇÃO</span>
                                </button>
                            </Grid>
                            <NotificationHistory 
                            data={this.state.data} 
                            rowEdit={this.notificationEdit} 
                            rowDelete={this.notificationDelete} 
                            totalItens={this.state.totalItens}
                            rowsPerPage={this.state.rowsPerPage}
                            currentPage={this.state.currentPage}
                            handleChangePage={this.handleChangePage} 
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage} 
                            />
                        </Fragment>
                    }
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
  return {
      notificationList: state.notification.historyList,
      loader: state.global.loader,
      detailedNotification: state.notification.detailed,
      pagination: state.notification.pagination
  }
}

function mapDispatchToProps(dispatch) {
  return {
      getAdminNotifications: (id= "", data) => {
        dispatch(getAdminNotifications(id= "", data))
      },
      getUserNotifications: () => {
        dispatch(getUserNotifications())
      },
      getDetailedNotification: (id) => {
        dispatch(getAdminDetailedNotification(id))
      },
      deleteNotification: (id) => {
        dispatch(deleteNotification(id))
      },
      resetNotifications: () => {
        dispatch(resetNotifications())
      },
      resetDetailedNotification: () => {
        dispatch(resetDetailedNotification())
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNotifications)
