import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Label, Tooltip, Legend, CartesianGrid } from "recharts";

const DashboardStackedBarChart = ({ data, label }) => {
    const customLegend = (value) => {

        let label
        switch (value) {
            case "rp":
                label= "PR"
                break;
            case "ygm":
                label= "YGM"
                break
            case "ygdh":
                label= "YGDH"
                break
            default:
                break;
        }

        return <span>{label}</span>
    }

    const customTooltip = ({ active, payload }) => {

        if(active && payload[0]) {
            return (
                <div className="custom-tooltip">
                    <p style={{color: "#327C0E", textAlign: "center"}}>{payload[0].payload.ygdh}</p>
                    <p style={{color: "#57B828", textAlign: "center"}}>{payload[0].payload.ygm}</p>
                    <p style={{color: "#9BFF5D", textAlign: "center"}}>{payload[0].payload.rp}</p>
                </div>
            )
        }
    }

    return (
        <ResponsiveContainer width="100%" >
            <BarChart data={data} maxBarSize={100} margin={{top: 70, right: 30, left: 30, bottom: 70}}>
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis>
                    <Label angle={-90} value={label} position="left" style={{textAnchor: 'middle'}}/>
                </YAxis>
                <XAxis dataKey="name"/>
                <Tooltip content={customTooltip} />
                <Legend 
                    verticalAlign="top" 
                    formatter={customLegend}
                    wrapperStyle={{
                        top: "50px"
                    }}
                />
                <Bar dataKey="rp" stackId="a" fill="#9BFF5D" stroke="#000" />
                <Bar dataKey="ygm" stackId="a" fill="#57B828" stroke="#000" />
                <Bar dataKey="ygdh" stackId="a" fill="#327C0E" stroke="#000" />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default DashboardStackedBarChart