export function authHeader(type) {
    const token = localStorage.getItem('Token')
      ? localStorage.getItem('Token')
      : sessionStorage.getItem('Token');

    if (type) {
      return {'Authorization' : 'Bearer ' + token};
    }

    return token
      ? new Headers({ 'Authorization' : 'Bearer ' + token, 'Content-Type' : 'application/json' })
      : new Headers ({'Content-Type' : 'application/json', 'Accept': 'application/json'});
  };

