import ProfileApi from '../api/profile'
import { toggleSidebar, toggleProfileBar } from './global'
import { getKmzFile } from './map'

export const LOAD_PROFILE = 'LOAD_PROFILE'
export const LOAD_POINT_DATA = 'LOAD_POINT_DATA'
export const DELETE_POINT_ROW = 'DELETE_POINT_ROW'
export const CLEAR_SERVER_MESSAGE = 'CLEAR_SERVER_MESSAGE'
export const EDIT_POINT_ROW = 'EDIT_POINT_ROW'
export const EDIT_POINT_ROW_FAIL = 'EDIT_POINT_ROW_FAIL'
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const RESET_POINTS = 'RESET_POINTS'

export function loadProfile(isSidebar = true){
    return function(dispatch){
        dispatch(toggleLoader())
        return ProfileApi.loadProfile()
            .then(profile => {
                dispatch(toggleLoader())
                dispatch(loadProfileSuccess(profile))
                if(isSidebar){
                    dispatch(toggleProfileBar())
                    dispatch(toggleSidebar())
                }
            }).catch(error => {throw(error)})
    }
}

export function loadPointData(data){
    return function(dispatch){
        dispatch(toggleLoader())
        return ProfileApi.loadPointData(data)
            .then(data => {
                dispatch(toggleLoader())
                dispatch(loadPointDataSuccess(data))
            }).catch(error => {throw(error)})
    }
}

export function deletePointRow(data){
    return function(dispatch){
        dispatch(toggleLoader())
        return ProfileApi.deletePointRow(data)
            .then(data => {
                dispatch(toggleLoader())
                dispatch(deletePointRowSuccess(data))
            }).catch(error => {throw(error)})
    }
}

export function editPointRow(data){
    return function(dispatch){
        dispatch(toggleLoader())
        return ProfileApi.editPointRow(data)
            .then(data => {
                dispatch(toggleLoader())
                dispatch(editPointRowSuccess(data))
            }).catch(error => {
                dispatch(editPointRowFail())
                dispatch(toggleLoader())
                throw(error)
            })
    }
}

export function downloadWeatherByPoints(data){
    return function(dispatch){
        dispatch(toggleLoader())
        return ProfileApi.downloadWeatherByPoints(data)
            .then(data => {
                dispatch(toggleLoader())
                window.open(data.url)
            }).catch(error => {
                dispatch(toggleLoader())
                throw(error)
            })
    }
}

export function uploadKmzFile(file){
    return async function(dispatch){
        dispatch(postKmzFile());
        if (file.size < 30000000) {
            try {
                const data = await ProfileApi.uploadKmzFile(file);
                dispatch({ type: 'KMZ_UPLOAD_SUCCESS', data })
                dispatch(getKmzFile());
            } catch (error) {
                dispatch({type: 'KMZ_UPLOAD_SUCCESS'});
                throw(error);
            }
        } else {
            dispatch({
                type: 'KMZ_UPLOAD_SUCCESS',
                data: { message: 'Arquivo excede limite de tamanho', status: 413 },
            })
            dispatch(getKmzFile());
        }
    }
}

export function postKmzFile() {
    return { type: 'POST_KMZ_FILE' }
}

export function loadProfileSuccess(profile){
    return({type: 'LOAD_PROFILE', profile})
}

export function loadPointDataSuccess(data){
    return({type: 'LOAD_POINT_DATA', data})
}

export function deletePointRowSuccess(data){
    return({type: 'DELETE_POINT_ROW', data})
}

export function editPointRowSuccess(data){
    return({type: 'EDIT_POINT_ROW', data})
}

export function editPointRowFail(){
    return({type: 'EDIT_POINT_ROW_FAIL'})
}

//standalone actions
export function clearDeleteMessage(){
    return({type: 'CLEAR_SERVER_MESSAGE'})
}

export function toggleLoader(){
    return({type: 'TOGGLE_LOADER'})
}

export function resetPoints(){
    return({type: 'RESET_POINTS'})
}

export function kmzDeleteSucess(data) {
    return ({ type: 'KMZ_DELETE_SUCCESS', data });
}

export function kmzUpdateSucess(data) {
    return ({ type: 'KMZ_UPDATE_SUCESS', data });
}
