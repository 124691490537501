import { authHeader } from "./config";
import { authHeaderUpload } from "./configUpload";

class ProfileApi {
  static loadProfile() {
    return fetch(process.env.REACT_APP_API + "api/profile?", {
      method: "GET",
      headers: authHeader(),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static loadPointData(data) {
    return fetch(
      process.env.REACT_APP_API +
        `api/profile/point_data?farmId=${data.farmId}&limit=${
          data.limit
        }&page=${data.page}&initial_date=${
          data.initial_date ? data.initial_date : ""
        }&final_date=${data.final_date ? data.final_date : ""}`,
      {
        method: "GET",
        headers: authHeader(),
      }
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static deletePointRow(data) {
    return fetch(process.env.REACT_APP_API + "api/profile/point_data", {
      method: "DELETE",
      headers: authHeader(),
      body: JSON.stringify({
        farmId: data.farmId,
        farmDataId: data.farmDataId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static editPointRow(data) {
    return fetch(process.env.REACT_APP_API + "api/profile/point_data", {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        farmId: data.farmId,
        updateInfo: data.updateInfo,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static downloadWeatherByPoints(data) {
    return fetch(process.env.REACT_APP_API + "api/points/download", {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        points: data.points,
        variables: data.variables,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static uploadKmzFile(file) {
    const data = new FormData();
    data.append("file", file);

    return fetch(process.env.REACT_APP_API + "api/profile/upload_kmz", {
      method: "POST",
      headers: authHeaderUpload(),
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static changePassword(passwordAndConfirmPassword) {
    return fetch(process.env.REACT_APP_API + "api/users/change_password", {
      method: "PATCH",
      headers: authHeader(),
      body: JSON.stringify(passwordAndConfirmPassword),
      redirect: "follow",
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }

  static deleteAccount(passwordToConfirmDeleteAccount) {
    return fetch(process.env.REACT_APP_API + "api/users/delete", {
      method: "PATCH",
      headers: authHeader(),
      body: JSON.stringify(passwordToConfirmDeleteAccount),
      redirect: "follow",
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return error;
      });
  }
}


export default ProfileApi;
