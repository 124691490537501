import React, { useState } from "react"

import { Dialog, MobileStepper, Grid, Button, IconButton, TextField } from "@material-ui/core"

import YoutubePlayer from "../common/YoutubePlayer"

import NavigateBefore from "@material-ui/icons/NavigateBefore"
import NavigateNext from "@material-ui/icons/NavigateNext"

import "../../stylesheets/css/components/notification.css"

const NotificationDetail = ({openModal, closeModal, notifications}) => {
    const [activeStep, setActiveStep] = useState(0)

    function onPreviousStep() {
        setActiveStep(activeStep - 1)
    }

    function onNextStep() {
        setActiveStep(activeStep + 1)
    }

    function onCloseModal() {
        setActiveStep(0)
        closeModal()
    }

    return (
        <Dialog
            className={"notification-detail-modal"}
            open={openModal}
            maxWidth="md"
            fullWidth
            PaperProps= {{
                style: {
                    position: "absolute",
                }
            }}
        >
            <Grid container>
                <Grid item container xs={1} alignContent="center" justify="center">
                    {notifications.length > 1 && activeStep !== 0 ?
                        <IconButton 
                            className={"arrow-button"}
                            disabled={activeStep === 0}
                            onClick={onPreviousStep}
                        >
                            <NavigateBefore className="arrow-icon" />
                        </IconButton>
                    :
                        null
                    }
                </Grid>

                {notifications.length > 0 ?
                    <Grid item container xs={10} justify="center" className="notification-form-container">
                        <Grid container justify="center" className="notification-form-text-field">
                            <TextField 
                                value={notifications[activeStep].title}
                                disabled
                                fullWidth
                                multiline
                                rows={1}
                                rowsMax={1}
                                variant="filled"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputProps={{
                                    className: "notification-form-input",
                                }}
                            />
                            <br/>
                            <TextField 
                                value={notifications[activeStep].body}
                                disabled
                                fullWidth
                                multiline
                                rows={10}
                                rowsMax={10}
                                variant="filled"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputProps={{
                                    className: "notification-form-input",
                                    maxLength: 800
                                }}
                            />
                            
                        </Grid>

                        <Grid item xs={10}>
                            <YoutubePlayer source={notifications[activeStep].url} className="video-player" />
                        </Grid>

                        <Grid container justify="center" className="controller-area">
                            {notifications.length > 1 ?
                                <Grid item xs={12}>
                                    <MobileStepper
                                        steps={notifications.length}
                                        position="static"
                                        className="stepper"
                                        classes={{
                                            dotActive: "active-step"
                                        }}
                                        activeStep={activeStep}
                                        justify="center"
                                    />
                                </Grid>
                            :
                                null
                            }
                            <Grid item container xs={12} justify="center">
                                {activeStep < (notifications.length-1) ?
                                    <Button onClick={onNextStep}>
                                        <span><u>Pular</u></span>
                                    </Button>
                                :
                                    <Button onClick={onCloseModal} variant="contained" className="last-button">
                                        <span>OK</span>
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                :
                    <Grid item xs={10} />
                }

                <Grid item container xs={1} alignContent="center" justify="center">
                    {notifications.length > 1 && activeStep !== (notifications.length - 1) ?
                        <IconButton 
                            className={"arrow-button"}
                            disabled={activeStep === (notifications.length - 1)}
                            onClick={onNextStep}
                        >
                            <NavigateNext className="arrow-icon" />
                        </IconButton>
                    :
                        null
                    }
                </Grid>   
            </Grid>       
        </Dialog>
    )
}

export default NotificationDetail