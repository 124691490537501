import React from 'react';

function PlantingLabels({ labels }) {
  return (
    <footer className='indicators-labels index-indicators-labels'>
      <div className='indicators-labels--item'>
        <div className='label-text-unit'>
          <p>Index</p>
        </div>
      </div>
      <div className='indicators-container'>
        {
          labels.map(({name, level}) => (
            <div key={name} className={`indicators-labels--item level-${level}`}>
              <div className='label-color'></div>
              <div className='label-text'>
                <p>{name}</p>
              </div>
            </div>
          ))
        }
      </div>
    </footer>
  )
}

export default PlantingLabels;
