import React from "react";
import {
  Button,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  Select,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import calendarTheme from "../../../../shared/Themes";
import { setCropYear, setVisualizationType } from "../../../../actions/GeneralCaneProductivityAction";

export function Step1GeneralCaneModal({ onClose, handleNext }) {
  const dispatch = useDispatch();
  const { cropYear, visualizationType } = useSelector(
    (state) => state.generalCaneProductivityReducer
  );

  return (
    <MuiThemeProvider theme={calendarTheme}>
      <div
        style={{
          width: "29%",
          margin: "30px 0 0 14.5%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <InputLabel
            style={{ width: "141px", fontSize: "12px", display: "block" }}
            shrink
            id="crop_year"
          >
            Selecionar Safra
          </InputLabel>
          <Select
            style={{ width: "100%" }}
            labelid="crop_year"
            id="crop_year"
            value={cropYear}
            onChange={(e) => dispatch(setCropYear(e.target.value))}
          >
            <MenuItem value={new Date().getFullYear()}>Atual</MenuItem>
            <MenuItem value={new Date().getFullYear() + 1}>Próxima</MenuItem>
          </Select>
        </div>
        <div>
          <InputLabel
            style={{ width: "141px", fontSize: "12px", display: "block" }}
            shrink
            id="visualization_type"
          >
            Selectionar Visualização
          </InputLabel>
          <Select
            style={{ width: "100%" }}
            labelid="visualization_type"
            id="visualization_type"
            value={visualizationType}
            onChange={(e) => dispatch(setVisualizationType(e.target.value))}
          >
            <MenuItem value="civil" >Civil</MenuItem>
            <MenuItem value="agricultural">Agrícola</MenuItem>
          </Select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "52px 16.5% 0 0",
        }}
      >
        <Button
          style={{
            color: "#707070",
            width: "170px",
            height: "33px",
          }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          style={{
            backgroundColor: "rgb(106, 164, 38)",
            color: "white",
            width: "170px",
            height: "33px",
          }}
          onClick={handleNext}
        >
          Próximo
        </Button>
      </div>
    </MuiThemeProvider>
  );
}
