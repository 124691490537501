import { styled } from '@mui/material';

const SealTitle = styled('div')({
  position: 'absolute',
  padding: '5px',
  left: '-240px',
  top: '-10px',
  color: 'black',
  fontWeight: 500,
});

export default SealTitle;
