import React from 'react';

import { unstable_Box as Box } from '@material-ui/core/Box';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

function FrequencyRadios({ classes, frequency, handleFrequency }) {
  return (
    <Box className={ classes.modalSection }>
      <Typography>
        Com qual frequência você deseja receber a atualização do gráfico em seu email?
      </Typography>
      <RadioGroup>
        <FormControlLabel
          className={ classes.radioLabel }
          control={ <Radio className={ classes.radioButtons } color="default" /> }
          value="diariamente"
          label="Diariamente"
          onChange={ handleFrequency }
        />
        <FormControlLabel
          className={ classes.radioLabel }
          control={ <Radio className={ classes.radioButtons } color="default" /> }
          value="semanalmente"
          label="Semanalmente"
          onChange={ handleFrequency }
        />
        <FormControlLabel
          className={ classes.radioLabel }
          control={ <Radio className={ classes.radioButtons }  color="default" /> }
          value="mensalmente"
          label="Mensalmente"
          onChange={ handleFrequency }
        />
      </RadioGroup>
    </Box>
  );
}

export default FrequencyRadios;
