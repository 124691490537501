import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import moment from "moment"
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { DatePicker } from 'material-ui-pickers'
import { numberToMonth, isDateInRange } from '../shared/utils/DatesUtils'
import { variableToName, variablePreposition } from '../shared/utils/MapColorsUtils'
import { changeMapDay, generateMapData} from '../actions/map'
import { toggleLoader, generatingMapData, toggleLeftLoader } from '../actions/global'
moment.locale('pt')

const CAD_VARIABLES = ['bhbox', 'arm', 'etr', 'def', 'exc', 'excdef', 'extbh']
const WEATHER_VARIABLES = ['tmax', 'tmin', 'tmed', 'rs', 'gd', 'u2', 'prec', 'ur', 'etp']

class MapInformation extends Component {
  constructor(props){
    super(props)

    this.state={
      string: '',
      cadString: undefined,
      previousDay: {
        label: '',
        year: '',
        variable: ''
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    let fullString = this.state.string;
    let cadString = undefined;
    let date = new Date();
    
    if (
      nextProps.mapDay['label'] !== this.props.mapDay['label'] ||
      nextProps.mapDay['year'] !== this.props.mapDay['year'] ||
      nextProps.mapDay['variable'] !== this.props.mapDay['variable']
    ) {
      this.setState({ previousDay: {
        label: this.props.mapDay['label'],
        year: this.props.mapDay['year'],
        variable: this.props.mapDay['variable']
      }});
    } else if (nextProps.grid !== undefined) {
      if (
        nextProps.mapDay['label'] !== this.state.previousDay['label'] ||
        nextProps.mapDay['year'] !== this.state.previousDay['year'] ||
        nextProps.mapDay['variable'] !== this.state.previousDay['variable'] ||
        this.props.graphicType === 'climatology'
      ) {
        this.setState({ previousDay: {
          label: nextProps.mapDay['label'],
          year: nextProps.mapDay['year'],
          variable: nextProps.mapDay['variable']
        } });

        const isInRange = !isDateInRange(
          new Date(), new Date(date.getFullYear(), 1, 1), new Date(date.getFullYear(), 3, 21)
        );
        const variableName = variableToName(nextProps.mapDay['variable']);
        const preposition = variablePreposition(nextProps.mapDay['variable']);
        const graphicType = this.props.graphicType;
        const { label, year } = nextProps.mapDay;

        if (nextProps.mapDay['variable'] === 'cane-flowering' && isInRange) {
          fullString = `${variableName} em ${nextProps.mapYear}`;
        } else if (nextProps.graphicRange !== 'annualy' && nextProps.graphicRange !== 'monthly') {
          fullString = this.props.graphicType === 'climatology'
            ? `Climatologia ${preposition} ${variableName} em ${label}`
            : `${graphicType === 'anomaly' ? `Anomalia ${preposition}` : ''} ${variableName} em ${label}/${year}`;
        } else if (nextProps.graphicRange === 'monthly') {
          const month = numberToMonth(nextProps.mapDay['label']);
          fullString = this.props.graphicType === 'climatology'
            ? `Climatologia ${preposition} ${variableName} em ${month}`
            : `${graphicType === 'anomaly' ? `Anomalia ${preposition}` : ''} ${variableName} em ${month} de ${year}`;
        } else if (nextProps.graphicRange === 'annualy') {
          fullString = `${graphicType === 'anomaly' ? `Anomalia ${preposition}` : ''} ${variableName} em ${year}`;
        }
      }

      if (CAD_VARIABLES.includes(nextProps.mapDay['variable'])) {
        const cad = nextProps.lastVisualizedCad !== '0'
          ? nextProps.lastVisualizedCad : nextProps.lastUsedCad;
        cadString = 'CAD = ' + cad + ' mm';
      }

      this.setState({ string: fullString, cadString });
    }
  }

  onChangeDate = (e) => {
    //THIS MAY CHANGE LATER IF WE HAVE MORE PERIODS
    let newDay = {}
    newDay['label'] = e.format('DD/MM')
    newDay['year'] = parseInt(e.format('YYYY'),10)
    newDay['variable'] = this.props.mapDay['variable']

    let attrs = {
      initial_date: e.format('YYYY-MM-DD'),
      final_date: e.format('YYYY-MM-DD'),
      type: this.props.graphicType,
      range: this.props.graphicRange,
      tb: this.props.tBasal,
      soil: this.props.lastUsedSoil,
      cad: this.props.lastUsedCad
    }
    this.props.setMapDay(newDay)
    this.props.generatingMapData()
    this.props.generateMapData(this.props.mapDay['variable'], attrs)
    this.props.toggleLeftLoader();
  }

  render() {
    if(this.props.grid !== undefined && this.props.xavierGrid){
      let date = this.props.graphicRange === 'daily' ? moment(`${this.props.mapDay.label}/${this.props.mapDay.year}`, "DD/MM/YYYY") : moment()
      let minDate = this.props.graphicRange === 'daily' ? moment(`${this.props.mapDay.year}`, "YYYY") : moment()
      let maxDate = moment().add(30,'days')
      let currentYear = moment().format("YYYY")
      if(this.props.graphicRange === 'daily'){
        if(currentYear !== this.props.mapYear){
          maxDate = moment(`31/12/${this.props.mapDay.year}`, "DD/MM/YYYY")
        }
      }

      return(
        <div className={classnames("map-info-show", {'map-info-report-open': this.props.reportActive}, {'extbh-info': ['extbh','excdef'].includes(this.props.mapDay['variable'])})}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {this.state.string}
            {this.props.graphicRange === 'daily' && WEATHER_VARIABLES.includes(this.props.mapDay['variable']) &&
              <button type={"button"} className={"button-calendar"} onClick={(event) => this.datepicker.open()}>
                <div className="button button--retangle">
                  <div className='button--retangle-icon-text' style={{marginTop: '4px'}}>
                    <i className="material-icons" style={{fontSize: '20px', color: '#6aa426'}}>event</i>
                  </div>
                </div>
              </button>
            }
          </div>
          {this.state.cadString &&
            <div style={{textAlign: 'center'}}>
              {this.state.cadString}
            </div>
          }
          <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <DatePicker
              value={date}
              onChange={this.onChangeDate}
              animateYearScrolling={false}
              ref={(e) => this.datepicker = e}
              minDate={minDate}
              maxDate={maxDate}
              style={{position: 'absolute'}}
              InputProps={{disableUnderline: true, style: {width: "0%", display: 'none'}, error: true}}
            />
          </MuiPickersUtilsProvider>
        </div>
      )
    }else{
      return(null)
    }
  }
}

function mapStateToProps(state) {
  return {
    mapYear: state.map.mapYear,
    mapDay: state.map.weatherMapDay,
    graphicRange: state.Reports.graphicRange,
    graphicType: state.Reports.graphicType,
    reportActive: state.Reports.reportActive,
    lastUsedCad: state.Reports.lastUsedCad,
    lastVisualizedCad: state.Reports.lastVisualizedCad,
    grid: state.map.grid,
    tBasal: state.Reports.tBasal,
    lastUsedSoil: state.Reports.lastUsedSoil

  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMapDay: (day) => {
      dispatch(changeMapDay(day))
    },
    generateMapData: (reportTypes, attrs) => {
      dispatch(generateMapData(reportTypes,attrs))
    },
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    toggleLeftLoader: () => {
      dispatch(toggleLeftLoader());
    },
    generatingMapData: () => {
      dispatch(generatingMapData())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapInformation)
