import {
    GET_SCHEDULES,
    EDIT_SCHEDULE,
    DELETE_SCHEDULE,
    CREATE_SCHEDULE,
    RESET_STATUS,
    CHANGE_STATUS
} from "../actions/schedule"

const initialState = {
    schedules: [],
    pagination: {
        total_pages: 1,
        current_page: 0,
        next_page: 1,
        previous_page: null,
        total_itens: 0
    },
    update: true,
    status: ""
}

export default (state = initialState, action) => {
    switch(action.type) {
        case GET_SCHEDULES:
            return Object.assign({}, state, { schedules: action.data })
        case EDIT_SCHEDULE:
            return Object.assign({}, state, { update: !state.update, status: "edit" })
        case DELETE_SCHEDULE:
            return Object.assign({}, state, { update: !state.update, status: "delete" })
        case CREATE_SCHEDULE:
            return Object.assign({}, state, { status: action.data.msg })
        case RESET_STATUS:
            return Object.assign({}, state, { status: "" })
        case CHANGE_STATUS:
            return Object.assign({}, state, { status: action.data.message ?? action.data.list })
        default:
            return state
    }
}