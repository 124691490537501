import React, { Component, Fragment } from 'react'
import validateInput from '../shared/validators/recoverPassword'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import classnames from 'classnames'

//actions
import { toggleLoader } from '../actions/global'
import { recoverPassword } from '../actions/users'

//components
import Loader from '../components/common/Loader'
import InputField from '../components/common/InputField'

class RecoverPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      passwordConfirm: '',
      errors: {},
      loginErrors: {}
    }
  }

  //Helper methods
  isValid() {
    const { errors, isValid } = validateInput(this.state)

    if(!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  //#region Render methods
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    if(this.isValid()) {
      this.props.toggleLoader()
      this.props.recoverPassword(this.state.password, this.state.passwordConfirm,this.props.match.params.token)
    }
  }
  //#endregion

  render(){
    if(this.props.result !== undefined){
      return(
        <Redirect to='/'/>
      )
    }else{
      const {errors, passwordConfirm, password} = this.state
      return(
        <Fragment>
          <Loader active={this.props.loader} />
          <div className='login-area'>
            <div className={classnames('login-area-form-container')}>
              <div className={classnames('login-area-form-container-inside', {'signin-area-form-container-inside': this.state.signIn})}>
                <div className={classnames('row login-area-form-container-inside-title-row', {'signin-area-form-container-inside-title-row': this.state.signIn})}>
                  <div className='login-area-form-container-inside-title'>
                    <img className={classnames({'logo-image-sign-in': this.state.signIn},{'logo-image': !this.state.signIn})} src='/images/logo_agrymax.png' alt='Logo Agrymax' />
                  </div>
                  {this.state.signIn? null :
                    <div className='login-area-form-container-inside-subtitle'>Informe a nova senha que você deseja.</div>
                  }
                </div>
                <Fragment>
                  <div className='login-area-form-container-inside-form-row'>
                    {this.props.errors ?
                      <div className="errors">
                        {this.props.errors}
                      </div> : null
                    }
                    <form onSubmit={ this.onSumbit }>
                      <InputField
                        name='password'
                        label='Senha'
                        type='password'
                        value={ password }
                        error={ errors.password }
                        onChange={ this.onChange }
                        active={password !== ''}
                        loginInputActive={password !== ''}
                        activeFarm={'a'}
                      />
                      <InputField
                        name='passwordConfirm'
                        label='Confirmar senha'
                        type='password'
                        value={ passwordConfirm }
                        error={ errors.passwordConfirm }
                        onChange={ this.onChange }
                        active={password !== ''}
                        loginInputActive={passwordConfirm !== ''}
                        activeFarm={'a'}
                      />
                    </form>
                  </div>
                  <div className='row login-area-form-container-inside-buttons-row'>
                    <button onClick={this.onSubmit} className='btn wave-light agrymet-green space-between'>TROCAR SENHA</button>
                  </div>
                </Fragment>
              </div>
            </div>
            <div className={classnames('login-area-image-container', {'signin-area-image-container': this.state.signIn})}>
              <video style={{'height': '100%', zIndex: 0, position: 'absolute', left: '130px'}} id="background-video" loop autoPlay muted>
                  <source src="/istock-830459030.mp4" type="video/mp4"/>
              </video>
            </div>
          </div>
        </Fragment>
      )
    }
  }
}

function mapStateToProps(state){
  return{
    errors: state.users.errors,
    loader: state.global.loader,
    result: state.users.createResult
  }
}

function mapDispatchToProps(dispatch){
  return{
    toggleLoader: () => {
      dispatch(toggleLoader())
    },
    recoverPassword: (password, passwordConfirm, token) => {
      dispatch(recoverPassword(password, passwordConfirm, token))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword)
