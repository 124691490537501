import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TextField,
  Select,
  TablePagination
} from "@material-ui/core";
import { toast } from 'react-toastify';
import { showToaster } from "../common/Toaster"
import { cloneDeep } from "lodash" 
import moment from "moment";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DatePicker } from "material-ui-pickers";

import '../../stylesheets/css/components/table.css'

import EditIcon from "@material-ui/icons/Edit"
import TrashIcon from "@material-ui/icons/Delete"
import CheckIcon from "@material-ui/icons/Check"
import CancelIcon from "@material-ui/icons/Cancel"

const row = (
  item,
  rowIndex,
  header,
  handleRemove,
  startEditing,
  editable,
  editingRowIndex,
  editingRow,
  editableColumns,
  selectColumns,
  selectOptions,
  dateColumns,
  handleChange,
  handleChangeDate,
  stopEditing,
  saveEditing
) => {
  const isEditing = editingRowIndex === rowIndex;

  return (
    <TableRow key={`tr-${rowIndex}`} className={rowIndex%2 !== 0 ? 'gray row' : 'row'}>
      {header.map((cell, cellIndex) => {
        let renderCell;
        if(isEditing) {
          if(selectColumns.includes(cellIndex)) {
            const currentSelect = selectOptions.filter(item => item.column === cellIndex)[0]
            renderCell = (
              <Select
                style={{fontSize: "inherit"}}
                name={currentSelect.name}
                onChange={e => {
                  handleChange(e, rowIndex)
                }}
                value={editingRow[currentSelect.name]}
              >
                {currentSelect.options.map(option => option)
                }
              </Select>
            )
          }
          else if(dateColumns.includes(cellIndex)) {
            renderCell = (
              <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <DatePicker
                    keyboard
                    className="input-margin-8"
                    placeholder={'DD/MM/AAAA'}
                    format={"DD/MM/YYYY"}
                    margin="dense"
                    name={cell.prop}
                    value={editingRow[cell.prop]}
                    onChange={(newDate) => handleChangeDate(newDate, cell.prop)}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    minDate={moment('1982-01-01')}
                    maxDate={moment().add(1, 'year')}
                    InputProps={{disableUnderline: true, style: {width: "150%"}, error: true}}
                    FormHelperTextProps={{error: true, className: "input-error-text" }}
                    helperText={''}
                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                />
              </MuiPickersUtilsProvider>
            )
          }
          else {
            renderCell = (
              <TextField
                name={cell.prop}
                onChange={e => handleChange(e)}
                defaultValue={item[cell.prop]}
                inputProps={{ maxLength: 8 }}
              />      
            )
          }
        }
        else {
          renderCell = dateColumns.includes(cellIndex) ? moment(item[cell.prop]).format("DD/MM/YYYY") : item[cell.prop]
        }

        return (
        <TableCell key={`trc-${cellIndex}`} align='center' className='cell'>
          {editableColumns.includes(cellIndex) 
            ? renderCell
            : item[cell.prop]
          }
        </TableCell>
      )})}
      {editableColumns.length > 0 ?
        <TableCell className={'button-cell'}>
          {isEditing ? (
            <Button disabled={!editable}>
              <CheckIcon onClick={() => saveEditing(rowIndex)} />
            </Button>
          ) : (
            <Button disabled={!editable}>
              <EditIcon onClick={() => startEditing(item, rowIndex)} />
            </Button>
          )}
        </TableCell>
      : null }
      <TableCell className={'button-cell'}>
        {isEditing ? (
          <Button disabled={!editable}>
            <CancelIcon onClick={() => stopEditing()} />
          </Button>
        ) : (
          <Button disabled={!editable}>
            <TrashIcon onClick={() => handleRemove(item)}/>
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ({
  data: parentData,
  totalItens,
  header,
  editableColumns,
  selectColumns,
  selectOptions,
  dateColumns,
  editable = true,
  extraAuth,
  rowNumberOption,
  sendEdit,
  sendDelete,
  sendUpdate
}) => {
  const [tableData, setTableData] = useState([])
  const [editingRowIndex, setEditingRowIndex] = useState(-1)
  const [editingRow, setEditingRow] = useState({})
  const [page, setPage] = useState(0)
  const [rowsNumber, setRowsNumber] = useState(rowNumberOption[0])

  useEffect(() => {
    setTableData(parentData)
  }, [parentData])

  useEffect(() => {
    stopEditing()
    sendUpdate(extraAuth, rowsNumber, page)
  }, [sendUpdate, extraAuth, rowsNumber, page])

  function handleRemove(row){
    const toasterParams = {
      title: 'EXCLUIR LINHA?',
      message: 'Tem certeza que deseja excluir essa linha?',
      status: false,
      autoClose: false,
      buttons: [
        {
          name: 'EXCLUIR',
          style: 'delete',
          func: confirmDeleting,
          data: [
            row.id,
            extraAuth
          ]
        },
        {
          name: 'CANCELAR',
          func: toast.dismiss
        }
      ]
    }
    showToaster(toasterParams)
  }

  function confirmDeleting(event){
    const data = {
      id: event.target.dataset.object.split(',')[0],
      farmId: event.target.dataset.object.split(',')[1]
    }
    sendDelete(data)
  }

  function startEditing(item, rowIndex){
    setEditingRowIndex(rowIndex)
    item.specific_args = {}
    setEditingRow(item)
  }

  function stopEditing(){
    setEditingRowIndex(-1)
  }

  function saveEditing(index){
    const tempRow = cloneDeep(editingRow)
    let sendMessage = false

    for (let [key, value] of Object.entries(tempRow)){
      if(key === "planting_date") {
        tempRow[key] = moment(value).format("YYYY-MM-DD")
      }
      if(parentData[index][key] !== value){
        sendMessage = true
        break
      }
    }

    const data = {
      extraAuth: extraAuth,
      updateInfo: tempRow,
    }

    if(sendMessage){
      sendEdit(data)
    }

    stopEditing()
  }

  function handleChange(event){
    setEditingRow({
      ...editingRow,
      [event.target.name]: event.target.value
    })
  }

  function handleChangeDate(event, prop){
    setEditingRow({
      ...editingRow,
      [prop]: event
    })
  }

  return (
    <Grid className='table'>
      <Table style={{float: "left"}}>
        <TableHead>
          <TableRow className={'gray'}>
            {header.map((cell, cellIndex) => (
              <TableCell key={`thc-${cellIndex}`} align='center' className={'header'}>{cell.name}</TableCell>
            ))}
            {editableColumns.length > 0 ? <TableCell/> : null }
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>    
          {tableData.map((item, rowIndex) => 
            row(
              item,
              rowIndex,
              header,
              handleRemove,
              startEditing,
              editable,
              editingRowIndex,
              editingRow,
              editableColumns,
              selectColumns,
              selectOptions,
              dateColumns,
              handleChange,
              handleChangeDate,
              stopEditing,
              saveEditing,
            )
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={rowNumberOption}
        count={totalItens}
        rowsPerPage={rowsNumber}
        page={page}
        onChangePage={(event, newPage) => setPage(newPage)}
        onChangeRowsPerPage={(e) => {
          setRowsNumber(e.target.value)
          setPage(0)
        }}
      />
    </Grid>
  )
};