import React, { Component } from 'react'
import classnames from 'classnames'
import { rates } from '../../../lib/fire'

class IndicatorColor extends Component {
  getLevel(data, type, soil) {
    if(data === undefined || data === -1){
      return 5
    }
    else if(type === 'fmaac'){
      if (data <= 3) { return 0 }
      else if (data > 3 && data <= 8) { return 1 }
      else if (data > 8 && data <= 14) { return 2 }
      else if (data > 14 && data <= 24) { return 3 }
      else if (data > 24) { return 4 }
    }
  }

  render() {
    let level = this.getLevel(this.props.data, this.props.agrytoolsType, this.props.soil)

    return(
      <div className={classnames('fire--indicator-color',
                                {'inside-active-indicator': this.props.id === this.props.active},
                                {'fire--indicator-border': this.props.last },
                                {'first--indicator-prediction': this.props.prediction === 0 && this.props.agrytoolsType === 'fmaac'},
                                {'second--indicator-prediction': [1,2].includes(this.props.prediction) && this.props.agrytoolsType === 'fmaac'},
                                {'third--indicator-prediction': this.props.prediction === 3 && this.props.agrytoolsType === 'fmaac'},
                                {'first--indicator-prediction-detail': this.props.predictionDetail === 0 && this.props.agrytoolsType === 'fmaac'},
                                {'second--indicator-prediction-detail': [1,2].includes(this.props.predictionDetail) && this.props.agrytoolsType === 'fmaac'},
                                {'third--indicator-prediction-detail': this.props.predictionDetail === 3 && this.props.agrytoolsType === 'fmaac'},
                              )}
            id={this.props.id}
            data-level={ level }
            title={this.props.agrytoolsType === 'herb' || this.props.agrytoolsType === 'ps' ? rates[level][this.props.agrytoolsType] : rates[level]['title'] }
            onClick={level === 5 ? null : this.props.onClick}
            key={this.props.id}
      >
      </div>
    )
  }
}

export default IndicatorColor
