import React, { Component } from "react";
import { UserListNewComponent } from "./UserListNewComponent";
import { connect } from "react-redux";
import { getUsersList } from "../../actions/admin";
import { setErrorsToNull } from "../../actions/users";

class AdminUserList extends Component {
  render() {
    return (
      <div className="row admin-information">
        <UserListNewComponent
          users={this.props.usersList}
          getAllUsers={this.props.getUsersList}
          history={this.props.history}
          setErrorsToNull={this.props.setErrorsToNull}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    usersList: state.admin.usersList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUsersList: () => {
      dispatch(getUsersList());
    },
    setErrorsToNull: () => {
      dispatch(setErrorsToNull());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserList);
