import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SearchInput from 'react-search-input'
import classnames from 'classnames'
import Checkbox from 'rc-checkbox'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClickOutside from 'react-click-outside'

class DashboardDropdown extends Component {
  constructor(props){
    super(props)

    this.state ={
      searchTerm: '',
      active: false,
      selectedItems: [],
      options: []
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.data !== this.props.data) {
      const options = []

      if(this.props.label === "Pontos") {
        this.props.data.map(item => 
          options.push({ value: item.value, text: item.name })
        )
      }
      else {
        this.props.data.map(item => 
          options.push({ value: item, text: item })  
        )
      }
  
      this.setState({
        options: options
      })
    }
  }

  //#region Helper methods

  setCheckStatus(row){
    if(row === 'all'){
      let allFarmsFlag = true
      this.state.options.forEach(item => {
        if(!this.props.checkedData.includes(item.value.toString())){
          allFarmsFlag = false
        }
      })
      return allFarmsFlag
    }else{
      return this.props.checkedData.indexOf(row.value.toString()) !== -1
    }
  }

  renderOptions(){
    return(
      <ul>
        <li key={"all"} className="checkbox-item">
          <label className="item-label">
            <Checkbox
              id={"all"}
              name={"all"}
              className="checkbox"
              onChange={this.handleCheckData}
              checked={this.setCheckStatus("all")}
            />
            <span className="text">Selecionar todos</span>
          </label>
        </li>
        {this.state.options.map(row => {
          return (
            <li key={row.value} className="checkbox-item">
              <label className="item-label">
                <Checkbox
                  id={row.value}
                  name={row.text}
                  className="checkbox"
                  onChange={this.handleCheckData}
                  checked={this.setCheckStatus(row)}
                />
                <span className="text">{row.text}</span>
              </label>
            </li>
          )
        })}
      </ul>
    )
  }
  //#endregion

  //#region Render methods

  handleClick = () => {
    this.setState({
      active: !this.state.active
    })
  }

  handleCheckData = (event) => {
    this.props.handleCheckData(event, this.props.label)
  }

  handleClear = () => {
    this.props.clearCheckedData(this.props.label)
  }

  handleSend = () => {
    this.handleClick()
    this.props.sendCheckedData(this.props.label)
  }

  //#endregion

  render() {
    return(
        <div className={classnames("dropdown-container")}>
          <Link to='#' onClick={this.handleClick}>
            <div className="button button--retangle">
              <div className='button--retangle-icon-left'>
                <i className='material-icons'>{this.props.label === "Pontos" ? "group_work" : "date_range"}</i>
              </div>
              <div className={classnames('button--retangle-icon-text', {'button--retangle-icon-text-2-letters': this.props.label.length === 10}, {'button--retangle-icon-text-3-letters': this.props.label.length === 11})}>
                {this.props.label}
              </div>
              <div className='button--retangle-icon-right'>
                <i className='material-icons'>arrow_drop_down</i>
              </div>
            </div>
          </Link>
          {this.state.active ?
            <ClickOutside onClickOutside={this.handleClick}>
              <div className={classnames('dropdown-div',{'period-dropdown': this.props.graphicActive && this.props.graphicControl}, {'soil-dropdown': this.props.optionsType === 'soil'})}>
                <div className={classnames("button button--retangle", {'button--retangle-active': this.state.active})}>
                  <i className='material-icons button--retangle-icon-left'>{this.props.label === "Pontos" ? "group_work" : "date_range"}</i>
                  <div className='button--retangle-icon-text'>
                    {this.props.label}
                  </div>
                </div>
              <PerfectScrollbar className="dropdown-scroll">
                {this.props.farms ? <div><SearchInput className="search-input" placeholder="Buscar Pontos" onChange={this.searchUpdated} /></div> : null}
                <div className={classnames('checkbox-list', {'years-list': !this.props.farms}, {'radio-list': (!this.props.farms && this.props.bhboxType) || this.props.graphicActive})}>
                  {this.renderOptions(this.props.data)}
                </div>
              </PerfectScrollbar>
              <footer className='dropdown-buttons'>
                <div onClick={this.handleClear}><img className="img-clear" src="/images/clear-icon.png" alt="LIMPAR"/></div>
                <div>
                  <button id={this.props.farms ? 'farms' : 'years'} className='btn btn-ok-drop right' onClick={this.handleSend}>OK</button>
                </div>
              </footer>
            </div>
          </ClickOutside>
          : null}
        </div>
    )
  }

}
export default DashboardDropdown
