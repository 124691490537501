import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import RadioButtonGroup from '../../common/RadioButtonGroup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ClickOutside from 'react-click-outside'

class TemperatureDropdown extends Component {
  constructor(props){
    super(props)

    this.state ={
      label: 'Exibir Todas'
    }

    this.radioSelect = this.props.radioSelect
  }

  //Lifecycle methods
  componentDidUpdate(prevProps){
    if(prevProps.radioSelect !== this.props.radioSelect){
      this.setState({
        label: this.valueToLabel(this.props.radioSelect)
      })
    }
  }

  //#region Helper methods
  valueToLabel(radioSelect){
    switch (radioSelect) {
      case 'all':
        return 'Exibir Todas'
      case 'tmax':
        return 'Temperatura Máxima'
      case 'tmean':
        return 'Temperatura Média'
      case 'tmin':
        return 'Temperatura Mínima'    
      default:
        break;
    }
  }

  renderOptions(){
    let tempOptions = [
      {value: 'all', text: 'Exibir Todas'},
      {value: 'tmax', text: 'Temperatura Máxima'},
      {value: 'tmean', text: 'Temperatura Média'},
      {value: 'tmin', text: 'Temperatura Mínima'}
    ]

    return(
      <RadioButtonGroup
        name={'options'}
        listOfItems={tempOptions}
        selectedItemCallback={this.handleRadio}
        checkedOption={this.props.radioSelect}
      />
    )
  }
  //#endregion

  //#region Render methods
  handleClick = () => {
    this.props.onClick()
  }

  handleRadio = (e) => {
    this.props.onClick()
    this.props.onTemperatureSelect(e)
  }
  //#endregion

  render() {
    return(
        <div className={classnames('header-div-item-outline', {'header-div-item-spacing': this.props.rightNeighbor})}>
          <Link to='#' onClick={this.handleClick}>
            <div className="button button--retangle">
              <div className='button--retangle-icon-left'>
                <i className='material-icons'>{this.props.leftIcon}</i>
              </div>
              <div className={classnames('button--retangle-icon-text', {'button--retangle-icon-text-2-letters': this.state.length === 10}, {'button--retangle-icon-text-3-letters': this.state.label.length === 11})}>
                {this.state.label}
              </div>
              <div className='button--retangle-icon-right'>
                <i className='material-icons'>arrow_drop_down</i>
              </div>
            </div>
          </Link>
          {this.props.active ?
            <ClickOutside onClickOutside={this.props.onClick}>
              <div className={classnames('dropdown-div', 'soil-dropdown')}>
                <div className={classnames("button button--retangle", {'button--retangle-active': this.props.active}, 'button--retangle-active-multiple')}>
                  <i className='material-icons button--retangle-icon-left'>{this.props.leftIcon}</i>
                  <div className='button--retangle-icon-text'>
                    {this.state.label}
                  </div>
                </div>
              <PerfectScrollbar className="dropdown-scroll">
                <div className={classnames('checkbox-list', 'years-list')}>
                  {this.renderOptions()}
                </div>
              </PerfectScrollbar>
            </div>
          </ClickOutside>
          : null}
        </div>
    )
  }

}
export default TemperatureDropdown
