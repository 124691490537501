import React, { Component } from "react";
import { connect } from "react-redux";

import { TextField, Grid } from "@material-ui/core";
import Select from "../../common/SearchDropdown";

import { downloadIndexData, resetDownload } from "../../../actions/admin";

class IndexForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      soil: {
        state: "soilTexture",
        value: "veryClayey",
        label: "Muito argiloso",
      },
      fromYear: "",
      toYear: "",
      required: {},
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  HandleSelectChange = (event) => {
    this.setState({
      [event.state]: event,
    });
  };

  handleDateChange = (event) => {
    this.setState({
      plantingDate: event,
    });
  };

  soilToCad(soil) {
    switch (soil) {
      case "sandy":
        return "25";
      case "sandyAverageTexture":
        return "50";
      case "mediumTexture":
        return "75";
      case "clayey":
        return "100";
      case "veryClayey":
        return "125";
      default:
        return "";
    }
  }

  onSubmit = async () => {
    let fromYearFlag = false,
      toYearFlag = false,
      pointsFlag = false;

    const data = {
      idsFarmsOfCheckbox: this.props.idsFarmsOfCheckbox,
      variable: this.props.type,
      points: this.props.points,
      cad: this.soilToCad(this.state.soil.value),
      soil_texture: this.state.soil.value,
      initial_year: this.state.fromYear,
      final_year: this.state.toYear,
    };

    if (
      this.state.fromYear !== "" &&
      this.state.toYear !== ""
    ) {
      this.props.setPageToDownload();
      this.props.setLinkToDownload(await downloadIndexData(data));
    } else {
      if (this.state.fromYear === "") {
        fromYearFlag = !fromYearFlag;
      }
      if (this.state.toYear === "") {
        toYearFlag = !toYearFlag;
      }
      if (this.props.pointsRequired.length === 0) {
        pointsFlag = !pointsFlag;
      }
      this.setState({
        required: {
          fromYear: fromYearFlag,
          toYear: toYearFlag,
        },
      });
      this.props.parentStatePoints(pointsFlag);
    }
  };

  render() {
    return (
      <>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: "50px" }}
        >
          <Grid item className="product-item" xl={3} lg={3}>
            <p className="label">Textura do solo</p>
            <Select
              name="texture"
              inputProps={{ name: "soilTexture", id: "soilTexture-input" }}
              value={this.state.soil}
              onChange={this.HandleSelectChange}
              options={[
                {
                  state: "soil",
                  value: "veryClayey",
                  label: "Muito argiloso",
                },
                { state: "soil", value: "clayey", label: "Argiloso" },
                {
                  state: "soil",
                  value: "mediumTexture",
                  label: "Textura média",
                },
                {
                  state: "soil",
                  value: "sandyAverageTexture",
                  label: "Textura média arenosa",
                },
                { state: "soil", value: "sandy", label: "Arenoso" },
              ]}
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <p className="label">Ano inicial</p>
            {this.state.required.fromYear ? (
              <div style={{ color: "red", marginTop: "5px" }}>
                *Escolha um ano inicial
              </div>
            ) : null}
            <TextField
              margin={"dense"}
              name="fromYear"
              type="text"
              value={this.state.efficiency}
              onChange={this.handleChange}
              InputProps={{
                disableUnderline: true,
                style: { width: "100%" },
                error: true,
                inputProps: { style: { height: "2rem", margin: 0 } },
              }}
              className="text-fields"
              style={{ width: "100%" }}
              FormHelperTextProps={{
                error: true,
                className: "input-error-text",
              }}
            />
          </Grid>
          <Grid container item xl={3} lg={3}>
            <p>Ano final</p>
            {this.state.required.toYear ? (
              <div
                style={{
                  color: "red",
                  margin: "5px 0 0 10%",
                  width: "100%",
                }}
              >
                *Escolha um ano final
              </div>
            ) : null}
            <TextField
              fullWidth
              margin={"dense"}
              name="toYear"
              type="text"
              value={this.state.plantingDate}
              onChange={this.handleChange}
              InputProps={{
                disableUnderline: true,
                style: { width: "100%" },
                error: true,
                inputProps: { style: { height: "2rem", margin: 0 } },
              }}
              className="text-fields"
              style={{ width: "90%" }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          style={{ padding: "50px" }}
        >
          <Grid item>
            <div className="row button-row">
              <button className="btn wave-light green" onClick={this.onSubmit}>
                DOWNLOAD
              </button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    downloadDataReturn: state.admin.downloadData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetDownload: () => {
      dispatch(resetDownload());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexForm);
