import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authHeader } from "../../api/config";

// Action Types
import { changeScheduleStatus } from '../../actions/schedule';

// Material UI Components
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// React Components
import EmailInput from './EmailInput';
import PeriodRadios from './PeriodRadios';
import FrequencyRadios from './FrequencyRadios';

const styles = {
  rootContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px 20px',
    width: '661px',
    minHeight: '545px',
    backgroundColor: '#FFFFFF',
    border: '0.2px solid #707070',
    borderRadius: '16px',
  },
  modalSection: {
    margin: '10px 0',
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    color: '#000000',
  },
  modalHeader: {
    font: '18px/24px Roboto',
    marginBottom: '5px',
    color: '#6AA426',
  },
  modalSubheader: {
    font: '16px/24px Roboto',
    color: '#000000',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  addIcon: {
    marginLeft: '10px',
  },
  radioLabel: {
    height: '32px',
  },
  radioButtons: {
    color: '#6AA426',
  },
  submitButton: {
    backgroundColor: '#6AA426',
    borderRadius: '8px',
    color: '#FFFFFF',
    font: '15px/20px Roboto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

function EmailModal(props) {
  const [emails, setEmails] = useState({ email1: '' });
  const [frequency, setFrequency] = useState('');
  const [period, setPeriod] = useState('');
  const [emailsQuant, setEmailsQuant] = useState(1);

  const dispatch = useDispatch();

  const { classes, showSchedule, toggleSchedule } = props;

  const handleEmails = ({ target: { name, value } }) => {
    setEmails({ ...emails, [name]: value })
  };

  const handleFrequency = ({ target: { value } }) => {
    setFrequency(value);
  };

  const handlePeriod = ({ target: { value } }) => {
    setPeriod(value);
  };

  const addEmailField = () => {
    if (emailsQuant < 5) {
      setEmailsQuant(emailsQuant + 1);
    }
  };

  const getFrequency = () => {
    switch (frequency) {
      case 'diariamente':
        return 1;
      case 'semanalmente':
        return 7;
      case 'mensalmente':
        return 31;
      default:
        return;
    }
  }

  const submitEmails = async (form) => {
    const url = process.env.REACT_APP_API + 'api/email_register';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(form),
      });
      const data = await response.json();
      dispatch(changeScheduleStatus(data))
    } catch (err) {
      throw err;
    }
  };

  const handleClick = () => {
    const form = {
      point_id: props.activeFarm,
      period: getFrequency(),
      day_time: period,
      graphic_product_type: props.reportType,
      graphic_visualization_type: props.visualizationType,
      graphic_scale: props.graphicRange,
      graphic_type: props.graphicType,
      graphic_tb: null,
      email_list: Object.values(emails).filter((email) => email),
    }
    toggleSchedule();
    submitEmails(form);
  }

  useEffect(() => {
    const addKey = () => {
      setEmails((e) => ({ ...e, [`email${emailsQuant}`]: '' }));
    };
    addKey();
  }, [emailsQuant]);

  return (
    <Modal open={ showSchedule }>
      <Grid container className={ classes.rootContainer }>
          <Grid item xs={ 12 } className={ classes.closeIconContainer }>
            <Fab size="small" className={ classes.closeIcon } onClick={ toggleSchedule }>
              <CloseIcon />
            </Fab>
          </Grid>

          <Grid item xs={ 12 } className={ classes.modalSection }>
            <Typography className={ classes.modalHeader }>
              Cadastrar email para receber o Relatório Agrometeorológico
            </Typography>
            <Typography className={ classes.modalSubheader }>
              Fazendo o agendamento, você recebe em seu email o relatório agrometeorológico.
            </Typography>
          </Grid>

          <Grid item xs={ 12 } className={ classes.modalSection }>
            <Typography align="left">Cadastrar email</Typography>
            {
              Object.entries(emails).map(([name, value]) => (
                <EmailInput
                  key={ name }
                  classes={ classes }
                  name={ name }
                  value={ value }
                  handleChange={ handleEmails }
                  addEmailField={ addEmailField }
                />
              ))
            }
          </Grid>

          <Grid item xs={ 12 }>
            <FrequencyRadios classes={ classes } handleFrequency={ handleFrequency } />
          </Grid>

          <Grid item xs={ 12 }>
            <PeriodRadios classes={ classes } handlePeriod={ handlePeriod } />
          </Grid>

          <Grid item xs={ 12 } className={ classes.buttonContainer }>
            <Button
              className={ classes.submitButton }
              variant="contained"
              onClick={ handleClick }
            >
              CADASTRAR AGENDAMENTO DO EMAIL
            </Button>
          </Grid>
      </Grid>
    </Modal>
  );
}

export default withStyles(styles)(EmailModal);
