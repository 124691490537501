import React from 'react'
import MonthSelect from '../../MonthSelect';
import { Button } from '@mui/material';

const BhboxFilter = ({
  onChange,
  filterParamsFromPeriod,
  filterParamsToPeriod,
  visualizationType,
  handlePeriodFilter,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <h6>{'Período: '}</h6>
      <MonthSelect
        type={'fromPeriod'}
        onDateChange={onChange}
        value={filterParamsFromPeriod}
        graphicRange={'monthly'}
        visualizationType={visualizationType}
      />
      <h6>a </h6>
      <MonthSelect
        type={'toPeriod'}
        onDateChange={onChange}
        value={filterParamsToPeriod}
        graphicRange={'monthly'}
        visualizationType={visualizationType}
      />
      <Button
        sx={{
          margin: '5px',
          height: '25px',
          color: 'white',
          background: '#6bb324',
          textTransform: 'none',
          cursor: 'pointer',
          '&:hover': {
          background: '#6bb324',
          },
        }}
        onClick={handlePeriodFilter}
      >
        Filtrar
      </Button>
    </div>
  )
}

export default BhboxFilter;
