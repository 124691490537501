import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import SearchInput, {createFilter} from 'react-search-input'

import {toggleSidebar, toggleMoreFarms} from '../actions/global'
import { setActiveFarm } from '../actions/farms'
import { resetReport, setReportTypes } from '../actions/reports'
import { setProductivityUndefined } from '../actions/productivity'
import { resetGrid } from '../actions/map'
import { resetFloweringReport } from '../actions/flowering'

const KEYS_TO_FILTERS = ['name']

class MoreFarmsList extends Component {
  constructor(props){
    super(props)

    this.state ={
      searchTerm: ''
    }
  }

  handleClick = (e) => {
    this.props.resetFloweringReport();
    this.props.resetReport()
    this.props.setProductivityUndefined()
    this.props.resetGrid()
    this.props.setReportTypes([])
    this.props.setActiveFarm(parseInt(e.target.id,10))
    this.props.toggleMoreFarmsActive()
  }

  searchUpdated = (term) => {
    this.setState({searchTerm: term})
  }

  render() {
    const filteredFarms = this.props.farms['farms'].filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
    return(
      <div>
        <div className='separator-div'></div>
        <div className='search-div'>
          <SearchInput className="search-input" placeholder="Buscar locais cadastrados" onChange={this.searchUpdated} />
          <i className='material-icons search-icon'>search</i>
        </div>
        <ul className='farms-list'>
          { filteredFarms.map((farm,index) =>
            <li key={farm.id}>
              <button id={farm.id} className='button--trans-farms' onClick={this.handleClick}>
                <span id={farm.id} className={classnames('list-circle',{'farm-label-size-1': farm.initials.length === 1, 'farm-label-size-2': farm.initials.length === 2, 'farm-label-size-3': farm.initials.length === 3, 'farm-label-size-4': farm.initials.length === 4})}>{ farm.initials }</span>
                {farm.name ? farm.name.substring(0,30) : ''}
              </button>
            </li>
          ) }
        </ul>
      </div>
    )
  }
}



function mapStateToProps(state) {
  return {
    farms: state.farms.points,
    sidebarActive: state.global.sidebarActive,
    reportActive: state.Reports.reportActive,
    moreFarmsActive: state.global.moreFarmsActive
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleMoreFarmsActive: () =>{
      dispatch(toggleMoreFarms())
    },
    toggleSidebar: () =>{
      dispatch(toggleSidebar())
    },
    setActiveFarm: (id) =>{
      dispatch(setActiveFarm(id))
    },
    resetReport: () => {
      dispatch(resetReport())
    },
    setProductivityUndefined: () => {
      dispatch(setProductivityUndefined())
    },
    resetGrid: () => {
      dispatch(resetGrid())
    },
    setReportTypes: (data) => {
      dispatch(setReportTypes(data))
    },
    resetFloweringReport: () => {
      dispatch(resetFloweringReport());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreFarmsList)
