import React, { Component } from 'react'
import InputField from '../common/InputField'
import validateInput from '../../shared/validators/signin'
import { connect } from 'react-redux'
import { createUser } from "../../actions/users"
import { adminUpdatePoints, resetServerMessage } from "../../actions/admin"
class UserData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      identifier: '',
      password: '',
      passwordConfirm: '',
      cpf_cnpj: '',
      errors: {},
      loginErrors: {},
      isLoading: false,
    }

    this.lastSizeCpf = 0
  }

  //Lifecycle methods
  componentDidUpdate(prevProps) {
    if(this.props.createResult && prevProps.createResult !== this.props.createResult) {
      if(this.props.userPlan === "basic") {
        const data = {
          user_id: this.props.createResult.user_id,
          points_add: this.props.points
        }  
        this.props.adminUpdatePoints(data)
      }
      else {
        this.props.closeModal()
      }
    }
    if(this.props.serverMessage !== "") {
      this.props.resetServerMessage()
      this.props.closeModal()
    }
  }

  //Helper methods
  isValid() {
    const { errors, isValid } = validateInput(this.state)
    if(!isValid) {
      this.setState({ errors })
    }

    return isValid
  }

  //#region Render methods
  onChange = (e) => {
    let erasing = false
    if (e.target.name === 'cpf_cnpj') {
      if(this.lastSizeCpf < e.target.value.length){
        erasing = false
      }else{
        erasing = true
      }
      this.lastSizeCpf = e.target.value.length
      if(e.target.value.length === 3 || e.target.value.length === 7){
        let value = e.target.value + '.'
        if (erasing) {
              this.setState({ [e.target.name]: e.target.value })
        } else {
              this.setState({ [e.target.name]: value })
        }
      } else if (e.target.value.length === 11 || e.target.value.length === 16) {
          let value = e.target.value + '-'
        if (erasing) {
              this.setState({ [e.target.name]: e.target.value })
        } else {
              this.setState({ [e.target.name]: value })
        }
      } else if (e.target.value.length === 15) {
          let value = e.target.value.replace('-', '')
        value = value.split('.').join('')
        value = value[0] + value[1] + '.' + value[2] + value[3] + value[4] + '.' + value[5] + value[6] + value[7] + '/' + value[8] + value[9] + value[10] + value[11] + '-'
        if (erasing) {
              this.setState({ [e.target.name]: e.target.value })
        }else{
              this.setState({ [e.target.name]: value })
        }
      } else {
          this.setState({ [e.target.name]: e.target.value })
      }
    }
     else {
      this.setState({ [e.target.name]: e.target.value })
    }
    let errors = Object.assign({}, this.state.errors)
    delete errors[e.target.name]
    this.setState({
      errors: errors
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    if(this.isValid()) {
      this.setState({ errors: {}, isLoading: true })
      this.props.createUser(
        this.state,
        this.props.userRole,
        this.props.userPlan,
        this.props.cultures,
        this.props.smartSelected
      );
    }
  }
  //#endregion

  render() {
    const { errors, identifier, password,passwordConfirm, cpf_cnpj} = this.state
    return(
      <div className="signin-modal-user-data">
        <form onSubmit={this.onSubmit}>
          <div className="row signin-row first-row">
            <div className="col s6">
              <InputField
                name='identifier'
                label='Email'
                type='email'
                onChange={ this.onChange }
                value={ identifier }
                error={ errors.identifier }
                active={ identifier !== ''}
                activeFarm={'a'}
              />
            </div>
            <div className="col s6" ref={(input) => {this.input = input }}>
              <InputField
                name='cpf_cnpj'
                label='CPF/CNPJ'
                type='text'
                value={ cpf_cnpj }
                error={ errors.cpf_cnpj }
                onChange={ this.onChange }
                active={cpf_cnpj !== ''}
                activeFarm={'a'}
                length={19}
              />
            </div>
          </div>
          <div className="row signin-row">
            <div className="col s6">
              <InputField
                name='password'
                label='Senha'
                type='password'
                value={ password }
                error={ errors.password }
                onChange={ this.onChange }
                active={password !== ''}
                activeFarm={'a'}
              />
            </div>
            <div className="col s6">
              <InputField
                name='passwordConfirm'
                label='Confirme sua senha'
                type='password'
                value={ passwordConfirm }
                error={ errors.passwordConfirm }
                onChange={ this.onChange }
                active={passwordConfirm !== ''}
                activeFarm={'a'}
              />
            </div>
          </div>
          <div className="row button login-area-form-container-inside-buttons-row">
            <button className='btn-flat btn-drop' style={{marginRight: '8px'}} onClick={this.props.backClick}>VOLTAR</button>
            <button type='submit' className='btn wave-light agrymet-green' style={{marginLeft: '8px', fontSize: '12px'}}>CADASTRAR</button>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    createResult: state.users.createResult,
    serverMessage: state.admin.serverMessage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    createUser: (data, userRole, userPlan, cultures, smartSelected) => {
      dispatch(createUser(data, userRole, userPlan, cultures, smartSelected))
    },
    adminUpdatePoints: (data) => {
      dispatch(adminUpdatePoints(data))
    },
    resetServerMessage: () => {
      dispatch(resetServerMessage())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserData)
