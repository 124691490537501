import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";

import {
  plan, fert, traf, herb, ps, cresc, pulv, agryfire as fireColor,
} from "../../../shared/utils/MapColorsUtils";
import { isArray } from "lodash";

export function ClimateFavorabilityReportChart({ agryindexAndAgryfiredata }) {
  const [numberAndStringMonth, setNumberAndStringMonth] = useState([]);

  const soil = useSelector((state) => state.Reports.lastUsedSoil);

  const { agryfire, agryindex } = agryindexAndAgryfiredata;

  const labels = [
    {
      label: "Plantio/Semeadura",
      initials: "plan",
    },
    {
      label: "Herbicida",
      initials: "herb",
    },
    {
      label: "Condição Tráfego",
      initials: "traf",
    },
    {
      label: "Preparo de Solo",
      initials: "ps",
    },
    {
      label: "Adubação",
      initials: "fert",
    },
    {
      label: "Crescimento das Plantas",
      initials: "cresc",
    },
    {
      label: "Pulverização",
      initials: "pulv",
    },
  ];

  const fireLabel = {
    label: "FIRE - Incêndio",
    initials: "agryfire",
  };

  const defineTheBoxColor = (type, data) => {
    if (type === "cresc") {
      if (data > 80) {
        return cresc[7].color;
      } else if (data <= 80 && data > 60) {
        return cresc[6].color;
      } else if (data <= 60 && data > 40) {
        return cresc[5].color;
      } else if (data <= 40 && data > 30) {
        return cresc[4].color;
      } else if (data <= 30 && data > 20) {
        return cresc[3].color;
      } else if (data <= 20 && data > 10) {
        return cresc[2].color;
      } else if (data <= 10 && data >= 1) {
        return cresc[1].color;
      } else if (data < 1) {
        return cresc[0].color;
      }
    } else if (type === "plan") {
      if (soil === "veryClayey" || soil === "clayey") {
        if (data <= 50 || data > 90) {
          return plan[2].color;
        } else if (data > 50 && data <= 70) {
          return plan[1].color;
        } else if (data > 70) {
          return plan[0].color;
        }
      } else if (soil === "sandyAverageTexture" || soil === "sandy") {
        if (data <= 60 || data > 97) {
          return plan[2].color;
        } else if (data > 60 && data <= 80) {
          return plan[1].color;
        } else if (data > 80) {
          return plan[0].color;
        }
      } else {
        if (data <= 55 || data > 95) {
          return plan[2].color;
        } else if (data > 55 && data <= 75) {
          return plan[1].color;
        } else if (data > 75) {
          return plan[0].color;
        }
      }
    } else if (type === "fert") {
      if (soil === "veryClayey" || soil === "clayey") {
        if (data <= 50 || data > 90) {
          return fert[2].color;
        } else if (data > 50 && data <= 70) {
          return fert[1].color;
        } else if (data > 70) {
          return fert[0].color;
        }
      } else if (soil === "sandyAverageTexture" || soil === "sandy") {
        if (data <= 60 || data > 97) {
          return fert[2].color;
        } else if (data > 60 && data <= 80) {
          return fert[1].color;
        } else if (data > 80) {
          return fert[0].color;
        }
      } else {
        if (data <= 55 || data > 95) {
          return fert[2].color;
        } else if (data > 55 && data <= 75) {
          return fert[1].color;
        } else if (data > 75) {
          return fert[0].color;
        }
      }
    } else if (type === "traf") {
      if (soil === "veryClayey") {
        if (data <= 80) {
          return traf[0].color;
        } else if (data > 80 && data <= 90) {
          return traf[1].color;
        } else if (data > 90) {
          return traf[2].color;
        }
      } else if (soil === "clayey") {
        if (data <= 85) {
          return traf[0].color;
        } else if (data > 85 && data <= 90) {
          return traf[1].color;
        } else if (data > 90) {
          return traf[2].color;
        }
      } else if (soil === "sandyAverageTexture" || soil === "sandy") {
        if (data <= 95) {
          return traf[0].color;
        } else if (data > 95 && data <= 97) {
          return traf[1].color;
        } else if (data > 97) {
          return traf[2].color;
        }
      } else {
        if (data <= 90) {
          return traf[0].color;
        } else if (data > 90 && data <= 95) {
          return traf[1].color;
        } else if (data > 95) {
          return traf[2].color;
        }
      }
    } else if (type === "herb") {
      if (soil === "veryClayey") {
        if (data <= 30) {
          return herb[3].color;
        } else if (data > 30 && data <= 50) {
          return herb[2].color;
        } else if (data > 50 && data <= 70) {
          return herb[1].color;
        } else if (data > 70) {
          return herb[0].color;
        }
      } else if (soil === "clayey") {
        if (data <= 35) {
          return herb[3].color;
        } else if (data > 35 && data <= 55) {
          return herb[2].color;
        } else if (data > 55 && data <= 75) {
          return herb[1].color;
        } else if (data > 75) {
          return herb[0].color;
        }
      } else if (soil === "mediumTexture") {
        if (data <= 40) {
          return herb[3].color;
        } else if (data > 40 && data <= 60) {
          return herb[2].color;
        } else if (data > 60 && data <= 80) {
          return herb[1].color;
        } else if (data > 80) {
          return herb[0].color;
        }
      } else if (soil === "sandyAverageTexture") {
        if (data <= 45) {
          return herb[3].color;
        } else if (data > 45 && data <= 65) {
          return herb[2].color;
        } else if (data > 65 && data <= 85) {
          return herb[1].color;
        } else if (data > 85) {
          return herb[0].color;
        }
      } else {
        if (data <= 50) {
          return herb[3].color;
        } else if (data > 50 && data <= 70) {
          return herb[2].color;
        } else if (data > 70 && data <= 90) {
          return herb[1].color;
        } else if (data > 90) {
          return herb[0].color;
        }
      }
    } else if (type === "ps") {
      if (soil === "veryClayey") {
        if (data <= 25) {
          return ps[4].color;
        } else if (data > 25 && data <= 30) {
          return ps[3].color;
        } else if (data > 30 && data <= 75) {
          return ps[2].color;
        } else if (data > 75 && data <= 85) {
          return ps[1].color;
        } else if (data > 85) {
          return ps[0].color;
        }
      } else if (soil === "clayey") {
        if (data <= 30) {
          return ps[4].color;
        } else if (data > 30 && data <= 35) {
          return ps[3].color;
        } else if (data > 35 && data <= 80) {
          return ps[2].color;
        } else if (data > 80 && data <= 90) {
          return ps[1].color;
        } else if (data > 90) {
          return ps[0].color;
        }
      } else if (soil === "mediumTexture") {
        if (data <= 35) {
          return ps[4].color;
        } else if (data > 35 && data <= 40) {
          return ps[3].color;
        } else if (data > 40 && data <= 85) {
          return ps[2].color;
        } else if (data > 85 && data <= 93) {
          return ps[1].color;
        } else if (data > 93) {
          return ps[0].color;
        }
      } else if (soil === "sandyAverageTexture") {
        if (data <= 40) {
          return ps[4].color;
        } else if (data > 40 && data <= 45) {
          return ps[3].color;
        } else if (data > 45 && data <= 90) {
          return ps[2].color;
        } else if (data > 90 && data <= 95) {
          return ps[1].color;
        } else if (data > 95) {
          return ps[0].color;
        }
      } else {
        if (data <= 45) {
          return ps[4].color;
        } else if (data > 45 && data <= 50) {
          return ps[3].color;
        } else if (data > 50 && data <= 95) {
          return ps[2].color;
        } else if (data > 95 && data <= 97) {
          return ps[1].color;
        } else if (data > 97) {
          return ps[0].color;
        }
      }
    } else if (type === "pulv") {
      if (data === 0) {
        return pulv[1].color;
      } else {
        return pulv[0].color;
      }
    } else if (type === "fire") {
      if (data === undefined || data === -1) {
        return 5;
      } else {
        if (data <= 3) {
          return 0;
        } else if (data > 3 && data <= 8) {
          return 1;
        } else if (data > 8 && data <= 14) {
          return 2;
        } else if (data > 14 && data <= 24) {
          return 3;
        } else if (data > 24) {
          return 4;
        }
      }
    }
  };

  const defineBorderOfTheBoxBasedOnDate = (day, month, year, label) => {
    var dateOfTheData = new Date(`${year}/${month}/${day}`);
    var today = new Date();

    if ((label === "pulv" || label === "plan") && dateOfTheData >= today) {
      return "3px solid #000";
    }
    if (today.getMonth() + 1 === month && today.getUTCDate() === day) {
      return "3px solid #000";
    }
    if (
      today.getMonth() + 2 === month &&
      new Date(new Date().setDate(new Date().getDate() + 29)).getDate() === day
    ) {
      return "3px solid #000";
    }

    return "1px solid #000000";
  };

  const renderGraphBody = (monthData, label, index, currentArray) => {
    return (
      <Grid
        item
        style={{
          backgroundColor: defineTheBoxColor(label, monthData.value),
          color: defineTheBoxColor(label, monthData.value),
          fontSize: "5px",
          borderLeft:
            monthData.month === new Date().getMonth() + 1 &&
            monthData.year >= new Date().getFullYear()
              ? defineBorderOfTheBoxBasedOnDate(
                  monthData.day,
                  monthData.month,
                  monthData.year
                )
              : "1px solid #000000",
          borderTop:
            label === "plan" &&
            monthData.month >= new Date().getMonth() + 1 &&
            monthData.year >= new Date().getFullYear()
              ? defineBorderOfTheBoxBasedOnDate(
                  monthData.day,
                  monthData.month,
                  monthData.year,
                  label
                )
              : "",
          borderBottom:
            label === "pulv" &&
            monthData.month >= new Date().getMonth() + 1 &&
            monthData.year >= new Date().getFullYear()
              ? defineBorderOfTheBoxBasedOnDate(
                  monthData.day,
                  monthData.month,
                  monthData.year,
                  label
                )
              : "1px solid #000000",
          borderRight:
            index !== currentArray.length - 1
              ? ""
              : monthData.month >= new Date().getMonth() + 2 &&
                monthData.year >= new Date().getFullYear()
              ? defineBorderOfTheBoxBasedOnDate(
                  monthData.day,
                  monthData.month,
                  monthData.year
                )
              : "1px solid #000000",
          width: "2.75%",
          height: "20px",
        }}
      >
        {parseInt(monthData.value)}
      </Grid>
    );
  };

  const monthStrings = [
    "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
    "Jul", "Ago", "Set", "Out", "Nov", "Dez",
  ];

  useEffect(() => {
    const monthsStrings2 = [
      "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
      "Jul", "Ago", "Set", "Out", "Nov", "Dez",
    ];

    const monthsToRenderLabel = agryindex.plan.data
      .map((data) => data.month)
      .filter((numberMonth, index, array) => numberMonth !== array[index + 1])
      .map((monthNumberFiltered) => ({
          monthNumber: monthNumberFiltered,
          monthString:
            monthsStrings2[monthNumberFiltered - 1],
        })
      );

    setNumberAndStringMonth(monthsToRenderLabel);
  }, [agryindex.plan.data]);

  const objectEntriesMonthData = Object.entries(agryindex)[0][1].data;
  const auxLengthOfBoxOfEachMonth = numberAndStringMonth.map((month) =>
    objectEntriesMonthData.filter((data) => data.month === month.monthNumber)
  );

  return (
    <Grid container>
      <Grid item md={2}>
        {labels.map((objectLabel, index) => (
          <Grid
            item
            style={{
              marginTop: index === 0 ? "40px" : "2px",
              marginRight: "5px",
              fontSize: "12px",
              textAlign: "end",
            }}
          >
            {objectLabel.label}
          </Grid>
        ))}
      </Grid>
      <Grid item md={10}>
        <Grid container>
          <Grid container>
            {numberAndStringMonth.map((month, index) => (
              <Grid
                key={index}
                style={{
                  width: `${auxLengthOfBoxOfEachMonth[index].length * 2.67}%`,
                  borderLeft: "1px solid #000",
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  textAlign: "center",
                }}
              >
                {month.monthString}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container>
          {objectEntriesMonthData.map((monthData, index) => (
            <Grid
              key={index}
              style={{
                fontSize: "10px",
                borderRight: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderTop: "1px solid #000000",
                width: "2.67%",
                textAlign: "center",
              }}
              item
            >
              {monthData.day}
            </Grid>
          ))}
        </Grid>
        <Grid container style={{ width: "97%" }}>
          {Object.entries(agryindex).map((data) =>
            data[1].data.map((monthData, index, currentArray) =>
              renderGraphBody(monthData, data[0], index, currentArray)
            )
          )}
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "15px" }}>
        <Grid
          item
          md={2}
          style={{
            fontSize: "12px",
            textAlign: "end",
            padding: "0 5px 0 0",
            marginTop: "30px",
          }}
        >
          {fireLabel.label}
        </Grid>

        <Grid container item md={10}>
          {agryfire.data.map((month, index) => (
            <Grid
              style={{
                width: `${3 * 2.67}%`,
                borderLeft: "1px solid #000",
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                textAlign: "center",
              }}
            >
              {monthStrings[index]}
            </Grid>
          ))}
          <Grid container>
            {Object.entries(agryfire.data).map((entries) =>
              entries[1].map((data, index) => {
                return (
                  <Grid
                    key={index}
                    style={{
                      width: "2.67%",
                      fontSize: "10px",
                      border: "1px solid #000000",
                      color:
                        defineTheBoxColor(
                          "fire",
                          isArray(data) ? data[data.length - 1] : data
                        ) === 5
                          ? "#FFF"
                          : fireColor[
                              defineTheBoxColor(
                                "fire",
                                isArray(data) ? data[data.length - 1] : data
                              )
                            ].color,
                      backgroundColor:
                        defineTheBoxColor(
                          "fire",
                          isArray(data) ? data[data.length - 1] : data
                        ) === 5
                          ? "#FFF"
                          : fireColor[
                              defineTheBoxColor(
                                "fire",
                                isArray(data) ? data[data.length - 1] : data
                              )
                            ].color,
                    }}
                    item
                  >
                    {isArray(data) ? data[data.length - 1] : data}
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2}>
        {[...labels, fireLabel].map((objectLabel, index) => (
          <Grid
            item
            style={{
              marginTop: index === 0 ? "35px" : "0",
              marginRight: "5px",
              fontSize: "12px",
              textAlign: "end",
            }}
          >
            {`${objectLabel.label} - `}
          </Grid>
        ))}
      </Grid>
      <Grid item md={10}>
        {[plan, herb, traf, ps, fert, cresc, pulv, fireColor].map(
          (planObjectColor, index) => (
            <Grid container>
              {planObjectColor.map((objectColor) => (
                <>
                  <Grid
                    item
                    style={{
                      fontSize: "10px",
                      marginRight: "5px",
                      marginTop: index === 0 ? "37px" : "3px",
                    }}
                  >
                    {objectColor.value}
                  </Grid>
                  <Grid
                    item
                    style={{
                      marginTop: index === 0 ? "39px" : "5px",
                      marginRight: "5px",
                      width: "10px",
                      height: "10px",
                      backgroundColor: `${objectColor.color}`,
                    }}
                  />
                </>
              ))}
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};
