import CadApi from '../api/cad'

export const GET_CAD_SUCCESS = 'GET_CAD_SUCCESS'

export function getCad(soil){
    return function(dispatch){
        return CadApi.getCad(soil)
            .then(response => {
                dispatch(getCadSuccess(response))
            }).catch(error => {throw(error)})
    }
}

export function getCadSuccess(response){
    return({type: 'GET_CAD_SUCCESS', response})
}