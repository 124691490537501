import React, { Component, Fragment } from "react";
import Sidebar from "../containers/Sidebar";
import Map from "../components/Map";
import Navbar from "../components/Navbar";
import Loader from "../components/common/Loader";
import ReportContainer from "../containers/ReportContainer";
import Schedule from "../components/Schedule";
import KmzFiles from '../components/profile/KmzFiles';
import ProductivityContainer from "../containers/ProductivityContainer";
import EucalyptusProductivityContainer from "../containers/EucalyptusProductivityContainer";
import GeneralProductivityContainer from "../containers/GeneralProductivityContainer";
import SpecificProductivityContainer from "../containers/SpecificProductivityContainer";
import SpecificEucalyptusProductivityContainer from "../containers/SpecificEucalyptusProductivityContainer";
import FloweringContainer from "../containers/FloweringContainer";
import Dashboard from "../containers/Dashboard";
import BhboxProduct from "../containers/BhboxProducts";
import { connect } from "react-redux";
import ApiErrorBoundarie from "../errorBoudaries/ApiErrorBoundarie";
import jwt from "jsonwebtoken";
import { ToastContainer } from "react-toastify";
import { showToaster } from "../components/common/Toaster";

//actions
import { loginSuccess, validateToken } from "../actions/login";
import { getFarms, setActiveFarm, resetUpload } from "../actions/farms";
import { getNeutralGrid } from "../actions/map";
import { loadProfile } from "../actions/profile";
import {
  toggleSidebar,
  setCadError,
  toggleLoader,
  toggleProfileBar,
} from "../actions/global";
import {
  toggleReportContainer,
  resetReport,
  generateReport,
  setLastUsedCad,
  setLastVisualizedCad,
  setWeatherTypes,
  setReportTypes,
} from "../actions/reports";
import {
  generateProductivity,
  setProductivityUndefined,
  generateSpecificProductivity,
  generateSoyProductivity,
  generateCornProductivity,
  generateEucalyptusProductivity,
  setChangedCadFlag,
} from "../actions/productivity";
import {
  resetModalState,
  setUsePreviousCrop,
} from "../actions/GeneralCaneProductivityAction";

const AGRYTOOLS = ["herb", "plan", "ps", "fert", "traf"];
class Root extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPinClicked: false,
    };
  }

  //#region Lifecycle methods
  componentDidMount() {
    let token = localStorage.Token ? localStorage.Token : sessionStorage.Token;
    this.props.validateToken();
    window.setInterval(this.props.validateToken, 1800000);

    if (!this.props.isLogged && token !== undefined) {
      this.props.toggleLoader();
      this.props.loginSuccess(jwt.decode(token));
      if (jwt.decode(token)["type"] === "bank") {
        this.props.getNeutralGrid();
      } else {
        this.props.getFarms(token);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.uploadStatus && nextProps.uploadStatus.status === 200) {
      const toasterParams = {
        title: "SUCESSO!",
        message: nextProps.uploadStatus["message"],
        status: true,
        autoClose: true,
      };
      showToaster(toasterParams);
    } else if (
      nextProps.uploadStatus &&
      nextProps.uploadStatus.status === 500
    ) {
      const toasterParams = {
        title: "ERRO!",
        message: nextProps.uploadStatus["message"],
        status: false,
        autoClose: true,
      };
      showToaster(toasterParams);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.profileActive &&
      (prevProps.activeFarm !== this.props.activeFarm ||
        (prevProps.moreFarmsActive !== this.props.moreFarmsActive &&
          this.props.moreFarmsActive))
    ) {
      this.props.toggleProfileBar();
    }
  }

  //Helper methods
  renderSidebar() {
    if (
      (this.props.farms !== undefined &&
        (this.props.activeFarm !== -2 || this.props.moreFarmsActive)) ||
      this.props.user["type"] === "bank" ||
      this.props.profileActive
    ) {
      return <Sidebar />;
    }
  }

  //#region Render methods
  resetPinFlag = () => {
    this.setState({
      isPinClicked: false,
    });
  };

  onPinClicked = (e, showAlertCallback, isNavbarClicked = false) => {
    this.props.resetModalState();
    this.props.setUsePreviousCrop(true);

    let id;

    this.setState({
      isPinClicked: true,
    });

    if (e.latLng) {
      let coords =
        e.latLng.lat().toFixed(3).toString() +
        "," +
        e.latLng.lng().toFixed(3).toString();

      this.props.farms["farms"].forEach((farm) => {
        if (farm.coords === coords) {
          id = farm.id;
        }
      });
    } else {
      id = parseInt(e.target.value, 10);
    }

    this.props.setActiveFarm(id);

    if (this.props.isUsingCustomizedModal) {
    }
    if (this.props.reportTypes.length !== 0) {
      const farm = this.props.farms["farms"].filter(
        (farm) => farm.id === id
      )[0];

      let cad;

      //Dont ask me where the hardcoded numbers come from
      if (this.props.reportTypes[0] === "plan") {
        cad = 25;
      } else if (AGRYTOOLS.includes(this.props.reportTypes[0])) {
        cad = 50;
      } else {
        if (this.props.lastVisualizedCad !== "0") {
          cad = this.props.lastVisualizedCad;
        } else if (farm.cad !== "") {
          cad = farm.cad;
        } else if (this.props.lastUsedCad !== "0") {
          cad = this.props.lastUsedCad;
        } else {
          cad = 25;
        }
      }

      if (this.props.reportTypes === "favo") {
        this.props.toggleReportUndefined();

        this.props.setReportTypes("favo");

        let cad = this.props.cad;
        let farmAttrs = {
          coords: farm.coords,
          farmId: this.props.activeFarm,
          cad,
          year: new Date().getFullYear(),
        };
        this.props.generateReport("favo", farmAttrs);
        this.props.toggleSidebar();
        if (this.props.sidebarActive) this.props.toggleSidebar();

        return;
      }

      const farmAttrs = {
        name: farm.name,
        coords: farm.coords,
        farmId: id,
        cad,
        initials: farm.initials,
        type:
          this.props.weatherType !== undefined
            ? this.props.weatherType
            : "period",
        range:
          this.props.reportTypes[0] === "excdef"
            ? "decendial"
            : this.props.weatherRange !== undefined
            ? this.props.weatherRange
            : "daily",
        initial_date:
          this.props.reportTypes[0] === "cresc"
            ? (new Date().getFullYear() - 10).toString()
            : "1980",
        final_date: (new Date().getFullYear() + 1).toString(),
        tb: this.props.tBasal,
        cultivate_type: "early",
        visualization_type: this.props.visualizationType,
      };

      const type = this.props.reportTypes[0];

      if (
        type === "bhbox" ||
        type === "fmaac" ||
        type === "extbh" ||
        type === "etretp" ||
        type === "arm" ||
        type === "excdef" ||
        type === "productivity"
      ) {
        this.props.setLastUsedCad(cad);
      }

      if (
        this.props.reportTypes[0] === "extbh" ||
        this.props.reportTypes[0] === "fmaac" ||
        this.props.reportTypes[0] === "etretp" ||
        this.props.reportTypes[0] === "bhbox"
      ) {
        if (farm.cad !== "") {
          this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
          this.props.toggleReportUndefined();
          this.props.generateReport(this.props.reportTypes, farmAttrs);

          if (!this.props.reportActive && this.props.reportTypes.length !== 0) {
            this.props.toggleReportContainer();
          }
        } else {
          const toasterParams = {
            title: "Erro!",
            message: "Insira um valor para a CAD.",
            status: false,
            autoClose: true,
          };
          showAlertCallback(toasterParams);

          if (this.props.sidebarActive) {
            this.props.toggleSidebar();
          }

          if (!this.props.cadError) {
            this.props.setCadError();
          }

          if (this.props.reportActive) {
            this.props.toggleReportContainer();
          }
        }
      } else if (
        this.props.reportTypes === "productivity" ||
        this.props.reportTypes === "soy-general-productivity" ||
        this.props.reportTypes === "corn-general-productivity"
      ) {
        const coords = farm.coords.split(",");
        const requestData = {
          lat: coords[0],
          lon: coords[1],
          cad: farm.cad,
          point_id: farm.id,
        };

        this.props.setProductivityUndefined();
        if (this.props.reportTypes === "soy-general-productivity") {
          this.props.generateSoyProductivity(requestData, "general");
        } else if (this.props.reportTypes === "corn-general-productivity") {
          this.props.generateCornProductivity(requestData, "general");
        } else {
          this.props.generateProductivity(requestData);
        }
        this.props.toggleReportUndefined();

        if (!this.props.reportActive) {
          this.props.toggleReportContainer();
        }
      } else if (this.props.reportTypes === "cane-flowering") {
        this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
        this.props.toggleReportUndefined();
        if (!this.props.reportActive && this.props.reportTypes.length !== 0) {
          this.props.toggleReportContainer();
        }
      } else {
        this.props.setWeatherTypes(farmAttrs["type"], farmAttrs["range"]);
        this.props.toggleReportUndefined();
        this.props.generateReport(this.props.reportTypes, farmAttrs);

        if (!this.props.reportActive && this.props.reportTypes.length !== 0) {
          this.props.toggleReportContainer();
        }
      }

      if (isNavbarClicked) {
        this.props.toggleSidebar();
      }
    } else if (
      !this.props.sidebarActive &&
      this.props.reportTypes.length === 0 &&
      !isNavbarClicked
    ) {
      this.props.toggleSidebar();
    }
  };

  onProfileClicked = () => {
    this.props.setReportTypes([]);
    this.props.toggleReportUndefined();
    if (!this.props.generalInfo) {
      this.props.loadProfile(true);
    } else {
      if (!this.props.sidebarActive) {
        this.props.toggleSidebar();
      }
      this.props.toggleProfileBar();
      if (
        this.props.profileActive &&
        this.props.activeFarm === -2 &&
        !this.props.moreFarmsActive
      ) {
        if (this.props.farms.farms.length > 0) {
          this.props.setActiveFarm(this.props.farms.farms[0].id);
        } else {
          this.props.toggleSidebar();
        }
      }
    }
  };
  //#endregion

  render() {
    let report;

    if (this.props.user["type"] === "bank") {
      report = <ReportContainer />;
    } else if (this.props.farms !== undefined) {
      if (this.props.profileActive && this.props.scheduleActive) {
        report = <Schedule />;
      } else if (this.props.profileActive && this.props.kmzActive) {
        report = <KmzFiles />
      } else if (this.props.reportTypes === "productivity") {
        report = <ProductivityContainer />;
      } else if (this.props.reportTypes === "cane-flowering") {
        report = <FloweringContainer />;
      } else if (
        ["eucalyptus-spec-productivity"].includes(this.props.reportTypes)
      ) {
        report = <SpecificEucalyptusProductivityContainer />;
      } else if (
        [
          "productivity-spec",
          "soy-productivity",
          "corn-spec-productivity",
          "cotton-spec-productivity",
          "wheat-spec-productivity",
        ].includes(this.props.reportTypes)
      ) {
        report = <SpecificProductivityContainer />;
      } else if (
        [
          "soy-general-productivity",
          "corn-general-productivity",
          "cotton-general-productivity",
          "wheat-general-productivity",
        ].includes(this.props.reportTypes)
      ) {
        report = <GeneralProductivityContainer />;
      } else if (this.props.reportTypes === "eucalyptus-general-productivity") {
        report = <EucalyptusProductivityContainer />;
      } else if (this.props.reportTypes === "dashboard") {
        report = <Dashboard />;
      } else if (["def", "exc"].includes(this.props.reportTypes[0])) {
        report = <BhboxProduct />;
      } else if (this.props.reportTypes.length > 0) {
        report = <ReportContainer />;
      }
    }

    return (
      <Fragment>
        <ApiErrorBoundarie>
          <Loader active={this.props.loader} />
          <ToastContainer className="toast-alert-container" />
          <Navbar
            logged={this.props.isLogged}
            onPinClicked={this.onPinClicked}
            onProfileClicked={this.onProfileClicked}
          />
          {this.props.isLogged ? (
            <Map
              onPinClicked={this.onPinClicked}
              isPinClicked={this.state.isPinClicked}
              resetPinFlag={this.resetPinFlag}
            />
          ) : null}
          {this.renderSidebar()}
          {report}
        </ApiErrorBoundarie>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isUsingCustomizedModal:
      state.generalCaneProductivityReducer.isUsingCustomizedModal,
    farms: state.farms.points,
    activeFarm: state.farms.activeFarm,
    report: state.Reports.report,
    isLogged: state.auth.isLoggedIn,
    errors: state.auth.errors,
    loader: state.global.loader,
    user: state.auth.user,
    sidebarActive: state.global.sidebarActive,
    profileActive: state.global.profileActive,
    scheduleActive: state.global.scheduleActive,
    kmzActive: state.global.kmzActive,
    reportActive: state.Reports.reportActive,
    reportTypes: state.Reports.reportTypes,
    cadError: state.global.cadError,
    lastUsedCad: state.Reports.lastUsedCad,
    lastVisualizedCad: state.Reports.lastVisualizedCad,
    weatherType: state.Reports.graphicType,
    weatherRange: state.Reports.graphicRange,
    tBasal: state.Reports.tBasal,
    uploadStatus: state.farms.uploadStatus,
    moreFarmsActive: state.global.moreFarmsActive,
    listedPoint: state.global.listedPoint,
    visualizationType: state.global.visualizationType,
    generalInfo: state.profile.generalInfo,
    cad: state.cad.cad,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUsePreviousCrop: (isUsingPreviousCrop) => {
      dispatch(setUsePreviousCrop(isUsingPreviousCrop));
    },
    resetModalState: () => {
      dispatch(resetModalState());
    },
    toggleLoader: () => {
      dispatch(toggleLoader());
    },
    getFarms: (user) => {
      dispatch(getFarms(user));
    },
    getNeutralGrid: () => {
      dispatch(getNeutralGrid());
    },
    loginSuccess: (user) => {
      dispatch(loginSuccess(user));
    },
    setActiveFarm: (id) => {
      dispatch(setActiveFarm(id));
    },
    toggleSidebar: () => {
      dispatch(toggleSidebar());
    },
    toggleProfileBar: () => {
      dispatch(toggleProfileBar());
    },
    toggleReportContainer: () => {
      dispatch(toggleReportContainer());
    },
    toggleReportUndefined: () => {
      dispatch(resetReport());
    },
    setWeatherTypes: (graphicType, graphicRange) => {
      dispatch(setWeatherTypes(graphicType, graphicRange));
    },
    generateReport: (reportType, farmAttrs) => {
      dispatch(generateReport(reportType, farmAttrs));
    },
    setCadError: () => {
      dispatch(setCadError());
    },
    setLastUsedCad: (cad) => {
      dispatch(setLastUsedCad(cad));
    },
    setLastVisualizedCad: (cad) => {
      dispatch(setLastVisualizedCad(cad));
    },
    resetUpload: () => {
      dispatch(resetUpload());
    },
    generateProductivity: (data) => {
      dispatch(generateProductivity(data));
    },
    setProductivityUndefined: () => {
      dispatch(setProductivityUndefined());
    },
    generateSpecificProductivity: (data) => {
      dispatch(generateSpecificProductivity(data));
    },
    generateSoyProductivity: (data, type) => {
      dispatch(generateSoyProductivity(data, type));
    },
    generateCornProductivity: (data, type) => {
      dispatch(generateCornProductivity(data, type));
    },
    generateEucalyptusProductivity: (data, type) => {
      dispatch(generateEucalyptusProductivity(data, type));
    },
    validateToken: () => {
      dispatch(validateToken());
    },
    loadProfile: () => {
      dispatch(loadProfile());
    },
    setReportTypes: (type) => {
      dispatch(setReportTypes(type));
    },
    setChangedCadFlag: (value) => {
      dispatch(setChangedCadFlag(value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
