import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames'

import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar'

import GeneralProductivity from '../components/reports/productivity/GeneralProductivity';
import WaterStorageChart from '../components/charts/WaterStorageChart'
import EvapotranspirationChart from '../components/charts/EvapotranspirationChart'
import ProductivityAverageCropGraph from '../components/reports/productivity/ProductivityAverageCropGraph';
import DropdownField from '../components/common/DropdownField'
import CalendarDropdown from '../components/reports/productivity/CalendarDropdown'
import IconButton from '@material-ui/core/IconButton';
import OptionsModal from '../components/reports/productivity/common/OptionsModal'
import GeneralSoyForm from '../components/reports/productivity/configs/GeneralSoyForm'
import { PRODUCTIVITY, SOY, CORN, COTTON, WHEAT } from "../components/reports/productivity/common/defaultValues"

import { generateSoyProductivity, generateCornProductivity, generateCottonProductivity, generateWheatProductivity, setProductivityUndefined } from '../actions/productivity';
import { toggleReportContainer } from '../actions/reports';
import { toggleTooltips } from '../actions/global';
import validateInput from '../shared/validators/generalProdValidation'
import {activePointChange} from '../shared/utils/ProductivityAccess'

const INITIAL_SOY_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  cycle: SOY.CYCLE,
  cc_user: SOY.CC,
  u_percent_user: SOY.U_PERCENT,
  frost: PRODUCTIVITY.FROST,
  crop: 'Atual'
}

const INITIAL_CORN_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  cycle: CORN.CYCLE,
  cc_user: CORN.CC,
  u_percent_user: CORN.U_PERCENT,
  frost: PRODUCTIVITY.FROST,
  crop: 'Atual'
}

const INITIAL_COTTON_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  cycle: COTTON.CYCLE,
  cc_user: COTTON.CC,
  u_percent_user: COTTON.U_PERCENT,
  frost: PRODUCTIVITY.FROST,
  crop: 'Atual'
}

const INITIAL_WHEAT_STATE = {
  eficiency: PRODUCTIVITY.EFFICIENCY,
  cycle: WHEAT.CYCLE,
  cc_user: WHEAT.CC,
  u_percent_user: WHEAT.U_PERCENT,
  crop: 'Atual'
}


//I chose this name expecting that we may user this component in the future for other cultures, but it may not be so
class GeneralProductivityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.setInitialState()
  }

  //State initializer method
  setInitialState(){
    let initialState

    switch(this.props.reportTypes){
      case 'corn-general-productivity':
        initialState = INITIAL_CORN_STATE 
        break
      case 'soy-general-productivity':
        initialState = INITIAL_SOY_STATE
        break
      case 'cotton-general-productivity':
        initialState = INITIAL_COTTON_STATE
        break
      case 'wheat-general-productivity':
        initialState = INITIAL_WHEAT_STATE
        break
      default:
        return
    }

    const state = {
      selectedMonth: undefined,
      selectedCrop: 'Atual',
      currentFilter: initialState,
      initialMonth: undefined,
      finalMonth: undefined,
      calendarOpen: false,
      monthlyCropData: [],
      monthOptions: [],
      bottomChartMonths: [],
      constantMonthOptions: [],
      selectedFarm: undefined,
      data: undefined,
      eficiency: undefined,
      averageCropData: [],
      currentSeasonAverageData: undefined,
      lastSeasonAverageData: undefined,
      openCaneDropdown: false,
      openReport: true,
      openOptions: false,
      errors: {}
    }
    
    return state
  }

  //#region Lifecycle methods
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { state } = this;
    let firstBoxplot = 0
    let options = []
    let nextCropOptions = []
    let newState = {}

    if (nextProps.productivity && nextProps.productivity.data) {
      const data = this.generateAverageCropData(nextProps.productivity.data);
      newState.data = nextProps.productivity.data;
      newState.averageCropData = data.averageCropData;
      newState.currentSeasonAverageData = data.currentSeasonAverageData;
      newState.lastSeasonAverageData = data.lastSeasonAverageData;

      Object.keys(nextProps.productivity.data).forEach(key => {
        let lastPosition = nextProps.productivity.data[key].year.length - 1
        if(nextProps.productivity.data[key].rp_value.length > 1 && firstBoxplot === 0){
          firstBoxplot = {value: key, box: true}
        }

        options.push({value: key, box: nextProps.productivity.data[key].rp_value.length > 1})

        if(state.selectedCrop === 'Próxima'){
          if(nextProps.productivity.data[key]["year"][lastPosition]["rp_value"]){
            nextCropOptions.push({value: key, box: nextProps.productivity.data[key].rp_value.length > 1})
          }
        }
      })

      newState.selectedMonth = firstBoxplot;
      this.setState(
        { ...state, ...newState },
        () => {
          if(state.selectedMonth){
            let monthlyCropData = this.generateMonthlyCropData(nextProps.productivity.data)
            newState.monthlyCropData = monthlyCropData;
          }
        },
      );

      let foundInitial = options.find(option => option.value === state.initialMonth)
      let foundFinal = options.find(option => option.value === state.finalMonth)

      newState.initialMonth =
        state.initialMonth && foundInitial
          ? state.initialMonth
          : { value: options[0]["value"] };
      newState.finalMonth =
        state.finalMonth && foundFinal
          ? state.finalMonth
          : { value: options[options.length - 1]["value"] };
      newState.monthOptions =
        state.selectedCrop === "Atual" ? options : nextCropOptions;
      newState.constantMonthOptions = options;
      newState.bottomChartMonths = options;
    }

    if (nextProps.farms && nextProps.activeFarm) {
      const selectedFarm = nextProps.farms.find(farm => farm.id === nextProps.activeFarm);
      if(this.state.selectedFarm && this.state.selectedFarm.id !== nextProps.activeFarm){

        newState.selectedCrop = "Atual";
      }
      newState.selectedFarm = selectedFarm;
    }
    this.setState(newState);
  }

  componentDidUpdate(prevProps, prevState) {
    let newState = {}
    activePointChange(prevProps, this.props, this.props.reportTypes)
    if(this.state.data !== undefined && this.props.productivity.data === undefined){
      this.setState({data: undefined})
    }else{     
      if(prevState.selectedMonth !== this.state.selectedMonth || this.state.monthlyCropData.length < 1){
        newState.monthlyCropData = this.generateMonthlyCropData(
          this.props.productivity.data
        );
        this.setState(newState);
      }

      if(prevState.finalMonth?.value !== this.state.finalMonth.value || prevState.initialMonth?.value !== this.state.initialMonth.value){
        const data = this.generateAverageCropData(this.props.productivity.data);
        newState.averageCropData = data.averageCropData;
        newState.currentSeasonAverageData = data.currentSeasonAverageData;
        newState.lastSeasonAverageData = data.lastSeasonAverageData;
        this.setState(newState);
      }

    }

  }
  //#endregion

  //#region Render methods

  handleOptionsModal = () => {
    this.setState({openOptions: !this.state.openOptions})
  }

  onChangeMonth = (value) => {
    this.setState({selectedMonth: {value: value.target.value, box: this.props.productivity.data[value.target.value].rp_value.length > 1}})
  }

  onSelectedCrop = (value) => {
    this.setState({selectedCrop: value.target.value})
  }

  getIndex = (collection, value) => {
    let index = collection.findIndex(month => {
      return month.value === value
    })
    return index
  }

  onChangeInitialMonth = (value) => {
    let beginIndex = this.getIndex(this.state.monthOptions, value.target.value)
    let endIndex = this.getIndex(this.state.monthOptions, this.state.finalMonth.value)
    let selectedMonth = this.state.selectedMonth
    let bottomChartMonths = this.state.monthOptions.slice(beginIndex,endIndex+1)
    let selectedMonthIndex = this.getIndex(bottomChartMonths, this.state.selectedMonth.value)

    if(selectedMonthIndex === -1){
      selectedMonth = bottomChartMonths[0]
    }

    this.setState({initialMonth: {value: value.target.value}, bottomChartMonths: bottomChartMonths, selectedMonth: selectedMonth})
  }

  onChangeFinalMonth = (value) => {
    let beginIndex = this.getIndex(this.state.monthOptions, this.state.initialMonth.value)
    let endIndex = this.getIndex(this.state.monthOptions, value.target.value)
    let selectedMonth = this.state.selectedMonth
    let bottomChartMonths = this.state.monthOptions.slice(beginIndex,endIndex+1)
    let selectedMonthIndex = this.getIndex(bottomChartMonths, this.state.selectedMonth.value)

    if(selectedMonthIndex === -1){
      selectedMonth = bottomChartMonths[bottomChartMonths.length - 1]
    }

    this.setState({finalMonth: {value: value.target.value}, bottomChartMonths: bottomChartMonths, selectedMonth: selectedMonth})
  }

  onChange = ({target : { value, name } }) => {
    let newState = {}

    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }

    newState[name] = value;

    this.setState(newState);
  }

  checkSum = () => {
    let sum  = parseFloat(this.state.cane) + parseFloat(this.state.cane2) + parseFloat(this.state.cane3) + parseFloat(this.state.cane4) + parseFloat(this.state.cane5)
    if(sum === 100) {
      return true
    }

    return false
  }

  recalculate = (e) => {
    let { errors, isValid } = validateInput(this.state.currentFilter)
    if(!isValid){
      this.setState({errors: errors})
      return
    }

    const coords = this.state.selectedFarm.coords.split(',');
    const agriculturalEficiency = this.state.currentFilter.eficiency;
    const cycle = this.state.currentFilter.cycle
    const cc_user = this.state.currentFilter.cc_user
    const u_percent_user = this.state.currentFilter.u_percent_user
    const frost = this.state.currentFilter.frost
    const crop_year = this.state.currentFilter.crop === 'Atual' ? new Date().getFullYear() : new Date().getFullYear() + 1
    const range = this.state.currentFilter.range
    const clone = this.state.currentFilter.clone

    const data = {
      cad: this.state.selectedFarm.cad,
      point_id: this.state.selectedFarm.id,
      lat: coords[0],
      lon: coords[1],
      agriculturalEficiency,
      cycle,
      cc_user,
      u_percent_user,
      frost,
      crop_year,
      range,
      clone
    };

    this.setState({openOptions: false}, () => {
      this.props.actions.setProductivityUndefined();
      switch(this.props.reportTypes){
        case "cotton-general-productivity":
          this.props.actions.generateCottonProductivity(data,'general');
          break
        case "corn-general-productivity":
          this.props.actions.generateCornProductivity(data,'general');
          break
        case "wheat-general-productivity":
          this.props.actions.generateWheatProductivity(data, 'general');
          break
        default:
          this.props.actions.generateSoyProductivity(data,'general');
      }
    })
  }

  generateAverageCropData = (data) => {
    const averageCropData = [];

    let veryUnfavorable = 0;
    let unfavorable = 0;
    let medium = 0;
    let favorable = 0;
    let veryFavorable = 0;
    let lastSeason = 0;
    let currentSeason = 0;
    let currentCrop = 0;

    let lastSeasonName = "";
    let currentSeasonName = "";
    let predictionCounter = 0;
    let mainCounter = 0;

    let validKeys = Object.keys(data)
    let lastKey = validKeys[validKeys.length-1]

    if(this.state.initialMonth){
      let validInitial = validKeys.find(key => key === this.state.initialMonth.value)
      if(validInitial){
        let initialMonthIndex = validKeys.indexOf(this.state.initialMonth.value)
        validKeys = validKeys.slice(initialMonthIndex)
      }
    }

    if(this.state.finalMonth && this.state.finalMonth.value !== lastKey){
      let validFinal = validKeys.find(key => key === this.state.finalMonth.value)
      if(validFinal){
        let finalMonthIndex = validKeys.indexOf(this.state.finalMonth.value)
        validKeys = validKeys.slice(0, finalMonthIndex+1)
      }
    }

    let observedData = 0
    let hasBoxplotLastSeason = false

    for (let index in validKeys) {
      let key  = validKeys[index]
      mainCounter++;

      if (data[key].rp_value && data[key].rp_value.length === 5) {
        veryUnfavorable += data[key].rp_value[0];
        unfavorable += data[key].rp_value[1];
        medium += data[key].rp_value[2];
        favorable += data[key].rp_value[3];
        veryFavorable += data[key].rp_value[4];

        currentSeason += data[key].rp_value[2];

        predictionCounter++;
      } else {
        observedData += data[key].rp_value[0];
        currentSeason += data[key].rp_value[0];
      }

      
      if(data[key].year[data[key].year.length - 1].rp_value.length > 1){
        lastSeason += data[key].year[data[key].year.length - 1].rp_value[2];
      }
      else{
        lastSeason += data[key].year[data[key].year.length - 1].rp_value[0];
      }
      lastSeasonName = data[key].year[data[key].year.length - 1].crop;
      currentSeasonName = data[key].crop

    }

    if (predictionCounter > 0) {
      //we have to parse once in order to format with two decimals
      //And parse a second time as the graphic library only accepts numbers
      veryUnfavorable = parseFloat(parseFloat(((veryUnfavorable + observedData) / mainCounter)).toFixed(2));
      unfavorable = parseFloat(parseFloat(((unfavorable + observedData) / mainCounter)).toFixed(2));
      medium = parseFloat(parseFloat(((medium + observedData) / mainCounter)).toFixed(2));
      favorable = parseFloat(parseFloat(((favorable + observedData) / mainCounter)).toFixed(2));
      veryFavorable = parseFloat(parseFloat(((veryFavorable + observedData) / mainCounter)).toFixed(2));
      lastSeason = parseFloat(parseFloat((lastSeason / mainCounter)).toFixed(2));
      currentSeason = parseFloat(parseFloat((currentSeason / mainCounter)).toFixed(2));

      averageCropData.push(
        {name: 'Muito Desfavorável', veryUnfavorable, last: hasBoxplotLastSeason ? null : lastSeason},
        {name: 'Desfavorável', unfavorable, last: hasBoxplotLastSeason ? null : lastSeason},
        {name: 'Mediano', medium, last: hasBoxplotLastSeason ? null : lastSeason},
        {name: 'Favorável', favorable, last: hasBoxplotLastSeason ? null : lastSeason},
        {name: 'Muito Favorável', veryFavorable, last: hasBoxplotLastSeason ? null : lastSeason},
        {name: lastSeasonName, lastSeason}
      );
    } else {
      currentCrop = parseFloat(parseFloat((currentSeason / mainCounter)).toFixed(2));
      lastSeason = parseFloat(parseFloat((lastSeason / mainCounter)).toFixed(2));

      averageCropData.push(
        {name: currentSeasonName, currentCrop},
        {name: lastSeasonName, lastSeason}
      )
    }


    return {
      averageCropData,
      currentSeasonAverageData: currentSeason,
      lastSeasonAverageData: lastSeason,
    };
  }

  setMonthlyCropString = (date, crop) => {
    let crops = crop.split('/')
    crops[0] = crops[0].substr(2)
    crops[1] = crops[1].substr(2)
    return `Safra ${crops[0]}/${crops[1]}`
  }

  generateMonthlyCropData = (data) => {
    let lastPosition = data[this.state.selectedMonth.value].year.length - 1
    let lastSeasonName = data[this.state.selectedMonth.value]["year"][lastPosition]["crop"]
    let lastSeason = parseFloat(data[this.state.selectedMonth.value]["year"][lastPosition]["rp_value"][0].toFixed(2))
    let hasBoxplotLastSeason = data[this.state.selectedMonth.value]["year"][lastPosition]["rp_value"].length

    if(this.state.selectedMonth["box"]){
      let veryUnfavorable = 0;
      let unfavorable = 0;
      let medium = 0;
      let favorable = 0;
      let veryFavorable = 0;

      veryUnfavorable = parseFloat(data[this.state.selectedMonth.value].rp_value[0].toFixed(2));
      unfavorable = parseFloat(data[this.state.selectedMonth.value].rp_value[1].toFixed(2));
      medium = parseFloat(data[this.state.selectedMonth.value].rp_value[2].toFixed(2));
      favorable = parseFloat(data[this.state.selectedMonth.value].rp_value[3].toFixed(2));
      veryFavorable = parseFloat(data[this.state.selectedMonth.value].rp_value[4].toFixed(2));

      let returnList = [
        {name: 'Muito Desfavorável', veryUnfavorable, last: hasBoxplotLastSeason > 1 ? null : lastSeason},
        {name: 'Desfavorável', unfavorable, last: hasBoxplotLastSeason > 1 ? null : lastSeason},
        {name: 'Mediano', medium, last: hasBoxplotLastSeason > 1 ? null : lastSeason},
        {name: 'Favorável', favorable, last: hasBoxplotLastSeason > 1 ? null : lastSeason},
        {name: 'Muito Favorável', veryFavorable, last: hasBoxplotLastSeason > 1 ? null : lastSeason},
      ]

      if(hasBoxplotLastSeason === 1){
        returnList.push({name: lastSeasonName, lastSeason})
      }
      return returnList
    }else{
      let currentCrop = parseFloat(data[this.state.selectedMonth.value].rp_value[0].toFixed(2));

      return [
        {name: this.setMonthlyCropString(this.state.selectedMonth.value, data[this.state.selectedMonth.value].crop), currentCrop, last: lastSeason},
        {name: this.setMonthlyCropString(this.state.selectedMonth.value, lastSeasonName), lastSeason},
      ]
    }
  }

  toggleCaneDropdown = () => {
    this.setState({openCaneDropdown: !this.state.openCaneDropdown})
  }

  toggleCalendarDropdown = () => {
    this.setState({calendarOpen: !this.state.calendarOpen})
  }

  onChangeCaneValues = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }

  toggleActive = () => {
    this.props.actions.toggleReportContainer()
  }

  handleCloseOptionsModal = () => {
    this.setState({openOptions: false})
    if(!this.state.data){
      window.location.reload()
    }
  }

  handleChange = (value) => {
    let { currentFilter } = this.state
    if(value.target.name === "frost") {
      currentFilter[value.target.name] = !this.state.currentFilter.frost
    }
    else {
      currentFilter[value.target.name] = value.target.value
    }
    this.setState({currentFilter: currentFilter})
  }
  //#endregion

  render() {
    if (this.state.data && this.props.farms && this.props.activeFarm) {
      const { productivity } = this.props;
      const parameters = productivity.specific
      let title = "";
      const farmName = this.state.selectedFarm.name !== "" ? this.state.selectedFarm.name : `Ponto ${this.state.selectedFarm.initials}`;
      const eficiency = parameters.agriculturalEficiency < 1 ? Math.round(parameters.agriculturalEficiency * 100) : parameters.agriculturalEficiency;

      title = `${farmName} | CAD = ${parameters.cad} mm | E.A. = ${eficiency  !== undefined ? eficiency : this.state.currentFilter.eficiency}% | Ciclo = ${parameters.cycle !== undefined ? parameters.cycle : this.state.currentFilter.cycle } dias | CC = ${parameters.cc_user !== undefined ? parameters.cc_user : this.state.currentFilter.cc_user} | U = ${parameters.u_percent_user !== undefined ? parameters.u_percent_user : this.state.currentFilter.u_percent_user} %`;

      let keys = Object.keys(this.state.data)
      if(this.state.initialMonth){
        let initialMonthIndex = keys.indexOf(this.state.initialMonth.value)
        keys = keys.slice(initialMonthIndex)
      }
      if(this.state.finalMonth && this.state.finalMonth.value !== 'December'){
        let finalMonthIndex = keys.indexOf(this.state.finalMonth.value)
        keys = keys.slice(0, finalMonthIndex+1)
      }
      const translatedKeys = []
      let firstKey = keys[0]

      const pastCropName1 = this.state.data[firstKey]['year'][0]['crop']
      const pastCropName2 = this.state.data[firstKey]['year'][1]['crop']
      const pastCropName3 = this.state.data[firstKey]['year'][2]['crop']
      const currentCrop = this.state.data[firstKey]['crop']
      const pastCropValues1 = []
      const pastCropValues2 = []
      const pastCropValues3 = []
      const pastCropBoxplotValue = []
      const currentCropPointsValues = []
      const currentCropBoxplotValues = []

      let value = []
      let past = []
      let currentCropAuxiliar = []
      keys.forEach((key,index) => {
        translatedKeys.push(key)
        value = this.state.data[key]["rp_value"].slice()

        past = this.state.data[key]["year"].slice()
        currentCropAuxiliar = []
        value.forEach(cropValue => {
          currentCropAuxiliar.push(parseFloat(cropValue.toFixed(2)))
        })
        currentCropAuxiliar.unshift(index)
        if(this.state.data[key]["rp_value"].length === 1){
          currentCropPointsValues.push(currentCropAuxiliar)
        }else{
          currentCropBoxplotValues.push(currentCropAuxiliar)
        }

        currentCropAuxiliar = []
        past.forEach((pastValue, innerIndex) => {
          if(innerIndex === 0){
            pastCropValues1.push(parseFloat(pastValue["rp_value"][0].toFixed(2)))
          }else if(innerIndex === 1){
            pastCropValues2.push(parseFloat(pastValue["rp_value"][0].toFixed(2)))
          }else{
            if(pastValue["rp_value"]){
              pastValue["rp_value"].forEach(cropValue => {
                currentCropAuxiliar.push(parseFloat(cropValue.toFixed(2)))
              })
              currentCropAuxiliar.unshift(index)
              if(pastValue["rp_value"].length > 1){
                pastCropBoxplotValue.push(currentCropAuxiliar)
              }else{
                pastCropValues3.push(currentCropAuxiliar)
              }
            }
          }
        })
      })

      return (
        <Fragment>
          <section className={classnames("report--container visible productivity", {active: this.props.reportActive})} style={{zIndex: "8"}}>
            <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !this.props.reportActive})} onClick={ this.toggleActive }><i className='material-icons'>play_arrow</i></span>
            <header className='row bhbox-header productivity-header'>
              <div className="prod-month-container">
                <CalendarDropdown
                  active={this.state.calendarOpen}
                  onClick={this.toggleCalendarDropdown}
                  initialMonth={this.state.initialMonth}
                  finalMonth={this.state.finalMonth}
                  constantMonthOptions={this.state.constantMonthOptions}
                  onChangeInitialMonth={this.onChangeInitialMonth}
                  onChangeFinalMonth={this.onChangeFinalMonth}
                />
              </div>
              <div className="prod-options-container">
                <IconButton disableRipple={true} aria-label="tooltips" onClick={this.props.actions.toggleTooltips} title={this.props.showTooltips ? 'Esconder caixa de informações' : 'Exibir caixa de informações'}>
                  <i className='material-icons'>{this.props.showTooltips ? 'visibility_off' : 'visibility'}</i>
                </IconButton>
                <IconButton disableRipple={true} aria-label="settings" onClick={this.handleOptionsModal}>
                  <i className="material-icons">settings</i>
                </IconButton>
              </div>
            </header>
            <div className="general-prod-disclaimer">
              <span>*</span>
              {`Última atualização: ${this.props.productivity.created_at}, Próxima atualização: ${this.props.productivity.next_update}`}
            </div>
            <PerfectScrollbar className={classnames('productivity-scroll ps ps--theme default')}>
              <div className="general-graph">
                <GeneralProductivity
                  keys={translatedKeys}
                  title={title}
                  pastCropName1={pastCropName1}
                  pastCropName2={pastCropName2}
                  pastCropName3={pastCropName3}
                  currentCrop={currentCrop}
                  currentCropBoxplotValues={currentCropBoxplotValues}
                  pastCropValues1={pastCropValues1}
                  pastCropValues2={pastCropValues2}
                  pastCropValues3={pastCropValues3}
                  pastCropBoxplotValue={pastCropBoxplotValue}
                  unit={this.props.reportTypes === "cotton-general-productivity"? '@/ha' : 'sacas/ha'}
                  currentCropPointsValues={currentCropPointsValues}
                  productivityType={this.props.reportTypes}
                  showTooltips={this.props.showTooltips}
                />
              </div>
              <div className="botton-graphs">
                <Grid container>
                  <Grid item xs={6}>
                    <div className="subtitle">
                      Safra em
                      <DropdownField
                        value={this.state.selectedMonth["value"]}
                        options={this.state.bottomChartMonths}
                        onChange={this.onChangeMonth}
                        type={'months'}
                      />
                    </div>
                    {this.state.monthlyCropData.length > 0 ? 
                    <ProductivityAverageCropGraph 
                      data={this.state.monthlyCropData} 
                      unit={this.props.reportTypes === "cotton-general-productivity"? '@/ha' : 'sacas/ha'}
                      showTooltips={this.props.showTooltips}/> 
                    : null}
                  </Grid>
                  <Grid item xs={6}>
                    {this.state.averageCropData.length > 0 &&
                      <Fragment>
                        <div className="subtitle">
                          Média da Safra
                        </div>
                        <ProductivityAverageCropGraph 
                          data={this.state.averageCropData} 
                          unit={this.props.reportTypes === "cotton-general-productivity"? '@/ha' : 'sacas/ha'}
                          showTooltips={this.props.showTooltips} />
                      </Fragment>
                    }
                  </Grid>
                </Grid>
                {productivity.armData !== null ?
                  <WaterStorageChart
                    data={productivity.armData}
                    cad={this.state.selectedFarm.cad}
                    farm={farmName}
                    crop={productivity.data !== undefined ? productivity.data[firstKey].crop : ''}
                    showTooltips={this.props.showTooltips}
                  />
                  :
                  null
                }
                {productivity.etrData !== null ?
                  <EvapotranspirationChart
                    data={productivity.etrData}
                    cad={productivity.cad}
                    showTooltips={this.props.showTooltips}
                  />
                  :
                  null
                }
              </div>

            </PerfectScrollbar>
          </section>
          {this.state.openOptions &&
            <OptionsModal
              open={this.state.openOptions}
              noClose={this.state.data ? false : true}
              recalculate={this.recalculate}
              handleClose={this.handleCloseOptionsModal}
              title={"Configurações"}
              form={
                <GeneralSoyForm
                  type={this.props.reportTypes}
                  initialOptions={keys}
                  currentState={this.state.currentFilter}
                  handleChange={this.handleChange}
                  initialDate={this.state.initialMonth}
                  errors={this.state.errors}
                />
              }
            />
          }
        </Fragment>
      )
    } else {
      return (
        <section className="report--container active visible productivity" style={{zIndex: "8"}}>
          <div className='loading-screen-prod'>
            Calculando...
            <br></br>
            O processo pode levar alguns minutos
          </div>
        </section>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    farms: state.farms.points.farms,
    activeFarm: state.farms.activeFarm,
    productivity: state.productivity,
    reportActive: state.Reports.reportActive,
    showTooltips: state.global.showTooltips,
    reportTypes: state.Reports.reportTypes
  }
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {
      generateSoyProductivity,
      generateCornProductivity,
      generateCottonProductivity,
      generateWheatProductivity,
      setProductivityUndefined,
      toggleReportContainer,
      toggleTooltips
    }), dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralProductivityContainer);
