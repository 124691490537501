import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ClickOutside from 'react-click-outside';

import 'rc-checkbox/assets/index.css'

function VisualizationDropdown(props) {
  const [isOpen, setOpen] = useState(false);

  const VISUALIZATION_OPTIONS = [
    {
      value: 'civil',
      text: 'Civil',
      disabled: false,
    },
    {
      value:'agricultural',
      text: 'Agrícola',
      disabled: false,
    }
  ];

  const renderItems = (item) => {
    return (
        <label
          key={item.text}
          className={ item.value
            ? 'btn-item radio-button-item checked'
            : 'btn-item radio-button-item not-cheked'
          }
        >
          <input
            type="radio"
            name='option'
            disabled={item.disabled}
            value={item.value}
            checked={item.value === props.visualizationType}
            onChange={(e) => {
              setOpen(false);
              props.handleVisualizationType(e.target.value);
            }
          }
            id={item.value}
          />
          <span className="radio-dot"></span>
          <span className="radio-button-item-word">{item.text}</span>
        </label>
      );
  };

  return(
    <div  className='header-div-item-outline header-div-item-spacing'>
      <Link to='#' onClick={() => setOpen(true)}>
        <div className="button button--retangle">
          <div className='button--retangle-icon-left'>
            <i className='material-icons'>event</i>
          </div>
          <div className='button--retangle-icon-text'>
            { props.visualizationType === 'civil' ? 'Civil' : 'Agrícola' }
          </div>
          <div className='button--retangle-icon-right'>
            <i className='material-icons'>arrow_drop_down</i>
          </div>
        </div>
      </Link>
      {
        isOpen && (
          <ClickOutside onClickOutside={() => setOpen(false)}>
            <div className='dropdown-div weather-dropdown-variables variables visualization-type'>
              <div className="button button--retangle">
                <i className='material-icons button--retangle-icon-left'>event</i>
                <div className='button--retangle-icon-text'>
                  { props.visualizationType === 'civil' ? 'Civil' : 'Agrícola' }
                </div>
              </div>
              <PerfectScrollbar className='dropdown-scroll years dropdown-year-scroll'>
                <div className='checkbox-list radio-list'>
                  <div className="radio-button-group">
                    { VISUALIZATION_OPTIONS.map((item) => renderItems(item)) }
                  </div>
                </div>
              </PerfectScrollbar>
            </div>
          </ClickOutside>
        )
      }
    </div>
  );
}

export default VisualizationDropdown;
