import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  MuiThemeProvider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import calendarTheme from "../../../../shared/Themes";

import {
  setUsePreviousCrop,
} from "../../../../actions/GeneralCaneProductivityAction";

import { Step1GeneralCaneModal } from "./Step1GeneralCaneModal";
import { Step2GeneralCaneModal } from "./Step2GeneralCaneModal";
import { Step3GeneralCaneModal } from "./Step3GeneralCaneModal";
import { Step4GeneralCaneModal } from "./Step4GeneralCaneModal";

import { civilMonths, agriculturalMonths } from '../../../../shared/utils/months';

export function GeneralCaneModal({
  open,
  onClose,
  onClickCalculateGeneralCaneModal,
  pointId,
  crop,
  selectedFarm
}) {
  const [activeStep, setActiveStep] = useState(0);

  const dispatch = useDispatch();
  const { visualizationType } = useSelector((state) => state.generalCaneProductivityReducer);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const currentComponentStepToRender = () => {
    return activeStep === 0 ? (
      <Step1GeneralCaneModal
        onClose={onClose}
        handleNext={handleNext}
      />
    ) : activeStep === 1 ? (
      <Step2GeneralCaneModal
        handleBack={handleBack}
        handleNext={handleNext}
      />
    ) : activeStep === 2 ? (
      <Step3GeneralCaneModal
        handleBack={handleBack}
        handleNext={handleNext}
        pointId={pointId}
        months={visualizationType === 'civil' ? civilMonths : agriculturalMonths}
      />
    ) : (
      <Step4GeneralCaneModal
        handleBack={handleBack}
        pointId={pointId}
        onClose={onClose}
        onClickCalculateGeneralCaneModal={onClickCalculateGeneralCaneModal}
        crop={ crop }
        selectedFarm={ selectedFarm }
      />
    );
  };

  const steps = [
    "Selecionar Safra",
    "Selecionar Período",
    "Cenário Mensal",
    "Previsão",
  ];

  return (
    <MuiThemeProvider theme={calendarTheme}>
      <Dialog fullScreen open={open} onClose={onClose} scroll="body">
        <div style={{ width: "95%", margin: "69px auto" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontSize: "20px", margin: "0 28% 0 0" }}
            >
              Configurações
            </Typography>
            <Button onClick={() => {
              onClose();
              dispatch(setUsePreviousCrop(true));
            }}>x</Button>
          </div>
          <Stepper
            connector={false}
            activeStep={activeStep}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  style={{ margin: "0 40px 0 0" }}
                  key={label}
                  {...stepProps}
                >
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {currentComponentStepToRender()}
        </div>
      </Dialog>
    </MuiThemeProvider>
  );
}
