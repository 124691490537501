import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classnames from "classnames";
import { toggleLoader } from "../actions/global";

import Grid from "@material-ui/core/Grid";
import PerfectScrollbar from "react-perfect-scrollbar";

import GeneralProductivity from "../components/reports/productivity/GeneralProductivity";
import WaterStorageChart from "../components/charts/WaterStorageChart";
import EvapotranspirationChart from "../components/charts/EvapotranspirationChart";
import ProductivityAverageCropGraph from "../components/reports/productivity/ProductivityAverageCropGraph";
import DropdownField from "../components/common/DropdownField";
import { GeneralCaneModal } from "../componentsRefactored/smartCane/generalCaneProductivity/modalSettingButtonGraphic/GeneralCaneModal";
import DownloadXLS from "../shared/utils/DownloadXLS";
import {
  PRODUCTIVITY,
  CANE,
} from "../components/reports/productivity/common/defaultValues";

import {
  generateProductivity,
  setProductivityUndefined,
  saveCustomizedProductivity,
  getCustomizedProductivity,
} from "../actions/productivity";
import {
  translateMonth,
  translateMonthToInitials,
} from "../shared/utils/DatesUtils";
import { toggleReportContainer } from "../actions/reports";
import { toggleTooltips } from "../actions/global";
import { activePointChange } from "../shared/utils/ProductivityAccess";
import validateInput from "../shared/validators/generalProdValidation";
import { setIsModalOpen } from "../actions/GeneralCaneProductivityAction";
import VisualizationDropdown from "../components/reports/productivity/VisualizationDropdown";

class ProductivityContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMonth: { value: 'January' },
      kdec: "",
      selectedCrop: "Atual",
      visualizationType: 'civil',
      initialMonth: undefined,
      finalMonth: { value: "December" },
      monthlyCropData: [],
      monthOptions: [],
      bottomChartMonths: [],
      constantMonthOptions: [],
      selectedFarm: undefined,
      data: undefined,
      eficiency: undefined,
      eficiencyError: undefined,
      cane: undefined,
      cane2: undefined,
      cane3: undefined,
      cane4: undefined,
      cane5: undefined,
      caneError: undefined,
      averageCropData: [],
      averageTch: undefined,
      tch: undefined,
      currentSeasonAverageData: undefined,
      lastSeasonAverageData: undefined,
      openCaneDropdown: false,
      openReport: true,
      month: 0,

      openOptions: false,
      currentFilter: {
        eficiency: PRODUCTIVITY.EFFICIENCY,
        cycle: CANE.CYCLE,
        cc_user: CANE.CC,
        u_percent_user: CANE.U_PERCENT,
        kdec: CANE.KDEC.toString(),
        canePlantedProportion: CANE.PLANTED_PROPORTION,
        cane2Proportion: CANE.PLANTED_PROPORTION,
        cane3Proportion: CANE.PLANTED_PROPORTION,
        cane4Proportion: CANE.PLANTED_PROPORTION,
        cane5Proportion: CANE.PLANTED_PROPORTION,
        frost: PRODUCTIVITY.FROST,
        recalc: PRODUCTIVITY.RECALC,
        crop: "Atual",
      },
      errors: {},
    };
  }

  defineMonthIdBasedOnMonthString = (monthString) => {
    switch (monthString) {
      case "January":
        return 0;
      case "February":
        return 1;
      case "March":
        return 2;
      case "April":
        return 3;
      case "May":
        return 4;
      case "June":
        return 5;
      case "July":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "October":
        return 9;
      case "November":
        return 10;
      case "December":
        return 11;
      default:
        return 0;
    }
  };
  defineMonthStringBasedOnIdMonthNumber = (monthId) => {
    switch (monthId) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
      default:
        return 0;
    }
  };

  //#region Lifecycle methods
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { state } = this;
    let firstBoxplot = 0;
    let options = [];
    let nextCropOptions = [];
    let newState = {};

    if (nextProps.productivity && nextProps.productivity.data) {
      const data = this.generateAverageCropData(nextProps.productivity.data);
      newState.data = nextProps.productivity.data;
      newState.averageCropData = data.averageCropData;
      newState.currentSeasonAverageData = data.currentSeasonAverageData;
      newState.lastSeasonAverageData = data.lastSeasonAverageData;

      Object.keys(nextProps.productivity.data).forEach((key) => {
        let lastPosition = nextProps.productivity.data[key].year.length - 1;
        if (
          nextProps.productivity.data[key].value.length > 1 &&
          firstBoxplot === 0
        ) {
          firstBoxplot = { value: key, box: true };
        }

        options.push({
          value: key,
          box: nextProps.productivity.data[key].value.length > 1,
        });

        if (state.selectedCrop === "Próxima") {
          if (nextProps.productivity.data[key]["year"][lastPosition]["value"]) {
            nextCropOptions.push({
              value: key,
              box: nextProps.productivity.data[key].value.length > 1,
            });
          }
        }
      });

      if (!this.props.isUsingCustomizedModal) {
        newState.selectedMonth =
          firstBoxplot === 0
            ? { value: Object.entries(newState.data)[0][0], box: false }
            : firstBoxplot;
      }

      if (state.selectedMonth) {
        let monthlyCropData = this.generateMonthlyCropData(
          nextProps.productivity.data
        );
        newState.monthlyCropData = monthlyCropData;
      }

      newState.initialMonth = this.state.tch
        ? {
            value: this.defineMonthStringBasedOnIdMonthNumber(
              Object.entries(this.state.tch)[0][0]
            ),
          }
        : { value: "January" };

      newState.monthOptions =
        newState.selectedCrop === "Atual" ? options : nextCropOptions;
      newState.constantMonthOptions = options;
      newState.bottomChartMonths = options;
    }

    if (nextProps.farms && nextProps.activeFarm) {
      const selectedFarm = nextProps.farms.find(
        (farm) => farm.id === nextProps.activeFarm
      );
      if (
        this.state.selectedFarm &&
        this.state.selectedFarm.id !== nextProps.activeFarm
      ) {
        newState.selectedCrop = "Atual";
      }
      newState.selectedFarm = selectedFarm;
    }
    this.setState(newState);
  }

  componentDidUpdate(prevProps, prevState) {
    activePointChange(prevProps, this.props, "cana");

    if (prevState.visualizationType !== this.state.visualizationType) {
      const [lat, lon] = this.state.selectedFarm.coords.split(',');
      const data = {
        lat,
        lon,
        cad: this.state.selectedFarm.cad,
        point_id: this.state.selectedFarm.id,
        visualization_type: this.state.visualizationType,
      };

      this.props.actions.generateProductivity(data);
    } else if (
      this.state.data !== undefined &&
      this.props.productivity.data === undefined
    ) {
      return;
    } else {
      let newState = {};
      if (
        prevState.selectedMonth !== this.state.selectedMonth ||
        this.state.monthlyCropData.length < 1
      ) {
        newState.monthlyCropData = this.generateMonthlyCropData(
          this.props.productivity.data
        );
        this.setState(newState);
      }

      if (
        prevState.finalMonth?.value !== this.state.finalMonth.value ||
        prevState.initialMonth?.value !== this.state.initialMonth.value
      ) {
        const data = this.generateAverageCropData(this.props.productivity.data);
        newState.averageCropData = data.averageCropData;
        newState.currentSeasonAverageData = data.currentSeasonAverageData;
        newState.lastSeasonAverageData = data.lastSeasonAverageData;
        this.setState(newState);
      }
    }
  }
  //#endregion

  //#region Render methods

  handleOptionsModal = () => {
    this.props.actions.setIsModalOpen(true);
  };

  onChangeSelectedMonth = (value) => {
    this.setState({
      selectedMonth: {
        value: value.target.value,
        box: this.props.productivity.data[value.target.value].value.length > 1,
      },
    });
  };

  onSelectedCrop = (value) => {
    this.setState({ selectedCrop: value.target.value });
  };

  handleVisualizationType = (visualization) => {
    this.setState({ visualizationType: visualization });
  }

  getIndex = (collection, value) => {
    let index = collection.findIndex((month) => {
      return month.value === value;
    });
    return index;
  };

  onChangeInitialMonth = (value) => {
    let beginIndex = this.getIndex(this.state.monthOptions, value.target.value);
    let endIndex = this.getIndex(
      this.state.monthOptions,
      this.state.finalMonth.value
    );

    let selectedMonth = this.state.selectedMonth;
    let bottomChartMonths = this.state.monthOptions.slice(
      beginIndex,
      endIndex + 1
    );
    let selectedMonthIndex = this.getIndex(
      bottomChartMonths,
      this.state.selectedMonth.value
    );

    if (selectedMonthIndex === -1) {
      selectedMonth = bottomChartMonths[0];
    }

    this.setState({
      initialMonth: { value: value.target.value },
      bottomChartMonths: bottomChartMonths,
      selectedMonth: selectedMonth,
    });
  };

  onChangeFinalMonth = (value) => {
    let beginIndex = this.getIndex(
      this.state.monthOptions,
      this.state.initialMonth.value
    );

    let endIndex = this.getIndex(this.state.monthOptions, value.target.value);
    let selectedMonth = this.state.selectedMonth;
    let bottomChartMonths = this.state.monthOptions.slice(
      beginIndex,
      endIndex + 1
    );
    let selectedMonthIndex = this.getIndex(
      bottomChartMonths,
      this.state.selectedMonth.value
    );

    if (selectedMonthIndex === -1) {
      selectedMonth = bottomChartMonths[bottomChartMonths.length - 1];
    }

    this.setState({
      finalMonth: { value: value.target.value },
      bottomChartMonths: bottomChartMonths,
      selectedMonth: selectedMonth,
    });
  };

  onChange = ({ target: { value, name } }) => {
    let mewState = {};

    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }

    mewState[name] = value;

    this.setState(mewState);
  };

  checkSum = () => {
    let sum =
      parseFloat(this.state.cane) +
      parseFloat(this.state.cane2) +
      parseFloat(this.state.cane3) +
      parseFloat(this.state.cane4) +
      parseFloat(this.state.cane5);
    if (sum === 100) {
      return true;
    }

    return false;
  };

  recalculate = (e) => {
    let { errors, isValid } = validateInput(this.state.currentFilter);
    if (!isValid) {
      this.setState({ errors: errors });
      return;
    }

    const coords = this.state.selectedFarm.coords.split(",");
    const crop_year =
      this.state.currentFilter.crop === "Atual"
        ? new Date().getFullYear()
        : new Date().getFullYear() + 1;
    const point_id = this.state.selectedFarm.id;

    const data = {
      cad: this.props.productivity.cad,
      lat: coords[0],
      lon: coords[1],
      crop_year,
      visualization_type: this.props.visualizationType,
      frost_flag: true,
      point_id,
      initial_month: this.defineMonthIdBasedOnMonthString(
        this.state.initialMonth.value
      ),
      final_month: this.defineMonthIdBasedOnMonthString(
        this.state.finalMonth.value
      ),
    };
    this.setState(
      {
        openOptions: false,
        errors: {},
      },
      async () => {
        await this.props.actions.generateProductivity(data);
      }
    );
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.recalculate();
    }
  };

  generateAverageCropData = (data) => {
    const averageCropData = [];

    let veryUnfavorable = 0;
    let unfavorable = 0;
    let medium = 0;
    let favorable = 0;
    let veryFavorable = 0;
    let lastSeason = 0;
    let currentSeason = 0;

    let lastSeasonName = "";
    let currentSeasonName = "";
    let predictionCounter = 0;
    let mainCounter = 0;

    let validKeys = Object.keys(data);

    let observedData = 0;
    for (let index in validKeys) {
      let key = validKeys[index];
      mainCounter++;

      if (data[key].value && data[key].value.length === 5) {
        veryUnfavorable += data[key].value[0];
        unfavorable += data[key].value[1];
        medium += data[key].value[2];
        favorable += data[key].value[3];
        veryFavorable += data[key].value[4];

        currentSeason += data[key].value[2];

        predictionCounter++;
      } else {
        observedData += data[key].value[0];
        currentSeason += data[key].value[0];
      }

      if (data[key].year[data[key].year.length - 1].value.length > 1) {
        lastSeason += data[key].year[data[key].year.length - 1].value[2];
      } else {
        lastSeason += data[key].year[data[key].year.length - 1].value;
      }
      lastSeasonName = data[key].year[data[key].year.length - 1].crop;
      currentSeasonName = data[key].crop;
    }

    if (predictionCounter > 0) {
      //we have to parse once in order to format with two decimals
      //And parse a second time as the graphic library only accepts numbers
      veryUnfavorable = parseFloat(
        parseFloat((veryUnfavorable + observedData) / mainCounter).toFixed(2)
      );
      unfavorable = parseFloat(
        parseFloat((unfavorable + observedData) / mainCounter).toFixed(2)
      );
      medium = parseFloat(
        parseFloat((medium + observedData) / mainCounter).toFixed(2)
      );
      favorable = parseFloat(
        parseFloat((favorable + observedData) / mainCounter).toFixed(2)
      );
      veryFavorable = parseFloat(
        parseFloat((veryFavorable + observedData) / mainCounter).toFixed(2)
      );
      lastSeason = parseFloat(parseFloat(lastSeason / mainCounter).toFixed(2));
      currentSeason = parseFloat(
        parseFloat(currentSeason / mainCounter).toFixed(2)
      );

      averageCropData.push(
        { name: "Muito Desfavorável", veryUnfavorable, last: lastSeason },
        { name: "Desfavorável", unfavorable, last: lastSeason },
        { name: "Mediano", medium, last: lastSeason },
        { name: "Favorável", favorable, last: lastSeason },
        { name: "Muito Favorável", veryFavorable, last: lastSeason },
        { name: lastSeasonName, lastSeason }
      );
    } else {
      lastSeason = parseFloat(parseFloat(lastSeason / mainCounter).toFixed(2));
      currentSeason = parseFloat(
        parseFloat(currentSeason / mainCounter).toFixed(2)
      );

      averageCropData.push(
        {
          name: currentSeasonName,
          currentCrop: currentSeason,
          last: lastSeason,
        },
        { name: lastSeasonName, lastSeason }
      );
    }

    return {
      averageCropData,
      currentSeasonAverageData: currentSeason,
      lastSeasonAverageData: lastSeason,
    };
  };

  setMonthlyCropString = (month, crop) => {
    let crops = crop.split("/");
    crops[0] = crops[0].substr(2);
    crops[1] = crops[1].substr(2);
    return `${translateMonthToInitials(month)} Safra ${crops[0]}/${crops[1]}`;
  };

  generateMonthlyCropData = (data) => {
    let lastPosition = data[this.state.selectedMonth["value"]].year.length - 1;
    let lastSeasonName =
      data[this.state.selectedMonth["value"]]["year"][lastPosition]["crop"];
    const lastSeasonToComper =
      data[this.state.selectedMonth["value"]]["year"][lastPosition]["value"];

    let lastSeason = parseFloat(
      Array.isArray(lastSeasonToComper)
        ? lastSeasonToComper[2].toFixed(2)
        : lastSeasonToComper.toFixed(2)
    );

    if (data[this.state.selectedMonth.value].value.length > 1) {
      let veryUnfavorable = 0;
      let unfavorable = 0;
      let medium = 0;
      let favorable = 0;
      let veryFavorable = 0;

      veryUnfavorable = parseFloat(
        data[this.state.selectedMonth["value"]].value[0].toFixed(2)
      );
      unfavorable = parseFloat(
        data[this.state.selectedMonth["value"]].value[1].toFixed(2)
      );
      medium = parseFloat(
        data[this.state.selectedMonth["value"]].value[2].toFixed(2)
      );
      favorable = parseFloat(
        data[this.state.selectedMonth["value"]].value[3].toFixed(2)
      );
      veryFavorable = parseFloat(
        data[this.state.selectedMonth["value"]].value[4].toFixed(2)
      );

      return [
        { name: "Muito Desfavorável", veryUnfavorable, last: lastSeason },
        { name: "Desfavorável", unfavorable, last: lastSeason },
        { name: "Mediano", medium, last: lastSeason },
        { name: "Favorável", favorable, last: lastSeason },
        { name: "Muito Favorável", veryFavorable, last: lastSeason },
        { name: lastSeasonName, lastSeason },
      ];
    } else {
      let currentCrop = parseFloat(
        data[this.state.selectedMonth["value"]].value[0].toFixed(2)
      );

      return [
        {
          name: this.setMonthlyCropString(
            this.state.selectedMonth.value,
            data[this.state.selectedMonth.value].crop
          ),
          currentCrop,
          last: lastSeason,
        },
        {
          name: this.setMonthlyCropString(
            this.state.selectedMonth.value,
            lastSeasonName
          ),
          lastSeason,
        },
      ];
    }
  };

  toggleCaneDropdown = () => {
    this.setState({ openCaneDropdown: !this.state.openCaneDropdown });
  };

  onChangeCaneValues = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  toggleActive = () => {
    this.props.actions.toggleReportContainer();
  };

  handleCloseOptionsModal = () => {
    this.props.actions.setIsModalOpen(false);
    if (!this.state.data) {
      window.location.reload();
    }
  };

  handleChange = (value) => {
    let { currentFilter } = this.state;
    if (value.target.name === "frost") {
      currentFilter[value.target.name] = !this.state.currentFilter.frost;
    } else {
      currentFilter[value.target.name] = value.target.value;
    }
    this.setState({ currentFilter: currentFilter });
  };

  onChangeMonth = async (event) => {
    let configData = {
      month: event.target.value,
      point_id: this.state.selectedFarm.id,
    };

    this.setState({
      month: event.target.value,
    });
    this.props.actions.toggleLoader();
    await this.props.actions.getCustomizedProductivity(configData);
    if (Object.values(this.props.configMemory.data).length !== 0) {
      this.setState({
        ...this.state,
        currentFilter: {
          ...this.state.currentFilter,
          eficiency: this.props.configMemory.data.agry_eficiency * 100,
          cycle: this.props.configMemory.data.cycle,
          cc_user: this.props.configMemory.data.cc_user,
          u_percent_user: this.props.configMemory.data.u_percent_user,
          kdec: this.props.configMemory.data.kdec,
          canePlantedProportion:
            this.props.configMemory.data.first_cut_prop * 100,
          cane2Proportion: this.props.configMemory.data.second_cut_prop * 100,
          cane3Proportion: this.props.configMemory.data.third_cut_prop * 100,
          cane4Proportion: this.props.configMemory.data.fourth_cut_prop * 100,
          cane5Proportion:
            this.props.configMemory.data.fifth_or_plus_prop * 100,
          frost: this.props.configMemory.data.frost_flag,
        },
      });
    }
    this.props.actions.toggleLoader();
  };
  //#endregion

  render() {
    if (!this.props.productivity.isGraphicLoading) {
      const { productivity } = this.props;
      const { currentSeasonAverageData, lastSeasonAverageData } = this.state;
      const csvHeaders = [
        { label: "Safra", key: "crop" },
        { label: "Mês", key: "month" },
        { label: "Produtividade", key: "productivity" },
        { label: "Limite superior", key: "upper_limit" },
        { label: "Terceiro quartil", key: "third_quartile" },
        { label: "Mediana", key: "median" },
        { label: "Primeiro quartil", key: "first_quartile" },
        { label: "Limite inferior", key: "lower_limit" },
      ];
      const csvData = [];
      let title = "";
      let wholeCropData = [];
      const farmName =
        this.state.selectedFarm.name !== ""
          ? this.state.selectedFarm.name
          : `Ponto ${this.state.selectedFarm.initials}`;

      title = `${farmName} | CAD = ${productivity.cad} mm | Kdec = ${
        productivity.kdec ? productivity.kdec : this.state.currentFilter.kdec
      }`;

      if (currentSeasonAverageData && lastSeasonAverageData) {
        wholeCropData.push({
          farm: farmName,
          lastCrop: parseInt(lastSeasonAverageData, 10),
          currentCrop: parseInt(currentSeasonAverageData, 10),
          variation: parseInt(
            Math.abs(lastSeasonAverageData - currentSeasonAverageData),
            10
          ),
        });
      }
      let keys = Object.keys(this.state.data);

      const translatedKeys = [];

      const pastCropName1 = this.props.usePreviousCrop
        ? this.state.data[Object.entries(this.state.data)[0][0]]["year"][0][
            "crop"
          ]
        : "";
      const pastCropName2 = this.props.usePreviousCrop
        ? this.state.data[Object.entries(this.state.data)[0][0]]["year"][1][
            "crop"
          ]
        : "";
      const pastCropName3 = this.props.usePreviousCrop
        ? this.state.data[Object.entries(this.state.data)[0][0]]["year"][2][
            "crop"
          ]
        : "";
      const currentCrop = this.state.tch
        ? this.state.data[Object.entries(this.state.data)[0][0]]["crop"]
        : this.state.data[Object.entries(this.state.data)[0][0]]["crop"];
      const pastCropValues1 = [];
      const pastCropValues2 = [];
      const pastCropValues3 = [];
      const pastCropBoxplotValue = [];
      const currentCropPointsValues = [];
      const currentCropBoxplotValues = [];

      let value = [];
      let past = [];
      let currentCropAuxiliar = [];

      keys.forEach((key, index) => {
        translatedKeys.push(translateMonth(key));
        value = this.state.data[key]["value"].slice();

        csvData.push({
          crop: this.state.data[key]["crop"],
          month: translateMonth(key),
          productivity: value.length > 1 ? "" : parseFloat(value[0].toFixed(2)),
          upper_limit: value.length > 1 ? parseFloat(value[0].toFixed(2)) : "",
          third_quartile: value[1] ? parseFloat(value[1].toFixed(2)) : "",
          median: value[2] ? parseFloat(value[2].toFixed(2)) : "",
          first_quartile: value[3] ? parseFloat(value[3].toFixed(2)) : "",
          lower_limit: value[4] ? parseFloat(value[4].toFixed(2)) : "",
        });
        past = this.state.data[key]["year"].slice();
        currentCropAuxiliar = [];
        value.forEach((cropValue) => {
          currentCropAuxiliar.push(parseFloat(cropValue.toFixed(2)));
        });
        currentCropAuxiliar.unshift(index);
        if (this.state.data[key]["value"].length === 1) {
          currentCropPointsValues.push(currentCropAuxiliar);
        } else {
          currentCropBoxplotValues.push(currentCropAuxiliar);
        }
        currentCropAuxiliar = [];
        past.forEach((pastValue, innerIndex) => {
          if (innerIndex === 0) {
            pastCropValues1.push(parseFloat(pastValue["value"].toFixed(2)));
          } else if (innerIndex === 1) {
            pastCropValues2.push(parseFloat(pastValue["value"].toFixed(2)));
          } else {
            if (pastValue["value"].length > 1) {
              pastValue["value"].forEach((cropValue) => {
                currentCropAuxiliar.push(parseFloat(cropValue.toFixed(2)));
              });
              currentCropAuxiliar.unshift(index);
              pastCropBoxplotValue.push(currentCropAuxiliar);
            } else {
              pastCropValues3.push(parseFloat(pastValue["value"].toFixed(2)));
            }
          }

          if (pastValue["value"]) {
            csvData.push({
              crop: pastValue["crop"],
              month: translateMonth(key),
              productivity:
                pastValue["value"].length > 1
                  ? parseFloat(pastValue["value"][0].toFixed(2))
                  : parseFloat(pastValue["value"].toFixed(2)),
              upper_limit: "",
              third_quartile: "",
              median: "",
              first_quartile: "",
              lower_limit: "",
            });
          }
        });
      });

      const onClickCalculateGeneralCaneModal = ({
        initialMonth,
        finalMonth,
        cycle,
        kdec,
        crop,
        usePreviousCrop,
        tch,
        averageTch,
      }) => {
        this.setState(
          {
            ...this.state,
            selectedMonth: {
              value: initialMonth,
            },
            initialMonth: { value: initialMonth },
            finalMonth: { value: finalMonth },
            selectedCrop: crop,
            tch: !usePreviousCrop ? tch : undefined,
            averageTch,
            currentFilter: {
              ...this.state.currentFilter,
              cycle: cycle,
              kdec: kdec,
              crop: crop,
            },
          },
          () => {
            this.recalculate();
          }
        );
      };

      return (
        <Fragment>
          <section
            className={classnames("report--container visible productivity", {
              active: this.props.reportActive,
            })}
            style={{ zIndex: "8" }}
          >
            <span
              className={classnames(
                "container--toggler container--toggler-left valign-wrapper",
                {
                  "container--toggler-left-not-active":
                    !this.props.reportActive,
                }
              )}
              onClick={this.toggleActive}
            >
              <i className="material-icons">play_arrow</i>
            </span>
            <header className="row bhbox-header productivity-header">
              <div className="prod-options-container">
                <div>
                  <VisualizationDropdown
                    visualizationType={this.state.visualizationType}
                    handleVisualizationType={this.handleVisualizationType}
                    selectedFarm={this.state.selectedFarm}
                  />
                </div>
                <div className="header-graphic" style={{ marginRight: "4px" }}>
                  <span
                    title={
                      this.props.showTooltips
                        ? "Esconder caixa de informações"
                        : "Exibir caixa de informações"
                    }
                    onClick={this.props.actions.toggleTooltips}
                  >
                    <i className="material-icons">
                      {this.props.showTooltips
                        ? "visibility_off"
                        : "visibility"}
                    </i>
                  </span>
                </div>
                <div className="header-graphic" style={{ marginRight: "4px" }}>
                  <span title="Configurações" onClick={this.handleOptionsModal}>
                    <i className="material-icons">settings</i>
                  </span>
                </div>

                <DownloadXLS
                  marginRight={undefined}
                  headers={csvHeaders}
                  data={csvData}
                />
              </div>
            </header>
            <div className="general-prod-disclaimer">
              <span>*</span>
              {`Última atualização: ${this.props.productivity.created_at}, Próxima atualização: ${this.props.productivity.next_update}`}
            </div>
            <PerfectScrollbar
              className={classnames("productivity-scroll ps ps--theme default")}
            >
              <div className="general-graph">
                <GeneralProductivity
                  keys={translatedKeys}
                  title={title}
                  pastCropName1={
                    this.props.usePreviousCrop ? pastCropName1 : ""
                  }
                  pastCropName2={
                    this.props.usePreviousCrop ? pastCropName2 : ""
                  }
                  pastCropName3={
                    this.props.usePreviousCrop ? pastCropName3 : ""
                  }
                  pastTchName={this.props.usePreviousCrop ? "" : "TCH"}
                  currentCrop={currentCrop}
                  currentCropBoxplotValues={currentCropBoxplotValues}
                  pastCropValues1={
                    this.props.usePreviousCrop ? pastCropValues1 : []
                  }
                  pastCropValues2={
                    this.props.usePreviousCrop ? pastCropValues2 : []
                  }
                  pastCropValues3={
                    this.props.usePreviousCrop ? pastCropValues3 : []
                  }
                  pastTchValues={this.state.tch ? this.state.tch : []}
                  pastCropBoxplotValue={pastCropBoxplotValue}
                  currentCropPointsValues={currentCropPointsValues}
                  showTooltips={this.props.showTooltips}
                />
              </div>
              <div className="botton-graphs">
                <Grid container>
                  <Grid item xs={6}>
                    <div className="subtitle">
                      Safra em
                      <DropdownField
                        value={this.state.selectedMonth["value"]}
                        options={this.state.bottomChartMonths}
                        onChange={this.onChangeSelectedMonth}
                        type={"months"}
                      />
                    </div>
                    {this.state.monthlyCropData.length > 0 ? (
                      <ProductivityAverageCropGraph
                        selectedMonth={this.state.selectedMonth.value}
                        tch={
                          this.props.usePreviousCrop
                            ? undefined
                            : this.state.tch
                        }
                        data={this.state.monthlyCropData}
                        showTooltips={this.props.showTooltips}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={6}>
                    {this.state.averageCropData.length > 0 && (
                      <Fragment>
                        <div className="subtitle">Média da Safra</div>
                        <ProductivityAverageCropGraph
                          tch={
                            this.props.usePreviousCrop
                              ? undefined
                              : this.state.averageTch
                          }
                          data={this.state.averageCropData}
                          showTooltips={this.props.showTooltips}
                        />
                      </Fragment>
                    )}
                  </Grid>
                </Grid>
                {productivity.armData !== null ? (
                  <WaterStorageChart
                    data={productivity.armData}
                    cad={productivity.cad}
                    farm={farmName}
                    crop={
                      productivity.data !== undefined
                        ? productivity.data[this.state.selectedMonth["value"]]
                            .crop
                        : ""
                    }
                    showTooltips={this.props.showTooltips}
                  />
                ) : null}
                {productivity.etrData !== null ? (
                  <EvapotranspirationChart
                    data={productivity.etrData}
                    cad={productivity.cad}
                    showTooltips={this.props.showTooltips}
                  />
                ) : null}
              </div>
            </PerfectScrollbar>
          </section>
          {this.props.isModalOpen && (
            <GeneralCaneModal
              open={this.props.isModalOpen}
              onClose={this.handleCloseOptionsModal}
              onClickCalculateGeneralCaneModal={
                onClickCalculateGeneralCaneModal
              }
              title={"Configurações"}
              pointId={this.state.selectedFarm.id}
              crop={ this.state.currentFilter.crop }
              selectedFarm={ this.state.selectedFarm }
            />
          )}
        </Fragment>
      );
    } else {
      return (
        <section
          className="report--container active visible productivity"
          style={{ zIndex: "8" }}
        >
          <div className="loading-screen-prod">
            Calculando...
            <br></br>O processo pode levar alguns minutos
          </div>
        </section>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    period: state.generalCaneProductivityReducer.period,
    isModalOpen: state.generalCaneProductivityReducer.isModalOpen,
    isUsingCustomizedModal:
      state.generalCaneProductivityReducer.isUsingCustomizedModal,
    usePreviousCrop: state.generalCaneProductivityReducer.usePreviousCrop,
    farms: state.farms.points.farms,
    activeFarm: state.farms.activeFarm,
    productivity: state.productivity,
    reportActive: state.Reports.reportActive,
    showTooltips: state.global.showTooltips,
    configMemory: state.productivity.config,
    visualizationType: state.generalCaneProductivityReducer.visualizationType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          setIsModalOpen,
          generateProductivity,
          saveCustomizedProductivity,
          getCustomizedProductivity,
          setProductivityUndefined,
          toggleReportContainer,
          toggleTooltips,
          toggleLoader,
        }
      ),
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductivityContainer);
