import React, { useState } from "react";
import InputField from "../common/InputField";

import TrashIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

const UserPoints = (props) => {
  const [lat, setLat] = useState("");
  const [lng, setLon] = useState("");

  function addRow(e) {
    e.preventDefault();
    const point = lat + "," + lng;
    props.addPoint(point);
  }

  return (
    <div className="signin-modal-user-data">
      <div className="row signin-row user-points">
        <form onSubmit={addRow} style={{display: 'flex', alignItems: 'center'}}>
          <div className="col">
            <InputField
              title={"-xx.xxx ou xx.xxx"}
              pattern="^-?[0-9]{1,2}\.[0-9]{1,3}$"
              name="lat"
              label="Latitude"
              type="text"
              value={lat}
              error={undefined}
              onChange={(e) => setLat(e.target.value)}
              active={lat !== ""}
              activeFarm={"a"}
            />
          </div>
          <div className="col">
            <InputField
              title={"-xx.xxx ou xx.xxx"}
              pattern="^-?[0-9]{1,2}\.[0-9]{1,3}$"
              name="lon"
              label="Longitude"
              type="text"
              value={lng}
              error={undefined}
              onChange={(e) => setLon(e.target.value)}
              active={lng !== ""}
              activeFarm={"a"}
            />
          </div>
          <div className="col">
            <button
              type="submit"
              className="btn wave-light agrymet-green"
              style={{ fontSize: "12px", padding: "0px 6px", height: "24px" }}
            >
              <AddIcon />
            </button>
          </div>
        </form>
      </div>
      {props.points.map((point, index) => (
        <div className="row signin-row user-points">
          <div className="col">
            <InputField
              name="lat"
              type="text"
              value={point.split(",")[0]}
              error={undefined}
              active={true}
              activeFarm={""}
            />
          </div>
          <div className="col">
            <InputField
              name="lon"
              type="text"
              value={point.split(",")[1]}
              error={undefined}
              active={true}
              activeFarm={""}
            />
          </div>
          <div className="col">
            <button
              className="btn wave-light agrymet-green"
              style={{ fontSize: "12px", padding: "0px 6px", height: "24px" }}
              onClick={() => props.deletePoint(index)}
            >
              <TrashIcon />
            </button>
          </div>
        </div>
      ))}
      <div className="row button login-area-form-container-inside-buttons-row">
        <button
          className="btn-flat btn-drop"
          style={{ marginRight: "8px" }}
          onClick={props.backClick}
        >
          VOLTAR
        </button>
        <button
          className="btn wave-light agrymet-green"
          style={{ marginLeft: "8px", fontSize: "12px" }}
          onClick={props.nextClick}
        >
          AVANÇAR
        </button>
      </div>
    </div>
  );
};

export default UserPoints;
