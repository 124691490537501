import React, { Component, Fragment } from 'react'
import history from '../common/history'

//components
import SimpleNav from '../common/SimpleNav'
import UploadInfo from './UploadInfo'
import UploadForm from './UploadForm'
import { ToastContainer } from 'react-toastify'
import Loader from '../common/Loader'
import { showToaster } from '../common/Toaster'

import {Grid} from '@material-ui/core'

class UploadPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedPoint: "0",
      file: undefined
    }
  }

  //#region Lifecycle methods
  componentDidMount(){
    let token = localStorage.Token ? localStorage.Token : sessionStorage.Token
    this.props.toggleLoader()
    this.props.getFarms(token)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.uploadStatus !== undefined && nextProps.uploadStatus.status === 200){
      let toasterParams = {
        title: 'SUCESSO!',
        message: nextProps.uploadStatus['message'],
        status: true,
        autoClose: false,
        onClose: () => {history.push('/')}
      }            
      showToaster(toasterParams)
    }else if(nextProps.uploadStatus !== undefined && nextProps.uploadStatus.status === 500){
      let toasterParams = {
        title: 'ERRO!',
        message: nextProps.uploadStatus['message'],
        status: false,
        autoClose: false,
      }            
      showToaster(toasterParams)
    }
  }

  componentWillUnmount(){
    this.props.resetUpload()
  }
  //#endregion

  //#region Render methods
  onChange = (event) => {
    if(event.target.name === 'point'){
      this.setState({
        selectedPoint: event.target.value
      })
    }else{
      this.setState({
        file: event.target.files[0]
      })
    }
  }

  onSubmit = (event) => {
    event.preventDefault()

    let attrs = {
      farmId: this.state.selectedPoint,
      file: this.state.file
    }
    let toasterParams = {
      title: 'FAZENDO UPLOAD DOS DADOS!',
      message: 'Continue usando o Agrymax enquanto isso! Notificaremos quando o processo acabar.',
      status: true,
      autoClose: false,
    }            
    showToaster(toasterParams)
    this.props.uploadData(attrs)
  }
  //#endregion

  render(){
    if(this.props.farms !== undefined){
      return(
        <Fragment>
          <Loader active={this.props.loader} />
          <ToastContainer className='toast-alert-container'/>
          <SimpleNav application={this.props.application}/>
          <Grid container direction='column' className='upload-info-container'>
            <Grid item>
              <Grid container justify='space-between'>
                <Grid item md={4} style={{paddingRight: '1%'}}>
                  <h5 className='upload-page-title'>UPLOAD DE DADOS</h5>
                  <p className='upload-page-info'>No Agrymax, é possível você inserir seus dados meteorológicos diários para que os nossos serviços sejam disponibilizados de forma personalizada para sua localidade, sendo assim, mais precisos e acurados.</p>
                </Grid>
                <Grid item  md={7}>
                  <UploadForm 
                    fileName={this.state.file} 
                    farms={this.props.farms} 
                    selectedPoint={this.state.selectedPoint} 
                    onChange={this.onChange} 
                    onSubmit={this.onSubmit}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className='upload-info-row'>
              <UploadInfo />
            </Grid>
          </Grid>
        </Fragment>
      )
    }else{
      return(null)
    }
  }

}

export default UploadPage
