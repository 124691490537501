import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'

import NewScheduleTable from "./common/NewScheduleTable";
import { showToaster } from './common/Toaster';

import { getSchedules, resetScheduleStatus } from '../actions/schedule'
import { toggleSchedule } from '../actions/global'
import classnames from 'classnames'

const Schedule = () => {
    const dispatch = useDispatch()
    const shouldUpdate = useSelector(state => state.schedule.update)
    const scheduleActive = useSelector(state => state.global.scheduleActive)
    const status = useSelector(state => state.schedule.status)

    useEffect(() => {
        dispatch(getSchedules())

        let toasterParams

        switch(status){
            case "edit":
                toasterParams = {
                    title: 'SUCESSO!',
                    message: 'Agendamento do gráfico atualizado!',
                    status: true,
                    autoClose: true,
                  }              
                showToaster(toasterParams)
                break
            case "delete":
                toasterParams = {
                    title: 'SUCESSO!',
                    message: 'Agendamento do gráfico excluído!',
                    status: true,
                    autoClose: true,
                  }              
                showToaster(toasterParams)
                break
            default:
                return
        }
        dispatch(resetScheduleStatus())
        
    },[dispatch, shouldUpdate, status])

    function toggleActive() {
        dispatch(toggleSchedule())
    }

    return (
        <Fragment>
            <section className={ classnames('report--container visible', { 'active': scheduleActive, 'toggler-hide': !scheduleActive }) }>
            <span className={classnames('container--toggler container--toggler-left valign-wrapper', {'container--toggler-left-not-active': !scheduleActive})} onClick={toggleActive}><i className='material-icons'>play_arrow</i></span>
            <header className='row bhbox-header'></header>
                <PerfectScrollbar className='schedule'>
                    <NewScheduleTable />
                </PerfectScrollbar>
            </section>
        </Fragment>
    )
}
  
export default Schedule